import styles from './VacationOverview.module.scss';

import {
  Eventcalendar,
  MbscCalendarColor,
  MbscCalendarDayData,
  MbscCalendarEvent,
  MbscCellClickEvent,
  MbscEventcalendarView,
  MbscResource,
} from '@mobiscroll/react';
import { Dialog, Theme } from '@mui/material';
import { dialogClasses } from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AutoSizer } from 'react-virtualized';

import { TimeFrameSwitch } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import { useMobiscrollLanguage } from '@work4all/components/lib/utils/use-mobiscroll-language/use-mobiscroll-language';

import { useUser } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';

import { MobileActionBar } from '../../containers/calendar/components/MobileActionBar';
import { Sidebar } from '../../containers/calendar/components/Sidebar';
import { CalendarCardColorBy } from '../../containers/calendar/types';
import { useAbsences } from '../../containers/vacations/use-absences';
import { usePublicHolidays } from '../../containers/vacations/use-public-holidays';
import { usePageTheme } from '../../providers/HookedMuiThemeProvider';
import { settings, useSetting } from '../../settings';
import { UserCompilationPicker } from '../entity-picker/UsersCompilationPicker';

import { CellTooltip } from './components/CellTooltip';
import { Footer } from './components/Footer';
import { Resource } from './components/Resource';
import { ResourcesHeader } from './components/ResourcesHeader';
import { EMPTY_ROW_ID } from './constants';
import { useKinds } from './hooks/useKinds';
import { absenceBackground } from './utils/absenceBackground';
import { getWholeDayInformation } from './utils/getWholeDayInformation';

const HOLIDAY_BACKGROUND = 'var(--bg-holiday)';
const HOVERED_RESSOURCE_BACKGROUND = '#8585853F';

const WEEKENDS: MbscCalendarColor = {
  background: HOLIDAY_BACKGROUND,
  recurring: { repeat: 'weekly', weekDays: 'SA,SU' },
};

export interface CalendarEventClickData {
  id: number;
  range: [Date, Date];
  vacationKind: LookUp;
  user: User;
  approver: User;
  options: {
    firstHalf: boolean;
    secondHalf: boolean;
  };
  isSickness?: boolean;
}
interface CalendarCellClickData {
  date: Date;
  user: User;
}

interface Props {
  showSelfOnly?: boolean;
  onEventClicked: (e: CalendarEventClickData) => void;
  onCellClicked: (e: CalendarCellClickData) => void;
  onCreateClick: () => void;
  date: Date;
  setDate: (date: Date) => void;
}

export const VacationOverview: React.FC<Props> = (props) => {
  const { date, setDate } = props;
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { kinds } = useKinds();

  const { value: visibleKinds, set: setVisibleKinds } = useSetting(
    settings.vacationVisibleKinds()
  );

  const language = useMobiscrollLanguage();

  const [openDialog, setOpenDialog] = useState(false);
  const user = useUser();
  const defaultUser = useMemo(
    () => [
      {
        id: user.benutzerCode,
        displayName: user.displayName,
        departmentName: user.departmentName,
      } as User,
    ],
    [user]
  );

  const [hoveredRessource, setHoveredRessource] = useState<string>();

  const { value: usersState, set: setUsers } = useSetting<User[]>({
    name: 'appointmentoverview.users',
    scope: 'tenant',
    defaultValue: defaultUser,
  });

  const handleUsersClear = useCallback(() => {
    // Clicking "Remove all" shouldn't remove the current user from the
    // selection, unless it's the only user left. If the current user is not
    // present in the selection, add it in.

    if (usersState.length === 0) {
      return;
    }

    if (usersState.length === 1 && usersState[0].id === defaultUser[0].id) {
      setUsers([]);
    } else {
      setUsers(defaultUser);
    }
  }, [usersState, setUsers, defaultUser]);

  const [focusedUserIds, setFocusedUserIds] = useState<number[]>([]);

  useEffect(() => {
    if (props?.showSelfOnly) {
      setFocusedUserIds([user.benutzerCode]);
    }
  }, [props?.showSelfOnly, user.benutzerCode]);

  const allUsers = useMemo(() => {
    return props.showSelfOnly ? defaultUser : usersState;
  }, [usersState, defaultUser, props.showSelfOnly]);

  const users = useMemo(() => {
    return focusedUserIds.length
      ? allUsers.filter((user) => focusedUserIds.includes(user.id))
      : allUsers;
  }, [focusedUserIds, allUsers]);

  const sortedUsers = useMemo(() => {
    return users
      .slice()
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [users]);

  const { value: vacationTimeRange } = useSetting(settings.vacationTimeRange());
  const isMonthView = vacationTimeRange === 'month';
  const isYearView = vacationTimeRange === 'year';

  const reqOptions = useMemo(() => {
    const fromToDate = isMonthView
      ? {
          from: date,
          to: DateTime.fromJSDate(date).plus({ months: 2 }).toJSDate(),
        }
      : {
          from: DateTime.fromJSDate(date).startOf('year').toJSDate(),
          to: DateTime.fromJSDate(date).endOf('year').toJSDate(),
        };

    return {
      ...fromToDate,
      userIds: users.map((el) => el.id),
    };
  }, [date, isMonthView, users]);

  const { data: publicHolidays } = usePublicHolidays(reqOptions);

  const {
    users: vacationUsers,
    vacations: { data: vacations },
    sicknesses: { data: sicknesses },
  } = useAbsences(reqOptions);

  const today = DateTime.now().startOf('day').toFormat('yyyy-MM-dd');

  const colors = useMemo<MbscCalendarColor[]>(() => {
    const colors: MbscCalendarColor[] = [];
    if (hoveredRessource) {
      colors.push({
        recurring: {
          repeat: 'daily',
        },
        resource: [hoveredRessource],
        background: HOVERED_RESSOURCE_BACKGROUND,
      });
    }

    if (isMonthView) {
      colors.push(
        WEEKENDS,
        {
          date: DateTime.now().startOf('day').toJSDate(),
          background: '#499ce04D',
        },
        ...publicHolidays.map((holiday) => {
          return {
            date: DateTime.fromISO(holiday.date).startOf('day').toJSDate(),
            background: HOLIDAY_BACKGROUND,
          };
        })
      );
    }

    return colors;
    //on purpose as we only want to reeval if the date string changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredRessource, publicHolidays, today, isMonthView]);

  const resources = useMemo<MbscResource[]>(() => {
    const res: MbscResource[] = [
      ...sortedUsers.map((el) => ({
        ...el,
        name: el.displayName,
        id: el.id || 'unknown',
      })), //ToDo our users interface marks id as optional what ts does not like for msbresource
    ];

    // We don't need the special placeholder row to render the collection
    // buttons anymore, but if there are no resources (no users selected), the
    // calendar renders the default one, which is incorrect. This will cause it
    // to render an empty placeholder row instead.
    if (res.length === 0) {
      res.push({
        id: EMPTY_ROW_ID,
      });
    }
    return res;
  }, [sortedUsers]);

  const events = useMemo<MbscCalendarEvent[]>(() => {
    const events: MbscCalendarEvent[] = [...vacations, ...sicknesses]
      .filter((absence) => {
        const id =
          absence?.simpleVacationKind?.id ??
          (absence.__typename === 'Krankheit' ? 'sickness' : 'vacation');

        // Empty array means "do not filter" (the same as "show everything").
        return visibleKinds.length === 0 || visibleKinds.includes(id);
      })
      .map((absence) => {
        return {
          ...absence,
          date: new Date(absence.date),
          resource: absence.user.id,
          allDay: true,
          original: absence,
        };
      });

    events.push({
      resource: 'Special',
    });

    const otherEvents: MbscCalendarEvent[] = isMonthView
      ? [
          {
            recurring: {
              repeat: 'daily',
            },
            resource: user.benutzerCode,
            test: true,
          },
        ]
      : [];

    return [...otherEvents, ...events];
  }, [vacations, sicknesses, isMonthView, user.benutzerCode, visibleKinds]);

  const calenderRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let calendarWrap: HTMLDivElement;
    if (calenderRef.current) {
      calendarWrap = calenderRef.current;
      const unsetHover = () => {
        setHoveredRessource(undefined);
      };
      const manageHoveredRessource = (e: MouseEvent) => {
        const el = (e.target as HTMLDivElement)?.closest(
          `.mbsc-timeline-resource`
        );

        if (el) {
          //todo set colors of the row
          setHoveredRessource(
            (el.querySelector(`.${styles.resource}`) as HTMLDivElement)?.dataset
              .id
          );
        } else {
          unsetHover();
        }
      };
      calendarWrap.addEventListener('mouseout', unsetHover);
      calendarWrap.addEventListener('mouseover', manageHoveredRessource);
      return () => {
        if (calendarWrap) {
          calendarWrap.removeEventListener('mouseout', unsetHover);
          calendarWrap.removeEventListener('mouseover', manageHoveredRessource);
        }
      };
    }
  }, []);

  const view: MbscEventcalendarView = useMemo(() => {
    if (isMonthView) {
      return {
        timeline: {
          type: 'month',
          rowHeight: 'variable',
          size: 2,
        },
      };
    }

    return {
      calendar: {
        type: 'year',
        popover: false,
      },
    };
  }, [isMonthView]);

  const onCellClick = useCallback(
    (args: MbscCellClickEvent) => {
      if (isMonthView && args.resource !== user.benutzerCode) {
        return;
      }
      props.onCellClicked({
        user: { ...user, id: user.benutzerCode },
        date: args.date,
      });
    },
    [isMonthView, props, user]
  );

  const renderDay = useCallback(
    (args: MbscCalendarDayData) => {
      const day = DateTime.fromJSDate(args.date).day;
      const events = args.events;
      const event = events[0]?.original as Vacation | Sickness;
      const style: CSSProperties =
        event && users.length >= 1
          ? {
              background:
                users.length === 1
                  ? absenceBackground(events, event)
                  : 'transparent',
              border: '2px solid',
              borderColor:
                users.length === 1
                  ? absenceBackground(events, event)
                  : '#499ce0',
              boxSizing: 'content-box',
            }
          : undefined;

      const colleagues: User[] = events?.map((event) => event.original.user);
      const uniqueColleagues = uniqBy(colleagues, 'id');

      return (
        <CellTooltip
          colleagues={uniqueColleagues}
          hideTooltip={users.length < 2 || colleagues.length === 0}
        >
          <div
            style={{
              ...style,
              borderRadius: '100%',
              width: '2em',
              height: '2em',
              display: 'inline-block',
              lineHeight: '2em',
            }}
          >
            {day}
          </div>
        </CellTooltip>
      );
    },
    [users]
  );

  const isUpMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const pageTheme = usePageTheme();

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
        sx={{
          [`.${dialogClasses.paper}`]: {
            height: 500,
          },
        }}
      >
        <UserCompilationPicker
          value={users}
          onChange={(users) => setUsers(users)}
          hiddenTabs={['resources', 'compilations']}
        />
      </Dialog>
      <div className={styles.wrapper}>
        <div
          className={clsx(styles.calendarWrapper, {
            [styles.mobile]: isYearView && !isUpMd,
          })}
        >
          <div className={styles.controls}>
            <div className={styles.date}>
              <TimeFrameSwitch
                initialTime={date}
                onChange={setDate}
                timeUnit={isYearView ? 'year' : 'month'}
              />
            </div>
          </div>

          <Divider />

          <div
            className={clsx(styles.autoSizerWrapper, {
              [styles.smallSide]: downSm,
            })}
          >
            <AutoSizer>
              {({ width, height }) => (
                <div
                  style={{ width }}
                  className={clsx({ [styles.grid]: isYearView && isUpMd })}
                >
                  {isUpMd && isYearView && (
                    <Sidebar
                      onUsersClear={handleUsersClear}
                      onCreateView={() => null}
                      users={allUsers}
                      onUsersChange={(users) => setUsers(users)}
                      focusedUserIds={focusedUserIds}
                      onFocusedUserIdsChange={setFocusedUserIds}
                      calendarCardColorBy={
                        CalendarCardColorBy.AppointmentStatus
                      }
                      showSelfOnly={props?.showSelfOnly}
                      views={[]}
                      activeView={undefined}
                      onSelectView={() => null}
                      onDeleteView={() => null}
                    />
                  )}

                  <Divider orientation="vertical" />

                  <div
                    style={{
                      height: isYearView && !isUpMd ? undefined : height,
                      minHeight: '100%',
                    }}
                    ref={calenderRef}
                  >
                    <Eventcalendar
                      locale={language}
                      dataTimezone="utc"
                      displayTimezone="local"
                      className={styles.calendar}
                      theme="ios"
                      themeVariant={pageTheme.value}
                      showControls={false}
                      view={view}
                      data={events}
                      colors={colors}
                      resources={resources}
                      selectedDate={date}
                      refDate={date}
                      onCellClick={onCellClick}
                      onEventClick={(args) => {
                        const absence = args.event.original as
                          | Vacation
                          | Sickness;

                        if (args.event.recurring) {
                          //we only do this to mark the active users rows
                          props.onCellClicked({
                            user: vacationUsers.find(
                              (vacUser) => vacUser.id === user.benutzerCode
                            ),
                            date: args.date,
                          });
                          return;
                        }

                        const options = getWholeDayInformation(events, absence);

                        const date = DateTime.fromISO(absence.date)
                          .startOf('day')
                          .toJSDate();

                        if (user.benutzerCode === absence.user.id) {
                          let config;
                          if (absence.__typename === 'Urlaub') {
                            const vacation = absence as Vacation;
                            config = {
                              vacationKind: vacation.vacationKind,
                              approver: vacation.vacationApprover,
                              options,
                            };
                          } else {
                            config = {
                              vacationKind: undefined,
                              approver: undefined,
                              options: {
                                firstHalf: true,
                                secondHalf: true,
                              },
                              isSickness: true,
                            };
                          }

                          props.onEventClicked({
                            id: absence.id,
                            range: [date, date],
                            user: absence.user,
                            ...config,
                          });
                        }
                      }}
                      showEventTooltip={false}
                      renderScheduleEvent={(event) => {
                        const absence = event.original as Vacation;
                        if (!event.original || !absence.user) {
                          return <div className={styles.isPlaceholder}></div>;
                        }

                        return (
                          <div
                            className={clsx(styles.vacation, {
                              [styles.isMyVacation]:
                                absence.user.id === user.benutzerCode,
                            })}
                            style={{
                              background: absenceBackground(events, absence),
                            }}
                          ></div>
                        );
                      }}
                      renderResourceHeader={() => (
                        <ResourcesHeader
                          onUsersClear={handleUsersClear}
                          showSelfOnly={props?.showSelfOnly}
                          openDialog={() => setOpenDialog(true)}
                        />
                      )}
                      renderResource={(resource) => (
                        <Resource
                          resource={resource}
                          onUsersChange={(users) => setUsers(users)}
                          showSelfOnly={props?.showSelfOnly}
                          users={users}
                          currentUserId={user.benutzerCode}
                        />
                      )}
                      renderDay={isYearView ? renderDay : undefined}
                    />
                  </div>
                </div>
              )}
            </AutoSizer>
          </div>
        </div>

        {isUpMd && (
          <Footer
            kinds={kinds}
            selectedKinds={visibleKinds}
            onSelectedKindsChange={(value) => setVisibleKinds(value)}
          />
        )}

        {!isUpMd && (
          <MobileActionBar
            users={users}
            onUsersChange={(users) => setUsers(users)}
            onUsersClear={handleUsersClear}
            focusedUserIds={focusedUserIds}
            onFocusedUserIdsChange={setFocusedUserIds}
            appointmentStates={[]}
            onAppointmentStatesChange={() => null}
            onCreateClick={props.onCreateClick}
            views={[]}
            activeView={undefined}
            onSelectView={() => null}
            onCreateView={() => null}
            onDeleteView={() => null}
            calendarCardColorBy={CalendarCardColorBy.AppointmentStatus}
            hideUserSelection={props?.showSelfOnly}
          />
        )}
      </div>
    </>
  );
};
