import { gql, useMutation } from '@apollo/client';

import { FileEntity } from '@work4all/models/lib/File';

export const MUTATION_GRAPHQL = gql`
  mutation AttachZugferdToTempfile($invoiceCode: Int!, $tempPdfFile: ID!) {
    attachZugferdToTempfile(
      invoiceCode: $invoiceCode
      tempPdfFile: $tempPdfFile
    ) {
      date: datum
      fileInfos {
        downloadUrl
        previewMimeType
        previewUrl
        fileEntityFilename
        fileSize
      }
      id
      fileName: dateiname
    }
  }
`;

export type AttachZugferdToTempfileResponse = {
  attachZugferdToTempfile: {
    fileInfos: FileEntity;
    dateiname: string;
    datum: string;
    id: number;
  };
};

export type AttachZugferdToTempfileVars = {
  invoiceCode: number;
  tempPdfFile: string;
};

export const useAttachZugferdToTempfile = () => {
  const [attachZugferdToTempFile] = useMutation<
    AttachZugferdToTempfileResponse,
    AttachZugferdToTempfileVars
  >(MUTATION_GRAPHQL);

  return { attachZugferdToTempFile };
};
