import { gql, useQuery } from '@apollo/client';
import { useObservableState } from 'observable-hooks';
import { useMemo, useState } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

const SEARCH_QUERY = gql`
  query Search(
    $term: String!
    $types: [SearchObjectType]!
    $querySize: Int
    $queryPage: Int
  ) {
    search(
      term: $term
      types: $types
      querySize: $querySize
      queryPage: $queryPage
    ) {
      ... on Kunde {
        id: code
        name
        number: nummer
        website: interNet
      }
      ... on Lieferant {
        id: code
        name
        number: nummer
        website: interNet
      }
      ... on Projekt {
        id: code
        name
        number: nummer
      }
      ... on Artikel {
        id: code
        name
        number: nummer
      }
      ... on Ansprechpartner {
        id: code
        name
        nameExtension: namenserweiterung
        firstName: vorname
        businessPartner: geschaeftspartner {
          id: code
          data {
            ... on Kunde {
              id: code
              name
              number: nummer
              website: interNet
            }
            ... on Lieferant {
              id: code
              name
              number: nummer
              website: interNet
            }
          }
        }
      }
    }
  }
`;
const DEFAULT_DATA: SearchResult = { search: [] };

export type SearchObjectType =
  | 'CUSTOMER'
  | 'SUPPLIER'
  | 'PROJECT'
  | 'ARTICLE'
  | 'CONTACTS';

interface UseGlobalSearchQueryVariables {
  term: string;
  types: SearchObjectType[];
  querySize: number;
  queryPage: number;
}

export type SearchResultItem = Project &
  Supplier &
  Customer &
  Contact &
  Article;

interface SearchResult {
  search: SearchResultItem[];
}

export const useGlobalSearchQuery = (
  props: Pick<UseGlobalSearchQueryVariables, 'types'>
) => {
  const { types } = props;

  const [page, setPage] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    inputValue
  );

  const variables = useMemo(() => {
    const _variables: UseGlobalSearchQueryVariables = {
      queryPage: page,
      querySize: 100,
      term: debouncedValue,
      types,
    };
    return _variables;
  }, [debouncedValue, page, types]);

  const response = useQuery<SearchResult, UseGlobalSearchQueryVariables>(
    SEARCH_QUERY,
    {
      variables,
      skip: types.length === 0 || debouncedValue.length < 3,
    }
  );

  const onChange = useMemo(() => {
    const onInputChange = (value: string) => {
      setInputValue(value);
      setDebouncedValue(value);
    };
    return onInputChange;
  }, [setDebouncedValue]);

  return {
    ...response,
    data: response.data || DEFAULT_DATA,
    onChange,
    setPage,
    inputValue,
    debouncedValue,
  };
};
