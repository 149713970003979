import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  SelectableTree,
  TreeNode,
} from '@work4all/components/lib/dataDisplay/tree/SelectableTree';
import { PickerTargetButton } from '@work4all/components/lib/input/picker-target-button';

import { useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

export interface RessourceClassPickerFieldProps {
  value: LookUp[];
  multiple: boolean;
  onChange: (value: LookUp[]) => void;
  error?: string;
}

export function RessourceClassPickerField(
  props: RessourceClassPickerFieldProps
) {
  const { multiple, onChange, value, error } = props;

  const popoverRef = useRef<EntityPickerPopover>(null);

  const { t } = useTranslation();

  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        name: null,
        id: null,
        textValue: null,
      } as LookUp,
      entity: Entities.lookUp,
      completeDataResponse: true,
      vars: {
        type: LookupType.RESSOURCENKLASSEN,
      },
    };
  }, []);

  const { data: ressourceClasses } = useDataProvider<LookUp>(request);

  const treeData = useMemo<TreeNode[]>(() => {
    const result = [...new Set(ressourceClasses.map((x) => x.textValue))].map(
      (x) => {
        const children = ressourceClasses
          .filter((y) => y.textValue === x)
          .map((x) => ({
            id: x.id.toString(),
            label: x.name || t('COMMON.WITHOUT_GROUP'),
          }));

        return {
          id: x,
          label: x,
          children,
        } as TreeNode;
      }
    );

    if (result.length === 1) {
      return [...result[0].children];
    }
    return result;
  }, [ressourceClasses, t]);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <SelectableTree
          multiple={multiple}
          selectable={'leaf'}
          data={treeData}
          selected={value.map((x) => x?.id.toString())}
          onChange={(ids) => {
            onChange?.(
              ressourceClasses.filter((x) => ids.includes(x?.id.toString()))
            );
          }}
        />
      }
    >
      <PickerTargetButton
        value={
          multiple
            ? (value as LookUp[]).length === 0
              ? null
              : `${(value as LookUp[]).length} ${t('COMMON.CHOSEN')}`
            : (value as LookUp)?.[0]?.name ?? ''
        }
        label={t('COMMON.RESSOURCECLASS', { count: multiple ? 2 : 1 })}
        error={error}
        onClear={() => {
          onChange?.(undefined);
        }}
      />
    </EntityPickerPopover>
  );
}
