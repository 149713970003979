import { useEventCallback } from '@mui/material/utils';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { RemoveToolbarAction } from '../type';

type Props = {
  ids: string[];
  action?: RemoveToolbarAction;
};

export const useToolbarDeleteAction = ({
  ids,
  action: { maxCount, canDeleteEntity, handler } = {
    maxCount: 0,
    canDeleteEntity: () => ({
      value: false,
      preventMessage: undefined,
    }),
    handler: noop,
  },
}: Props) => {
  const dialogs = useDialogs();
  const { t } = useTranslation();

  const showDeleteDialog = useEventCallback((count: number = 1) => {
    return dialogs.confirm({
      title: t('ALERTS.HINT'),
      description: t('ALERTS.DELETE.REASSURE', { count }),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });
  });

  const showPreventDialog = useEventCallback((preventMessage: string) => {
    return dialogs.alert({
      title: t('ALERTS.HINT'),
      description: preventMessage,
    });
  });

  const showMaxCountDialog = useEventCallback(() => {
    return dialogs.alert({
      title: t('ALERTS.DELETE.NOT_ALLOWED'),
      description: t('ALERTS.DELETE.MAX_WARNING', { count: maxCount }),
    });
  });

  const onDeleteClick = useEventCallback(async () => {
    if (ids.length > maxCount) {
      await showMaxCountDialog();
      return;
    }

    if (canDeleteEntity) {
      for (const id of ids) {
        const { value, preventMessage } = canDeleteEntity(id);

        if (value) {
          const confirmed = await showDeleteDialog();
          if (confirmed) {
            handler([id]);
          }
        } else {
          await showPreventDialog(preventMessage);
        }
      }

      return;
    }

    const confirmed = await showDeleteDialog(ids.length);
    if (confirmed) {
      handler(ids);
    }
  });

  return { onDeleteClick };
};
