import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { AbsenceOverview } from './AbsenceOverview.entity';
import { User } from './User.entity';
import { WorkingTimeTimeCardDayInfo } from './WorkingTimeTimeCardDayInfo.entity';
import { WorkingTimeTimeCardMonthCorrection } from './WorkingTimeTimeCardMonthCorrection.entity';

export class TimeCard<T extends EMode = EMode.entity> {
  /** Alias: abwesenheit */
  absence?: AbsenceOverview<T>;
  /** Alias: abzugAusgezahlteUeberstundenMinuten */
  deductionPayedOvertimeMinutes?: number;
  /** Alias: abzugInkludierteMinuten */
  deductionIncludedMinutes?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: datum */
  date?: string;
  /** Alias: istBruttoGesamtMinuten */
  isGrossTotalMinutes?: number;
  /** Alias: istNettoGesamtMinuten */
  isNetTotalMinutes?: number;
  /** Alias: korrekturen */
  correctionList?: WorkingTimeTimeCardMonthCorrection<T>[];
  /** Alias: letzterZwischensaldoDatum */
  lastInterimBalanceDate?: string;
  /** Alias: sollGesamtMinuten */
  targetTotalMinutes?: number;
  /** Alias: tage */
  timeCardDays?: WorkingTimeTimeCardDayInfo<T>[];
  /** Alias: zeitkontoMonatsEnde */
  timeAccountAtMonthEnd?: number;
  /** Alias: zeitkontoVormonatsEnde */
  timeAccountAtPreviousMonthEnd?: number;
  /** Alias: saldoCurrentMonth */
  saldoCurrentMonth?: number;
  /** Alias: maxTargetTimeMinutes */
  maxTargetTimeMinutes?: number;
  /** Alias: archivedPdfDownloadUrl */
  archivedPdfDownloadUrl?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TimeCard> = {
  absence: {
    alias: 'abwesenheit',
    entity: Entities.absenceOverview,
  },
  deductionPayedOvertimeMinutes: {
    alias: 'abzugAusgezahlteUeberstundenMinuten',
  },
  deductionIncludedMinutes: {
    alias: 'abzugInkludierteMinuten',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  date: {
    alias: 'datum',
  },
  isGrossTotalMinutes: {
    alias: 'istBruttoGesamtMinuten',
  },
  isNetTotalMinutes: {
    alias: 'istNettoGesamtMinuten',
  },
  correctionList: {
    alias: 'korrekturen',
    entity: Entities.workingTimeTimeCardMonthCorrection,
  },
  lastInterimBalanceDate: {
    alias: 'letzterZwischensaldoDatum',
  },
  targetTotalMinutes: {
    alias: 'sollGesamtMinuten',
  },
  timeCardDays: {
    alias: 'tage',
    entity: Entities.workingTimeTimeCardDayInfo,
  },
  timeAccountAtMonthEnd: {
    alias: 'zeitkontoMonatsEnde',
  },
  timeAccountAtPreviousMonthEnd: {
    alias: 'zeitkontoVormonatsEnde',
  },
  saldoCurrentMonth: {
    alias: 'saldoCurrentMonth',
  },
  maxTargetTimeMinutes: {
    alias: 'maxTargetTimeMinutes',
  },
  archivedPdfDownloadUrl: {
    alias: 'archivedPdfDownloadUrl',
  },
  __typename: {
    alias: '__typename',
  },
};

export const timeCardEntityDefinition: EntitiyDefinition<TimeCard> = {
  local: {},
  remote: {
    queryName: 'getZeitkarte',
    fragmentName: 'Zeitkarte',
    withPaginationWrapper: false,
    params: [
      { name: 'benutzerCode', type: 'Int!' },
      { name: 'datum', type: 'DateTime!' },
    ],
  },
  fieldDefinitions: fields,
};
