import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DuplicateIcon } from '@work4all/assets/icons/duplicate.svg';

import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Base } from '../base/Base';
import { BaseActionProps } from '../types';

export const Duplicate: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      activateForDisabled={true}
      title={props.disabled ? t('RIGHTS.MISSING') : undefined}
    >
      <Base
        icon={<DuplicateIcon style={{ width: '1.5rem', height: '1.5rem' }} />}
        title={t('MASK.DUPLICATE')}
        {...props}
      />
    </Tooltip>
  );
};
