import { Stack } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ProjectPlanningView } from '../../../../../components/data-tables/project-table/components/project-table-bottom-section/components/project-planning-view/ProjectPlanningView';

import { TopBar } from './top-bar/TopBar';

export enum ProjectPlanningPageSearchParamKeys {
  projectIds = 'projectIds',
  layout = 'layout',
}

export const ProjectPlanningPage = () => {
  const [searchParams] = useSearchParams();

  const projectIdsParams = searchParams.get(
    ProjectPlanningPageSearchParamKeys.projectIds
  );

  const layoutInitial =
    (searchParams.get(ProjectPlanningPageSearchParamKeys.layout) as
      | 'gantt'
      | 'stacked') || 'gantt';

  const projectIds = projectIdsParams?.split(',').map((x) => parseInt(x)) || [];

  const [layout, setLayout] = useState(layoutInitial);

  return (
    <Stack height="100%" width="100%">
      <TopBar
        layout={layout}
        onLayoutChanged={(layout) => {
          setLayout(layout);
          searchParams.set(ProjectPlanningPageSearchParamKeys.layout, layout);
          const search = searchParams.toString();
          const newUrl =
            window.location.origin + window.location.pathname + '?' + search;
          window.history.pushState({ path: newUrl }, '', newUrl);
        }}
      />
      <ProjectPlanningView
        projectIdList={projectIds}
        showFullscreenAction={false}
        onProjectIdsChange={(ids) => {
          if (ids.length) {
            searchParams.set(
              ProjectPlanningPageSearchParamKeys.projectIds,
              ids.length ? ids.join(',') : null
            );
          } else {
            searchParams.delete(ProjectPlanningPageSearchParamKeys.projectIds);
          }

          const search = searchParams.toString();
          const newUrl =
            window.location.origin + window.location.pathname + '?' + search;
          window.history.pushState({ path: newUrl }, '', newUrl);
        }}
        layout={layout}
      />
    </Stack>
  );
};
