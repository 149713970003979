import { MbscCalendarDayData } from '@mobiscroll/react';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { TimeUnit } from '../components/time-unit-switch/TimeUnitSwitch';

export const useRenderDay = (props: { unit: TimeUnit }) => {
  const { unit } = props;
  return useCallback(
    (args: MbscCalendarDayData) => {
      return (
        <Box
          textAlign="center"
          fontSize="70%"
          overflow="hidden"
          textOverflow="clip"
          whiteSpace="nowrap"
        >
          {unit === 'year'
            ? args.date.getDate()
            : DateTime.fromJSDate(args.date).toFormat('dd.MM.')}
        </Box>
      );
    },
    [unit]
  );
};
