import { useEventCallback } from '@mui/material/utils';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { Row } from 'react-table';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { RowMeasurer, RowMeasurerCache } from '@work4all/components';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { useDnDScroll } from './hooks/use-dnd-scroll';

export type InfiniteRow<T extends object> = Row<T> & { temporarySize?: number };

export interface InfiniteListState {
  isRowVisible: (index: number) => boolean;
}

interface InfiniteListContextValues {
  infiniteState: InfiniteListState;
  renderRow: (
    props: ListChildComponentProps<InfiniteRow<object>[]>,
    state: InfiniteListState,
    measureRef?: React.RefCallback<HTMLElement>,
    maxRowSize?: number
  ) => JSX.Element;
  maxRowSize?: Record<number, number>;
  measureRows: boolean;
  measureMax: (index: number, input: number | null) => void;
  getMaxSize: (index: number) => number | undefined;
}
const InfiniteListContext = createContext<InfiniteListContextValues>(null);
export const useInifiteList = () => useContext(InfiniteListContext);

interface InfiniteListProps<T extends object> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listRef: React.MutableRefObject<VariableSizeList<any>>;
  itemSize?: 'auto' | ((index: number) => number);
  rows: InfiniteRow<T>[];
  renderRow: (
    props: ListChildComponentProps<InfiniteRow<T>[]>,
    state: InfiniteListState,
    measureRef?: React.RefCallback<HTMLElement>,
    maxRowSize?: number
  ) => JSX.Element;
  width: number;
  height: number;
}

const estimatedItemSize = remToPx(3.5);
const defaultMaxSize = remToPx(10);

const RowRenderer = (props: ListChildComponentProps) => {
  const { infiniteState, renderRow, measureRows, maxRowSize } =
    useContext(InfiniteListContext);

  if (measureRows) {
    return (
      <RowMeasurer index={props.index}>
        {({ measureRef }) =>
          renderRow(
            props,
            infiniteState,
            measureRef,
            maxRowSize[props.index] ?? defaultMaxSize
          )
        }
      </RowMeasurer>
    );
  }

  return renderRow(props, infiniteState);
};

export function InfiniteList<T extends object>(props: InfiniteListProps<T>) {
  const { listRef, width, height, itemSize, rows, renderRow } = props;

  const visibleRangeRef = useRef({
    startIndex: 0,
    stopIndex: 0,
  });

  const onItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      visibleRangeRef.current = {
        startIndex: visibleStartIndex,
        stopIndex: visibleStopIndex,
      };
    },
    []
  );

  const isRowVisible = useCallback((index: number) => {
    return (
      index >= visibleRangeRef.current.startIndex &&
      index <= visibleRangeRef.current.stopIndex
    );
  }, []);

  const infiniteState = useMemo(() => {
    return {
      isRowVisible,
    };
  }, [isRowVisible]);

  const measureRows = itemSize === 'auto';

  const maxRowSize = useRef<Record<number, number | null>>({});

  const measureMax = useEventCallback((index: number, value: number | null) => {
    if (maxRowSize.current[index] === value) return;
    maxRowSize.current[index] = value;
  });

  const value = useMemo(() => {
    return {
      infiniteState,
      renderRow,
      measureRows,
      maxRowSize: maxRowSize.current,
      measureMax,
      getMaxSize: (index: number) => {
        return maxRowSize.current[index] === null
          ? undefined
          : maxRowSize.current[index] ?? defaultMaxSize;
      },
    } as unknown as InfiniteListContextValues;
  }, [infiniteState, renderRow, measureRows, maxRowSize, measureMax]);

  const innerRef = useRef<HTMLDivElement>();
  useDnDScroll(innerRef.current?.parentElement);

  function renderList({ itemSize }: { itemSize: (index: number) => number }) {
    return (
      <InfiniteListContext.Provider value={value}>
        <VariableSizeList
          ref={listRef}
          innerRef={innerRef}
          style={{ overflowX: 'hidden' }}
          width={width}
          height={height}
          itemSize={itemSize}
          estimatedItemSize={estimatedItemSize}
          itemCount={rows.length}
          overscanCount={8}
          itemData={rows}
          onItemsRendered={onItemsRendered}
          className="initite-list"
        >
          {RowRenderer}
        </VariableSizeList>
      </InfiniteListContext.Provider>
    );
  }

  if (measureRows) {
    return (
      <RowMeasurerCache
        estimatedRowHeight={remToPx(3.5)}
        onResize={() => {
          listRef.current?.resetAfterIndex(0);
        }}
      >
        {({ getRowHeight }) => renderList({ itemSize: getRowHeight })}
      </RowMeasurerCache>
    );
  }

  return renderList({ itemSize });
}
