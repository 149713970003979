import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputObjectGroupRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: number[];
  /** Alias: remove */
  remove?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputObjectGroupRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputObjectGroupRelationEntityDefinition: EntitiyDefinition<InputObjectGroupRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputObjectGroupRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
