import { useEffect, useMemo } from 'react';

import { SimplePDFReportProps } from '@work4all/components/lib/components/entity-template-provider/interfaces/SimpleReportErpTypes';
import { PDFPreview } from '@work4all/components/lib/preview/pdf-preview/PDFPreview';

import { useDataProvider, useSecureBlob } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Tenant } from '@work4all/models/lib/Classes/Tenant.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../settings';

import { accessorByType } from './components/signature-conf/SignatureConf';
import { useSimpleReport } from './use-simple-report';

export const SimplePDFReport = (props: SimplePDFReportProps) => {
  const { data, bzObjType, onPdfBlobChange } = props;

  const { activeTenant } = useTenant();

  const simpleReportConf = useSetting(settings.simpleReportConf());

  const { value: simpleReportSignatureConf } = useSetting(
    settings.simpleReportSignatureConf()
  );

  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.tenant,
      data: {
        id: null,
        name: null,
        imageFileName: null,
        street: null,
        postalCode: null,
        city: null,
        phoneNumber: null,
        faxNumber: null,
        ustID: null,
      } as Tenant<EMode.query>,
      filter: [{ id: { $eq: activeTenant } }],
      completeDataResponse: true,
    };
  }, [activeTenant]);

  const tenantResponse = useDataProvider<Tenant>(request);
  const tenant = tenantResponse?.data?.[0];

  const { blob: signatureBlobUrl } = useSecureBlob(
    (data as DeliveryNote).signature?.fileInfos?.downloadUrl ?? null
  );

  const { pdfUrl, pdfBlob } = useSimpleReport({
    data,
    bzObjType,
    options: {
      ...simpleReportConf.value,
      senderCity: tenant?.city,
      senderName: tenant?.name,
      senderPostalCode: tenant?.postalCode,
      senderStreet: tenant?.street,
    },
    signatureImgBlob: signatureBlobUrl,
    signatureName: accessorByType[simpleReportSignatureConf.nameSource]?.(data),
  });

  useEffect(() => {
    onPdfBlobChange?.(pdfBlob);
  }, [pdfBlob, onPdfBlobChange]);

  return pdfUrl ? <PDFPreview url={pdfUrl} /> : null;
};
