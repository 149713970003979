import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from '@work4all/assets/icons/outline-calendar.svg';

import { getStatisticFormatedDate } from '@work4all/components/lib/dataDisplay/basic-table/reporting/getStatisticFormatedDate';
import { DatePicker } from '@work4all/components/lib/input/date-picker';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';

interface IWheelPickerProps {
  min?: Date;
  max?: Date;
  unit: 'month' | 'year';
  value?: Date;
  defaultValue?: Date;
  label?: string;
  onChange: (value: Date) => void;
}

export function DateWheelPickerField(props: IWheelPickerProps) {
  const { value, defaultValue = new Date(), onChange, unit, min, max } = props;
  const { t } = useTranslation();

  const [currentDate, setCurrentDate] = useState(defaultValue);

  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    setCurrentDate(value || defaultValue);
  }, [value, defaultValue]);

  return (
    <>
      <NavigationPopover
        open={popOverOpen}
        onClose={() => setPopOverOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        initialView={{
          // title: t('MORE.FILTER_TITLE'),
          view: (
            <DatePicker
              min={min}
              max={max}
              value={value}
              defaultValue={defaultValue}
              controls={['date']}
              dateFormat={unit === 'month' ? 'MM/YYYY' : 'YYYY'}
              dateWheels={unit === 'month' ? '|MMMM|YYYY|' : '|YYYY|'}
              onChange={(e) => {
                onChange(e.value as Date);
                setCurrentDate(e.value as Date);
              }}
            />
          ),
        }}
      />
      <Box style={{ display: 'flex' }}>
        <Box style={{ width: '100%' }}>
          <LabeledInput
            onClick={() => {
              setPopOverOpen(true);
            }}
            ref={anchorRef}
            label={
              props.label ||
              t(unit === 'month' ? 'COMMON.MONTH' : 'COMMON.YEAR')
            }
            value={getStatisticFormatedDate(
              currentDate,
              unit === 'month' ? 'MMM yyyy' : 'yyyy'
            )}
            iconRight={<CalendarIcon />}
            actions={[
              {
                icon: (
                  <ChevronLeft
                    color={
                      !(
                        DateTime.fromJSDate(currentDate).startOf(unit) >
                        DateTime.fromJSDate(min).startOf(unit)
                      )
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                ),
                href: null,
                onClick: () => {
                  if (
                    DateTime.fromJSDate(currentDate).startOf(unit) >
                    DateTime.fromJSDate(min).startOf(unit)
                  ) {
                    const newDate = DateTime.fromJSDate(currentDate)
                      .minus({ [unit]: 1 })
                      .toJSDate();
                    setCurrentDate(newDate);
                    onChange(newDate);
                  }
                },
              },
              {
                icon: (
                  <ChevronRight
                    color={
                      !(
                        DateTime.fromJSDate(currentDate).startOf(unit) <
                        DateTime.fromJSDate(max).startOf(unit)
                      )
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                ),
                href: null,
                onClick: () => {
                  if (
                    DateTime.fromJSDate(currentDate).startOf(unit) <
                    DateTime.fromJSDate(max).startOf(unit)
                  ) {
                    const newDate = DateTime.fromJSDate(currentDate)
                      .plus({ [unit]: 1 })
                      .toJSDate();
                    setCurrentDate(newDate);
                    onChange(newDate);
                  }
                },
              },
            ]}
          />
        </Box>
      </Box>
    </>
  );
}
