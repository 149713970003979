import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface CommentValue {
  comment: string;
  setComment: (comment: string) => void;
}

export const CommentContext = createContext<CommentValue>({
  comment: '',
  setComment: () => {},
});

interface CommentProviderProps {
  children: ReactNode;
}

export const CommentProvider: FC<CommentProviderProps> = ({ children }) => {
  const [comment, setComment] = useState<string>('');

  const value = useMemo(() => ({ comment, setComment }), [comment]);

  return (
    <CommentContext.Provider value={value}>{children}</CommentContext.Provider>
  );
};

export const useCommentContext = () => {
  return useContext(CommentContext);
};
