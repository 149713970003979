import { gql } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { prepareResponse } from '@work4all/data';

import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { BzObjectRelation } from '@work4all/models/lib/Classes/BzObjectRelation.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InboundPosition } from '@work4all/models/lib/Classes/InboundPosition.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { InputPromptResult } from '@work4all/models/lib/Classes/InputPromptResult.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { ModifyShadowBzObjectResult } from '@work4all/models/lib/Classes/ModifyShadowBzObjectResult.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';
import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { CUSTOM_FIELDS_DATA } from '../../../../components/custom-fields/custom-fields-data';

export const bpCombined: BusinessPartnerContactCombined<EMode.query> = {
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        number: null,
        name: null,
        street: null,
        city: null,
        postalCode: null,
        website: null,
        isPrivateCustomer: null,
        languageId: null,
        eInvoiceFormat: null,
        invoiceEMailAddress: null,
      },
      supplier: {
        id: null,
        number: null,
        name: null,
        street: null,
        city: null,
        postalCode: null,
        website: null,
        isPrivateCustomer: null,
        languageId: null,
        invoiceEMailAddress: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    name: null,
    firstName: null,
    role: null,
    layedOff: null,
    salutation: {
      id: null,
      isFemale: null,
      isMale: null,
    },
    phoneNumber: null,
    eMail: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
};

const positionCommonFields:
  | Position<EMode.query>
  | InboundPosition<EMode.query> = {
  id: null,
  positionKind: null,
  articleId: null,
  number: null,
  longtext: null,
  amount: null,
  orderAmount: null,
  unit: null,
  shortText: null,
  internalText: null,
  vat: null,
  measurement: null,
  weight: null,
  singleWeight: null,
  amoundPalettes: null,
  amountCarton: null,
  amountVe: null,
  factor: null,
  articleNumber: null,
  process: null,
  // posId: null,
  // partsListAmount: null,
  // partsListType: null,
  index: null,
  // localId: null,

  singlePriceNet: null,
  totalPriceNet: null,
  discount: null,
  originalId: null,
};

const positionPriceFields: Position<EMode.query> = {
  totalPriceGross: null,
  singlePriceGross: null,
  insteadOfTotalPrice: null,
  purchasePrice: null,
};

const additionalPositionFields: Position<EMode.query> = {
  ledgerAccount: {
    id: null,
    name: null,
    number: null,
  },
  ledgerAccountNumber: null,
  costAccountEntity: {
    id: null,
    name: null,
    number: null,
  },
  costAccount: null,
  costCenter1: {
    id: null,
    name: null,
    number: null,
  },
  costCenter1Number: null,
  width: null,
  volume: null,
  totalVolume: null,
  length: null,
  purchasePriceSurcharge: null,
  minutePrice: null,
  startTime: null,
  endTime: null,
  article: {
    id: null,
    name: null,
    number: null,
  },
  dispositionStart: null,
  dispositionEnd: null,
  ownArticleNumber: null,
  deliveryDate: null,
  actualDeliveryDate: null,
  manufacturerNumber: null,
  supplierId: null,
  process: null,
  supplier: {
    id: null,
    name: null,
  },
  responsibleUser: {
    id: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    shortName: null,
  },
  editorUserId: null,
};

const positionFields: Position<EMode.query> = {
  ...positionCommonFields,
  ...positionPriceFields,
  ...additionalPositionFields,
  posId: null,
  partsListAmount: null,
  partsListType: null,
  rohertrag: null,
  localId: null,
  customFieldList: [CUSTOM_FIELDS_DATA],
};

const orderProps: Order = {
  actualDeliveryDate: null,
};

const deliveryNotePositionFields: Position<EMode.query> = {
  ...positionCommonFields,
  ...additionalPositionFields,
  posId: null,
  partsListAmount: null,
  partsListType: null,
  customFieldList: [CUSTOM_FIELDS_DATA],
  localId: null,
};

const inboundPositionFields: InboundPosition<EMode.query> = {
  ...positionCommonFields,
  costCenterId: null,
  projectId: null,
};

const inboundDeliveryNotesProps: InboundDeliveryNote<EMode.query> = {
  currency: {
    id: null,
    name: null,
  },
  currencyId: null,
  note: null,
  number: null,
  status1: null,
  date: null,
  rtfHeadText: null,
  rtfFooterText: null,
  userId: null,
  user: {
    id: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    shortName: null,
  },
  user2Id: null,
  user2: {
    id: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    shortName: null,
  },
  outgoingDeliveryDate: null,
  ourSign: null,
  yourSign: null,
  projectId: null,
  project: {
    id: null,
    name: null,
    number: null,
  },
  businessPartnerContactCombined: bpCombined,
  businessPartnerId: null,
  businessPartnerType: null,
  businessPartnerText: null,
  contactId: null,

  frozen: null,
  serviceStartDate: null,
  serviceEndDate: null,
  erpAttachmentList: [
    {
      id: null,
      fileName: null,
      fileExtension: null,
      lastModificationDate: null,
      updateTime: null,
      originalFileName: null,
      fileInfos: {
        fileSize: null,
        fileEntityFilename: null,
        previewUrl: null,
        downloadUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
      },
      user: {
        id: null,
        displayName: null,
        eMail: null,
        phoneNumber: null,
        shortName: null,
      },
    },
  ],
  archivePdf: [
    {
      id: null,
      downloadUrl: null,
      mimeType: null,
      xML: null,
      fileInfos: {
        fileEntityFilename: null,
        downloadUrl: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileServiceProviderInfos: {
          id: null,
          fileName: null,
          mimeType: null,
          fspUrl: null,
        },
      },
    },
  ],
  customFieldList: [CUSTOM_FIELDS_DATA],
};

const commonProps = {
  ...inboundDeliveryNotesProps,
  additionalAddress1: bpCombined,
  additionalAddress1CompanyId: null,
  additionalAddress1CompanyType: null,
  additionalAddress1ContactId: null,
  additionalAddress1Text: null,
  additionalAddress2: bpCombined,
  additionalAddress2CompanyId: null,
  additionalAddress2CompanyType: null,
  additionalAddress2ContactId: null,
  additionalAddress2Text: null,
  additionalAddress3: bpCombined,
  additionalAddress3CompanyId: null,
  additionalAddress3CompanyType: null,
  additionalAddress3ContactId: null,
  additionalAddress3Text: null,
  paymentKind: {
    id: null,
    note: null,
  },
  paymentId: null,
  paymentDeadline: null,
  skonto: null,
  skontoDurationDays: null,
  deliveryKind: {
    id: null,
    text: null,
  },
  bankDetails: {
    id: null,
    name: null,
    bic: null,
    iban: null,
  },
  language: {
    name: null,
    id: null,
  },
  value: null,
  contractStartDate: null,
  user: {
    id: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    shortName: null,
  },
  priceGroup: {
    id: null,
    name: null,
    isGrossPrice: null,
  },
  priceGroupId: null,
  costCenter: {
    id: null,
    name: null,
    number: null,
  },
  costCenterId: null,
};

// TODO: missing props on InboundDeliveryNote, Calculation, Order, and Contract
const missingProps: Offer | DeliveryNote | Order | Contract = {
  deliveryKindId: null,
  bankDetailsId: null,
  releaseInformation: {
    releaseNeeded: null,
  },
};

const dispoProps = {
  dispositionStart: null,
  dispositionEnd: null,
};
const partialInvoiceLogic = {
  partialInvoiceLogicId: null,
  partialInvoiceLogic: {
    id: null,
    name: null,
  },
};

export const createResponseData = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
): ModifyShadowBzObjectResult<EMode.query>['data'] => {
  const headProps = erpCommonProps;
  const positionProps = erpCommonProps['positionList']?.[0] ?? {};
  const extendedCommonProps = {
    ...commonProps,
    positionList: [
      { ...positionFields, insteadOfTotalPrice: null, ...positionProps },
    ],
  };

  const commonPropsWithoutPrices = cloneDeep({
    ...commonProps,
    positionList: [{ ...deliveryNotePositionFields, ...positionProps }],
  });
  delete commonPropsWithoutPrices.value;
  switch (entity) {
    case Entities.offer:
      return {
        offer: {
          ...headProps,
          ...extendedCommonProps,
          ...missingProps,
          ...dispoProps,
          ...partialInvoiceLogic,
          isClosed: null,
          frozen: null,
          validUntilDate: null,
        } as Offer<EMode.query>,
      };

    case Entities.deliveryNote:
      return {
        deliveryNote: {
          ...headProps,
          ...commonPropsWithoutPrices,
          ...missingProps,
          isClosed: null,
          frozen: null,
          signature: {
            id: null,
            name: null,
            fileType: null,
            info1: null,
            info2: null,
            fileInfos: {
              fileSize: null,
              fileEntityFilename: null,
              previewUrl: null,
              downloadUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
            },
          },
        } as DeliveryNote<EMode.query>,
      };
    case Entities.invoice:
      return {
        invoice: {
          ...headProps,
          ...extendedCommonProps,
          ...missingProps,
          paid: null,
          ra: null,
          frozen: null,
        } as Invoice<EMode.query>,
      };
    case Entities.calculation:
      return {
        calculation: {
          ...headProps,
          ...extendedCommonProps,
          isClosed: null,
          frozen: null,
          ...dispoProps,
          validUntilDate: null,
        } as Calculation<EMode.query>,
      };
    case Entities.order:
      return {
        order: {
          ...headProps,
          ...extendedCommonProps,
          ...missingProps,
          ...orderProps,
          isClosed: null,
          frozen: null,
          ...dispoProps,
        } as Order<EMode.query>,
      };
    case Entities.contract:
      return {
        contract: {
          ...headProps,
          ...extendedCommonProps,
          ...missingProps,
          isClosed: null,
          frozen: null,
          ...dispoProps,
          ...partialInvoiceLogic,
          contractDate: null,
          contractNumber: null,
        } as Contract<EMode.query>,
      };
    case Entities.demand:
      return {
        demand: {
          ...headProps,
          ...extendedCommonProps,
          isClosed: null,
          frozen: null,
          contractStartDate: null,
        } as Demand<EMode.query>,
      };
    case Entities.inboundDeliveryNote:
      return {
        inboundDeliveryNote: {
          ...headProps,
          ...inboundDeliveryNotesProps,
          isClosed: null,
          frozen: null,
          inboundDeliveryNoteKind: null,
          deliveryDate: null,
          deliveryNoteNumber: null,
          positionList: [inboundPositionFields],
        } as InboundDeliveryNote<EMode.query>,
      };

    default:
      throw new Error(`${entity} is not supported`);
  }
};

export const createResponse = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
): ModifyShadowBzObjectResult<EMode.query> => ({
  id: null,
  name: null,
  bzObjType: null,
  businessPartnerAssignedNumber: null,
  // The "id" field is not fetched on purpose. By doing this it will be
  // stored separately from the original entity in the Apollo cache. This
  // makes it so that changes to the shadow object aren't reflected in the
  // rest of the application until the shadowBzObject is finalized
  data: createResponseData(entity, erpCommonProps),
});

const createShadowBzObjectResponeGraphql = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) =>
  prepareResponse(
    Entities.modifyShadowBzObjectResult,
    createResponse(entity, erpCommonProps)
  );

export const createShadowBzObjectGraphQl = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) => gql`
mutation CreateShadowBzObject(
  $type: BzObjType!
  $sdObjType: SdObjType
  $sdObjMemberCode: Int
  $bzObjMemberCode: Int
  $apCode: Int
  $name: String
) {
  createShadowBzObject(
    type: $type
    sdObjType: $sdObjType
    sdObjMemberCode: $sdObjMemberCode
    bzObjMemberCode: $bzObjMemberCode
    name: $name
    apCode: $apCode
  ) {
    ${createShadowBzObjectResponeGraphql(entity, erpCommonProps)}
  }
}
`;

export const modifyShadowBzObjectGraphQl = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) => gql`
mutation ModifyShadowBzObject(
  $id: ID!
  $deliveryNote: InputLieferschein
  $offer: InputAngebot
  $invoice: InputRechnung
  $calculation: InputKalkulation
  $contract: InputAuftrag
  $demand: InputBedarfsanforderung
  $order: InputBestellung
  $inboundDeliveryNote: InputEingangslieferschein
  $promptResult: [InputPromptResult]
) {
  modifyShadowBzObject(
    id: $id
    lieferschein: $deliveryNote
    angebot: $offer
    rechnung: $invoice
    kalkulation: $calculation
    auftrag: $contract
    bedarf: $demand
    bestellung: $order
    eingangslieferschein: $inboundDeliveryNote
    promptResult: $promptResult
  ) {
    ${createShadowBzObjectResponeGraphql(entity, erpCommonProps)}
  }
}
`;

export type ModifyShadowBzObjectResponse = {
  modifyShadowBzObject: ModifyShadowBzObjectResult;
};

export type ModifyShadowBzObjectVars = {
  id: string | number;
  deliveryNote?: DeliveryNote;
  promptResult?: InputPromptResult[];
};

export type CreateShadowBzObjectVars = {
  type: BzObjType;
  bzObjMemberCode?: number;
  sdObjType?: SdObjType;
  sdObjMemberCode?: number;
  name?: string | null;
  apCode?: number | null;
};

export type CreateShadowBzObjectResponse = {
  createShadowBzObject: ModifyShadowBzObjectResult;
};

export const modifyShadowBzObjectAddPositionGraphQl = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) => gql`
mutation ModifyShadowBzObjectAddPosition(
  $id: ID!
  $positionType: ErpPositionsArt
  $articleId: Int
  $articleIds: [Int]
  $amount: Decimal
  $bomStyle: InsertBomStyle
  $defaultText: String
  $index: Int
  $localId: String
  $copyPositions: [CopyPosition]
) {
  modifyShadowBzObjectAddPosition(
    id: $id
    positionsArt: $positionType
    artikelCode: $articleId
    artikelCodes: $articleIds
    anzahl: $amount
    bomStyle: $bomStyle
    defaultText: $defaultText
    index: $index
    altId: $localId
    copyPositions: $copyPositions
  ) {
    ${createShadowBzObjectResponeGraphql(entity, erpCommonProps)}
  }
}
`;

export type ModifyShadowBzObjectAddPositionResponse = {
  modifyShadowBzObjectAddPosition: ModifyShadowBzObjectResult;
};

export type ModifyShadowBzObjectAddPositionVars = {
  id: string | number;
  positionType: ErpPositionsKind;
  articleId?: number | null;
  articleIds?: number[] | undefined;
  amount?: number | null;
  bomStyle?: InsertBomStyle;
  defaultText?: string;
  index?: number;
  localId?: string;
  copyPositions?: { positionCode: number }[];
};

export const modifyShadowBzObjectRemovePositionGraphQl = (
  entity: Entities
) => gql`
mutation ModifyShadowBzObjectRemovePosition($id: ID!, $positionId: Int!, $positionIds: [Int]) {
  modifyShadowBzObjectRemovePosition(id: $id, positionCode: $positionId,  positionCodes: $positionIds) {
    ${createShadowBzObjectResponeGraphql(entity)}
  }
}
`;

export type ModifyShadowBzObjectRemovePositionResponse = {
  modifyShadowBzObjectRemovePosition: ModifyShadowBzObjectResult;
};

export type ModifyShadowBzObjectRemovePositionVars = {
  id: string | number;
  positionId?: number;
  positionIds: number[];
};

export const modifyShadowObjectMovePositionGraphQl = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) => gql`
mutation ModifyShadowObjectMovePosition(
  $id: ID!
  $positionId: Int!
  $index: Int!
) {
  modifyShadowBzObjectMovePosition(
    id: $id
    positionCode: $positionId
    newIndex: $index
  ) {
    ${createShadowBzObjectResponeGraphql(entity, erpCommonProps)}
  }
}
`;

export type ModifyShadowBzObjectMovePositionResponse = {
  modifyShadowBzObjectMovePosition: ModifyShadowBzObjectResult;
};

export type ModifyShadowBzObjectMovePositionVars = {
  id: string | number;
  positionId: number;
  index: number;
};

const shadowBzObjectResponeGraphQlWithId = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) =>
  prepareResponse(
    Entities.modifyShadowBzObjectResult,
    createResponse(entity, { id: null, ...erpCommonProps })
  );

export const persistShadowBzObjectGraphQl = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) => gql`
  mutation PersistShadowBzObject($id: ID! $attachements: InputErpAnhangAttachementsRelation, $relations: BzObjectRelation) {
    persistShadowBzObject(id: $id, attachements: $attachements, relations: $relations, removeShadowBzObject: false) {
      ${shadowBzObjectResponeGraphQlWithId(entity, erpCommonProps)}
    }
  }
`;

export type PersistShadowBzObjectResponse = {
  persistShadowBzObject: ModifyShadowBzObjectResult;
};

export type PersistShadowBzObjectVars = {
  id: string;
  attachements?: InputErpAnhangAttachementsRelation;
  relations: BzObjectRelation;
};

export const DELETE_SHADOW_BZ_OBJECT = gql`
  mutation DeleteShadowBzObject($id: ID!) {
    deleteShadowBzObject(id: $id)
  }
`;

export type DeleteShadowBzObjectResponse = {
  deleteShadowBzObject: ModifyShadowBzObjectResult;
};

export type DeleteShadowBzObjectVars = {
  id: string | number;
};

export const modifyShadowBzObjectModifyPositionGraphQl = (
  entity: Entities,
  erpCommonProps: ModifyShadowBzObjectResult['data'] = {}
) => gql`
  mutation ModifyShadowBzObjectModifyPosition(
    $id: ID!
    $position: InputPosition!
  ) {
    modifyShadowBzObjectModifyPosition(id: $id, position: $position) {
      ${createShadowBzObjectResponeGraphql(entity, erpCommonProps)}
    }
  }
`;

export type ModifyShadowBzObjectModifyPositionResponse = {
  modifyShadowBzObjectModifyPosition: ModifyShadowBzObjectResult;
};

export type ModifyShadowBzObjectModifyPositionVars = {
  id: string | number;
  position: Position;
};
