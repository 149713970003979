import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse/Collapse';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input/LabeledInput';

import { useFormContextPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/use-form-context-plus';
import { ProjectProcessFormValue } from '../../types';

export const NoteSection = () => {
  const { t } = useTranslation();

  const { register } = useFormContextPlus<ProjectProcessFormValue>();

  return (
    <Collapse title={t('COMMON.NOTE')} defaultOpen={false}>
      <LabeledInput
        {...register('comment')}
        label={t('COMMON.NOTE')}
        multiline
        style={{
          height: '7.25rem',
        }}
      />
    </Collapse>
  );
};
