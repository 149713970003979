import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ProjectStepLinkType } from '../Enums/ProjectStepLinkType.enum';

import { ProjectProcess } from './ProjectProcess.entity';

export class ProjectProcessLink<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: projectStep1Code */
  projectProcess1Id?: number;
  /** Alias: projectStep2Code */
  projectProcess2Id?: number;
  /** Alias: linkType */
  linkType?: ProjectStepLinkType;
  /** Alias: projectCode */
  projectId?: number;
  /** Alias: projectStep1 */
  projectStep1?: ProjectProcess<T>;
  /** Alias: projectStep2 */
  projectStep2?: ProjectProcess<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectProcessLink> = {
  id: {
    alias: 'code',
  },
  projectProcess1Id: {
    alias: 'projectStep1Code',
  },
  projectProcess2Id: {
    alias: 'projectStep2Code',
  },
  linkType: {
    alias: 'linkType',
  },
  projectId: {
    alias: 'projectCode',
  },
  projectStep1: {
    alias: 'projectStep1',
    entity: Entities.projectProcess,
  },
  projectStep2: {
    alias: 'projectStep2',
    entity: Entities.projectProcess,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectProcessLinkEntityDefinition: EntitiyDefinition<ProjectProcessLink> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProjectStepLink',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
