import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UsePreviewInputSnackbarProps } from '../../types';

export const usePreviewInputSnackbar = ({
  locked: isLocked,
  lockedByUser,
  canEditAllSelected,
}: UsePreviewInputSnackbarProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { isError, message } = useMemo(() => {
    let isError = false;
    let message = null;

    if (!canEditAllSelected) {
      isError = true;
      message = `${t('MASK.EDITING_NOT_POSSIBLE')} | ${t(
        'COMMON.NO_EDIT_RIGHT'
      )}`;
    }

    if (isLocked) {
      isError = true;
      message = `${t('MASK.EDITING_NOT_POSSIBLE')} | ${t(
        'MASK.MASK_OPENED_BY',
        {
          userName: lockedByUser?.displayName,
        }
      )}`;
    }

    return { isError, message: message };
  }, [canEditAllSelected, isLocked, lockedByUser?.displayName, t]);

  const showError = useCallback(() => {
    enqueueSnackbar(message, {
      variant: 'warning',
    });
  }, [enqueueSnackbar, message]);

  return { showError, isError };
};
