import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ErpObjectConversionRequestOptions<T extends EMode = EMode.entity> {
  /** Alias: refreshHeadAndClosingText */
  refreshHeadAndClosingText?: boolean;
  /** Alias: createHistoryPosition */
  createHistoryPosition?: boolean;
  /** Alias: userOnlyBacklogPositions */
  userOnlyBacklogPositions?: boolean;
  /** Alias: createMultipleObjects */
  createMultipleObjects?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<ErpObjectConversionRequestOptions> = {
  refreshHeadAndClosingText: {
    alias: 'refreshHeadAndClosingText',
  },
  createHistoryPosition: {
    alias: 'createHistoryPosition',
  },
  userOnlyBacklogPositions: {
    alias: 'userOnlyBacklogPositions',
  },
  createMultipleObjects: {
    alias: 'createMultipleObjects',
  },
  __typename: {
    alias: '__typename',
  },
};

export const erpObjectConversionRequestOptionsEntityDefinition: EntitiyDefinition<ErpObjectConversionRequestOptions> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ErpObjectConversionRequestOptions',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
