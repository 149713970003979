import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useFormPlus } from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import {
  Form,
  MaskOverlayHeader,
  MaskOverlayStandardSubmitButton,
} from '../../../components';
import {
  MaskContextProvider,
  useMaskConfig,
} from '../../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../../hooks/use-confrm-before-close-mask';
import { MUTATION_ENTITY_BY_VIEW_ENTITY } from '../constants';
import { usePaymentData, usePaymentMutations } from '../hooks';
import { Payment, PaymentMaskFormValue, PaymentMaskProps } from '../types';

import { GeneralSection } from './GeneralSection';
import { TableSection } from './TableSection';

export const PaymentMask = ({ id, entity }: PaymentMaskProps) => {
  const { t } = useTranslation();
  const { onClose } = useHistoryStack();
  const mask = useMaskConfig({ entity, id });
  const { data, refetch } = usePaymentData({ id, entity });

  const form = useFormPlus<PaymentMaskFormValue>({
    mode: 'onChange',
    defaultValues: data,
  });

  const { reset, handleSubmit } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const { addAndModify } = usePaymentMutations({
    entity,
    invoiceId: data.id,
    onCompleted: () => {
      onClose();
    },
  });
  const onSubmit = useCallback(
    (data: PaymentMaskFormValue) => {
      addAndModify(data.payments as Payment[]);
    },
    [addAndModify]
  );

  useEntityEvents((event) => {
    const mutationEntity = MUTATION_ENTITY_BY_VIEW_ENTITY[entity];
    if (mutationEntity === event.entity) {
      refetch();
    }
  });

  const { isDirty } = form.formState;

  useConfirmBeforeCloseMask(isDirty);

  return (
    <FormProvider {...form}>
      <MaskContextProvider value={mask}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MaskOverlayHeader
            title={t('INPUTS.OPEN_PAYMENT_MASK')}
            subTitle={
              data?.number
                ? `${data?.number} ${data?.note ? `| ${data.note}` : ''}`
                : ''
            }
            actions={<MaskOverlayStandardSubmitButton />}
          />
          <GeneralSection />
          <TableSection />
        </Form>
      </MaskContextProvider>
    </FormProvider>
  );
};
