import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnInstance,
  FilterType,
  usePrepareFilters,
  useTableStateBag,
} from '@work4all/components';
import { getTimeSpanUnit } from '@work4all/components/lib/dataDisplay/basic-table/components/date-range-picker/DateRangePicker';
import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';
import { CheckListField } from '@work4all/components/lib/input/check-list-field/CheckListField';

import { useDataProvider, useDeleteEntity, useUser } from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { ObjectTypeByEntity } from '@work4all/models';
import { SavedListFilter } from '@work4all/models/lib/Classes/SavedListFilter.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useConfirmDeleteEntities } from '../../../containers/mask-overlays/mask-overlay/hooks/use-confirm-delete-entities';
import { deserializeFilter } from '../../../utils/deserializeFilter';
import { parseTableSettings } from '../../data-tables/make-table-settings';
import { tryParseSettings } from '../../list-page/ListPage';

import { CheckListLabel } from './components/CheckListLabel';
import { CreateEditFilter } from './components/CreateEditFilter';
import { CreateFilterButton } from './components/CreateFilterButton';
import { CreateFilterIconButton } from './components/CreateFilterIconButton';
import { VERSION } from './constants/version';
import { CreateEditFilterProps } from './interfaces/CreateEditFilterProps';
import { parseFilterConfig } from './utils/parseFilterConfig';

type id = number | string;
interface FilterActionsProps {
  id: id;
  onEdit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: id
  ) => void;
  onDelete: (id: id) => void;
  disabled?: boolean;
}

interface ColumnFilterType {
  filterType?: FilterType;
}

interface Column extends Omit<ColumnInstance, 'filterType'>, ColumnFilterType {}

const DEFAULT_VALUE = [];
const NumberOfResults = ({
  prefilter,
  settings,
  entity,
}: {
  settings: string;
  entity: Entities;
  prefilter: unknown[];
}) => {
  const {
    tableInstance: { allColumns },
  } = useTableStateBag();

  const { filter: parsedSettingsFilters = DEFAULT_VALUE } = useMemo(() => {
    return parseTableSettings(settings) || {};
  }, [settings]);

  const filters = useMemo(() => {
    return parsedSettingsFilters
      .filter((filter) => {
        const column: Column = allColumns.find(
          (column) => column.id === filter.id
        );

        return column && column?.filterType;
      })
      .map((filter) => {
        const column: Column = allColumns.find(
          (column) => column.id === filter.id
        );

        const deserialize = deserializeFilter(filter, column.filterType);

        return {
          id:
            !column?.filterField || column.filterField.length === 0
              ? filter.id
              : column.filterField,
          value: {
            ...deserialize,
            filterType: column.filterType,
          },
        };
      });
  }, [allColumns, parsedSettingsFilters]);

  const { filter: preparedFilters = DEFAULT_VALUE } = usePrepareFilters(
    filters,
    undefined,
    undefined,
    entity
  );

  const requestData = useMemo(
    () => ({
      entity: entity,
      data: {},
      filter: [...preparedFilters, ...(prefilter ?? [])],
    }),
    [preparedFilters, entity, prefilter]
  );

  const result = useDataProvider(requestData);
  return <CountIndicator variant="secondary" value={result.total} />;
};

const FilterActions = ({
  id,
  onEdit,
  onDelete,
  disabled = false,
}: FilterActionsProps) => {
  const confirmDeleteEntities = useConfirmDeleteEntities();

  const handleDelete = useCallback(
    (id: id) => {
      confirmDeleteEntities().then((confirmed) => {
        if (confirmed) {
          onDelete(id);
        }
      });
    },
    [confirmDeleteEntities, onDelete]
  );

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={(e) => onEdit(e, id)}
        disabled={disabled}
      >
        <EditIcon />
      </IconButton>

      <IconButton
        size="small"
        color="primary"
        onClick={() => handleDelete(id)}
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};

interface Props<T> {
  value?: T & { id?: number | string; __typename?: string };
  onChange?: (value: T) => void;
  entity: Entities;
  hidePersonalFilters?: boolean;
  hideGeneralFilters?: boolean;
}

export const PersonalAndGeneralView = <T,>({
  value,
  onChange,
  entity,
  hidePersonalFilters = false,
  hideGeneralFilters = false,
}: Props<T>) => {
  const user = useUser();
  const { t } = useTranslation();
  const [createOrEditData, setCreateOrEditData] =
    useState<
      Pick<CreateEditFilterProps, 'target' | 'defaultValues' | 'filtersList'>
    >();
  const [deleteEntity] = useDeleteEntity();
  const tableStateBag = useTableStateBag();
  const [selectedValue, setSelectedValue] = useState<SavedListFilter>();

  const { personalRequestData, generalRequestData } = useMemo(() => {
    const data: SavedListFilter = {
      id: null,
      name: null,
      userId: null,
      global: null,
      filter: null,
    };

    const common: DataRequest = {
      entity: Entities.savedListFilter,
      data,
    };

    const commonFilters = [
      { objectType: { $eq: ObjectTypeByEntity[entity] } },
      { version: { $eq: VERSION } },
    ];

    const personalRequestData: DataRequest = {
      ...common,
      filter: [
        ...commonFilters,
        { global: { $eq: false } },
        { userId: { $eq: user.benutzerCode } },
      ],
      sort: [
        {
          field: 'name',
          direction: SortDirection.ASCENDING,
        },
      ],
    };

    const generalRequestData: DataRequest = {
      ...common,
      filter: [...commonFilters, { global: { $eq: true } }],
      sort: [
        {
          field: 'name',
          direction: SortDirection.ASCENDING,
        },
      ],
    };

    return { personalRequestData, generalRequestData };
  }, [user.benutzerCode, entity]);

  const { data: personalFilters, refetch: refetchPersonalFilters } =
    useDataProvider<SavedListFilter>(personalRequestData);
  const { data: generalFilters, refetch: refetchGeneralFilters } =
    useDataProvider<SavedListFilter>(generalRequestData);

  const onCreate = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      global: SavedListFilter['global']
    ) => {
      setCreateOrEditData({
        target: event.target as Element,
        defaultValues: {
          global: global,
        },
        filtersList: global ? generalFilters : personalFilters,
      });
    },
    [generalFilters, personalFilters]
  );

  const onEdit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: id) => {
      const filter = [...personalFilters, ...generalFilters].find(
        (filter) => filter.id === id
      );

      setCreateOrEditData({
        target: event.target as Element,
        defaultValues: filter,
        filtersList: filter.global ? generalFilters : personalFilters,
      });
    },
    [generalFilters, personalFilters]
  );

  const onDelete = useCallback(
    (id: id) => {
      deleteEntity({
        type: Entities.savedListFilter,
        ids: [id],
      });
    },
    [deleteEntity]
  );

  const onChangeHandler = useCallback(
    (views: SavedListFilter[]) => {
      const view = views[0];

      setSelectedValue(view);
      onChange?.(view as T);

      const {
        filter: settings,
        groupBy,
        visibleColumns,
      } = parseFilterConfig(view.filter);

      const { filter = [], sort = [] } = tryParseSettings(settings);

      tableStateBag.tableInstance.setSortBy(sort);
      tableStateBag.tableInstance.setAllFilters([]);
      tableStateBag.tableInstance.allColumns.forEach(
        (column: ColumnInstance) => {
          const filterValue = filter.find(
            (_column) => column.id === _column.id
          )?.value;

          if (filterValue) {
            if (column.filterType === FilterType.Date) {
              const startDate = filterValue?.startDate
                ? isNaN(Date.parse(filterValue.startDate))
                  ? filterValue.startDate
                  : new Date(filterValue.startDate)
                : undefined;

              const endDate = filterValue?.endDate
                ? isNaN(Date.parse(filterValue.endDate))
                  ? filterValue.endDate
                  : new Date(filterValue.endDate)
                : undefined;

              column.setFilter({
                value: {
                  startDate: startDate,
                  endDate: endDate,
                  span: getTimeSpanUnit(startDate, endDate),
                },
                filterType: column.filterType,
              });
            } else {
              column.setFilter({
                value: filterValue,
                filterType: column.filterType,
              });
            }
          }

          tableStateBag.tableInstance.toggleGroupBy(
            column.id,
            groupBy.includes(column.id)
          );

          tableStateBag.tableInstance.toggleHideColumn(
            column.id,
            !visibleColumns.includes(column.id)
          );
        }
      );
    },
    [onChange, tableStateBag.tableInstance]
  );

  const renderPersonalFilters = useMemo(() => {
    if (hidePersonalFilters) return null;

    return (
      <>
        <CheckListField
          onChange={onChangeHandler}
          all={personalFilters}
          value={[selectedValue]}
          renderSideElementOnHover={(id) => (
            <FilterActions id={id} onEdit={onEdit} onDelete={onDelete} />
          )}
          labelElement={
            <CheckListLabel
              value={t('TICKET.PERSONAL_FILTERS')}
              rightElement={
                <CreateFilterIconButton onClick={(e) => onCreate(e, false)} />
              }
            />
          }
          renderIndicator={(id) => {
            const settings = parseFilterConfig(
              personalFilters.find((filter) => filter.id === id).filter
            );

            return (
              <NumberOfResults
                prefilter={settings.prefilter}
                settings={settings.filter}
                entity={entity}
              />
            );
          }}
          hideCheckboxs
        />
        {personalFilters.length === 0 ? (
          <CreateFilterButton onClick={(e) => onCreate(e, false)}>
            {t('TICKET.ADD_PERSONAL_FILTER')}
          </CreateFilterButton>
        ) : null}
      </>
    );
  }, [
    onChangeHandler,
    personalFilters,
    selectedValue,
    t,
    onEdit,
    onDelete,
    onCreate,
    entity,
    hidePersonalFilters,
  ]);

  const renderGeneralFilters = useMemo(() => {
    if (hideGeneralFilters) return null;

    return (
      <>
        <CheckListField
          onChange={onChangeHandler}
          all={generalFilters}
          value={[selectedValue]}
          renderSideElementOnHover={(id) => (
            <FilterActions
              id={id}
              onEdit={onEdit}
              onDelete={onDelete}
              disabled={!user.isMaster}
            />
          )}
          labelElement={
            <CheckListLabel
              value={t('TICKET.GENERAL_FILTERS')}
              rightElement={
                <CreateFilterIconButton
                  onClick={(e) => onCreate(e, true)}
                  disabled={!user.isMaster}
                />
              }
            />
          }
          renderIndicator={(id) => {
            const settings = parseFilterConfig(
              generalFilters.find((filter) => filter.id === id).filter
            );
            return (
              <NumberOfResults
                prefilter={settings.prefilter}
                settings={settings.filter}
                entity={entity}
              />
            );
          }}
          hideCheckboxs
        />
        {generalFilters.length === 0 ? (
          <CreateFilterButton
            onClick={(e) => onCreate(e, true)}
            disabled={!user.isMaster}
          >
            {t('TICKET.ADD_GENERAL_FILTER')}
          </CreateFilterButton>
        ) : null}
      </>
    );
  }, [
    onChangeHandler,
    generalFilters,
    selectedValue,
    t,
    user.isMaster,
    onEdit,
    onDelete,
    onCreate,
    entity,
    hideGeneralFilters,
  ]);

  useEntityEvents((event) => {
    if (event.entity === Entities.savedListFilter) {
      refetchPersonalFilters();
      refetchGeneralFilters();
    }
  });

  useEffect(() => {
    if (!value) return;
    const _value = [...personalFilters, ...generalFilters].find(
      (filter) =>
        filter.id === value?.id && filter.__typename === value?.__typename
    );

    if (!_value) setSelectedValue(undefined);
  }, [generalFilters, personalFilters, value]);

  return (
    <>
      <div>
        {generalFilters.length === 0 || personalFilters.length > 0 ? (
          <>
            {renderPersonalFilters}
            {renderGeneralFilters}
          </>
        ) : (
          <>
            {renderGeneralFilters}
            {renderPersonalFilters}
          </>
        )}
      </div>

      {createOrEditData && (
        <CreateEditFilter
          {...createOrEditData}
          onClose={() => {
            setCreateOrEditData(undefined);
          }}
          entity={entity}
        />
      )}
    </>
  );
};
