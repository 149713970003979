import { useEffect, useRef, useState } from 'react';

import { useHttpClient } from '@work4all/data';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailAttachment } from '@work4all/models/lib/Classes/EMailAttachment.entity';

import { EmailPreview } from '../../components/entity-preview/email-preview';
import { IUsePreview } from '../use-preview';

export type MSGPreviewProps = {
  url: string;
} & Partial<IUsePreview['props']>;

interface EmailPreview {
  Attachements: {
    DownloadMimeType: string;
    DownloadUrl: string;
    DownloadUrlForPreview: string;
    FileEntityFilename: string;
    FileSize: number;
    PreviewMimeType: string;
  }[];
  Body: string;
  CC: string;
  To: string;
  BCC: string;
  Sender: string;
  Subject: string;
}

export function MsgPreview({ url }: MSGPreviewProps) {
  const cachedUrl = useRef('');
  const httpClient = useHttpClient();
  const [emailData, setEmailData] = useState<EMail>({});

  useEffect(() => {
    if (emailData.bodyHtml && url === cachedUrl.current) return;
    cachedUrl.current = url;
    fetchPreview(url);
  }, [url, emailData]);

  async function fetchPreview(url) {
    const data = await httpClient.get<EmailPreview>({
      url,
    });

    const dataCc = data.data.CC.toString();
    const dataTo = data.data.To.toString();
    const baseURL = url.split('/api/')[0];

    const previewEmail: EMail = {
      bodyHtml: data.data.Body,
      rewrittenBodyHtml: data.data.Body,
      to: dataTo,
      attachmentList: data.data.Attachements.map<EMailAttachment>(
        (attachment, index) => {
          return {
            fileName: attachment.FileEntityFilename,
            id: index,
            displayFilename: attachment.FileEntityFilename,
            fileInfos: {
              downloadMimeType: attachment.PreviewMimeType,
              downloadUrl: `${baseURL}/api${attachment.DownloadUrl}`,
              previewUrl: `${baseURL}/api/${attachment.DownloadUrlForPreview}`,
              previewMimeType: attachment.PreviewMimeType,
              fileSize: attachment.FileSize,
            },
          };
        }
      ),
      bcc: data.data.BCC,
      from: data.data.Sender,
      subject: data.data.Subject,
      cc: dataCc,
    };
    setEmailData(previewEmail);
  }

  return (
    <EmailPreview
      email={emailData}
      hideHeader={true}
      disableConvertingAttachments
    />
  );
}
