import { ArrowDropDown } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

export type WorkDayHoursUnit = 'day' | 'hour' | 'month';

const availableUnits: WorkDayHoursUnit[] = ['day', 'hour', 'month'];

export interface WorkDayHoursFieldProps {
  valueDays: number;
  unit?: WorkDayHoursUnit;
  hourToDayRatio?: number;
  label?: string;
  onValueChange: (days: number) => void;
  onUnitChange?: (unit: WorkDayHoursUnit) => void;
}

export const WorkDayHoursField = (props: WorkDayHoursFieldProps) => {
  const { t } = useTranslation();
  const {
    hourToDayRatio = 8,
    unit: initialUnit = 'day',
    valueDays,
    label = t('COMMON.PLANNING_VALUE'),
    onValueChange,
    onUnitChange,
  } = props;

  const [unit, setUnit] = useState<WorkDayHoursUnit>('day');
  const [selectOpen, setSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    setUnit(initialUnit);
  }, [initialUnit]);

  const handleValueChange = useCallback(
    (val: number) => {
      if (unit === 'hour') {
        onValueChange(val / hourToDayRatio);
      }
      if (unit === 'month') {
        onValueChange(val * 20);
      }
      if (unit === 'day') {
        onValueChange(val);
      }
    },
    [hourToDayRatio, onValueChange, unit]
  );

  const displayValue = useMemo<number>(() => {
    if (unit === 'hour') {
      return valueDays * hourToDayRatio;
    }
    if (unit === 'month') {
      return valueDays / 20;
    }

    return valueDays;
  }, [hourToDayRatio, unit, valueDays]);

  const getUnitText = useCallback(
    (props: { unit: WorkDayHoursUnit; plural?: boolean; short?: boolean }) => {
      if (props.short) {
        return t(`COMMON.${props.unit.toUpperCase()}_SHORT`);
      }
      return t(
        `COMMON.${props.unit.toUpperCase()}${props.plural ? '_plural' : ''}`
      );
    },
    [t]
  );

  const selectButton = useRef(null);

  return (
    <Stack direction="row">
      <LabeledInput
        value={displayValue}
        type="number"
        label={label}
        onChange={(e) => {
          handleValueChange(parseFloat(e.target.value.toString()));
        }}
        endAdornment={
          <Stack
            ref={selectButton}
            mt={label ? '1.125rem' : '0'}
            pl="0.25rem"
            direction="row"
            color="var(--text03)"
            onClick={onUnitChange ? () => setSelectOpen(true) : undefined}
            style={{
              cursor: onUnitChange ? 'pointer' : undefined,
            }}
          >
            {getUnitText({ unit, plural: displayValue !== 1 })}
            {onUnitChange ? <ArrowDropDown fontSize="small" /> : null}
          </Stack>
        }
      />
      <Popover
        open={selectOpen}
        anchorEl={selectButton.current}
        anchorPosition={{ top: 0, left: 0 }}
      >
        <List disablePadding>
          {availableUnits.map((u) => {
            return (
              <ListItemButton
                key={u}
                onClick={() => {
                  setUnit(u);
                  setSelectOpen(false);
                  onUnitChange?.(u);
                }}
              >
                <Typography>
                  {getUnitText({ unit: u, plural: true })}
                </Typography>
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </Stack>
  );
};
