import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function getRequiredEntity(entity: Entities): Entities {
  const combinedBusinessPartnerEntities = [Entities.deliveryNote];
  const supplierEntities = [
    Entities.order,
    Entities.demand,
    Entities.inboundDeliveryNote,
  ];

  if (combinedBusinessPartnerEntities.includes(entity)) {
    return undefined;
  }
  if (supplierEntities.includes(entity)) {
    return Entities.supplier;
  }
  return Entities.customer;
}
