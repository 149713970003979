import { Stack, Typography } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useMemo } from 'react';

import { Country } from '@work4all/models/lib/Classes/Country.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { UserIcon } from '../../user-icon/UserIcon';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ICountryPickerProps<TMultiple extends boolean> = IPickerProps<
  Country,
  TMultiple
>;

export function CountryPicker<TMultiple extends boolean>(
  props: ICountryPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...COUNTRY_FIELDS, ...data };
  }, [data]);

  const renderCountry = (country: Country) => {
    return (
      <Tooltip title={country.longtext}>
        <Stack direction="row">
          <ListItemAvatar>
            <UserIcon shortName={country.shortName} size="m" />
          </ListItemAvatar>

          <Typography variant="body2" noWrap>
            {country.longtext}
          </Typography>
        </Stack>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.country}
      data={allFields}
      filterBy={['shortName', 'longtext']}
      sortBy="longtext"
      {...rest}
      renderItemContent={renderCountry}
    />
  );
}
const COUNTRY_FIELDS: Country = {
  id: null,
  longtext: null,
  shortName: null,
  anglosaxon: null,
};
