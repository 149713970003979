import { useTranslation } from 'react-i18next';

type Props = {
  value: boolean;
};

export const AccountingLockCell = ({ value }: Props) => {
  const { t } = useTranslation();

  const textValue = value ? t('COMMON.LOCKED') : t('COMMON.OPEN');

  return textValue.toUpperCase();
};
