import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  formatVatRate,
  VatPicker,
} from '@work4all/components/lib/components/entity-picker/vat-picker/VatPicker';

import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';

import { PickerTargetButton } from './PickerTargetButton';

export function VatRateCell(props: {
  disabled?: boolean;
  value: VatRate;
  onChange: (value: VatRate | null) => void;
}) {
  const { disabled = false, value, onChange } = props;

  return (
    <EntityPickerPopover
      picker={
        <VatPicker
          clearable={false}
          value={value}
          onChange={onChange}
          multiple={false}
        />
      }
    >
      <PickerTargetButton disabled={disabled}>
        {formatVatRate(value)}
      </PickerTargetButton>
    </EntityPickerPopover>
  );
}
