import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { LayoutType } from '../Enums/LayoutType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputLayout<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: layoutType */
  layoutType?: LayoutType;
  /** Alias: name */
  name?: string;
  /** Alias: defaultLayout */
  defaultLayout?: boolean;
  /** Alias: definition */
  definition?: string;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputLayout> = {
  id: {
    alias: 'id',
  },
  layoutType: {
    alias: 'layoutType',
  },
  name: {
    alias: 'name',
  },
  defaultLayout: {
    alias: 'defaultLayout',
  },
  definition: {
    alias: 'definition',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputLayoutEntityDefinition: EntitiyDefinition<InputLayout> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputLayout',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
