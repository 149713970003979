import { useContext } from 'react';

import { ConvertInvoiceToRaContext } from '../contexts';

export const useConvertInvoiceToRaContext = () => {
  const ctx = useContext(ConvertInvoiceToRaContext);

  if (!ctx) {
    throw new Error(
      'useConvertInvoiceToRaContext should be used inside ConvertInvoiceToRaProvider'
    );
  }

  return ctx;
};
