import styles from './styles.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type IDialogProps = {
  title?: string | React.ReactNode;
  icon?: JSX.Element;
  open: boolean;
  onClose?: (
    event,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'
  ) => void;
  onConfirm?: (e) => void;
  /**
   * Whether or not to show the "X" button in hte next to the dialog title.
   *
   * @default true
   */
  closeButton?: boolean;
  confirmDisabled?: boolean;
  children?: React.ReactNode;
  classes?: {
    dialog?: DialogProps['classes'];
    header?: string;
  };
};

export function Dialog(props: IDialogProps) {
  const {
    title,
    icon,
    open,
    onClose,
    onConfirm,
    closeButton = true,
    confirmDisabled = false,
    children,
  } = props;

  const { t } = useTranslation();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      classes={{
        ...props.classes?.dialog,
        paper: clsx(styles['dialog'], props.classes?.dialog?.paper),
      }}
    >
      <div className={clsx(styles['dialog-header'], props.classes?.header)}>
        <MuiDialogTitle className={styles['dialog-title']}>
          {icon}
          {title}
        </MuiDialogTitle>

        {onConfirm && (
          <Button
            disabled={confirmDisabled}
            size="large"
            color="primary"
            onClick={onConfirm}
            startIcon={<CheckIcon />}
          >
            {t('ALERTS.BTN_APPLY')}
          </Button>
        )}

        {closeButton && (
          <IconButton
            className={styles['dialog-button']}
            onClick={(e) => onClose(e, 'closeButtonClick')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>

      {children}
    </MuiDialog>
  );
}
