import { useEventCallback } from '@mui/material/utils';
import { useCallback, useMemo } from 'react';

import {
  useDataMutation,
  useDataProvider,
  useHttpClient,
  useUser,
} from '@work4all/data';
import { uploadTempFile } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { resizeImage } from '../components/user-menu/views/Profile/components/Avatar/utils/resizeImage';

type ImageType = 'image/jpeg' | 'image/png' | 'image/gif';

const EMPTY_OBJECT_KEY = '00000000-0000-0000-0000-000000000000';

export const useAvatar = () => {
  const httpClient = useHttpClient();
  const user = useUser();

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      data: {
        id: null,
        avatarUrl: null,
      },
      filter: [{ id: { $eq: user.benutzerCode } }],
    };
  }, [user]);

  const response = useDataProvider(requestData);

  const avatarUrl = response.data?.[0]?.avatarUrl;

  const [mutate] = useDataMutation({
    entity: Entities.user,
    mutationType: EMode.upsert,
    responseData: { id: null, avatarUrl: null },
  });

  const handleRemoveAvatar = useCallback(() => {
    mutate(
      { id: user.benutzerCode },
      {
        relations: {
          userImageFromTempFile: EMPTY_OBJECT_KEY,
        },
      }
    );
  }, [mutate, user.benutzerCode]);

  const handleUploadAvatar = useEventCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        const validImageTypes: ImageType[] = [
          'image/jpeg',
          'image/png',
          'image/gif',
        ];

        if (!validImageTypes.includes(file.type as ImageType)) return;

        const resizedImage = await resizeImage(file);

        const uploadResponse = await uploadTempFile(
          resizedImage,
          httpClient,
          user.baseUrl
        );

        if (uploadResponse && uploadResponse.data) {
          const objectKey = uploadResponse.data.generatedObject;
          await mutate(
            { id: user.benutzerCode },
            {
              relations: {
                userImageFromTempFile: objectKey,
              },
            }
          );
        }
      }
    }
  );

  return {
    uploadAvatar: handleUploadAvatar,
    removeAvatar: handleRemoveAvatar,
    avatarUrl,
  };
};
