import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ArticlePurchaseInformationObjectType } from '../Enums/ArticlePurchaseInformationObjectType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Currency } from './Currency.entity';
import { Supplier } from './Supplier.entity';

export class ArticlePurchaseInformation<T extends EMode = EMode.entity> {
  /** Alias: objectCode */
  objectId?: number;
  /** Alias: purchaseInfoType */
  purchaseInfoType?: ArticlePurchaseInformationObjectType;
  /** Alias: currencyCode */
  currencyId?: number;
  /** Alias: price */
  price?: number;
  /** Alias: supplierCode */
  supplierId?: number;
  /** Alias: objectDate */
  objectDate?: string;
  /** Alias: currency */
  currency?: Currency<T>;
  /** Alias: supplier */
  supplier?: Supplier<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ArticlePurchaseInformation> = {
  objectId: {
    alias: 'objectCode',
  },
  purchaseInfoType: {
    alias: 'purchaseInfoType',
  },
  currencyId: {
    alias: 'currencyCode',
  },
  price: {
    alias: 'price',
  },
  supplierId: {
    alias: 'supplierCode',
  },
  objectDate: {
    alias: 'objectDate',
  },
  currency: {
    alias: 'currency',
    entity: Entities.currency,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  __typename: {
    alias: '__typename',
  },
};

export const articlePurchaseInformationEntityDefinition: EntitiyDefinition<ArticlePurchaseInformation> =
  {
    local: {},
    remote: {
      queryName: 'getArticlePurchaseInformation',
      fragmentName: 'ArticlePurchaseInformation',
      withPaginationWrapper: false,
      params: [{ name: 'articleCode', type: 'Int!' }],
    },
    fieldDefinitions: fields,
  };
