import { useState } from 'react';

export const useDocumentPreview = (filePath: string) => {
  const [detailInFullsceen, setFullscreen] = useState(false);

  const isLinkable =
    typeof filePath === 'string' &&
    (filePath.startsWith('https') ||
      filePath.startsWith('http') ||
      filePath.startsWith('www'));

  return {
    detailInFullsceen,
    setFullscreen,
    isLinkable,
  };
};
