import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Appointment } from './Appointment.entity';
import { Calculation } from './Calculation.entity';
import { CallMemo } from './CallMemo.entity';
import { CheckList } from './CheckList.entity';
import { Contract } from './Contract.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { Document } from './Document.entity';
import { EMail } from './EMail.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { InboundInvoice } from './InboundInvoice.entity';
import { Invoice } from './Invoice.entity';
import { Letter } from './Letter.entity';
import { Note } from './Note.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { Project } from './Project.entity';
import { SalesOpportunities } from './SalesOpportunities.entity';
import { Task } from './Task.entity';
import { Ticket } from './Ticket.entity';
import { TravelExpenses } from './TravelExpenses.entity';
import { VisitationReport } from './VisitationReport.entity';

export type ChronoFileItemUnion<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ?
        | Task<T>
        | CheckList<T>
        | CallMemo<T>
        | Note<T>
        | Project<T>
        | Appointment<T>
        | Ticket<T>
        | VisitationReport<T>
        | Letter<T>
        | Document<T>
        | EMail<T>
        | Offer<T>
        | Contract<T>
        | Calculation<T>
        | DeliveryNote<T>
        | Invoice<T>
        | Demand<T>
        | TravelExpenses<T>
        | Order<T>
        | InboundDeliveryNote<T>
        | InboundInvoice<T>
        | SalesOpportunities<T>
    : {
        /** Alias: Aufgabe */
        task?: Task<T>;
        /** Alias: Checkliste */
        checkList?: CheckList<T>;
        /** Alias: Telefonat */
        callMemo?: CallMemo<T>;
        /** Alias: Notiz */
        note?: Note<T>;
        /** Alias: Projekt */
        project?: Project<T>;
        /** Alias: Termin */
        appointment?: Appointment<T>;
        /** Alias: Ticket */
        ticket?: Ticket<T>;
        /** Alias: Besuchsbericht */
        visitationReport?: VisitationReport<T>;
        /** Alias: Brief */
        letter?: Letter<T>;
        /** Alias: Dokument */
        document?: Document<T>;
        /** Alias: EMail */
        eMail?: EMail<T>;
        /** Alias: Angebot */
        offer?: Offer<T>;
        /** Alias: Auftrag */
        contract?: Contract<T>;
        /** Alias: Kalkulation */
        calculation?: Calculation<T>;
        /** Alias: Lieferschein */
        deliveryNote?: DeliveryNote<T>;
        /** Alias: Rechnung */
        invoice?: Invoice<T>;
        /** Alias: Bedarfsanforderung */
        demand?: Demand<T>;
        /** Alias: Reisekostenabrechnung */
        travelExpenses?: TravelExpenses<T>;
        /** Alias: Bestellung */
        order?: Order<T>;
        /** Alias: Eingangslieferschein */
        inboundDeliveryNote?: InboundDeliveryNote<T>;
        /** Alias: Eingangsrechnung */
        inboundInvoice?: InboundInvoice<T>;
        /** Alias: Verkaufschance */
        salesOpportunities?: SalesOpportunities<T>;
      };

const fields: FieldDefinitions<ChronoFileItemUnion<EMode.query>> = {
  task: {
    alias: 'task',
    entity: Entities.task,
  },
  checkList: {
    alias: 'checkList',
    entity: Entities.checkList,
  },
  callMemo: {
    alias: 'callMemo',
    entity: Entities.callMemo,
  },
  note: {
    alias: 'note',
    entity: Entities.note,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  appointment: {
    alias: 'appointment',
    entity: Entities.appointment,
  },
  ticket: {
    alias: 'ticket',
    entity: Entities.ticket,
  },
  visitationReport: {
    alias: 'visitationReport',
    entity: Entities.visitationReport,
  },
  letter: {
    alias: 'letter',
    entity: Entities.letter,
  },
  document: {
    alias: 'document',
    entity: Entities.document,
  },
  eMail: {
    alias: 'eMail',
    entity: Entities.eMail,
  },
  offer: {
    alias: 'offer',
    entity: Entities.offer,
  },
  contract: {
    alias: 'contract',
    entity: Entities.contract,
  },
  calculation: {
    alias: 'calculation',
    entity: Entities.calculation,
  },
  deliveryNote: {
    alias: 'deliveryNote',
    entity: Entities.deliveryNote,
  },
  invoice: {
    alias: 'invoice',
    entity: Entities.invoice,
  },
  demand: {
    alias: 'demand',
    entity: Entities.demand,
  },
  travelExpenses: {
    alias: 'travelExpenses',
    entity: Entities.travelExpenses,
  },
  order: {
    alias: 'order',
    entity: Entities.order,
  },
  inboundDeliveryNote: {
    alias: 'inboundDeliveryNote',
    entity: Entities.inboundDeliveryNote,
  },
  inboundInvoice: {
    alias: 'inboundInvoice',
    entity: Entities.inboundInvoice,
  },
  salesOpportunities: {
    alias: 'salesOpportunities',
    entity: Entities.salesOpportunities,
  },
};

export const chronoFileItemUnionEntityDefinition: EntitiyDefinition<
  ChronoFileItemUnion<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ChronoFileItemUnion',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
