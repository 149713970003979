import styles from './FileHeader.module.scss';

import { ArrowBack } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';
import { ReactComponent as YellowNoteIcon } from '@work4all/assets/icons/yellow-note.svg';

import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import {
  FileHorizontalSelect,
  FileHorizontalSelectProps,
} from '@work4all/components/lib/input/horizontal-select/custom/FileHorizontalSelect';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';

import { Link, useNavigate } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectProtectionState } from '@work4all/models/lib/Enums/ObjectProtectionState.enum';
import { FileType } from '@work4all/models/lib/File';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { MaskModal } from '../../../../components/entity-picker/MaskModal';

import { FileContext, FilePageViewType } from './FileContext';
import { FilesPagination, IFilesPaginationProps } from './FilesPagination';
import { ObjectProtectionButton } from './ObjectProtectionButton/ObjectProtectionButton';
import { useYellowNoteContext } from './yellow-note/yellow-note-context';

export interface IFileHeaderProps extends FileHorizontalSelectProps {
  onClose: () => void;
  onResetContact: () => void;
  onCloseBaseData: () => void;
  withPaper?: boolean;
  title: string;
  type: FileType;
  selectedContact?: Contact<EMode.entity>;
  pagination?: IFilesPaginationProps;
  activeView?: FilePageViewType;
  subTitle?: string;
}

export const FileHeader = (props: IFileHeaderProps) => {
  const {
    withPaper,
    title,
    pagination,
    selectedContact,
    onClose,
    onResetContact,
    onCloseBaseData,
    activeView = null,
    viewMode,
    setViewMode,
  } = props;

  const { t } = useTranslation();

  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const file = useContext(FileContext);
  const entity = fileTypeToEntity(file.type);

  const navigate = useNavigate();

  const [maskModalOpen, setMaskModalOpen] = useState(false);

  const contextMenuRef = useRef(null);

  const yellowNote = useYellowNoteContext();

  const { objectGroups, objectGroupProtectionState } = useDeepMemo(
    () => ({
      objectGroups: file.data?.objectGroups,
      objectGroupProtectionState: file.data?.objectGroupProtectionState,
    }),
    [file]
  );

  const fileTitleComp = useMemo(() => {
    return (
      <>
        <Typography variant="h3" component="span" color="text.secondary">
          {t(`${entity.toUpperCase()}.PAGE_TITLE`)}
        </Typography>

        <Typography variant="h3" component="span" color={'text.tertiary'}>
          {' | '}
          {title}
        </Typography>
      </>
    );
  }, [entity, t, title]);

  return (
    <>
      <div className={styles['file-header-wrapper']}>
        <div
          className={clsx(
            styles['file-meta'],
            withPaper && styles['file-meta--with-paper']
          )}
        >
          <Stack direction="row" alignItems="center" spacing={1} minWidth={0}>
            {(lg || (!selectedContact && !activeView)) && (
              <IconButton size="large" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
            {!lg && activeView === FilePageViewType.basedata && (
              <IconButton size="large" onClick={onCloseBaseData}>
                <ArrowBack />
              </IconButton>
            )}
            {!lg && selectedContact && (
              <IconButton size="large" onClick={onResetContact}>
                <ArrowBack />
              </IconButton>
            )}

            {lg &&
              (file.data?.objectGroupProtectionState ===
              ObjectProtectionState.LOCKED ? (
                fileTitleComp
              ) : (
                <Link
                  to={`details/${file.type}/${file.id}`}
                  className={styles.editEntityCTA}
                >
                  {fileTitleComp}
                </Link>
              ))}
            {!lg && (
              <div>
                <Typography
                  variant="h4"
                  component="h4"
                  color={'text.secondary'}
                >
                  {(selectedContact &&
                    selectedContact?.name + ' ' + selectedContact?.firstName) ||
                    (activeView === FilePageViewType.basedata &&
                      t('CUSTOMER.BASE_DATA')) ||
                    t(`${entity.toUpperCase()}.PAGE_TITLE`)}
                </Typography>
              </div>
            )}
          </Stack>

          <div
            className={clsx(
              styles['file-meta__right'],
              styles['file-meta__switch-wrapper']
            )}
          >
            <Stack direction="row" alignItems="center">
              {lg && selectedContact && (
                <Stack justifyContent="center" style={{ marginRight: '1rem' }}>
                  <Chip
                    label={selectedContact.displayName}
                    handleDelete={onResetContact}
                  />
                </Stack>
              )}

              {lg && (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap="1rem"
                  paddingRight="1rem"
                >
                  <FileHorizontalSelect
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    layout="button"
                  />
                </Stack>
              )}

              {pagination && !selectedContact && (
                <FilesPagination {...pagination} />
              )}
              <ObjectProtectionButton
                objectGroupProtectionState={objectGroupProtectionState}
                objectGroups={objectGroups}
                entity={Entities[file.type]}
                parentId={file.data?.id}
              />

              {lg && (
                <Tooltip
                  title={t('FILE.YELLOW_NOTE.TOOLTIP', {
                    entity: t(
                      'COMMON.' +
                        (entity === Entities.project
                          ? 'PROJECT'
                          : entity === Entities.supplier
                          ? 'SUPPLIER'
                          : 'CUSTOMER')
                    ),
                  })}
                  placement="bottom-end"
                >
                  <IconButton
                    className={styles.yellowNote}
                    onClick={() => {
                      yellowNote.setOpen(true);
                    }}
                  >
                    <YellowNoteIcon />
                  </IconButton>
                </Tooltip>
              )}

              {!lg && (
                <IconButton
                  ref={contextMenuRef}
                  size="large"
                  color="default"
                  onClick={() => setContextMenuOpen(true)}
                >
                  <MoreVertIcon />
                </IconButton>
              )}

              <NavigationPopover
                anchorEl={contextMenuRef.current}
                open={contextMenuOpen}
                initialView={{
                  view: (
                    <List>
                      {!selectedContact &&
                        file.data?.objectGroupProtectionState !==
                          ObjectProtectionState.LOCKED && (
                          <ListItem
                            button
                            onClick={() => {
                              navigate(`details/${file.type}/${file.id}`);
                              setContextMenuOpen(false);
                            }}
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText>{t('COMMON.EDIT')}</ListItemText>
                          </ListItem>
                        )}
                      {selectedContact && (
                        <ListItem
                          button
                          onClick={() => {
                            navigate(`details/contact/${selectedContact?.id}`);
                            setContextMenuOpen(false);
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText>{t('COMMON.EDIT')}</ListItemText>
                        </ListItem>
                      )}
                      {!selectedContact && (
                        <>
                          <ListItem
                            button
                            onClick={() => {
                              navigate(`details/file-settings/${entity}`);
                            }}
                          >
                            <ListItemIcon>
                              <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText>{t('COMMON.SETTINGS')}</ListItemText>
                          </ListItem>
                          {!lg && (
                            <ListItemButton
                              disabled={yellowNote.isOpen}
                              onClick={() => {
                                yellowNote.setOpen(true);
                                setContextMenuOpen(false);
                              }}
                            >
                              <ListItemIcon>
                                <YellowNoteIcon />
                              </ListItemIcon>
                              <ListItemText>
                                {t('FILE.YELLOW_NOTE.TITLE')}
                              </ListItemText>
                            </ListItemButton>
                          )}
                        </>
                      )}
                      {!lg && (
                        <>
                          <Divider style={{ padding: '0 0 0.5rem 0' }} />
                          <ListItem
                            button
                            onClick={() => {
                              setContextMenuOpen(false);
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                justifyItems: 'center',
                              }}
                            >
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                gap="1rem"
                              >
                                <FileHorizontalSelect
                                  viewMode={viewMode}
                                  setViewMode={setViewMode}
                                />
                              </Stack>
                            </Box>
                          </ListItem>
                        </>
                      )}
                    </List>
                  ),
                }}
                onClose={() => {
                  setContextMenuOpen(false);
                }}
              />
            </Stack>
          </div>
        </div>
      </div>

      <MaskModal
        entity={entity}
        open={entity != null && maskModalOpen}
        onClose={() => {
          setMaskModalOpen(false);
        }}
        onAfterSave={(result: Customer | Supplier | Project) => {
          setMaskModalOpen(false);
          navigate(`/${file.type}s/${result.id}`);
        }}
      />
    </>
  );
};

export function fileTypeToEntity(fileType: FileType): Entities | null {
  switch (fileType) {
    case FileType.CUSTOMER:
      return Entities.customer;
    case FileType.SUPPLIER:
      return Entities.supplier;
    case FileType.PROJECT:
      return Entities.project;
    default:
      return null;
  }
}
