import { useTranslation } from 'react-i18next';

import { DueDateClassified } from '@work4all/models/lib/Enums/DueDateClassified.enum';

type Props = {
  value: DueDateClassified;
};

export const DueDateClassifiedCell = ({ value }: Props) => {
  const { t } = useTranslation();

  if (value === DueDateClassified.NULL) return null;
  return t(`DUE_DATE_CLASSIFIEDS.${value}`);
};
