import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputArticleSetLedgerAccountsAssignmentRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: taxGroupCode */
  taxGroupCode?: number;
  /** Alias: accountNumber */
  accountNumber?: number;
  /** Alias: vatCode */
  vatCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleSetLedgerAccountsAssignmentRelation> =
  {
    taxGroupCode: {
      alias: 'taxGroupCode',
    },
    accountNumber: {
      alias: 'accountNumber',
    },
    vatCode: {
      alias: 'vatCode',
    },
    __typename: {
      alias: '__typename',
    },
  };

export const inputArticleSetLedgerAccountsAssignmentRelationEntityDefinition: EntitiyDefinition<InputArticleSetLedgerAccountsAssignmentRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleSetLedgerAccountsAssignmentRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
