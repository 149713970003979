import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputNotiz<T extends EMode = EMode.entity> {
  /** Alias: altdaten */
  altdaten?: number;
  /** Alias: ansprpCode */
  ansprpCode?: number;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: bCode */
  bCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: bErstellt */
  bErstellt?: string;
  /** Alias: code */
  code?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: erinnernDate */
  erinnernDate?: string;
  /** Alias: kNBerichtCode */
  kNBerichtCode?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notizText */
  notizText?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: parentCode */
  parentCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: projektePlanungCode */
  projektePlanungCode?: number;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: sDObjType */
  sDObjType?: SdObjType;
  /** Alias: sN */
  sN?: string;
  /** Alias: status */
  status?: number;
  /** Alias: titel */
  titel?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputNotiz> = {
  altdaten: {
    alias: 'altdaten',
  },
  ansprpCode: {
    alias: 'ansprpCode',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  bCode: {
    alias: 'bCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  bErstellt: {
    alias: 'bErstellt',
  },
  code: {
    alias: 'code',
  },
  datum: {
    alias: 'datum',
  },
  erinnernDate: {
    alias: 'erinnernDate',
  },
  kNBerichtCode: {
    alias: 'kNBerichtCode',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  notizText: {
    alias: 'notizText',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  parentCode: {
    alias: 'parentCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  projektePlanungCode: {
    alias: 'projektePlanungCode',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  sDObjType: {
    alias: 'sDObjType',
  },
  sN: {
    alias: 'sN',
  },
  status: {
    alias: 'status',
  },
  titel: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputNotizEntityDefinition: EntitiyDefinition<InputNotiz> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputNotiz',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
