import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Unit } from '@work4all/models/lib/Classes/Unit.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type UnitPickerProps<TMultiple extends boolean> = IPickerProps<
  Unit,
  TMultiple
>;

export function UnitPicker<TMultiple extends boolean>(
  props: UnitPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...UNIT_FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetUnits',
      data: allFields,
      entity: Entities.unit,
    };
  }, [allFields]);

  const response = useDataProvider(request);

  const responsePatched = useMemo(() => {
    return {
      ...response,
      total: response.data.length,
      data: response.data.slice().sort((a, b) => a.order - b.order),
    };
  }, [response]);

  const renderItem = (unit: Unit) => {
    return (
      <Typography variant="body2" noWrap>
        {unit.name}
      </Typography>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.unit}
      data={allFields}
      filterBy="name"
      sortBy="name"
      {...rest}
      fixedDataSet={responsePatched}
      renderItemContent={renderItem}
    />
  );
}

const UNIT_FIELDS: Unit = {
  id: null,
  name: null,
  factor: null,
  order: null,
};
