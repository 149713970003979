import styles from './NoPreview.module.scss';

import { Typography } from '@mui/material';

import { FileIcon } from '../components/file-icon/FileIcon';

export type INoPreviewProps = {
  text: string;
  filePath?: string;
  url?: string;
};

export function NoPreview({ text, filePath }: INoPreviewProps) {
  const fileNameSegments = filePath?.split('.') || null;
  const fileEnding = fileNameSegments
    ? fileNameSegments[fileNameSegments.length - 1]
    : null;
  return (
    <div className={styles['no-preview']}>
      {fileEnding && (
        <div>
          <FileIcon fileType={fileEnding} />
        </div>
      )}
      <Typography className="text" variant="body1">
        {text}
      </Typography>
    </div>
  );
}
