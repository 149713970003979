import { Stack } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '../../../../../entity-picker/components';
import { InfoCardLabel } from '../../../info-card-label/InfoCardLabel';
import { PreviewTextEditor } from '../../../preview-text-editor/PreviewTextEditor';
import { usePreviewInput } from '../../hooks';
import { PreviewTextEditorInputProps } from '../../types';

export const PreviewTextEditorInput = ({
  value,
  accessor,
  disabled,
  onClose,
  labelTranslationKey,
  previewMode = 'rich',
}: PreviewTextEditorInputProps) => {
  const { t } = useTranslation();
  const ref = useRef<EntityPickerPopover>(null);
  const { previewValue, editValueRef, setValue, onClick, CircularProgress } =
    usePreviewInput<string>({
      formattedValue: value,
      accessor,
      disabled,
      ref,
      value,
    });

  return (
    <>
      {labelTranslationKey && (
        <Stack
          direction={'column'}
          justifyContent="space-between"
          width={'100%'}
          alignItems=""
          sx={{ padding: '1rem 1rem 0 1rem', gap: '0.5rem' }}
        >
          <InfoCardLabel>{t(labelTranslationKey)}</InfoCardLabel>
        </Stack>
      )}

      <PreviewTextEditor
        ref={ref}
        value={previewValue}
        onClick={onClick}
        decorator={<CircularProgress />}
        previewMode={previewMode}
        onChange={(value) => {
          setValue(value);
        }}
        onClose={() => {
          onClose(editValueRef.current);
        }}
        disabled={disabled}
      />
    </>
  );
};
