import { useBottomTableContext } from '../hooks';
import { BottomTableContentProps } from '../types';

export const BottomTableContent = ({ children }: BottomTableContentProps) => {
  const { isCollapsed } = useBottomTableContext();

  if (isCollapsed) {
    return null;
  }

  return children;
};
