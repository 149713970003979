import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputSalesOpportunityAttachementModify<
  T extends EMode = EMode.entity
> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputSalesOpportunityAttachementModify> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSalesOpportunityAttachementModifyEntityDefinition: EntitiyDefinition<InputSalesOpportunityAttachementModify> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSalesOpportunityAttachementModify',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
