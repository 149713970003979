import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useCommentContext } from '@work4all/utils';

export function useConfirmBeforeCloseMask(enabled: boolean) {
  const { t } = useTranslation();

  const { setObjectionListener, confirm } = useHistoryStack();

  const { comment } = useCommentContext();

  const isCommentEmpty = !comment;

  useEffect(() => {
    const warningMessage = !isCommentEmpty
      ? t('ALERTS.UNSAVED_COMMENT')
      : t('ALERTS.UNSAVED_CHANGES');

    if (enabled || !isCommentEmpty) {
      setObjectionListener(() => {
        return confirm(warningMessage);
      });

      return () => {
        setObjectionListener(null);
      };
    }
  }, [enabled, setObjectionListener, confirm, t, isCommentEmpty]);
}
