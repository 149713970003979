import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OrganisationAreaProvider } from '../providers';
import { OrganisationAreaProps } from '../types';

import { OrganisationAreaActions } from './organisation-area-actions';
import { OrganisationAreaCloseAction } from './organisation-area-close-action';
import { OrganisationAreaContent } from './organisation-area-content';
import { OrganisationAreaHeader } from './organisation-area-header';
import { OrganisationAreaSettingsAction } from './organisation-area-settings-action';
import { OrganisationAreaTitle } from './organisation-area-title';

export const OrganisationArea = ({ entity }: OrganisationAreaProps) => {
  const { t } = useTranslation();

  return (
    <OrganisationAreaProvider entity={entity}>
      <OrganisationAreaHeader>
        <OrganisationAreaTitle>
          {t('COMMON.ORGANISATION')}
        </OrganisationAreaTitle>

        <OrganisationAreaActions>
          <OrganisationAreaSettingsAction />
          <OrganisationAreaCloseAction />
        </OrganisationAreaActions>
      </OrganisationAreaHeader>

      <Divider />

      <OrganisationAreaContent />
    </OrganisationAreaProvider>
  );
};
