import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { User } from './User.entity';

export class ProjectProcessCompletionFeedback<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: projectStepCode */
  projectProcessId?: number;
  /** Alias: userCode */
  userId?: number;
  /** Alias: timestamp */
  timestamp?: string;
  /** Alias: completionPercent */
  completionPercent?: number;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectProcessCompletionFeedback> = {
  id: {
    alias: 'code',
  },
  projectProcessId: {
    alias: 'projectStepCode',
  },
  userId: {
    alias: 'userCode',
  },
  timestamp: {
    alias: 'timestamp',
  },
  completionPercent: {
    alias: 'completionPercent',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectProcessCompletionFeedbackEntityDefinition: EntitiyDefinition<ProjectProcessCompletionFeedback> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ProjectStepCompletionFeedback',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
