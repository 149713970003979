import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';

import {
  isBusinessPartner,
  isContact,
} from './cells/contact-or-business-partner';

/**
 * Filters out invalid senders from the given MailboxContent items.
 *
 * Only senders of type Contact, Customer, or Supplier are supported.
 *
 * @param items an array of mailbox content items.
 * @return the filtered mailbox content items with unsupported senders removed.
 */
export function filterOutInvalidSenders(
  items: MailboxContent[]
): MailboxContent[] {
  return items.map((mail) => {
    if (!mail) {
      return null;
    }
    const hasSenderSuggestions =
      mail.possibleSenders != null && mail.possibleSenders.length > 0;

    if (!hasSenderSuggestions) {
      return mail;
    }

    const filteredSenders = mail.possibleSenders.filter((contact) => {
      return isContact(contact) || isBusinessPartner(contact);
    });

    return {
      ...mail,
      possibleSenders: filteredSenders,
    };
  });
}
