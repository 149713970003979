import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { AggregationType } from '../Enums/AggregationType.enum';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { GroupFieldDefinition } from './GroupFieldDefinition.entity';

export class GroupQuery<T extends EMode = EMode.entity> {
  /** Alias: entityType */
  entityType?: ObjectType;
  /** Alias: groupKeyFields */
  groupKeyFields?: GroupFieldDefinition<T>[];
  /** Alias: filter */
  filter?: string;
  /** Alias: aggregation */
  aggregation?: AggregationType;

  __typename?: string;
}

const fields: FieldDefinitions<GroupQuery> = {
  entityType: {
    alias: 'entityType',
  },
  groupKeyFields: {
    alias: 'groupKeyFields',
  },
  filter: {
    alias: 'filter',
  },
  aggregation: {
    alias: 'aggregation',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupQueryEntityDefinition: EntitiyDefinition<GroupQuery> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'GroupQuery',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
