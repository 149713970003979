import styles from './PdfEditorOverlayController.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';
import {
  PdfEditor,
  PDFEditorInstance,
} from '@work4all/components/lib/preview/pdf-editor/PdfEditor';

import { useDataProvider, useHttpClient, useUser } from '@work4all/data';
import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import {
  FileType,
  updateFile,
  uploadTempFile,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { usePageTitle } from '../../../../../hooks';
import { usePageTheme } from '../../../../../providers/HookedMuiThemeProvider';

const fileInfos: FileEntity = {
  previewMimeType: null,
  previewUrl: null,
  fileEntityFilename: null,
};

const reData: ReViewModel = {
  id: null,
  note: null,
  receipts: [
    {
      id: null,
      name: null,
      localFilename: null,
      fileInfos: fileInfos,
    },
  ],
};

const documentData: Document = {
  id: null,
  name: null,
  fileInfos: fileInfos,
  childDocuments: [
    {
      id: null,
      name: null,
      fileInfos: fileInfos,
    },
  ],
};

const letterData: Letter = {
  id: null,
  note: null,
  fileInfos: fileInfos,
};

const requestData: Record<string, Work4AllEntity> = {
  [Entities.reViewModel]: reData,
  [Entities.document]: documentData,
  [Entities.letter]: letterData,
};

const fileTypeByEntity: Record<string, FileType> = {
  [Entities.reViewModel]: FileType.Datei,
  [Entities.document]: FileType.Dokument,
  [Entities.letter]: FileType.Brief,
};

interface Props {
  entityType: Entities;
  entityId: number | string;
  docId: number | string;
  onClose: () => void;
}

export const PdfEditorOverlayController = (props: Props) => {
  const { t } = useTranslation();
  const { docId, entityId, entityType, onClose } = props;

  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
  const [editor, setEditor] = useState<PDFEditorInstance>(null);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const request = useMemo(() => {
    return {
      entity: entityType,
      data: requestData[entityType] || { id: null },
      filter: [{ id: { $eq: entityId } }],
    };
  }, [entityId, entityType]);

  const { data } = useDataProvider(request);

  const fileData = useMemo<FileEntity>(() => {
    switch (entityType) {
      case Entities.reViewModel:
        return (data?.[0] as ReViewModel)?.receipts?.find(
          (x) => x.id === parseInt(docId?.toString())
        )?.fileInfos;

      case Entities.letter:
        return (data?.[0] as Letter)?.fileInfos;
      case Entities.document:
        return (data?.[0] as Document)?.id === parseInt(docId?.toString())
          ? (data?.[0] as Document)?.fileInfos
          : (data?.[0] as Document)?.childDocuments?.find(
              (x) => x.id === parseInt(docId?.toString())
            )?.fileInfos;

      default:
        return null;
    }
  }, [data, docId, entityType]);

  const httpHeaders = useAuthHeaders();

  const httpClient = useHttpClient();
  const user = useUser();

  const saveDoc = useCallback(async () => {
    setSavingInProgress(true);
    const result = await editor?.downloadOrSave();
    const tempFileResult = await uploadTempFile(
      new File([result.blob], fileData?.fileEntityFilename),
      httpClient,
      user.baseUrl
    );

    await updateFile(
      httpClient,
      user.baseUrl,
      fileTypeByEntity[entityType],
      tempFileResult?.data?.generatedObject,
      parseInt(docId.toString())
    );
    setSavingInProgress(false);
    onClose();
  }, [
    docId,
    editor,
    entityType,
    fileData?.fileEntityFilename,
    httpClient,
    user.baseUrl,
    onClose,
  ]);

  const pageTheme = usePageTheme();

  return (
    <>
      <Stack width="100%" height="100%" direction="column">
        <Box flex="1">
          <PdfEditorPageHeader
            onCloseClick={() => setShowCloseDialog(true)}
            onSaveClick={saveDoc}
            isLoading={savingInProgress}
            disableSave={savingInProgress}
          />
        </Box>

        {fileData ? (
          <PdfEditor
            url={fileData.previewUrl}
            httpHeaders={httpHeaders}
            onEditorInit={setEditor}
            pageTheme={pageTheme.value}
          />
        ) : null}
      </Stack>

      <Dialog
        open={showCloseDialog}
        onClose={() => setShowCloseDialog(false)}
        title={t('ALERTS.UNSAVED_CHANGES_TITLE')}
        classes={{ dialog: { paper: styles.dialog } }}
      >
        <DialogContent>
          <Typography variant="body1">{t('ALERTS.UNSAVED_CHANGES')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button size="large" color="primary" type="button" onClick={onClose}>
            {t('ALERTS.DISCARD')}
          </Button>
          <Button
            size="large"
            color="primary"
            type="button"
            onClick={() => setShowCloseDialog(false)}
          >
            {t('ALERTS.CONTINUE_EDITING')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
        title={t('PDF_EDITOR.SAVE_CONFIRM.TITLE')}
      >
        <DialogContent>
          <Typography variant="body1">
            {t('PDF_EDITOR.SAVE_CONFIRM.BODY')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            size="large"
            color="primary"
            type="button"
            onClick={() => setShowConfirmationDialog(false)}
          >
            {t('COMMON.OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function PdfEditorPageHeader(props: {
  onCloseClick: () => void;
  onSaveClick: () => void;
  disableSave?: boolean;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();

  const { onCloseClick, onSaveClick, disableSave, isLoading } = props;

  const title = t(`COMMON.PDF_EDITOR`);

  usePageTitle(title);

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const defaultProps: LoadingButtonProps = {
    variant: 'contained',
    children: t('INPUTS.SAVE'),
    type: 'submit',
    size: 'large',
    color: 'primary',
  };

  return (
    <div className={styles.header}>
      <Stack direction="row" width="100%">
        <Box display="flex" alignItems="center" flex="1">
          <ButtonBase
            onClick={onCloseClick}
            className={styles.goBack}
            disabled={disableSave}
          >
            {isLoading ? <CircularProgress size="1.5rem" /> : <CloseIcon />}
          </ButtonBase>

          <Typography
            variant={isViewportDownMd ? 'h4' : 'h3'}
            color="text.secondary"
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <LoadingButton
            onClick={onSaveClick}
            sx={{ color: 'white' }}
            startIcon={<SaveIcon />}
            title={t('COMMON.SAVE')}
            loading={isLoading}
            disabled={isLoading}
            {...defaultProps}
          />
        </Box>
      </Stack>
    </div>
  );
}
