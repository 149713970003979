import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

export type IDateCellProps = {
  value: string;
  column: {
    cellParams: {
      spanEnd?: string;
      format?: string; //https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
      wholeDayIndicator: string; //field that determines if this is to be considered without time
    };
  };
  row: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
  };
};

export const DateCell: React.FC = (props: IDateCellProps) => {
  const {
    value,
    column: { cellParams },
    row: { original: rowData },
  } = props;

  let endValue;
  if (cellParams && cellParams.spanEnd && rowData[cellParams.spanEnd]) {
    endValue = rowData[cellParams.spanEnd];
  }

  return (
    formatDateString(
      value,
      cellParams?.format || DateFormatPreset.DATE_SIMPLE_2YEAR,
      {
        periodEnd: endValue,
        isWholeDay: rowData['isWholeDay'],
      }
    ) || '-'
  );
};
