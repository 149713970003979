export function parseAsURL(value: string) {
  if (!value || !value.trim) {
    return null;
  }
  const trimmedValue = value.trim();
  const validateURL = /^www\.|\.([a-zA-Z]{2,})$/;

  if (URL.canParse(trimmedValue)) {
    return trimmedValue;
  }

  if (validateURL.test(trimmedValue)) {
    if (URL.canParse('http://' + trimmedValue)) {
      return 'http://' + trimmedValue;
    }
  }

  return null;
}
