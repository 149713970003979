import { useCallback, useMemo } from 'react';

import { EntityByObjectType } from '@work4all/models';
import { ModuleAccessRight } from '@work4all/models/lib/Classes/ModuleAccessRight.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { throwInDev, viewEntityToMutationEntity } from '@work4all/utils';

import { useDocumentClasses } from './document-classes';
import { useModuleRights } from './use-module-rights';

type RightsMap = {
  [K in Entities]?: boolean;
};

const filterHiddenEntitiesBySDobjType = (
  rights: ModuleAccessRight[],
  sdObjType: SdObjType
) => {
  const sdObjTypeRelatedRights: RightsMap = {};
  rights
    .filter((el) => el.type === 'WIDGET' && el.sdObjType === sdObjType)
    .reduce((curr, el) => {
      const entityType = EntityByObjectType[el.objectType];
      const entityVariant = el.subObjectType;

      const entityName = formatEntityName(entityType, entityVariant);
      curr[entityName] = !el.hasAccess;

      return curr;
    }, sdObjTypeRelatedRights);
  return sdObjTypeRelatedRights;
};

export const useHiddenEntities = () => {
  const { rights } = useModuleRights();

  const documentClasses = useDocumentClasses();

  const documentVariantBusinessPartnerTypeMap = useMemo(() => {
    return documentClasses.reduce<Record<string, string[]>>(
      (map, documentClass) => {
        const name = formatEntityName(Entities.document, documentClass.name);

        map[name] ??= [];
        map[name].push(documentClass.businessPartnerType);

        return map;
      },
      {}
    );
  }, [documentClasses]);

  const partiallyAvailableEntities = useMemo(() => {
    return {
      ...STATIC_PARTIALLY_AVAILABLE_ENTITIES,
      ...documentVariantBusinessPartnerTypeMap,
    };
  }, [documentVariantBusinessPartnerTypeMap]);

  const hiddenForCustomer = useMemo<RightsMap>(
    () => filterHiddenEntitiesBySDobjType(rights, SdObjType.KUNDE),
    [rights]
  );

  const hiddenForSupplier = useMemo<Partial<Record<Entities, boolean>>>(
    () => filterHiddenEntitiesBySDobjType(rights, SdObjType.LIEFERANT),
    [rights]
  );

  const hiddenForProject = useMemo<Partial<Record<Entities, boolean>>>(
    () => filterHiddenEntitiesBySDobjType(rights, SdObjType.PROJEKT),
    [rights]
  );

  const isHidden = useCallback(
    function isHidden(
      options:
        | Entities
        | {
            entityType: Entities;
            entityVariant?: string;
            businessPartnerType?: SdObjType;
          }
    ): boolean {
      if (typeof options === 'string') {
        return isHidden({ entityType: options });
      }

      const {
        entityType: entityTypeProps,
        businessPartnerType,
        entityVariant,
      } = options;

      const entityType = viewEntityToMutationEntity(entityTypeProps);

      if (entityType !== Entities.document && entityVariant != null) {
        throwInDev('Entity variants are only supported for Document entities');
      }

      const entityName = formatEntityName(entityType, entityVariant);

      if (businessPartnerType) {
        //check if its hidden for this businesspartnerType
        switch (businessPartnerType) {
          case SdObjType.KUNDE:
            return hiddenForCustomer[entityName] === true;
          case SdObjType.LIEFERANT:
            return hiddenForSupplier[entityName] === true;
          case SdObjType.PROJEKT:
            return hiddenForProject[entityName] === true;
        }
      }
      //either its hidden without a context, or hidden in all contexts it is available for
      const businessPartnersToCheck =
        partiallyAvailableEntities[entityName] ?? ALL_FILE_ENTITIES;

      return businessPartnersToCheck.every((businessPartnerType) => {
        return isHidden({ entityType, businessPartnerType, entityVariant });
      });
    },
    [
      hiddenForCustomer,
      hiddenForProject,
      hiddenForSupplier,
      partiallyAvailableEntities,
    ]
  );
  return { isHidden };
};

const STATIC_PARTIALLY_AVAILABLE_ENTITIES: Partial<
  Record<Entities, SdObjType[]>
> = {
  [Entities.ticket]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.offer]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.contract]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.calculation]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.invoice]: [SdObjType.KUNDE, SdObjType.PROJEKT],
  [Entities.demand]: [SdObjType.LIEFERANT, SdObjType.PROJEKT],
  [Entities.order]: [SdObjType.LIEFERANT, SdObjType.PROJEKT],
  [Entities.inboundInvoice]: [SdObjType.LIEFERANT, SdObjType.PROJEKT],
  [Entities.project]: [SdObjType.LIEFERANT, SdObjType.KUNDE],
};

const ALL_FILE_ENTITIES = [
  SdObjType.KUNDE,
  SdObjType.PROJEKT,
  SdObjType.LIEFERANT,
];

function formatEntityName(entityType: string, entityVariant: string) {
  if (entityVariant == null || entityVariant === '') {
    return entityType;
  }

  return `${entityType}(${entityVariant})`;
}
