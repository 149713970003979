import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputArticleUpsertBomComponent } from './InputArticleUpsertBomComponent.entity';

export class InputArticleBomComponents<T extends EMode = EMode.entity> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: add */
  add?: InputArticleUpsertBomComponent<T>[];
  /** Alias: modify */
  modify?: InputArticleUpsertBomComponent<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleBomComponents> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleBomComponentsEntityDefinition: EntitiyDefinition<InputArticleBomComponents> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleBomComponents',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
