import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BanderoleInfo } from '@work4all/components/lib/components/banderole/BanderoleInfo';
import { MIME_TYPES, Preview } from '@work4all/components/lib/preview/Preview';

import { useUser } from '@work4all/data';

import { TimeCardArchivedPdfDownloadAction } from './TimeCardArchivedPdfDownloadAction';
import { TimeCardArchivedPdfFullscreenAction } from './TimeCardArchivedPdfFullscreenAction';
import { TimeCardArchivedPdfProps } from './types';

const ARCHIVED_PDF_FILE_NAME = 'STATISTIC.TIMECARD.WORKINGHOURS';

export const TimeCardArchivedPdf = ({
  archivedPdfDownloadUrl,
}: TimeCardArchivedPdfProps) => {
  const user = useUser();
  const { t } = useTranslation();

  const [isFullscreen, setFullscreen] = useState(false);

  const fileUrl = `${user.baseUrl}/api/${archivedPdfDownloadUrl}`;
  const fileName = `${t(ARCHIVED_PDF_FILE_NAME)}.pdf`;

  return (
    <>
      <BanderoleInfo
        visible={true}
        text={t('STATISTIC.TIMECARD.ARCHIVED_PDF')}
      />

      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <TimeCardArchivedPdfDownloadAction
          downloadUrl={fileUrl}
          fileName={fileName}
        />
        <TimeCardArchivedPdfFullscreenAction setFullscreen={setFullscreen} />
      </div>

      <Preview
        fileName={fileName}
        url={fileUrl}
        mimeType={MIME_TYPES.pdf}
        noPreview=""
        openInFullscreen={isFullscreen}
        onFullscreenClose={() => {
          setFullscreen(false);
        }}
        downloadProps={{
          url: fileUrl,
        }}
      />
    </>
  );
};
