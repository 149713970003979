import styles from './CustomListEntityPicker.module.scss';

import { ListItem, ListItemButton } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { Tooltip } from '../../../tooltip/Tooltip';

interface ItemRowProps {
  content: ReactNode;
  title: string;
  isInteractive: boolean;
  hasSelection: boolean;
  isSelected: boolean;
  className?: string;
  onClick: () => void;
  onClear: () => void;
}

export const ItemRow = forwardRef<HTMLDivElement, ItemRowProps>(
  (
    {
      content,
      title,
      isInteractive,
      hasSelection,
      isSelected,
      className,
      onClick,
      onClear,
    },
    ref
  ) => {
    if (isInteractive) {
      return (
        <ListItem disablePadding className={className}>
          <Tooltip title={title}>
            <ListItemButton
              ref={ref}
              dense
              onClick={onClick}
              className={styles.row}
              selected={isSelected}
              tabIndex={-1}
            >
              {content}
              {hasSelection && (
                <span className={styles.close}>
                  {isSelected && (
                    <ClearIcon
                      className={styles['deselect-icon']}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClear();
                      }}
                    />
                  )}
                </span>
              )}
            </ListItemButton>
          </Tooltip>
        </ListItem>
      );
    }

    return (
      <ListItem className={clsx(styles.row, styles.muted)} dense>
        {content}
      </ListItem>
    );
  }
);
