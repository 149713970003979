import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { useTicketCustomActions } from '../../containers/files/detail/components/file-preview-panel/use-ticket-custom-actions';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/ticket-table-schema.json';
import { TicketPriorityCell } from './TicketPriorityCell';
import { TicketsTableQuickFilters } from './tickets-table-components/tickets-table-filters/TicketsTableQuickFilters';
import { TicketStatusCell } from './TicketStatusCell';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

export const TicketsTable = React.forwardRef<TableInstance, IEntityTable>(
  function TicketsTable(props, ref) {
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Ticket,
      never
    >({
      schema: schema as never,
      cells: {
        TicketStatus: TicketStatusCell,
        TicketPriority: TicketPriorityCell,
      },
      defaultSort,
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.status1 === TicketStatus.ERLEDIGT,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const quickFilters = useMemo(() => <TicketsTableQuickFilters />, []);
    const { t } = useTranslation();

    const left = useTicketCustomActions({
      tickets: dataTable.selectedEntities,
    });

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        {...props}
        actions={{
          custom: { left },
        }}
        areas={{
          left: {
            content: quickFilters,
            resizable: true,
            collapsible: true,
            title: t('COMMON.FILTER'),
          },
        }}
        prepareRowDisplayModifiers={rowModifiers}
      />
    );
  }
);
