import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  InvoiceStatus,
  InvoiceStatusPicker,
  InvoiceStatusPickerProps,
  mapInvoiceStatus,
} from '@work4all/components/lib/components/entity-picker/invoice-status-picker/InvoiceStatusPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface InvoiceStatusPickerFieldProps<TMultiple extends boolean>
  extends Omit<
    InvoiceStatusPickerProps<TMultiple>,
    'multiple' | 'value' | 'onChange'
  > {
  label?: string;
  error?: string;
  disabled?: boolean;
  field?: JSX.Element;
  multiple?: boolean;
  paid: boolean;
  ra: boolean;
  onChange: (isClosed: boolean, frozen: boolean) => void;
}

function mapToForm(status: InvoiceStatus): {
  ra: boolean;
  paid: boolean;
} {
  switch (status) {
    case InvoiceStatus.Open:
      return { ra: false, paid: false };
    case InvoiceStatus.Ra:
      return { ra: true, paid: false };
    case InvoiceStatus.RaAndPaid:
      return { ra: true, paid: true };
    default:
      throw new Error('this is not allowed combination');
  }
}
const createInvoiceStatusPickerField = <TMultiple extends boolean>() => {
  return React.forwardRef<
    HTMLDivElement,
    InvoiceStatusPickerFieldProps<TMultiple>
  >(function InvoiceStatusPickerField(props, ref) {
    const {
      field: _field,
      error: _error,
      disabled: _disabled = false,
      label: _label,
      multiple: _multiple,
      ...pickerProps
    } = props;
    const { paid, ra, onChange } = pickerProps;
    const { t } = useTranslation();

    const popoverRef = useRef<EntityPickerPopover>(null);

    // map status
    const status: InvoiceStatus = useMemo(
      () => mapInvoiceStatus(ra, paid),
      [paid, ra]
    );

    const stringValue = t('COMMON.INVOICE_STATUS_PICKER_' + status);
    return (
      <EntityPickerPopover
        ref={popoverRef}
        picker={
          <InvoiceStatusPicker
            multiple={false}
            value={{ id: status, name: stringValue }}
            onChange={(value) => {
              const { ra, paid } = mapToForm(value?.id);
              onChange(ra, paid);
            }}
          />
        }
      >
        <PickerTargetButton
          ref={ref}
          label={t('COMMON.STATUS')}
          value={stringValue}
          disabled={_disabled}
        />
      </EntityPickerPopover>
    );
  });
};

export const InvoiceStatusPickerField = createInvoiceStatusPickerField();
