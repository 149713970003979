import { useTranslation } from 'react-i18next';

import { ErpDialog } from '../ErpDialog';
import { CloneConvertDialogProps } from '../types';

export const ConvertErpDialog = (props: CloneConvertDialogProps) => {
  const { sourceEntity, targetEntity, data } = props;
  const { t } = useTranslation();
  if (!sourceEntity || !targetEntity || !data) return null;
  return (
    <ErpDialog
      {...props}
      title={t('COMMON.CONVERT_ERP_TITLE', {
        source: t(`COMMON.${sourceEntity.toUpperCase()}`),
        target: t(`COMMON.${targetEntity.toUpperCase()}`),
      })}
    />
  );
};
