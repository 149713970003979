import styles from './PositionsTabPanel.module.scss';

import { Panel } from '../../../../../components/panel/Panel';

import { PositionsWithAutoDisable } from './components/Positions';

export const PositionsTabPanel = () => {
  return (
    <Panel className={styles.panel}>
      <PositionsWithAutoDisable showSelectionColumn={true} showBorders={true} />
    </Panel>
  );
};
