import { groupBy, omit } from 'lodash';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { ErpObject, GroupedPosition } from '../../types';

export const groupByArticle = (data: ErpObject[]): GroupedPosition[] => {
  if (!data) return;

  const positionsWithArticles = data
    .flatMap((erpObject) =>
      (erpObject.positionList || []).map((pos) => ({
        ...pos,
        parent: erpObject,
      }))
    )
    .filter((pos) => pos.articleId)
    .filter((pos) => pos.positionKind !== ErpPositionsKind.STUECKLISTE);

  const groups = groupBy(
    positionsWithArticles,
    (item) => `${item.supplierId}:${item.articleId}`
  );

  let incrementalPosId = 0;
  // Convert the grouped data into a flat array with headers and items
  const result = Object.entries(groups).flatMap(([_key, items]) => {
    const list: GroupedPosition[] = [
      {
        id: ++incrementalPosId,
        name: items[0]?.article?.name,
        number: items[0]?.article?.number,
        articleId: items[0]?.articleId,
        unit: items[0]?.article?.unit?.name,
        amount: items.reduce((p, c) => p + c.amount, 0),
        should: items.reduce((p, c) => p + c.amount, 0),
        singlePriceNet: items[0]?.singlePriceNet,
        discount: 0,
        // Header for the group
        positionKind:
          items.length > 1
            ? ErpPositionsKind.STUECKLISTE
            : ErpPositionsKind.STANDARD,
        supplier: items[0]?.supplier,
        supplierId: items[0]?.supplierId,
        isHeader: true,
        childs: items.map((x) => x.id),
      },
      // Items for the group
      ...(items.length > 1
        ? items.map((position) => ({
            ...omit(position, 'parent'),
            posId: incrementalPosId,
            supplierId: position.supplierId || 0,
            name: `${position.parent.contractNumber} | ${position.parent.businessPartnerContactCombined?.businessPartner.data.name}`,
            number: `Pos ${position.number}`,
            should: position.amount,
            articleId: position.articleId,
          }))
        : []),
    ];
    return list;
  });

  return result;
};
