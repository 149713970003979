import { useState } from 'react';

import { OrganisationAreaContext } from '../contexts';
import {
  OrganisationAreaProviderProps,
  OrganisationAreaSectionType,
} from '../types';

export const OrganisationAreaProvider = ({
  children,
  entity,
}: OrganisationAreaProviderProps) => {
  const [multiselectableSections, setMultiselectableSections] = useState<
    OrganisationAreaSectionType[]
  >([]);

  return (
    <OrganisationAreaContext.Provider
      value={{ entity, multiselectableSections, setMultiselectableSections }}
    >
      {children}
    </OrganisationAreaContext.Provider>
  );
};
