import { createContext } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationAreaContextValue } from '../types';

export const OrganisationAreaContext =
  createContext<OrganisationAreaContextValue>({
    entity: Entities.customer,
    multiselectableSections: [],
    setMultiselectableSections: () => {},
  });
