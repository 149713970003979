import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { MfaMode } from '../Enums/MfaMode.enum';

export class InputValidateSetMfaMode<T extends EMode = EMode.entity> {
  /** Alias: userCode */
  userCode?: number;
  /** Alias: mfaMode */
  mfaMode?: MfaMode;
  /** Alias: mfaCode */
  mfaCode?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputValidateSetMfaMode> = {
  userCode: {
    alias: 'userCode',
  },
  mfaMode: {
    alias: 'mfaMode',
  },
  mfaCode: {
    alias: 'mfaCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputValidateSetMfaModeEntityDefinition: EntitiyDefinition<InputValidateSetMfaMode> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputValidateSetMfaMode',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
