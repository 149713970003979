import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';

import { useBottomTableContext } from '../hooks';

export const BottomTableToggleCollapsibilityAction = () => {
  const { isCollapsed, togglecollapsibility } = useBottomTableContext();

  const renderIcon = useCallback(() => {
    if (isCollapsed) {
      return <KeyboardArrowUpIcon />;
    }

    return <KeyboardArrowDownIcon />;
  }, [isCollapsed]);

  return <IconButton onClick={togglecollapsibility}>{renderIcon()}</IconButton>;
};
