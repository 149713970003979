import styles from './FileListGalleryItem.module.scss';

import { Typography } from '@mui/material';
import sanitize from 'sanitize-html';

import { FileListGalleryItemThumbnail } from './FileListGalleryItemThumbnail';
import { FileAndThumbnail } from './types';

export interface FileListGalleryItemProps {
  file: FileAndThumbnail;
  onClick: () => void;
}

export function FileListGalleryItem(props: FileListGalleryItemProps) {
  const { file, onClick } = props;
  const description = file.name;

  return (
    <div className={styles.root}>
      <div className={styles.thumbnail} tabIndex={0} onClick={onClick}>
        <FileListGalleryItemThumbnail file={file} />
      </div>

      <Typography
        variant="body1"
        overflow="hidden"
        height={3 * 1.5 + 'rem'}
        textAlign="left"
        dangerouslySetInnerHTML={{ __html: sanitize(description) }}
      />
    </div>
  );
}
