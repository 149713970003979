import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Unit<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: faktor */
  factor?: number;
  /** Alias: minuten */
  minutes?: number;
  /** Alias: name */
  name?: string;
  /** Alias: einheitOrder */
  order?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Unit> = {
  id: {
    alias: 'code',
  },
  factor: {
    alias: 'faktor',
  },
  minutes: {
    alias: 'minuten',
  },
  name: {
    alias: 'name',
  },
  order: {
    alias: 'einheitOrder',
  },
  __typename: {
    alias: '__typename',
  },
};

export const unitEntityDefinition: EntitiyDefinition<Unit> = {
  local: {},
  remote: {
    queryName: 'getEinheiten',
    fragmentName: 'Einheit',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'code', type: 'Int' },
      { name: 'name', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
