import { Box, Grid } from '@mui/material';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem } from '../../../../../locked-inputs';
import { ArticleMaskFormValue } from '../../types';

import { PurchasePriceTable } from './PurchasePriceTable';
import { SinglePriceTable } from './SinglePriceTable';

export function PricesTabPanel() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();

  const standardAsEntry = form.watch('standardPurchasePriceAsEntryPrice');

  const entryPrice = form.watch('entryPrice');
  const articlePrices = form.watch('articlePrices');

  const singlePriceList = articlePrices.singlePriceList;
  const singlePriceListSorted = useMemo(() => {
    return sortBy(singlePriceList, (x) => x.priceGroup.id);
  }, [singlePriceList]);

  const purchasePriceList = articlePrices.purchasePriceList;

  return (
    <Box sx={{ py: '1rem' }}>
      <Grid container columns={1}>
        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.SELLING_PRICES')}
            defaultOpen
            bottomIndent="lg"
          >
            <SinglePriceTable
              entryPrice={entryPrice}
              entryPriceDisabled={Boolean(standardAsEntry)}
              onEntryPriceChange={(value) => {
                form.setValue('entryPrice', value, { shouldDirty: true });
              }}
              singlePriceList={singlePriceListSorted}
              onChange={(singlePriceList) => {
                form.setValue(
                  'articlePrices',
                  { ...articlePrices, singlePriceList },
                  { shouldDirty: true }
                );
              }}
            />
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.PURCHASE_PRICES')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={1} spacing="1rem">
              <Grid item xs={1}>
                <PurchasePriceTable
                  standardSupplierId={form.watch('standardSupplierId')}
                  onStandardSupplierIdChange={(value) => {
                    form.setValue('standardSupplierId', value, {
                      shouldDirty: true,
                    });
                  }}
                  purchasePriceList={purchasePriceList}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="standardPurchasePriceAsEntryPrice"
                  render={({ field: { value, ...field } }) => {
                    const checked = Boolean(value);

                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        disabled={purchasePriceList.length === 0}
                        label={t(
                          'MASK.ARTICLE.FIELDS.standardPurchasePriceAsEntryPrice'
                        )}
                        {...field}
                        checked={checked}
                        defaultChecked={checked}
                        onChange={(event) => {
                          field.onChange(Number(event.target.checked));
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.DISCOUNTS_AND_COMMISSIONS')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={4} spacing="1rem">
              {/* <Grid item xs={1}>
                <LabeledInput disabled label={t('Rabattgruppe')} value="Ohne" />
              </Grid> */}

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="discountable"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.discountable')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="noSkonto"
                  render={({ field: { value, ...field } }) => {
                    const checked = !value;

                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.noSkonto')}
                        {...field}
                        checked={checked}
                        defaultChecked={checked}
                        onChange={(event) => {
                          field.onChange(!event.target.checked);
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="hasNoCommission"
                  render={({ field: { value, ...field } }) => {
                    const checked = !value;

                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.hasNoCommission')}
                        {...field}
                        checked={checked}
                        defaultChecked={checked}
                        onChange={(event) => {
                          field.onChange(!event.target.checked);
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}
