import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class DeliveryKind<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: intrastatNichtBeruecksichtigen */
  dontConsiderIntrastat?: boolean;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: lieferungsziel */
  deliveryTarget?: number;
  /** Alias: schluessel */
  key?: number;
  /** Alias: stufe */
  step?: number;
  /** Alias: text */
  text?: string;
  /** Alias: incoterm */
  incoterm?: string;

  __typename?: string;
}

const fields: FieldDefinitions<DeliveryKind> = {
  id: {
    alias: 'code',
  },
  dontConsiderIntrastat: {
    alias: 'intrastatNichtBeruecksichtigen',
  },
  longtext: {
    alias: 'langtext',
  },
  deliveryTarget: {
    alias: 'lieferungsziel',
  },
  key: {
    alias: 'schluessel',
  },
  step: {
    alias: 'stufe',
  },
  text: {
    alias: 'text',
  },
  incoterm: {
    alias: 'incoterm',
  },
  __typename: {
    alias: '__typename',
  },
};

export const deliveryKindEntityDefinition: EntitiyDefinition<DeliveryKind> = {
  local: {},
  remote: {
    queryName: 'getLieferungArt',
    fragmentName: 'LieferungArt',
    withPaginationWrapper: false,
    params: [{ name: 'codes', type: '[Int]' }],
  },
  fieldDefinitions: fields,
};
