import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputCheckliste<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: mustervorlage */
  mustervorlage?: boolean;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: projektePlanungCode */
  projektePlanungCode?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: titel */
  titel?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: themen */
  themen?: number[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputCheckliste> = {
  code: {
    alias: 'code',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  datum: {
    alias: 'datum',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  mustervorlage: {
    alias: 'mustervorlage',
  },
  notiz: {
    alias: 'notiz',
  },
  projektCode: {
    alias: 'projektCode',
  },
  projektePlanungCode: {
    alias: 'projektePlanungCode',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  titel: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  themen: {
    alias: 'themen',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputChecklisteEntityDefinition: EntitiyDefinition<InputCheckliste> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputCheckliste',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
