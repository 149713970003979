import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputArticleTextAddRelation<T extends EMode = EMode.entity> {
  /** Alias: languageCode */
  languageCode?: number;
  /** Alias: longText */
  longText?: any;
  /** Alias: shortText */
  shortText?: any;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleTextAddRelation> = {
  languageCode: {
    alias: 'languageCode',
  },
  longText: {
    alias: 'longText',
  },
  shortText: {
    alias: 'shortText',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleTextAddRelationEntityDefinition: EntitiyDefinition<InputArticleTextAddRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleTextAddRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
