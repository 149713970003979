import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputBrief<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: art */
  art?: number;
  /** Alias: erstellerBenutzerCode */
  erstellerBenutzerCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: blobFileType */
  blobFileType?: number;
  /** Alias: briefdatei */
  briefdatei?: string;
  /** Alias: dateiPfad */
  dateiPfad?: string;
  /** Alias: datum */
  datum?: string;
  /** Alias: gesperrt */
  gesperrt?: boolean;
  /** Alias: inhalt */
  inhalt?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: lookupCode */
  lookupCode?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: status1 */
  status1?: boolean;
  /** Alias: status2 */
  status2?: boolean;
  /** Alias: status3 */
  status3?: boolean;
  /** Alias: status4 */
  status4?: boolean;
  /** Alias: status5 */
  status5?: boolean;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: themen */
  themen?: number[];

  __typename?: string;
}

const fields: FieldDefinitions<InputBrief> = {
  code: {
    alias: 'code',
  },
  art: {
    alias: 'art',
  },
  erstellerBenutzerCode: {
    alias: 'erstellerBenutzerCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  blobFileType: {
    alias: 'blobFileType',
  },
  briefdatei: {
    alias: 'briefdatei',
  },
  dateiPfad: {
    alias: 'dateiPfad',
  },
  datum: {
    alias: 'datum',
  },
  gesperrt: {
    alias: 'gesperrt',
  },
  inhalt: {
    alias: 'inhalt',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  lookupCode: {
    alias: 'lookupCode',
  },
  notiz: {
    alias: 'notiz',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  status3: {
    alias: 'status3',
  },
  status4: {
    alias: 'status4',
  },
  status5: {
    alias: 'status5',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  themen: {
    alias: 'themen',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputBriefEntityDefinition: EntitiyDefinition<InputBrief> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputBrief',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
