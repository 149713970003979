import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import { parseAsURL } from '@work4all/components/lib/input/labeled-input/parse-as-url';

import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

export function CustomTextField(props: CustomFieldProps) {
  const { name, label } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  const [isFocused, setIsFocused] = useState(false);

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field }) => {
        const url = parseAsURL(field.value);
        return (
          <LabeledInput
            label={label}
            {...field}
            endAdornment={
              url &&
              !isFocused && (
                <IconButton
                  component="a"
                  href={url}
                  size="small"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewIcon color="primary" />
                </IconButton>
              )
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              field.onBlur();
              setIsFocused(false);
            }}
          />
        );
      }}
    />
  );
}
