import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CountryPicker,
  ICountryPickerProps,
} from '@work4all/components/lib/components/entity-picker/country-picker/CountryPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface CountryPickerFieldProps
  extends Omit<ICountryPickerProps<false>, 'multiple'> {
  label?: string;
  error?: string;
}

export const CountryPickerField = React.forwardRef<
  HTMLDivElement,
  CountryPickerFieldProps
>(function CountryPickerField(props, ref) {
  const { error, label, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={<CountryPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value?.shortName ?? ''}
        label={label || t('INPUTS.COUNTRY')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
