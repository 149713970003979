import { MouseEventHandler } from 'react';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { NotePreview } from '../note-preview';

export const callMemoPreviewFields: CallMemo<EMode.query> = {
  id: null,
  title: null,
  date: null,
  user: {
    id: null,
    displayName: null,
  },
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    eMail: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
  project: {
    id: null,
    name: null,
  },
  note: null,
};

export type ICallMemoPreviewContainerProps = {
  callMemoIds: number[];
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  onVisibilityToggle?: (visible: boolean) => void;
};

export function CallMemoPreviewContainer(
  props: ICallMemoPreviewContainerProps
) {
  const {
    callMemoIds,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const callMemos = useEntityPreviewData<CallMemo>(
    callMemoIds,
    Entities.callMemo,
    callMemoPreviewFields
  );

  if (!callMemos) {
    return null;
  }

  // Note and CallMemo preview are exactly the same at the moment.
  return (
    <NotePreview
      isCallMemo={true}
      notes={callMemos}
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
