import { useEffect } from 'react';

export const useDnDScroll = (scrollContainer: HTMLElement) => {
  useEffect(() => {
    if (scrollContainer) {
      const handleDragOver = (e) => {
        const rect = scrollContainer.getBoundingClientRect();
        const offset = rect.height * 0.15;
        const maxSpeed = 10;
        let scrollSpeed = 0;
        // Calculate the distance from the top and bottom
        const distanceFromTop = e.clientY - rect.top;
        const distanceFromBottom = rect.bottom - e.clientY;

        // Determine scroll speed based on distance
        if (distanceFromTop < offset) {
          scrollSpeed = Math.max(
            maxSpeed * ((offset - distanceFromTop) / offset),
            2
          );
          scrollSpeed = -Math.pow(scrollSpeed, 1.5);
        } else if (distanceFromBottom < offset) {
          scrollSpeed = Math.max(
            maxSpeed * ((offset - distanceFromBottom) / offset),
            2
          );
          scrollSpeed = Math.pow(scrollSpeed, 1.5);
        }

        if (scrollSpeed !== 0) {
          scrollContainer.scrollBy(0, scrollSpeed);
        }
      };

      scrollContainer.addEventListener('dragover', handleDragOver);
      return () => {
        scrollContainer.removeEventListener('dragover', handleDragOver);
      };
    }
  }, [scrollContainer]);
};
