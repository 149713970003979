import styles from './LedgerAccounts.module.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { BasicTable } from '@work4all/components';
import { ColumnAdditionalData } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { ArticleLedgerAccount } from '@work4all/models/lib/Classes/ArticleLedgerAccount.entity';
import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';

import { LedgerAccountCell } from '../../../components/table-cells/LedgerAccountCell';
import { VatRateCell } from '../../../components/table-cells/VatRateCell';

export interface LedgerAccountsProps {
  ledgerAccounts: ArticleLedgerAccount[];
  onChange: (
    original: ArticleLedgerAccount,
    change: Partial<ArticleLedgerAccount>
  ) => void;
  ledgerAccountFilter: unknown[];
}

export function LedgerAccounts(props: LedgerAccountsProps) {
  const { ledgerAccounts, onChange, ledgerAccountFilter } = props;

  const { t } = useTranslation();

  const columns = useMemo(() => {
    const columns: (Column<ArticleLedgerAccount> & ColumnAdditionalData)[] = [
      {
        id: 'taxGroup',
        Header: t('COMMON.GROUP'),
        accessor: (row) => row.taxGroup?.name,
        width: 180,
      },
      {
        id: 'vatRate',
        Header: t('COMMON.VAT'),
        accessor: 'vatRate',
        width: 130,
        Cell: (cell) => {
          return (
            <VatRateCell
              value={cell.value}
              onChange={(vatRate) => {
                onChange(cell.row.original, { vatRate });
              }}
            />
          );
        },
      },
      {
        id: 'ledgerAccount',
        Header: t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT'),
        accessor: 'ledgerAccount',
        width: 650,
        Cell: (cell) => {
          return (
            <LedgerAccountCell
              value={cell.value}
              onChange={(ledgerAccount) => {
                const change: Partial<ArticleLedgerAccount> = {
                  ledgerAccount,
                };

                if (ledgerAccount?.taxKey?.vatRate) {
                  change.vatRate = ledgerAccount.taxKey.vatRate;
                }

                onChange(cell.row.original, change);
              }}
              overridePrefilter={ledgerAccountFilter}
              data={LEDGER_ACCOUNT_FIELDS}
            />
          );
        },
      },
    ];

    return columns;
  }, [t, onChange, ledgerAccountFilter]);

  return (
    <BasicTable
      classes={{ tableBody: styles.tableBody }}
      mode="client"
      columns={columns}
      data={ledgerAccounts}
      isVirtual={false}
      fitContentHeight={true}
      resizableColumns={true}
    />
  );
}

const LEDGER_ACCOUNT_FIELDS: LedgerAccount = {
  taxKey: {
    id: null,
    vatRate: {
      id: null,
      sentence: null,
    },
  },
};
