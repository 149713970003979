import { MAX_HOURS_PRESENT } from '../../calendar/constants';
import { TimeTracker } from '../use-time-tracker';

export const getDefaultTime = (
  { result }: TimeTracker,
  workDayEnd: string
): Date => {
  const isRunningForMoreThanMaxHours =
    result.totalPresent.as('hours') > MAX_HOURS_PRESENT;
  let defTime = new Date();

  const [endHour, endMinute] = workDayEnd.split(':').map(Number);

  if (isRunningForMoreThanMaxHours && result.presentSince.hour < endHour) {
    defTime = result.presentSince
      .set({
        hour: endHour,
        minute: endMinute,
      })
      .toJSDate();
  } else if (result.presentSince.hour >= endHour && result.isPresent) {
    defTime = result.presentSince.plus({ minute: 1 }).toJSDate();
  } else return undefined;
  return defTime;
};
