import { useEffect, useState } from 'react';

import { ErpObject, GroupedPosition } from '../../types';
import { ErpDialogPositionsProps } from '../ErpDialogPositions';

import { ErpDialogGroupedPositions } from './ErpDialogGroupedPositions';
import { groupByArticle } from './groupByArticle';
import { useGroupByArticleQuery } from './use-group-by-article-query';

interface ErpDialogArticleGroupedPositionsProps
  extends Omit<ErpDialogPositionsProps, 'entry'> {
  data: ErpObject[];
}
export const ErpDialogArticleGroupedPositions = (
  props: ErpDialogArticleGroupedPositionsProps
) => {
  const { sourceEntity } = props;

  const {
    erpData: { data },
  } = useGroupByArticleQuery({
    data: props.data,
    sourceEntity,
  });

  const [positionList, setPositionList] = useState<GroupedPosition[]>([]);
  useEffect(() => {
    if (data.length) {
      const positionList = groupByArticle(data);
      setPositionList(positionList);
    }
  }, [data]);

  return (
    <ErpDialogGroupedPositions
      key={positionList.length}
      {...props}
      positionList={positionList}
    />
  );
};
