import { MenuItem } from '@mui/material';
import { cloneDeep, isEqual, merge } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import {
  useDataProvider,
  useFormPlus,
  usePermissions,
  useUser,
} from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { PaymentKind } from '@work4all/models/lib/Classes/PaymentKind.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';
import {
  canAddInboundInvoice,
  canDeleteInboundInvoice,
  canEditInboundInvoice,
} from '@work4all/utils/lib/permissions';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { useBinaryTransfer } from '../../../../../hooks/useBinaryTransfer';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { Form, MaskOverlayHeader } from '../../components';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { LockOverride } from '../../components/LockOverride';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import { MaskContextProvider, useMaskConfig } from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useMaskLock } from '../../hooks/use-mask-lock';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { parseTemplate } from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';
import {
  ErpInitialView,
  useErpInitialView,
} from '../erp/components/initial-view/ErpInitialView';
import { InboundInvoiceSettings } from '../settings/inbound-invoice-settings/InboundInvoiceSettings';

import { GeneralTabPanel, MoreTabPanel } from './components/tab-panels';
import { RE_VIEW_MODEL_DATA } from './constants';
import { CurrencyExchangeInfoContextProvider } from './CurrencyExchangeInfoContextProvider';
import { useSkontoStartDate, useSkontoUpdate } from './hooks/use-skonto-update';
import {
  ReViewModelFormValue,
  ReViewModelLocalData,
  ReViewModelRemoteData,
} from './types';
import { useShadowReObjectApi } from './use-re-shadow-object-api/use-shadow-re-object-api';
import { ShadowReObjectApiContextProvider } from './use-re-shadow-object-api/use-shadow-re-object-api-context';

export function InboundInvoiceOverlayController(props: MaskControllerProps) {
  const { t } = useTranslation();
  const { onAfterSave } = props;
  const mask = useMaskConfig(props);

  const lock = useMaskLock(mask);

  const shouldUseRegularQuery = !lock.isLoading && lock.isLocked;
  const shouldUseShadowObjectApi = !lock.isLoading && !lock.isLocked;

  const schema = useEntityJsonSchema(mask.entity);

  const customRules = useCallback(
    (input: ReViewModelRemoteData) => {
      if (!input.businessPartnerId || !input.supplier) {
        return {
          supplier: {
            message: t('ERROR.FIELD_REQUIRED'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const resolver = useJSONSchemaResolver(schema, customRules);
  const user = useUser();
  const template = parseTemplate(mask);

  const isProjectBased =
    template?.entity === Entities.project && mask.isCreateMode;

  const initialProps = useErpInitialView(
    isProjectBased || (!template && !mask.id),
    onAfterSave
  );

  const parentId = isProjectBased
    ? initialProps.businessPartner?.id
    : template?.businessPartnerId || initialProps.businessPartner?.id;

  const query = useDataProvider(
    useMemo(() => {
      const data = RE_VIEW_MODEL_DATA;

      return {
        skip: !shouldUseRegularQuery,
        filter: [{ id: { $eq: mask.id } }],
        entity: mask.entity,
        data,
      };
    }, [mask.entity, mask.id, shouldUseRegularQuery])
  );

  const [shadowObject, shadowObjectApi] = useShadowReObjectApi({
    entity: mask.entity,
    id: mask.id,
    parentEntity:
      template?.businessPartnerType && !isProjectBased
        ? template?.businessPartnerType
        : Entities.supplier,
    parentId,
    skip:
      (isProjectBased && !initialProps.businessPartner) ||
      !shouldUseShadowObjectApi ||
      (mask.isCreateMode && !template && !initialProps.businessPartner),
    projectId: isProjectBased ? template.id : undefined,
  });

  const entityData =
    (shouldUseRegularQuery
      ? query.data[0]
      : shouldUseShadowObjectApi
      ? shadowObject?.data
      : null) ?? null;

  const localState = useRef<ReViewModelLocalData>({
    localOrders: [],
    localInboundDeliveryNotes: [],
  });

  const extendedState = useMemo(() => {
    if (shadowObject?.type === 'create') {
      localState.current.localOrders = shadowObject.data.orders;
      localState.current.localInboundDeliveryNotes =
        shadowObject.data.inboundDeliveryNotes;
    }
    return {
      ...entityData,
      ...localState.current,
    };
  }, [shadowObject, entityData]);

  const data: ReViewModelFormValue = extendedState;

  const form = useFormPlus<ReViewModelFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    context: {
      schema,
    },
  });

  const startDate = useSkontoStartDate();
  useSkontoUpdate(form);

  const { formState, handleSubmit, getValues } = form;

  useEffect(() => {
    const normalizeData = normalizeFormValue(data);
    // Resetting values is an expensive operation. If the value hasn't changed,
    // we can skip it to improve responsiveness. We still need to reset to
    // update dirty fields.
    form.reset(normalizeData, {
      keepValues: isEqual(form.getValues(), normalizeData),
      keepErrors: true,
    });

    // Every time the form is reset we reset the dirty fields.
    persistedDirtyFieldsRef.current = {};
  }, [form, data]);

  useFormUpdate(
    {
      approvedByUser: (user: User) => {
        return {
          approvedByUserId: user?.id || 0,
        };
      },
      releasedByUser: (user: User) => {
        return {
          releasedByUserId: user?.id || 0,
        };
      },
      blockedByUser: (user: User) => {
        return {
          blockedByUserId: user?.id || 0,
        };
      },
      currency: (currency: Currency) => {
        return {
          currencyId: currency?.id || 0,
        };
      },
      bankAccount: (bankDetails: BankDetails) => {
        return {
          bankAccountId: bankDetails?.id || 0,
        };
      },
      supplier: (supplier: Supplier) => {
        return {
          bankAccount: undefined,
          businessPartnerId: supplier?.id || 0,
        };
      },
      paymentKind: (paymentKind: PaymentKind) => {
        return {
          paymentId: paymentKind ? paymentKind.id : 0,
        };
      },
      discountDays: (skontoDurationDays: number) => {
        const receivedDate = getValues(startDate);
        if (skontoDurationDays < 0) return { discountDays: 0 };
        if (!receivedDate || Number.isNaN(skontoDurationDays)) return {};
        const date = DateTime.fromISO(receivedDate);
        const discountDate = date.plus({ days: skontoDurationDays }).toISO();
        return { discountDate };
      },
      discountDate: (skontoDate: string) => {
        const receivedDate = getValues(startDate);
        if (!receivedDate) return {};

        const date = DateTime.fromISO(receivedDate);
        const kontoDate = DateTime.fromISO(skontoDate);

        const discountDays = Number(kontoDate.diff(date, 'days').toFormat('d'));
        if (discountDays < 0)
          return { discountDays: 0, discountDate: receivedDate };
        return { discountDays };
      },
      discount2Days: (skonto2DurationDays: number) => {
        const receivedDate = getValues(startDate);
        if (skonto2DurationDays < 0) return { discount2Days: 0 };
        if (!receivedDate || Number.isNaN(skonto2DurationDays)) return {};
        const date = DateTime.fromISO(receivedDate);
        const discount2Date = date.plus({ days: skonto2DurationDays }).toISO();
        return { discount2Date };
      },
      discount2Date: (skonto2Date: string) => {
        const receivedDate = getValues(startDate);
        if (!receivedDate) return {};

        const date = DateTime.fromISO(receivedDate);
        const kontoDate = DateTime.fromISO(skonto2Date);

        const discount2Days = Number(
          kontoDate.diff(date, 'days').toFormat('d')
        );
        if (discount2Days < 0)
          return { discount2Days: 0, discount2Date: receivedDate };
        return { discount2Days };
      },
      dueDate: (dueDateIso: string) => {
        const date = getValues('dateOfReceipt');
        const invoiceDate = DateTime.fromISO(date);
        const dueDate = DateTime.fromISO(dueDateIso);
        const days = Number(dueDate.diff(invoiceDate, 'days').toFormat('d'));

        if (days <= 0) {
          return { dueDate: date };
        }
      },
      localOrders: (orders) => {
        localState.current.localOrders = orders;
        return {};
      },
      localInboundDeliveryNotes: (inboundDeliveryNotes) => {
        localState.current.localInboundDeliveryNotes = inboundDeliveryNotes;
        return {};
      },
    },
    form
  );

  const isDirty = shadowObjectApi.isDirty;

  const lastFormValues = useRef<ReViewModelFormValue>(null);

  // Keep track of fields that ever changed in the form since last reset. This
  // is required to determine if a field was changed even if the value is the
  // same as the initial one.
  const persistedDirtyFieldsRef = useRef<
    (typeof form)['formState']['dirtyFields']
  >({});

  const shadowObjectApiModify = shadowObjectApi.modify;

  useEffect(() => {
    const subscription = form.watch((formValues, info) => {
      // Updates caused by calling `form.reset` will have an empty `name`
      // property. These should be skipped.
      if (!info.name) return;

      const formDirtyFields = form.formState.dirtyFields;
      const persistentDirtyFields = persistedDirtyFieldsRef.current;

      // Send all fields that were changed since last reset.
      const dirtyFields = merge(persistentDirtyFields, formDirtyFields);

      const isValueEqual = isEqual(formValues, lastFormValues.current);
      const isBusinessPartnerSelected = !!formValues.supplier;
      const isFormDirty = Object.keys(dirtyFields).length > 0;

      if (isValueEqual || !isBusinessPartnerSelected || !isFormDirty) {
        return;
      }

      const { __typename, ...input } = formValues;
      // TODO: problem is that DatePicker when clear return '' value instead of null
      if (!input.plannedDeliveryDate) input.plannedDeliveryDate = null;
      if (!input.deliveryDate) input.deliveryDate = null;
      if (!input.discount2Date) input.discount2Date = null;
      if (!input.discountDate) input.discountDate = null;
      if (input.paymentTermDays && typeof input.paymentTermDays === 'string')
        input.paymentTermDays = parseInt(input.paymentTermDays);

      const toModify = pickUpdateFields(input, dirtyFields);

      lastFormValues.current = cloneDeep(formValues);
      persistedDirtyFieldsRef.current = dirtyFields;

      shadowObjectApiModify(toModify, {
        assignedDeliveryNotes: input.localInboundDeliveryNotes.map((x) => x.id),
        assignedOrders: input.localOrders.map((x) => x.id),
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, shadowObjectApiModify]);

  const entityRights = useMemo(
    () => ({
      create: canAddInboundInvoice(user),
      read: false,
      update: canEditInboundInvoice(user, data as InboundInvoice<EMode.entity>),
      delete: canDeleteInboundInvoice(
        user,
        data as InboundInvoice<EMode.entity>
      ),
    }),
    [data, user]
  );

  const receipt = useMemo(() => {
    return data?.receipts?.map((x) => {
      return {
        ...x,
      };
    });
  }, [data?.receipts]);

  const tempFileManager = useTempFileManager(receipt);

  const attachments =
    useAttachementsRelation<InputErpAnhangAttachementsRelation>(
      tempFileManager,
      Entities.erpAttachment,
      'id'
    );

  const attachementsDirty = attachments?.attachements
    ? Object.entries(attachments?.attachements)
        .filter((x) => x[1])
        .some((x) => x[1].length)
    : false;

  const onSubmit = useCallback(async () => {
    const submitShadowObject = async () => {
      await shadowObjectApi.persist(undefined, attachments?.attachements);
      onAfterSave?.(null);
    };

    await submitShadowObject();
  }, [attachments, shadowObjectApi, onAfterSave]);

  const computedIsDirty = attachementsDirty || isDirty || formState.isDirty;
  useConfirmBeforeCloseMask(computedIsDirty);

  const { go } = useHistoryStack();

  const openSettings = useCallback(() => {
    go({
      title: t('SETTINGS.SETTINGS'),
      subTitle: '',
      view: <InboundInvoiceSettings amplitudeEntryPoint="InboundInvoiceMask" />,
    });
  }, [go, t]);

  const { permissions } = usePermissions();
  const hasPreviewFeatures = !permissions.inboundInvoice.isPreview();

  const bt = useBinaryTransfer();

  useEffect(() => {
    if (mask.params?.basedon === 'transfer' && bt.transferData) {
      const files = bt.transferData;

      if (!(files?.length > 0)) {
        return;
      }

      tempFileManager.uploadFiles(files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LockOverride
      forceLock={data.isPayed || data.amountGross > 0 || !hasPreviewFeatures}
      lockReason={
        hasPreviewFeatures
          ? t('ALERTS.INCOMING_INVOICE_PAYMENTS_EXIST_NO_EDIT', {
              number: data.invoiceNumber,
            })
          : t('MASK.ERP.PREVIEW')
      }
    >
      <MaskContextProvider value={mask}>
        <EntityRightsContext.Provider value={entityRights}>
          <TempFileManagerContext.Provider value={tempFileManager}>
            <CurrencyExchangeInfoContextProvider>
              <ShadowReObjectApiContextProvider value={shadowObjectApi}>
                <FormProvider {...form}>
                  <MaskTabContext
                    defaultValue={
                      mask.params?.tab || props.openTab || 'general'
                    }
                  >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <MaskOverlayHeader
                        title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                        subTitle={data?.supplier?.name}
                        actions={
                          <>
                            <MaskOverlaySubmitButton
                              loading={formState.isSubmitting}
                              disabled={!computedIsDirty}
                            />
                            <MaskOverlayFullscreenToggleButton />
                            <MaskOverlayMenuWrapper>
                              <MenuItem onClick={openSettings}>
                                {t('MASK.SETTINGS')}
                              </MenuItem>
                            </MaskOverlayMenuWrapper>
                          </>
                        }
                        tabs={<Tabs isCreateMode={mask.isCreateMode} />}
                      />
                      <Content isCreateMode={mask.isCreateMode} />
                      <ErpInitialView
                        {...initialProps}
                        creditor
                        entity={Entities.supplier}
                      />
                    </Form>
                  </MaskTabContext>
                </FormProvider>
              </ShadowReObjectApiContextProvider>
            </CurrencyExchangeInfoContextProvider>
          </TempFileManagerContext.Provider>
        </EntityRightsContext.Provider>
      </MaskContextProvider>
    </LockOverride>
  );
}

const Tabs = memo(function AppointmentTabs({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value={'general'} label={t('MASK.GENERAL')} />
      <MaskTab value={'more'} label={t('MASK.MORE')} />
      <MaskTab
        disabled
        value={INDIVIDUAL_TAB_ID}
        label={t('MASK.INDIVIDUAL')}
      />
      <MaskTab
        value="history"
        disabled={isCreateMode}
        label={t('MASK.HISTORY')}
      />
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  return (
    <AttachmentsDropZone>
      <MaskContent>
        <MaskTabPanel value={'general'}>
          <GeneralTabPanel />
        </MaskTabPanel>

        <MaskTabPanel value={'more'}>
          <MoreTabPanel />
        </MaskTabPanel>

        {!isCreateMode && (
          <MaskTabPanel value="history">
            <HistoryTabPanel />
          </MaskTabPanel>
        )}
      </MaskContent>
    </AttachmentsDropZone>
  );
});
