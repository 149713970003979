import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputArticlePricesSetSimplePrice } from './InputArticlePricesSetSimplePrice.entity';

export class InputArticlePrices<T extends EMode = EMode.entity> {
  /** Alias: simplePrices */
  simplePrices?: InputArticlePricesSetSimplePrice<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputArticlePrices> = {
  simplePrices: {
    alias: 'simplePrices',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticlePricesEntityDefinition: EntitiyDefinition<InputArticlePrices> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticlePrices',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
