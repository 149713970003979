import { useEventCallback } from '@mui/material/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { EmailTemplatesPopover, useDialogs } from '@work4all/components';
import { getSortedEmailTemplateList } from '@work4all/components/lib/components/entity-picker/email-template-picker/utils';
import { useEmailTemplates } from '@work4all/components/lib/hooks/use-email-templates';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../settings';
import { MaskConfig, MaskTemplateEntity } from '../../mask-overlay/types';
import {
  EmailMaskCustomParams,
  EmailOverlayController,
} from '../../mask-overlay/views/email/EmailOverlayController';

interface EmailParams {
  entityTemplate?: MaskTemplateEntity | null;
  params?: MaskConfig<EmailMaskCustomParams>['params'];
}

export interface EmailTemplateButtonContextProps {
  kind: EMailTemplateKind | EMailTemplateKind[];
  mainKind?: EMailTemplateKind;
  noTemplate?: boolean;
  isSplit?: boolean;
  languageId?: number;
  overrideRecipients?: string;
  getEmailParams: (
    template: EMailTemplate,
    context?: unknown
  ) => Promise<EmailParams>;
}

interface EmailTemplateButtonContextState {
  emailTempaltePickerFieldRef: React.MutableRefObject<undefined>;
  onClick: () => void;
  onArrowClick: () => void;
}

const EmailTemplateButtonContext =
  createContext<EmailTemplateButtonContextState | null>(null);

export const EmailTemplateButtonProvider = (
  props: PropsWithChildren<EmailTemplateButtonContextProps>
) => {
  const {
    children,
    noTemplate,
    kind,
    isSplit,
    mainKind,
    overrideRecipients,
    ...args
  } = props;

  const dialogs = useDialogs();

  const [pickEmailTemplateOpen, setPickEmailTemplateOpen] = useState(false);
  const emailTempaltePickerFieldRef = useRef();
  const templates = useEmailTemplates(kind);

  const { value: standardTemplates, set: setStandardTemplate } = useSetting(
    settings.standardEmailTemplates()
  );
  const validTemplates = Array.isArray(templates) ? templates : [];

  const { mainLanguageTemplates, mainKindTemplates, restTemplates } =
    getSortedEmailTemplateList(
      validTemplates,
      standardTemplates,
      kind,
      mainKind
    );

  const openEmailMaskForTemplate = useEventCallback(
    async (template: EMailTemplate) => {
      const emailParams = await args.getEmailParams(template);
      if (!emailParams) return;

      const { entityTemplate, params } = emailParams;

      const initialView: IStackItem = {
        view: (
          <EmailOverlayController
            entity={Entities.eMail}
            id={null}
            template={entityTemplate}
            onAfterSave={closeMaskOverlay}
            params={{
              basedon: 'EmailTemplate',
              emailTemplate: template ? JSON.stringify(template) : undefined,
              forceNoTemplate: (template === null).toString(),
              mainEmailTemplateKind: mainKind,
              overrideRecipients,
              ...params,
            }}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close() {
          dialogs.close(dialog.id);
        },
      });

      function closeMaskOverlay() {
        dialogs.close(dialog.id);
      }
    }
  );

  const value = useMemo(() => {
    return {
      emailTempaltePickerFieldRef,
      onClick: () => {
        if (isSplit) {
          openEmailMaskForTemplate(mainLanguageTemplates[0]);
        } else if (mainLanguageTemplates.length > 1) {
          setPickEmailTemplateOpen(true);
        } else {
          openEmailMaskForTemplate(mainLanguageTemplates[0]);
        }
      },
      onArrowClick: () => {
        if (
          mainLanguageTemplates.length > 1 ||
          (mainLanguageTemplates.length === 1 && noTemplate)
        ) {
          setPickEmailTemplateOpen(true);
        } else {
          openEmailMaskForTemplate(mainLanguageTemplates[0]);
        }
      },
    };
  }, [isSplit, mainLanguageTemplates, noTemplate, openEmailMaskForTemplate]);

  return (
    <EmailTemplateButtonContext.Provider value={value}>
      {pickEmailTemplateOpen && (
        <EmailTemplatesPopover
          noTemplate={noTemplate}
          mainTemplates={props.mainKind ? mainKindTemplates : null}
          templates={props.mainKind ? restTemplates : mainLanguageTemplates}
          anchorEl={emailTempaltePickerFieldRef.current}
          onClose={() => setPickEmailTemplateOpen(false)}
          onTemplateClick={(template: EMailTemplate) => {
            setPickEmailTemplateOpen(false);
            openEmailMaskForTemplate(template);
          }}
          onStandardSelect={(template: EMailTemplate) => {
            setStandardTemplate({
              ...standardTemplates,
              [template.eMailTemplateKind]:
                standardTemplates[template.eMailTemplateKind] !== template.id
                  ? template.id
                  : null,
            });
          }}
        />
      )}

      {children}
    </EmailTemplateButtonContext.Provider>
  );
};

export const useEmailTemplateButton = () => {
  const state = useContext(EmailTemplateButtonContext);
  if (!state) {
    throw new Error(
      'Context is null. Make sure you wrapped your component with <EmailTemplateButtonContext />'
    );
  }
  return state;
};
