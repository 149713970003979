import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  formatVatRate,
  IVatPickerProps,
  VatPicker,
} from '@work4all/components/lib/components/entity-picker/vat-picker/VatPicker';

import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface VatRatePickerFieldProps
  extends Omit<IVatPickerProps<false>, 'multiple' | 'onChange' | 'value'> {
  error?: string;
  name?: string;
  label?: string;
  value: VatRate;
  onChange: (vat: VatRate) => void;
}

export const VatRatePickerField = React.forwardRef<
  HTMLDivElement,
  VatRatePickerFieldProps
>(function VatRatePickerField(props, ref) {
  const { error, label, ...pickerProps } = props;
  const { value } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={<VatPicker {...pickerProps} clearable={false} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={formatVatRate(value)}
        label={label ?? t('COMMON.VAT')}
        error={error}
      />
    </EntityPickerPopover>
  );
});
