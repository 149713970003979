import styles from './Delivery.module.scss';

import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { DateTimeInputPicker } from '../../../../../../../../locked-inputs';
import { Collapse } from '../../../../../../../components';
import { ReViewModelFormValue } from '../../../../../types';

export function Delivery() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ReViewModelFormValue>();
  const { register } = form;

  return (
    <Collapse
      className={styles.delivery}
      title={t('MASK.DELIVERY')}
      defaultOpen
    >
      <Stack spacing={2}>
        <Grid container direction="row" spacing={2} columns={2}>
          <Grid item xs>
            <DateTimeInputPicker
              dateLabel={t('INPUTS.PLANNED_DELIVERY_DATE')}
              withTime={false}
              {...register('plannedDeliveryDate')}
            />
          </Grid>

          <Grid item xs>
            <DateTimeInputPicker
              dateLabel={t('INPUTS.ACTUAL_DELIVERY_DATE')}
              withTime={false}
              {...register('deliveryDate')}
            />
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" spacing={0.5} px={1}>
          <InfoIcon color="primary" fontSize="medium" />

          <Typography variant="caption">
            {t('MASK.DELIVERY_RELIABILITY_INFO')}
          </Typography>
        </Stack>
      </Stack>
    </Collapse>
  );
}
