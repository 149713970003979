import { TextmarksList } from '../../types';

import { EMAIL_TEXTMARKS } from './email-textmarks';

export const TICKET_TEXTMARKS: TextmarksList = {
  ...EMAIL_TEXTMARKS,
  ticket: {
    id: 'TEXTMARK.TICKET',
    name: 'TEXTMARK.TICKET',
    subItems: [
      {
        id: 'TEXTMARK.TICKET_NUMBER',
        name: 'TEXTMARK.TICKET_NUMBER',
        accessor: 'ticket.nummer',
      },
      {
        id: 'TEXTMARK.TICKET_TITLE',
        name: 'TEXTMARK.TICKET_TITLE',
        accessor: 'ticket.titel',
      },
      {
        id: 'TEXTMARK.TICKET_PROBLEMDESCRIPTION',
        name: 'TEXTMARK.TICKET_PROBLEMDESCRIPTION',
        accessor: 'ticket.problembeschreibung',
      },
      {
        id: 'TEXTMARK.TICKET_SOLUTION',
        name: 'TEXTMARK.TICKET_SOLUTION',
        accessor: 'ticket.loesungsText',
      },
    ],
  },
};
