import Add from '@mui/icons-material/Add';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BankDetailsPickerProps } from '@work4all/components/lib/components/entity-picker/BankDetailsPicker';
import {
  EntityPickerActionBar,
  EntityPickerActionButton,
} from '@work4all/components/lib/components/entity-picker/components';

import { useDataMutation, useDataProvider } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputbankAccountRelationAddModify } from '@work4all/models/lib/Classes/InputbankAccountRelationAddModify.entity';
import { InputKundeRelation } from '@work4all/models/lib/Classes/InputKundeRelation.entity';
import { InputLieferantRelation } from '@work4all/models/lib/Classes/InputLieferantRelation.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { BusinessPartners } from '../../../../containers/mask-overlays/mask-overlay/views/businessPartners/BusinessPartnerOverlayController';
import { BankDetailsPopover } from '../../../../containers/mask-overlays/mask-overlay/views/businessPartners/components/tab-panels/erp/components/bank-details/components/bank-details-popover/BankDetailsPopover';

interface BankDetailsPickerFieldFooterProps
  extends Omit<BankDetailsPickerProps, 'onChange'> {
  onChange: (value: BankDetails<EMode.entity>) => void;
}

export const BankDetailsPickerFieldFooter: React.FC<
  BankDetailsPickerFieldFooterProps
> = (props) => {
  const { t } = useTranslation();

  const { onChange, entity, entityId } = props;
  const businessPartnerId = entityId;

  const popoverState = usePopoverState();

  const businessPartnerRequestRequestData = useMemo(() => {
    return {
      entity,
      data: {
        id: null,
        bankDetailsList: {
          id: null,
          isMainBank: null,
        },
      },
      filter: [
        {
          id: {
            $eq: entityId,
          },
        },
      ],
    };
  }, [entity, entityId]);
  const businessPartnerResponse = useDataProvider<Supplier | Customer>(
    businessPartnerRequestRequestData
  );
  const bankDetailsList = businessPartnerResponse.data?.[0]?.bankDetailsList;
  const currentMainBank = bankDetailsList?.find((bank) => !!bank.isMainBank);

  const [mutate] = useDataMutation<
    BusinessPartners,
    EMode.upsert,
    InputKundeRelation | InputLieferantRelation
  >({
    entity,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
      bankDetailsList: [
        {
          id: null,
          iban: null,
          accountNumber: null,
        },
      ],
    },
    resetStore: false,
    onCompleted: () => {
      popoverState.onClose();
    },
  });

  const handleSubmitBankDetails = async (submitData: BankDetails) => {
    const relations: InputKundeRelation | InputLieferantRelation = {
      bankAccounts: {
        addModify: [
          {
            accountowner: submitData.accountOwner,
            bankAccountNumber: submitData.accountNumber,
            bIC: submitData.bic,
            code: submitData.id,
            iBAN: submitData.iban,
            ledger: submitData.ledgerAccountId,
            mandateDate: submitData.mandateDate?.length
              ? submitData.mandateDate
              : null,
            mandateRefNo: submitData.mandateReferenceNumber,
            name: submitData.name,
            sEPACreditorID: submitData.sepaCreditorId,
            sortCode: submitData.bankSortCode,
            mainBank: Boolean(submitData.isMainBank),
          } satisfies InputbankAccountRelationAddModify,
        ],
      },
    };

    if (currentMainBank && !!submitData.isMainBank) {
      relations.bankAccounts.addModify.push({
        code: currentMainBank.id,
        mainBank: false,
      } satisfies InputbankAccountRelationAddModify);
    }

    const resData = await mutate({ id: businessPartnerId }, { relations });
    const newBankAccount =
      resData &&
      'bankDetailsList' in resData &&
      resData.bankDetailsList.filter(
        (resBank) => !bankDetailsList.some((bank) => bank.id === resBank.id)
      )?.[0];

    if (newBankAccount) {
      onChange({
        ...submitData,
        id: newBankAccount.id,
      });
    }
  };

  return (
    <EntityPickerActionBar>
      <EntityPickerActionButton
        startIcon={<Add />}
        onClick={() => {
          popoverState.handleClick();
        }}
      >
        {t('COMMON.NEW')}
      </EntityPickerActionButton>

      <BankDetailsPopover
        onSubmit={handleSubmitBankDetails}
        popoverState={popoverState}
        newBankList={[]}
      />
    </EntityPickerActionBar>
  );
};
