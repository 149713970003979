import { InputRaZahlung } from '@work4all/models/lib/Classes/InputRaZahlung.entity';

import { Payment } from '../types';

export const paymentToInputPayment = (payment: Payment): InputRaZahlung => {
  return {
    code: typeof payment?.id === 'number' ? payment?.id : undefined,
    benutzerCode: payment?.user?.id,
    buchungsDatum: payment?.bookingDate,
    summe: payment?.amount,
    notiz: payment?.note,
    sachkontoCode: payment?.ledgerAccount?.id,
    datum: payment?.date,
  };
};
