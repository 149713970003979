import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputTermin<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: sdObjMemberCode */
  sdObjMemberCode?: number;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: datumVon */
  datumVon?: string;
  /** Alias: datumBis */
  datumBis?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: besuchsberichtCode */
  besuchsberichtCode?: number;
  /** Alias: erinnern */
  erinnern?: boolean;
  /** Alias: privat */
  privat?: boolean;
  /** Alias: ganztags */
  ganztags?: boolean;
  /** Alias: ort */
  ort?: string;
  /** Alias: parentCode */
  parentCode?: number;
  /** Alias: ersteller */
  ersteller?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: erinnerung */
  erinnerung?: string;
  /** Alias: farbenCode */
  farbenCode?: number;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: titel */
  titel?: string;
  /** Alias: urlaubCode */
  urlaubCode?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: positionCode */
  positionCode?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberCode?: number;
  /** Alias: anzahlTageProjektplanung */
  anzahlTageProjektplanung?: number;
  /** Alias: kostenerfassungCode */
  kostenerfassungCode?: number;
  /** Alias: prioritaet */
  prioritaet?: number;
  /** Alias: projektPlanungCode */
  projektPlanungCode?: number;
  /** Alias: projektPlanungDauer */
  projektPlanungDauer?: number;
  /** Alias: projektPlanungArt */
  projektPlanungArt?: number;
  /** Alias: serientermin */
  serientermin?: boolean;
  /** Alias: serienterminDefinitionCode */
  serienterminDefinitionCode?: number;
  /** Alias: exchangeSerientermin */
  exchangeSerientermin?: boolean;
  /** Alias: exchangeConversationId */
  exchangeConversationId?: string;
  /** Alias: exchangeId */
  exchangeId?: string;
  /** Alias: reisekostenabrechnungCode */
  reisekostenabrechnungCode?: number;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: echterTermin */
  echterTermin?: boolean;
  /** Alias: ticketId */
  ticketId?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: icsUid */
  icsUid?: string;
  /** Alias: exchangeMeeting */
  exchangeMeeting?: boolean;
  /** Alias: exchangeTeilnehmer */
  exchangeTeilnehmer?: string;
  /** Alias: meetingUrl */
  meetingUrl?: string;
  /** Alias: fromAbsolute */
  fromAbsolute?: string;
  /** Alias: toAbsolute */
  toAbsolute?: string;
  /** Alias: lieferscheinCode */
  lieferscheinCode?: number;
  /** Alias: lieferscheinPositionenCode */
  lieferscheinPositionenCode?: number;
  /** Alias: auftragCode */
  contractId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputTermin> = {
  code: {
    alias: 'code',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  sdObjMemberCode: {
    alias: 'sdObjMemberCode',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  projektCode: {
    alias: 'projektCode',
  },
  datumVon: {
    alias: 'datumVon',
  },
  datumBis: {
    alias: 'datumBis',
  },
  notiz: {
    alias: 'notiz',
  },
  besuchsberichtCode: {
    alias: 'besuchsberichtCode',
  },
  erinnern: {
    alias: 'erinnern',
  },
  privat: {
    alias: 'privat',
  },
  ganztags: {
    alias: 'ganztags',
  },
  ort: {
    alias: 'ort',
  },
  parentCode: {
    alias: 'parentCode',
  },
  ersteller: {
    alias: 'ersteller',
  },
  creationDate: {
    alias: 'creationDate',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  erinnerung: {
    alias: 'erinnerung',
  },
  farbenCode: {
    alias: 'farbenCode',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  titel: {
    alias: 'titel',
  },
  urlaubCode: {
    alias: 'urlaubCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  positionCode: {
    alias: 'positionCode',
  },
  bzObjMemberCode: {
    alias: 'bzObjMemberCode',
  },
  anzahlTageProjektplanung: {
    alias: 'anzahlTageProjektplanung',
  },
  kostenerfassungCode: {
    alias: 'kostenerfassungCode',
  },
  prioritaet: {
    alias: 'prioritaet',
  },
  projektPlanungCode: {
    alias: 'projektPlanungCode',
  },
  projektPlanungDauer: {
    alias: 'projektPlanungDauer',
  },
  projektPlanungArt: {
    alias: 'projektPlanungArt',
  },
  serientermin: {
    alias: 'serientermin',
  },
  serienterminDefinitionCode: {
    alias: 'serienterminDefinitionCode',
  },
  exchangeSerientermin: {
    alias: 'exchangeSerientermin',
  },
  exchangeConversationId: {
    alias: 'exchangeConversationId',
  },
  exchangeId: {
    alias: 'exchangeId',
  },
  reisekostenabrechnungCode: {
    alias: 'reisekostenabrechnungCode',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  echterTermin: {
    alias: 'echterTermin',
  },
  ticketId: {
    alias: 'ticketId',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  icsUid: {
    alias: 'icsUid',
  },
  exchangeMeeting: {
    alias: 'exchangeMeeting',
  },
  exchangeTeilnehmer: {
    alias: 'exchangeTeilnehmer',
  },
  meetingUrl: {
    alias: 'meetingUrl',
  },
  fromAbsolute: {
    alias: 'fromAbsolute',
  },
  toAbsolute: {
    alias: 'toAbsolute',
  },
  lieferscheinCode: {
    alias: 'lieferscheinCode',
  },
  lieferscheinPositionenCode: {
    alias: 'lieferscheinPositionenCode',
  },
  contractId: {
    alias: 'auftragCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTerminEntityDefinition: EntitiyDefinition<InputTermin> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputTermin',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
