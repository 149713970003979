import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputAufgabe<T extends EMode = EMode.entity> {
  /** Alias: ansprpCode */
  ansprpCode?: number;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: autoWeiterleiten */
  autoWeiterleiten?: number;
  /** Alias: bCode */
  bCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: bErstellt */
  bErstellt?: string;
  /** Alias: bZObjMemberCode */
  bZObjMemberCode?: number;
  /** Alias: bZObjType */
  bZObjType?: BzObjType;
  /** Alias: checklisteCode */
  checklisteCode?: number;
  /** Alias: checklistePositionenCode */
  checklistePositionenCode?: number;
  /** Alias: code */
  code?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: enddatum */
  enddatum?: string;
  /** Alias: erinnernDate */
  erinnernDate?: string;
  /** Alias: erledigt */
  erledigt?: number;
  /** Alias: erledigtNotiz */
  erledigtNotiz?: string;
  /** Alias: gespraechspunkteCode */
  gespraechspunkteCode?: number;
  /** Alias: kNBerichtCode */
  kNBerichtCode?: number;
  /** Alias: loeschvormerkung */
  loeschvormerkung?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: objGrCode */
  objGrCode?: number;
  /** Alias: outlook_EntryID */
  outlook_EntryID?: string;
  /** Alias: parentCode */
  parentCode?: number;
  /** Alias: prioritaet */
  prioritaet?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: projektePlanungCode */
  projektePlanungCode?: number;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: sDObjType */
  sDObjType?: SdObjType;
  /** Alias: sN */
  sN?: string;
  /** Alias: status */
  status?: any;
  /** Alias: stunden */
  stunden?: number;
  /** Alias: ticketCode */
  ticketCode?: number;
  /** Alias: ticketID */
  ticketID?: string;
  /** Alias: titel */
  titel?: string;
  /** Alias: wartungslauf */
  wartungslauf?: number;
  /** Alias: weiterleitenAnBCode */
  weiterleitenAnBCode?: number;
  /** Alias: weiterleitenDatum */
  weiterleitenDatum?: string;
  /** Alias: weiterleitenGrCode */
  weiterleitenGrCode?: number;
  /** Alias: weiterleitenStatusCode */
  weiterleitenStatusCode?: number;
  /** Alias: zeitbedarf */
  zeitbedarf?: number;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: gelesen */
  gelesen?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<InputAufgabe> = {
  ansprpCode: {
    alias: 'ansprpCode',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  autoWeiterleiten: {
    alias: 'autoWeiterleiten',
  },
  bCode: {
    alias: 'bCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  bErstellt: {
    alias: 'bErstellt',
  },
  bZObjMemberCode: {
    alias: 'bZObjMemberCode',
  },
  bZObjType: {
    alias: 'bZObjType',
  },
  checklisteCode: {
    alias: 'checklisteCode',
  },
  checklistePositionenCode: {
    alias: 'checklistePositionenCode',
  },
  code: {
    alias: 'code',
  },
  datum: {
    alias: 'datum',
  },
  enddatum: {
    alias: 'enddatum',
  },
  erinnernDate: {
    alias: 'erinnernDate',
  },
  erledigt: {
    alias: 'erledigt',
  },
  erledigtNotiz: {
    alias: 'erledigtNotiz',
  },
  gespraechspunkteCode: {
    alias: 'gespraechspunkteCode',
  },
  kNBerichtCode: {
    alias: 'kNBerichtCode',
  },
  loeschvormerkung: {
    alias: 'loeschvormerkung',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  notiz: {
    alias: 'notiz',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  outlook_EntryID: {
    alias: 'outlook_EntryID',
  },
  parentCode: {
    alias: 'parentCode',
  },
  prioritaet: {
    alias: 'prioritaet',
  },
  projektCode: {
    alias: 'projektCode',
  },
  projektePlanungCode: {
    alias: 'projektePlanungCode',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  sDObjType: {
    alias: 'sDObjType',
  },
  sN: {
    alias: 'sN',
  },
  status: {
    alias: 'status',
  },
  stunden: {
    alias: 'stunden',
  },
  ticketCode: {
    alias: 'ticketCode',
  },
  ticketID: {
    alias: 'ticketID',
  },
  titel: {
    alias: 'titel',
  },
  wartungslauf: {
    alias: 'wartungslauf',
  },
  weiterleitenAnBCode: {
    alias: 'weiterleitenAnBCode',
  },
  weiterleitenDatum: {
    alias: 'weiterleitenDatum',
  },
  weiterleitenGrCode: {
    alias: 'weiterleitenGrCode',
  },
  weiterleitenStatusCode: {
    alias: 'weiterleitenStatusCode',
  },
  zeitbedarf: {
    alias: 'zeitbedarf',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  gelesen: {
    alias: 'gelesen',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAufgabeEntityDefinition: EntitiyDefinition<InputAufgabe> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputAufgabe',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
