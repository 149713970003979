import { Box, Theme, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { WebsiteFavicon } from '@work4all/components';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ISupplierPickerProps,
  SupplierPicker,
} from '@work4all/components/lib/components/entity-picker/supplier-picker/SupplierPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ISupplierPickerProps<false>, 'multiple'> {
  error?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const SupplierPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const { disabled, error, favorites = true, ...pickerProps } = props;
    const { value, onChange } = pickerProps;
    const popoverRef = useRef<EntityPickerPopover>(null);
    const entityTemplate = useEntityTemplate();

    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('sm')
    );

    return (
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        disabled={disabled}
        picker={
          <SupplierPicker
            favorites={favorites}
            {...pickerProps}
            multiple={false}
          />
        }
        footer={entityTemplate.renderPickerFooter({
          popoverRef,
          entity: Entities.supplier,
          ...props,
        })}
      >
        <PickerTargetButton
          ref={ref}
          onClear={() => {
            onChange(null);
          }}
          disabled={disabled}
          label={props.label || t('COMMON.SUPPLIER')}
          error={error}
          required={props.required}
          value={
            value ? (
              <Box
                paddingTop="1rem"
                display="flex"
                gap="0.5rem"
                alignItems="center"
              >
                {<WebsiteFavicon website={value?.website} />} {value?.name}
              </Box>
            ) : (
              ''
            )
          }
        />
      </EntityPickerPopover>
    );
  }
);
