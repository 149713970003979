import { Base } from '../../../input/actions/base/Base';
import { IconButtonWithTooltip } from '../../../input/actions/IconButtonWithTooltip';
import { CustomToolbarMappedAction } from '../ToolbarTypes';

export const mapCustomActionsToComponent = (
  actions: CustomToolbarMappedAction[]
) => {
  return (
    actions?.map((action) => {
      if (action.tooltip) {
        return (
          <IconButtonWithTooltip
            key={action.actionKey}
            rootRef={action.rootRef}
            icon={
              <action.IconComponent
                style={{ height: '1.5rem', width: '1.5rem' }}
              />
            }
            onClick={action.onClick}
            tooltip={action.tooltip}
            disabled={action.disabled}
            disableReason={action.disableReason}
          />
        );
      }
      return (
        <Base
          rootRef={action.rootRef}
          key={action.actionKey}
          icon={
            <action.IconComponent
              style={{ height: '1.5rem', width: '1.5rem' }}
            />
          }
          title={action.title}
          {...action}
        />
      );
    }) || []
  );
};
