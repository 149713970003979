import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CityPicker,
  CityPickerProps,
} from '@work4all/components/lib/components/entity-picker/city-picker/CityPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface CityPickerFieldProps
  extends Omit<
    CityPickerProps,
    'multiple' | 'value' | 'onChange' | 'canWriteValue'
  > {
  error?: string;
  postalCodeFilter?: string;
  value: string;
  onChange: (value: string | null) => void;
  onClick?: () => void;
  label?: string;
  canWriteValue?: boolean;
}

export const CityPickerField = React.forwardRef<
  HTMLDivElement,
  CityPickerFieldProps
>(function CityPickerField(props, ref) {
  const { error, value, label, onChange, canWriteValue, ...pickerProps } =
    props;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={
        <CityPicker
          {...pickerProps}
          value={value ? { id: null, city: value } : null}
          onChange={(e) => {
            onChange(e?.city ?? null);
          }}
          canWriteValue={canWriteValue}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        value={value ?? ''}
        label={label || t('INPUTS.LOCATION')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
