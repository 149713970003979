import { Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { IResponse } from '@work4all/data';

import { UserGroup } from '@work4all/models/lib/Classes/UserGroup.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IUserGroupPickerProps<TMultiple extends boolean> = IPickerProps<
  UserGroup,
  TMultiple
>;

export function UserGroupPicker<TMultiple extends boolean>(
  props: IUserGroupPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...USER_GROUP_FIELDS, ...data };
  }, [data]);

  const renderUser = (userGroup: UserGroup) => {
    if (props) return <UserGroupRow userGroup={userGroup} />;
  };

  return (
    <ListEntityPicker
      entity={Entities.userGroup}
      data={allFields}
      filterBy="name"
      sortBy="name"
      {...rest}
      renderItemContent={renderUser}
      transformResponse={(response: IResponse<UserGroup<EMode.entity>>) => {
        return {
          ...response,
          data: sortBy(response.data, 'name'),
        } as IResponse<UserGroup<EMode.entity>>;
      }}
      completeDataResponse
    />
  );
}
const USER_GROUP_FIELDS: UserGroup = {
  id: null,
  name: null,
  grIndex: null,
  grLevel: null,
};

export const UserGroupRow = ({ userGroup }: { userGroup: UserGroup }) => {
  return (
    <div>
      <Typography noWrap>{userGroup.name}</Typography>
    </div>
  );
};
