import styles from './RelevantCard.module.scss';

import { IconButton, Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import {
  AppParts,
  Link,
  useCanView,
  useHiddenEntities,
  useModuleRights,
  useUser,
} from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

import {
  makeTableSettings,
  stringifyTableSettings,
  TableSettings,
} from '../../../../../components/data-tables/make-table-settings';
import { settings, useSetting } from '../../../../../settings';
import { WEEK_IN_MILLISECONDS } from '../../../../calendar/constants';
import { CollapsibleCard } from '../../CollapsibleCard/CollapsibleCard';
import { CardWidgetProps } from '../types';

import { BirthdaysMenu } from './components/BirthdaysMenu';
import { SettingsMenu } from './components/SettingsMenu';
import { useRelevantData } from './use-relevant-data';

export const RelevantCard: React.FC<CardWidgetProps> = (
  props: CardWidgetProps
) => {
  const user = useUser();

  const { rights } = useModuleRights();

  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);
  const canViewBirthdays = useCanView(AppParts.BIRTHDAYS);
  const canViewVacations = useCanView(AppParts.VACATIONS);

  const startDate = useMemo(
    // adding a millisecond to avoid catching whole-day appointments from the day before
    () => DateTime.now().startOf('day').plus({ millisecond: 1 }),
    []
  );
  const endDate = useMemo(() => DateTime.now().endOf('day'), []);
  const endOfWeek = useMemo(
    () => DateTime.fromMillis(startDate.toMillis() + WEEK_IN_MILLISECONDS),
    [startDate]
  );

  const { isHidden } = useHiddenEntities();

  const {
    resAppointment,
    resTask,
    projectHoursState,
    vacation,
    resTicketS1,
    resTicketS2,
    resSalesOpportunities,
    resTravelReceipts,
    unreadEmailsToAssign,
    birthdays,
  } = useRelevantData({ user, startDate, endDate, endOfWeek });

  const { t } = useTranslation();

  const [showRelevantSettingsMenu, setShowRelevantSettingsMenu] =
    useState<boolean>(false);
  const [initialView, setInitialView] = useState<IStackItem | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSettingsClick = (event) => {
    setInitialView({
      title: t('COMMON.BIRTHDAY_PLURAL'),
      view: <BirthdaysMenu data={birthdays.data} />,
    });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const relevantWidgetView = useSetting(settings.relevantWidgetVisibleViews());

  const adminEnabledShowingBirthdays = useSetting(
    settings.adminEnabledShowingBirthdays()
  );

  const userEnabledShowingBirthdays = useSetting(
    settings.userEnabledShowingBirthdays()
  );

  const ticketsDeeplinkS1 = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status1',
          value: [
            {
              id: TicketStatus.OFFEN,
              name: t('TICKET_STATUS.' + TicketStatus.OFFEN),
            },
            {
              id: TicketStatus.IN_BEARBEITUNG,
              name: t('TICKET_STATUS.' + TicketStatus.IN_BEARBEITUNG),
            },
          ],
        },
        {
          id: 'editor1.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
        {
          id: 'followUpDate',
          value: {
            startDate: RelativeDateFilter.todayAndPast,
            endDate: null,
          },
        },
      ],
    };
    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const ticketsDeeplinkS2 = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status2',
          value: [
            {
              id: TicketStatus.OFFEN,
              name: t('TICKET_STATUS.' + TicketStatus.OFFEN),
            },
            {
              id: TicketStatus.IN_BEARBEITUNG,
              name: t('TICKET_STATUS.' + TicketStatus.IN_BEARBEITUNG),
            },
          ],
        },
        {
          id: 'editor2.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
        {
          id: 'followUpDate',
          value: {
            startDate: RelativeDateFilter.todayAndPast,
            endDate: null,
          },
        },
      ],
    };
    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const tasksDeeplink = useMemo(() => {
    const filter = makeTableSettings(Entities.task, {
      date: {
        value: {
          startDate: RelativeDateFilter.todayAndPast,
          endDate: null,
        },
      },
      status: {
        value: [
          {
            id: TaskStatus.OFFEN,
            name: t('TASK_STATUS.' + TaskStatus.OFFEN),
          },
          {
            id: TaskStatus.IN_BEARBEITUNG,
            name: t('TASK_STATUS.' + TaskStatus.IN_BEARBEITUNG),
          },
          {
            id: TaskStatus.WARTET,
            name: t('TASK_STATUS.' + TaskStatus.WARTET),
          },
        ],
      },
      user: {
        value: [
          {
            id: user.benutzerCode.toString(),
            displayName: user?.displayName,
          },
        ],
      },
    });

    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const salesOpportunitiesDeeplink = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status',
          value: [
            {
              id: SalesOpportunityStatus.ACTIVE,
              name: t(
                'COMMON.SALESOPPORTUNITIES.' + SalesOpportunityStatus.ACTIVE
              ),
            },
          ],
        },
        {
          id: 'user2.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
      ],
    };
    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const travelReceiptsDeepLink = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = {
      filter: [
        {
          id: 'travelExpenses.closedByuserid',
          value: {
            value: 'parked',
            filterType: 'TravelReceiptStatus',
          },
        },
      ],
    };
    filter.filter.push({
      id: 'travelExpenses.businessPartner.data.id',
      value: [
        {
          id: user.supplierCode.toString(),
          displayName: user.displayName,
        },
      ],
    });

    return stringifyTableSettings(filter);
  }, [user.supplierCode, user.displayName]);

  const { permissions } = usePermissions();

  const canSeeAppointments = !isHidden(Entities.appointment);
  const canSeeTasks = !isHidden(Entities.task);
  const canSeeTickets = !isHidden(Entities.ticket);
  const canSeeSalesOpportunities = !isHidden(Entities.salesOpportunities);
  const canSeeContacts = !isHidden(Entities.contact);
  const canSeeTravelReceipts =
    !isHidden(Entities.travelReceipts) && permissions.travelReceipts;

  const mailboxDeeplink = useMemo(() => {
    const f1: TableSettings = {
      filter: [
        {
          id: 'status',
          value: [
            {
              id: MailAssignStatus.NOT_YET_ASSIGNED,
              name: t('MAIL_ASSIGN_STATUS.NOT_YET_ASSIGNED'),
            },
          ],
        },
      ],
    };

    return stringifyTableSettings(f1);
  }, [endDate, t]);

  const projectTimeTracked = useMemo(() => {
    const time = projectHoursState?.selectedDay?.trackedTime / 60 ?? 0;
    const trackedTime = t('RELEVANT.PROJECT.TIME.HOURS_TRACKED', {
      hours: parseFloat(time.toFixed(1)).toLocaleString(),
    });

    if (isNaN(time)) {
      return <Skeleton width="5rem" height="1rem" />;
    }

    return trackedTime;
  }, [projectHoursState?.selectedDay?.trackedTime, t]);

  const birthdaysText = useMemo(() => {
    if (birthdays.total === 0) return t('BIRTHDAYS.NONE');

    const numberOfBirthdays = birthdays.todayTotal || birthdays.total;
    const when = birthdays.todayTotal > 0 ? 'today' : 'soon';

    return t('BIRTHDAYS', {
      context: when,
      count: numberOfBirthdays,
    });
  }, [birthdays, t]);

  return (
    <CollapsibleCard
      title={t('RELEVANT_WIDGET.TITLE')}
      headerContent={
        <IconButton
          onClick={() => setShowRelevantSettingsMenu(true)}
          size="medium"
          className={null}
        >
          <SettingsIcon />
        </IconButton>
      }
      {...props}
    >
      <div className={styles.wrap}>
        {!isTimeTrackingUser(rights) &&
          canSeeTasks &&
          relevantWidgetView.value.includes(Entities.task) && (
            <>
              <div className={styles.label}>{t('COMMON.TASK_plural')}:</div>
              <div className={styles.value}>
                {resTask.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link
                    className={styles.link}
                    to={'/more/entity/task?settings=' + tasksDeeplink}
                  >
                    {resTask?.total > 0
                      ? `${resTask?.total} ${t('COMMON.OPEN')}`
                      : t('COMMON.NONE_AVAILABLE')}
                  </Link>
                )}
              </div>
            </>
          )}

        {canSeeAppointments &&
          relevantWidgetView.value.includes(Entities.appointment) && (
            <>
              <div className={styles.label}>
                {t('COMMON.APPOINTMENT_plural')}:
              </div>
              <div className={styles.value}>
                {resAppointment.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link className={styles.link} to={`/more/calendar`}>
                    {resAppointment?.total > 0
                      ? `${resAppointment?.total} ${t('COMMON.TODAY.LOWER')}`
                      : t('COMMON.NONE_AVAILABLE')}
                  </Link>
                )}
              </div>
            </>
          )}

        {canSeeTickets &&
          relevantWidgetView.value.includes(Entities.ticket) && (
            <>
              <div className={styles.label}>{t('COMMON.TICKET_plural')}:</div>
              <div className={styles.value}>
                {resTicketS1.pending || resTicketS2.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : resTicketS1?.total === 0 && resTicketS2?.total === 0 ? (
                  <Link
                    className={styles.link}
                    to={'/more/entity/ticket?settings=' + ticketsDeeplinkS1}
                  >
                    {t('COMMON.NONE_AVAILABLE')}
                  </Link>
                ) : (
                  <>
                    {resTicketS1?.total ? (
                      <Link
                        className={styles.link}
                        to={'/more/entity/ticket?settings=' + ticketsDeeplinkS1}
                      >
                        {`${resTicketS1?.total} ${t('COMMON.OPEN')}`}
                      </Link>
                    ) : (
                      ''
                    )}
                    {resTicketS1?.total && resTicketS2?.total ? (
                      <span className={styles.label}>&nbsp;|&nbsp;</span>
                    ) : (
                      ''
                    )}

                    {resTicketS2?.total ? (
                      <Link
                        className={styles.link}
                        to={'/more/entity/ticket?settings=' + ticketsDeeplinkS2}
                      >
                        {`${resTicketS2?.total} ${t('COMMON.OPEN')}`}
                      </Link>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </>
          )}

        {canViewProjectTimeTracking &&
          relevantWidgetView.value.includes(Entities.timeTracking) && (
            <>
              <div className={styles.label}>{t('COMMON.PROJECTTIME')}:</div>
              <div className={styles.value}>
                <Link className={styles.link} to={'/more/timetracking'}>
                  <span className={styles.link}>{projectTimeTracked}</span>
                </Link>
              </div>
            </>
          )}

        {canViewVacations &&
          relevantWidgetView.value.includes(Entities.vacation) && (
            <>
              <div className={styles.label}>{t('COMMON.VACATION')}:</div>
              <div className={styles.value}>
                {vacation.isLoading ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link
                    className={styles.link}
                    rel="noreferrer"
                    to={'/more/vacation'}
                  >
                    {vacation.vacationStats.remaining.toLocaleString()}{' '}
                    {t('COMMON.DAYS_REMAINING')}
                    {` ${t('COMMON.FOR')} ${DateTime.now().year}`}
                  </Link>
                )}
              </div>
            </>
          )}
        {canSeeSalesOpportunities &&
          relevantWidgetView.value.includes(Entities.salesOpportunities) && (
            <>
              <div className={styles.label}>
                {t('COMMON.SALESOPPORTUNITIES')}:
              </div>
              <div className={styles.value}>
                {resSalesOpportunities.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link
                    className={styles.link}
                    to={
                      `/more/entity/salesOpportunities?settings=` +
                      salesOpportunitiesDeeplink
                    }
                  >
                    {resSalesOpportunities?.total > 0
                      ? `${resSalesOpportunities?.total} ${t('COMMON.OPEN')}`
                      : t('COMMON.NONE_AVAILABLE')}
                  </Link>
                )}
              </div>
            </>
          )}
        {canSeeContacts &&
          canViewBirthdays &&
          adminEnabledShowingBirthdays.value &&
          userEnabledShowingBirthdays.value && (
            <>
              <div className={styles.label}>{t('COMMON.BIRTHDAY_PLURAL')}:</div>
              <div className={styles.value}>
                {resSalesOpportunities.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <span
                    className={birthdays.total > 0 ? styles.link : null}
                    onClick={birthdays.total > 0 ? handleSettingsClick : null}
                  >
                    {birthdaysText}
                  </span>
                )}
              </div>
              <NavigationPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                initialView={initialView}
              />
            </>
          )}

        <div className={styles.label}>{t('LIST.MAILBOXCONTENT.INBOX')}:</div>
        <div className={styles.value}>
          {
            <Link
              className={styles.link}
              rel="noreferrer"
              to={`/more/entity/mailboxContent?settings=${mailboxDeeplink}`}
            >
              {unreadEmailsToAssign.totalUnread > 0
                ? t('LIST.MAILBOXCONTENT.UNREAD_INCOMING_EMAILS', {
                    count: unreadEmailsToAssign.totalUnread,
                  })
                : t('LIST.MAILBOXCONTENT.NONE_UNREAD')}
            </Link>
          }
        </div>

        {canSeeTravelReceipts &&
          relevantWidgetView.value.includes(Entities.travelReceipts) && (
            <React.Fragment>
              <div className={styles.label}>{t('COMMON.TRAVELRECEIPTS')}:</div>
              <div className={styles.value}>
                {resTravelReceipts.loading ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link
                    className={styles.link}
                    rel="noreferrer"
                    to={`/more/entity/travelReceipts?settings=${travelReceiptsDeepLink}`}
                  >
                    {resTravelReceipts?.total > 0
                      ? `${resTravelReceipts?.total} ${t('COMMON.OPEN')}`
                      : t('COMMON.NONE_AVAILABLE')}
                  </Link>
                )}
              </div>
            </React.Fragment>
          )}
        <NavigationOverlay
          classes={{
            wrapper: styles.dialogWrapper,
            paper: styles.paper,
          }}
          open={showRelevantSettingsMenu}
          initialView={{
            view: <SettingsMenu />,

            title: t('SETTINGS.SETTINGS'),
            subTitle: 'Relevant Widget',
          }}
          close={() => {
            setShowRelevantSettingsMenu(false);
          }}
        />
      </div>
    </CollapsibleCard>
  );
};
