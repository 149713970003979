import { useTranslation } from 'react-i18next';

import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';

type Props = {
  value: InvoiceForm;
};

export const InvoiceFormCell = ({ value }: Props) => {
  const { t } = useTranslation();

  return t(`INVOICE_FORM.${value}`);
};
