import i18next, { TFunction } from 'i18next';
import { DateTime } from 'luxon';

import { Event } from '@work4all/models/lib/Classes/Event.entity';

export interface GroupedEvents {
  label: string;
  events: Event[];
}

export function groupEventsByDate(
  events: Event[],
  t: TFunction
): GroupedEvents[] {
  const sections = [
    'future',
    'today',
    'yesterday',
    'thisWeek',
    'lastWeek',
    'thisMonth',
    'lastMonth',
    'thisYear',
    'lastYear',
  ];
  const now = DateTime.local();
  const dates = {
    future: now.startOf('day').plus({ days: 1 }),
    today: now.startOf('day'),
    yesterday: now.startOf('day').minus({ days: 1 }),
    thisWeek: now.startOf('week'),
    lastWeek: now.startOf('week').minus({ weeks: 1 }),
    thisMonth: now.startOf('month'),
    lastMonth: now.startOf('month').minus({ months: 1 }),
    thisYear: now.startOf('year'),
    lastYear: now.startOf('year').minus({ years: 1 }),
  };

  function formatLabel(mark: string, date: DateTime): string {
    const localizedDate = date.setLocale(i18next.language);
    switch (mark) {
      case 'future':
        return t('DATE_TIME.future');
      case 'today':
        return `${t('DATE_TIME.today')}, ${localizedDate.toFormat('EEEE')}`;
      case 'yesterday':
        return `${t('DATE_TIME.yesterday')}, ${localizedDate.toFormat('EEEE')}`;
      case 'thisWeek':
        return `${t('DATE_TIME.thisWeek')}, ${localizedDate.toFormat('EEEE')}`;
      case 'lastWeek':
        return `${t('DATE_TIME.lastWeek')}, KW ${localizedDate.toFormat('WW')}`;
      case 'thisMonth':
        return `${t('DATE_TIME.thisMonth')}, ${localizedDate.toFormat('MMMM')}`;
      case 'lastMonth':
        return `${t('DATE_TIME.lastMonth')}, ${localizedDate.toFormat('MMMM')}`;
      case 'thisYear':
        return `${t('DATE_TIME.thisYear')}, ${localizedDate.toFormat('MMMM')}`;
      case 'lastYear':
        return `${t('DATE_TIME.lastYear')}, ${localizedDate.toFormat('yyyy')}`;
      case 'before':
        return localizedDate.toFormat('yyyy');
      case 'unknown':
        return t('DATE_TIME.unknown');
      default:
        throw new Error(`Unknown date label "${mark}"`);
    }
  }

  function getGroupLabel(value: string): string {
    const date = DateTime.fromISO(value);

    if (!date.isValid) {
      return formatLabel('unknown', date);
    }

    for (const mark of sections) {
      if (date >= dates[mark]) {
        return formatLabel(mark, date);
      }
    }

    return formatLabel('before', date);
  }

  const groups: GroupedEvents[] = [];
  let lastGroupLabel: string | null = null;

  for (const event of events) {
    const groupLabel = getGroupLabel(event.creationDate);

    if (groupLabel !== lastGroupLabel) {
      groups.push({
        label: groupLabel,
        events: [],
      });

      lastGroupLabel = groupLabel;
    }

    groups[groups.length - 1].events.push(event);
  }

  return groups;
}
