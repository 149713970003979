import styles from './VacationMask.module.scss';

import { useApolloClient } from '@apollo/client';
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Datepicker,
  formatDate,
} from '@mobiscroll/react';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { noop } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ILockInfo, LockContext } from '@work4all/components/lib/hooks';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';
import { useMobiscrollLanguage } from '@work4all/components/lib/utils/use-mobiscroll-language/use-mobiscroll-language';

import { useDataMutation, useFormPlus, useUser } from '@work4all/data';

import { InputUrlaub } from '@work4all/models/lib/Classes/InputUrlaub.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { VacationDayKind } from '@work4all/models/lib/Enums/VacationDayKind.enum';

import { assertNever } from '@work4all/utils';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { UserPickerField } from '../../components/entity-picker/UserPickerField';
import { VacationApproverPickerField } from '../../components/entity-picker/VacationApproverPickerField';
import { VacationKindPickerField } from '../../components/entity-picker/VacationKindPickerField';
import { CellTooltip } from '../../components/vacation/components/CellTooltip';
import { usePageTheme } from '../../providers/HookedMuiThemeProvider';
import { settings, useSetting } from '../../settings';
import { DateTimeInputPicker } from '../mask-overlays/locked-inputs';
import {
  ControlWrapper,
  Form,
  MaskOverlayHeader,
} from '../mask-overlays/mask-overlay/components';
import { MaskOverlaySubmitButton } from '../mask-overlays/mask-overlay/components/MaskOverlaySubmitButton';
import {
  MaskContextProvider,
  useMaskContextValue,
} from '../mask-overlays/mask-overlay/hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../mask-overlays/mask-overlay/hooks/use-confrm-before-close-mask';

import { useVacationRequestState } from './use-vacation-request-state';
import { useVacationStats } from './use-vacation-stats';
import { VacationDaysInfo } from './VacationDaysInfo';
import { VacationDaysInfoList } from './VacationDaysInfoList';

export interface VacationMaskProps {
  date?: Date | null;
  user?: User | null;
  init?: FormValue;
}

type FormValue = {
  range: [Date | null, Date | null];
  vacationKind: LookUp;
  user: PathsOf<User>;
  approver: PathsOf<User>;
  options: {
    firstHalf: boolean;
    secondHalf: boolean;
  };
  isSickness?: boolean;
};

export function VacationMask(props: VacationMaskProps) {
  const { date: inDate } = props;
  const { t } = useTranslation();

  const language = useMobiscrollLanguage();

  // Another half can still be requested so we unblock form and just show as normal Request vacation with that day
  const isHalf =
    props.init?.options.firstHalf || props.init?.options.secondHalf;
  const date = useRef(isHalf ? props.init.range[0] : inDate).current;
  const init = useRef(isHalf ? undefined : props.init).current;

  const disabled = init != null;

  const { close, setObjectionListener } = useHistoryStack();

  useConfirmBeforeCloseMask(!disabled);

  // Keep track of the range displayed in the calendar.
  const [viewRange, setViewRange] = useState<[Date, Date] | null>(null);

  const today = useMemo(() => {
    const today = DateTime.now().startOf('day');
    return today.toJSDate();
  }, []);

  const currentUser = useUser();

  const defaultValues = useMemo<FormValue>(() => {
    if (init) {
      if (!init.approver) init.approver = init.user.vacationApprover;
      return init;
    }

    const tomorrow = DateTime.now().startOf('day').plus({ days: 1 }).toJSDate();

    const range: [Date, Date] =
      date != null ? [date, date] : [tomorrow, tomorrow];

    const user = props.user ?? { ...currentUser, id: currentUser.benutzerCode };

    const defaultValues: FormValue = {
      range,
      vacationKind: null,
      user: user,
      approver: user.vacationApprover ?? null,
      options: {
        firstHalf: false,
        secondHalf: false,
      },
    };

    return defaultValues;
  }, [currentUser, date, init, props.user]);

  const form = useFormPlus<FormValue>({
    defaultValues,
  });

  const { control, watch } = form;

  const calendarRef = useRef<Datepicker>(null);

  // HACK Using just 'user' does not cause a re-render for some reason. Should
  // investigate the cause later.
  const userId = watch('user').id;
  const range = watch('range');
  const options = watch('options');
  const approver = watch('approver');

  const [currentMonth, setCurrentMonth] = useState(date || new Date());
  const currentYear = useMemo(() => currentMonth.getFullYear(), [currentMonth]);

  const { stats: vacationStats } = useVacationStats({
    userId: currentUser.benutzerCode,
    year: currentYear,
  });

  const state = useVacationRequestState({
    mode: disabled ? 'view' : 'request',
    range,
    viewRange,
    userId,
    options,
    currentYear,
  });

  const { isSingleDay, isMultipleDays, days, duration, getDayInfo } = state;
  // isFirstFixed, but not
  const isFirstFixed =
    days.length >= 1 &&
    ['first-half', 'second-half'].includes(days[0].slot) &&
    days[0].isVacation;
  const isLastDayFixed =
    days.length >= 1 &&
    ['first-half', 'second-half'].includes(days[days.length - 1].slot) &&
    days[days.length - 1].isVacation;
  const singleDayWithFixedHalf =
    days.length === 1 && (isFirstFixed || isLastDayFixed);

  const responseData = useMemo(() => {
    return { __typename: null };
  }, []);

  const [upsertVacation] = useDataMutation({
    entity: Entities.vacationRequest,
    mutationType: EMode.upsert,
    responseData,
    resetStore: false,
    includeNonPrimitveValues: true,
  });

  const apolloClient = useApolloClient();

  const onSubmit = async (values: FormValue) => {
    if (days.length === 0) {
      return;
    }

    const common: Vacation = {
      userId: values.user?.id ?? 0,
      approverUserId: values.approver?.id ?? 0,
      vacationKindId: values.vacationKind?.id ?? 0,
    };

    const inputs = days.map((day) => {
      const dayPosition = (function resolveDayPosition(): VacationDayKind {
        switch (day.slot) {
          case 'full-day':
            return VacationDayKind.GANZTAGS;
          case 'first-half':
            return VacationDayKind.HALBTAGS_VORMITTAG;
          case 'second-half':
            return VacationDayKind.HALBTAGS_NACHMITTAG;
          default:
            assertNever(day.slot, `Unknown day slot value ${day.slot}`);
        }
      })();

      const amount = day.slot === 'full-day' ? 1 : 0.5;

      return {
        ...common,
        date: DateTime.fromJSDate(day.date).toISODate(),
        vacationDayPosition: dayPosition,
        amount,
      };
    });
    // Fields remapping is required due to the german fields in the mutation.
    await upsertVacation({
      vacationDays: inputs.map((input) => {
        const _input: InputUrlaub = {
          code: input.id,
          notiz: input.note,
          urlaubsArtCode: input.vacationKindId,
          datumAntrag: input.applicationDate,
          datumGenehmigung: input.approveDate,
          benutzerCodeGenehmigung: input.approverUserId,
          updateTime: input.updateTime,
          insertTime: input.insertTime,
          benutzerCode: input.userId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          datum: input.date,
          vacationDayPosition: input.vacationDayPosition,
          menge: input.amount,
        };

        return _input;
      }),
    });

    sendAmplitudeData(EventType.AddVacation, {});

    apolloClient.resetStore();

    setObjectionListener(null);
    close();
  };

  const lockContextValue = useMemo<ILockInfo>(() => {
    const lock: ILockInfo = {
      locked: disabled,
      loading: false,
      user: null,
      lock: noop,
      unlock: noop,
    };

    return lock;
  }, [disabled]);

  const { value: canChangeApprovalPerson } = useSetting(
    settings.canChangeApprovalPerson()
  );

  const maskContext = useMaskContextValue({
    entity: Entities.vacation,
    isCreateMode: true,
    isEditMode: false,
    mode: 'create',
    wip: false,
    params: {},
  });

  const remainingDaysAfterTheRequest = vacationStats.remaining - duration;
  const vacationKind = watch('vacationKind');
  // null or id 0 means vacation
  // if vacation, you can't request if the remaining is under 0, but on the other kinds you can
  const canRequest =
    (vacationKind && vacationKind.id !== 0) ||
    remainingDaysAfterTheRequest >= 0;

  const pageTheme = usePageTheme();

  return (
    <MaskContextProvider value={maskContext}>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <LockContext.Provider value={lockContextValue}>
            <MaskOverlayHeader
              title={t(`VACATION.VACATION_REQUEST_TITLE`)}
              actions={
                disabled ? null : (
                  <MaskOverlaySubmitButton
                    disabled={!canRequest || days.length === 0 || !approver}
                  >
                    {t('INPUTS.REQUEST')}
                  </MaskOverlaySubmitButton>
                )
              }
            />
            {!canRequest && (
              <Box p="0.25rem 1rem" bgcolor="var(--alert)">
                <Typography>{t('ERROR.SUFFICIENT_VACATION_DAYS')}</Typography>
              </Box>
            )}
            <div style={{ padding: '1rem' }}>
              <Controller
                control={control}
                name="range"
                render={({ field }) => {
                  const { value: range, onChange } = field;

                  function parseDateOrNull(input: string): Date | null {
                    const date = new Date(input);

                    if (Number.isNaN(date.valueOf())) {
                      return null;
                    }

                    return date;
                  }

                  return (
                    <>
                      <ControlWrapper>
                        <DateTimeInputPicker
                          clearable={false}
                          withTime={false}
                          dateLabel={t('INPUTS.START')}
                          value={range[0] ?? null}
                          onChange={(event) => {
                            const date = parseDateOrNull(
                              event.currentTarget.value
                            );
                            onChange([date, range[1]]);
                          }}
                        />
                        <DateTimeInputPicker
                          clearable={false}
                          withTime={false}
                          dateLabel={t('INPUTS.END')}
                          value={range[1] ?? null}
                          onChange={(event) => {
                            const date = parseDateOrNull(
                              event.currentTarget.value
                            );
                            onChange([range[0], date]);
                          }}
                        />
                      </ControlWrapper>

                      <Datepicker
                        ref={calendarRef}
                        className={clsx(
                          styles.calendar,
                          disabled && styles.disabled
                        )}
                        renderCalendarHeader={() => (
                          <Stack direction={'row'}>
                            <Box color={'red'}>
                              <CalendarPrev />
                              <CalendarToday />
                              <CalendarNext />
                            </Box>

                            <CalendarNav />
                          </Stack>
                        )}
                        disabled={disabled}
                        locale={language}
                        controls={['calendar']}
                        showRangeLabels={false}
                        theme="material"
                        themeVariant={pageTheme.value}
                        select="range"
                        value={range}
                        renderDay={(day) => {
                          const { date } = day;
                          const isToday = +date === +today;
                          const {
                            isSelected,
                            isFirstHalf,
                            isSecondHalf,
                            isExcluded,
                            isHoliday,
                            isApprovedVacation,
                            isRequestedVacation,
                            isOwnVacation,
                            isColleaguesVacation,
                            colleagues,
                          } = getDayInfo(date);

                          const showColleagues =
                            isColleaguesVacation && !isOwnVacation;

                          const cell = (
                            <div
                              className={clsx(styles.day, {
                                [styles.today]: isToday,
                                [styles.selected]: isSelected,
                                [styles.excluded]: isExcluded && !init,
                                [styles.firstHalf]: isFirstHalf,
                                [styles.secondHalf]: isSecondHalf,
                                [styles.holiday]: isHoliday,
                                [styles.approved]: isApprovedVacation,
                                [styles.pending]: isRequestedVacation,
                              })}
                            >
                              <div className={styles.monthDay}>
                                {date.getDate()}
                              </div>

                              <div className={styles.weekDay}>
                                {formatDate('DDD', date, language)}

                                {isOwnVacation && (
                                  <FlightTakeoffIcon fontSize="inherit" />
                                )}

                                {showColleagues && (
                                  <div className={styles.colleagues}>
                                    <PersonIcon fontSize="inherit" />
                                    <span>{colleagues.length}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          );

                          if (!showColleagues) {
                            return cell;
                          }

                          return (
                            <CellTooltip colleagues={colleagues}>
                              {cell}
                            </CellTooltip>
                          );
                        }}
                        onChange={(event) => {
                          if (!disabled) {
                            const value = event.value[1]
                              ? event.value
                              : [event.value[0], event.value[0]];
                            onChange(value);
                          }
                        }}
                        onPageChange={({ viewStart, viewEnd, month }) => {
                          setCurrentMonth(month);
                          setViewRange([viewStart, viewEnd]);
                        }}
                        calendarType="month"
                        calendarSize={1}
                        display="inline"
                        touchUi={true}
                      />
                    </>
                  );
                }}
              />

              {isSingleDay && !singleDayWithFixedHalf && (
                <Controller
                  control={control}
                  name="options.firstHalf"
                  render={({ field: firstHalf }) => {
                    return (
                      <Controller
                        control={control}
                        name="options.secondHalf"
                        render={({ field: secondHalf }) => {
                          // If both `firstHalf` and `secondHalf` are `true`, treat
                          // it as 'second-half'. The data is structured this way to
                          // make it easier to manage state when switching from
                          // single to multiple days and back.
                          const value = secondHalf.value
                            ? 'second-half'
                            : firstHalf.value
                            ? 'first-half'
                            : 'full-day';

                          const onChange = (value: string) => {
                            form.setValue('options', {
                              firstHalf: value === 'first-half',
                              secondHalf: value === 'second-half',
                            });
                          };

                          return (
                            <FormControl sx={{ py: '1rem' }}>
                              <RadioGroup
                                defaultValue="1"
                                value={value}
                                onChange={(event, value) => onChange(value)}
                              >
                                <FormControlLabel
                                  disabled={disabled}
                                  classes={{
                                    label: styles.radioLabel,
                                  }}
                                  value="full-day"
                                  control={<Radio />}
                                  label={t('VACATION.OPTIONS.FULL_DAY')}
                                />
                                <FormControlLabel
                                  disabled={disabled}
                                  classes={{
                                    label: styles.radioLabel,
                                  }}
                                  value="first-half"
                                  control={<Radio />}
                                  label={t('VACATION.OPTIONS.FIRST_HALF')}
                                />
                                <FormControlLabel
                                  disabled={disabled}
                                  classes={{
                                    label: styles.radioLabel,
                                  }}
                                  value="second-half"
                                  control={<Radio />}
                                  label={t('VACATION.OPTIONS.SECOND_HALF')}
                                />
                              </RadioGroup>
                            </FormControl>
                          );
                        }}
                      />
                    );
                  }}
                />
              )}

              {isMultipleDays && (
                <FormControl sx={{ py: '1rem' }}>
                  {!isFirstFixed && (
                    <Controller
                      control={control}
                      name="options.secondHalf"
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            disabled={disabled}
                            classes={{
                              label: styles.radioLabel,
                            }}
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(event, checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            }
                            label={t('VACATION.OPTIONS.START_HALF_DAY')}
                          />
                        );
                      }}
                    />
                  )}
                  {!isLastDayFixed && (
                    <Controller
                      control={control}
                      name="options.firstHalf"
                      render={({ field }) => {
                        return (
                          <FormControlLabel
                            disabled={disabled}
                            classes={{
                              label: styles.radioLabel,
                            }}
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(event, checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            }
                            label={t('VACATION.OPTIONS.END_HALF_DAY')}
                          />
                        );
                      }}
                    />
                  )}
                </FormControl>
              )}

              <Divider />

              <div className={styles.info}>
                <VacationDaysInfoList>
                  <VacationDaysInfo
                    label={t('VACATION.VACATION_DAYS.TOTAL', {
                      year: vacationStats.year,
                    })}
                    days={vacationStats.base}
                    info={
                      vacationStats.carriedOver > 0
                        ? t('VACATION.VACATION_DAYS.FROM_LAST_YEAR', {
                            days: vacationStats.carriedOver,
                          })
                        : null
                    }
                  />
                  <VacationDaysInfo
                    label={t('VACATION.VACATION_DAYS.USED')}
                    days={vacationStats.used}
                  />
                  <VacationDaysInfo
                    label={t('VACATION.VACATION_DAYS.SELECTED')}
                    days={duration}
                  />
                  <VacationDaysInfo
                    label={t('VACATION.VACATION_DAYS.REMAINING')}
                    days={remainingDaysAfterTheRequest}
                  />
                </VacationDaysInfoList>
              </div>

              <div className={styles.info}>
                <ControlWrapper>
                  <Controller
                    control={control}
                    name="vacationKind"
                    render={({ field }) => {
                      return (
                        <VacationKindPickerField
                          {...field}
                          isSickness={props.init?.isSickness}
                          clearable={false}
                        />
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="user"
                    render={({ field }) => {
                      return (
                        <UserPickerField
                          {...field}
                          disabled={true}
                          clearable={false}
                          onChange={(value) => {
                            // Ignore the change if the value is null. The form
                            // can't work without a user being selected.
                            if (value !== null) {
                              field.onChange(value);
                            }
                          }}
                        />
                      );
                    }}
                  />

                  {!props.init?.isSickness && (
                    <Controller
                      control={control}
                      name="approver"
                      render={({ field }) => {
                        return (
                          <VacationApproverPickerField
                            disabled={!canChangeApprovalPerson}
                            {...field}
                            clearable={false}
                          />
                        );
                      }}
                    />
                  )}
                </ControlWrapper>
              </div>
            </div>
          </LockContext.Provider>
        </Form>
      </FormProvider>
    </MaskContextProvider>
  );
}
