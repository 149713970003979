import styles from './SumCell.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';

import { formatCurrency, formatNumberAsCurrency } from '@work4all/utils';

export const SumCell: React.FC<CellProps<ChronoFileItem>> = (
  props: CellProps<ChronoFileItem>
) => {
  const fileItem = props.cell.row.original.fileItem;

  const { t } = useTranslation();

  const { value, currency } = getCurrencyAndValue(fileItem);
  if (value) {
    return (
      <div className={styles.main}>{`${formatNumberAsCurrency(
        value
      )} ${formatCurrency(currency, t)}`}</div>
    );
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCurrencyAndValue(input: any): {
  value: number;
  currency: Currency;
} {
  const value = parseFloat(input?.value);

  return { value: isNaN(value) ? null : value, currency: input.currency };
}
