import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';

import { IResponse } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ILookUpProps<TMultiple extends boolean> = IPickerProps<
  LookUp,
  TMultiple
> & {
  lookupType: LookupType;
};

export function LookUpPicker<TMultiple extends boolean>(
  props: ILookUpProps<TMultiple>
) {
  const { data, lookupType, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...LookUp_FIELDS, ...data };
  }, [data]);

  const [searchTerm, setSearchTerm] = useState('');

  const transformResponse = useCallback(
    (response: IResponse<LookUp>) => {
      const filteredData = response.data.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return {
        ...response,
        data: filteredData,
        total: filteredData.length,
      };
    },
    [searchTerm]
  );

  return (
    <ListEntityPicker
      entity={Entities.lookUp}
      data={allFields}
      filterBy={['name']}
      sortBy="name"
      {...rest}
      vars={{ type: lookupType }}
      onSearchValueChange={setSearchTerm}
      transformResponse={transformResponse}
      renderItemContent={(lookUp) => (
        <Tooltip title={lookUp.name}>
          <Typography variant="body2" noWrap>
            {lookUp.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const LookUp_FIELDS: LookUp = {
  id: null,
  name: null,
  value: null,
};
