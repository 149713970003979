import styles from './History.module.scss';

import { EntityHistory } from '@work4all/components/lib/components/entity-preview/EntityHistory';

import { useMaskContext } from '../../hooks/mask-context';

export const History = () => {
  const mask = useMaskContext();

  return (
    <div className={styles.root}>
      <EntityHistory
        entity={mask.entity}
        id={mask.id}
        breadcrumbTitle={mask.entity}
        fullWidth
        fullCommentEditor
        withTabs
      />
    </div>
  );
};
