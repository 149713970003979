import styles from './ProjectHours.module.scss';

import {
  Eventcalendar,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
} from '@mobiscroll/react';
import { ArrowDownward } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import { TabContext, TabList } from '@mui/lab';
import {
  Box,
  CardProps,
  IconButton,
  Tab,
  Theme,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { decimalToDuration, Dialog, DialogContent } from '@work4all/components';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { DateTimeInputPicker } from '@work4all/components/lib/input/date-time-input-picker';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';
import { useMobiscrollLanguage } from '@work4all/components/lib/utils/use-mobiscroll-language/use-mobiscroll-language';

import { AppParts, IUser, useCanView, useUser } from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { TimeTrackingOverviewItem } from '@work4all/models/lib/Classes/TimeTrackingOverviewItem.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TimeTrackingKind } from '@work4all/models/lib/Enums/TimeTrackingKind.enum';

import { canAddTimeTrackingForProjects } from '@work4all/utils/lib/permissions';

import { UserPickerField } from '../../components/entity-picker/UserPickerField';
import { useSuggestionsContext } from '../../contexts/SuggestionsContext';
import { usePageTheme } from '../../providers/HookedMuiThemeProvider';
import { settings, useSetting } from '../../settings';
import { useCalendarHoursConfig } from '../calendar/hooks/use-calendar-hours-config';
import { ProjectTimeSettings } from '../mask-overlays/mask-overlay/views/settings/project-time-settings/ProjectTimeSettings';

import { Card, CardHeader } from './components';
import { EventPopover, EventPopoverProps } from './components/EventPopover';
import ProjectHourEvent from './components/ProjectHourEvent';
import useSuggestions from './hooks/useSuggestions';
import { ProjectTimeDisplayMode } from './ProjectTimeDisplayMode';
import { ProjectTimeMaskInit } from './ProjectTimeMask';
import { TimeTracker } from './use-time-tracker';
import { useSelectedDay } from './useTimeTrackingOverview';
import { calculateDuration } from './utils/calculateDuration';
import suggestionSettings from './utils/suggestionSettings';

const view: MbscEventcalendarView = {
  schedule: { type: 'day' },
} as const;

export interface ProjectHoursProps extends ProjectHoursState {
  renderHeader?: boolean;
  onEventSelected: (item: TimeTracking<EMode.entity>) => void;
  onCreateEvent: (
    init: Omit<ProjectTimeMaskInit, 'date'> &
      Required<Pick<ProjectTimeMaskInit, 'date'>>
  ) => void;
  presetDate?: Date;
  tracker: TimeTracker;
  userToTrack: User | IUser;
  setUserToTrack: (u: User) => void;
  onOpenTimeTemplateMask?: () => void;
  canEditAll: boolean;
}

interface ProjectHoursState {
  isProjectTimeEnabled: boolean;
  date: { from: Date; to: Date };
  setDate: (value: { from: Date; to: Date }) => void;
  events: {
    start: Date;
    end: Date;
    allDay: boolean;
    original: TimeTracking;
    suggestion?: {
      title: string;
      attendeeList: User[];
    };
  }[];
  suggestions: Appointment[];
  selectedDay: TimeTrackingOverviewItem | null;
  refetch?: () => void;
}

export function useProjectHoursState({
  presetDate,
  userToTrack,
}: Pick<ProjectHoursProps, 'presetDate' | 'userToTrack'>): ProjectHoursState {
  const isProjectTimeEnabled = useCanView(AppParts.PROJECTTIMETRACKING);

  const [date, setDate] = useState(() => {
    const today = presetDate
      ? DateTime.fromJSDate(presetDate).startOf('day')
      : DateTime.now().startOf('day');

    return {
      from: today.startOf('day').toJSDate(),
      to: today.endOf('day').toJSDate(),
    };
  });

  const { selectedDay, refetch } = useSelectedDay({
    ...date,
    disabled: !isProjectTimeEnabled,
    userId: (userToTrack as User).id || (userToTrack as IUser).benutzerCode,
  });

  useEntityEvents((event) => {
    if (event.entity === Entities.timeTracking) {
      refetch();
    }
  });

  const { showSuggestions, hiddenSuggestions } = useSuggestionsContext();
  const suggestions = useSuggestions(date.from, date.to, userToTrack);

  const events = useMemo(() => {
    if (!isProjectTimeEnabled || !selectedDay) {
      return [];
    }

    const { timeTrackingItems } = selectedDay;

    const timeTrackingEvents = timeTrackingItems.map((item) => {
      return {
        start: item.startDateTime
          ? DateTime.fromISO(item.startDateTime).toJSDate()
          : date.from,
        end: item.endDateTime
          ? DateTime.fromISO(item.endDateTime).toJSDate()
          : date.from,
        allDay: item.timeTrackingKind === TimeTrackingKind.ANZAHL,
        original: item,
      };
    });

    if (suggestions?.length === 0 || !showSuggestions) {
      return timeTrackingEvents;
    }

    const suggestionEvents = suggestions
      ?.filter((item) => {
        return !hiddenSuggestions.find((id) => id === item.id);
      })
      ?.map((item) => {
        const { amount, dates, isAllDay, timeTrackingKind } =
          suggestionSettings(item, date);

        const appointmentAttendeeList = item.appointmentAttendeeList;
        const appointmentAttendeeListUsers = appointmentAttendeeList.length
          ? appointmentAttendeeList
              .filter((el) => !!el.user) //appointments can have ressources or businesspartners assigned as well
              .map((item) => {
                const user = item.user;

                return {
                  id: (user as IUser).benutzerCode || (user as User).id,
                  displayName: user.displayName,
                };
              })
          : null;

        return {
          start: dates.start.toJSDate(),
          end: dates.end.toJSDate(),
          allDay: isAllDay,
          suggestion: {
            title: item.title,
            attendeeList: appointmentAttendeeListUsers,
          },
          original: {
            id: item.id,
            date: dates.value,
            startDateTime: dates.start.toISO(),
            endDateTime: dates.end.toISO(),
            amount: amount,
            timeTrackingKind: timeTrackingKind,
            customer:
              item.businessPartner?.businessPartnerType === SdObjType.KUNDE
                ? item.businessPartner?.data
                : null,
            project: item.project,
            projectProcess: item.projectProcess,
          } as TimeTracking,
        };
      });
    return [...timeTrackingEvents, ...suggestionEvents];
  }, [
    date,
    hiddenSuggestions,
    isProjectTimeEnabled,
    selectedDay,
    showSuggestions,
    suggestions,
  ]);
  return {
    isProjectTimeEnabled,
    date,
    setDate,
    events,
    selectedDay,
    refetch,
    suggestions,
  };
}

export const getStartForNewProjectTime = (
  dateToApplyTimeTo: Date,
  events: ProjectHoursState['events'],
  tracker: TimeTracker
) => {
  const selectedDate = DateTime.fromJSDate(dateToApplyTimeTo);
  let previousEndtimestamp;
  let endTimestamp = selectedDate
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .valueOf();

  // project times check
  events.forEach((event) => {
    if (event?.suggestion) return;
    const eventTimestamp = DateTime.fromJSDate(event.end).valueOf();

    if (!event.allDay && eventTimestamp > endTimestamp) {
      endTimestamp = eventTimestamp;
      previousEndtimestamp = eventTimestamp;
    }
  });

  // if no project time selected & there is a worktime
  const workTimeStart = tracker.result.presentSince;
  if (
    !previousEndtimestamp &&
    workTimeStart.day === selectedDate.day &&
    workTimeStart.month === selectedDate.month &&
    workTimeStart.year === selectedDate.year
  ) {
    previousEndtimestamp = workTimeStart.valueOf();
  }

  // if no project & work time, start time = current time
  const time = previousEndtimestamp
    ? DateTime.fromMillis(previousEndtimestamp)
    : DateTime.now();

  const adjustedDate = selectedDate
    .set({
      hour: time.hour,
      minute: time.minute,
      second: 0,
      millisecond: 0,
    })
    .toJSDate();

  return adjustedDate;
};

export const ProjectHours = ({
  renderHeader = true,
  onEventSelected,
  onCreateEvent,
  tracker,
  isProjectTimeEnabled,
  date,
  setDate,
  events,
  selectedDay,
  userToTrack,
  setUserToTrack,
  canEditAll,
  onOpenTimeTemplateMask,
  suggestions,
  ...cardProps
}: ProjectHoursProps & CardProps) => {
  const { t } = useTranslation();

  const language = useMobiscrollLanguage();
  const user = useUser();
  const canAddTimes = canAddTimeTrackingForProjects(user) || canEditAll;
  const { value: createTimeTrackingForOthersValue } = useSetting(
    settings.createTimeTrackingForOthers()
  );

  const [showProjectTimeAlert, setShowProjectTimeAlert] = useState(false);

  const { hideSuggestion } = useSuggestionsContext();

  events.sort((a, b) => {
    return a.allDay && b.allDay
      ? a.original.amount - b.original.amount
      : a.start > b.start
      ? 1
      : a.start < b.start
      ? -1
      : 0;
  });

  const onEventClick = useCallback(
    (event: MbscCalendarEvent) => {
      const isSuggested = event?.suggestion ? true : false;
      const slectedEvent = event.original;

      if (isSuggested) {
        onCreateEvent({
          id: slectedEvent.id,
          kind: slectedEvent.timeTrackingKind,
          date: DateTime.fromISO(slectedEvent.date as string).toJSDate(),
          startDateTime: DateTime.fromISO(
            slectedEvent.startDateTime
          ).toJSDate(),
          endDateTime: DateTime.fromISO(slectedEvent.endDateTime).toJSDate(),
          amount: slectedEvent.amount,
          customer: slectedEvent.customer,
          project: slectedEvent.project,
          projectProcess: slectedEvent.projectProcess,
          comment: event.suggestion.title,
          users:
            canEditAll && createTimeTrackingForOthersValue
              ? event.suggestion?.attendeeList
              : event.suggestion?.attendeeList?.find(
                  (attendee) => attendee.benutzerCode === user.benutzerCode
                ),
        });
      } else {
        onEventSelected(slectedEvent as TimeTracking);
      }
    },
    [
      onCreateEvent,
      canEditAll,
      createTimeTrackingForOthersValue,
      user.benutzerCode,
      onEventSelected,
    ]
  );

  const renderEvent = useCallback(
    (event: MbscCalendarEventData) => {
      const isSuggested = event.original?.suggestion ? true : false;
      const eventData = event.original.original;
      const duration = calculateDuration(event.original);

      return (
        <ProjectHourEvent
          key={event.id}
          fixed={event.allDay}
          suggested={isSuggested}
          duration={duration}
          firstText={
            isSuggested ? event.original.suggestion.title : eventData?.comment
          }
          customer={eventData?.customer?.name}
          project={[eventData?.project?.number, eventData?.project?.name]
            .filter(Boolean)
            .join(' | ')}
          contract={eventData?.contract?.note}
          ticket={eventData?.ticket?.title}
          onHide={isSuggested ? () => hideSuggestion(eventData.id) : undefined}
        />
      );
    },
    [hideSuggestion]
  );

  const { workDayStart, workDayEnd } = useCalendarHoursConfig();

  const colors = useMemo<MbscCalendarColor[]>(() => {
    return [
      {
        start: '00:00',
        end: workDayStart,
        background: 'var(--ui02)',
        recurring: {
          repeat: 'daily',
        },
      },
      {
        start: workDayEnd,
        end: '24:00',
        background: 'var(--ui02)',
        recurring: {
          repeat: 'daily',
        },
      },
    ];
  }, [workDayEnd, workDayStart]);

  const ref = useRef<Eventcalendar>();

  const projectTimeDisplayMode = useSetting(settings.projectTimeDisplayMode());
  const tabValue = projectTimeDisplayMode.value;

  useEffect(() => {
    const startHourOfDay = Math.max(0, parseInt(workDayStart, 10) - 1);

    if (tabValue === ProjectTimeDisplayMode.timeline) {
      ref.current.setState({
        ...ref.current.state,
        selectedDate: DateTime.fromJSDate(date.from)
          .set({ hour: startHourOfDay })
          .toJSDate()
          .getTime(),
      });
    }
  }, [date.from, events, tabValue, workDayStart]);

  const { SuggestionsToggleIcon } = useSuggestionsContext();

  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const renderListItemDetails = useCallback(
    (label, value) => (
      <>
        <div className={styles.detailsLabel}>{label}:</div>
        <div className={styles.detailsValue}>{value}</div>
      </>
    ),
    []
  );

  const renderListItem = useCallback(
    (event) => (
      <div
        key={event.original.id}
        className={clsx(styles.listEvent, {
          [styles.suggestion]: event.suggestion,
        })}
        onClick={() => onEventClick(event)}
      >
        <div className={styles.timeContainer}>
          {!event.suggestion && (
            <>
              {event.original.timeTrackingKind === TimeTrackingKind.VON_BIS && (
                <>
                  <div>
                    {DateTime.fromISO(event.original.startDateTime).toFormat(
                      'HH:mm'
                    )}
                  </div>
                  <ArrowDownward className={styles.timeSpanArrow} />
                  <div>
                    {DateTime.fromISO(event.original.endDateTime).toFormat(
                      'HH:mm'
                    )}
                  </div>
                </>
              )}
              {event.original.timeTrackingKind === TimeTrackingKind.ANZAHL && (
                <>
                  {decimalToDuration(event?.original?.amount).toFormat('hh:mm')}{' '}
                  h
                </>
              )}
            </>
          )}
          {event.suggestion && <AddIcon className={styles.timeSpanAdd} />}
        </div>

        <div className={styles.eventContent}>
          <div className={styles.eventTitle}>
            {event?.original?.comment || event?.suggestion?.title}
          </div>
          <div className={styles.eventDetails}>
            {event?.original.project &&
              renderListItemDetails(
                t('COMMON.PROJECT'),
                [event.original.project.number, event.original.project.name]
                  .filter(Boolean)
                  .join(' | ')
              )}
            {event?.original.customer &&
              renderListItemDetails(
                t('COMMON.CUSTOMER'),
                event.original.customer.name
              )}
            {event?.original.activityArticle &&
              renderListItemDetails(
                t('COMMON.ACTIVITY'),
                event.original.activityArticle.name
              )}
            {event?.original.contract &&
              renderListItemDetails(
                t('COMMON.CONTRACT'),
                event.original.contract.contractNumber +
                  ' | ' +
                  event.original.contract.note
              )}
            {event?.original.ticket &&
              renderListItemDetails(
                t('COMMON.TICKET'),
                event.original.ticket.number +
                  ' | ' +
                  event.original.ticket.title
              )}
          </div>
        </div>
      </div>
    ),
    [onEventClick, renderListItemDetails, t]
  );

  const handleAddClick = () => {
    if (isProjectTimeEnabled) {
      const adjustedDate = getStartForNewProjectTime(
        date.from,
        events,
        tracker
      );

      onCreateEvent({
        date: adjustedDate,
      });
    } else {
      setShowProjectTimeAlert(true);
    }
  };

  const [eventPopover, setEventPopover] = useState<EventPopoverProps>();

  const projectTimeTracked = useMemo(() => {
    const time = selectedDay?.trackedTime ?? 0;

    const trackedTimeValue = decimalToDuration(time / 60);
    const trackedTime = t('RELEVANT.PROJECT.TIME.TRACKED', {
      hours: trackedTimeValue.hours,
      minutes: trackedTimeValue.minutes,
    });

    return trackedTime;
  }, [selectedDay?.trackedTime, t]);

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const pageTheme = usePageTheme();

  return (
    <>
      <NavigationOverlay
        classes={{
          wrapper: styles.dialogWrapper,
        }}
        open={isSettingsOpen}
        initialView={{
          view: <ProjectTimeSettings amplitudeEntryPoint="ProjectTimeMask" />,
          title: t('TIME_TRACKER.PROJECT_TIME'),
        }}
        close={() => {
          setSettingsOpen(false);
        }}
      />

      <TabContext value={projectTimeDisplayMode.value}>
        <ProjectTimeDisabledAlert
          open={showProjectTimeAlert}
          onClose={() => setShowProjectTimeAlert(false)}
        />

        <Card className={clsx(styles.projectHours, cardProps?.className)}>
          {renderHeader ? (
            <CardHeader title={<div>{t('COMMON.TRACKED_PROJECT_TIME')}</div>}>
              <div style={{ display: 'flex' }}>
                {!isTablet ? (
                  <BaseActionButton
                    icon={<PlaylistAddCheckIcon />}
                    color="primary"
                    onClick={onOpenTimeTemplateMask}
                  >
                    {t('COMMON.TIMETRACKING.TEMPLATE_plural')}
                  </BaseActionButton>
                ) : null}

                {isTablet ? (
                  <IconButton
                    size="medium"
                    color="primary"
                    data-test-id="addProjectTimeFab"
                    disabled={!canAddTimes}
                    onClick={() => {
                      if (isProjectTimeEnabled) {
                        const adjustedDate = getStartForNewProjectTime(
                          date.from,
                          events,
                          tracker
                        );

                        onCreateEvent({
                          date: adjustedDate,
                        });
                      } else {
                        setShowProjectTimeAlert(true);
                      }
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : null}

                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => setSettingsOpen(true)}
                >
                  <SettingsIcon />
                </IconButton>
              </div>
            </CardHeader>
          ) : null}

          <div style={{ padding: '1rem', display: 'flex', gap: '1rem' }}>
            <div className={styles.equalFlexWith}>
              <DateTimeInputPicker
                dateLabel={t('COMMON.DAY')}
                value={date.from}
                withTime={false}
                onChange={(e) => {
                  setDate({
                    from: new Date(e.currentTarget.value),
                    to: DateTime.fromISO(e.currentTarget.value)
                      .endOf('day')
                      .toJSDate(),
                  });
                }}
                clearable={false}
              />
            </div>
            {canEditAll ? (
              <div className={styles.equalFlexWith}>
                <UserPickerField
                  multiple={false}
                  value={{
                    id:
                      (userToTrack as User).id ||
                      (userToTrack as IUser).benutzerCode,
                    displayName: userToTrack.displayName,
                  }}
                  onChange={(value) => {
                    if (value) setUserToTrack(value as User);
                  }}
                />
              </div>
            ) : null}
          </div>

          {tabValue === ProjectTimeDisplayMode.list && (
            <div className={styles.listContainer}>
              {events.map(renderListItem)}
              {isTablet && (
                <div style={{ padding: '1rem' }}>
                  <Tooltip
                    title={!canAddTimes ? t('RIGHTS.MISSING') : undefined}
                    activateForDisabled={true}
                  >
                    <BaseActionButton
                      onClick={handleAddClick}
                      title={t('COMMON.ADD', { name: t('COMMON.PROJECTTIME') })}
                      icon={<AddIcon />}
                      disabled={!canAddTimes}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          )}

          {tabValue === ProjectTimeDisplayMode.timeline && (
            <div className={styles.timelineContainer}>
              <Eventcalendar
                ref={ref}
                className={styles.scheduler}
                locale={language}
                theme="none"
                showControls={false}
                data={events}
                colors={colors}
                view={view}
                themeVariant={pageTheme.value}
                onEventClick={(e) => onEventClick(e.event)}
                onEventCreate={({ event }) => {
                  const start = event.start as Date;
                  const end = event.end as Date;

                  const date = DateTime.fromJSDate(start)
                    .startOf('day')
                    .toJSDate();

                  onCreateEvent({
                    kind: TimeTrackingKind.VON_BIS,
                    date,
                    startDateTime: start,
                    endDateTime: end,
                    amount: DateTime.fromJSDate(end)
                      .diff(DateTime.fromJSDate(start))
                      .as('hours'),
                  });

                  // Returning `false` here will prevent the temporary event from
                  // being persisted in the UI. Instead we'll open a mask to configure
                  // all the required time tracking properties and save it from there.
                  return false;
                }}
                renderScheduleEvent={renderEvent}
                dragToCreate={true}
                showEventTooltip={false}
                onEventHoverIn={(args) => {
                  setEventPopover({
                    target: args.domEvent.target,
                    event: args.event,
                  });
                }}
                onEventHoverOut={() => setEventPopover(undefined)}
              />
            </div>
          )}

          <EventPopover {...eventPopover} />

          <div className={styles.footer}>
            <TabContext value={tabValue}>
              <TabList
                sx={{ padding: ' 0rem ', width: '200px', flex: '1' }}
                onChange={(e, val) => projectTimeDisplayMode.set(val)}
              >
                <Tab
                  label={t('PROJECT_TIME.DISPLAY_MODE.LIST')}
                  value={ProjectTimeDisplayMode.list}
                />
                <Tab
                  label={t('PROJECT_TIME.DISPLAY_MODE.TIMELINE')}
                  value={ProjectTimeDisplayMode.timeline}
                />
              </TabList>
            </TabContext>
            {suggestions.length > 0 && (
              <Box flex={1}>
                <SuggestionsToggleIcon />
              </Box>
            )}

            <div style={{ textAlign: 'right' }}>
              <Typography component="span" color="text.primary">
                {projectTimeTracked}
              </Typography>
            </div>
          </div>
        </Card>
        {!isTablet && (
          <Tooltip
            title={!canAddTimes ? t('RIGHTS.MISSING') : undefined}
            activateForDisabled={true}
          >
            <Fab
              className={styles.fab}
              size="medium"
              color="primary"
              data-test-id="addProjectTimeFab"
              disabled={!canAddTimes}
              onClick={() => {
                if (isProjectTimeEnabled) {
                  const adjustedDate = getStartForNewProjectTime(
                    date.from,
                    events,
                    tracker
                  );

                  onCreateEvent({
                    date: adjustedDate,
                  });
                } else {
                  setShowProjectTimeAlert(true);
                }
              }}
            >
              <AddIcon className={styles.iconLight} />
            </Fab>
          </Tooltip>
        )}
      </TabContext>
    </>
  );
};

interface ProjectTimeDisabledProps {
  open: boolean;
  onClose: () => void;
}

function ProjectTimeDisabledAlert(props: ProjectTimeDisabledProps) {
  const { open, onClose } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeButton={false}
      title={'Modul nicht verfügbar'}
    >
      <DialogContent>
        <p>{t('COMMON.TIMETRACKING.NO_PROJECTTIME_LICENSE')}</p>

        <Button fullWidth size="large" color="primary" onClick={onClose}>
          {t('ALERTS.CLOSE')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
