import styles from './LinkPreDocuments.module.scss';

import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';

import { InboundDeliveryNotesPickerField } from '../../../../../../../../../../components/entity-picker/InboundDeliveryNotesPickerField';
import { OrderPickerField } from '../../../../../../../../../../components/entity-picker/OrderPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { Button } from '../../../../../../../../locked-inputs';
import { Collapse } from '../../../../../../../components';
import { ReViewModelFormValue } from '../../../../../types';
import { useShadowReObjectApiContext } from '../../../../../use-re-shadow-object-api/use-shadow-re-object-api-context';

type LinkedDocumentsSnapshot = Pick<
  ReViewModelFormValue,
  'localOrders' | 'localInboundDeliveryNotes'
>;

export function LinkPreDocuments() {
  const { t } = useTranslation();
  const form = useFormContextPlus<ReViewModelFormValue>();
  const { control, watch, getValues } = form;

  const dialogs = useDialogs();

  const shadowObjectApi = useShadowReObjectApiContext();

  const headerBar = (
    <Tooltip title={t('MASK.LINK_PRE_DOCUMENTS_TOOLTIP')} placement="right">
      <IconButton color="primary" size="small" sx={{ alignSelf: 'center' }}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  const orders = watch('localOrders');
  const deliveryNotes = watch('localInboundDeliveryNotes');

  const preLinkExist = orders?.length || deliveryNotes?.length;
  const direction = preLinkExist ? 'column' : 'row';
  const spacing = preLinkExist ? 2 : 0;

  const isLinkedDocumentsPopoverOpenRef = useRef(false);
  const linkedDocumentsSnapshotRef = useRef<LinkedDocumentsSnapshot>(null);

  const onRecreateTable = async (value: LinkedDocumentsSnapshot) => {
    const confirmed = await dialogs.confirm({
      title: t('ALERTS.OVERWRITE_BOOKINGS'),
      description: t('ALERTS.OVERWRITE_BOOKINGS_BODY'),
    });

    if (confirmed) {
      shadowObjectApi.recreateTable({
        orders: value.localOrders,
        deliveryNotes: value.localInboundDeliveryNotes,
      });
    }
  };

  const onLinkedDocumentsChange = () => {
    const { localOrders, localInboundDeliveryNotes } = getValues();

    const linkedDocuments: LinkedDocumentsSnapshot = {
      localOrders,
      localInboundDeliveryNotes,
    };

    onRecreateTable(linkedDocuments);
  };

  const onPopoverOpen = () => {
    isLinkedDocumentsPopoverOpenRef.current = true;
    linkedDocumentsSnapshotRef.current = {
      localOrders: orders,
      localInboundDeliveryNotes: deliveryNotes,
    };
  };

  const onPopoverClose = () => {
    // Check if linked documents have actually changed, and, if they did, ask
    // whether or not to regenerate the bookings table from the linked documents.

    const snapshot = linkedDocumentsSnapshotRef.current ?? {
      localOrders: [],
      localInboundDeliveryNotes: [],
    };

    const { localOrders, localInboundDeliveryNotes } = getValues();

    const newOrders = new Set(localOrders.map((x) => x.id));
    const oldOrders = new Set(snapshot.localOrders.map((x) => x.id));

    const newNotes = new Set(localInboundDeliveryNotes.map((x) => x.id));
    const oldNotes = new Set(
      snapshot.localInboundDeliveryNotes.map((x) => x.id)
    );

    if (!isEqual(newOrders, oldOrders) || !isEqual(newNotes, oldNotes)) {
      onLinkedDocumentsChange();
    }

    linkedDocumentsSnapshotRef.current = null;
    isLinkedDocumentsPopoverOpenRef.current = false;
  };
  const supplier = watch('supplier');
  const prefilter = useMemo(
    () => [
      {
        'businessPartnerContactCombined.businessPartner.data.id': {
          $in: [supplier?.id],
        },
      },
    ],
    [supplier]
  );

  const tableFilters = useMemo(
    () => [
      {
        id: 'businessPartnerContactCombined.businessPartner.data.id',
        value: {
          value: [
            {
              id: supplier?.id,
              name: supplier?.name,
            },
          ],
          filterType: 'picker',
        },
      },
    ],
    [supplier]
  );

  return (
    <Collapse
      className={styles.linkPreDocuments}
      title={t('MASK.LINK_PRE_DOCUMENTS')}
      defaultOpen
      headerBar={headerBar}
    >
      <Stack
        direction={direction}
        gap={spacing}
        alignItems="start"
        flexWrap="wrap"
      >
        <ControllerPlus
          control={control}
          name="localOrders"
          render={({ field }) => {
            const onChange = (orders: Order[]) => {
              field.onChange(orders);

              if (!isLinkedDocumentsPopoverOpenRef.current) {
                onLinkedDocumentsChange();
              }
            };

            return (
              <OrderPickerField
                value={field.value}
                filters={tableFilters}
                prefilter={prefilter}
                multiple={true}
                onChange={onChange}
                onPopoverOpen={onPopoverOpen}
                onPopoverClose={onPopoverClose}
                field={
                  <Button type="button" startIcon={<LinkIcon />} size="large">
                    {t('MASK.ADD_ORDERS')}
                  </Button>
                }
              />
            );
          }}
        />

        <ControllerPlus
          control={control}
          name="localInboundDeliveryNotes"
          render={({ field }) => {
            const onChange = (inboundDeliveryNotes: InboundDeliveryNote[]) => {
              field.onChange(inboundDeliveryNotes);

              if (!isLinkedDocumentsPopoverOpenRef.current) {
                onLinkedDocumentsChange();
              }
            };

            return (
              <InboundDeliveryNotesPickerField
                value={field.value}
                prefilter={prefilter}
                filters={tableFilters}
                multiple={true}
                onChange={onChange}
                onPopoverOpen={onPopoverOpen}
                onPopoverClose={onPopoverClose}
                field={
                  <Button type="button" startIcon={<LinkIcon />} size="large">
                    {t('MASK.ADD_INBOUND_DELIVERY_NOTES')}
                  </Button>
                }
              />
            );
          }}
        />
      </Stack>
    </Collapse>
  );
}
