import { useEffect } from 'react';
import { debounceTime, Subject } from 'rxjs';

export const useSyncScroll = (
  type: 'horizontal' | 'vertical',
  ...refs: React.MutableRefObject<HTMLDivElement>[]
) => {
  useEffect(() => {
    if (!refs.every((x) => x.current)) return;
    const handleScroll = (e) => {
      refs.forEach((ref) => {
        if (ref.current === e.target) return;
        if (type === 'horizontal') ref.current.scrollLeft = e.target.scrollLeft;
        else ref.current.scrollTop = e.target.scrollTop;
      });
    };

    const elements = refs.map((x) => x.current);

    elements.forEach((el) => {
      el.addEventListener('scroll', handleScroll);
    });

    return () => {
      elements.forEach((el) => {
        el.removeEventListener('scroll', handleScroll);
      });
    };
  }, [refs, type]);

  // Scrollbars need to be visible on both or on none
  useEffect(() => {
    if (!refs.every((x) => x.current)) return;
    const elements = refs.map((x) => x.current);

    const subject = new Subject<void>();
    const subscription = subject
      .pipe(
        debounceTime(300) // Debounce to emit only if there's no emission in 300ms
      )
      .subscribe(() => {
        let scrollXVisible = false;
        let scrollYVisible = false;
        elements.forEach((el) => {
          scrollXVisible = scrollXVisible || el.scrollWidth > el.clientWidth;
          scrollYVisible = scrollYVisible || el.scrollHeight > el.clientHeight;
        });

        elements.forEach((el) => {
          if (type === 'vertical')
            el.style.overflowX = scrollXVisible ? 'scroll' : 'hidden';
          if (type === 'horizontal')
            el.style.overflowY = scrollYVisible ? 'scroll' : 'hidden';
        });
      });
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        subject.next();
      }
    );

    elements.forEach((el) => {
      resizeObserver.observe(el);
    });
    return () => {
      subscription.unsubscribe();
      resizeObserver.disconnect();
    };
  }, [refs, type]);
};
