export const createDuplicateName = (originalName: string, names: string[]) => {
  const regex = new RegExp(`^\\s*${originalName}\\s*\\((\\d+)\\)\\s*$`);
  // we start duplicates from 2
  let maxNumber = 1;
  names.forEach((str) => {
    const match = str.match(regex);
    if (match) {
      const number = parseInt(match[1], 10); // Extract the number
      if (maxNumber === null || number > maxNumber) {
        maxNumber = number;
      }
    }
  });

  return `${originalName} (${maxNumber + 1})`;
};
