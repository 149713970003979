import { useCallback, useState } from 'react';

import { ConvertInvoiceToRaContext } from '../contexts';
import { useConvertInvoiceToRaAlert } from '../hooks/use-convert-invoice-to-ra-alert';
import { useConvertInvoiceToRaMutation } from '../hooks/use-convert-invoice-to-ra-mutation';
import { ConvertInvoiceToRaProviderProps } from '../types';

export const ConvertInvoiceToRaProvider = ({
  children,
  invoiceId,
  reportId,
}: ConvertInvoiceToRaProviderProps) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const toggleAbility = useCallback(() => {
    setIsEnabled(!isEnabled);
  }, [isEnabled]);

  const convert = useConvertInvoiceToRaMutation({ invoiceId, reportId });
  const alert = useConvertInvoiceToRaAlert();

  return (
    <ConvertInvoiceToRaContext.Provider
      value={{ isEnabled, toggleAbility, convert, alert, reportId }}
    >
      {children}
    </ConvertInvoiceToRaContext.Provider>
  );
};
