import { useCallback } from 'react';

import { settings, useSetting } from '../../../settings';
import {
  IsVisibleFunction,
  ToggleVisibilityFunction,
  UseOrganisationAreaSectionVisibilityProps,
} from '../types';

export const useOrganisationAreaSectionVisibility = ({
  entity,
}: UseOrganisationAreaSectionVisibilityProps) => {
  const { value: sections, set: setEntityVisibleSections } = useSetting(
    settings.organisationAreaVisibleSections(entity)
  );

  const isVisible = useCallback<IsVisibleFunction>(
    (section) => {
      return sections.includes(section);
    },
    [sections]
  );

  const toggleVisibility = useCallback<ToggleVisibilityFunction>(
    (section) => {
      let newSections = [...sections];
      const isSectionVisible = isVisible(section);

      if (isSectionVisible) {
        newSections = newSections.filter((_section) => section !== _section);
      } else {
        newSections.push(section);
      }

      setEntityVisibleSections(newSections);
    },
    [isVisible, setEntityVisibleSections, sections]
  );

  return { isVisible, toggleVisibility };
};
