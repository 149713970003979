import { ILayout, LayoutTypes } from '@work4all/models';

import customerWidgets from './default-customer-file-widgets.json';
import projectWidgets from './default-project-file-widgets.json';
import supplierWidgets from './default-supplier-file-widgets.json';

export const useDefaultWidgetLayouts = (props: { tenant: number }) => {
  const { tenant } = props;

  /** Transforming IDs so they can be configured per tenant */
  const layouts: ILayout[] = [
    {
      defaultLayout: true,
      definition: JSON.stringify(customerWidgets),
      id: `13f59a95-0937-4ad0-b324-88f664eb2ce9-${tenant}`,
      name: 'Standard Kunde',
      insertTime: new Date().toISOString(),
      layoutType: LayoutTypes.Customer,
      tenant: 0,
      updateTime: new Date().toISOString(),
    },
    {
      defaultLayout: true,
      definition: JSON.stringify(supplierWidgets),
      id: `ef29a85c-081e-45ba-9b78-772569f7e1c9-${tenant}`,
      name: 'Standard Lieferant',
      insertTime: new Date().toISOString(),
      layoutType: LayoutTypes.Supplier,
      tenant: 0,
      updateTime: new Date().toISOString(),
    },
    {
      defaultLayout: true,
      definition: JSON.stringify(projectWidgets),
      id: `ae9ddc3d-968a-4a80-a11e-b1a20d5a0b6b-${tenant}`,
      name: 'Standard Projekt',
      insertTime: new Date().toISOString(),
      layoutType: LayoutTypes.Project,
      tenant: 0,
      updateTime: new Date().toISOString(),
    },
  ];

  return layouts;
};
