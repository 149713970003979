import { useMemo } from 'react';

import { EntityByLayoutType } from '@work4all/models/lib/GraphQLEntities';
import {
  ILayoutDefinition,
  ILayoutGroupDefinition,
  LayoutTypes,
  WidgetsDefinitions,
} from '@work4all/models/lib/Layout';

import { useDefaultWidgetLayouts } from './use-default-widget-layouts';

export const useWidgetsDefinitions = (props: { tenant: number }) => {
  const { tenant } = props;

  const layouts = useDefaultWidgetLayouts({ tenant });

  const widgetDefinitions = useMemo<WidgetsDefinitions[]>(() => {
    return layouts.map((it) => {
      const definition: ILayoutDefinition[] | ILayoutGroupDefinition[] =
        JSON.parse(it.definition);

      if (definition.length === 0) {
        return { ...it, definition: [] };
      }

      // It's a new format with groups
      if ('widgets' in definition[0]) {
        const groups = definition as ILayoutGroupDefinition[];

        reportMissingFields(
          it.name,
          groups
            .map((group) => group.widgets)
            .reduce((acc, current) => [...acc, ...current], [])
        );

        return { ...it, definition: groups };
      }
      // It's an old format without groups.
      // Group them here. For all files except "Suppliers" create 2 groups.
      // "Suppliers" file will have 1 group to showcase different ui.
      else {
        const widgets = definition as ILayoutDefinition[];

        reportMissingFields(it.name, widgets);

        const split =
          it.layoutType === LayoutTypes.Supplier
            ? widgets.length
            : Math.floor(widgets.length / 2);

        const groups: ILayoutGroupDefinition[] = [
          {
            id: `${it.layoutType}-1`,
            collapsed: false,
            type: 'custom',
            name: 'Group 1',
            widgets: widgets.slice(0, split),
          } as const,
          {
            id: `${it.layoutType}-2`,
            collapsed: false,
            type: 'custom',
            name: 'Group 2',
            widgets: widgets.slice(split),
          } as const,
        ].filter((group) => group.widgets.length > 0);

        return { ...it, definition: groups };
      }
    });
  }, [layouts]);

  return widgetDefinitions;
};

function reportMissingFields(
  layoutName: string,
  widgetConfigs: ILayoutDefinition[]
): void {
  const missing = new Set<string>();

  widgetConfigs.forEach((widgetConfig) => {
    widgetConfig.config.entities.forEach((widgetEntityConfig) => {
      if (!EntityByLayoutType[widgetEntityConfig.entityTypeName]) {
        missing.add(
          `${widgetEntityConfig.entityTypeName} (in Widget ${widgetConfig.title})`
        );
      }
    });
  });

  if (missing.size > 0) {
    console.error(
      `Layout ${layoutName} has missing type configurations for ${[
        ...missing,
      ].join(', ')}. Widgets containing these types will not work properly.`
    );
  }
}
