import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse/Collapse';
import { DateTimeInputPicker } from '@work4all/components/lib/input/date-time-input-picker/DateTimeInputPicker';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input/LabeledInput';

import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { ControllerPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/use-form-context-plus';
import { ProjectProcessKindPickerField } from '../../../../../../../../../../entity-picker/ProjectProcessKindPickerField';
import { ProjectProcessFormValue } from '../../types';

export const GeneralSection = () => {
  const { t } = useTranslation();

  const { register, control, watch } =
    useFormContextPlus<ProjectProcessFormValue>();

  const kindId = watch('kindId');

  return (
    <Collapse title={t('COMMON.GENERAL')} defaultOpen={true}>
      <Grid container spacing="1rem">
        <Grid item xs={4}>
          <ControllerPlus
            control={control}
            name="kindId"
            render={({ field }) => {
              return <ProjectProcessKindPickerField {...field} />;
            }}
          />
        </Grid>
        {kindId !== ProjectProcessKind.GLIEDERUNGSPUNKT && (
          <>
            <Grid item xs={4}>
              <ControllerPlus
                control={control}
                name="startDatum"
                render={({ field }) => {
                  return (
                    <DateTimeInputPicker
                      {...field}
                      value={new Date(field.value)}
                      dateLabel={t('COMMON.STARTDATE')}
                      clearable={false}
                      withTime={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <ControllerPlus
                control={control}
                name="endDateInner"
                render={({ field }) => {
                  return (
                    <DateTimeInputPicker
                      {...field}
                      value={new Date(field.value)}
                      dateLabel={t('COMMON.ENDDATE')}
                      clearable={false}
                      withTime={false}
                      disabled={kindId !== ProjectProcessKind.VORGANG}
                    />
                  );
                }}
              />
            </Grid>{' '}
          </>
        )}

        <Grid item xs={kindId === ProjectProcessKind.GLIEDERUNGSPUNKT ? 8 : 12}>
          <Stack gap="1rem" direction="column">
            <LabeledInput {...register('process')} label={t('COMMON.TITLE')} />
          </Stack>
        </Grid>
      </Grid>
    </Collapse>
  );
};
