import { BottomTableProvider } from '../providers';
import { BottomTableProps } from '../types';

import { BottomTableContent } from './BottomTableContent';
import { BottomTableHeader } from './BottomTableHeader';
import { BottomTableToggleCollapsibilityAction } from './BottomTableToggleCollapsibilityAction';
import { BottomTableViews } from './BottomTableViews';
import { BottomViewTable } from './BottomViewTable';

export const BottomTable = (props: BottomTableProps) => {
  return (
    <BottomTableProvider {...props}>
      <BottomTableHeader>
        <BottomTableToggleCollapsibilityAction />
        <BottomTableViews />
      </BottomTableHeader>

      <BottomTableContent>
        <BottomViewTable />
      </BottomTableContent>
    </BottomTableProvider>
  );
};
