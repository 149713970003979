import { useMemo, useState } from 'react';

import { BottomTableContext } from '../contexts';
import {
  BottomTableContextValue,
  BottomTableProviderProps,
  BottomTableView,
} from '../types';

export const BottomTableProvider = ({
  children,
  views,
  isCollapsed,
  setIsCollapsed,
  prefilter = [],
}: BottomTableProviderProps) => {
  const [activeView, setActiveView] = useState<BottomTableView>(views[0]);

  const value: BottomTableContextValue = useMemo(() => {
    return {
      views,
      isCollapsed,
      togglecollapsibility: () => setIsCollapsed(!isCollapsed),
      activeView,
      setActiveView,
      prefilter,
    };
  }, [activeView, isCollapsed, prefilter, setIsCollapsed, views]);

  return (
    <BottomTableContext.Provider value={value}>
      {children}
    </BottomTableContext.Provider>
  );
};
