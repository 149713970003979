import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';
import { Report2SpecialSourceType } from '../Enums/Report2SpecialSourceType.enum';
import { ReportMode } from '../Enums/ReportMode.enum';
import { ReportType } from '../Enums/ReportType.enum';
import { ReportTypeOfSource } from '../Enums/ReportTypeOfSource.enum';

export class Report2<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: reportType */
  reportType?: ReportType;
  /** Alias: location */
  location?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: reportMode */
  reportMode?: ReportMode;
  /** Alias: typeOfSource */
  typeOfSource?: ReportTypeOfSource;
  /** Alias: sourceDetails */
  sourceDetails?: string;
  /** Alias: specialSourceType */
  specialSourceType?: Report2SpecialSourceType;
  /** Alias: deploymentId */
  deploymentId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Report2> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  reportType: {
    alias: 'reportType',
  },
  location: {
    alias: 'location',
  },
  objectType: {
    alias: 'objectType',
  },
  reportMode: {
    alias: 'reportMode',
  },
  typeOfSource: {
    alias: 'typeOfSource',
  },
  sourceDetails: {
    alias: 'sourceDetails',
  },
  specialSourceType: {
    alias: 'specialSourceType',
  },
  deploymentId: {
    alias: 'deploymentId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const report2EntityDefinition: EntitiyDefinition<Report2> = {
  local: {},
  remote: {
    queryName: 'getReport2',
    fragmentName: 'Report2',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'upsertReport2',
        args: [
          { name: 'input', type: 'InputReport2!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [{ name: 'filter', type: 'String' }],
  },
  fieldDefinitions: fields,
};
