/* eslint-disable no-restricted-imports */
import { useCallback, useRef } from 'react';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { createTransaction } from '../sentry';
/**
 * Extension for useForm from `react-hook-form`.
 * It will add additional layer for sentry logging.
 */
export const useFormPlus = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(
  props?: UseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues, TContext> => {
  // Initialize the form from react-hook-form with the provided props
  const form = useForm<TFieldValues, TContext>(props);
  const handleSubmitRef = useRef(form.handleSubmit);

  // Wrap handleSubmit to add custom logic before the original handleSubmit runs
  const customHandleSubmit: UseFormHandleSubmit<TFieldValues> = useCallback(
    (
      onValid: SubmitHandler<TFieldValues>,
      onInvalid?: SubmitErrorHandler<TFieldValues>
    ) => {
      return handleSubmitRef.current(async (data) => {
        const type = props.defaultValues?.__typename;
        const transaction = createTransaction({
          name: `Handle form submit${type ? ` (${type})` : ''}`,
        });

        try {
          await onValid(data);
        } finally {
          transaction.finish();
        }
      }, onInvalid);
    },
    [props.defaultValues?.__typename]
  );

  form.handleSubmit = customHandleSubmit;
  return form;
};
