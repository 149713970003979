import { useEffect, useState } from 'react';

import { IRouteParams } from '../types';

import { useOverlayRouteParams } from './use-overlay-route-params';

export function useMaskMetaData(): IRouteParams {
  // When you navigate back in the browser, the route will change and will no
  // longer include the mask parameters, but the mask component still needs to
  // render during its closing animation. Because of this we keep the old
  // `metaDataSaved` value and use it if the actual `metaDataCurrent` resolves
  // to `null`.
  const metaDataCurrent = useOverlayRouteParams();
  const [metaDataSaved, setMetaDataSaved] = useState(metaDataCurrent);

  // Update the saved value whenever the mask parameters change, except if you
  // close the mask (in this case just keep the old value).
  useEffect(() => {
    if (metaDataCurrent != null) {
      setMetaDataSaved(metaDataCurrent);
    }
  }, [metaDataCurrent]);

  const metaData = metaDataCurrent ?? metaDataSaved;

  return metaData;
}
