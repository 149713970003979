import styles from './ToggleButton.module.scss';

import { ExpandMore } from '@mui/icons-material';
import { Menu, MenuItem, Stack } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

import { BaseActionButton } from '../../input/base-action-button/BaseActionButton';

interface IToggleButtonProps {
  isActive: (timeUnit: RelativeDateFilter) => boolean;
  onChange?: (value: RelativeDateFilter) => void;
  values: { id; name: string }[];
}

export const ToggleButton: React.FC<IToggleButtonProps> = (props) => {
  const { onChange, values, isActive } = props;

  const active = useMemo(() => {
    let result = false;
    for (const value of values) {
      if (isActive(value.id)) {
        result = true;
      }
    }
    return result;
  }, [isActive, values]);

  const activeItem = useMemo(() => {
    return values.find((x) => isActive(x.id));
  }, [isActive, values]);

  const title = useMemo(() => {
    return activeItem?.name || values[0]?.name;
  }, [activeItem?.name, values]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClickMenuBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" className={styles.toggleButtonWrap}>
      <BaseActionButton
        onClick={() => {
          onChange?.(active ? null : values[0].id);
        }}
        className={clsx(styles.toggleButton, styles.maxWidth, {
          [styles.active]: active,
        })}
        title={title}
      ></BaseActionButton>
      {values.length > 1 && (
        <BaseActionButton
          onClick={handleClickMenuBtnClick}
          className={clsx(styles.toggleButton, { [styles.active]: active })}
          icon={<ExpandMore />}
        ></BaseActionButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorPosition={{
          left: 0,
          top: 0,
        }}
      >
        {values.map((val) => {
          return (
            <MenuItem
              onClick={() => {
                onChange(val.id);
                handleClose();
              }}
            >
              {val.name}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
