import styles from './EMailSignatureOverlayController.module.scss';

import { Button } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import {
  useDataMutation,
  useDataProvider,
  useFormPlus,
  useUser,
} from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { ControllerPlus } from '../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { MenuItem } from '../../../locked-inputs';
import { Collapse, ControlWrapper, Form } from '../../components';
import { MaskActions } from '../../components/MaskActions';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';

type EMailSignatureFormValue = PathsOf<EMailSignature, 2>;

type EMailSignatureOverlayControllerProps = MaskControllerProps & {
  amplitudeEntryPoint: string;
};

export function EMailSignatureOverlayController(
  props: EMailSignatureOverlayControllerProps
) {
  const { amplitudeEntryPoint } = props;
  const { t } = useTranslation();

  const { goBack, close, currentStackIndex, setObjectionListener } =
    useHistoryStack();
  const [mutate] = useDataMutation<
    EMailSignature,
    EMode.upsert,
    {
      attachements;
    }
  >({
    entity: Entities.eMailSignature,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
    },
  });

  const mask = useMaskConfig(props);

  useEffect(() => {
    if (mask.isCreateMode) {
      sendAmplitudeData(EventType.AddEMailSignature, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditEMailSignature, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, mask, mask.isCreateMode, props.id]);

  const requestEMailSignatureData = useMemo<DataRequest>(() => {
    const EMailSignatureRequestData: EMailSignature<EMode.query> = {
      id: null,
      name: null,
      body: null,
    };
    const filter = [{ id: { $eq: mask.id } }];
    const data = EMailSignatureRequestData;
    return {
      entity: Entities.eMailSignature,
      data,
      filter,
      keysArguments: EMAIL_SIGNATURE_KEYS_ARGUMENTS,
    };
  }, [mask.id]);

  const currentEMailSignature = useDataProvider<EMailSignature>(
    requestEMailSignatureData,
    mask.isCreateMode
  );

  const dataRaw = useMemo(() => {
    const defaultValues = {};

    const result =
      (mask.isCreateMode
        ? defaultValues
        : currentEMailSignature?.data?.find((x) => x.id === props.id)) ??
      defaultValues;
    return result;
  }, [currentEMailSignature?.data, mask.isCreateMode, props.id]);

  const data = useMemo<PathsOf<EMailSignature, 2>>(() => {
    const result = normalizeFormValue(dataRaw);
    return result;
  }, [dataRaw]);

  const schema = useEntityJsonSchema(Entities.eMailSignature);
  const resolver = useJSONSchemaResolver(schema);

  const form = useFormPlus<EMailSignatureFormValue>({
    resolver,
    defaultValues: data,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { formState } = form;

  useConfirmBeforeCloseMask(formState.isDirty);

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const deleteHandler = useStandardDeleteEntityHandler({
    entity: Entities.eMailSignature,
    id: mask.id,
  });

  const onSubmit = useEventCallback(async (values: EMailSignatureFormValue) => {
    const update = mask.isCreateMode
      ? values
      : pick(values, ['id', ...Object.keys(formState.dirtyFields)]);

    if (update.body) {
      update.body = htmlParser.applyInlineStyles(update.body);
    }

    await mutate(update);

    setObjectionListener(null);
    sendAmplitudeData(EventType.SaveEMailSignature, {
      entryPoint: amplitudeEntryPoint,
    });
    if (currentStackIndex !== 0) {
      goBack();
    } else {
      close();
    }
  });

  const maskContext = useMaskContextValue({ ...mask, data });

  return (
    <MaskContextProvider value={maskContext}>
      <FormProvider {...form}>
        <div className={clsx(styles.root, 'custom-scrollbar')}>
          <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <NavigationOverlayHeader
                title={t('COMMON.EMAILSIGNATURE')}
                breadcrumbsChildren={
                  <MaskActions>
                    <Button
                      type="submit"
                      size="large"
                      color="primary"
                      variant="contained"
                    >
                      {t('INPUTS.SAVE')}
                    </Button>
                    {mask.id && (
                      <MaskOverlayMenuWrapper>
                        <MenuItem onClick={deleteHandler}>
                          {t('MASK.REMOVE')}
                        </MenuItem>
                      </MaskOverlayMenuWrapper>
                    )}
                  </MaskActions>
                }
              />

              <EMailSignatureMaskContent />
            </Form>
          </div>
        </div>
      </FormProvider>
    </MaskContextProvider>
  );
}

const generateFileUploadUrl = (baseUrl: string, authToken: string) => {
  return `${baseUrl}/api/file/inlinemailimage?auth=${authToken}`;
};

function EMailSignatureMaskContent() {
  const { t } = useTranslation();

  const { register, control } = useFormContextPlus<EMailSignatureFormValue>();

  const { activeTenant } = useTenant();
  const user = useUser();

  return (
    <>
      <div className={styles.metaData}>
        <div className={styles.left}>
          <Collapse title={t('COMMON.DESCRIPTION')} defaultOpen>
            <ControlWrapper>
              <LabeledInput label={t('COMMON.NAME')} {...register('name')} />
            </ControlWrapper>
          </Collapse>
        </div>
      </div>
      <Collapse defaultOpen>
        <ControllerPlus
          control={control}
          name="body"
          render={({ field }) => (
            <TextEditor
              value={field.value}
              onChange={field.onChange}
              enableTextmarks={true}
              enableTextBuildingBlocks={true}
              applyZoomLevel={false}
              config={{
                heightMin: 200,
                requestHeaders: {
                  'x-work4all-mandant': activeTenant,
                },
                imageUpload: true,
                imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
                events: {
                  drop: function (dropEvent) {
                    dropEvent.preventDefault();
                  },
                },
              }}
            />
          )}
        />
      </Collapse>
    </>
  );
}
