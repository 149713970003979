import { Box, Grid } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import produce from 'immer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { ArticleLedgerAccount } from '@work4all/models/lib/Classes/ArticleLedgerAccount.entity';
import { TaxGroupType } from '@work4all/models/lib/Enums/TaxGroupType.enum';

import { throwInDev } from '@work4all/utils';

import { CostCenterPickerField } from '../../../../../../../components/entity-picker/CostCenterPickerField';
import { CountryPickerField } from '../../../../../../../components/entity-picker/CountryPickerField';
import { VatRatePickerField } from '../../../../../../../components/entity-picker/VatRatePickerField';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { ArticleMaskFormValue } from '../../types';

import { LedgerAccounts } from './LedgerAccounts';

export function AccountingTabPanel() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();

  const articleLedgerAccounts = form.watch('articleLedgerAccountAssignements');

  const profitLedgerAccounts = useMemo(
    () => filterByGroupType(articleLedgerAccounts, TaxGroupType.PROFIT),
    [articleLedgerAccounts]
  );

  const costLedgerAccounts = useMemo(
    () => filterByGroupType(articleLedgerAccounts, TaxGroupType.COST),
    [articleLedgerAccounts]
  );

  const handleLedgerAccountsChange = useEventCallback(
    (original: ArticleLedgerAccount, change: Partial<ArticleLedgerAccount>) => {
      const newValue = produce(articleLedgerAccounts, (draft) => {
        const found = draft.find(
          (item) =>
            item.taxGroup.id === original.taxGroup.id &&
            item.taxGroup.type === original.taxGroup.type
        );

        if (!found) {
          const id = `${original.taxGroup.type}-${original.taxGroup.id}`;
          throwInDev(`ArticleLedgerAccount:${id} not found`);
          return;
        }

        Object.assign(found, change);
      });

      form.setValue('articleLedgerAccountAssignements', newValue, {
        shouldDirty: true,
      });
    }
  );

  return (
    <Box sx={{ py: '1rem' }}>
      <Grid container columns={1}>
        <Grid item xs={1}>
          <Collapse title={t('ERP.BASE_DATA')} defaultOpen bottomIndent="lg">
            <Grid container columns={4} spacing="1rem">
              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="costCenterNew"
                  render={({ field }) => {
                    return (
                      <CostCenterPickerField
                        label={t('COMMON.COST_CENTER')}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="vatRate"
                  render={({ field }) => {
                    return (
                      <VatRatePickerField label={t('COMMON.VAT')} {...field} />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  name="landOfOrigin"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <CountryPickerField
                        label={t('MASK.ARTICLE.FIELDS.landOfOrigin')}
                        {...field}
                        value={field.value ? { shortName: field.value } : null}
                        onChange={(result) => {
                          field.onChange(result?.shortName || '');
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.REVENUE_ACCOUNTS')}
            defaultOpen
            bottomIndent="lg"
          >
            <LedgerAccounts
              ledgerAccounts={profitLedgerAccounts}
              onChange={handleLedgerAccountsChange}
              ledgerAccountFilter={PROFIT_LEDGER_ACCOUNTS_FILTER}
            />
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.COST_ACCOUNTS')}
            defaultOpen
            bottomIndent="lg"
          >
            <LedgerAccounts
              ledgerAccounts={costLedgerAccounts}
              onChange={handleLedgerAccountsChange}
              ledgerAccountFilter={COST_LEDGER_ACCOUNTS_FILTER}
            />
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}

function filterByGroupType(
  ledgerAccounts: ArticleLedgerAccount[],
  type: TaxGroupType
) {
  const filtered = ledgerAccounts.filter((x) => x.taxGroup.type === type);
  return filtered;
}

const PROFIT_LEDGER_ACCOUNTS_FILTER = [
  { kind: { $eq: 6 } },
  { disabled: { $eq: false } },
];

const COST_LEDGER_ACCOUNTS_FILTER = [
  { kind: { $in: [4, 10] } },
  { disabled: { $eq: false } },
];
