import { TextmarksList } from '../../types';

import { EMAIL_TEXTMARKS } from './email-textmarks';

export const ERP_EMAIL_TEXTMARKS: TextmarksList = {
  ...EMAIL_TEXTMARKS,
  ERP: {
    id: 'TEXTMARK.ERP',
    name: 'TEXTMARK.ERP',
    subItems: [
      {
        id: 'TEXTMARK.ERP_NUMBER',
        name: 'TEXTMARK.ERP_NUMBER',
        accessor: 'ERP.number',
      },
      {
        id: 'TEXTMARK.ERP_KIND',
        name: 'TEXTMARK.ERP_KIND',
        accessor: 'ERP.kind',
      },
      {
        id: 'TEXTMARK.ERP_NOTE',
        name: 'TEXTMARK.ERP_NOTE',
        accessor: 'ERP.note',
      },
    ],
  },
};
