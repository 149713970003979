import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class StatisticCustomerDatasheetStatistic<
  T extends EMode = EMode.entity
> {
  /** Alias: value */
  value?: number;
  /** Alias: statisticName */
  statisticName?: string;
  /** Alias: number */
  number?: string;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCustomerDatasheetStatistic> = {
  value: {
    alias: 'value',
  },
  statisticName: {
    alias: 'statisticName',
  },
  number: {
    alias: 'number',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCustomerDatasheetStatisticEntityDefinition: EntitiyDefinition<StatisticCustomerDatasheetStatistic> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticCustomerDatasheetStatistic',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
