import { MouseEventHandler } from 'react';

import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';

import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ProjectFileInfoPanel } from '../ProjectFileInfoPanel';

export type IProjectFileInfoPanelContainerProps = {
  projectId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCloseClick?: MouseEventHandler<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEditClicked?: MouseEventHandler<any>;
  onVisibilityToggle?: (visible: boolean) => void;
};
export const requestedProjectData: Project<EMode.query> = {
  id: null,
  name: null,
  number: null,
  documentDirectory: null,
  creatorUserId: null,
  creator: {
    id: null,
    displayName: null,
  },
  group: {
    id: null,
    name: null,
  },
  projectKind: {
    name: null,
    id: null,
  },
  startDateInner: null,
  endDateInner: null,
  startDateOuter: null,
  endDateOuter: null,
  supplier: {
    id: null,
    name: null,
    website: null,
  },
  customer: {
    id: null,
    name: null,
    website: null,
  },
  projectStatus: {
    name: null,
    id: null,
    closedStatus: null,
  },
  note: null,
  parentProject: {
    id: null,
    name: null,
  },
  budget: null,
  categoryAssignmentList: [
    {
      categoryName: null,
      categoryId: null,
      categoryKindId: null,
      categoryKindName: null,
      id: null,
    },
  ],
};

export function ProjectFileInfoPanelContainer(
  props: IProjectFileInfoPanelContainerProps
) {
  const { projectId, onCloseClick, onEditClicked, onVisibilityToggle } = props;
  const projects = useEntityPreviewData<Project>(
    [projectId],
    Entities.project,
    requestedProjectData
  );

  const { activeTenant } = useTenant();
  if (!projects) {
    return null;
  }

  return (
    <ProjectFileInfoPanel
      project={projects[0]}
      onEdit={() => onEditClicked(null)}
      onCloseClick={() => onCloseClick(null)}
      onOpenFilePageClicked={() => {
        window.open(`/t-${activeTenant}/projects/${projectId}`, '_blank');
      }}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
