import {
  createContext,
  useContext,
  useDebugValue,
  useMemo,
  useState,
} from 'react';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { environment } from '../../../../environments';
import { NEW_ENTITY_ID } from '../mask-metadata';
import { MaskConfig, MaskExtendedConfig } from '../types';

export interface MaskContextValue<T = unknown> extends MaskExtendedConfig {
  isLoading?: boolean;
  data?: T;
  customFields?: ParsedCustomFieldConfig[];
  commentHistoryValue?: string;
  setCommentHistoryValue?: (value: string) => void;
}

export const MaskContext = createContext<MaskContextValue | null>(null);

export interface MaskContextProviderProps<T = unknown> {
  value: MaskContextValue<T>;
  children?: React.ReactNode;
}

export function MaskContextProvider<T>({
  children,
  value,
}: MaskContextProviderProps<T>) {
  const [commentHistoryValue, setCommentHistoryValue] = useState('');

  const extendedValue = useMemo(() => {
    return {
      commentHistoryValue,
      setCommentHistoryValue,
      ...value,
    };
  }, [commentHistoryValue, value]);
  return (
    <MaskContext.Provider value={extendedValue}>
      {children}
    </MaskContext.Provider>
  );
}

MaskContext.Provider as <T = unknown>(
  props: MaskContextProviderProps<T>
) => JSX.Element;

export function useMaskConfig<P extends string = string>(
  config: MaskConfig<P>
): MaskExtendedConfig<P> {
  const { entity, entityVariant, id, template, params } = config;

  const wip = !environment.entities[entity];

  const value = useMemo<MaskExtendedConfig<P>>(() => {
    const mode = id == null || id === NEW_ENTITY_ID ? 'create' : 'edit';

    const value: MaskExtendedConfig<P> = {
      entity,
      entityVariant,
      id,
      template,
      params: params ?? {},
      mode,
      isCreateMode: mode === 'create',
      isEditMode: mode === 'edit',
      wip,
    };

    return value;
  }, [entity, entityVariant, id, template, params, wip]);

  useDebugValue(value);

  return value;
}

/**
 * A utility hook to help create a memoized context value to avoid unnecessary
 * re-renders.
 */
export function useMaskContextValue<T = unknown>(
  value: MaskContextValue<T>
): MaskContextValue {
  const {
    isLoading,
    entity,
    entityVariant,
    id,
    mode,
    isCreateMode,
    isEditMode,
    wip,
    params,
    template,
    data,
    customFields,
  } = value;

  const context = useMemo<MaskContextValue<T>>(() => {
    return {
      isLoading,
      entity,
      entityVariant,
      id,
      mode,
      isCreateMode,
      isEditMode,
      wip,
      params,
      template,
      data,
      customFields,
    };
  }, [
    isLoading,
    entity,
    entityVariant,
    id,
    mode,
    isCreateMode,
    isEditMode,
    wip,
    params,
    template,
    data,
    customFields,
  ]);

  return context;
}

export function useMaskContext<T = unknown>(): MaskContextValue<T> {
  const context = useContext(MaskContext);

  if (context == null) {
    throw new Error('useMask must be used within a <MaskContextProvider>');
  }

  return context as MaskContextValue<T>;
}
