import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';

export class BzObjectMutationRequest<T extends EMode = EMode.entity> {
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: codes */
  codes?: number[];
  /** Alias: isClosed */
  isClosed?: boolean;
  /** Alias: frozen */
  frozen?: boolean;
  /** Alias: bodyText */
  bodyText?: any;

  __typename?: string;
}

const fields: FieldDefinitions<BzObjectMutationRequest> = {
  bzObjType: {
    alias: 'bzObjType',
  },
  codes: {
    alias: 'codes',
  },
  isClosed: {
    alias: 'isClosed',
  },
  frozen: {
    alias: 'frozen',
  },
  bodyText: {
    alias: 'bodyText',
  },
  __typename: {
    alias: '__typename',
  },
};

export const bzObjectMutationRequestEntityDefinition: EntitiyDefinition<BzObjectMutationRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'BzObjectMutationRequest',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
