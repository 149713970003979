import { TextmarksList } from '../../types';

import { EMAIL_TEXTMARKS } from './email-textmarks';

export const APPOINTMENT_TEXTMARKS: TextmarksList = {
  ...EMAIL_TEXTMARKS,
  appointment: {
    id: 'TEXTMARK.APPOINTMENT',
    name: 'TEXTMARK.APPOINTMENT',
    subItems: [
      {
        id: 'TEXTMARK.APPOINTMENT_DATE',
        name: 'TEXTMARK.APPOINTMENT_DATE',
        accessor: 'appointment.date',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_LOCATION',
        name: 'TEXTMARK.APPOINTMENT_LOCATION',
        accessor: 'appointment.city',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_SUBJECT',
        name: 'TEXTMARK.APPOINTMENT_SUBJECT',
        accessor: 'appointment.title',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_NOTE',
        name: 'TEXTMARK.APPOINTMENT_NOTE',
        accessor: 'appointment.note',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_ORDER_NUMBER',
        name: 'TEXTMARK.APPOINTMENT_ORDER_NUMBER',
        accessor: 'appointment.contractNumber',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_DELIVERY_NOTE_NUMBER',
        name: 'TEXTMARK.APPOINTMENT_DELIVERY_NOTE_NUMBER',
        accessor: 'appointment.deliveryNoteNumber',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_PARTICIPANTS',
        name: 'TEXTMARK.APPOINTMENT_PARTICIPANTS',
        accessor: 'appointment.participants',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_RESOURCES',
        name: 'TEXTMARK.APPOINTMENT_RESOURCES',
        accessor: 'appointment.resources',
      },
      {
        id: 'TEXTMARK.APPOINTMENT_EXTERN',
        name: 'TEXTMARK.APPOINTMENT_EXTERN',
        accessor: 'appointment.extern',
      },
    ],
  },
};
