import { useTranslation } from 'react-i18next';

import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type IEInvoiceFormatPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: string; name: string }, TMultiple>;

export function EInvoiceFormatPicker<TMultiple extends boolean>(
  props: IEInvoiceFormatPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: EInvoiceFormat.PDF,
      name: t('E_INVOICE_FORMAT.' + EInvoiceFormat.PDF),
    },
    {
      id: EInvoiceFormat.X_RECHNUNG_CII,
      name: t('E_INVOICE_FORMAT.' + EInvoiceFormat.X_RECHNUNG_CII),
    },
    {
      id: EInvoiceFormat.X_RECHNUNG_UBL,
      name: t('E_INVOICE_FORMAT.' + EInvoiceFormat.X_RECHNUNG_UBL),
    },
    {
      id: EInvoiceFormat.ZUG_FE_RD,
      name: t('E_INVOICE_FORMAT.' + EInvoiceFormat.ZUG_FE_RD),
    },
  ];

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
