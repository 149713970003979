import styles from './organisation-area-settings-popover.module.scss';

import { Popover } from '@mui/material';

import { OrganisationAreaSettingsPopoverProps } from '../types';

export const OrganisationAreaSettingsPopover = (
  props: OrganisationAreaSettingsPopoverProps
) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{ paper: styles.paper }}
      {...props}
    />
  );
};
