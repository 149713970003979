import { Download, Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { t } from 'i18next';

import { StackProvider } from '../../../../navigation/history-stack';
import { Content } from '../../../../navigation/navigation-overlay/components/content/Content';
import { PreviewTitle } from '../../components/preview-title/PreviewTitle';

interface SimpleReportWithFullScreenProps {
  preview: JSX.Element;
  fileName: string;
  open: boolean;
  onClose: () => void;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onDownloadClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SimpleReportWithFullScren = (
  props: SimpleReportWithFullScreenProps
) => {
  const { preview, fileName, open, onClose, onEditClicked, onDownloadClick } =
    props;

  return (
    <StackProvider
      onClose={onClose}
      initialView={{
        title: fileName || t('COMMON.PREVIEW'),
        view: (
          <Box height={'100%'} display="flex" flexDirection="column">
            <PreviewTitle
              onCloseClick={onClose}
              onEditClicked={onEditClicked}
              actions={
                <>
                  {onEditClicked && (
                    <IconButton color="primary" onClick={onEditClicked}>
                      <Edit />
                    </IconButton>
                  )}
                  {onDownloadClick && (
                    <IconButton color="primary" onClick={onDownloadClick}>
                      <Download />
                    </IconButton>
                  )}
                </>
              }
            >
              {fileName}
            </PreviewTitle>
            <Box flex="1">{preview}</Box>
          </Box>
        ),
      }}
    >
      {preview}
      <Content open={open} close={onClose} onExited={onClose} />
    </StackProvider>
  );
};
