import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ArticleCategory } from './ArticleCategory.entity';

export class ArticleCategoryMark<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: kategorienCode */
  kategorienCode?: number;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: articleCategory */
  articleCategory?: ArticleCategory<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ArticleCategoryMark> = {
  id: {
    alias: 'code',
  },
  kategorienCode: {
    alias: 'kategorienCode',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  articleCategory: {
    alias: 'articleCategory',
    entity: Entities.articleCategory,
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleCategoryMarkEntityDefinition: EntitiyDefinition<ArticleCategoryMark> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ArtikelKategorieMark',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
