const DEFAULT_WIDTH = 340;
const DEFAULT_HEIGHT = 340;

export const resizeImage = async (
  file: File,
  dimensions: {
    width?: number;
    height?: number;
  } = {}
) => {
  return new Promise<File>((resolve) => {
    const { width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT } = dimensions;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const image = new Image();
    const objectURL = URL.createObjectURL(file);
    image.src = objectURL;

    image.onload = () => {
      canvas.width = width;
      canvas.height = height;

      ctx?.drawImage(image, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob as Blob], file.name, {
          type: file.type,
        });

        URL.revokeObjectURL(objectURL);

        resolve(resizedFile);
      });
    };

    image.onerror = () => {
      URL.revokeObjectURL(objectURL);
      resolve(file);
    };
  });
};
