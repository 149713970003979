import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LedgerAccountPickerField } from '../../../../../../../components/entity-picker/LedgerAccountPickerField';
import { settings, useSetting } from '../../../../../../../settings';
import { useMaskContext } from '../../../../hooks/mask-context';

export const LedgerAccountSettings = () => {
  const { entity } = useMaskContext();

  const { set: setDefaultLedgerAccountId, value: defaultLedgerAccountId } =
    useSetting(settings.paymentDefaultLedgerAccountId(entity));

  const {
    set: setDefaultSkontoLedgerAccountId,
    value: defaultSkontoLedgerAccountId,
  } = useSetting(settings.paymentDefaultSkontoLedgerAccountId(entity));

  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        number: null,
        name: null,
      } as LedgerAccount,
      entity: Entities.ledgerAccount,
      filter: [
        {
          id: {
            $in: [defaultLedgerAccountId, defaultSkontoLedgerAccountId],
          },
        },
      ],
    };
  }, [defaultLedgerAccountId, defaultSkontoLedgerAccountId]);

  const { data } = useDataProvider<LedgerAccount>(request);

  const defaultLedgerAccount = useMemo(() => {
    return data.find((a) => a.id === defaultLedgerAccountId);
  }, [data, defaultLedgerAccountId]);

  const defaultSkontoLedgerAccount = useMemo(() => {
    return data.find((a) => a.id === defaultSkontoLedgerAccountId);
  }, [data, defaultSkontoLedgerAccountId]);

  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" gap="1rem" pr="1rem">
      <Box width="12rem" maxWidth="100%">
        <LedgerAccountPickerField
          value={defaultLedgerAccount}
          onChange={(x) => setDefaultLedgerAccountId(x?.id || 0)}
          label={t('PAYMENTS.DEFAULT.LEDGERACCOUNT')}
        />
      </Box>
      <Box width="12rem" maxWidth="100%">
        <LedgerAccountPickerField
          value={defaultSkontoLedgerAccount}
          onChange={(x) => setDefaultSkontoLedgerAccountId(x?.id || 0)}
          label={t('PAYMENTS.DEFAULT.SKONTO.LEDGERACCOUNT')}
        />
      </Box>
    </Stack>
  );
};
