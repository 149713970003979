import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useMaskContext } from '../../../hooks/mask-context';
import { Payment, PaymentEntity } from '../types';

import { useDefaultLedgerAccounts } from './use-default-ledger-accounts';
import { usePaymentHandlers } from './use-payment-handlers';

interface Data {
  discount: number;
  discountAmount: number;
  savedPaymentAmount: number;
  paymentAmount: number;
}

interface Props {
  invoiceId: number;
  discount: number;
  remaining: number;
}

const toFixed = (val: number) => parseFloat(val.toFixed(2));

export const useBookingAssistance = ({
  invoiceId,
  discount,
  remaining,
}: Props) => {
  const { entity } = useMaskContext();

  const { watch } = useFormContext();
  const payments = watch('payments') || [];

  const { defaultLedgerAccount, defaultSkontoLedgerAccount } =
    useDefaultLedgerAccounts(entity as PaymentEntity);

  const { onAdd } = usePaymentHandlers({
    invoiceId,
    payments,
  });

  const initial: Data = useMemo(() => {
    const discountAmount = toFixed((remaining / 100) * discount);
    const paymentAmount = toFixed(remaining - discountAmount);
    return {
      discount: discount || 0,
      discountAmount,
      paymentAmount,
      savedPaymentAmount: remaining,
    };
  }, [discount, remaining]);

  const [data, setData] = useState<Data>(initial);

  const canCreate = data.paymentAmount > 0 || data.discountAmount > 0;

  const create = useCallback(() => {
    if (canCreate) {
      const bookingDate = DateTime.now().toISO();

      const result: Payment[] = [];

      if (data.paymentAmount > 0) {
        result.push({
          amount: data.paymentAmount,
          bookingDate,
          ledgerAccount: defaultLedgerAccount,
          ledgerAccountNumber: defaultLedgerAccount?.id,
        });
      }

      if (data.discountAmount > 0) {
        result.push({
          amount: data.discountAmount,
          bookingDate,
          ledgerAccount: defaultSkontoLedgerAccount,
          ledgerAccountNumber: defaultSkontoLedgerAccount?.id,
        });
      }

      onAdd(result);
      setData(initial);
    }
  }, [
    canCreate,
    data.paymentAmount,
    data.discountAmount,
    onAdd,
    initial,
    defaultLedgerAccount,
    defaultSkontoLedgerAccount,
  ]);

  const edit = useCallback(
    (key: keyof Omit<Data, 'savedPaymentAmount'>, value: number) => {
      const editedData = { ...data, [key]: Number(Math.max(value, 0)) || 0 };

      switch (key) {
        case 'paymentAmount': {
          editedData.paymentAmount = Math.min(
            editedData.paymentAmount,
            editedData.savedPaymentAmount
          );
          editedData.discount = toFixed(
            ((editedData.savedPaymentAmount - editedData.paymentAmount) * 100) /
              editedData.savedPaymentAmount
          );
          editedData.discountAmount = toFixed(
            editedData.savedPaymentAmount - editedData.paymentAmount
          );
          break;
        }
        case 'discountAmount': {
          const correctedDiscountAmount = toFixed(
            Math.min(editedData.discountAmount, editedData.savedPaymentAmount)
          );

          editedData.discountAmount = correctedDiscountAmount;

          editedData.paymentAmount =
            editedData.savedPaymentAmount - correctedDiscountAmount;

          editedData.discount = toFixed(
            ((editedData.savedPaymentAmount - editedData.paymentAmount) * 100) /
              editedData.savedPaymentAmount
          );

          break;
        }
        case 'discount': {
          editedData.discount = Math.min(editedData.discount, 100);
          editedData.discountAmount = toFixed(
            editedData.savedPaymentAmount * editedData.discount * 0.01
          );
          editedData.paymentAmount = toFixed(
            editedData.savedPaymentAmount - editedData.discountAmount
          );
          break;
        }
        default:
          break;
      }

      editedData.discountAmount = toFixed(editedData.discountAmount);

      setData(editedData);
    },
    [data]
  );

  return { data, canCreate, create, edit };
};
