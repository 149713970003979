import { Close, East, West } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { uuid } from 'short-uuid';

import { Collapse } from '@work4all/components/lib/components/collapse/Collapse';
import { ProjectStepLinkDirection } from '@work4all/components/lib/components/entity-picker/project-step-link-direction-picker copy/ProjectStepLinkDirectionPicker';

import { ControllerPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/use-form-context-plus';
import { ProjectProcessConnectionCreator } from '../../../project-process-connection-creator/ProjectProcessConnectionCreator';
import { ConnectionDefinition, ProjectProcessFormValue } from '../../types';

export const ConnectionsSection = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContextPlus<ProjectProcessFormValue>();

  const projectId = watch('projectId');
  const id = watch('id');

  return (
    <Collapse title={t('COMMON.CONNECTIONS')} defaultOpen={false}>
      <ControllerPlus
        control={control}
        name="connections"
        render={({ field }) => {
          return (
            <>
              {field.value?.map((connection) => {
                return (
                  <Grid
                    container
                    spacing="1rem"
                    p="0.5rem 0"
                    borderBottom="1px solid var(--ui04)"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={3}
                      alignContent="center"
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                    >
                      {connection.direction ===
                      ProjectStepLinkDirection.Incoming ? (
                        <East color="disabled" />
                      ) : (
                        <West color="disabled" />
                      )}
                      <Typography>
                        {t(
                          'PROJECT_STEP_LINK_DIRECTION.' + connection.direction
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        {t('PROJECT_STEP_LINK_TYPE.' + connection.linkType)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                    >
                      <Typography
                        width="100%"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {connection.connectedProcessName}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          field.onChange(
                            field.value.filter(
                              (x) => x.uuid !== connection.uuid
                            )
                          );
                        }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
              <Box mt="1rem">
                <ProjectProcessConnectionCreator
                  processId={id}
                  projectId={projectId}
                  onChange={(value) => {
                    field.onChange([
                      ...field.value,
                      {
                        connectedProcessId: value.projectProcess.id,
                        connectedProcessKind: value.projectProcess.kindId,
                        connectedProcessName: value.projectProcess.process,
                        direction: value.direction,
                        linkType: value.linkType,
                        uuid: uuid(),
                      } as ConnectionDefinition,
                    ]);
                  }}
                />
              </Box>
            </>
          );
        }}
      />
    </Collapse>
  );
};
