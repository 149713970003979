import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EMAIL_TEMPLATE_PICKER_DATA,
  EmailTemplatePicker,
  EmailTemplatePickerProps,
} from '@work4all/components/lib/components/entity-picker/email-template-picker/EmailTemplatePicker';

import { useUser } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canAddEmailTemplate } from '@work4all/utils/lib/permissions';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface EmailTemplatePickerFieldProps extends EmailTemplatePickerProps {
  label?: string;
  error?: string;
  disabled?: boolean;
}

export const EmailTemplatePickerField = React.forwardRef<
  HTMLDivElement,
  EmailTemplatePickerFieldProps
>(function EmailTemplatePickerField(props, ref) {
  const { error, disabled = false, label, ...pickerProps } = props;
  const {
    value,
    onChange: pickerOnChange,
    data,
    clearable = true,
  } = pickerProps;

  const { t } = useTranslation();
  const user = useUser();

  const popoverRef = useRef<EntityPickerPopover>(null);

  const allFields = useMemo(() => {
    return { ...EMAIL_TEMPLATE_PICKER_DATA, ...data };
  }, [data]);

  const field = (
    <PickerTargetButton
      ref={ref}
      value={value ? value.name : ''}
      label={label ?? t('INPUTS.TEMPLATE')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              pickerOnChange(null);
            }
      }
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<EmailTemplatePicker {...pickerProps} />}
      footer={
        <EntityPickerActions
          disableAddButton={!canAddEmailTemplate(user)}
          entity={Entities.eMailTemplate}
          multiple={false}
          data={allFields}
          onChange={(value) => {
            pickerOnChange(value as EMailTemplate);
            popoverRef.current?.close();
          }}
        />
      }
    >
      {field}
    </EntityPickerPopover>
  );
});
