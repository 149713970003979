import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ListIcon from '@mui/icons-material/List';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useResizeObserver } from '@work4all/components/lib/hooks';

import { Link } from '@work4all/data';

import {
  MAX_DISPLAYED_NOTIFICATIONS,
  NotificationList,
  useLatestNotifications,
} from '../../../../../../features/notifications';
import { NotificationActions } from '../../../../../../features/notifications/components/NotificationActions/NotificationActions';
import { MaskModal } from '../../../../../entity-picker/MaskModal';

export interface LatestNotificationsProps {
  onClose: () => void;
}

export function LatestNotifications(props: LatestNotificationsProps) {
  const { onClose } = props;

  const { t } = useTranslation();

  const [notificationsContainerElement, setNotificationsContainerElement] =
    useState<HTMLElement>(null);

  const [notificationsContainerHeight, setNotificationsContainerHeight] =
    useState(0);

  // Measure the height of the notification list container and set min height
  // so the list doesn't collapse to 0 and doesn't take more space than it
  // actually needs.
  useResizeObserver(notificationsContainerElement, (x) => {
    const size = x.borderBoxSize[0];
    setNotificationsContainerHeight(size.blockSize);
  });

  const latestNotifications = useLatestNotifications({
    pageSize: MAX_DISPLAYED_NOTIFICATIONS,
  });

  const { data, total } = latestNotifications;

  if (data.length === 0) {
    return null;
  }

  return (
    <NotificationActions
      onMutationCompleted={() => {
        latestNotifications.refetch();
      }}
    >
      {({ mask, onCloseMask, onNotificationClick, onMarkAllAsReadClick }) => {
        const notificationsPageLinkProps = {
          component: Link,
          to: '/more/notifications',
          onClick: onClose,
        };

        return (
          <>
            <Stack direction="row" alignItems="center" px="1rem">
              <Divider
                style={{ flex: '1', marginRight: '1rem' }}
                title={t('COMMON.NOTIFICATIONS')}
              />

              <Tooltip title={t('NOTIFICATIONS.MARK_ALL_AS_READ')}>
                <IconButton
                  size="large"
                  color="primary"
                  onClick={onMarkAllAsReadClick}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>

              <IconButton
                size="large"
                color="primary"
                {...notificationsPageLinkProps}
              >
                <ListIcon />
              </IconButton>
            </Stack>

            <Box
              sx={{
                flex: '0 1 auto',
                overflow: 'auto',
              }}
              style={{
                height: '100%',
                minHeight: notificationsContainerHeight
                  ? `min(10rem, ${notificationsContainerHeight}px)`
                  : undefined,
              }}
            >
              <div ref={setNotificationsContainerElement}>
                <NotificationList
                  items={data}
                  onNotificationClick={onNotificationClick}
                />

                {total > MAX_DISPLAYED_NOTIFICATIONS && (
                  <Button
                    variant="text"
                    sx={{ mx: '0.5rem' }}
                    {...notificationsPageLinkProps}
                  >
                    {t('USER.NOTIFICATIONS_MORE', {
                      count: total - MAX_DISPLAYED_NOTIFICATIONS,
                    })}
                  </Button>
                )}
              </div>
            </Box>

            <MaskModal
              {...mask}
              onClose={onCloseMask}
              onAfterSave={onCloseMask}
            />
          </>
        );
      }}
    </NotificationActions>
  );
}
