import { useMemo } from 'react';
import { FilterValue, SortingRule } from 'react-table';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { assertNever } from '@work4all/utils';

import { FilterType } from '../types';
import {
  booleanNumberToQuery,
  booleanToQuery,
  dateToQuery,
  numbersToQuery,
  pickerToQuery,
  searchToQuery,
} from '../utils/filters/filterConversion';

interface FilterSortState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: any | undefined;
  sort: SortingRule<never> | undefined;
}

export type CustomFilterMapping = Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<FilterType, (id: string, values: any) => [string, any][]>
>;

type Filter = Record<string, unknown>;

export const usePrepareFilters = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortBy?: any,
  customFilterMapping?: CustomFilterMapping,
  entity?: Entities
): FilterSortState => {
  const state: FilterSortState = useMemo(() => {
    const sort = sortBy ? sortBy[0] : undefined;
    let filter = undefined;
    if (filters) {
      filter = filters.length
        ? filters.reduce((acc: Filter[], curr) => {
            const filter: FilterValue & { filterType: FilterType } = curr.value;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let query: any;
            const filterType: FilterType = filter.filterType;
            if (customFilterMapping && customFilterMapping[filterType]) {
              const mappings = customFilterMapping[filterType](
                curr.id,
                filter.value
              );
              mappings.forEach(([newId, newQuery]) => {
                acc.push({ [newId]: newQuery });
              });
              return acc;
            }

            switch (filterType) {
              case FilterType.ClosedStatus:
                if (filter.value) {
                  let filters: Filter;

                  if (entity === Entities.project) {
                    /**
                     * A workaround because projects have different properties
                     * to determine if it's closed or not
                     */
                    filters = {
                      $or: [
                        {
                          projectStatusId: { $eq: null },
                        },
                        {
                          'projectStatus.closedStatus': { $eq: 0 },
                        },
                      ],
                    };
                  } else {
                    filters = { [curr.id]: { $eq: 0 } };
                  }

                  acc.push(filters);
                  break;
                }
                break;
              case FilterType.Search:
                query = searchToQuery(filter.value);
                break;
              case FilterType.Date:
                query = dateToQuery(
                  filter.value.startDate,
                  filter.value.endDate
                );
                break;
              case FilterType.RequiredTime:
              case FilterType.Number:
                query = numbersToQuery(filter.value.from, filter.value.to);
                break;
              case FilterType.ObjectType:
              case FilterType.Picker:
              case FilterType.EmailKind:
              case FilterType.VacationKind:
              case FilterType.EInvoiceFormat:
              case FilterType.TaskStatus:
              case FilterType.SalesOpportunitiesGroupPicker:
              case FilterType.SalesOpportunitiesStatusPicker:
              case FilterType.PaymentStatus:
              case FilterType.InvoiceForm:
              case FilterType.ReAccountingStatus:
              case FilterType.RaAccountingStatus:
              case FilterType.PermitStatus:
              case FilterType.InvoiceKind:
              case FilterType.DueDateClassified:
              case FilterType.TicketStatus:
                query = pickerToQuery(
                  Array.isArray(filter.value) ? filter.value : [filter.value]
                );
                break;
              case FilterType.BooleanNumber:
                query = booleanNumberToQuery(
                  Array.isArray(filter.value) ? filter.value : [filter.value]
                );
                break;
              case FilterType.Boolean:
                query = booleanToQuery(
                  Array.isArray(filter.value) ? filter.value : [filter.value]
                );
                break;
              case FilterType.Check:
                if (filter.value) {
                  query = { $eq: 'true' };
                }
                break;
              case FilterType.TravelReceiptStatus: {
                let mappings = [];
                switch (filter.value) {
                  case 'parked':
                    mappings = [['travelExpenses.closedByuserid', { $eq: 0 }]];
                    break;
                  case 'closed':
                    mappings = [
                      ['travelExpenses.insertionREDate', { $eq: null }],
                      ['travelExpenses.closedByuserid', { $ne: 0 }],
                    ];
                    break;
                  case 'booked':
                    mappings = [
                      ['travelExpenses.insertionREDate', { $ne: null }],
                      ['travelExpenses.closedByuserid', { $ne: 0 }],
                    ];
                    break;
                }
                mappings.forEach(([newId, newQuery]) => {
                  acc.push({ [newId]: newQuery });
                });
                break;
              }
              case FilterType.ChronoContactPicker:
                throw new Error(
                  `${filterType} is not implemented as a generic filter.`
                );
              default:
                assertNever(filterType, `Unknown filter type ${filterType}`);
            }

            if (query) {
              acc.push({ [curr.id]: query });
            }

            return acc;
          }, [])
        : undefined;
    }
    return {
      sort,
      filter,
    };
  }, [sortBy, filters, customFilterMapping, entity]);

  return state;
};
