import styles from './BOMDialog.module.scss';

import { Box, Button, Checkbox, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { useDataProvider, useFormPlus } from '@work4all/data';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatNumberAsCurrency } from '@work4all/utils';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { SUPPLIER_ERP_ENTITIES } from '../../../../../../../../../../settings/settings';
import { PositionsBillOfMaterialsValues } from '../hooks/use-bom-dialog';

import { BomRowProps, BomVariantExample } from './BomVariantExample';
import {
  BOM_VARIANT_LOOK_UP,
  mapBomVariantToOption,
  useBomVariantOptions,
} from './BomVariantPicker';

export interface BOMDialogProps {
  article: Article | null;
  onAddArticle: (context: PositionsBillOfMaterialsValues) => void;
  onClose: () => void;
  entity: Entities;
}

export const BOMDialog = (props: BOMDialogProps) => {
  const { article, onAddArticle, onClose, entity } = props;
  const articleId = article?.id;
  const { t } = useTranslation();

  const { value } = useSetting(settings.lastBomVariant(entity));

  const { control, watch, reset } = useFormPlus<PositionsBillOfMaterialsValues>(
    {
      defaultValues: {
        variant: mapBomVariantToOption(BOM_VARIANT_LOOK_UP[0].id, t),
        noShow: false,
      },
    }
  );

  useEffect(() => {
    const currentBom = value;
    if (currentBom) {
      reset({
        variant: mapBomVariantToOption(currentBom.variant, t),
        noShow: false,
      });
    } else {
      reset({
        variant: mapBomVariantToOption(BOM_VARIANT_LOOK_UP[0].id, t),
        noShow: false,
      });
    }
  }, [reset, value, t]);

  const onSubmit = useCallback(() => {
    const values = watch();
    onAddArticle(values);
  }, [onAddArticle, watch]);

  const { options } = useBomVariantOptions(
    SUPPLIER_ERP_ENTITIES.includes(entity)
  );

  const request = useMemo<DataRequest>(() => {
    const data: Article = {
      id: null,
      name: null,
      longtext: null,
      netPrice: null,
      bomComponents: [
        {
          id: null,
          index: null,
          name: null,
          amount: null,
          article: {
            id: null,
            netPrice: null,
          },
        },
      ],
    };
    return {
      entity: Entities.article,
      filter: [{ id: { $eq: articleId } }],
      data,
    };
  }, [articleId]);
  const artilceWithBom = useDataProvider<Article>(request, !articleId);

  const { sampleHeader, sampleData } = useMemo(() => {
    const temp = artilceWithBom.data?.[0];
    const sampleHeader: BomRowProps = {
      number: '1',
      name: temp?.longtext,
      currency: formatNumberAsCurrency(temp?.netPrice) + '€',
    };

    const sampleData: BomRowProps[] = (temp?.bomComponents || [])
      // Designer instruction - just 2
      .slice(0, 2)
      .map((component, index) => ({
        number: `1.${index + 1}`,
        name: component.name,
        currency: formatNumberAsCurrency(component?.article?.netPrice) + '€',
      }));

    return { sampleHeader, sampleData };
  }, [artilceWithBom]);

  return (
    <Dialog
      open={Boolean(article)}
      title={t('MASK.BILL_OF_MATERIALS_TITLE')}
      onClose={onClose}
      classes={{
        dialog: {
          paper: styles.dialog,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        className={styles.container}
      >
        <Controller
          control={control}
          name="variant"
          render={({ field }) => {
            return (
              <Box display="flex" flexDirection="column">
                <Divider title={t('ERP.BOM.SECTION.SUPPLIER')} />
                <div className={styles.bomContainer}>
                  {options.slice(0, 4).map((bomVariant, index) => (
                    <BomVariantExample
                      key={bomVariant.id}
                      variant={bomVariant}
                      index={index + 1}
                      selected={bomVariant.id === field.value.id}
                      onClick={field.onChange}
                      sampleHeader={sampleHeader}
                      sampleData={sampleData}
                    />
                  ))}
                </div>
                {!SUPPLIER_ERP_ENTITIES.includes(entity) && (
                  <>
                    <Divider title={t('ERP.BOM.SECTION.CUSTOMER')} />
                    <div className={styles.bomContainer}>
                      {options.slice(4, 6).map((bomVariant, index) => (
                        <BomVariantExample
                          key={bomVariant.id}
                          variant={bomVariant}
                          index={index + 5}
                          selected={bomVariant.id === field.value.id}
                          onClick={field.onChange}
                          sampleHeader={sampleHeader}
                          sampleData={sampleData}
                        />
                      ))}
                    </div>
                  </>
                )}
              </Box>
            );
          }}
        />
      </Box>

      <Box display="flex" gap="0.5rem" className={styles.dialogFooter}>
        <Controller
          control={control}
          name="noShow"
          render={({ field }) => (
            <Checkbox
              className={styles.checkbox}
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Box>
          <Typography variant="body1">
            {t('MASK.BILL_OF_MATERIALS_NO_SHOW')}
          </Typography>
          <Typography variant="caption">
            {t('MASK.BILL_OF_MATERIALS_NO_SHOW_SETTINGS')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-around" paddingBottom="1rem">
        <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
        <Button onClick={onSubmit}>{t('COMMON.INSERT')}</Button>
      </Box>
    </Dialog>
  );
};
