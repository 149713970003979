import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileEntity } from '@work4all/models/lib/File';

export const MUTATION_GRAPHQL = gql`
  mutation InvoiceToXInvoice(
    $invoiceCode: Int!
    $eInvoiceType: ERechnungFormat!
  ) {
    invoiceToXInvoice(invoiceCode: $invoiceCode, eInvoiceType: $eInvoiceType) {
      date: datum
      fileInfos {
        downloadUrl
        previewMimeType
        previewUrl
        fileEntityFilename
        fileSize
      }
      id
      fileName: dateiname
    }
  }
`;

export type InvoiceToXInvoiceResponse = {
  invoiceToXInvoice: {
    fileInfos: FileEntity;
    dateiname: string;
    datum: string;
    id: number;
  };
};

export type InvoiceToXInvoiceVars = {
  invoiceCode: number;
  eInvoiceType: EInvoiceFormat;
};

export interface UseInvoiceToXInvoiceProps {
  data: ERPTypes;
  entityType: Entities;
  id: string | number;
}

export const useInvoiceToXInvoice = (props: UseInvoiceToXInvoiceProps) => {
  const { data, entityType, id } = props;
  const customer =
    entityType === Entities.invoice
      ? (data.businessPartnerContactCombined.businessPartner.data as Customer)
      : null;

  const variables = useMemo<InvoiceToXInvoiceVars>(() => {
    return {
      eInvoiceType: customer?.eInvoiceFormat,
      invoiceCode: typeof id === 'string' ? parseInt(id) : id,
    };
  }, [customer?.eInvoiceFormat, id]);
  const [convertInvoice] = useMutation<
    InvoiceToXInvoiceResponse,
    InvoiceToXInvoiceVars
  >(MUTATION_GRAPHQL, {
    variables,
  });

  return { convertInvoice, eInvoiceType: customer?.eInvoiceFormat };
};
