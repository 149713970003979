import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  OrganisationAreaEntity,
  OrganisationAreaSectionConfigs,
  OrganisationAreaSectionType,
} from './types';

export const ORGANISATION_AREA_SECTIONS = [
  'groups',
  'categories',
  'personal-filters',
  'general-filters',
] as const;

export const ORGANISATION_AREA_SECTIONS_CONFIGS: OrganisationAreaSectionConfigs[] =
  [
    {
      id: 'groups',
      nameTranslationKey: 'COMMON.GROUPS',
    },
    {
      id: 'categories',
      nameTranslationKey: 'COMMON.CATEGORIES',
    },
    {
      id: 'personal-filters',
      nameTranslationKey: 'COMMON.PERSONAL_FILTERS',
    },
    {
      id: 'general-filters',
      nameTranslationKey: 'COMMON.GENERAL_FILTERS',
    },
  ];

export const ORGANISATION_AREA_UNAVAILABLE_SECTIONS_BY_ENTITY: Partial<
  Record<OrganisationAreaEntity, OrganisationAreaSectionType[]>
> = {
  [Entities.article]: ['categories'],
};
