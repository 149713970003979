import styles from './TableRow.module.scss';

import clsx from 'clsx';
import produce from 'immer';
import { ReactNode } from 'react';
import { TableCommonProps } from 'react-table';

import { TableCell } from '../TableCell/TableCell';

import { DRAG_HANDLER_COLUMN_WIDTH } from './constants';

export type ITableHeaderRowProps = TableCommonProps & {
  disabled?: boolean;
  children?: ReactNode;
  noDrag: boolean;
  isSimpleMode: boolean;
  hasBorder?: {
    vertical: boolean;
    horizontal: boolean;
  };
};

export const TableHeaderRow = (props: ITableHeaderRowProps) => {
  const {
    className,
    style,
    disabled,
    noDrag,
    isSimpleMode,
    children,
    hasBorder = { vertical: true, horizontal: true },
    ...rest
  } = props;

  const modifiedStyle = produce(style, (draft) => {
    draft.width =
      (typeof draft.width === 'number' ? draft.width : parseInt(draft.width)) +
      DRAG_HANDLER_COLUMN_WIDTH;
  });

  return (
    <div
      className={clsx(styles['table-row'], styles['disable-hover'], {
        [styles['noBorderHorizontal']]: !hasBorder?.horizontal,
        [styles['noBorderVertical']]: !hasBorder?.vertical,
        [styles['borderRight']]: hasBorder?.vertical,
      })}
    >
      {disabled || noDrag ? null : (
        <TableCell
          role="cell"
          className={clsx(styles['drag-handler'], {
            [styles['drag-handler-simple']]: isSimpleMode,
          })}
        />
      )}
      <div className={className} style={modifiedStyle} {...rest}>
        {children}
      </div>
    </div>
  );
};
