import styles from './VacationPage.module.scss';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonBase, Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EntityPickerTab,
  EntityPickerTabBar,
} from '@work4all/components/lib/components/entity-picker/components';
import { useCanGoBack } from '@work4all/components/lib/hooks';

import { useNavigate, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { User } from '@work4all/models/lib/Classes/User.entity';

import {
  CalendarEventClickData,
  VacationOverview,
} from '../../components/vacation/VacationOverview';
import { usePageTitle } from '../../hooks';
import { settings, useSetting } from '../../settings';

import { VacationTimeRange } from './types';
import { useVacationStats } from './use-vacation-stats';
import { VacationDaysInfo } from './VacationDaysInfo';
import { VacationDaysInfoList } from './VacationDaysInfoList';
import { VacationMaskOverlay } from './VacationMaskOverlay';

export function VacationPage() {
  const { t } = useTranslation();
  const { value: vacationTimeRange, set: setVacationTimeRange } = useSetting(
    settings.vacationTimeRange()
  );

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    if (isViewportDownMd) {
      setVacationTimeRange('year');
    }
  }, [isViewportDownMd, setVacationTimeRange]);

  const [date, setDate] = useState(() => {
    const thisMonth = DateTime.now().startOf('month');

    return thisMonth.toJSDate();
  });

  const currentYear = useMemo(() => date.getFullYear(), [date]);

  const user = useUser();

  const canUserDisplayMultipleCalendars = checkUserRight(
    UserRights.UrlaubskalenderAufrufen,
    user.benutzerRechte
  );

  const { stats: vacationStats } = useVacationStats({
    userId: user.benutzerCode,
    year: currentYear,
  });

  const [overlay, setOverlay] = useState<{
    open: boolean;
    date?: Date | null;
    user?: User | null;
    init?: CalendarEventClickData;
  }>({ open: false });

  const availableRanges: VacationTimeRange[] = ['month', 'year'];

  return (
    <div className={styles.root}>
      <VacationMaskOverlay
        open={overlay.open}
        date={overlay.date}
        user={overlay.user}
        init={overlay.init}
        onClose={() => setOverlay({ open: false })}
      />

      <VacationPageHeader />

      <div className={styles.actionBar}>
        {!isViewportDownMd && (
          <Button
            size="large"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setOverlay({ open: true });
            }}
          >
            {t('VACATION.REQUEST_VACATION')}
          </Button>
        )}

        <div className={isViewportDownMd ? styles.rightMobile : styles.right}>
          {!isViewportDownMd && (
            <EntityPickerTabBar
              value={vacationTimeRange}
              onChange={(value) => setVacationTimeRange(value)}
            >
              {availableRanges.map((value, index) => {
                return (
                  <EntityPickerTab value={value} key={index}>
                    {t(`COMMON.${value.toUpperCase()}`)}
                  </EntityPickerTab>
                );
              })}
            </EntityPickerTabBar>
          )}

          <Divider orientation={isViewportDownMd ? 'horizontal' : 'vertical'} />

          <VacationDaysInfoList>
            <VacationDaysInfo
              label={t('VACATION.VACATION_DAYS.TOTAL', {
                year: vacationStats.year,
              })}
              days={vacationStats.base}
              info={
                vacationStats.carriedOver > 0
                  ? t('VACATION.VACATION_DAYS.FROM_LAST_YEAR', {
                      days: vacationStats.carriedOver,
                    })
                  : null
              }
            />
            <VacationDaysInfo
              label={t('VACATION.VACATION_DAYS.USED')}
              days={vacationStats.used}
            />
            <VacationDaysInfo
              label={t('VACATION.VACATION_DAYS.REMAINING')}
              days={vacationStats.remaining}
            />
          </VacationDaysInfoList>
        </div>
      </div>
      <Divider />

      <VacationOverview
        showSelfOnly={!canUserDisplayMultipleCalendars}
        onCellClicked={(e) => {
          setOverlay({
            open: true,
            date: e.date,
            user: e.user,
          });
        }}
        onEventClicked={(e) => {
          setOverlay({ open: true, init: e });
        }}
        onCreateClick={() => {
          setOverlay({ open: true });
        }}
        date={date}
        setDate={setDate}
      />
    </div>
  );
}

function VacationPageHeader() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const canGoBack = useCanGoBack();
  const closeUrl = '/more';

  const title = t(`VACATION.TITLE`);

  usePageTitle(title);

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <div className={styles.header}>
      <ButtonBase
        onClick={() => {
          if (canGoBack) {
            navigate(-1);
          } else {
            navigate(closeUrl);
          }
        }}
        className={styles.goBack}
      >
        {canGoBack ? <ArrowBackIcon /> : <CloseIcon />}
      </ButtonBase>

      <Typography
        variant={isViewportDownMd ? 'h4' : 'h3'}
        color="text.secondary"
      >
        {title}
      </Typography>
    </div>
  );
}
