import styles from './SignatureDialog.module.scss';

import { Button } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@work4all/components/lib/dialog';
import { ConfirmDialog } from '@work4all/components/lib/dialogs/ConfirmDialog';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item/CheckboxRadioItem';

import { useSecureBlobUrl } from '@work4all/data';

import { IAttachmentEntity } from '@work4all/models';
import { FileLink } from '@work4all/models/lib/Classes/FileLink.entity';

import { useSignatureCanvas } from './use-signature-canvas';

interface SignatureBaseDialogProps {
  onClose: () => void;
  open: boolean;
  signature?: IAttachmentEntity;
  defalultSignature?: FileLink;
  onConfirm: (file: File, saveAsOwn: boolean) => void;
}

interface SignatureDialogProps extends SignatureBaseDialogProps {
  onDelete: () => void;
}

export const SignatureDialog = (props: SignatureDialogProps) => {
  const { open, onClose, onConfirm, signature, onDelete, defalultSignature } =
    props;
  const { t } = useTranslation();
  const [useDefaultSignature, setUseDefaultSignature] = useState(false);

  const saveAsOwn = useRef(!defalultSignature);
  const { canvas, getFile, clear } = useSignatureCanvas(
    signature
      ? signature?.fileInfos?.previewUrl
      : useDefaultSignature
      ? defalultSignature?.fileInfos?.downloadUrl
      : null
  );

  const { blobUrl: defaultSigBlobUrl } = useSecureBlobUrl(
    defalultSignature?.fileInfos?.downloadUrl ?? null
  );
  const handleRemove = () => {
    clear();
    setUseDefaultSignature(false);
    saveAsOwn.current = false;
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      title={t('COMMON.SIGNATURE')}
      onClose={handleOnClose}
      classes={{ dialog: { paper: styles['dialog'] } }}
    >
      <DialogContent>
        {!signature && defalultSignature?.fileInfos?.downloadUrl && (
          <BaseActionButton
            className={styles.defaultSignatureButton}
            onClick={() => setUseDefaultSignature(true)}
            icon={
              <img
                src={defaultSigBlobUrl}
                alt="Default Signature"
                width="80px"
              />
            }
          >
            {t('COMMON.USE_SIGNATURE_SAVED')}
          </BaseActionButton>
        )}

        <div className={styles['signature']}>
          {!signature && (
            <div className={styles['remove-default']}>
              <Button variant="text" onClick={handleRemove}>
                {t('ATTACHMENTS.REMOVE')}
              </Button>
            </div>
          )}

          {canvas}

          {!signature && (
            <div className={styles['marker']}>
              <div className={styles['marker__indicator']}>
                <div className={styles['marker__indicator-sign']}>SIGN</div>
                <div className={styles['marker__indicator-arrow']} />
              </div>

              <div className={styles['marker__line']}></div>
            </div>
          )}
        </div>

        {!signature && (
          <div className={styles['options']}>
            <CheckboxRadioItem
              label={t('COMMON.SIGNATURE_SAVE')}
              defaultChecked={saveAsOwn.current}
              onChange={(e) => {
                saveAsOwn.current = e.target.checked;
              }}
            />
          </div>
        )}
      </DialogContent>

      <div></div>
      {signature ? (
        <DialogActions>
          <BaseActionButton
            title={t('ALERTS.BTN_DELETE')}
            component="body2"
            color="primary"
            onClick={onDelete}
          />
        </DialogActions>
      ) : (
        <DialogActions>
          <BaseActionButton
            title={t('ALERTS.BTN_ABORT')}
            component="body2"
            color="primary"
            onClick={handleOnClose}
          />
          <BaseActionButton
            title={t('ALERTS.CONFIRM')}
            component="body2"
            color="primary"
            onClick={async () => {
              const file = await getFile(useDefaultSignature);
              onConfirm(file, saveAsOwn.current);
            }}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

interface SignatureDialogConfirmDialogProps extends SignatureBaseDialogProps {
  onDelete: (id: string | number) => void;
}

export const SignatureDialogConfirmDialog = (
  props: SignatureDialogConfirmDialogProps
) => {
  const { t } = useTranslation();
  const [isDeleteDialog, setDeleteDialog] = useState(false);

  if (isDeleteDialog && props.signature)
    return (
      <ConfirmDialog
        open={isDeleteDialog}
        title={t('COMMON.SIGNATURE_DELETE')}
        description={t('COMMON.SIGNATURE_DELETE_MESSAGE')}
        onConfirm={() => {
          props.onDelete(props.signature.id);
          setDeleteDialog(false);
        }}
        onCancel={() => setDeleteDialog(false)}
      />
    );

  return <SignatureDialog {...props} onDelete={() => setDeleteDialog(true)} />;
};
