import { useState } from 'react';

import { DialogsProvider } from '@work4all/components';
import { CallModalContext } from '@work4all/components/lib/hooks/call-modal/CallModalContext';
import { CallModalProps } from '@work4all/components/lib/hooks/call-modal/CallModalProps';

import { CallModal } from '../components/CallModal';

interface CallModalProviderProps {
  children: React.ReactNode;
}

export const CallModalProvider = ({ children }: CallModalProviderProps) => {
  const [callModal, setCallModal] = useState<CallModalProps>();

  return (
    <CallModalContext.Provider value={{ callModal, setCallModal }}>
      <DialogsProvider>
        {callModal && <CallModal {...callModal} />}
        {children}
      </DialogsProvider>
    </CallModalContext.Provider>
  );
};
