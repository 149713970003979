import styles from './GeneralData.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import { ProjectKindPicker } from '@work4all/components/lib/components/entity-picker/project-kind-picker/ProjectKindPicker';
import { ProjectStatusPicker } from '@work4all/components/lib/components/entity-picker/project-status-picker/ProjectStatusPicker';

import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  LabeledInput,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ProjectMaskFormValue } from '../../../../../types';

export const GeneralData: React.FC = () => {
  const { t } = useTranslation();
  const { control, register } = useFormContextPlus<ProjectMaskFormValue>();
  return (
    <Collapse
      defaultOpen
      title={t('MASK.GENERAL')}
      classes={{ wrapperInner: styles.wrapperInner }}
      className={styles.root}
    >
      <ControlWrapper paddingBottom={false}>
        <LabeledInput label={t('INPUTS.DESCRIPTION')} {...register('name')} />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput label={t('INPUTS.NUMBER')} {...register('number')} />
        <ControllerPlus
          name="projectKind"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                picker={<ProjectKindPicker multiple={false} {...field} />}
              >
                <PickerTargetButton
                  onClear={null}
                  value={field.value?.name || ''}
                  label={t('INPUTS.TYPE')}
                />
              </EntityPickerPopover>
            );
          }}
        />
        <ControllerPlus
          name="projectStatus"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                picker={
                  <ProjectStatusPicker
                    clearable={false}
                    multiple={false}
                    {...field}
                  />
                }
              >
                <PickerTargetButton
                  value={field.value?.name || t('COMMON.PROJECT_EMPTY_STATUS')}
                  label={t('INPUTS.STATUS')}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </ControlWrapper>

      <ControlWrapper className={styles.noteWrapper}>
        <ControllerPlus
          name="note"
          control={control}
          render={({ field }) => (
            <LabeledInput {...field} label={t('COMMON.NOTE')} multiline />
          )}
        />
      </ControlWrapper>
    </Collapse>
  );
};
