import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IProjectProcessKindPickerProps,
  ProjectProcessKindPicker,
  useProjectProcessKind,
} from '@work4all/components/lib/components/entity-picker/project-process-kind-picker/ProjectProcessKindPicker';

import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<
    IProjectProcessKindPickerProps<false>,
    'onChange' | 'multiple' | 'value'
  > {
  error?: string;
  label?: string;
  onChange: (value: ProjectProcessKind) => void;
  value: ProjectProcessKind;
}

export const ProjectProcessKindPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { error, label: _label, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();
  const kinds = useProjectProcessKind();

  return (
    <EntityPickerPopover
      picker={
        <ProjectProcessKindPicker
          multiple={false}
          value={{ id: value, name: '' }}
          onChange={(value) => {
            onChange(value.id);
          }}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        label={uppercaseFirstLetter(t('COMMON.KIND'))}
        error={error}
        value={kinds.find((x) => x.id === value)?.name}
      />
    </EntityPickerPopover>
  );
});
