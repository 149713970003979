import styles from './organisation-area-settings-popover-content.module.scss';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useOrganisationAreaSectionVisibility } from '../hooks';
import { OrganisationAreaSettingsPopoverContentProps } from '../types';
import { availableSectionsConfigs } from '../utils';

export const OrganisationAreaSettingsPopoverContent = ({
  entity,
}: OrganisationAreaSettingsPopoverContentProps) => {
  const { t } = useTranslation();
  const sections = availableSectionsConfigs(entity);

  const { isVisible, toggleVisibility } = useOrganisationAreaSectionVisibility({
    entity,
  });

  const renderOptions = useCallback(() => {
    return sections.map((checkbox) => {
      return (
        <CheckboxRadioItem
          key={checkbox.id}
          label={t(checkbox.nameTranslationKey)}
          control="checkbox"
          onChange={() => {
            toggleVisibility(checkbox.id);
          }}
          checked={isVisible(checkbox.id)}
          horizontalPadding={false}
        />
      );
    });
  }, [isVisible, t, toggleVisibility, sections]);

  return <div className={styles.root}>{renderOptions()}</div>;
};
