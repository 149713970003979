import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

export const Clone: React.FC<BaseActionProps> = (props) => {
  return (
    <IconButtonWithTooltip
      tooltip="Clone"
      icon={<ContentCopyIcon style={{ width: '1.5rem', height: '1.5rem' }} />}
      {...props}
    />
  );
};
