import { noop } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ConfirmationDialog } from '../../../confirmation-dialog/ConfirmationDialog';
import {
  Direction,
  GetLocalState,
  GoBack,
  HistoryStack,
  IStackItem,
  ObjectionHandler,
  SetLocalState,
} from '../../types';
import { IHistoryStackBag, StackContext } from '../stack-context/StackContext';

export interface IStackProviderProps {
  children?: React.ReactNode;
  initialView: IStackItem;
  onClose?: () => void;
}

const isDataCallable = (data: unknown): data is (data: unknown) => void => {
  return typeof data === 'function';
};

const VIEW_PREFIX = 'view_';
const defaultLocalState = {};

const useConfirmation = () => {
  const [resolveConfirm, setResolveConfirm] = useState(null);
  const messageRef = useRef<string>();

  const handleClose = useCallback(() => {
    setResolveConfirm(null);
  }, []);

  const confirm = useCallback((reason?: string): Promise<boolean> => {
    return new Promise((res) => {
      messageRef.current = reason;
      setResolveConfirm(() => res);
    });
  }, []);

  const agree = useCallback(() => {
    if (resolveConfirm) {
      resolveConfirm(true);
    }
    handleClose();
  }, [handleClose, resolveConfirm]);

  const cancel = useCallback(() => {
    if (resolveConfirm) {
      resolveConfirm(false);
    }
    handleClose();
  }, [handleClose, resolveConfirm]);

  return {
    open: Boolean(resolveConfirm),
    agree,
    cancel,
    confirm,
    handleClose,
    reason: messageRef.current,
  };
};

/**
 * TODO: do not remove item from stack when going back. Introduce "currentIndex"
 * Use it to get "current" item. PROBLEM: item is removed from stack immediately
 * transition has started. In this case user won't see breadcrumbs for component
 * that he is leaving while transition is running.
 *
 * initial view should be memoized if you do not want to reset stack view on every render
 * this allows you to control when stack should be reset */
export const StackProvider: React.FC<IStackProviderProps> = (props) => {
  const [stack, setStack] = useState<HistoryStack>(
    props.initialView ? [props.initialView] : []
  );
  const onClose = props.onClose || noop;
  const [currentStackIndex, setCurrentStackIndex] = useState(
    stack.length > 0 ? stack.length - 1 : 0
  );
  const [direction, setDirection] = useState<Direction>('push');
  const [localStateObj, setLocalStateObj] = useState(defaultLocalState);
  const activeObjectionRef = useRef<ObjectionHandler>();
  const current = stack[currentStackIndex];
  const { confirm, ...restConfirmData } = useConfirmation();

  const getViewStateKey = useCallback(
    (i?: number) => {
      return `${VIEW_PREFIX}${i !== undefined ? i : currentStackIndex}`;
    },
    [currentStackIndex]
  );

  const go = useCallback(
    (item: IStackItem) => {
      setDirection('push');

      /**
       * History is diverged at this point.
       * Destroy all stack items with their local state that are ahead of currentStackIndex.
       */
      const invalidItemStartIdx = currentStackIndex + 1;
      setLocalStateObj((old) => {
        const newState = { ...old };
        for (let i = invalidItemStartIdx; i < stack.length; i += 1) {
          const stateKey = getViewStateKey(i);
          delete newState[stateKey];
        }

        return newState;
      });

      setStack((old) => {
        const cpy = old.slice(0, currentStackIndex + 1);
        cpy.push(item);
        return cpy;
      });
      setCurrentStackIndex((idx) => {
        return idx + 1;
      });
    },
    [currentStackIndex, getViewStateKey, stack.length]
  );

  const goBack: GoBack = useCallback(
    (index?: number) => {
      index = typeof index === 'number' ? index : currentStackIndex - 1;
      setDirection('pop');

      /**
       * Do not destroy stack item when user leaves.
       * This history can be used by third-party component (like CSSTransition)
       * The only data that should be destroyed is "close objections"
       */

      setCurrentStackIndex(() => {
        const n = index;
        return n < 0 ? 0 : n;
      });
    },
    [currentStackIndex]
  );

  const setLocalState: SetLocalState = useCallback((stateId, data) => {
    setLocalStateObj((old) => {
      const d = isDataCallable(data) ? data(old[stateId]) : data;
      return { ...old, [stateId]: d };
    });
  }, []);

  const getLocalState: GetLocalState = useCallback(
    (stateId) => {
      return localStateObj[stateId];
    },
    [localStateObj]
  );

  const setCurrentViewState: SetLocalState = useCallback(
    (stateId, data) => {
      setLocalStateObj((old) => {
        const stateKey = getViewStateKey();
        const viewState = old ? old[stateKey] : {};
        const viewStateChunk = isDataCallable(data)
          ? data(viewState[stateId])
          : data;
        return {
          ...old,
          [stateKey]: { ...viewState, [stateId]: viewStateChunk },
        };
      });
    },
    [getViewStateKey]
  );

  const getCurrentViewState: GetLocalState = useCallback(
    (stateId, viewIndex?: number) => {
      const stateKey = getViewStateKey(viewIndex);
      const viewState = localStateObj[stateKey];
      return viewState ? viewState[stateId] : undefined;
    },
    [getViewStateKey, localStateObj]
  );

  const {
    closeWithObjectionsRaised,
    goBackWithObjectionRaised,
    goWithObjectionRaised,
  } = useMemo(() => {
    const withRaisedObjection = (action: (...args) => void) => {
      return async (...args) => {
        if (!activeObjectionRef.current) {
          action(...args);
          return;
        }
        let can = activeObjectionRef.current();
        if (can instanceof Promise) {
          can = await can;
        }
        if (can) {
          setObjectionListener(null);
          action(...args);
          return;
        }
      };
    };

    const closeWithObjectionsRaised = withRaisedObjection(onClose);
    const goBackWithObjectionRaised: GoBack = withRaisedObjection(goBack);
    const goWithObjectionRaised = withRaisedObjection(go);

    return {
      closeWithObjectionsRaised,
      goBackWithObjectionRaised,
      goWithObjectionRaised,
    };
  }, [onClose, goBack, go]);

  // Also prevent a user from closing the tab without confirmation if an
  // objection listener is registered.
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (!activeObjectionRef.current) {
        return;
      }

      // We want to prevent a user from closing the tab without confirmation.
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm();

      if (!confirmed) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, []);

  const updateCurrent: IHistoryStackBag['updateCurrent'] = useCallback(
    (item) => {
      setStack((oldStack) => {
        const idx = currentStackIndex;
        const curr = oldStack[idx];
        let newItem: IStackItem;
        if (typeof item === 'function') {
          newItem = item(curr);
        } else {
          newItem = item;
        }

        const newStack = [...oldStack];
        newStack[idx] = newItem;
        return newStack;
      });
    },
    [currentStackIndex]
  );

  const setObjectionListener = useCallback((listener: ObjectionHandler) => {
    activeObjectionRef.current = listener;
  }, []);

  useEffect(() => {
    setStack(props.initialView ? [props.initialView] : []);
    setCurrentStackIndex(0);
    setLocalStateObj(defaultLocalState);
  }, [props.initialView]);

  return (
    <StackContext.Provider
      value={{
        stack,
        current,
        setStack,
        direction,
        currentStackIndex,
        go: goWithObjectionRaised,
        goBack: goBackWithObjectionRaised,
        setLocalState,
        getLocalState,
        close: closeWithObjectionsRaised,
        // Close without rejection
        onClose,
        setObjectionListener,
        confirm,
        setCurrentViewState,
        getCurrentViewState,
        updateCurrent,
      }}
    >
      <ConfirmationDialog {...restConfirmData} />
      {props.children}
    </StackContext.Provider>
  );
};
