import { useContext } from 'react';

import { OrganisationAreaContext } from '../contexts';

export const useOrganisationAreaContext = () => {
  const context = useContext(OrganisationAreaContext);

  if (!context) {
    throw new Error(
      '`useOrganisationAreaContext` should be used inside `OrganisationAreaProvider`.'
    );
  }

  return context;
};
