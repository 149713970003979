import { MbscCalendarEvent } from '@mobiscroll/react';
import { DateTime } from 'luxon';

import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';
import { VacationDayKind } from '@work4all/models/lib/Enums/VacationDayKind.enum';

/**
 *  If in that day both halfs are taken we need to say it's full day already
 */
export const getWholeDayInformation = (
  events: MbscCalendarEvent[],
  absence: Vacation | Sickness
) => {
  const anotherEventAtThatDay = events.find(
    (x) =>
      x.id !== absence.id &&
      DateTime.fromJSDate(x.date as Date).toFormat('yyyy-MM-dd') ===
        absence.date
  );

  const vacation = absence as Vacation;
  if (!vacation)
    return {
      firstHalf: false,
      secondHalf: false,
    };

  return {
    firstHalf:
      vacation.vacationDayPosition === VacationDayKind.HALBTAGS_VORMITTAG &&
      ![VacationDayKind.HALBTAGS_NACHMITTAG, VacationDayKind.GANZTAGS].includes(
        anotherEventAtThatDay?.vacationDayPosition
      ),
    secondHalf:
      vacation.vacationDayPosition === VacationDayKind.HALBTAGS_NACHMITTAG &&
      ![VacationDayKind.HALBTAGS_VORMITTAG, VacationDayKind.GANZTAGS].includes(
        anotherEventAtThatDay?.vacationDayPosition
      ),
  };
};
