import { useMemo } from 'react';

export const useChronoFileRequestFields = () => {
  return useMemo(() => {
    const fileItem = {};
    const currencyTypes = ['contract', 'calculation', 'offer'];
    const currencyQuery = {
      value: null,
      currency: {
        name: null,
      },
    };

    const projectTypes = [
      'contract',
      'calculation',
      'offer',
      'order',
      'eMail',
      'task',
      'ticket',
      'document',
      'note',
      'appointment',
      'checkList',
      'letter',
      'visitationReport',
      'invoice',
      'inboundDeliveryNote',
      'deliveryNote',
      'salesOpportunities',
    ];
    const projectQuery = {
      project: {
        name: null,
      },
    };

    const userTypes = [
      'task',
      'callMemo',
      'note',
      'appointment',
      'checkList',
      'letter',
      'eMail',
      'document',
      'document',
      'visitationReport',
      'calculation',
      'offer',
      'contract',
      'invoice',
      'deliveryNote',
      'salesOpportunities',
    ];
    const userQuery = {
      user: {
        id: null,
        displayName: null,
      },
    };

    const noteTypes = [
      'letter',
      'document',
      'document',
      'visitationReport',
      'calculation',
      'offer',
      'contract',
      'invoice',
      'deliveryNote',
    ];
    const noteQuery = {
      note: null,
    };

    const titleTypes = [
      'task',
      'callMemo',
      'note',
      'appointment',
      'ticket',
      'checkList',
    ];

    const titleQuery = {
      title: null,
    };

    const contactTypes = [
      'ticket',
      'task',
      'callMemo',
      'note',
      'appointment',
      'checkList',
      'letter',
      'eMail',
      'document',
      'visitationReport',
      'salesOpportunities',
      //   'order',
      //   'inboundDeliveryNote',
      //   'calculation',
      //   'offer',
      //   'contract',
      //   'invoice',
      //   'deliveryNote',
    ];

    const contactQuery = {
      contact: {
        id: null,
        displayName: null,
      },
    };

    const addFileItemFields = (types: string[], queryFields: object) => {
      types.forEach((x) => {
        fileItem[x] = { ...fileItem[x], ...queryFields };
      });
    };
    addFileItemFields(contactTypes, contactQuery);
    addFileItemFields(currencyTypes, currencyQuery);
    addFileItemFields(projectTypes, projectQuery);
    addFileItemFields(userTypes, userQuery);
    addFileItemFields(currencyTypes, currencyQuery);
    addFileItemFields(titleTypes, titleQuery);
    addFileItemFields(noteTypes, noteQuery);

    fileItem['ticket'].editor1 = {
      displayName: null,
    };

    fileItem['project'] = {
      ...fileItem['project'],
      name: null,
      internalProjectManager: {
        displayName: null,
      },
    };

    fileItem['eMail'] = {
      ...fileItem['eMail'],
      subject: null,
      kind: null,
    };

    fileItem['salesOpportunities'] = {
      ...fileItem['salesOpportunities'],
      name: null,
    };

    return {
      fileItem,
    };
  }, []);
};
