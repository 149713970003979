import styles from './AvatarMenu.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { Menu, MenuItem } from '@mui/material';
import { t } from 'i18next';

import { useAvatar } from '../../../../../../hooks/useAvatar';

export interface AvatarMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  setIsDialogOpen: (value: boolean) => void;
}

export const AvatarMenu: React.FC<AvatarMenuProps> = ({
  open,
  anchorEl,
  onClose,
  fileInputRef,
  setIsDialogOpen,
}) => {
  const { avatarUrl } = useAvatar();

  const handleClick = () => {
    fileInputRef.current.click();
    onClose();
  };

  const handleRemove = () => {
    setIsDialogOpen(true);
    onClose();
  };

  return (
    <Menu
      className={styles.menu}
      id="Avatar-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem className={styles.menuItem} onClick={handleClick}>
        <UploadIcon className={styles.icon} />
        {t('PROFILE.CHANGE_PROFILE_PICTURE')}
      </MenuItem>
      {avatarUrl && (
        <MenuItem className={styles.menuItem} onClick={handleRemove}>
          <DeleteIcon className={styles.icon} />
          {t('PROFILE.DELETE_PROFILE_PICTURE')}
        </MenuItem>
      )}
    </Menu>
  );
};
