import styles from './styles.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import {
  Link,
  useDataProvider,
  useRefetchOnEntityChanges,
} from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { AddressConnection } from '@work4all/models/lib/Classes/AddressConnection.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { RelationsPopover } from '../../../../../components/relations-popover';
import { RelationsPopoverProps } from '../../../../../components/relations-popover/interfaces';
import { BusinessPartners } from '../../../../mask-overlays/mask-overlay/views/businessPartners/BusinessPartnerOverlayController';

import { InlineDataList } from './InlineDataList';

type IRelationsCardProps = {
  entity: Entities;
  id: number;
  entityData: BusinessPartners<EMode.entity>;
  disabled?: boolean;
};

const ADDRESS_CONNECTION_FIELDS: AddressConnection<EMode.query> = {
  id: null,
  connection: {
    id: null,
    name: null,
  },
  contactB: {
    id: null,
    displayName: null,
  },
  businessPartnerB: {
    id: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
      },
    },
  },
};

export const RelationsCard: FC<IRelationsCardProps> = ({
  id,
  entityData,
  disabled,
}) => {
  const { t } = useTranslation();

  const requestData: DataRequest = useMemo(() => {
    return {
      entity: Entities.addressConnection,
      data: ADDRESS_CONNECTION_FIELDS,
      filter: [{ sdObjMemberIdA: { $eq: id } }],
    };
  }, [id]);

  const loadedAddressconnection = useDataProvider<AddressConnection>(
    requestData,
    !id
  );
  const relations = loadedAddressconnection.data;

  const anchorRef = useRef(null);

  const [relationsPopover, setRelationsPopover] = useState<
    Pick<RelationsPopoverProps, 'open' | 'isCreateMode' | 'relationId'>
  >({ open: false, isCreateMode: false });

  useRefetchOnEntityChanges({
    entity: Entities.addressConnection,
    refetch: loadedAddressconnection.refetch,
    skipIfCurrentUser: true,
  });

  useEntityEvents((event) => {
    if (event.entity === Entities.addressConnection) {
      loadedAddressconnection.refetch();
    }
  });

  return (
    <div>
      <RelationsPopover
        onClose={() => {
          setRelationsPopover({ open: false, isCreateMode: false });
        }}
        businessPartnerId={id}
        businessPartnerType={typeNameToSdObjType(entityData?.__typename)}
        anchorEl={anchorRef.current}
        {...relationsPopover}
      />

      <div ref={anchorRef} className={styles.categoryHeadWrapper}>
        <div style={{ width: '100%' }}>
          <Divider title={t('MASK.RELATIONS')} size="body" />
        </div>

        <IconButton
          size="large"
          color="primary"
          onClick={() => {
            setRelationsPopover({ open: true, isCreateMode: true });
          }}
          disabled={disabled}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div className={styles.categoryContentWrapper}>
        {!relations || relations.length === 0 ? null : (
          <InlineDataList
            onLabelClick={(relation) => {
              setRelationsPopover({
                open: true,
                isCreateMode: false,
                relationId: relation.id,
              });
            }}
            items={[...relations]
              .sort((a, b) =>
                a.businessPartnerB?.data?.name.localeCompare(
                  b.businessPartnerB?.data?.name
                )
              )
              .map((item) => {
                const type = item.businessPartnerB?.data?.__typename;
                const entity = type === 'Lieferant' ? 'suppliers' : 'customers';
                return {
                  id: item.id,
                  label: item.connection?.name,
                  content: (
                    <Typography
                      component={type ? Link : undefined}
                      to={
                        type
                          ? `/${entity}/${item.businessPartnerB?.data?.id}`
                          : undefined
                      }
                      variant="body2"
                      className={clsx(styles['overflow-line'], styles['link'])}
                    >
                      {`${item.businessPartnerB?.data?.name}${
                        item.contactB?.displayName
                          ? `, ${item.contactB?.displayName}`
                          : ''
                      }`}
                    </Typography>
                  ),
                };
              })}
          />
        )}
      </div>
    </div>
  );
};
