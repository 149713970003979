import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';

import { settings, useSetting } from '../../../../settings';
import { ControlViewLeftResizableAreaProps } from '../types';

export const ControlViewLeftResizableArea = ({
  children,
  resizable,
}: ControlViewLeftResizableAreaProps) => {
  const settingsSize = useSetting(settings.leftAreaSize());

  return (
    <ResizableArea
      minWidth={250}
      maxWidth={500}
      size={{ width: settingsSize.value.width }}
      onResize={(size) => settingsSize.set(size)}
      handles={resizable ? 'right' : undefined}
    >
      {children}
    </ResizableArea>
  );
};
