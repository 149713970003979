import { Stack, Typography } from '@mui/material';
import React, { FC, MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineData } from './InlineData';

type IPaymentCardProps = {
  paymentDeadline: number;
  skonto: number;
  skontoDurationDays: number;
  skonto2: number;
  skonto2DurationDays: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const PaymentCard: FC<IPaymentCardProps> = ({
  paymentDeadline,
  skonto,
  skontoDurationDays,
  skonto2,
  skonto2DurationDays,
  onClick,
}) => {
  const { t } = useTranslation();

  const payment = useMemo(() => {
    const result = [
      <Typography key="deadline" variant="body2">
        {paymentDeadline ? `${paymentDeadline} ${t('COMMON.DAYS_SHORT')}` : '-'}
      </Typography>,
    ];

    if (skonto && skontoDurationDays) {
      result.push(
        <Typography key="skonto" variant="body2">
          {`${skonto} % in ${skontoDurationDays} ${t('COMMON.DAYS_SHORT')}`}
        </Typography>
      );
    }

    if (skonto2 && skonto2DurationDays) {
      result.push(
        <Typography key="skonto2" variant="body2">
          {`${skonto2} % in ${skonto2DurationDays} ${t('COMMON.DAYS_SHORT')}`}
        </Typography>
      );
    }

    return result;
  }, [
    paymentDeadline,
    skonto,
    skonto2,
    skonto2DurationDays,
    skontoDurationDays,
    t,
  ]);

  return (
    <InlineData
      onClick={onClick}
      label={t('FILE_CONTACTS.PAYMENTTERMS')}
      content={<Stack>{payment}</Stack>}
    />
  );
};
