import { ControlViewLeftProps } from '../types';

import { ControlViewLeftCollapseController } from './control-view-left-collapse-controller';
import { ControlViewLeftContent } from './control-view-left-content';
import { ControlViewLeftResizableArea } from './control-view-left-resizable-area';

export const ControlViewLeft = ({
  title,
  entity,
  children,
  custom = false,
  resizable = false,
  collapsible = false,
}: ControlViewLeftProps) => {
  return (
    <ControlViewLeftCollapseController
      title={title}
      entity={entity}
      collapsible={collapsible}
    >
      <ControlViewLeftResizableArea resizable={resizable}>
        <ControlViewLeftContent
          title={title}
          entity={entity}
          custom={custom}
          collapsible={collapsible}
        >
          {children}
        </ControlViewLeftContent>
      </ControlViewLeftResizableArea>
    </ControlViewLeftCollapseController>
  );
};
