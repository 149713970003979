import { useCallback, useMemo } from 'react';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDeleteEntity } from '@work4all/data';

import { ObjectTypesUnion } from '@work4all/models';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { MaskConfig } from '../types';

import { useConfirmDeleteEntities } from './use-confirm-delete-entities';

export function useStandardDeleteEntityHandler(
  config: MaskConfig,
  onDelete?: () => void
) {
  const historyStack = useHistoryStack();
  const [deleteEntity] = useDeleteEntity();
  const confirmDeleteEntities = useConfirmDeleteEntities();

  const entityType = useMemo(() => {
    let fallBackType: ObjectTypesUnion;
    if (config.entity === Entities.contact) {
      if (!config.template) {
        throw new Error(
          "can't create delete handler for contacts without knowledge about the parent entity (customer, supplier)"
        );
      }
      if (config.template.entity === Entities.customer) {
        fallBackType = 'KUNDENANSPRECHPARTNER';
      }
      if (config.template.entity === Entities.supplier) {
        fallBackType = 'LIEFERANTENANSPRECHPARTNER';
      }
    }
    return { type: config.entity, fallBackType };
  }, [config.entity, config.template]);

  const handleClick = useCallback(() => {
    confirmDeleteEntities().then((confirmed) => {
      if (confirmed) {
        historyStack.setObjectionListener(null);
        deleteEntity({
          type: entityType.type,
          fallbackType: entityType.fallBackType,
          ids: [config.id],
        }).then(() => {
          sendAmplitudeData(EventType.DeleteElement, {
            name: config.entity,
            entryPoint: config.amplitudeEntryPoint || config.entity + 'Mask',
          });
          if (onDelete) {
            onDelete();
            return;
          }
          if (historyStack.currentStackIndex === 0) historyStack.close();
          historyStack.goBack();
        });
      }
    });
  }, [
    confirmDeleteEntities,
    historyStack,
    deleteEntity,
    entityType.type,
    entityType.fallBackType,
    config.id,
    config.entity,
    config.amplitudeEntryPoint,
    onDelete,
  ]);

  return handleClick;
}
