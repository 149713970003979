import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useCollapsedAreas } from '../../../hooks';
import { useOrganisationAreaContext } from '../hooks';

export const OrganisationAreaCloseAction = () => {
  const { entity } = useOrganisationAreaContext();
  const { toggleCollapse } = useCollapsedAreas({
    entity,
    area: 'left',
  });

  return (
    <IconButton onClick={toggleCollapse}>
      <ChevronLeft />
    </IconButton>
  );
};
