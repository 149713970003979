import styles from './ProjectDirectoryPopover.module.scss';

import { Button, RadioGroup, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useDataProvider, useFormPlus } from '@work4all/data';

import { FileServiceProviderConfig } from '@work4all/models/lib/Classes/FileServiceProviderConfig.entity';
import { InputProjektRelation } from '@work4all/models/lib/Classes/InputProjektRelation.entity';
import { ProjectGroup } from '@work4all/models/lib/Classes/ProjectGroup.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { CreateProjectDirectoryType } from '@work4all/models/lib/Enums/CreateProjectDirectoryType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ControllerPlus } from '../../containers/mask-overlays/form-plus/controller-plus';
import { ControlWrapper } from '../../containers/mask-overlays/mask-overlay/components';

type ProjectDirectoryValues = {
  directoryType: CreateProjectDirectoryType;
  directoryName: string | null;
  fspId: string | null;
};

interface ProjectDirectoryPopoverProps {
  open: boolean;
  onSelect: (directoryRelations: InputProjektRelation) => void;
  onClose: () => void;
  projectNumber?: string;
  projectName: string;
  predefinedGroupDirectory?: string;
}

export const FILE_SERVICE_PROVIDER_DATA: FileServiceProviderConfig = {
  id: null,
  name: null,
  directoryNameConvertPath: null,
  directoryNameReplacePath: null,
  isWorkDirectory: null,
  providerType: null,
};

export const PROJECT_GROUPS_DATA: ProjectGroup = {
  id: null,
  name: null,
  projectDirectoryPath: null,
};

export const ProjectDirectoryPopover = (
  props: ProjectDirectoryPopoverProps
) => {
  if (!props.open) {
    return null;
  }

  return <ProjectDirectoryPopoverContent {...props} />;
};

export const ProjectDirectoryPopoverContent = ({
  open,
  onSelect,
  onClose,
  projectNumber,
  projectName,
  predefinedGroupDirectory,
}: ProjectDirectoryPopoverProps) => {
  const { t } = useTranslation();

  const form = useFormPlus<ProjectDirectoryValues>({
    defaultValues: {
      directoryType: CreateProjectDirectoryType.NEW_PROJEKT_DIR,
      directoryName: `${projectNumber ?? ''} ${projectName}`.trimStart(),
    },
  });
  const { control, register, handleSubmit, watch } = form;

  const directoryType = watch('directoryType');

  const fileServiceProviderRequest = useMemo<DataRequest>(
    () => ({
      data: FILE_SERVICE_PROVIDER_DATA,
      entity: Entities.fileServiceProviderConfig,
      completeDataResponse: true,
      skip: false,
      operationName: 'GetFileServiceProvider',
    }),
    []
  );
  const { data: fileServiceProviderData } = useDataProvider(
    fileServiceProviderRequest
  );

  const workDirectory = fileServiceProviderData?.find((data) =>
    Boolean(data?.isWorkDirectory)
  );

  const predefinedGroupDirectoryFsp = fileServiceProviderData
    .find((data) =>
      predefinedGroupDirectory?.includes(data?.directoryNameConvertPath?.at(-1))
    )
    ?.directoryNameConvertPath?.at(-1);
  const predefinedPath = predefinedGroupDirectory
    ?.substring(predefinedGroupDirectory?.indexOf(predefinedGroupDirectoryFsp))
    ?.replace(/\\\\/g, '\\');

  const onSubmit = useCallback(
    async (data: ProjectDirectoryValues) => {
      const relations: InputProjektRelation = {
        setProjectDirectory: {
          createProjectDirectoryType: data.directoryType,
        },
      };

      onSelect(relations);
    },
    [onSelect]
  );

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        onClose={onClose}
        closeButton={false}
        title={t('PROJECT_DIRECTORY.DIALOG_TITLE')}
        classes={{ dialog: { paper: styles.dialog } }}
      >
        <DialogContent>
          <ControllerPlus
            control={control}
            name="directoryType"
            render={({ field }) => (
              <ControlWrapper as={RadioGroup} grid paddingTop paddingBottom>
                <Stack gap="2rem">
                  <CheckboxRadioItem
                    horizontalPadding={false}
                    label={t('PROJECT_DIRECTORY.DEFAULT.LABEL')}
                    caption={
                      <Stack gap="0.5rem" mt="0.5rem">
                        <Typography
                          className={clsx(styles.caption, styles.captionLink)}
                          variant="caption"
                        >
                          {workDirectory?.directoryNameConvertPath?.at(-1)}\
                        </Typography>
                        <Typography
                          className={styles.caption}
                          variant="caption"
                        >
                          {t('PROJECT_DIRECTORY.DEFAULT.CAPTION')}
                        </Typography>
                      </Stack>
                    }
                    value={CreateProjectDirectoryType.DEFAULT}
                    control="radio"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    checked={field.value === CreateProjectDirectoryType.DEFAULT}
                  />
                  <CheckboxRadioItem
                    horizontalPadding={false}
                    label={t('PROJECT_DIRECTORY.NEW.LABEL')}
                    caption={
                      <Stack gap="0.5rem" mt="0.5rem">
                        <Typography
                          className={clsx(styles.caption, styles.captionLink)}
                          variant="caption"
                        >
                          {predefinedPath}
                        </Typography>
                        <Typography
                          className={styles.caption}
                          variant="caption"
                        >
                          {t('PROJECT_DIRECTORY.NEW.CAPTION')}
                        </Typography>
                        {directoryType ===
                          CreateProjectDirectoryType.NEW_PROJEKT_DIR && (
                          <LabeledInput
                            disabled
                            label={t('FIELDS.folder')}
                            required
                            startAdornment={
                              <span className={styles.startAdornment}>
                                {predefinedPath}
                              </span>
                            }
                            className={styles.inputValue}
                            autoComplete="off"
                            autoFocus={true}
                            {...register('directoryName')}
                          />
                        )}
                      </Stack>
                    }
                    value={CreateProjectDirectoryType.NEW_PROJEKT_DIR}
                    control="radio"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    checked={
                      field.value === CreateProjectDirectoryType.NEW_PROJEKT_DIR
                    }
                  />
                </Stack>
              </ControlWrapper>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button size="large" color="primary" type="button" onClick={onClose}>
            {t('COMMON.ABORT')}
          </Button>
          <Button
            size="large"
            color="primary"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            {t('COMMON.CREATE')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
