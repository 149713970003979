import { BzObjType } from '../Enums/BzObjType.enum';
import { Entities } from '../Enums/Entities.enum';

export function convertEntityToBzObjType(entity: Entities): BzObjType {
  switch (entity) {
    case Entities.calculation:
      return BzObjType.KALKULATION;
    case Entities.contract:
      return BzObjType.AUFTRAG;
    case Entities.offer:
      return BzObjType.ANGEBOT;
    case Entities.invoice:
      return BzObjType.RECHNUNG;
    case Entities.deliveryNote:
      return BzObjType.LIEFERSCHEIN;
    case Entities.order:
      return BzObjType.BESTELLUNG;
    case Entities.demand:
      return BzObjType.BEDARFSANFORDERUNG;
    case Entities.inboundDeliveryNote:
      return BzObjType.EINGANGSLIEFERSCHEIN;
    default:
      throw new Error(`Cannot convert entity "${entity}" tp \`BzObjType\``);
  }
}
