import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

export const getTextmarkTypeFromTemplate = (templateType: string) => {
  switch (templateType) {
    case EMailTemplateKind.ERP_OBJECTS:
      return 'erp';
    case EMailTemplateKind.TICKET:
      return 'ticket';
    case EMailTemplateKind.APPOINTMENT_NOTIFICATION:
      return 'appointment';
    default:
      return 'email';
  }
};
