import { Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import { CostGroupPicker } from '@work4all/components/lib/components/entity-picker/CostGroupPicker';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';

import { PickerTargetButton } from './PickerTargetButton';

export function CostGroupCell(props: {
  disabled?: boolean;
  value: LookUp;
  onChange: (value: LookUp | null) => void;
  style?: React.CSSProperties;
  autoFocus?: boolean;
}) {
  const { disabled = false, value, onChange, autoFocus, style } = props;
  const popoverRef = useRef<EntityPickerPopover>(null);

  const label = value ? (
    <Typography component="span" variant="body2" color={'var(--text01)'}>
      {value.name}
    </Typography>
  ) : null;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <CostGroupPicker value={value} onChange={onChange} multiple={false} />
      }
    >
      <PickerTargetButton
        style={style}
        autoFocus={autoFocus}
        disabled={disabled}
      >
        {label}
      </PickerTargetButton>
    </EntityPickerPopover>
  );
}
