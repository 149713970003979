import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { REQUEST_DATA_BY_ENTITY } from '../constants';
import { PaymentEntity } from '../types';
import { prepareData } from '../utils';

interface Props {
  id: string | number;
  entity: PaymentEntity;
}

export const usePaymentData = ({ id, entity }: Props) => {
  const requestData = useMemo<DataRequest>(() => {
    return {
      entity,
      data: REQUEST_DATA_BY_ENTITY[entity],
      filter: [{ id: { $eq: id } }],
    };
  }, [id, entity]);

  const response = useDataProvider(requestData);
  const data = useMemo(() => prepareData(response, entity), [response, entity]);

  return useMemo(() => {
    return { data, refetch: response.refetch };
  }, [data, response.refetch]);
};
