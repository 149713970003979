import styles from './DocumentPreview.module.scss';

import React from 'react';

import { DocumentPreviewContent } from './components/DocumentPreviewContent';
import { DocumentPreviewTitle } from './components/DocumentPreviewTitle';
import { useDocumentPreview } from './hooks/use-document-preview';
import { IDocumentPreviewProps } from './IDocumentPreviewProps';

export function DocumentPreview(props: IDocumentPreviewProps) {
  const { filePath, subtitleComp } = props;

  const documentPreviewProps = useDocumentPreview(filePath);

  return (
    <div className={styles.wrapper}>
      <DocumentPreviewTitle {...props} {...documentPreviewProps} />
      {subtitleComp}
      <DocumentPreviewContent {...props} {...documentPreviewProps} />
    </div>
  );
}
