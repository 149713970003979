import { IconButton } from '@mui/material';

import { ReactComponent as DownloadIcon } from '@work4all/assets/icons/download.svg';

import { useAuthHeaders } from '@work4all/data/lib/auth';
import { downloadAuthed } from '@work4all/data/lib/utils';

import { TimeCardArchivedPdfDownloadActionProps } from './types';

export const TimeCardArchivedPdfDownloadAction = ({
  fileName,
  downloadUrl,
}: TimeCardArchivedPdfDownloadActionProps) => {
  const httpHeaders = useAuthHeaders();

  return (
    <IconButton
      color="primary"
      onClick={(e) => {
        e.preventDefault();
        downloadAuthed(downloadUrl, fileName, httpHeaders);
      }}
    >
      <DownloadIcon />
    </IconButton>
  );
};
