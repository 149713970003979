import { useRef, useState } from 'react';

import {
  BusinessPartnerPicker,
  IBusinessPartnerPickerProps,
} from '@work4all/components/lib/components/entity-picker/business-partner-picker/BusinessPartnerPicker';
import {
  EntityPickerPopover,
  IEntityPickerPopoverProps,
} from '@work4all/components/lib/components/entity-picker/components';
import { CustomerPicker } from '@work4all/components/lib/components/entity-picker/customer-picker/CustomerPicker';
import { SupplierPicker } from '@work4all/components/lib/components/entity-picker/supplier-picker/SupplierPicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { getRequiredEntity } from '../../containers/mask-overlays/mask-overlay/views/erp/getRequiredEntity';

import { EntityPickerActions } from './EntityPickerActions';

export type IBusinessPartnerPickerPopoverProps = {
  entity: Entities;
  withActionBar?: boolean;
  combinedBusinessPartner?: boolean;
} & Omit<IBusinessPartnerPickerProps, 'onTabChange'> &
  Pick<IEntityPickerPopoverProps, 'children' | 'anchorEl'>;

export function BusinessPartnerPickerPopover(
  props: IBusinessPartnerPickerPopoverProps
) {
  const {
    entity,
    withActionBar,
    combinedBusinessPartner,
    children,
    anchorEl,
    ...pickerProps
  } = props;

  const popoverRef = useRef<EntityPickerPopover>();

  const businessPartnerEntity = getRequiredEntity(entity);
  const [selectedTab, setSelectedTab] = useState(
    businessPartnerEntity ?? Entities.customer
  );

  const isCustomerPicker =
    !combinedBusinessPartner && businessPartnerEntity === Entities.customer;
  const isSupplierPicker =
    !combinedBusinessPartner && businessPartnerEntity === Entities.supplier;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      anchorEl={anchorEl}
      picker={
        isCustomerPicker ? (
          <CustomerPicker {...pickerProps} />
        ) : isSupplierPicker ? (
          <SupplierPicker {...pickerProps} />
        ) : (
          <BusinessPartnerPicker
            onTabSelected={setSelectedTab}
            {...pickerProps}
          />
        )
      }
      footer={
        withActionBar ? (
          <EntityPickerActions
            entity={selectedTab}
            multiple={pickerProps.multiple}
            onChange={(value) => {
              pickerProps.onChange(value);
              popoverRef.current?.close();
            }}
          ></EntityPickerActions>
        ) : null
      }
    >
      {children}
    </EntityPickerPopover>
  );
}
