import styles from './TableSection.module.scss';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Table, TableStateBagProvider } from '@work4all/components';
import { ActionsBar } from '@work4all/components/lib/dataDisplay/actions-bar/ActionsBar';
import { useRowSelection } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useRowSelection';

import { useUser } from '@work4all/data';

import { canAddPayment } from '@work4all/utils/lib/permissions';

import { usePaymentHnadlers, usePaymentTable } from '../hooks';

import { LedgerAccountSettings } from './ledger-account-settings/LedgerAccountSettings';

export const TableSection = () => {
  const { t } = useTranslation();
  const { watch, getValues } = useFormContext();
  const { selectedRows, onSelectedRowsChange } = useRowSelection();

  const payments = watch('payments') || [];
  const grossAmount = watch('grossAmount');

  const { columns, displayFooter } = usePaymentTable(payments);
  const showRemoveIcon =
    selectedRows.length === 1 && payments.every((x) => !x.datevDate);

  const { onAdd, onRemove } = usePaymentHnadlers({
    invoiceId: getValues('id'),
    payments,
    selectedRows,
    total: grossAmount,
  });

  const user = useUser();
  const canUserAddPayment =
    canAddPayment(user) && payments.every((x) => !x.datevDate);

  return (
    <div className={styles.wrapper}>
      <Stack direction="row" alignItems="center" gap="1rem" pb="1rem">
        <Stack direction="row" alignItems="center" gap="1rem" flex={1}>
          <Typography variant="h4" style={{ margin: 0, padding: 0 }}>
            {t('COMMON.PAYMENTS')}
          </Typography>

          <ActionsBar
            add={canUserAddPayment ? () => onAdd() : undefined}
            remove={showRemoveIcon ? onRemove : undefined}
            hideMoreButton
            hideTooltip
          />
        </Stack>

        <LedgerAccountSettings />
      </Stack>

      <TableStateBagProvider tableInstance={null}>
        <Table
          mode="client"
          columns={columns}
          data={payments}
          allItemsCount={payments.length}
          className={clsx({ [styles.table]: payments.length > 0 })}
          onSelectedRowsChange={onSelectedRowsChange}
          displayFooter={displayFooter}
          resizableColumns
          hideHeaderTooltip
        />
      </TableStateBagProvider>
    </div>
  );
};
