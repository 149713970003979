import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DuplicateEntityType } from '../Enums/DuplicateEntityType.enum';
import { EMode } from '../Enums/EMode.enum';

export class DuplicateRequest<T extends EMode = EMode.entity> {
  /** Alias: sourceEntityType */
  sourceEntityType?: DuplicateEntityType;
  /** Alias: sourceEntityCode */
  sourceEntityCode?: number;
  /** Alias: sourceEntityId */
  sourceEntityId?: string;
  /** Alias: includeAttachements */
  includeAttachements?: boolean;
  /** Alias: textSuffix */
  textSuffix?: string;

  __typename?: string;
}

const fields: FieldDefinitions<DuplicateRequest> = {
  sourceEntityType: {
    alias: 'sourceEntityType',
  },
  sourceEntityCode: {
    alias: 'sourceEntityCode',
  },
  sourceEntityId: {
    alias: 'sourceEntityId',
  },
  includeAttachements: {
    alias: 'includeAttachements',
  },
  textSuffix: {
    alias: 'textSuffix',
  },
  __typename: {
    alias: '__typename',
  },
};

export const duplicateRequestEntityDefinition: EntitiyDefinition<DuplicateRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'DuplicateRequest',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
