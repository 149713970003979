import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useRxDB } from 'rxdb-hooks';

import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { MailboxFolder } from '@work4all/models/lib/Classes/MailboxFolder.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

const READ_MAIL_CHILD_FOLDERS_FLAT = gql`
  query ReadMailboxChildFoldersFlat($mailboxId: ID!, $parentId: String) {
    readMailboxChildFoldersFlat(mailboxId: $mailboxId, parentId: $parentId) {
      childFolderCount
      folderType
      id
      index
      name
      parentId
      unassignedItemsCount
      unreadItemsCount
    }
  }
`;

const READ_MAILBOXES_FLAT = gql`
  query ReadMailboxesFlat {
    readMailboxesFlat {
      folder {
        folderType
        id
        index
        childFolderCount
        name
        parentId
        unassignedItemsCount
        unreadItemsCount
      }
      id
      loadErrorMessage
      loadErrorType
      mailboxPrimaryAddress
      mailboxType
    }
  }
`;

export const useReadMailboxChildFoldersFlat = (props: {
  parentId: string;
  mailboxId: string;
}) => {
  return useQuery<{
    readMailboxChildFoldersFlat: MailboxFolder<EMode.entity>[];
  }>(READ_MAIL_CHILD_FOLDERS_FLAT, {
    variables: {
      parentId: props.parentId,
      mailboxId: props.mailboxId,
    },
    skip: !props.mailboxId || !props.parentId,
  });
};

export const useReadMailboxesFlat = () => {
  return useQuery<{ readMailboxesFlat: Mailbox<EMode.entity>[] }>(
    READ_MAILBOXES_FLAT
  );
};

export const USER_MAILBOXES_ID = 'w4a-mailboxes-local.t-';
export function useMailboxesData(loadDeepFolders: boolean = true) {
  const db = useRxDB();
  const { activeTenant } = useTenant();

  const client = useApolloClient();

  const [flatMailboxes, setFlatMailboxes] = useState<Mailbox[]>([]);
  const [completeMailboxes, setCompleteMailboxes] = useState<Mailbox[]>([]);

  const mailboxesRes = useReadMailboxesFlat();

  const getLocalMailboxes = useCallback(async () => {
    const localMailboxes = await db.getLocal(
      `${USER_MAILBOXES_ID}${activeTenant}`
    );
    setCompleteMailboxes(localMailboxes._data.data || []);
  }, [activeTenant, db]);

  const loadAdditionalFolders = useCallback(
    async (mailboxes: Mailbox[]) => {
      async function getSubFolders(
        mailboxId: string,
        folderId: string
      ): Promise<MailboxFolder[]> {
        const subFoldersRes = await client.query({
          query: READ_MAIL_CHILD_FOLDERS_FLAT,
          variables: {
            mailboxId,
            parentId: folderId,
          },
        });

        let subFolders = subFoldersRes.data?.readMailboxChildFoldersFlat;

        const promises = subFolders
          .filter((subFolders) => subFolders.childFolderCount)
          .map((subFolders) => getSubFolders(mailboxId, subFolders.id));

        const resAll = await Promise.all(promises);
        for (const subChildFolders of resAll) {
          subFolders = subFolders.concat(subChildFolders);
        }

        return subFolders;
      }

      const resultingMailboxes = [];

      for (const mailbox of mailboxes) {
        const originalFolders = [...mailbox.folder];
        let additionalFolders = [];

        const promises = originalFolders
          .filter((folder) => folder.childFolderCount)
          .map((folder) => getSubFolders(mailbox.id, folder.id));

        const resAll = await Promise.all(promises);
        for (const subFolders of resAll) {
          additionalFolders = additionalFolders.concat(subFolders);
        }

        const uniqueFolders = [...originalFolders, ...additionalFolders].reduce(
          (acc, obj) => {
            if (!acc.some((o) => o.id === obj.id)) {
              acc.push(obj);
            }
            return acc;
          },
          []
        );

        resultingMailboxes.push({
          ...mailbox,
          folder: uniqueFolders,
        });
      }

      setCompleteMailboxes(resultingMailboxes);
    },
    [client]
  );

  useEffect(() => {
    getLocalMailboxes();
  }, []);

  useEffect(() => {
    db.upsertLocal(
      `${USER_MAILBOXES_ID}${activeTenant}`,
      completeMailboxes.length ? completeMailboxes : []
    );
  }, [activeTenant, db, completeMailboxes]);

  useEffect(() => {
    if (!mailboxesRes.loading) {
      setFlatMailboxes(mailboxesRes.data.readMailboxesFlat);
      if (loadDeepFolders) {
        loadAdditionalFolders(mailboxesRes.data.readMailboxesFlat);
      }
    }
  }, [loadAdditionalFolders, mailboxesRes, loadDeepFolders]);

  const refetchMailboxes = useCallback(() => {
    mailboxesRes.refetch();
  }, [mailboxesRes]);

  return {
    mailboxes: completeMailboxes.length ? completeMailboxes : flatMailboxes,
    refetch: refetchMailboxes,
  };
}
