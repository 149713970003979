import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';

import { useDialogs } from '@work4all/components';

import { useOrganisationAreaContext } from '../hooks';

import { OrganisationAreaSettingsPopover } from './organisation-area-settings-popover';
import { OrganisationAreaSettingsPopoverContent } from './organisation-area-settings-popover-content';

export const OrganisationAreaSettingsAction = () => {
  const dialogs = useDialogs();
  const { entity } = useOrganisationAreaContext();

  const openSettingsPopover = useCallback(
    (event) => {
      const dialog = dialogs.open(OrganisationAreaSettingsPopover, {
        anchorEl: event.currentTarget,
        children: <OrganisationAreaSettingsPopoverContent entity={entity} />,
        onClose: () => dialogs.close(dialog.id),
      });
    },
    [dialogs, entity]
  );

  return (
    <IconButton onClick={openSettingsPopover}>
      <SettingsIcon />
    </IconButton>
  );
};
