import React from 'react';

import { CloneConvertDialogProps } from '../types';

import { ConvertErpDialog } from './ConvertErpDialog';
import { CopyErpDialog } from './CopyErpDialog';

export const CloneConvertDialog = (props: CloneConvertDialogProps) => {
  const { sourceEntity: entity, targetEntity } = props;
  return (
    <React.Fragment>
      {targetEntity === entity ? <CopyErpDialog {...props} /> : null}
      {targetEntity && targetEntity !== entity ? (
        <ConvertErpDialog {...props} />
      ) : null}
    </React.Fragment>
  );
};
