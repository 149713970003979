import AttachmentIcon from '@mui/icons-material/AttachFile';
import { CircularProgress } from '@mui/material';
import { ChangeEvent, useContext } from 'react';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { useLock } from '../../hooks/object-lock';
import { FileButton } from '../../input/FileButton';
import { LABEL_VISIBILITY, MaskActionButton } from '../MaskActionButton';

interface AttachmentsUploadButtonProps {
  disabled?: boolean;
  disabledReason?: string;
}

export const AttachmentsUploadButton: React.FC<AttachmentsUploadButtonProps> = (
  props
) => {
  const { disabled = false } = props;

  const attachmentsContext = useContext(TempFileManagerContext);
  const { uploadFiles: uploadAttachments, currentUploadingFiles } =
    attachmentsContext;

  const lock = useLock();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    uploadAttachments(Array.from(e.target.files));
  }

  const isUploadActionAllowed =
    (!disabled && !lock.locked) || currentUploadingFiles > 0;

  return (
    <FileButton name="upload" multiple onChange={handleChange}>
      {(props) => (
        <MaskActionButton
          {...props}
          label={''}
          labelVisibility={LABEL_VISIBILITY.HIDE}
          color="primary"
          icon={
            currentUploadingFiles > 0 ? (
              <CircularProgress size="1.25rem" />
            ) : (
              <AttachmentIcon />
            )
          }
          disabled={!isUploadActionAllowed}
        />
      )}
    </FileButton>
  );
};
