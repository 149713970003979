import { Radio } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import {
  BasicTable,
  ColumnAdditionalData,
  DateCell,
} from '@work4all/components';

import { PurchasePrice } from '@work4all/models/lib/Classes/PurchasePrice.entity';

import { formatNumberAsCurrency } from '@work4all/utils';

export interface PurchasePriceTableProps {
  standardSupplierId: number;
  onStandardSupplierIdChange: (value: number) => void;
  purchasePriceList: PurchasePrice[];
}

export function PurchasePriceTable(props: PurchasePriceTableProps) {
  const { standardSupplierId, onStandardSupplierIdChange, purchasePriceList } =
    props;

  const { t } = useTranslation();

  const columns = useMemo(() => {
    const columns: (Column<PurchasePrice> & ColumnAdditionalData)[] = [
      {
        id: 'isStandard',
        Header: t('MASK.ARTICLE.PURCHASE_PRICE.FIELDS.IS_STANDARD_PRICE'),
        accessor: (row) => row.supplier.id === standardSupplierId,
        width: 100,
        Cell: (props) => {
          return (
            <Radio
              checked={props.value}
              onClick={() => {
                onStandardSupplierIdChange(
                  props.value ? 0 : props.row.original.supplier.id
                );
              }}
              sx={{
                display: 'block',
                width: 'max-content',
                margin: 'auto',
                padding: 0,
              }}
            />
          );
        },
      },
      {
        id: 'supplier',
        Header: t('COMMON.SUPPLIER'),
        accessor: (row) => {
          return row.supplier.name;
        },
        width: 120,
      },
      {
        id: 'validFromDate',
        Header: t('MASK.VALID_FROM'),
        accessor: (row) => {
          return row.validFromDate;
        },
        width: 120,
        Cell: DateCell,
      },
      {
        id: 'listPrice',
        Header: t('MASK.LIST_PRICE'),
        alignment: 'right',
        accessor: (row) => {
          return `${formatNumberAsCurrency(row.listPrice)} €`;
        },
        width: 120,
      },
      {
        id: 'discount',
        Header: t('MASK.DISCOUNT'),
        alignment: 'right',
        accessor: (row) => {
          return `${formatNumberAsCurrency(row.discount)} %`;
        },
        width: 120,
      },
      {
        id: 'purchasePrice',
        Header: t('MASK.PURCHASE_PRICE_SHORT'),
        alignment: 'right',
        accessor: (row) => {
          return `${formatNumberAsCurrency(row.purchasePrice)} €`;
        },
        width: 120,
      },
    ];

    return columns;
  }, [onStandardSupplierIdChange, standardSupplierId, t]);

  return (
    <BasicTable
      mode="client"
      columns={columns}
      data={purchasePriceList}
      isVirtual={false}
      fitContentHeight={true}
      resizableColumns={true}
    />
  );
}
