import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { useUser } from '@work4all/data';

import { useMaskContext } from '../../../hooks/mask-context';
import { NEW_ROW_ID_PREFIX } from '../constants';
import { Payment, PaymentEntity, UsePaymentOnEditHnadler } from '../types';

import { useDefaultLedgerAccounts } from './use-default-ledger-accounts';
import { usePaymentMutations } from './use-payment-mutations';

interface Props {
  invoiceId?: number;
  total?: number;
  payments: Payment[];
  selectedRows?: string[];
}

export const usePaymentHandlers = ({
  invoiceId,
  payments,
  selectedRows,
  total,
}: Props) => {
  const user = useUser();
  const dialogs = useDialogs();
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { entity } = useMaskContext();
  const { remove } = usePaymentMutations({
    entity: entity as PaymentEntity,
    invoiceId,
  });

  const { defaultLedgerAccount } = useDefaultLedgerAccounts(
    entity as PaymentEntity
  );

  const onAdd = useCallback(
    (data?: Payment[]) => {
      const remaining = payments
        .map((p) => p.amount)
        ?.reduce((a, b) => a + b, 0);

      const defaultData: Payment = {
        id: `${NEW_ROW_ID_PREFIX}${payments.length}`,
        bookingDate: DateTime.now().toISO(),
        amount: total - remaining,
        ledgerAccountNumber: defaultLedgerAccount?.number,
        ledgerAccount: defaultLedgerAccount,
        note: '',
        datevDate: '',
        user: {
          id: user.benutzerCode,
          displayName: user.displayName,
        },
        date: DateTime.now().toISO(),
      };

      const newPaymentsData: Payment[] = [
        ...payments,
        ...(data
          ? data.map((d, idx) => ({
              ...defaultData,
              id: `${NEW_ROW_ID_PREFIX}${payments.length + idx + 1}`,
              ...d,
            }))
          : [defaultData]),
      ];

      setValue('payments', newPaymentsData, {
        shouldDirty: true,
      });
    },
    [
      payments,
      total,
      defaultLedgerAccount,
      user.benutzerCode,
      user.displayName,
      setValue,
    ]
  );

  const onEdit: UsePaymentOnEditHnadler = useCallback(
    (oldData: Payment, newData: Payment) => {
      const copyOfPayments = [...payments];
      const payment = copyOfPayments.find((item) => item.id === oldData.id);
      Object.keys(newData).forEach((key) => {
        payment[key] = newData[key];
      });

      setValue('payments', copyOfPayments, { shouldDirty: true });
    },
    [payments, setValue]
  );

  const onRemove = useCallback(async () => {
    const id = selectedRows[0];
    if (id.startsWith(NEW_ROW_ID_PREFIX)) {
      setValue(
        'payments',
        payments.filter((payment) => payment.id !== id)
      );
      return;
    }

    const confirmed = await dialogs.confirm({
      title: t('ALERTS.HINT'),
      description: t('ALERTS.DELETE.PAYMENTS'),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });

    if (confirmed) {
      remove(Number(id));
    }
  }, [dialogs, payments, remove, selectedRows, setValue, t]);

  return { onAdd, onEdit, onRemove };
};
