import React, { useMemo } from 'react';
import { Cell } from 'react-table';

import { formatNumberAsCurrency } from '@work4all/utils';

export const NUMBER_PARAMS = {
  cellParams: {
    maximumFractionDigits: 2,
  },
};

export const CURRENCY_PARAMS = {
  cellParams: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
};

export interface ExtendedNumberCell {
  column: {
    cellParams?: {
      minimumFractionDigits?: number;
      maximumFractionDigits?: number;
    };
  };
}

export type INumberCellProps = {
  value: string | number;
  column?: {
    id: string;
  };
  row?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
  };
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  className?: string;
  onEdit?: () => void;
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: Cell<any, any> & ExtendedNumberCell;
};

export const NumberCell = (props: INumberCellProps) => {
  let { minimumFractionDigits = 0, maximumFractionDigits = 0 } = props;
  const {
    value,

    className = '',
    style,
  } = props;

  if (props.cell.column.cellParams) {
    const params = props.cell.column.cellParams;
    minimumFractionDigits = params.minimumFractionDigits || 0;
    maximumFractionDigits = params.maximumFractionDigits || 0;
  }

  const displayValue = useMemo(() => {
    if ((typeof value === 'string' && value === '0') || value === 0) {
      return '';
    } else {
      return formatNumberAsCurrency(
        typeof value === 'string' ? parseInt(value, 10) : value,
        {
          minimum: minimumFractionDigits,
          maximum: maximumFractionDigits,
        }
      );
    }
  }, [maximumFractionDigits, minimumFractionDigits, value]);

  return (
    <div
      style={{ textAlign: 'right', ...style }}
      className={className}
      onClick={props.onEdit}
    >
      {displayValue || ''}
    </div>
  );
};
