import { useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LanguageResource } from '@work4all/utils/lib/i18n/LanguageResource.enum';

import { useErpTranslation } from './translations/use-erp-translation';

interface UseErpFileNameProps {
  entityType: Entities;
  data: {
    contractNumber?: number;
    number?: number;
    value?: number;
    language?: {
      id?: number;
    };
  };
  languageResources?: LanguageResource[];
}

export const useErpFileName = (props: UseErpFileNameProps) => {
  const { entityType, data, languageResources } = props;
  const { t } = useErpTranslation({ languageResources });

  const lang = data.language?.id === 0 ? 'de' : 'en';

  const initialFileName = useMemo(() => {
    const translationKey =
      'COMMON.' +
      (entityType === Entities.invoice && data.value < 0
        ? 'CREDIT'
        : entityType.toUpperCase());

    const result = `${t(lang, translationKey)} ${
      entityType === Entities.contract ? data.contractNumber : data?.number
    }.pdf`;
    return result;
  }, [data.contractNumber, data.number, data.value, entityType, lang, t]);
  return initialFileName;
};
