import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

export const OrganisationAreaSectionEditAction = () => {
  return (
    <IconButton disabled>
      <EditIcon />
    </IconButton>
  );
};
