import styles from './BusinessPartnerStatusBanner.module.scss';

import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface BusinessPartnerStatusBannerProps {
  closed?: boolean;
  locked?: boolean;
}

export function BusinessPartnerStatusBanner(
  props: BusinessPartnerStatusBannerProps
) {
  const { closed = false, locked = false } = props;

  const { t } = useTranslation();

  if (!closed && !locked) return null;

  const statusText = [closed && t('FILE.EXTINCT'), locked && t('FILE.LOCKED')]
    .filter(Boolean)
    .join(' | ');

  return (
    <div className={styles.banner}>
      <WarningIcon />
      <Typography color="inherit">{statusText}</Typography>
    </div>
  );
}
