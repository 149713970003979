import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, Stack, Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectPlanningView } from './components/project-planning-view/ProjectPlanningView';
import { ProjectContractsTable } from './components/ProjectContractsTable';

interface IProps {
  onCollapsedChanged: (collapsed: boolean) => void;
  collapsed: boolean;
  projectIdList: number[];
}

type ProjectTabs = 'projectPlanning' | 'contracts';

const tabs: ProjectTabs[] = ['projectPlanning', 'contracts'];

const TAB_LABEL: Record<ProjectTabs, string> = {
  contracts: 'COMMON.CONTRACT_plural',
  projectPlanning: 'COMMON.PROJECTPROCESS_plural',
};

export const ProjectTableBottomSection = (props: IProps) => {
  const { collapsed, onCollapsedChanged, projectIdList } = props;
  const [selectedTab, setSelectedTab] =
    useState<ProjectTabs>('projectPlanning');

  const renderedTab = useMemo(() => {
    switch (selectedTab) {
      case 'projectPlanning':
        return <ProjectPlanningView projectIdList={projectIdList} />;
      case 'contracts':
        return <ProjectContractsTable projectIdList={projectIdList} />;
      default:
        return null;
    }
  }, [projectIdList, selectedTab]);

  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        padding="0.5rem 0"
        zIndex={100}
        width="fit-content"
      >
        <IconButton
          onClick={() => {
            onCollapsedChanged(!collapsed);
          }}
        >
          {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
        <Tabs
          value={collapsed ? false : selectedTab}
          onChange={(_, value) => {
            onCollapsedChanged(false);
            setSelectedTab(value);
          }}
        >
          {tabs.map((tab, index) => {
            const label = TAB_LABEL[tab];

            return <Tab key={index} value={tab} label={t(label)} />;
          })}
        </Tabs>
      </Stack>
      {!collapsed ? renderedTab : null}
    </>
  );
};
