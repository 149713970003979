import { buildSchema } from './build-schema';
import { getEntityConfigs } from './configs/get-entity-configs';
import { GetTenantSchema } from './types';

export const getTenantSchema: GetTenantSchema = ({ entity, settings }) => {
  const configs = getEntityConfigs({ entity });

  if (!configs) {
    return { required: [], properties: {} };
  }

  return buildSchema({ configs, settings });
};
