import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class UserGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: grIndex */
  grIndex?: number;
  /** Alias: grLevel */
  grLevel?: number;

  __typename?: string;
}

const fields: FieldDefinitions<UserGroup> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  grIndex: {
    alias: 'grIndex',
  },
  grLevel: {
    alias: 'grLevel',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userGroupEntityDefinition: EntitiyDefinition<UserGroup> = {
  local: {},
  remote: {
    queryName: 'getObjectProtectionGroups',
    fragmentName: 'BenutzerGruppe',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
