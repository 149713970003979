import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { GroupQueryResultItem } from './GroupQueryResultItem.entity';

export class GroupQueryResultRow<T extends EMode = EMode.entity> {
  /** Alias: data */
  data?: GroupQueryResultItem<T>[];
  /** Alias: aggregateResult */
  aggregateResult?: number;

  __typename?: string;
}

const fields: FieldDefinitions<GroupQueryResultRow> = {
  data: {
    alias: 'data',
    entity: Entities.groupQueryResultItem,
  },
  aggregateResult: {
    alias: 'aggregateResult',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupQueryResultRowEntityDefinition: EntitiyDefinition<GroupQueryResultRow> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'GroupQueryResultRow',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
