import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ListEntityPicker } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../../../../../../locked-inputs';

export type ProjectFolderPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: number; name: string },
  TMultiple
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> & { dataSet: any };

export function ProjectFolderPicker<TMultiple extends boolean>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: ProjectFolderPickerProps<TMultiple> & { dataSet: any }
) {
  const { ...rest } = props;

  const dataSet = useMemo(() => {
    return {
      total: props.dataSet.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: props.dataSet,
    };
  }, [props.dataSet]);

  return (
    <ListEntityPicker
      entity={Entities.project}
      multiple={false}
      layout="simple"
      maxItems={3}
      data={{ id: null, name: null }}
      filterBy="name"
      sortBy="name"
      clearable={true}
      {...rest}
      fixedDataSet={dataSet}
      renderItemContent={(status) => (
        <Typography variant="body2" noWrap>
          {status.name}
        </Typography>
      )}
    />
  );
}

interface ProjectFolderPickerFieldProps
  extends Omit<ProjectFolderPickerProps<false>, 'multiple'> {
  error?: string;
  label?: string;
}

export const ProjectFolderPickerField = React.forwardRef<
  HTMLDivElement,
  ProjectFolderPickerFieldProps
>(function ProjectFolderPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { label, value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={<ProjectFolderPicker multiple={false} {...pickerProps} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value?.name ?? ''}
        label={label || t('COMMON.FOLDER')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
