import styles from './styles.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabeledInputWithUnit } from '../../../../../locked-inputs';
import { ControlWrapper } from '../../../../components';
import { useBookingAssistance } from '../../hooks';

interface ContentProps {
  id: number;
}

export const BookingAssistance = () => {
  const { watch } = useFormContext();
  const id = watch('id');
  const payments = watch('payments');

  if (id && payments?.length === 0) {
    return <BookingAssistanceContent id={id} />;
  }

  return null;
};

export const BookingAssistanceContent = ({ id }: ContentProps) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const discountDate = watch('discountDate');
  const payments = watch('payments');
  const remaining = watch('remainingAmount');
  const discount = watch('discountPercent');
  const isInDatev = payments.some((x) => x.datevDate);

  const { data, canCreate, create, edit } = useBookingAssistance({
    invoiceId: id,
    discount,
    remaining,
  });

  const discountRef = useRef<HTMLInputElement>(null);
  const discountAmountRef = useRef<HTMLInputElement>(null);
  const paymentAmountRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    discountRef.current.value = data.discount.toString();
    discountAmountRef.current.value = data.discountAmount.toFixed(2);
    paymentAmountRef.current.value = data.paymentAmount.toFixed(2);
  }, [data]);

  return (
    <div className={styles.bookingAssistance}>
      <Divider>{t('PAYMENT.BOOKING_ASSISTANCE_TITLE')}</Divider>

      <Grid container spacing="1rem" padding="1rem 0">
        <Grid item xs={6}>
          <Stack gap="0.25rem">
            <LabeledInputWithUnit
              label={t('COMMON.RA.DISCOUNT')}
              unit={'%'}
              type="number"
              defaultValue={data.discount}
              onBlur={(e) => {
                edit('discount', Number(e?.target?.value));
              }}
              disabled={payments?.length}
              ref={discountRef}
            />
            {discount ? (
              <Typography variant="caption">
                {t('PAYMENT.SKONTO_UNTIL', {
                  discount,
                  date: DateTime.fromISO(discountDate).toFormat('dd.MM.yyyy'),
                })}
              </Typography>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <LabeledInputWithUnit
            label={t('COMMON.DISCOUNT_AMOUNT')}
            unit={'€'}
            type="number"
            defaultValue={data.discountAmount.toFixed(2)}
            onBlur={(e) => {
              edit('discountAmount', Number(e?.target?.value));
            }}
            disabled={payments?.length}
            ref={discountAmountRef}
          />
        </Grid>
      </Grid>

      <ControlWrapper columns={2}>
        <LabeledInputWithUnit
          label={t('INPUTS.PAYMENT')}
          unit={'€'}
          type="number"
          defaultValue={data.paymentAmount.toFixed(2)}
          onBlur={(e) => {
            edit('paymentAmount', Number(e?.target?.value));
          }}
          disabled={payments?.length}
          ref={paymentAmountRef}
        />
        <Button
          disabled={!canCreate || isInDatev || payments?.length}
          startIcon={<CheckIcon />}
          onClick={create}
        >
          {t('PAYMENT.BOOKING_ASSISTANCE_BUTTON')}
        </Button>
      </ControlWrapper>
    </div>
  );
};
