import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { EmailTemplatePreview } from './EmailTemplatePreview';

export type IEmailPreviewContainerProps = {
  emailTemplateId: string;
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onVisibilityToggle?: (visible: boolean) => void;
};

export const EMAIL_TEMPLATE_PREVIEW_DATA: EMailTemplate<EMode.query> = {
  id: null,
  name: null,
  body: null,
  signature: {
    id: null,
    body: null,
  },
};

export function EmailTemplatePreviewContainer(
  props: IEmailPreviewContainerProps
) {
  const { emailTemplateId, onCloseClick, onEditClicked, onVisibilityToggle } =
    props;

  const emailTemplates = useEntityPreviewData<EMailTemplate>(
    [emailTemplateId],
    Entities.eMailTemplate,
    EMAIL_TEMPLATE_PREVIEW_DATA,
    undefined,
    {
      'signature.body': EMAIL_SIGNATURE_KEYS_ARGUMENTS.body,
    }
  );

  if (!emailTemplates?.[0]) {
    return null;
  }

  return (
    <EmailTemplatePreview
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      emailTemplate={emailTemplates[0]}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
