import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export enum ErpStatus {
  Open = 'OPEN',
  Frozen = 'FROZEN',
  FrozenAndClosed = 'FROZEN_AND_LOCKED',
  Incorrect = 'INCORRECT',
}

export type ErpStatusPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: ErpStatus; name: string },
  TMultiple
>;

export function ErpStatusPicker<TMultiple extends boolean>(
  props: ErpStatusPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: ErpStatus.Open,
      name: t('COMMON.ERP_STATUS_PICKER_' + ErpStatus.Open),
    },
    {
      id: ErpStatus.Frozen,
      name: t('COMMON.ERP_STATUS_PICKER_' + ErpStatus.Frozen),
    },
    {
      id: ErpStatus.FrozenAndClosed,
      name: t('COMMON.ERP_STATUS_PICKER_' + ErpStatus.FrozenAndClosed),
    },
  ];

  return <FixedDataPicker dataSet={dataSet} {...rest} clearable={false} />;
}
