import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputArticlePricesSetSimplePrice<T extends EMode = EMode.entity> {
  /** Alias: priceGroupCode */
  priceGroupCode?: number;
  /** Alias: price */
  price?: number;
  /** Alias: surcharge */
  surcharge?: number;
  /** Alias: pricePerMinute */
  pricePerMinute?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticlePricesSetSimplePrice> = {
  priceGroupCode: {
    alias: 'priceGroupCode',
  },
  price: {
    alias: 'price',
  },
  surcharge: {
    alias: 'surcharge',
  },
  pricePerMinute: {
    alias: 'pricePerMinute',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticlePricesSetSimplePriceEntityDefinition: EntitiyDefinition<InputArticlePricesSetSimplePrice> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticlePricesSetSimplePrice',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
