import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../settings';
import { PaymentEntity } from '../types';

export const useDefaultLedgerAccounts = (entity: PaymentEntity) => {
  const { set: setDefaultLedgerAccountId, value: defaultLedgerAccountId } =
    useSetting(settings.paymentDefaultLedgerAccountId(entity));

  const {
    set: setDefaultSkontoLedgerAccountId,
    value: defaultSkontoLedgerAccountId,
  } = useSetting(settings.paymentDefaultSkontoLedgerAccountId(entity));

  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        number: null,
        name: null,
      } as LedgerAccount,
      entity: Entities.ledgerAccount,
      filter: [
        {
          id: {
            $in: [defaultLedgerAccountId, defaultSkontoLedgerAccountId],
          },
        },
      ],
    };
  }, [defaultLedgerAccountId, defaultSkontoLedgerAccountId]);

  const { data } = useDataProvider<LedgerAccount>(request);

  const defaultLedgerAccount = useMemo<LedgerAccount>(() => {
    return data.find((a) => a.id === defaultLedgerAccountId);
  }, [data, defaultLedgerAccountId]);

  const defaultSkontoLedgerAccount = useMemo<LedgerAccount>(() => {
    return data.find((a) => a.id === defaultSkontoLedgerAccountId);
  }, [data, defaultSkontoLedgerAccountId]);

  return {
    defaultLedgerAccount,
    defaultSkontoLedgerAccount,
    setDefaultLedgerAccountId,
    setDefaultSkontoLedgerAccountId,
  };
};
