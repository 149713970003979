import { Document } from '@work4all/models/lib/Classes/Document.entity';

import { FileAndThumbnail } from './types';

export function getDocumentFileList(doc: Document): FileAndThumbnail[] {
  const otherDocs = doc.childDocuments ?? [];

  return [doc, ...otherDocs]
    .filter((doc) => !!doc.fileInfos)
    .map((doc) => {
      /**
       * RK: added the id as its used for renaming context even if this is not doable for saved files anymore
       * https://work4all.slack.com/archives/C03LXTT1RL4/p1708611239256329
       *
       * there are cases, where the fileInfo does not hold fileserviceProivderInfos (e.g. customer has no fsp, just local files)
       */
      return {
        id: `${doc.id}`,
        file: doc.fileInfos,
        thumbnail: doc.thumbnail,
        name: doc.noteHtml,
      };
    });
}
