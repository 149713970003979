import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { SettingScope, useSettingsContext } from '../settings';

type Props = {
  scope: SettingScope;
};

export const useSyncedSettings = ({ scope }: Props) => {
  const { getValue, subscribe } = useSettingsContext();
  const [settings, setSettings] = useState(() => getValue()[scope]);

  useEffect(() => {
    function onStateChange() {
      const newValue = getValue()[scope];
      setSettings((oldValue) => {
        if (isEqual(oldValue, newValue)) {
          return oldValue;
        }

        return newValue;
      });
    }

    onStateChange();

    return subscribe(onStateChange);
  }, [getValue, subscribe, scope]);

  return settings;
};
