import styles from './LanguageTab.module.scss';

import { useTranslation } from 'react-i18next';

import { getTextmarkTypeFromTemplate } from '@work4all/components/lib/components/entity-picker/textmark-picker';
import { FROALA_TEXTMARKS_STYLES_URL } from '@work4all/components/lib/components/entity-preview/utils';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { ControlWrapper } from '../../../../components';
import { HtmlInputField } from '../../components/html-input-field/HtmlInputField';
import { EMailTemplateFormValue } from '../../EMailTemplateOverlayController';

export interface LanguageTabProps {
  onChange: (value: string) => void;
}

export const LanguageTab = () => {
  const { activeTenant } = useTenant();
  const user = useUser();

  const { t } = useTranslation();

  const { control, setValue, formState, watch } =
    useFormContextPlus<EMailTemplateFormValue>();

  const generateFileUploadUrl = (baseUrl: string, authToken: string) => {
    return `${baseUrl}/api/file/inlinemailimage?auth=${authToken}`;
  };

  const subjectErrorText =
    formState.errors?.subject?.type === 'maxLength'
      ? t('INPUTS.ERRORS.MAX_LENGTH')
      : undefined;

  const eMailTemplateKind = watch('eMailTemplateKind');
  const textmarkType = getTextmarkTypeFromTemplate(eMailTemplateKind);

  return (
    <>
      <ControlWrapper className={styles.subjectWrapper}>
        <ControllerPlus
          control={control}
          name="subject"
          render={({ field }) => {
            return (
              <HtmlInputField
                label={t('COMMON.SUBJECT')}
                {...field}
                error={subjectErrorText}
                textmarkType={textmarkType}
              />
            );
          }}
        />
      </ControlWrapper>

      <ControllerPlus
        control={control}
        name="body"
        render={({ field }) => (
          <TextEditor
            enableTextBuildingBlocks={true}
            enableTextmarks={true}
            value={field.value || ''}
            applyZoomLevel={false}
            onChange={(val) => {
              setValue('body', val, { shouldDirty: true });
            }}
            config={{
              heightMin: 200,
              useClasses: true,
              requestHeaders: {
                'x-work4all-mandant': activeTenant,
              },
              imageUpload: true,
              imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
              iframeStyleFiles: [FROALA_TEXTMARKS_STYLES_URL],
            }}
            textmarkType={textmarkType}
          />
        )}
      />
    </>
  );
};
