import styles from './HeaderCell.module.scss';

import clsx from 'clsx';
import React, { ReactNode, useContext } from 'react';

import { Tooltip } from '../../../../../../components/tooltip/Tooltip';
import { ColumnVisibilityContext } from '../../../../hooks/useColumnVisibility';
import { ColumnInstance, ICssClasses } from '../../../../types';
import { SELECTION_COLUMN_ID } from '../../../../utils/makeRowsSelectable';
import { Seperator } from '../../../seperator/Seperator';
import { ITableCellProps, TableCell } from '../../../table-cell/TableCell';
import { HeaderCellContent } from '../header-cell-content/HeaderCellContent';
import { HeaderCellWrapper } from '../header-cell-wrapper/HeaderCellWrapper';

export interface IHeaderCellProps {
  col: ColumnInstance;
  canResizeLast: boolean;
  canResizeCurrent: boolean;
  cellRef?: React.ForwardedRef<HTMLDivElement>;
  rootRef?: React.ForwardedRef<HTMLDivElement>;
  cellProps?: ITableCellProps;
  noSeperator: boolean;
  resizableColumns: boolean;
  classes?: ICssClasses;
  columnDragIndicators?: Record<'before' | 'after', boolean>;
  style?: React.CSSProperties;
  hideTooltip?: boolean;
}

export const HeaderCell: React.FC<IHeaderCellProps> = (props) => {
  const {
    col,
    rootRef,
    cellRef,
    canResizeCurrent,
    canResizeLast,
    noSeperator,
    resizableColumns,
    cellProps,
    columnDragIndicators,
    style,
    hideTooltip,
  } = props;

  let resizer: JSX.Element | null = null;
  if (col.id === SELECTION_COLUMN_ID || noSeperator || !col.getResizerProps) {
    resizer = null;
  } else if (canResizeCurrent && resizableColumns) {
    resizer = (
      <Seperator
        className={props.classes?.headerSeperator}
        {...col.getResizerProps()}
      />
    );
  } else {
    resizer = <Seperator className={props.classes?.headerSeperator} />;
  }

  const noPaddingLeft = canResizeLast && !props.noSeperator;
  const noPaddingRight = canResizeCurrent && !props.noSeperator;
  const noFlex = col.noFlex;

  const columnVisibilityContext = useContext(ColumnVisibilityContext);

  const getHeaderTitle = (value: ReactNode): ReactNode => {
    return typeof value !== 'function' ? value : '';
  };

  const noBorderStyle = {
    border: col.id === 'PLACEHOLDER' ? 'unset' : undefined,
  };
  const resolvedStyle = style ? { ...style, ...noBorderStyle } : noBorderStyle;
  const { key, ...headerProps } = col.getHeaderProps({ style: resolvedStyle });
  return (
    <Tooltip
      title={getHeaderTitle(col.Header as string)}
      placement="top"
      hideOnClickIfUnhoverd
      hide={hideTooltip}
    >
      <div
        ref={rootRef}
        className={clsx(
          styles.headerCellRoot,
          col.className,
          props.classes?.headerCellRoot,
          {
            [styles.noFlex]: noFlex,
            [styles.columnDragBefore]: columnDragIndicators?.before,
            [styles.columnDragAfter]: columnDragIndicators?.after,
          }
        )}
        {...headerProps}
        key={key}
      >
        <HeaderCellWrapper>
          <TableCell
            className={clsx(styles['cell'], {
              [styles.highlighted]:
                columnVisibilityContext?.toggledColumnId === col.id,
            })}
            noPaddingLeft={noPaddingLeft}
            noPaddingRight={noPaddingRight}
            dataType={col.dataType}
            alignment={col.alignment}
            ref={cellRef}
            {...cellProps}
          >
            <HeaderCellContent col={col} classes={props.classes} />
          </TableCell>
          {resizer}
        </HeaderCellWrapper>
      </div>
    </Tooltip>
  );
};
