import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useConvertInvoiceToRaContext } from '../hooks';

export const ConvertInvoiceToRaAbilitySwitchButton = () => {
  const { t } = useTranslation();
  const { isEnabled, toggleAbility } = useConvertInvoiceToRaContext();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Switch checked={isEnabled} onClick={toggleAbility} />
        <Typography style={{ marginLeft: 8 }} variant="body1">
          {t('MORE.RA')}
        </Typography>
      </div>

      <Tooltip title={t('TOOLTIP.CONVERT_INVOICE_TO_RA')}>
        <IconButton color="primary">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
