import styles from './Preview.module.scss';

import LinkIcon from '@mui/icons-material/Link';
import UploadFile from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import i18next from 'i18next';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { PictureFromFileOrCam } from '@work4all/components/lib/input/upload-image/components/camera-take-picture/PicFromFileOrCam';
import {
  IPreviewProps,
  MIME_TYPES,
  Preview as PreviewComp,
} from '@work4all/components/lib/preview/Preview';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../hooks/mask-context';
import { useEntityRightsContext } from '../../../../hooks/use-entity-rights';
import { DocumentMaskContext } from '../../context';
import { DocumentMaskFormValue } from '../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapper: styles.collapseWrapperContent,
};

export const PreviewOld = () => {
  const { t } = useTranslation();
  const tfm = useContext(TempFileManagerContext);

  const { watch } = useFormContextPlus<DocumentMaskFormValue>();

  const filePath = watch('filePath');
  const basedOnTemplate = watch('basedOnTemplate');
  const formFileInfos = watch('fileInfos');

  const mask = useMaskContext();
  const maskExtras = useContext(DocumentMaskContext);
  const rights = useEntityRightsContext();

  const uploadTempFile = tfm.temporaryFileUploads?.[0];

  const fileInfos = formFileInfos || uploadTempFile?.fileInfos;
  const isLinkable =
    maskExtras.type === 'link' &&
    typeof filePath === 'string' &&
    filePath.startsWith('https');

  const isTplCreate = maskExtras.type === 'template' && mask.mode === 'create';

  let previewAttrs: IPreviewProps = {
    url: fileInfos?.previewUrl,
    mimeType: fileInfos?.previewMimeType as MIME_TYPES,
    noPreview: !fileInfos?.fileServiceProviderInfos?.exists
      ? i18next.t('MASK.NO_PREVIEW_DOWNLOAD')
      : i18next.t('MASK.NO_PREVIEW_FOR', {
          type: fileInfos?.downloadMimeType,
        }),
    downloadProps:
      fileInfos?.downloadUrl && filePath
        ? {
            url: fileInfos?.downloadUrl,
            filePath: filePath,
          }
        : undefined,
    fileName: fileInfos?.fileEntityFilename,
    noPreviewUrl:
      fileInfos?.fileServiceProviderInfos?.fspUrl || fileInfos?.downloadUrl,
    fspUrl: fileInfos?.fileServiceProviderInfos?.fspUrl,
    isLinkable: isLinkable,
  };
  if (isLinkable) {
    previewAttrs = {
      ...previewAttrs,
      url: filePath,
      mimeType: MIME_TYPES.urllist,
      downloadProps: undefined,
      fileName: filePath,
    };
  }
  if (isTplCreate) {
    previewAttrs = {
      ...previewAttrs,
      noPreview: i18next.t('MASK.NO_TEMPLATE_PREVIEW'),
      mimeType: basedOnTemplate?.fileInfos?.previewMimeType as MIME_TYPES,
      url: basedOnTemplate?.fileInfos?.previewUrl,
    };
  }

  function renderPreviewHeaderBar() {
    if (maskExtras.type === 'file' && mask.isCreateMode) {
      return (
        <DocumentFileActions
          onFileSelect={(file) => maskExtras.setFiles([file])}
        />
      );
    } else if (isLinkable) {
      return <DocumentLinkActions href={filePath} />;
    }

    return null;
  }

  return (
    <Collapse
      title={t('COMMON.PREVIEW')}
      defaultOpen
      classes={collapseClasses}
      className={styles.collapse}
      headerBar={renderPreviewHeaderBar()}
    >
      <PreviewComp
        {...previewAttrs}
        iconProps={{ showPreviewExternallyIcon: rights.update }}
      />
    </Collapse>
  );
};

function DocumentFileActions({
  onFileSelect,
}: {
  onFileSelect: (file: File) => void;
}) {
  const { t } = useTranslation();

  return (
    <PictureFromFileOrCam
      multiple={false}
      asDataUri={false}
      onPictureTaken={onFileSelect}
      onImageChoosen={onFileSelect}
    >
      {({ chooseFile }) => {
        return (
          <Stack direction="row" justifyContent="flex-end" flex="1">
            <Button
              size="large"
              startIcon={<UploadFile />}
              onClick={chooseFile}
            >
              {t('FILE.createFrom.upload')}
            </Button>
          </Stack>
        );
      }}
    </PictureFromFileOrCam>
  );
}

interface DocumentLinkActionsProps {
  href: string;
}

function DocumentLinkActions(props: DocumentLinkActionsProps) {
  const { href } = props;

  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="flex-end" flex="1">
      <Button size="large" startIcon={<LinkIcon />} href={href} target="_blank">
        {t('MASK.OPEN_LINK')}
      </Button>
    </Stack>
  );
}
