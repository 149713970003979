import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputAdressVerknuepfung<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: sdObjMemberCodeA */
  sdObjMemberCodeA?: number;
  /** Alias: sdObjMemberCodeB */
  sdObjMemberCodeB?: number;
  /** Alias: sdObjTypeA */
  sdObjTypeA?: SdObjType;
  /** Alias: sdObjTypeB */
  sdObjTypeB?: SdObjType;
  /** Alias: ansprechpartnerCodeA */
  ansprechpartnerCodeA?: number;
  /** Alias: ansprechpartnerCodeB */
  ansprechpartnerCodeB?: number;
  /** Alias: verknuepfungCode */
  verknuepfungCode?: number;
  /** Alias: erstellerCode */
  erstellerCode?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputAdressVerknuepfung> = {
  code: {
    alias: 'code',
  },
  sdObjMemberCodeA: {
    alias: 'sdObjMemberCodeA',
  },
  sdObjMemberCodeB: {
    alias: 'sdObjMemberCodeB',
  },
  sdObjTypeA: {
    alias: 'sdObjTypeA',
  },
  sdObjTypeB: {
    alias: 'sdObjTypeB',
  },
  ansprechpartnerCodeA: {
    alias: 'ansprechpartnerCodeA',
  },
  ansprechpartnerCodeB: {
    alias: 'ansprechpartnerCodeB',
  },
  verknuepfungCode: {
    alias: 'verknuepfungCode',
  },
  erstellerCode: {
    alias: 'erstellerCode',
  },
  creationDate: {
    alias: 'creationDate',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAdressVerknuepfungEntityDefinition: EntitiyDefinition<InputAdressVerknuepfung> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAdressVerknuepfung',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
