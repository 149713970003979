import styles from './ProjectPlanningView.module.scss';

import {
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventDragEvent,
} from '@mobiscroll/react';
import { EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uuid } from 'short-uuid';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useDataMutation, useNavigate } from '@work4all/data';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { InputProjectStepRelation } from '@work4all/models/lib/Classes/InputProjectStepRelation.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { ProjectPlanningPageSearchParamKeys } from '../../../../../../../containers/mask-overlays/mask-overlay/views/project-planning/ProjectPlanningPage';
import { ResourcesTimeline } from '../resources-timeline/ResourcesTimeline';
import { ZoomableTimeline } from '../zoomable-timeline/ZoomableTimeline';

import { ProjectProcessQuickCreateDialog } from './components/project-process-quick-create-dialog/ProjectProcessQuickCreateDialog';
import { ProjectProcessTemplate } from './components/project-process-quick-create-dialog/types';
import { PROJECT_PROCESS_DATA } from './components/project-process-quick-create-dialog/utils';
import { TimeUnit } from './components/time-unit-switch/TimeUnitSwitch';
import { ZoomValue } from './components/timeline-zoom/TimelineZoom';
import { Toolbar } from './components/toolbar/Toolbar';
import { useConnections } from './hooks/use-connections';
import { useEvents } from './hooks/use-events';
import { useProjectWithProcesses } from './hooks/use-project-with-processes';
import { useRenderResource } from './hooks/use-render-resource';
import { useRenderResourceHeader } from './hooks/use-render-resource-header';
import { useResources } from './hooks/use-resources';
import { useSyncTimelines } from './hooks/use-sync-timelines';
import { ProjectProcessColumn } from './types';
import {
  DEFAULT_LEFT_AREA_COLS,
  mergePersistedProjectProcessCols,
} from './utils';

interface IProps {
  projectIdList: number[];
  timeUnit?: TimeUnit;
  zoom?: ZoomValue;
  leftAreaCollapsed?: boolean;
  leftAreaColumns?: ProjectProcessColumn[];
  showToolbar?: boolean;
  showAddAction?: boolean;
  showPrintAction?: boolean;
  showDateSelector?: boolean;
  showZoomControl?: boolean;
  showResourceFilter?: boolean;
  showTimeUnitControl?: boolean;
  showFullscreenAction?: boolean;
  showLayoutSelector?: boolean;
  onProjectIdsChange?: (ids: number[]) => void;
  layout?: 'gantt' | 'stacked';
}

export const ProjectPlanningView = (props: IProps) => {
  const {
    projectIdList: initialProjectIdList,
    timeUnit: initialTimeUnit = 'year',
    zoom: initialZoom = 3,
    leftAreaCollapsed: initialLeftAreaCollapsed = false,
    leftAreaColumns: initialLeftAreaColumns = DEFAULT_LEFT_AREA_COLS,
    showAddAction = true,
    showDateSelector = true,
    showFullscreenAction = true,
    showLayoutSelector = true,
    layout = 'gantt',
    showPrintAction = true,
    showTimeUnitControl = true,
    showToolbar = true,
    showZoomControl = true,
    showResourceFilter = true,
    onProjectIdsChange,
  } = props;

  const [bottomSectionHeight, setBottomSectionHeight] = useState(300);
  const [bottomSectionCollapsed, setBottomSectionCollapsed] = useState(true);
  const compactView = useMemo(() => {
    return layout === 'stacked';
  }, [layout]);
  const [projectIdList, setProjectIdList] = useState(initialProjectIdList);
  const [unit, setUnit] = useState(initialTimeUnit);
  const [zoom, setZoom] = useState(initialZoom);
  const [leftAreaCollapsed, setLeftAreaCollapsed] = useState(
    initialLeftAreaCollapsed
  );
  const [leftAreaColumns, setLeftAreaColumns] = useState(
    mergePersistedProjectProcessCols(initialLeftAreaColumns)
  );
  const [date, setDate] = useState(new Date());
  const [resourceClasses, setResourceClasses] = useState<LookUp[]>([]);
  const [ganttInst, setGanttInst] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const { data: projectList, refetch } = useProjectWithProcesses(projectIdList);

  const ganttTimelineClass = useMemo(() => `timeline-${uuid()}`, []);
  const resourceTimelineClass = useMemo(() => `timeline-${uuid()}`, []);

  const syncedTimelineProps = useSyncTimelines(
    ganttTimelineClass,
    resourceTimelineClass
  );

  const [maxEventStack, setMaxEventStack] = useState(3);
  const [showConnections, setShowConnections] = useState(true);

  const view = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        type: unit,
        startDay: 1,
        endDay: 0,
        eventList: true,
        weekNumbers: true,
        maxEventStack,
      },
    }),
    [maxEventStack, unit]
  );

  const [mutate] = useDataMutation<
    ProjectProcess,
    EMode.upsert,
    InputProjectStepRelation
  >({
    entity: Entities.projectProcess,
    mutationType: EMode.upsert,
    responseData: PROJECT_PROCESS_DATA,
  });

  const events = useEvents({
    projectList,
    compact: compactView,
    resourceClassIdList: resourceClasses.map((x) => x.id),
  });

  const resources = useResources({
    projectList,
    compact: compactView,
    resourceClassIdList: resourceClasses.map((x) => x.id),
    eventList: events || [],
  });
  const connections = useConnections({ projectList });

  const printView = () => {
    ganttInst.print();
  };
  const projectIdsChanged = useRef(true);

  useEffect(() => {
    setZoom(initialZoom);
  }, [initialZoom]);

  useEffect(() => {
    setUnit(initialTimeUnit);
  }, [initialTimeUnit]);

  useEffect(() => {
    projectIdsChanged.current = true;
  }, [projectIdList]);

  useEffect(() => {
    if (!projectIdsChanged.current || events.length === 0) {
      return;
    }
    const newStartDate = events
      ?.filter((x) => x.start && !x.children)
      .sort((a, b) => {
        return a.start > b.start ? 1 : -1;
      })?.[0]?.start;
    if (
      newStartDate instanceof Date &&
      !isNaN(newStartDate as unknown as number)
    )
      setDate(newStartDate as Date);
    projectIdsChanged.current = false;
  }, [events]);

  const { t } = useTranslation();

  const renderResource = useRenderResource({
    onDateChange: setDate,
    leftAreaColumns,
    onProjectRemoved: (projectId: number) => {
      const ids = projectIdList.filter((x) => x !== projectId);
      setProjectIdList(ids);
      onProjectIdsChange?.(ids);
    },
  });

  const renderResourceHeader = useRenderResourceHeader({
    leftAreaColumns,
    onLeftAreaColumnsChanged: setLeftAreaColumns,
    leftAreaCollapsed,
    onLeftAreaCollapsedChanged: setLeftAreaCollapsed,
    onProjectSelected: (project) => {
      if (project) {
        const ids = [...projectIdList, project.id];
        setProjectIdList(ids);
        onProjectIdsChange?.(ids);
      }
    },
    showTableHeaders: !compactView,
  });

  const leftAreaWidth = useMemo(() => {
    const leftOffset = remToPx(5);
    return Math.round(
      leftAreaColumns
        .filter((x) => !x.hidden)
        .map((x) => x.width)
        .reduce((a, b) => a + b) + leftOffset
    );
  }, [leftAreaColumns]);

  const overlayInitialView = useMemo<IStackItem>(() => {
    return {
      view: (
        <ProjectPlanningViewOverlayController
          {...props}
          projectIdList={projectIdList}
          leftAreaCollapsed={leftAreaCollapsed}
          leftAreaColumns={leftAreaColumns}
          timeUnit={unit}
          zoom={zoom}
        />
      ),
      title: t('COMMON.PROJECT_PLANNING'),
    };
  }, [leftAreaCollapsed, leftAreaColumns, projectIdList, props, t, unit, zoom]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [projectProcessTemplate, setProjectProcessTemplate] =
    useState<ProjectProcessTemplate>(null);
  const [projectProcessId, setProjectProcessId] = useState<number>(null);

  const openEventDialog = useCallback((e: MbscEventClickEvent) => {
    const event = e.event;
    setProjectProcessId(parseInt(event.id.toString()));
    setDialogOpen(true);
  }, []);

  const handleEventDrag = useCallback(
    async (args: MbscEventDragEvent, inst: EventcalendarBase) => {
      const { event } = args;

      /**
       * Check if new event was created
       */
      if (event.id.toString().startsWith('mbsc')) {
        const process = projectList
          .find((p) =>
            p.projectProcessList.find((pp) => pp.id === event.resource)
          )
          ?.projectProcessList.find((pp) => pp.id === event.resource);

        if (!process) {
          return;
        }

        setProjectProcessTemplate({
          projectId: process.projectId,
          project: process.project,
          kindId: ProjectProcessKind.VORGANG,
          startDatum: DateTime.fromJSDate(event.start as Date)
            .startOf('day')
            .toISO(),
          endDateInner: DateTime.fromJSDate(event.end as Date)
            .startOf('day')
            .toISO(),
          parent: process.parent,
          parentId: process.parentId,
        });
        setDialogOpen(true);
        inst.removeEvent(event.id);
      } else {
        /**
         * Else mutate existing event
         */
        await mutate(
          {
            id: event.id,
            startDatum: DateTime.fromJSDate(event.start as Date).toISO(),
            endDateInner: DateTime.fromJSDate(event.end as Date)
              .startOf('day')
              .toISO(),
            __typename: undefined,
          } as ProjectProcess,
          {
            relations: {},
          }
        );

        refetch();
      }
    },
    [mutate, projectList, refetch]
  );

  const handleSelectedDateChange = useCallback((args: { date: Date }) => {
    setDate(args.date);
  }, []);

  const navigate = useNavigate();

  const handleFullscreenClick = useCallback(() => {
    navigate(
      `/more/project-planning?layout=${layout}&${
        ProjectPlanningPageSearchParamKeys.projectIds
      }=${projectIdList.join(',')}`
    );
  }, [layout, navigate, projectIdList]);

  const [selectedEvents, setSelectedEvents] = useState<MbscCalendarEvent[]>([]);

  const gantt = useMemo(() => {
    // return null
    return (
      <ZoomableTimeline
        clickToCreate={false}
        dragToCreate={true}
        dragToMove={true}
        dragBetweenResources={false}
        dragInTime={true}
        dragToResize={true}
        eventDelete={false}
        showControls={false}
        date={date}
        onSelectedDateChange={(e) => {
          e && handleSelectedDateChange({ date: e.date as Date });
        }}
        componentRef={setGanttInst}
        onEventDoubleClick={openEventDialog}
        onEventDragEnd={handleEventDrag}
        resources={resources}
        events={events}
        view={view}
        renderResource={renderResource}
        renderResourceHeader={renderResourceHeader}
        connections={!compactView && showConnections ? connections : []}
        zoom={zoom}
        leftAreaWidth={leftAreaWidth}
        leftAreaCollapsed={leftAreaCollapsed}
        externalDrag
        className={ganttTimelineClass}
        onEventClick={(e) => {
          setSelectedEvents([e.event]);
        }}
        {...syncedTimelineProps.first}
      />
    );
  }, [
    compactView,
    connections,
    date,
    events,
    handleEventDrag,
    handleSelectedDateChange,
    leftAreaCollapsed,
    leftAreaWidth,
    openEventDialog,
    renderResource,
    renderResourceHeader,
    resources,
    syncedTimelineProps.first,
    view,
    zoom,
    showConnections,
    ganttTimelineClass,
  ]);

  const resourceTimeline = useMemo(() => {
    return (
      <ResourcesTimeline
        view={view}
        date={date}
        leftAreaCollapsed={leftAreaCollapsed}
        leftAreaWidth={leftAreaWidth}
        zoom={zoom}
        className={resourceTimelineClass}
        highlightedDateRanges={selectedEvents.map((x) => ({
          start: new Date((x.process as ProjectProcess).startDatum),
          end: new Date((x.process as ProjectProcess).endDateInner),
        }))}
        {...syncedTimelineProps.second}
      />
    );
  }, [
    view,
    date,
    leftAreaCollapsed,
    leftAreaWidth,
    zoom,
    selectedEvents,
    syncedTimelineProps.second,
    resourceTimelineClass,
  ]);

  return (
    <>
      {dialogOpen && (
        <ProjectProcessQuickCreateDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setProjectProcessId(null);
            refetch();
          }}
          onOpenMask={() => null}
          template={projectProcessTemplate}
          id={projectProcessId}
        />
      )}
      {overlayOpen && (
        <NavigationOverlay
          initialView={overlayInitialView}
          open={overlayOpen}
          close={() => {
            setOverlayOpen(false);
          }}
          withBreadcrumbs
          classes={{
            wrapperSmall: styles.wrapperFullscreen,
          }}
        />
      )}
      {showToolbar && (
        <Toolbar
          date={date}
          unit={unit}
          zoom={zoom}
          onAddProcessClick={() => {
            setProjectProcessTemplate({
              projectId: projectList?.[0]?.id,
              project: projectList?.[0],
              kindId: ProjectProcessKind.VORGANG,
            });
            setDialogOpen(true);
          }}
          onDateChange={(start) => handleSelectedDateChange({ date: start })}
          showAddAction={projectList.length ? showAddAction : false}
          onFullscreenBtnClick={handleFullscreenClick}
          onPrintClick={printView}
          onTimeUnitChange={setUnit}
          onZoomChange={setZoom}
          showDateSelector={showDateSelector}
          showFullscreenAction={showFullscreenAction}
          showPrintAction={showPrintAction}
          showTimeUnitControl={showTimeUnitControl}
          showZoomControl={showZoomControl}
          resourceClasses={resourceClasses}
          showResourceFilter={showResourceFilter}
          onRessourceClassesChange={setResourceClasses}
          onConnectionToggleChange={setShowConnections}
          maxEventStack={maxEventStack}
          onMaxEventStackChange={setMaxEventStack}
          showConnections={showConnections}
          showConnectionToggle={!compactView}
          showMaxEventStackSelector={compactView}
        />
      )}
      <Stack direction="row" height="100%" overflow="hidden">
        {leftAreaCollapsed && (
          <Box
            borderTop="1px solid var(--ui04)"
            borderRight="1px solid var(--ui04)"
          >
            <ExpandButton
              icon={leftAreaCollapsed ? <ChevronRight /> : <ChevronLeft />}
              textStart="top"
              title={t('COMMON.DETAILS')}
              color="text03"
              onClick={() => setLeftAreaCollapsed(!leftAreaCollapsed)}
            />
          </Box>
        )}
        <Stack height="100%" width="100%">
          {gantt}
          {bottomSectionCollapsed ? (
            <BottomTabs
              collapsed={bottomSectionCollapsed}
              onCollapsedChanged={setBottomSectionCollapsed}
            />
          ) : (
            <Box flexGrow={1}>
              <ResizableArea
                handles={'top'}
                size={{ height: bottomSectionHeight }}
                direction="vertical"
                onResize={(e) => setBottomSectionHeight(e.height)}
              >
                <Stack height="100%" minHeight={bottomSectionHeight}>
                  <BottomTabs
                    collapsed={bottomSectionCollapsed}
                    onCollapsedChanged={setBottomSectionCollapsed}
                  />
                  {resourceTimeline}
                </Stack>
              </ResizableArea>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export const BottomTabs = (props: {
  collapsed: boolean;
  onCollapsedChanged: (collapsed: boolean) => void;
}) => {
  const { collapsed, onCollapsedChanged } = props;
  const { t } = useTranslation();
  return (
    <Stack direction="row" padding="0.5rem 0">
      <IconButton
        onClick={() => {
          onCollapsedChanged(!collapsed);
        }}
      >
        {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      <Tabs
        value={collapsed ? false : 'resources'}
        onChange={() => {
          onCollapsedChanged(false);
        }}
      >
        <Tab value={'resources'} label={t('COMMON.RESOURCES')} />;
      </Tabs>
    </Stack>
  );
};

export const ProjectPlanningViewOverlayController = (props: IProps) => {
  return (
    <ProjectPlanningView
      {...props}
      showFullscreenAction={false}
      showAddAction
      showDateSelector
      showPrintAction
      showTimeUnitControl
      showToolbar
      showZoomControl
      showLayoutSelector
      showResourceFilter
      leftAreaCollapsed={false}
    />
  );
};
