import { ChevronRight } from '@mui/icons-material';

import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';

import { useCollapsedAreas } from '../../../../hooks';
import { ControlViewLeftCollapseControllerProps } from '../types';

export const ControlViewLeftCollapseController = ({
  title,
  entity,
  children,
  collapsible,
}: ControlViewLeftCollapseControllerProps) => {
  const { isCollapsed, toggleCollapse } = useCollapsedAreas({
    entity,
    area: 'left',
  });

  if (collapsible && isCollapsed) {
    return (
      <ExpandButton
        icon={<ChevronRight />}
        textStart="top"
        title={title}
        color="text03"
        onClick={toggleCollapse}
      />
    );
  }

  return children;
};
