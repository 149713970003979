import Ajv, { RequiredParams, TypeParams, ValidateFunction } from 'ajv';
import localizeDE from 'ajv-i18n/localize/de';
import localizeEN from 'ajv-i18n/localize/en';
import { set } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ajv = new Ajv({ allErrors: true });

export type FieldToErrorMap = {
  [field: string]: { message: string; type: string };
};

export const useJSONSchemaResolver = (
  jsonSchema,
  customValidationRules: (data: unknown) => true | FieldToErrorMap = () => true
) => {
  const validateRef = useRef<ValidateFunction>();
  const schemaRef = useRef(jsonSchema);
  schemaRef.current = jsonSchema;

  const { i18n, t } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    if (jsonSchema) {
      try {
        validateRef.current = ajv.compile(jsonSchema);
      } catch (e) {
        validateRef.current = undefined;
        console.error(e);
      }
    } else {
      validateRef.current = undefined;
    }
  }, [jsonSchema]);

  const resolver = useCallback(
    async (data) => {
      const validate = validateRef.current;

      //we do null all empty strings as this allows us to send and validate date-times properly
      Object.keys(data).forEach((key) => {
        if (schemaRef.current?.properties[key]?.format === 'date-time') {
          data[key] = data[key] === '' ? null : data[key];
        }
      });
      const customValidationResult = customValidationRules(data);
      //no validate function implies everything is allowed?
      if ((!validate || validate(data)) && customValidationResult === true) {
        return {
          values: data,
          errors: {},
        };
      } else {
        const errors = validate?.errors || [];
        const customErrors =
          customValidationResult !== true ? { ...customValidationResult } : {};

        if (language === 'de') {
          localizeDE(errors);
        } else {
          localizeEN(errors);
        }

        const errs = errors.reduce((allErrors, currentError) => {
          let dataPath = currentError.dataPath.slice(1);
          if (currentError.keyword === 'required') {
            //as ive learned, required is an error on the object level and therfore jut implicitly attributed to the field
            //RequiredParams
            dataPath = (currentError.params as RequiredParams).missingProperty;
          }

          let customMessage: string;

          if (
            currentError.keyword === 'type' &&
            (currentError.params as TypeParams).type === 'object'
          ) {
            customMessage = t('ERROR.FIELD_REQUIRED');
          }

          set(allErrors, dataPath, {
            type: currentError.keyword ?? 'validation',
            message:
              customMessage ??
              currentError.message.replace(/^\w/, (c) => c.toUpperCase()),
          });

          return allErrors;
        }, customErrors);

        console.debug('Form validation errors:', errs);

        return {
          values: {},
          errors: errs,
        };
      }
    },
    [customValidationRules, t, language]
  );

  return resolver;
};
