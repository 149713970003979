import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EInvoiceFormat } from '../Enums/EInvoiceFormat.enum';
import { EMode } from '../Enums/EMode.enum';

export class InvoiceToOutgoingInvoiceLedgerRequest<
  T extends EMode = EMode.entity
> {
  /** Alias: invoiceCode */
  invoiceCode?: any;
  /** Alias: createArchivePdf */
  createArchivePdf?: boolean;
  /** Alias: awaitArchivePdf */
  awaitArchivePdf?: boolean;
  /** Alias: convertAlsoWithoutValidLedgerAssignments */
  convertAlsoWithoutValidLedgerAssignments?: boolean;
  /** Alias: archivePdfReportCode */
  archivePdfReportCode?: any;
  /** Alias: createEInvoice */
  createEInvoice?: boolean;
  /** Alias: eInvoiceFormat */
  eInvoiceFormat?: EInvoiceFormat;

  __typename?: string;
}

const fields: FieldDefinitions<InvoiceToOutgoingInvoiceLedgerRequest> = {
  invoiceCode: {
    alias: 'invoiceCode',
  },
  createArchivePdf: {
    alias: 'createArchivePdf',
  },
  awaitArchivePdf: {
    alias: 'awaitArchivePdf',
  },
  convertAlsoWithoutValidLedgerAssignments: {
    alias: 'convertAlsoWithoutValidLedgerAssignments',
  },
  archivePdfReportCode: {
    alias: 'archivePdfReportCode',
  },
  createEInvoice: {
    alias: 'createEInvoice',
  },
  eInvoiceFormat: {
    alias: 'eInvoiceFormat',
  },
  __typename: {
    alias: '__typename',
  },
};

export const invoiceToOutgoingInvoiceLedgerRequestEntityDefinition: EntitiyDefinition<InvoiceToOutgoingInvoiceLedgerRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InvoiceToOutgoingInvoiceLedgerRequest',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
