import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ICardWidgetRenderCellParams } from '@work4all/components/lib/dataDisplay/card-widget';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';

type Props = ICardWidgetRenderCellParams<
  InboundInvoice,
  RELedgerAccountSplit[]
>;

const COMMON_STYLE = {
  width: '32px',
};

export const BookingsTotalAmountPercentage = ({
  value: bookings = [],
  fileId,
}: Props) => {
  const { t } = useTranslation();

  const allBookingsTotalAmount = bookings.reduce(
    (accumulator, booking) => accumulator + booking.proportionDM,
    0
  );

  const projectBookingsTotalAmount = bookings
    .filter((booking) => booking.projectId === fileId)
    .reduce((accumulator, booking) => accumulator + booking.proportionDM, 0);

  if (allBookingsTotalAmount === projectBookingsTotalAmount) {
    return <div style={COMMON_STYLE} />;
  }

  const percentage =
    (projectBookingsTotalAmount / allBookingsTotalAmount) * 100 ?? 0;

  const rounderPercentage = Math.round(percentage);

  return (
    <Tooltip title={t('TOOLTIP.SPLITTED_INVOICE')}>
      <div
        style={{
          ...COMMON_STYLE,
          backgroundColor: 'var(--ui05)',
          borderRadius: '3px',
          color: 'var(--textInverse)',
          textAlign: 'center',
        }}
      >
        {rounderPercentage}%
      </div>
    </Tooltip>
  );
};
