export enum ConvertInvoiceToRaStatuses {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  KEINE_POSITION = 'KEINE_POSITION',
  POSITION_OHNE_SACHKONTO_GEFUNDEN = 'POSITION_OHNE_SACHKONTO_GEFUNDEN',
  RECHNUNG_IST_BEREITS_IM_RECHNUNGSAUSGANGSBUCH = 'RECHNUNG_IST_BEREITS_IM_RECHNUNGSAUSGANGSBUCH',
  FEHLER_BEIM_PDF_ERSTELLEN = 'FEHLER_BEIM_PDF_ERSTELLEN',
  FEHLER_FREIGABE = 'FEHLER_FREIGABE',
  SUMME_NULL = 'SUMME_NULL',
  ZUGFERD_BENOETIGT_ARCHIV_PDF = 'ZUGFERD_BENOETIGT_ARCHIV_PDF',
  RECHNUNG_EXISTIERT_NICHT = 'RECHNUNG_EXISTIERT_NICHT',
}
