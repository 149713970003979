import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EntityPickerTab,
  EntityPickerTabBar,
  FilterTextInput,
} from '@work4all/components/lib/components/entity-picker/components';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import {
  SelectableTree,
  TreeNode,
} from '@work4all/components/lib/dataDisplay/tree/SelectableTree';

import { useDataProvider } from '@work4all/data';

import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDebounce } from '@work4all/utils/lib/hooks/use-debounce';

export interface UserCompilationPickerProps {
  value: User[];
  onChange: (users: User[]) => void;
  autoFocusTextInput?: boolean;
  hiddenTabs?: UserCompilationPickerTab[];
  allowedUserIds?: number[];
}

export type UserCompilationPickerTab =
  | 'employees'
  | 'departments'
  | 'resources'
  | 'compilations';

export function UserCompilationPicker(props: UserCompilationPickerProps) {
  const {
    value,
    onChange,
    hiddenTabs = [],
    autoFocusTextInput,
    allowedUserIds = [],
  } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.trim(), 200);

  const [activeTab, setActiveTab] =
    useState<UserCompilationPickerTab>('employees');

  function renderActiveTab() {
    switch (activeTab) {
      case 'employees':
        return (
          <Employees
            value={value}
            onChange={onChange}
            search={debouncedSearch}
            allowedIds={allowedUserIds}
          />
        );

      case 'departments':
        return (
          <Departments
            value={value}
            onChange={onChange}
            search={debouncedSearch}
          />
        );

      case 'resources':
        return (
          <Resources
            value={value}
            onChange={onChange}
            search={debouncedSearch}
          />
        );

      default:
        return null;
    }
  }

  return (
    <Stack direction="column" height="100%" padding={'0 .5rem'}>
      <Box padding="0.5rem" flex="none">
        <FilterTextInput
          autoFocus={autoFocusTextInput}
          value={search}
          onChange={setSearch}
          placeholder={t('PICKER.SEARCH.DEFAULT')}
        />
      </Box>

      <Box flex="none">
        <EntityPickerTabBar
          value={activeTab}
          onChange={(val) => setActiveTab(val)}
        >
          {!hiddenTabs.includes('employees') && (
            <EntityPickerTab value={'employees'}>
              {t('COMMON.EMPLOYEE')}
            </EntityPickerTab>
          )}
          {!hiddenTabs.includes('departments') && (
            <EntityPickerTab value={'departments'}>
              {t('MASK.DEPARTMENTS')}
            </EntityPickerTab>
          )}
          {!hiddenTabs.includes('resources') && (
            <EntityPickerTab value={'resources'}>
              {t('COMMON.RESOURCES')}
            </EntityPickerTab>
          )}
          {/* {!hiddenTabs.includes('compilations') && (
            <EntityPickerTab value={'compilations'}>
              Zusammenstellungen
            </EntityPickerTab>
          )} */}
        </EntityPickerTabBar>
      </Box>

      <Box flex={1} overflow="auto">
        {renderActiveTab()}
      </Box>
    </Stack>
  );
}

function Employees({
  search,
  value,
  onChange,
  allowedIds,
}: {
  search: string;
  value: User[];
  onChange: (users: User[]) => void;
  allowedIds?: number[];
}) {
  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactUnionWrapper,
      data: {
        id: null,
        contactKind: null,
        data: {
          user: {
            id: null,
            displayName: null,
            lastName: null,
            firstName: null,
            eMail: null,
            userKind: null,
            loginName: null,
            departmentName: null,
          },
        },
      } as ContactUnionWrapper<EMode.query>,
      completeDataResponse: true,
      operationName: 'GetAllEmployees',
      vars: {
        searchValue: '%' + search.split(' ').join('%') + '%',
        maxRows: 9999,
        contactTypes: [ContactKind.MITARBEITER, ContactKind.BENUTZER],
      },
    };
  }, [search]);

  const response = useDataProvider<ContactUnionWrapper>(request);

  const users = response.data
    .map((contactUnionWrapper) => {
      return contactUnionWrapper.data as User;
    })
    .filter((x) => (allowedIds?.length ? allowedIds?.includes(x.id) : true))
    .sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

  const selectedIds = new Set(value.map((user) => user.id));

  return (
    <List>
      {users.map((user) => {
        const userId = user.id;

        const isSelected = selectedIds.has(userId);

        const handleToggle = () => {
          if (isSelected) {
            onChange(value.filter((user) => user.id !== userId));
          } else {
            onChange([...value, user]);
          }
        };

        return (
          <ListItem key={userId} disablePadding>
            <ListItemButton
              dense
              role={undefined}
              onClick={() => handleToggle()}
              selected={isSelected}
              style={{ height: '2.25rem' }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isSelected}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>

              <Stack
                alignItems="center"
                direction="row"
                overflow="hidden"
                gap="0.5rem"
                flex={1}
              >
                <ListItemAvatar>
                  <HookedUserIcon userId={user.id} size="m" />
                </ListItemAvatar>

                <Typography noWrap>{user.displayName}</Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

function Departments({
  search,
  value,
  onChange,
}: {
  search: string;
  value: User[];
  onChange: (users: User[]) => void;
}) {
  const { t } = useTranslation();
  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactUnionWrapper,
      data: {
        id: null,
        contactKind: null,
        data: {
          user: {
            id: null,
            displayName: null,
            lastName: null,
            firstName: null,
            eMail: null,
            userKind: null,
            loginName: null,
            departmentName: null,
          },
        },
      } as ContactUnionWrapper<EMode.query>,
      completeDataResponse: true,
      operationName: 'GetAllUsersWithDepartmentNames',
      vars: {
        searchValue: '%' + search.split(' ').join('%') + '%',
        maxRows: 9999,
        contactTypes: [ContactKind.MITARBEITER, ContactKind.BENUTZER],
      },
    };
  }, [search]);

  const response = useDataProvider<ContactUnionWrapper>(request);

  const { data: contactUnionWrappers } = response;

  const { treeNodes, userById, usersByDepartmentId } = useMemo(() => {
    const userById = new Map<string, User>();
    const departmentById = new Map<string, string>();
    const usersByDepartmentId = new Map<string, string[]>();

    if (!contactUnionWrappers) {
      return {
        treeData: [],
        groupById: userById,
        groupsByParentId: usersByDepartmentId,
      };
    }

    for (const contactUnionWrapper of contactUnionWrappers) {
      if (contactUnionWrapper.data.__typename !== 'Benutzer') {
        throw new Error(
          `Unexpected entity type ${contactUnionWrapper.data.__typename}`
        );
      }

      const user = contactUnionWrapper.data as User;

      const userNodeId = getUserNodeId(user);
      const departmentNodeId = getDepartmentNodeId(user);

      userById.set(userNodeId, user);
      departmentById.set(departmentNodeId, user.departmentName);

      if (!usersByDepartmentId.has(departmentNodeId)) {
        usersByDepartmentId.set(departmentNodeId, []);
      }

      usersByDepartmentId.get(departmentNodeId).push(userNodeId);
    }

    const compareDepartmentsByName = (a: string, b: string) => {
      return a.localeCompare(b);
    };

    const compareUsersByName = (a: User, b: User) => {
      return a.displayName.localeCompare(b.displayName);
    };

    function createDepartmentNode(
      departmentNodeId: string,
      departmentName: string
    ): TreeNode {
      const departmentUsers = usersByDepartmentId.get(departmentNodeId);

      const treeItem: TreeNode = {
        id: departmentNodeId,
        label: departmentName || t('COMMON.NO_LABEL'),
        children: departmentUsers
          .map((userNodeId) => {
            const user = userById.get(userNodeId);
            return [userNodeId, user] as const;
          })
          .sort(([, a], [, b]) => compareUsersByName(a, b))
          .map(([userNodeId, user]) => {
            return createUserNode(userNodeId, user);
          }),
      };

      return treeItem;
    }

    function createUserNode(userNodeId: string, user: User): TreeNode {
      const userNode: TreeNode = {
        id: userNodeId,
        label: (
          <Stack direction="row" alignItems="center" gap="0.5rem">
            <HookedUserIcon size="l" userId={user.id} />
            <Typography variant="body1" noWrap>
              {user.displayName}
            </Typography>
          </Stack>
        ),
      };

      return userNode;
    }

    const treeNodes: TreeNode[] = [...departmentById]
      .sort(([, a], [, b]) => compareDepartmentsByName(a, b))
      .map(([departmentNodeId, departmentName]) => {
        return createDepartmentNode(departmentNodeId, departmentName);
      });

    return {
      treeNodes,
      userById,
      departmentById,
      usersByDepartmentId,
    };
  }, [contactUnionWrappers]);

  const selected = useMemo<string[]>(() => {
    const set = new Set<string>();

    for (const user of value) {
      set.add(getUserNodeId(user));
    }

    const uniqueDepartmentNodeIds = new Set(value.map(getDepartmentNodeId));

    for (const departmentNodeId of uniqueDepartmentNodeIds) {
      const departmentUserNodeIds = usersByDepartmentId.get(departmentNodeId);

      if (departmentUserNodeIds) {
        const isEveryDepartmentUserSelected = departmentUserNodeIds.every(
          (userNodeId) => set.has(userNodeId)
        );

        if (isEveryDepartmentUserSelected) {
          set.add(departmentNodeId);
        }
      }
    }

    return [...set];
  }, [usersByDepartmentId, value]);

  function handleChange(ids: string[]) {
    const newValue = [...value];

    const added = ids.filter((id) => !selected.includes(id));
    const removed = selected.filter((id) => !ids.includes(id));

    const removeUserByNodeId = (userNodeId: string) => {
      const user = userById.get(userNodeId);

      if (user) {
        const index = newValue.findIndex((it) => it.id === user.id);

        if (index !== -1) {
          newValue.splice(index, 1);
        }
      }
    };

    const addUserByNodeId = (userNodeId: string) => {
      const user = userById.get(userNodeId);

      if (user) {
        if (!newValue.find((it) => it.id === user.id)) {
          newValue.push(user);
        }
      }
    };

    for (const id of removed) {
      if (isDepartmentNodeId(id)) {
        const userNodeIds = usersByDepartmentId.get(id);
        userNodeIds.forEach((userNodeId) => removeUserByNodeId(userNodeId));
      } else {
        removeUserByNodeId(id);
      }
    }

    for (const id of added) {
      if (isDepartmentNodeId(id)) {
        const userNodeIds = usersByDepartmentId.get(id);
        userNodeIds.forEach((userNodeId) => addUserByNodeId(userNodeId));
      } else {
        addUserByNodeId(id);
      }
    }

    onChange(newValue);
  }

  return (
    <SelectableTree
      multiple
      selectable="all"
      data={treeNodes}
      selected={selected}
      onChange={handleChange}
    />
  );
}

function Resources({
  search,
  value,
  onChange,
}: {
  search: string;
  value: User[];
  onChange: (users: User[]) => void;
}) {
  const { t } = useTranslation();
  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactUnionWrapper,
      data: {
        id: null,
        contactKind: null,
        data: {
          user: {
            id: null,
            displayName: null,
            lastName: null,
            firstName: null,
            eMail: null,
            userKind: null,
            loginName: null,
            departmentName: null,
            shortName: null,
          },
        },
      } as ContactUnionWrapper<EMode.query>,
      completeDataResponse: true,
      operationName: 'GetAllUsersWithDepartmentNames',
      vars: {
        searchValue: '%' + search.split(' ').join('%') + '%',
        maxRows: 9999,
        contactTypes: [ContactKind.RESSOURCE],
      },
    };
  }, [search]);

  const response = useDataProvider<ContactUnionWrapper>(request);

  const { data: contactUnionWrappers } = response;

  const { treeNodes, userById, usersByDepartmentId } = useMemo(() => {
    const userById = new Map<string, User>();
    const departmentById = new Map<string, string>();
    const usersByDepartmentId = new Map<string, string[]>();

    if (!contactUnionWrappers) {
      return {
        treeData: [],
        groupById: userById,
        groupsByParentId: usersByDepartmentId,
      };
    }

    for (const contactUnionWrapper of contactUnionWrappers) {
      if (contactUnionWrapper.data.__typename !== 'Benutzer') {
        throw new Error(
          `Unexpected entity type ${contactUnionWrapper.data.__typename}`
        );
      }

      const user = contactUnionWrapper.data as User;

      const userNodeId = getUserNodeId(user);
      const departmentNodeId = getDepartmentNodeId(user);

      userById.set(userNodeId, user);
      departmentById.set(departmentNodeId, user.departmentName);

      if (!usersByDepartmentId.has(departmentNodeId)) {
        usersByDepartmentId.set(departmentNodeId, []);
      }

      usersByDepartmentId.get(departmentNodeId).push(userNodeId);
    }

    const compareDepartmentsByName = (a: string, b: string) => {
      return a.localeCompare(b);
    };

    const compareUsersByName = (a: User, b: User) => {
      return a.displayName.localeCompare(b.displayName);
    };

    function createDepartmentNode(
      departmentNodeId: string,
      departmentName: string
    ): TreeNode {
      const departmentUsers = usersByDepartmentId.get(departmentNodeId);

      const treeItem: TreeNode = {
        id: departmentNodeId,
        label: departmentName || t('COMMON.NO_LABEL'),
        children: departmentUsers
          .map((userNodeId) => {
            const user = userById.get(userNodeId);
            return [userNodeId, user] as const;
          })
          .sort(([, a], [, b]) => compareUsersByName(a, b))
          .map(([userNodeId, user]) => {
            return createUserNode(userNodeId, user);
          }),
      };

      return treeItem;
    }

    function createUserNode(userNodeId: string, user: User): TreeNode {
      const userNode: TreeNode = {
        id: userNodeId,
        label: user.displayName || user.loginName,
      };

      return userNode;
    }

    const treeNodes: TreeNode[] = [...departmentById]
      .sort(([, a], [, b]) => compareDepartmentsByName(a, b))
      .map(([departmentNodeId, departmentName]) => {
        return createDepartmentNode(departmentNodeId, departmentName);
      });

    return {
      treeNodes,
      userById,
      departmentById,
      usersByDepartmentId,
    };
  }, [contactUnionWrappers]);

  const selected = useMemo<string[]>(() => {
    const set = new Set<string>();

    for (const user of value) {
      set.add(getUserNodeId(user));
    }

    const uniqueDepartmentNodeIds = new Set(value.map(getDepartmentNodeId));

    for (const departmentNodeId of uniqueDepartmentNodeIds) {
      const departmentUserNodeIds = usersByDepartmentId.get(departmentNodeId);

      if (departmentUserNodeIds) {
        const isEveryDepartmentUserSelected = departmentUserNodeIds.every(
          (userNodeId) => set.has(userNodeId)
        );

        if (isEveryDepartmentUserSelected) {
          set.add(departmentNodeId);
        }
      }
    }

    return [...set];
  }, [usersByDepartmentId, value]);

  function handleChange(ids: string[]) {
    const newValue = [...value];

    const added = ids.filter((id) => !selected.includes(id));
    const removed = selected.filter((id) => !ids.includes(id));

    const removeUserByNodeId = (userNodeId: string) => {
      const user = userById.get(userNodeId);

      if (user) {
        const index = newValue.findIndex((it) => it.id === user.id);

        if (index !== -1) {
          newValue.splice(index, 1);
        }
      }
    };

    const addUserByNodeId = (userNodeId: string) => {
      const user = userById.get(userNodeId);

      if (user) {
        if (!newValue.find((it) => it.id === user.id)) {
          newValue.push(user);
        }
      }
    };

    for (const id of removed) {
      if (isDepartmentNodeId(id)) {
        const userNodeIds = usersByDepartmentId.get(id);
        userNodeIds.forEach((userNodeId) => removeUserByNodeId(userNodeId));
      } else {
        removeUserByNodeId(id);
      }
    }

    for (const id of added) {
      if (isDepartmentNodeId(id)) {
        const userNodeIds = usersByDepartmentId.get(id);
        userNodeIds.forEach((userNodeId) => addUserByNodeId(userNodeId));
      } else {
        addUserByNodeId(id);
      }
    }

    onChange(newValue);
  }

  return (
    <SelectableTree
      multiple
      selectable="all"
      data={treeNodes}
      selected={selected}
      onChange={handleChange}
    />
  );
}

function getUserNodeId(user: User): string {
  return `User:${user.id}`;
}

function getDepartmentNodeId(user: User): string {
  return `Department:${user.departmentName}`;
}

function isDepartmentNodeId(id: string): boolean {
  return id.startsWith('Department:');
}
