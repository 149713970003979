import styles from './EmailTemplatesPopover.module.scss';

import { Star, StarBorder } from '@mui/icons-material';
import {
  ButtonBase,
  Divider,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';

import { Tooltip } from '../tooltip/Tooltip';

type EmailTemplateWithStandard = EMailTemplate & { isStandard?: boolean };
interface EmailTemplatesPopoverProps {
  templates: EmailTemplateWithStandard[];
  mainTemplates?: EmailTemplateWithStandard[];
  anchorEl: Element;
  onClose: () => void;
  onTemplateClick: (template: EMailTemplate | null) => void;
  onStandardSelect: (template: EMailTemplate | null) => void;
  noTemplate?: boolean;
}

export const EmailTemplatesPopover = ({
  templates,
  mainTemplates,
  anchorEl,
  onClose,
  onTemplateClick,
  onStandardSelect,
  noTemplate,
}: EmailTemplatesPopoverProps) => {
  const { t } = useTranslation();

  const user = useUser();

  const { sortedTemplates, sortedMainTemplates } = useMemo(() => {
    const sortByName = (value: EmailTemplateWithStandard[] = []) => {
      if (!value || value.length === 0) {
        return [];
      }
      return [...value].sort((a, b) => a.name.localeCompare(b.name));
    };

    return {
      sortedTemplates: sortByName(templates),
      sortedMainTemplates: sortByName(mainTemplates),
    };
  }, [templates, mainTemplates]);

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      classes={{
        root: styles.root,
      }}
    >
      <Box
        padding={'.25rem'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
        }}
      >
        <Typography
          variant="h4"
          color="text.primary"
          style={{
            marginBottom: '0.5rem',
            marginTop: '1rem',
            paddingLeft: '0.75rem',
          }}
        >
          {t('COMMON.EMAIL_TEMPLATES')}
        </Typography>

        {sortedMainTemplates && (
          <>
            {sortedMainTemplates.map((template) => (
              <Tooltip key={template.id} title={template.name}>
                <ButtonBase
                  onClick={() => {
                    onTemplateClick(template);
                  }}
                  key={template.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: 680,
                    paddingRight: 8,
                  }}
                >
                  <Typography
                    p={2}
                    style={{
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {template.name}
                  </Typography>

                  {user.isMaster && (
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        onStandardSelect(template);
                      }}
                    >
                      {template?.isStandard ? (
                        <Star style={{ color: 'var(--brand02)' }} />
                      ) : (
                        <StarBorder />
                      )}
                    </IconButton>
                  )}
                  {!user.isMaster && template?.isStandard && (
                    <IconButton edge="end">
                      <Star style={{ color: 'var(--brand02)' }} />
                    </IconButton>
                  )}
                </ButtonBase>
              </Tooltip>
            ))}
            <Divider style={{ width: '100%', borderColor: 'var(--ui03)' }} />
          </>
        )}

        {sortedTemplates.map((template) => {
          return (
            <Tooltip key={template.id} title={template.name}>
              <ButtonBase
                sx={{
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  onTemplateClick(template);
                }}
                key={template.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  maxWidth: 680,
                }}
              >
                <Typography
                  p={2}
                  style={{
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {template.name}
                </Typography>
              </ButtonBase>
            </Tooltip>
          );
        })}

        {noTemplate && (
          <>
            <Divider style={{ width: '100%', borderColor: 'var(--ui03)' }} />
            <ButtonBase
              onClick={() => {
                onTemplateClick(null);
              }}
            >
              <Typography p={2}>{t('COMMON.BLANK')}</Typography>
            </ButtonBase>
          </>
        )}
      </Box>
    </Popover>
  );
};
