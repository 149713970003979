import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationArea } from '../organisation-area';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useFileActions } from './FileActions';
import schema from './schemata/supplier-table-schema.json';

type ISupplierTableCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];

export const SuppliersTable = forwardRef<TableInstance, IEntityTable>(
  function SuppliersTable(props, ref) {
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Supplier,
      ISupplierTableCustomColumns
    >({
      schema: schema as never,
      defaultSort,
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return { ...modifiers, isFaded: value.extinct === -1 };
      },
      [prepareRowDisplayModifiers]
    );

    const { fileActionDefinitions, openInNewTab } = useFileActions({
      entity: Entities.supplier,
    });

    const { t } = useTranslation();

    return (
      <EntityTable
        ref={ref}
        areas={{
          left: {
            title: t('COMMON.ORGANISATION'),
            content: <OrganisationArea entity={Entities.supplier} />,
            resizable: true,
            collapsible: true,
            custom: true,
          },
        }}
        actions={{
          custom: {
            left: fileActionDefinitions,
          },
          openInNewTab,
          createWidget: false,
        }}
        prepareRowDisplayModifiers={rowModifiers}
        {...dataTable}
        {...props}
      />
    );
  }
);
