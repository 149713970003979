import styles from './CustomListEntityPicker.module.scss';

import { ListItem, ListItemText } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NoResultsRow: FC = () => {
  const { t } = useTranslation();

  return (
    <ListItem className={styles.row} disablePadding>
      <ListItemText
        primary={t('PICKER.NO_RESULTS_FOUND')}
        classes={{ primary: styles.nothingFound }}
      />
    </ListItem>
  );
};
