import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Appointment } from './Appointment.entity';
import { CallMemo } from './CallMemo.entity';
import { Note } from './Note.entity';
import { SalesOpportunities } from './SalesOpportunities.entity';
import { Task } from './Task.entity';

export type DuplicatedObjectUnion<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? Task<T> | CallMemo<T> | Note<T> | Appointment<T> | SalesOpportunities<T>
    : {
        /** Alias: Aufgabe */
        task?: Task<T>;
        /** Alias: Telefonat */
        callMemo?: CallMemo<T>;
        /** Alias: Notiz */
        note?: Note<T>;
        /** Alias: Termin */
        appointment?: Appointment<T>;
        /** Alias: Verkaufschance */
        salesOpportunities?: SalesOpportunities<T>;
      };

const fields: FieldDefinitions<DuplicatedObjectUnion<EMode.query>> = {
  task: {
    alias: 'task',
    entity: Entities.task,
  },
  callMemo: {
    alias: 'callMemo',
    entity: Entities.callMemo,
  },
  note: {
    alias: 'note',
    entity: Entities.note,
  },
  appointment: {
    alias: 'appointment',
    entity: Entities.appointment,
  },
  salesOpportunities: {
    alias: 'salesOpportunities',
    entity: Entities.salesOpportunities,
  },
};

export const duplicatedObjectUnionEntityDefinition: EntitiyDefinition<
  DuplicatedObjectUnion<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'DuplicatedObjectUnion',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
