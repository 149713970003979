import styles from '../General.module.scss';

import { Fullscreen } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { usePreviewNavigation } from '@work4all/components/lib/components/entity-preview/erp-preview/hooks/use-preview-navigation';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { Remove } from '@work4all/components/lib/input/actions/remove/Remove';
import { Upload } from '@work4all/components/lib/input/actions/upload/Upload';
import {
  FilePreview,
  FilePreviewProvider,
  useFilePreview,
} from '@work4all/components/lib/preview/FilePreviewProvider';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Collapse } from '../../../../components';
import { withAutoDisable } from '../../../../utils/with-auto-disable';
// TODO: add delete confirm modal

export function Receipt() {
  const receiptConext = useContext(TempFileManagerContext);
  const { file, component } = usePreviewNavigation(receiptConext.fileList);
  return (
    <FilePreviewProvider file={file}>
      <ReceiptWithAutoDisable navigation={component} />
    </FilePreviewProvider>
  );
}

function ReceiptInternal({
  navigation,
  disabled,
}: {
  navigation: JSX.Element;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const { openFullscreen, file } = useFilePreview();
  const receiptConext = useContext(TempFileManagerContext);

  const onUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      receiptConext.uploadFiles(Array.from(e.target.files), true);
    },
    [receiptConext]
  );

  const dialogs = useDialogs();

  const onRemove = useCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('ALERTS.BTN_DELETE'),
      description: t('DELETE.ATTACHMENT.CONFIRMATION.DESCRIPTION'),
    });
    if (!confirmed) return;
    const id = file?.id;
    receiptConext.markFilesToDelete([id?.toString()]);
  }, [receiptConext, file, dialogs, t]);

  const headerBar = (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {!receiptConext.fileList?.length && (
          <Upload onChange={onUpload} noTitle disabled={disabled} />
        )}
        {file && <Remove onClick={onRemove} disabled={disabled} />}
        {file && (
          <IconButtonWithTooltip
            tooltip={t('COMMON.FULLSCREEN')}
            icon={<Fullscreen />}
            onClick={openFullscreen}
          />
        )}
      </Stack>
      {navigation}
    </Stack>
  );

  return (
    <Collapse
      className={styles.receipt}
      title={t('COMMON.DOCUMENT')}
      defaultOpen
      headerBar={headerBar}
      classes={{
        root: styles['receipt-root'],
        wrapper: styles['receipt-wrapper'],
      }}
    >
      <Box
        bgcolor={'#eeeeee'}
        height={'100%'}
        minHeight={600}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {file ? (
          <FilePreview file={file} />
        ) : (
          <Typography>{t('COMMON.EMPTY')}</Typography>
        )}
      </Box>
    </Collapse>
  );
}
const ReceiptWithAutoDisable = withAutoDisable(ReceiptInternal);
