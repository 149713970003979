import React from 'react';
import { TableInstance } from 'react-table';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/eMailSignature-table-schema.json';

const manualGroupBy = false;
const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];

export const EMailSignatureTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function EMailSignatureTable(props, ref) {
  const { forceRequestFields, ...rest } = props;

  const { data, ...dataTable } = useEntityDataTable<
    EMailSignature,
    Record<string, never>
  >({
    schema: schema as never,
    forceRequestFields,
    defaultSort,
  });

  return (
    <EntityTable
      ref={ref}
      {...dataTable}
      manualGroupBy={manualGroupBy}
      data={data}
      {...rest}
    />
  );
});
