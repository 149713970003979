import styles from './FileListGalleryItemThumbnail.module.scss';

import { useSecureBlobUrl } from '@work4all/data';

import { Loading } from '../../Loading';

import { FileListGalleryItemThumbnailFallback } from './FileListGalleryItemThumbnailFallback';
import { FileAndThumbnail } from './types';

export interface FileListGalleryItemThumbnailProps {
  file: FileAndThumbnail;
}

export function FileListGalleryItemThumbnail(
  props: FileListGalleryItemThumbnailProps
) {
  const { file } = props;

  const thumbnailUrl = resolveThumbnailUrl(file);
  const { blobUrl, error, isLoading } = useSecureBlobUrl(thumbnailUrl, {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!thumbnailUrl || error) {
    return <FileListGalleryItemThumbnailFallback file={file.file} />;
  }

  return <img className={styles.image} src={blobUrl} alt="" />;
}

function resolveThumbnailUrl(file: FileAndThumbnail): string | null {
  if (file.thumbnail) {
    return file.thumbnail.fileInfos.previewUrl;
  }

  // If a file is an image, just load the file itself if the thumbnail is
  // missing. For other files default to fallback UI.
  switch (file.file.previewMimeType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+x':
    case 'image/webp':
      return file.file.previewUrl;

    default:
      return null;
  }
}
