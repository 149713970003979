import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputArtikel<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: artikelArt */
  artikelArt?: any;
  /** Alias: standardEkAlsEinstand */
  standardEkAlsEinstand?: number;
  /** Alias: internerText */
  internerText?: any;
  /** Alias: seriennummerBeiWarenausgang */
  seriennummerBeiWarenausgang?: boolean;
  /** Alias: seriennummerBeiWareneingang */
  seriennummerBeiWareneingang?: boolean;
  /** Alias: bewertung */
  bewertung?: any;
  /** Alias: breite */
  breite?: any;
  /** Alias: chargenverwaltung */
  chargenverwaltung?: boolean;
  /** Alias: creationDate */
  creationDate?: any;
  /** Alias: dienstleistung */
  dienstleistung?: boolean;
  /** Alias: disposition */
  disposition?: boolean;
  /** Alias: ean */
  ean?: any;
  /** Alias: einheitCode */
  einheitCode?: number;
  /** Alias: euroNettopreis */
  euroNettopreis?: any;
  /** Alias: euroNettopreis2 */
  euroNettopreis2?: any;
  /** Alias: euroNettopreis3 */
  euroNettopreis3?: any;
  /** Alias: rabattfaehig */
  rabattfaehig?: boolean;
  /** Alias: excelDatei */
  excelDatei?: any;
  /** Alias: faktor */
  faktor?: any;
  /** Alias: festpreis1 */
  festpreis1?: boolean;
  /** Alias: festpreis2 */
  festpreis2?: boolean;
  /** Alias: festpreis3 */
  festpreis3?: boolean;
  /** Alias: fremdleistung */
  fremdleistung?: boolean;
  /** Alias: garantieNachReparatur */
  garantieNachReparatur?: boolean;
  /** Alias: gewicht */
  gewicht?: any;
  /** Alias: grCode */
  grCode?: number;
  /** Alias: herstellerCode */
  herstellerCode?: number;
  /** Alias: herstellernummer */
  herstellernummer?: any;
  /** Alias: hoehe */
  hoehe?: any;
  /** Alias: kalkulationslogik */
  kalkulationslogik?: any;
  /** Alias: kalkulationslogikFaktor */
  kalkulationslogikFaktor?: any;
  /** Alias: kartonFaktor */
  kartonFaktor?: any;
  /** Alias: keineProvision */
  keineProvision?: boolean;
  /** Alias: kostenkonto */
  kostenkonto?: any;
  /** Alias: kostenkonto2 */
  kostenkonto2?: number;
  /** Alias: kostenkontoEu */
  kostenkontoEu?: any;
  /** Alias: kostenkontoNonEu */
  kostenkontoNonEu?: number;
  /** Alias: kostenStCode */
  kostenStCode?: number;
  /** Alias: kostenStCode2 */
  kostenStCode2?: number;
  /** Alias: kostenStNummer2 */
  kostenStNummer2?: number;
  /** Alias: standardLagerortCode */
  standardLagerortCode?: number;
  /** Alias: lagerwertFifo */
  lagerwertFifo?: any;
  /** Alias: laenge */
  laenge?: any;
  /** Alias: meldebestand */
  meldebestand?: any;
  /** Alias: mietartikel */
  mietartikel?: boolean;
  /** Alias: mindestbestand */
  mindestbestand?: any;
  /** Alias: mittlererEk */
  mittlererEk?: any;
  /** Alias: palettenFaktor */
  palettenFaktor?: any;
  /** Alias: mwstCode */
  mwstCode?: number;
  /** Alias: name */
  name?: any;
  /** Alias: nichtAutomatischBestellen */
  nichtAutomatischBestellen?: boolean;
  /** Alias: nichtLagerArtikel */
  nichtLagerArtikel?: boolean;
  /** Alias: nichtSkontofaehig */
  nichtSkontofaehig?: boolean;
  /** Alias: langtext */
  langtext?: any;
  /** Alias: nummer */
  nummer?: any;
  /** Alias: nurEinkauf */
  nurEinkauf?: number;
  /** Alias: preisstaffelCode */
  preisstaffelCode?: number;
  /** Alias: produktionsartikel */
  produktionsartikel?: boolean;
  /** Alias: rabattGrCode */
  rabattGrCode?: number;
  /** Alias: sachkNummer */
  sachkNummer?: number;
  /** Alias: sachkNummerEu */
  sachkNummerEu?: number;
  /** Alias: sachkNummerNonEu */
  sachkNummerNonEu?: number;
  /** Alias: seriennummernlogik */
  seriennummernlogik?: any;
  /** Alias: sollBestand */
  sollBestand?: any;
  /** Alias: staffelpreis */
  staffelpreis?: boolean;
  /** Alias: staffelpreisZuschlag */
  staffelpreisZuschlag?: any;
  /** Alias: staffelRabatte */
  staffelRabatte?: boolean;
  /** Alias: standardBildCode */
  standardBildCode?: number;
  /** Alias: standardLieferantCode */
  standardLieferantCode?: number;
  /** Alias: wartungsanteil */
  wartungsanteil?: any;
  /** Alias: stillgelegt */
  stillgelegt?: boolean;
  /** Alias: taetigkeit */
  taetigkeit?: boolean;
  /** Alias: veFaktor */
  veFaktor?: any;
  /** Alias: wartungsartikelFuer */
  wartungsartikelFuer?: any;
  /** Alias: zolltarifnummer */
  zolltarifnummer?: any;
  /** Alias: zuschlag1 */
  zuschlag1?: any;
  /** Alias: zuschlag2 */
  zuschlag2?: any;
  /** Alias: zuschlag3 */
  zuschlag3?: any;
  /** Alias: zuschlag4 */
  zuschlag4?: any;
  /** Alias: einstandspreis */
  einstandspreis?: any;
  /** Alias: minutenpreis1 */
  minutenpreis1?: any;
  /** Alias: minutenpreis2 */
  minutenpreis2?: any;
  /** Alias: minutenpreis3 */
  minutenpreis3?: any;
  /** Alias: aufwand */
  aufwand?: any;
  /** Alias: bezugskosten */
  bezugskosten?: any;
  /** Alias: webshopAusblenden */
  webshopAusblenden?: boolean;
  /** Alias: besitzerSdObjMemberCode */
  besitzerSdObjMemberCode?: number;
  /** Alias: besitzerSdObjType */
  besitzerSdObjType?: SdObjType;
  /** Alias: boxArtikel */
  boxArtikel?: boolean;
  /** Alias: boxFuellgrad */
  boxFuellgrad?: any;
  /** Alias: shopBestellungohneBestand */
  shopBestellungohneBestand?: boolean;
  /** Alias: nichtAbrechenbar */
  nichtAbrechenbar?: boolean;
  /** Alias: ressourceClassCode */
  ressourceClassCode?: number;
  /** Alias: herkunftsland */
  herkunftsland?: any;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: summarizeForPackingLists */
  summarizeForPackingLists?: boolean;
  /** Alias: isArticleForRessourcePlaning */
  isArticleForRessourcePlaning?: boolean;
  /** Alias: vatCodeEu */
  vatCodeEu?: number;
  /** Alias: vatCodeNonEu */
  vatCodeNonEu?: number;
  /** Alias: vatCodeCost */
  vatCodeCost?: number;
  /** Alias: vatCodeEuCost */
  vatCodeEuCost?: number;
  /** Alias: vatCodeNonEuCost */
  vatCodeNonEuCost?: number;
  /** Alias: erstellerBenutzerCode */
  creatorUserId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputArtikel> = {
  code: {
    alias: 'code',
  },
  artikelArt: {
    alias: 'artikelArt',
  },
  standardEkAlsEinstand: {
    alias: 'standardEkAlsEinstand',
  },
  internerText: {
    alias: 'internerText',
  },
  seriennummerBeiWarenausgang: {
    alias: 'seriennummerBeiWarenausgang',
  },
  seriennummerBeiWareneingang: {
    alias: 'seriennummerBeiWareneingang',
  },
  bewertung: {
    alias: 'bewertung',
  },
  breite: {
    alias: 'breite',
  },
  chargenverwaltung: {
    alias: 'chargenverwaltung',
  },
  creationDate: {
    alias: 'creationDate',
  },
  dienstleistung: {
    alias: 'dienstleistung',
  },
  disposition: {
    alias: 'disposition',
  },
  ean: {
    alias: 'ean',
  },
  einheitCode: {
    alias: 'einheitCode',
  },
  euroNettopreis: {
    alias: 'euroNettopreis',
  },
  euroNettopreis2: {
    alias: 'euroNettopreis2',
  },
  euroNettopreis3: {
    alias: 'euroNettopreis3',
  },
  rabattfaehig: {
    alias: 'rabattfaehig',
  },
  excelDatei: {
    alias: 'excelDatei',
  },
  faktor: {
    alias: 'faktor',
  },
  festpreis1: {
    alias: 'festpreis1',
  },
  festpreis2: {
    alias: 'festpreis2',
  },
  festpreis3: {
    alias: 'festpreis3',
  },
  fremdleistung: {
    alias: 'fremdleistung',
  },
  garantieNachReparatur: {
    alias: 'garantieNachReparatur',
  },
  gewicht: {
    alias: 'gewicht',
  },
  grCode: {
    alias: 'grCode',
  },
  herstellerCode: {
    alias: 'herstellerCode',
  },
  herstellernummer: {
    alias: 'herstellernummer',
  },
  hoehe: {
    alias: 'hoehe',
  },
  kalkulationslogik: {
    alias: 'kalkulationslogik',
  },
  kalkulationslogikFaktor: {
    alias: 'kalkulationslogikFaktor',
  },
  kartonFaktor: {
    alias: 'kartonFaktor',
  },
  keineProvision: {
    alias: 'keineProvision',
  },
  kostenkonto: {
    alias: 'kostenkonto',
  },
  kostenkonto2: {
    alias: 'kostenkonto2',
  },
  kostenkontoEu: {
    alias: 'kostenkontoEu',
  },
  kostenkontoNonEu: {
    alias: 'kostenkontoNonEu',
  },
  kostenStCode: {
    alias: 'kostenStCode',
  },
  kostenStCode2: {
    alias: 'kostenStCode2',
  },
  kostenStNummer2: {
    alias: 'kostenStNummer2',
  },
  standardLagerortCode: {
    alias: 'standardLagerortCode',
  },
  lagerwertFifo: {
    alias: 'lagerwertFifo',
  },
  laenge: {
    alias: 'laenge',
  },
  meldebestand: {
    alias: 'meldebestand',
  },
  mietartikel: {
    alias: 'mietartikel',
  },
  mindestbestand: {
    alias: 'mindestbestand',
  },
  mittlererEk: {
    alias: 'mittlererEk',
  },
  palettenFaktor: {
    alias: 'palettenFaktor',
  },
  mwstCode: {
    alias: 'mwstCode',
  },
  name: {
    alias: 'name',
  },
  nichtAutomatischBestellen: {
    alias: 'nichtAutomatischBestellen',
  },
  nichtLagerArtikel: {
    alias: 'nichtLagerArtikel',
  },
  nichtSkontofaehig: {
    alias: 'nichtSkontofaehig',
  },
  langtext: {
    alias: 'langtext',
  },
  nummer: {
    alias: 'nummer',
  },
  nurEinkauf: {
    alias: 'nurEinkauf',
  },
  preisstaffelCode: {
    alias: 'preisstaffelCode',
  },
  produktionsartikel: {
    alias: 'produktionsartikel',
  },
  rabattGrCode: {
    alias: 'rabattGrCode',
  },
  sachkNummer: {
    alias: 'sachkNummer',
  },
  sachkNummerEu: {
    alias: 'sachkNummerEu',
  },
  sachkNummerNonEu: {
    alias: 'sachkNummerNonEu',
  },
  seriennummernlogik: {
    alias: 'seriennummernlogik',
  },
  sollBestand: {
    alias: 'sollBestand',
  },
  staffelpreis: {
    alias: 'staffelpreis',
  },
  staffelpreisZuschlag: {
    alias: 'staffelpreisZuschlag',
  },
  staffelRabatte: {
    alias: 'staffelRabatte',
  },
  standardBildCode: {
    alias: 'standardBildCode',
  },
  standardLieferantCode: {
    alias: 'standardLieferantCode',
  },
  wartungsanteil: {
    alias: 'wartungsanteil',
  },
  stillgelegt: {
    alias: 'stillgelegt',
  },
  taetigkeit: {
    alias: 'taetigkeit',
  },
  veFaktor: {
    alias: 'veFaktor',
  },
  wartungsartikelFuer: {
    alias: 'wartungsartikelFuer',
  },
  zolltarifnummer: {
    alias: 'zolltarifnummer',
  },
  zuschlag1: {
    alias: 'zuschlag1',
  },
  zuschlag2: {
    alias: 'zuschlag2',
  },
  zuschlag3: {
    alias: 'zuschlag3',
  },
  zuschlag4: {
    alias: 'zuschlag4',
  },
  einstandspreis: {
    alias: 'einstandspreis',
  },
  minutenpreis1: {
    alias: 'minutenpreis1',
  },
  minutenpreis2: {
    alias: 'minutenpreis2',
  },
  minutenpreis3: {
    alias: 'minutenpreis3',
  },
  aufwand: {
    alias: 'aufwand',
  },
  bezugskosten: {
    alias: 'bezugskosten',
  },
  webshopAusblenden: {
    alias: 'webshopAusblenden',
  },
  besitzerSdObjMemberCode: {
    alias: 'besitzerSdObjMemberCode',
  },
  besitzerSdObjType: {
    alias: 'besitzerSdObjType',
  },
  boxArtikel: {
    alias: 'boxArtikel',
  },
  boxFuellgrad: {
    alias: 'boxFuellgrad',
  },
  shopBestellungohneBestand: {
    alias: 'shopBestellungohneBestand',
  },
  nichtAbrechenbar: {
    alias: 'nichtAbrechenbar',
  },
  ressourceClassCode: {
    alias: 'ressourceClassCode',
  },
  herkunftsland: {
    alias: 'herkunftsland',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  summarizeForPackingLists: {
    alias: 'summarizeForPackingLists',
  },
  isArticleForRessourcePlaning: {
    alias: 'isArticleForRessourcePlaning',
  },
  vatCodeEu: {
    alias: 'vatCodeEu',
  },
  vatCodeNonEu: {
    alias: 'vatCodeNonEu',
  },
  vatCodeCost: {
    alias: 'vatCodeCost',
  },
  vatCodeEuCost: {
    alias: 'vatCodeEuCost',
  },
  vatCodeNonEuCost: {
    alias: 'vatCodeNonEuCost',
  },
  creatorUserId: {
    alias: 'erstellerBenutzerCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArtikelEntityDefinition: EntitiyDefinition<InputArtikel> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputArtikel',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
