import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../settings';
import { ReViewModelFormValue } from '../types';

export const useSkontoStartDate = () => {
  const inboundInvoiceExpireDateCalculationByDateOfReceipt = useSetting(
    settings.inboundInvoiceExpireDateCalculationByDateOfReceipt()
  );
  return useMemo(() => {
    return inboundInvoiceExpireDateCalculationByDateOfReceipt.value
      ? 'dateOfReceipt'
      : 'invoiceDate';
  }, [inboundInvoiceExpireDateCalculationByDateOfReceipt.value]);
};

const useSkontoDateUpdate = (
  form: UseFormReturn<ReViewModelFormValue, object>,
  currentPeriodKey: 'discountDays' | 'discount2Days',
  endDateKey: 'discountDate' | 'discount2Date'
) => {
  const { watch, setValue } = form;
  const expiryCalcProp = useSkontoStartDate();
  const startDateIso = watch(expiryCalcProp);
  const currentPeriod = watch(currentPeriodKey);
  const endDateIso = watch(endDateKey);

  useEffect(() => {
    if (currentPeriod === undefined || !startDateIso || !endDateIso) return;
    const startDate = DateTime.fromISO(startDateIso);
    const endDate = DateTime.fromISO(endDateIso);
    const period = endDate.diff(startDate, 'days').as('days');
    if (period !== currentPeriod) {
      setTimeout(() => {
        setValue(endDateKey, startDate.plus({ days: currentPeriod }).toISO(), {
          shouldDirty: true,
        });
        // TODO: FE refinement needed - maybe there is better solution
      }, 0);
    }
  }, [
    currentPeriod,
    startDateIso,
    endDateIso,
    setValue,
    expiryCalcProp,
    endDateKey,
  ]);
};

export const useSkontoUpdate = (
  form: UseFormReturn<ReViewModelFormValue, object>
) => {
  useSkontoDateUpdate(form, 'discountDays', 'discountDate');
  useSkontoDateUpdate(form, 'discount2Days', 'discount2Date');
};
