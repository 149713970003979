import styles from './Positions.module.scss';

import { Box, IconButton, Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import { ColumnInstance, TableInstance } from 'react-table';

import { ReactComponent as ColumnsIcon } from '@work4all/assets/icons/columns.svg';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { CustomFieldsConfigProvider } from '@work4all/data/lib/custom-fields';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { ColumnsVisibility } from '../../../../../../../../../components/data-tables/table/components/toolbar/components/columns-visibility';
import { settings, useSetting } from '../../../../../../../../../settings';
import { useFormContextPlus } from '../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../hooks/mask-context';
import { withAutoDisable } from '../../../../../../utils/with-auto-disable';
import { ErpPaymentData } from '../../../../ErpData';

import { ClonePositionButton } from './clone-position-button/ClonePositionButton';
import { IEditTable } from './edit-table/types';
import { useBomDialog } from './hooks/use-bom-dialog';
import { usePositionState } from './hooks/use-position-state';
import { usePositionsColumns } from './hooks/use-positions-columns';
import { SelectedPositionProvider } from './hooks/use-selected-position';
import { MoreActionButtonButton } from './more-actions-button/MoreActionsButton';
import { NewPostitionButton } from './new-position-button/NewPostitionButton';
import { PositionPicker } from './position-picker/PositionPicker';
import { BOMDialog } from './positions-bill-of-materials/BOMDialog';
import { PositionsSettings } from './positions-settings/PositionsSettings';
import {
  ForwardedEditTableProps,
  IPositionsTableProps,
  PositionsTable,
} from './positions-table/PositionsTable';
import { ErpPriceGroupPicker } from './price-group-picker/ErpPriceGroupPicker';
import { RemovePositionButton } from './remove-position-button/RemovePositionButton';
import { RowSizePicker } from './row-size-picker/RowSizePicker';
import { handleOrderChange } from './utils/handle-order-change';

interface IPositionsProps
  extends ForwardedEditTableProps,
    Pick<IPositionsTableProps, 'showPositionMask' | 'disabled'> {
  showArticleSelectionBig?: boolean;
}

const emptyColumns: ColumnInstance[] = [];

export const Positions: React.FC<IPositionsProps> = (props) => {
  const {
    allowedColumns,
    disabled = false,
    showArticleSelectionBig = true,
    showPositionMask = true,
    showBorders,
    showSelectionColumn,
    singleLine,
  } = props;

  const editTableRef = useRef<IEditTable>();
  const horizontalScrollRef = useRef<HTMLDivElement>();
  const { entity } = useMaskContext();
  const { watch } = useFormContextPlus<ErpPaymentData>();

  const tableInstanceRef = useRef<TableInstance>();

  const desktopMenuButtonRef = useRef<HTMLButtonElement>(null);
  const columnsPopoverState = usePopoverState();
  const openColumnsPopover = columnsPopoverState.handleClick;

  const columnSettings = useSetting(settings.erpPositionsConfig(entity));
  const handleVisibilityChange = useCallback(
    (columns: string[]) => {
      if (!columns.filter((x) => SELECTION_COLUMN_ID !== x).length) return;
      columnSettings.set({
        ...columnSettings.value,
        order: columns,
        visibility: columns,
      });
    },
    [columnSettings]
  );

  const columnsVisibilityManager = useColumnVisibilityManager({
    tableInstanceRef,
    onVisibilityChange: handleVisibilityChange,
    orderColumns: handleOrderChange,
    disabledColumns: ['longtext', 'number'],
  });

  const {
    positions,
    total,
    onAddPosition,
    onClonePosition,
    convertToInternal,
    onRemovePosition,
    onMovePosition,
    onEditPosition,
    onCollapsePosition,
  } = usePositionState({
    editTableRef,
    entity,
    isSimpleMode: !showBorders,
  });

  const onAddSpecial = useCallback(
    (positionType: ErpPositionsKind, article, autofocus) => {
      onAddPosition({
        positionType,
        article,
        autofocus,
      });
      if (horizontalScrollRef?.current)
        horizontalScrollRef.current.scrollLeft = 0;
    },
    [onAddPosition]
  );

  const previewSize = useSetting(settings.erpArticleSize());
  const erpShowArticle = useSetting(settings.erpShowArticle());

  const { columns, resetColumns } = usePositionsColumns({
    tableInstanceRef,
    allowedColumns,
    total,
  });

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { bomArticle, onAddBomArticle, onAddPositionWithArticle, onBomClose } =
    useBomDialog({
      onAddPosition,
      entity,
    });

  const onAddOnArticlePicker = useCallback(
    (article) => {
      onAddPositionWithArticle({
        positionType: ErpPositionsKind.STANDARD,
        article,
      });
    },
    [onAddPositionWithArticle]
  );

  const onPickPosition = useCallback(
    (article?: Article | string) => {
      if (typeof article === 'string') {
        onAddPosition({
          positionType: ErpPositionsKind.TITEL,
          defaultText: article,
        });
        return;
      }
      if (article)
        onAddPositionWithArticle({
          positionType: ErpPositionsKind.STANDARD,
          article,
        });
      else
        onAddPosition({
          positionType: ErpPositionsKind.TEXTZEILE,
        });
    },
    [onAddPosition, onAddPositionWithArticle]
  );

  const businessPartnerContactCombined = watch(
    'businessPartnerContactCombined'
  );

  const customerId =
    businessPartnerContactCombined?.businessPartner?.businessPartnerType ===
    SdObjType.KUNDE
      ? businessPartnerContactCombined?.businessPartner?.id
      : undefined;

  const positionsTable = useMemo(() => {
    return (
      <PositionsTable
        refs={{
          editTable: editTableRef,
          scroll: horizontalScrollRef,
        }}
        columns={columns}
        tableInstanceRef={tableInstanceRef}
        disabled={disabled}
        positions={positions ?? []}
        onMovePosition={onMovePosition}
        onPickPosition={onPickPosition}
        onRemovePosition={onRemovePosition}
        onEditPosition={onEditPosition}
        onCollapsePosition={onCollapsePosition}
        showPositionMask={showPositionMask}
        showBorders={showBorders}
        allowedColumns={allowedColumns}
        onAddPosition={onAddPositionWithArticle}
        showSelectionColumn={showSelectionColumn}
        singleLine={singleLine}
        total={total}
      />
    );
  }, [
    disabled,
    positions,
    onMovePosition,
    onPickPosition,
    onRemovePosition,
    onEditPosition,
    onCollapsePosition,
    showPositionMask,
    showBorders,
    columns,
    allowedColumns,
    onAddPositionWithArticle,
    showSelectionColumn,
    singleLine,
    total,
  ]);

  const frozen = watch('frozen');

  return (
    <SelectedPositionProvider>
      <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
        <div className={styles['tableWrapper']}>
          {!isViewportDownMd && showArticleSelectionBig && (
            <Box
              padding="0.5rem 1.75rem"
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" gap="0.5rem">
                <NewPostitionButton
                  disabled={disabled}
                  onAddSpecial={onAddSpecial}
                  editTableRef={editTableRef}
                />
                <RemovePositionButton
                  disabled={disabled}
                  onRemovePosition={onRemovePosition}
                />
                <ClonePositionButton
                  disabled={disabled}
                  onClonePosition={onClonePosition}
                  positions={positions}
                />
                <MoreActionButtonButton
                  disabled={disabled}
                  convertToInternal={convertToInternal}
                  positions={positions}
                />
              </Box>

              <Box display="flex">
                <ErpPriceGroupPicker
                  disabled={frozen === false ? undefined : true}
                />
                <Box width="0.5rem" />
                <RowSizePicker />
                <IconButton
                  onClick={(event) => {
                    return openColumnsPopover(
                      event,
                      desktopMenuButtonRef.current
                    );
                  }}
                >
                  <ColumnsIcon className={styles.icon} />
                </IconButton>
                <PositionsSettings />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flex: 1,
              borderBottom: '1px solid var(--ui04)',
            }}
          >
            {positionsTable}
          </Box>

          <ColumnsVisibility
            popoverState={columnsPopoverState}
            tableInstanceRef={tableInstanceRef}
            visibleColumns={
              tableInstanceRef.current?.allColumns ?? emptyColumns
            }
            onResetColumns={resetColumns}
          />
        </div>
        {!isViewportDownMd && showArticleSelectionBig && (
          <ResizableArea
            handles="top"
            className={styles.resizable}
            direction="vertical"
            size={
              erpShowArticle.value
                ? previewSize.value
                : { height: remToPx(3.25) }
            }
            onResize={(size) => {
              previewSize.set(size);
              erpShowArticle.set(true);
            }}
            maxHeight={1000}
            minHeight={erpShowArticle.value ? remToPx(8.5) : remToPx(3.25)}
          >
            <PositionPicker
              disabled={disabled}
              className={styles.picker}
              onAddStandard={onAddOnArticlePicker}
              onGroupDoubleClick={onPickPosition}
              customerId={customerId}
            />
          </ResizableArea>
        )}
        <BOMDialog
          entity={entity}
          article={bomArticle}
          onAddArticle={onAddBomArticle}
          onClose={onBomClose}
        />
      </ColumnVisibilityContext.Provider>
    </SelectedPositionProvider>
  );
};

const PositionsWithCustomColumns = (props: IPositionsProps) => {
  return (
    <CustomFieldsConfigProvider entity={Entities.position}>
      <Positions {...props} />
    </CustomFieldsConfigProvider>
  );
};

export const PositionsWithAutoDisable = withAutoDisable(
  React.memo(PositionsWithCustomColumns)
);
