import styles from './MailboxContentTableQuickFilters.module.scss';

import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import {
  ContextMenu,
  DISMISS_POPOVER,
} from '@work4all/components/lib/components/context-menu/ContextMenu';
import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';

import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { MailboxLoadErrorType } from '@work4all/models/lib/Enums/MailboxLoadErrorType.enum';

import { SelectedMailboxFolder } from '../../../settings';
import { LinkMailboxDialog } from '../LinkMailboxDialog';

import { listStyleOverrides } from './list-style-overrides';
import { MailboxSettingsSidebar } from './MailboxSettingsSidebar';
import {
  MailboxFolderTreeItem,
  makeFolderTree,
  ROOT_PARENT_ID,
} from './make-folder-tree';
import { ExtendedMailbox } from './types';

export interface MailboxContentTableQuickFiltersProps {
  mailboxes: ExtendedMailbox[];
  onDeleteMailbox: (mailbox: Mailbox) => void;
  selectedFolder: SelectedMailboxFolder;
  onSelectedFolderChange: (value: SelectedMailboxFolder | null) => void;
  onAfterBlockedSendersChange: () => void;
}

export function MailboxContentTableQuickFilters({
  mailboxes,
  onDeleteMailbox,
  selectedFolder,
  onSelectedFolderChange,
  onAfterBlockedSendersChange,
}: MailboxContentTableQuickFiltersProps) {
  const { t } = useTranslation();

  const [isLinkMailboxDialogOpen, setLinkMailboxDialogOpen] = useState(false);
  const [isSettingsSidebarOpen, setSettingsSidebarOpen] = useState(false);
  const [settingsMailboxId, setSettingsMailboxId] = useState<string | null>(
    null
  );

  const settingsMailbox = useMemo(() => {
    if (settingsMailboxId == null) {
      return null;
    }
    return (
      mailboxes.find((mailbox) => mailbox.id === settingsMailboxId) ?? null
    );
  }, [settingsMailboxId, mailboxes]);

  return (
    <>
      <Stack height="100%">
        <div className="custom-scrollbar" style={{ flex: 1, overflow: 'auto' }}>
          <List component="nav" disablePadding>
            {mailboxes.map((mailbox) => {
              return (
                <RenderMailbox
                  key={mailbox.id}
                  mailbox={mailbox}
                  selected={selectedFolder}
                  onSelectedChange={onSelectedFolderChange}
                  onDelete={() => onDeleteMailbox(mailbox)}
                  onSettingsClick={() => {
                    setSettingsMailboxId(mailbox.id);
                    setSettingsSidebarOpen(true);
                  }}
                />
              );
            })}
          </List>

          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={async () => {
              setLinkMailboxDialogOpen(true);
            }}
          >
            {t('ASSIGN_INCOMING_EMAILS.LINK_MAILBOX')}
          </Button>
        </div>
      </Stack>

      <LinkMailboxDialog
        open={isLinkMailboxDialogOpen}
        onClose={() => {
          setLinkMailboxDialogOpen(false);
        }}
      />

      <MailboxSettingsSidebar
        mailbox={settingsMailbox}
        open={isSettingsSidebarOpen}
        onClose={() => setSettingsSidebarOpen(false)}
        onDelete={() => {
          onDeleteMailbox(settingsMailbox);
          setSettingsSidebarOpen(false);
        }}
        onAfterBlockedSendersChange={onAfterBlockedSendersChange}
      />
    </>
  );
}

function RenderMailbox({
  mailbox,
  selected,
  onSelectedChange,
  onDelete,
  onSettingsClick,
}: {
  mailbox: ExtendedMailbox;
  selected: SelectedMailboxFolder | null;
  onSelectedChange: (value: SelectedMailboxFolder | null) => void;
  onDelete: () => void;
  onSettingsClick: () => void;
}) {
  const { t } = useTranslation();

  const [collapsedFolders, setCollapsedFolders] = useState<
    Record<string, boolean>
  >({});

  const folders = useMemo(() => {
    return mailbox.folder === null
      ? null
      : makeFolderTree(mailbox.folder.filter((folder) => folder.isVisible));
  }, [mailbox]);

  function renderMailboxListItem(mailbox: Mailbox) {
    if (mailbox.loadErrorType !== null) {
      return <MailboxErrorState mailbox={mailbox} />;
    }

    const isOpen = !collapsedFolders[ROOT_PARENT_ID];

    const handleClick = () => {
      setCollapsedFolders((collapsed) => {
        return {
          ...collapsed,
          [ROOT_PARENT_ID]: !collapsed[ROOT_PARENT_ID],
        };
      });
    };

    return (
      <ListItem disablePadding>
        <ListItemButton role={undefined} onClick={handleClick}>
          <ListItemIcon>
            <ExpandMore
              sx={{
                transition: 'transform 0.1s',
                transform: isOpen ? null : 'rotate(-90deg)',
              }}
            />
          </ListItemIcon>

          <ListItemText
            primary={mailbox.mailboxPrimaryAddress}
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
        <IconButton size="small" onClick={onSettingsClick}>
          <SettingsIcon />
        </IconButton>
      </ListItem>
    );
  }

  function renderMailboxFolders(folders: MailboxFolderTreeItem[]) {
    return renderFolders(folders, ROOT_PARENT_ID);
  }

  function renderFolders(folders: MailboxFolderTreeItem[], parentId: string) {
    return (
      <Collapse in={!collapsedFolders[parentId]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: '1rem' }}>
          {folders.map((folder) => {
            const folderId = folder.folder.id;

            const isSelected =
              selected?.mailbox === mailbox.id && selected?.folder === folderId;

            const isOpen = !collapsedFolders[folderId];
            const hasChildren = folder.children != null;

            return (
              <Fragment key={folderId}>
                <ListItem
                  secondaryAction={
                    hasChildren ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setCollapsedFolders((collapsed) => ({
                            ...collapsed,
                            [folderId]: !collapsed[folderId],
                          }));
                        }}
                      >
                        <ExpandMore
                          sx={{
                            transition: 'transform 0.1s',
                            transform: isOpen ? null : 'rotate(-90deg)',
                          }}
                        />
                      </IconButton>
                    ) : null
                  }
                  disablePadding
                  sx={listStyleOverrides.listItem}
                >
                  <ListItemButton
                    role={undefined}
                    selected={isSelected}
                    onClick={() => {
                      onSelectedChange({
                        mailbox: mailbox.id,
                        folder: folderId,
                      });
                    }}
                    sx={listStyleOverrides.listItemButton}
                  >
                    <ListItemText primary={folder.folder.name} />

                    {folder.folder.unreadItemsCount > 0 && (
                      <ListItemIcon>
                        <CountIndicator
                          value={folder.folder.unreadItemsCount}
                          variant="secondary"
                        />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>

                {folder.children && renderFolders(folder.children, folderId)}
              </Fragment>
            );
          })}
        </List>
      </Collapse>
    );
  }

  return (
    <>
      <ContextMenu
        content={
          <MenuList className={styles.menuList}>
            <MenuItem
              className={clsx(styles.menuItem, DISMISS_POPOVER)}
              onClick={onDelete}
            >
              {t('MASK.REMOVE')}
            </MenuItem>
          </MenuList>
        }
      >
        {renderMailboxListItem(mailbox)}
      </ContextMenu>

      {folders !== null && renderMailboxFolders(folders)}
    </>
  );
}

function MailboxErrorState({ mailbox }: { mailbox: Mailbox }) {
  const { t } = useTranslation();
  const dialogs = useDialogs();

  function handleClick() {
    function getAlertParams(
      errorType: MailboxLoadErrorType,
      errorMessage: string
    ): {
      title: string;
      description: string;
    } {
      switch (errorType) {
        case MailboxLoadErrorType.AUTH_ERROR: {
          return {
            title: t('LINKED_MAILBOX_ERROR.AUTH_ERROR.TITLE'),
            description: t('LINKED_MAILBOX_ERROR.AUTH_ERROR.DESCRIPTION', {
              replace: { email: mailbox.mailboxPrimaryAddress },
            }),
          };
        }

        case MailboxLoadErrorType.UNSUPPORTED_ACTION: {
          return {
            title: t('LINKED_MAILBOX_ERROR.UNSUPPORTED_ACTION.TITLE'),
            description: t(
              'LINKED_MAILBOX_ERROR.UNSUPPORTED_ACTION.DESCRIPTION'
            ),
          };
        }

        default: {
          return {
            title: t('LINKED_MAILBOX_ERROR.UNKNOWN.TITLE'),
            description: errorMessage,
          };
        }
      }
    }

    dialogs.alert(
      getAlertParams(mailbox.loadErrorType, mailbox.loadErrorMessage)
    );
  }

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <ErrorIcon color="error" />
      </ListItemIcon>
      <ListItemText
        primary={mailbox.mailboxPrimaryAddress}
        primaryTypographyProps={{ fontWeight: 700 }}
      />
    </ListItemButton>
  );
}
