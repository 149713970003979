import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type EmailTemplatePickerProps = Omit<
  IPickerProps<EMailTemplate, false>,
  'multiple' | 'prefilter'
>;

export function EmailTemplatePicker(props: EmailTemplatePickerProps) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...EMAIL_TEMPLATE_PICKER_DATA, ...data };
  }, [data]);

  const renderItem = (item: EMailTemplate) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      multiple={false}
      entity={Entities.eMailTemplate}
      data={allFields}
      filterBy={['name']}
      sortBy="name"
      prefilter={[{ parentId: { $eq: null } }]}
      renderItemContent={renderItem}
      keysArguments={{ 'signature.body': EMAIL_SIGNATURE_KEYS_ARGUMENTS.body }}
      {...rest}
    />
  );
}

export const EMAIL_TEMPLATE_PICKER_DATA: EMailTemplate = {
  id: null,
  name: null,
  body: null,
  subject: null,
  languageCode: null,
  eMailTemplateKind: null,
  childs: [
    {
      id: null,
      body: null,
      subject: null,
      languageCode: null,
      signature: {
        id: null,
        name: null,
        body: null,
      },
      attachmentList: [
        {
          id: null,
        },
      ],
    },
  ],
  signature: {
    body: null,
    id: null,
    name: null,
  },
  attachmentList: [
    {
      id: null,
    },
  ],
};
