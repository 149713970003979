import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputProjekt<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: projektArtCode */
  projektArtCode?: number;
  /** Alias: projektLeiterInternCode */
  projektLeiterInternCode?: number;
  /** Alias: bewertung */
  bewertung?: number;
  /** Alias: dokumentverzeichnis */
  dokumentverzeichnis?: string;
  /** Alias: anfangDatum */
  anfangDatum?: string;
  /** Alias: endeDatum */
  endeDatum?: string;
  /** Alias: bisDatum */
  bisDatum?: string;
  /** Alias: vonDatum */
  vonDatum?: string;
  /** Alias: bCode */
  bCode?: number;
  /** Alias: etat */
  etat?: number;
  /** Alias: sperrungEigenleistung */
  sperrungEigenleistung?: number;
  /** Alias: grCode */
  grCode?: number;
  /** Alias: kostenStellenCode */
  kostenStellenCode?: number;
  /** Alias: kundenApCode */
  kundenApCode?: number;
  /** Alias: kundenCode */
  kundenCode?: number;
  /** Alias: projektleiterExtern */
  projektleiterExtern?: string;
  /** Alias: letzteAenderung */
  letzteAenderung?: string;
  /** Alias: lieferantenApCode */
  lieferantenApCode?: number;
  /** Alias: lieferantenCode */
  lieferantenCode?: number;
  /** Alias: name */
  name?: string;
  /** Alias: nebenadresse2Code */
  nebenadresse2Code?: number;
  /** Alias: nebenadresse2Type */
  nebenadresse2Type?: number;
  /** Alias: nebenadresseCode */
  nebenadresseCode?: number;
  /** Alias: nebenadresseType */
  nebenadresseType?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: nummer */
  nummer?: string;
  /** Alias: parentProjektCode */
  parentProjektCode?: number;
  /** Alias: projektLink0 */
  projektLink0?: string;
  /** Alias: projektLink1 */
  projektLink1?: string;
  /** Alias: projektLink2 */
  projektLink2?: string;
  /** Alias: projektStatusCode */
  projektStatusCode?: number;
  /** Alias: sperrungFremdleistung */
  sperrungFremdleistung?: number;
  /** Alias: verzeichnisTemplateTitelCode */
  verzeichnisTemplateTitelCode?: number;
  /** Alias: shopFormularDaten */
  shopFormularDaten?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputProjekt> = {
  code: {
    alias: 'code',
  },
  projektArtCode: {
    alias: 'projektArtCode',
  },
  projektLeiterInternCode: {
    alias: 'projektLeiterInternCode',
  },
  bewertung: {
    alias: 'bewertung',
  },
  dokumentverzeichnis: {
    alias: 'dokumentverzeichnis',
  },
  anfangDatum: {
    alias: 'anfangDatum',
  },
  endeDatum: {
    alias: 'endeDatum',
  },
  bisDatum: {
    alias: 'bisDatum',
  },
  vonDatum: {
    alias: 'vonDatum',
  },
  bCode: {
    alias: 'bCode',
  },
  etat: {
    alias: 'etat',
  },
  sperrungEigenleistung: {
    alias: 'sperrungEigenleistung',
  },
  grCode: {
    alias: 'grCode',
  },
  kostenStellenCode: {
    alias: 'kostenStellenCode',
  },
  kundenApCode: {
    alias: 'kundenApCode',
  },
  kundenCode: {
    alias: 'kundenCode',
  },
  projektleiterExtern: {
    alias: 'projektleiterExtern',
  },
  letzteAenderung: {
    alias: 'letzteAenderung',
  },
  lieferantenApCode: {
    alias: 'lieferantenApCode',
  },
  lieferantenCode: {
    alias: 'lieferantenCode',
  },
  name: {
    alias: 'name',
  },
  nebenadresse2Code: {
    alias: 'nebenadresse2Code',
  },
  nebenadresse2Type: {
    alias: 'nebenadresse2Type',
  },
  nebenadresseCode: {
    alias: 'nebenadresseCode',
  },
  nebenadresseType: {
    alias: 'nebenadresseType',
  },
  notiz: {
    alias: 'notiz',
  },
  nummer: {
    alias: 'nummer',
  },
  parentProjektCode: {
    alias: 'parentProjektCode',
  },
  projektLink0: {
    alias: 'projektLink0',
  },
  projektLink1: {
    alias: 'projektLink1',
  },
  projektLink2: {
    alias: 'projektLink2',
  },
  projektStatusCode: {
    alias: 'projektStatusCode',
  },
  sperrungFremdleistung: {
    alias: 'sperrungFremdleistung',
  },
  verzeichnisTemplateTitelCode: {
    alias: 'verzeichnisTemplateTitelCode',
  },
  shopFormularDaten: {
    alias: 'shopFormularDaten',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjektEntityDefinition: EntitiyDefinition<InputProjekt> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputProjekt',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
