import i18next from 'i18next';

type Handlers = {
  edit: () => void;
  newLine: () => void;
};

export const textEditorCommentKeydown = function (
  e: KeyboardEvent,
  { edit, newLine }: Handlers
) {
  if (e.key === 'Enter') {
    e.preventDefault();

    // If Ctrl/Cmd is pressed together with Enter, trigger edit()
    if (e.ctrlKey || e.metaKey) {
      edit();
    } else {
      newLine();
    }
  }
};

export const getCommentSaveKeysLabel = () => {
  const userAgent = navigator.userAgent;

  let modifierKey = i18next.t('COMMON.COMMENT_SAVE_LABEL');

  // Detecting MacOS based on userAgent string
  if (userAgent.includes('Mac OS X')) {
    modifierKey = i18next.t('COMMON.COMMENT_SAVE_LABEL_MAC');
  }

  return modifierKey;
};
