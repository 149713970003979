import { Tab, Tabs } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useBottomTableContext } from '../hooks';

export const BottomTableViews = () => {
  const { t } = useTranslation();
  const { views, activeView, setActiveView, isCollapsed } =
    useBottomTableContext();

  const renderViews = useCallback(() => {
    return views.map((view, index) => {
      return (
        <Tab
          key={index}
          value={view.entity}
          label={t(view.titleTranslationKey)}
        />
      );
    });
  }, [t, views]);

  return (
    <Tabs
      value={isCollapsed ? undefined : activeView.entity}
      onChange={(_, entity: Entities) => {
        setActiveView(views.find((view) => view.entity === entity));
      }}
    >
      {renderViews()}
    </Tabs>
  );
};
