import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';
import { TicketStatus } from '../Enums/TicketStatus.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputTicket<T extends EMode = EMode.entity> {
  /** Alias: iD */
  iD?: string;
  /** Alias: abschlussdatum */
  abschlussdatum?: string;
  /** Alias: abschlussdatum2 */
  abschlussdatum2?: string;
  /** Alias: ansprechpartnerCode */
  ansprechpartnerCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: bZObjMemberCode */
  bZObjMemberCode?: number;
  /** Alias: bZObjType */
  bZObjType?: BzObjType;
  /** Alias: problembeschreibung */
  problembeschreibung?: string;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: creationDateUTC */
  creationDateUTC?: string;
  /** Alias: eskalationsStufeCode */
  eskalationsStufeCode?: number;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: positionCode */
  positionCode?: number;
  /** Alias: positionCodeWartung */
  positionCodeWartung?: number;
  /** Alias: prioritaet */
  prioritaet?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: sDObjMemberCode */
  sDObjMemberCode?: number;
  /** Alias: sDObjType */
  sDObjType?: SdObjType;
  /** Alias: loesungsText */
  loesungsText?: string;
  /** Alias: status1 */
  status1?: TicketStatus;
  /** Alias: status2 */
  status2?: TicketStatus;
  /** Alias: kategorie1Code */
  kategorie1Code?: number;
  /** Alias: bearbeiter1Code */
  bearbeiter1Code?: number;
  /** Alias: bearbeiter2Code */
  bearbeiter2Code?: number;
  /** Alias: kategorie2Code */
  kategorie2Code?: number;
  /** Alias: ticketArtCode */
  ticketArtCode?: number;
  /** Alias: nummer */
  nummer?: number;
  /** Alias: titel */
  titel?: string;
  /** Alias: type */
  type?: number;
  /** Alias: wartungsobjektCode */
  wartungsobjektCode?: number;
  /** Alias: contactPerson */
  contactPerson?: string;
  /** Alias: companyName */
  companyName?: string;
  /** Alias: wiedervorlage */
  wiedervorlage?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: projektVorgangCode */
  projectProcessId?: number;
  /** Alias: zeitbedarf */
  timeRequired?: number;
  /** Alias: ertrag */
  ertrag?: number;
  /** Alias: sprintId */
  sprintId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputTicket> = {
  iD: {
    alias: 'iD',
  },
  abschlussdatum: {
    alias: 'abschlussdatum',
  },
  abschlussdatum2: {
    alias: 'abschlussdatum2',
  },
  ansprechpartnerCode: {
    alias: 'ansprechpartnerCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  bZObjMemberCode: {
    alias: 'bZObjMemberCode',
  },
  bZObjType: {
    alias: 'bZObjType',
  },
  problembeschreibung: {
    alias: 'problembeschreibung',
  },
  creationDate: {
    alias: 'creationDate',
  },
  creationDateUTC: {
    alias: 'creationDateUTC',
  },
  eskalationsStufeCode: {
    alias: 'eskalationsStufeCode',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  positionCode: {
    alias: 'positionCode',
  },
  positionCodeWartung: {
    alias: 'positionCodeWartung',
  },
  prioritaet: {
    alias: 'prioritaet',
  },
  projektCode: {
    alias: 'projektCode',
  },
  sDObjMemberCode: {
    alias: 'sDObjMemberCode',
  },
  sDObjType: {
    alias: 'sDObjType',
  },
  loesungsText: {
    alias: 'loesungsText',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  kategorie1Code: {
    alias: 'kategorie1Code',
  },
  bearbeiter1Code: {
    alias: 'bearbeiter1Code',
  },
  bearbeiter2Code: {
    alias: 'bearbeiter2Code',
  },
  kategorie2Code: {
    alias: 'kategorie2Code',
  },
  ticketArtCode: {
    alias: 'ticketArtCode',
  },
  nummer: {
    alias: 'nummer',
  },
  titel: {
    alias: 'titel',
  },
  type: {
    alias: 'type',
  },
  wartungsobjektCode: {
    alias: 'wartungsobjektCode',
  },
  contactPerson: {
    alias: 'contactPerson',
  },
  companyName: {
    alias: 'companyName',
  },
  wiedervorlage: {
    alias: 'wiedervorlage',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  projectProcessId: {
    alias: 'projektVorgangCode',
  },
  timeRequired: {
    alias: 'zeitbedarf',
  },
  ertrag: {
    alias: 'ertrag',
  },
  sprintId: {
    alias: 'sprintId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTicketEntityDefinition: EntitiyDefinition<InputTicket> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputTicket',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
