import styles from './ReportButton.module.scss';

import { Print, Star } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDialogs } from '@work4all/components';
import { useReportOptions } from '@work4all/components/lib/components/entity-picker/report-picker/ReportPicker';
import { ReportType } from '@work4all/components/lib/components/entity-picker/report-type-picker/ReportTypePicker';
import { FileIcon } from '@work4all/components/lib/components/file-icon/FileIcon';
import { SplitButton } from '@work4all/components/lib/components/split-button/SplitButton';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { Report } from '@work4all/models/lib/Classes/Report.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { ErpData } from '../../../ErpData';
import { convertEntityToReportBzObjType } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';
import { useShadowBzObjectApiContext } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

import { ReportPreviewMask } from './ReportPreviewMask';

interface ReportButtonProps {
  disabled?: boolean;
}

export function ReportButton(props: ReportButtonProps) {
  const { disabled } = props;
  const { t } = useTranslation();
  const mask = useMaskContext();
  const form = useFormContextPlus<ErpData>();

  const { persist, isDirty, positions } = useShadowBzObjectApiContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const reportBzObjectType = useMemo(() => {
    try {
      return convertEntityToReportBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);

  const bzObjectType = useMemo(() => {
    try {
      return convertEntityToBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);

  const { options: reports, data } = useReportOptions(reportBzObjectType);
  const options = useMemo(() => {
    return [
      {
        id: 'default',
        name: t('COMMON.PREVIEW'),
        reportType: ReportType.CrystalReports,
      },
      ...reports.map((x) => ({ ...x, reportType: ReportType.CrystalReports })),
    ];
  }, [reports, t]);

  const dialogs = useDialogs();

  async function openReportMask(report: Report, reportType: ReportType) {
    // Persist the shadow object first if there are changes.
    if (isDirty || mask.isCreateMode) {
      await form.handleSubmit(async () => {
        const response = await persist();
        const data = response.data.persistShadowBzObject.data;

        if (pathname.includes('/new')) {
          const path = pathname.replace('new', `${data.id}`);
          navigate(path, { replace: true });
        }

        openReportMaskWithData(
          {
            ...data,
            positionList: data.positionList.filter(
              (x) => 'posId' in x && !x.posId
            ),
          },
          report,
          reportType
        );
      })();
    } else {
      const data: ERPTypes = {
        ...form.getValues(),
        positionList: positions.filter((x) => !x.posId),
      };

      openReportMaskWithData(data, report, reportType);
    }

    function openReportMaskWithData(
      data: ERPTypes,
      report: Report,
      reportType: ReportType
    ) {
      const contact = data.businessPartnerContactCombined.contact;
      const businessPartner =
        data.businessPartnerContactCombined.businessPartner;
      const businessPartnerType = data.businessPartnerType;

      const id = data.id ?? mask.id;

      const initialView: IStackItem = {
        view: (
          <ReportPreviewMask
            id={id}
            reportBzObjectType={reportBzObjectType}
            report={report}
            reportType={reportType}
            data={data}
            contactId={contact?.id}
            businessPartnerId={businessPartner?.id}
            businessPartnerType={
              businessPartnerType === SdObjType.KUNDE
                ? Entities.customer
                : Entities.supplier
            }
            bzObjectType={bzObjectType}
            entity={mask.entity}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close() {
          dialogs.close(dialog.id);
        },
      });
    }
  }

  return (
    <>
      <Tooltip title={t('COMMON.REPORT_TYPE.Simple')}>
        <IconButton
          disabled={disabled}
          sx={{ opacity: disabled ? 0.5 : undefined }}
          onClick={() => openReportMask(null, ReportType.Simple)}
        >
          <FileIcon fileType="pdf" />
        </IconButton>
      </Tooltip>
      <SplitButton
        disabled={disabled || !reports.length}
        firstIsTitle
        options={options}
        onClick={async (option) => {
          let report = data.find((x) => x.id === option.id);
          if (!report) {
            report = data.find((x) => x.standardReport);
            if (!report) report = data[0];
          }

          openReportMask(report, option.reportType);
        }}
        renderItem={(option) => {
          const report = data.find((x) => x.id === option.id);
          return (
            <React.Fragment>
              <div className={styles.description}>{option.name}</div>
              {report?.standardReport ? (
                <Star className={styles.favActive} />
              ) : null}
            </React.Fragment>
          );
        }}
        mobileIcon={<Print />}
      />
    </>
  );
}
