import { useMemo } from 'react';
import { SortingRule } from 'react-table';

import { markSectionsByDate } from '@work4all/utils/lib/mark-sections-by-date';

import { BasicTableColumn, DataType } from '../types';

import { useTableStateBag } from './useTableStateBag';

interface UseDateSectionsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<Record<string, any> | null>;
  columns: {
    id?: number | string;
    accessor?: BasicTableColumn['accessor'];
    dataType?: DataType;
  }[];

  initialSortBy?: SortingRule<object>[];
  setSkipPageReset?: () => void;
}

export const useDateSections = (props: UseDateSectionsProps) => {
  const tableStateBag = useTableStateBag();
  return useMemo(() => {
    // prevent resetting of groupings, filters, sorting, etc on data update.
    props.setSkipPageReset?.();

    const sortBy = tableStateBag?.tableState?.sortBy ?? props.initialSortBy;
    const groupBy = tableStateBag?.tableState?.groupBy;

    const sortById = sortBy?.[0]?.id;
    const sortColumn = props.columns.find((column) => {
      const id = column.id ?? (column.accessor as string);
      return id === sortById;
    });
    const isSortedByDate = sortColumn?.dataType === DataType.Date;
    const isGrouped = groupBy != null && groupBy.length > 0;

    const items =
      props.data?.map((item) => {
        return item ?? { skeleton: true };
      }) ?? [];

    if (isSortedByDate && !isGrouped) {
      return markSectionsByDate({
        items: props.data,
        getDate(item) {
          return item[sortById];
        },
        mapFn(item, { isSectionStart, sectionLabel }) {
          return { ...item, meta: { isSectionStart, sectionLabel } };
        },
      });
    }

    return items;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);
};
