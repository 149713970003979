import { MbscCalendarEvent, MbscResource } from '@mobiscroll/react';
import { useMemo } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { RESOURCE_PROJECT_ID_PRFX } from './utils';

function sortByIndex(a: ProjectProcess, b: ProjectProcess) {
  return a.index > b.index ? 1 : -1;
}

export const useResources = (props: {
  projectList: Project[];
  compact?: boolean;
  resourceClassIdList?: number[];
  eventList: MbscCalendarEvent[];
}) => {
  const { projectList, compact = true, resourceClassIdList, eventList } = props;
  return useMemo<MbscResource[]>(() => {
    const result: MbscResource[] = [];

    for (const project of projectList) {
      const filteredProcesses =
        !resourceClassIdList || !resourceClassIdList.length
          ? project?.projectProcessList
          : project?.projectProcessList.filter((x) =>
              resourceClassIdList.includes(x.ressourceClassId)
            );

      const projectResource: MbscResource = {
        id: RESOURCE_PROJECT_ID_PRFX + project.id,
        name: `${project.number} - ${project.name}`,
        eventCreation: false,
        children: [],
        eventDragBetweenResources: false,
      };

      if (!compact) {
        const handleProcess = (process: ProjectProcess) => {
          const event = eventList.find((x) => x.id === process.id);
          const resource = {
            color:
              process.ressourceClass?.hexColorPair?.split(',')[0] ||
              'var(--ui04)',
            name: process.process,
            id: process.id,
            eventDragBetweenResources: false,
            process,
            event,
          };

          if (process.kindId === ProjectProcessKind.GLIEDERUNGSPUNKT) {
            projectResource.children.push({
              id: process.id,
              name: process.process,
              eventCreation: false,
              children: [],
              eventDragBetweenResources: false,
            });
          } else {
            const group = projectResource.children.find(
              (g) => g.id === process.parentId
            );

            if (group) {
              group.children.push(resource);
            } else {
              projectResource.children.push(resource);
            }
          }
        };

        [...filteredProcesses]
          .filter((x) => !x.parentId)
          .sort(sortByIndex)
          .forEach(handleProcess);
        [...filteredProcesses]
          .filter((x) => x.parentId)
          .sort(sortByIndex)
          .forEach(handleProcess);
      }

      result.push(projectResource);
    }

    return result;
  }, [compact, eventList, projectList, resourceClassIdList]);
};
