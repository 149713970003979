import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmailForward } from '@work4all/assets/icons/email-actions/email-forward.svg';
import { ReactComponent as EmailSendAgain } from '@work4all/assets/icons/email-actions/email-send_again.svg';

import {
  LABEL_VISIBILITY,
  MaskActionButton,
} from '@work4all/components/lib/components/MaskActionButton';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useOpenMask } from '../../hooks/use-open-mask';

export enum EMAILMODES {
  reply = 'reply',
  replyAll = 'replyAll',
  forward = 'forward',
  sendAgain = 'sendAgain',
}

interface EmailActionsProps {
  hasRight: boolean;
  id?: number;
  outbound?: boolean;
}

export const useEmailActions = (props: EmailActionsProps) => {
  const { hasRight, outbound } = props;
  const { t } = useTranslation();
  const onOpenMask = useOpenMask();

  const navigateTo = useCallback(
    (mode: EMAILMODES, id: number) => {
      if (mode === undefined || id === undefined) return;

      onOpenMask?.().handler({
        entity: Entities.eMail,
        params: {
          mode,
          templateId: String(id),
        },
      });
    },
    [onOpenMask]
  );

  const emailActionDefinitions: CustomToolbarAction[] =
    useMemo((): CustomToolbarAction[] => {
      return [
        {
          actionKey: EMAILMODES.sendAgain,
          IconComponent: EmailSendAgain,
          title: t('MASK.SEND_AGAIN'),
          disabled: !hasRight || !outbound,
          disableReason: !hasRight ? t('RIGHTS.MISSING') : undefined,
          handler: (id: number) => {
            navigateTo(EMAILMODES.sendAgain, id);
          },
          mode: 'Edit',
        },
        {
          actionKey: EMAILMODES.reply,
          IconComponent: ReplyIcon,
          title: t('MASK.REPLY'),
          disabled: !hasRight,
          disableReason: !hasRight ? t('RIGHTS.MISSING') : undefined,
          handler: (id: number) => {
            navigateTo(EMAILMODES.reply, id);
          },
          mode: 'Edit',
        },
        {
          actionKey: EMAILMODES.replyAll,
          IconComponent: ReplyAllIcon,
          title: t('MASK.REPLY_ALL'),
          disabled: !hasRight,
          disableReason: !hasRight ? t('RIGHTS.MISSING') : undefined,
          handler: (id: number) => {
            navigateTo(EMAILMODES.replyAll, id);
          },
          mode: 'Edit',
        },
        {
          actionKey: EMAILMODES.forward,
          IconComponent: EmailForward,
          title: t('MASK.FORWARD_ONTO'),
          disabled: !hasRight,
          disableReason: !hasRight ? t('RIGHTS.MISSING') : undefined,
          handler: (id: number) => {
            navigateTo(EMAILMODES.forward, id);
          },
          mode: 'Edit',
        },
      ];
    }, [outbound, hasRight, navigateTo, t]);
  return { navigateTo, emailActionDefinitions };
};

export const EmailActions = (props: EmailActionsProps) => {
  const { emailActionDefinitions } = useEmailActions(props);

  return (
    <>
      {emailActionDefinitions.map((x) => (
        <MaskActionButton
          key={x.actionKey}
          color="primary"
          labelVisibility={LABEL_VISIBILITY.HIDE}
          label={x.title}
          icon={<x.IconComponent />}
          disabledReason={x.disableReason}
          disabled={x.disabled}
          onClick={() => {
            x.handler(props.id);
          }}
        />
      ))}
    </>
  );
};
