import { TextmarksList, TextmarkType } from '../../types';

import { APPOINTMENT_TEXTMARKS } from './appointment-textmarks';
import { DOCUMENT_TEXTMARKS } from './document-textmarks';
import { EMAIL_TEXTMARKS } from './email-textmarks';
import { ERP_EMAIL_TEXTMARKS } from './erp-textmarks';
import { TICKET_TEXTMARKS } from './ticket-textmark';

export const TEXTMARKS_BY_TYPE: Record<TextmarkType, TextmarksList> = {
  appointment: APPOINTMENT_TEXTMARKS,
  document: DOCUMENT_TEXTMARKS,
  email: EMAIL_TEXTMARKS,
  erp: ERP_EMAIL_TEXTMARKS,
  ticket: TICKET_TEXTMARKS,
};
