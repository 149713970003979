import { useCallback } from 'react';

import { useDataMutation } from '@work4all/data';

import { InputRaZahlung } from '@work4all/models/lib/Classes/InputRaZahlung.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import {
  MUTATION_ENTITY_BY_VIEW_ENTITY,
  NEW_ROW_ID_PREFIX,
} from '../constants';
import { Payment, PaymentEntity } from '../types';
import { paymentToInputPayment } from '../utils';

interface Props {
  invoiceId: number;
  entity: PaymentEntity;
  onCompleted?: () => void;
}

export const usePaymentMutations = ({
  invoiceId,
  entity,
  onCompleted,
}: Props) => {
  const mutationEntity = MUTATION_ENTITY_BY_VIEW_ENTITY[entity];
  const [mutate] = useDataMutation({
    entity: mutationEntity,
    mutationType: EMode.upsert,
    responseData: { id: null },
    translateRelationInput: false,
    onCompleted: onCompleted,
  });

  const addDirectly = useCallback(
    (data: InputRaZahlung[]) => {
      mutate(
        { id: invoiceId },
        {
          relations: {
            payments: {
              add: data,
            },
          },
        }
      );
    },
    [invoiceId, mutate]
  );

  const addAndModify = useCallback(
    (data: Payment[]) => {
      if (data.length === 0) return;

      const add: InputRaZahlung[] = [];
      const modify: InputRaZahlung[] = [];

      data.forEach((payment) => {
        if (
          typeof payment.id === 'string' &&
          payment.id.startsWith(NEW_ROW_ID_PREFIX)
        ) {
          delete payment['id'];
          add.push(paymentToInputPayment(payment));
        } else {
          delete payment['__typename'];
          modify.push(paymentToInputPayment(payment));
        }
      });

      mutate({ id: invoiceId }, { relations: { payments: { add, modify } } });
    },
    [invoiceId, mutate]
  );

  const remove = useCallback(
    (paymentId: number) => {
      mutate(
        { id: invoiceId },
        { relations: { payments: { delete: [paymentId] } } }
      );
    },
    [invoiceId, mutate]
  );

  return { addAndModify, remove, addDirectly };
};
