import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { PriceGroup } from './PriceGroup.entity';

export class SinglePrice<T extends EMode = EMode.entity> {
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: festpreis */
  isFixedPrice?: boolean;
  /** Alias: minutenLohn */
  minuteWage?: number;
  /** Alias: preis */
  price?: number;
  /** Alias: preisgruppe */
  priceGroupId?: number;
  /** Alias: zuschlag */
  surcharge?: number;
  /** Alias: preisgruppeItem */
  priceGroup?: PriceGroup<T>;

  __typename?: string;
}

const fields: FieldDefinitions<SinglePrice> = {
  articleId: {
    alias: 'artikelCode',
  },
  id: {
    alias: 'code',
  },
  isFixedPrice: {
    alias: 'festpreis',
  },
  minuteWage: {
    alias: 'minutenLohn',
  },
  price: {
    alias: 'preis',
  },
  priceGroupId: {
    alias: 'preisgruppe',
  },
  surcharge: {
    alias: 'zuschlag',
  },
  priceGroup: {
    alias: 'preisgruppeItem',
    entity: Entities.priceGroup,
  },
  __typename: {
    alias: '__typename',
  },
};

export const singlePriceEntityDefinition: EntitiyDefinition<SinglePrice> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Einzelpreis',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
