import { useMemo } from 'react';
import { Row } from 'react-table';

import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  MaskTemplateEntity,
  OnOpenMask,
} from '../../containers/mask-overlays/mask-overlay/types';

import { useEntityHandlers } from './use-entity-handlers';

export function useTableHandlers(
  entity: Entities,
  onOpenMask: OnOpenMask,
  template: MaskTemplateEntity | null = null
) {
  const tableStateBag = useTableStateBag();

  const entries = useMemo(() => {
    return (
      (
        tableStateBag.tableInstance?.selectedFlatRows.filter(
          (x) => !x.isGrouped
        ) as unknown as Row<{ id: string }>[]
      )?.map((x) => x.original) || []
    );
  }, [tableStateBag.tableInstance?.selectedFlatRows]);

  return useEntityHandlers({
    entity,
    onOpenMask,
    template,
    entries,
  });
}
