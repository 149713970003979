import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputTextbaustein<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: name */
  name?: string;
  /** Alias: grCode */
  grCode?: number;
  /** Alias: text */
  text?: string;
  /** Alias: rtfText */
  rtfText?: string;
  /** Alias: letzteAenderung */
  letzteAenderung?: string;
  /** Alias: erpStandardTextBausteinType */
  erpStandardTextBausteinType?: any;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputTextbaustein> = {
  code: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  grCode: {
    alias: 'grCode',
  },
  text: {
    alias: 'text',
  },
  rtfText: {
    alias: 'rtfText',
  },
  letzteAenderung: {
    alias: 'letzteAenderung',
  },
  erpStandardTextBausteinType: {
    alias: 'erpStandardTextBausteinType',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTextbausteinEntityDefinition: EntitiyDefinition<InputTextbaustein> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTextbaustein',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
