import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputLetterDocumentFileRelation<T extends EMode = EMode.entity> {
  /** Alias: templateCode */
  templateCode?: number;
  /** Alias: tempFileId */
  tempFileId?: string;
  /** Alias: targetfileName */
  targetfileName?: string;
  /** Alias: subDirectory */
  subDirectory?: string;
  /** Alias: displayName */
  displayName?: string;
  /** Alias: bodyHtml */
  bodyHtml?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputLetterDocumentFileRelation> = {
  templateCode: {
    alias: 'templateCode',
  },
  tempFileId: {
    alias: 'tempFileId',
  },
  targetfileName: {
    alias: 'targetfileName',
  },
  subDirectory: {
    alias: 'subDirectory',
  },
  displayName: {
    alias: 'displayName',
  },
  bodyHtml: {
    alias: 'bodyHtml',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputLetterDocumentFileRelationEntityDefinition: EntitiyDefinition<InputLetterDocumentFileRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputLetterDocumentFileRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
