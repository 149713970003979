import styles from './MailboxContentPreview.module.scss';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  InfoCard,
  InfoCards,
  PreviewTitle,
  PreviewWrapper,
} from '@work4all/components/lib/components/entity-preview/components';
import { setDefaultHeadStyles } from '@work4all/components/lib/components/entity-preview/utils';
import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';

import { usePageZoom } from '@work4all/data';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { SuggestionsFoundText } from './cells/components/SuggestionsFoundText';
import { ContactOrBusinessPartner } from './cells/contact-or-business-partner';
import { ContactOrBusinessPartnerPicker } from './cells/ContactOrBusinessPartnerPicker';
import { ContactText } from './cells/ContactText';
import { ProjectPickerWithSuggestions } from './cells/ProjectPickerWithSuggestions';
import { ProjectText } from './cells/ProjectText';
import { useMailboxContentManagerContext } from './mailbox-content-manager-context';
import { useReadMail } from './use-read-mail';

export type MailboxContentPreviewProps = {
  mailboxContent: MailboxContent;
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
  onVisibilityToggle?: (visible: boolean) => void;
};

export function MailboxContentPreview(props: MailboxContentPreviewProps) {
  const { mailboxContent, onCloseClick, onVisibilityToggle } = props;

  const { t } = useTranslation();

  const { zoomLevel } = usePageZoom();

  const isEditable =
    mailboxContent.assignStatus === MailAssignStatus.NOT_YET_ASSIGNED;

  const managerContext = useMailboxContentManagerContext();

  const mailboxContentState = managerContext.state.itemsById[mailboxContent.id];

  const currentMail = useReadMail({
    folderId: managerContext.state.folder.folder,
    mailboxId: managerContext.state.folder.mailbox,
    mailId: mailboxContentState.data.id,
  });

  const renderableBody = currentMail.data?.readMail?.renderableBody;

  const currentContact = mailboxContentState.contact;
  const savedContact =
    mailboxContent.convertedMail?.contact ??
    mailboxContent.convertedMail?.businessPartner.data;

  const contact = (isEditable ? currentContact : savedContact) ?? null;

  const project =
    (isEditable
      ? mailboxContentState.project
      : mailboxContent.convertedMail?.project) ?? null;

  const possibleSenders = mailboxContent.possibleSenders;
  const possibleProjects = mailboxContent.possibleProjects;

  const srcDoc = useMemo(() => {
    return (
      setDefaultHeadStyles({
        value: renderableBody || '',
        zoom: zoomLevel,
      }) || renderableBody
    );
  }, [renderableBody, zoomLevel]);

  const formattedDate = useMemo(() => {
    return formatDateString(
      mailboxContent.date,
      DateFormatPreset.DATE_TIME_SIMPLE
    );
  }, [mailboxContent.date]);

  const toList = useMemo(() => {
    return splitEmails(mailboxContent.to);
  }, [mailboxContent.to]);

  const ccList = useMemo(() => {
    return splitEmails(mailboxContent.cc);
  }, [mailboxContent.cc]);

  const [showAllTo, setShowAllTo] = useState(false);
  const [showAllCc, setShowAllCc] = useState(false);

  useEffect(() => {
    setShowAllTo(false);
    setShowAllCc(false);
  }, [mailboxContent.id]);

  function renderEmailList({
    list,
    showAll,
    setShowAll,
  }: {
    list: string[];
    showAll: boolean;
    setShowAll: (value: boolean) => void;
  }) {
    return (
      <Typography>
        {list.length === 0 && '-'}

        {(showAll ? list : list.slice(0, 3)).map((value, index) => {
          return <Chip key={index} label={value} maxWidth={18} />;
        })}

        {!showAll && list.length > 3 && (
          <span className={styles.blueAction} onClick={() => setShowAll(true)}>
            {`+${list.length - 3} ${t('FAV_LINKS.MORE')}`}
          </span>
        )}
      </Typography>
    );
  }

  function renderAlreadyAssignedBanner() {
    const email = mailboxContent.convertedMail;

    return (
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.success.main,
          color: theme.palette.text.inverse,
          px: '0.5rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0.5rem',
        })}
      >
        <CheckCircleIcon />

        <Typography variant="caption" color="inherit" sx={{ py: '1rem' }}>
          {email != null ? (
            <Trans
              i18nKey={'MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED_EXTENDED'}
              values={{
                user: email.user.displayName,
                date: formatDateString(
                  email.insertTime,
                  DateFormatPreset.DATE_SIMPLE_4YEAR
                ),
              }}
            />
          ) : (
            t('MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED')
          )}
        </Typography>
      </Box>
    );
  }

  const handleContactChange = (value: ContactOrBusinessPartner): void => {
    managerContext.onContactChange(mailboxContent.id, value);
  };

  const handleProjectChange = (value: Project): void => {
    managerContext.onProjectChange(mailboxContent.id, value);
  };

  const isAssigned =
    mailboxContent.assignStatus === MailAssignStatus.ALREADY_ASSIGNED ||
    mailboxContent.assignStatus === MailAssignStatus.ASSIGNED_TO_OBJECT ||
    mailboxContent.assignStatus ===
      MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL;

  return (
    <PreviewWrapper>
      <div className={styles.wrap}>
        <PreviewTitle
          readonly
          onCloseClick={onCloseClick}
          onVisibilityToggle={onVisibilityToggle}
        >
          {mailboxContent.title}
        </PreviewTitle>

        {isAssigned && renderAlreadyAssignedBanner()}

        <iframe
          className={styles.content}
          title="E-Mail Preview"
          srcDoc={srcDoc}
        />

        <Divider orientation="horizontal" />

        <InfoCards>
          <InfoCard staticField label={t('MASK.SENDER')}>
            {mailboxContent.from}
          </InfoCard>

          <InfoCard staticField label={t('COMMON.DATE.TIME')}>
            {formattedDate}
          </InfoCard>

          <InfoCard column="1/3" staticField label={t('MASK.TO')}>
            {renderEmailList({
              list: toList,
              showAll: showAllTo,
              setShowAll: setShowAllTo,
            })}
          </InfoCard>

          <InfoCard column="1/3" staticField label={t('MASK.CC')}>
            {renderEmailList({
              list: ccList,
              showAll: showAllCc,
              setShowAll: setShowAllCc,
            })}
          </InfoCard>
        </InfoCards>

        <Divider orientation="horizontal" />

        <InfoCards>
          <EntityPickerPopover
            disabled={!isEditable}
            picker={
              <ContactOrBusinessPartnerPicker
                value={contact}
                onChange={handleContactChange}
                suggestions={possibleSenders}
              />
            }
          >
            <InfoCard staticField={!isEditable} label={t('COMMON.CONTACT')}>
              {contact || !isEditable ? (
                <ContactText contact={contact} />
              ) : (
                <SuggestionsFoundText suggestions={possibleSenders} />
              )}
            </InfoCard>
          </EntityPickerPopover>

          <EntityPickerPopover
            disabled={!isEditable}
            picker={
              <ProjectPickerWithSuggestions
                value={project}
                onChange={handleProjectChange}
                suggestions={possibleProjects}
              />
            }
          >
            <InfoCard staticField={!isEditable} label={t('COMMON.PROJECT')}>
              {project || !isEditable ? (
                <ProjectText project={project} />
              ) : (
                <SuggestionsFoundText suggestions={possibleProjects} />
              )}
            </InfoCard>
          </EntityPickerPopover>
        </InfoCards>
      </div>
    </PreviewWrapper>
  );
}

function splitEmails(emailsString: string): string[] {
  if (!emailsString) return [];

  return emailsString
    .split(';')
    .map((x) => x.trim())
    .filter(Boolean);
}
