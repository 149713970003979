import { ILayoutEntityUIConfigColumn } from '@work4all/models/lib/Layout';

import { WIDGETS_ARRAY_COLUMNS_BY_FILE_TYPE } from './constants';
import { UseWidgetArrayColumnsProps } from './types';

export const widgetArrayColumns = ({
  entity,
  fileType,
}: UseWidgetArrayColumnsProps) => {
  const arrayColumnsFields = {};
  const arrayColumnsUIConfig: ILayoutEntityUIConfigColumn[] = [];

  const columns = (WIDGETS_ARRAY_COLUMNS_BY_FILE_TYPE[fileType] ?? {})[entity];

  if (columns) {
    columns.forEach((column) => {
      arrayColumnsFields[column.accessor] = [column.data];
      arrayColumnsUIConfig.push({
        accessor: column.accessor,
        type: column.type,
      });
    });
  }

  return { arrayColumnsFields, arrayColumnsUIConfig };
};
