import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownloadIcon } from '@work4all/assets/icons/download.svg';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

export const Download: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <IconButtonWithTooltip
      tooltip={t('INPUTS.DOWNLOAD')}
      icon={<DownloadIcon style={{ width: '1.5rem', height: '1.5rem' }} />}
      {...props}
    />
  );
};
