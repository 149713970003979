import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

export class StorageLocation<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: hauptlager */
  isMainStorageLocation?: boolean;
  /** Alias: unsichtbar */
  invisible?: boolean;
  /** Alias: reparaturLager */
  isMaintenanceStorage?: boolean;
  /** Alias: lagerortNummer */
  number?: string;
  /** Alias: standort */
  location?: string;
  /** Alias: halle */
  hall?: string;
  /** Alias: regal */
  shelf?: string;
  /** Alias: fach */
  compartment?: string;
  /** Alias: ebene */
  level?: string;
  /** Alias: groesse */
  size?: string;
  /** Alias: maximaleBoxBreite */
  maxBoxWidth?: number;
  /** Alias: type */
  type?: number;
  /** Alias: typeMemberCode */
  typeMemberId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<StorageLocation> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  isMainStorageLocation: {
    alias: 'hauptlager',
  },
  invisible: {
    alias: 'unsichtbar',
  },
  isMaintenanceStorage: {
    alias: 'reparaturLager',
  },
  number: {
    alias: 'lagerortNummer',
  },
  location: {
    alias: 'standort',
  },
  hall: {
    alias: 'halle',
  },
  shelf: {
    alias: 'regal',
  },
  compartment: {
    alias: 'fach',
  },
  level: {
    alias: 'ebene',
  },
  size: {
    alias: 'groesse',
  },
  maxBoxWidth: {
    alias: 'maximaleBoxBreite',
  },
  type: {
    alias: 'type',
  },
  typeMemberId: {
    alias: 'typeMemberCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const storageLocationEntityDefinition: EntitiyDefinition<StorageLocation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'Lagerort',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
