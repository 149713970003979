import { DateTime } from 'luxon';

import { UserPresenceTimeInfo } from '@work4all/models/lib/Classes/UserPresenceTimeInfo.entity';
import { TimeStampKind } from '@work4all/models/lib/Enums/TimeStampKind.enum';

import { TimePreviewActions } from './types';

export const calculateActionDependsOnBlock = (
  block: UserPresenceTimeInfo
): TimePreviewActions[] => {
  if (!block) return ['Add'];

  const last = block.items[block.items.length - 1].kind;
  if (last === TimeStampKind.TAG_BEENDET) return ['Add'];
  if (last === TimeStampKind.PAUSENBEGINN) return ['Resume'];

  return ['Pause', 'End'];
};

type IsValidPauseProps = {
  start: string | undefined;
  end: string | undefined;
  minimumPauseAmount: number;
};

export const isValidPause = ({
  start,
  end,
  minimumPauseAmount = 0,
}: IsValidPauseProps) => {
  if (!start || !end || minimumPauseAmount === 0) return true;

  const startDateTime = DateTime.fromISO(start);
  const endDateTime = DateTime.fromISO(end);
  const pauseAmount = endDateTime.diff(startDateTime, ['minutes']).minutes;

  return pauseAmount >= minimumPauseAmount;
};
