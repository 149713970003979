import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ArticleInventoryInfo<T extends EMode = EMode.entity> {
  /** Alias: inventoryValueFIFO */
  inventoryValueFIFO?: number;
  /** Alias: inventoryTotalValueFIFO */
  inventoryTotalValueFIFO?: number;
  /** Alias: totalInventoryAmount */
  totalInventoryAmount?: number;
  /** Alias: supplierOrderdAmount */
  supplierOrderdAmount?: number;
  /** Alias: supplierOrderBacklogAmount */
  supplierOrderBacklogAmount?: number;
  /** Alias: reservedAmount */
  reservedAmount?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ArticleInventoryInfo> = {
  inventoryValueFIFO: {
    alias: 'inventoryValueFIFO',
  },
  inventoryTotalValueFIFO: {
    alias: 'inventoryTotalValueFIFO',
  },
  totalInventoryAmount: {
    alias: 'totalInventoryAmount',
  },
  supplierOrderdAmount: {
    alias: 'supplierOrderdAmount',
  },
  supplierOrderBacklogAmount: {
    alias: 'supplierOrderBacklogAmount',
  },
  reservedAmount: {
    alias: 'reservedAmount',
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleInventoryInfoEntityDefinition: EntitiyDefinition<ArticleInventoryInfo> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ArticleInventoryInfo',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
