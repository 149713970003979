import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useValidateEmail = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validate = (email: string, showError = true) => {
    if (!email) return false;
    const isValid = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!isValid && showError) {
      enqueueSnackbar(t('ERROR.INVALID_EMAIL_ADDRESS', { email: email }), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    return isValid;
  };

  return validate;
};
