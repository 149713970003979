import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ErpStatusPicker,
  ErpStatusPickerProps,
} from '@work4all/components/lib/components/entity-picker/erp-status-picker/ErpStatusPicker';
import {
  mapFromErpStatus,
  useMapErpStatus,
} from '@work4all/components/lib/components/entity-picker/erp-status-picker/use-map-erp-status';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface ErpStatusPickerFieldProps<TMultiple extends boolean>
  extends Omit<
    ErpStatusPickerProps<TMultiple>,
    'multiple' | 'value' | 'onChange'
  > {
  label?: string;
  error?: string;
  disabled?: boolean;
  field?: JSX.Element;
  multiple?: boolean;
  isClosed: boolean;
  frozen: boolean;
  onChange: (isClosed: boolean, frozen: boolean) => void;
}
const createErpStatusPickerField = <TMultiple extends boolean>() => {
  return React.forwardRef<HTMLDivElement, ErpStatusPickerFieldProps<TMultiple>>(
    function DeliveryNotesPickerField(props, ref) {
      const {
        field: _field,
        error: _error,
        disabled: _disabled,
        label: _label,
        multiple: _multiple,
        ...pickerProps
      } = props;
      const { isClosed, frozen, onChange } = pickerProps;
      const { t } = useTranslation();

      const popoverRef = useRef<EntityPickerPopover>(null);

      const { status, stringValue } = useMapErpStatus(frozen, isClosed);
      return (
        <EntityPickerPopover
          ref={popoverRef}
          picker={
            <ErpStatusPicker
              multiple={false}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value={{ id: status, name: stringValue } as any}
              onChange={(value) => {
                const { frozen, isClosed } = mapFromErpStatus(value?.id);
                onChange(frozen, isClosed);
              }}
            />
          }
        >
          <PickerTargetButton
            ref={ref}
            label={t('COMMON.STATUS')}
            value={stringValue}
            disabled={_disabled}
          />
        </EntityPickerPopover>
      );
    }
  );
};

export const ErpStatusPickerField = createErpStatusPickerField();
