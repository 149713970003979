import type { Environment } from '../environment.types';

// This constant will be replaced after build when the Docker container is
// started. This allows us to deploy the same image to both staging and
// production environments.

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const configuration: string = '__INJECTED__W4A_CONFIGURATION__DO_NOT_CHANGE';
const fallbackConfiguration = 'production';

function resolveReplacedConfiguration(): Environment['configuration'] {
  // Use `startsWith()` instead of `!==` so that this code path is not removed
  // by webpack during optimization.
  if (configuration.startsWith('__INJECTED')) {
    const errorMessage = [
      `The configuration name was not injected.`,
      `Falling back to "${fallbackConfiguration}".`,
    ].join(' ');

    console.error(errorMessage);

    return fallbackConfiguration;
  }

  // At this point we trust that the injected value is "correct" and don't
  // validate anything.
  return configuration as Environment['configuration'];
}

const isProd = resolveReplacedConfiguration() === 'production';

export const environment: Environment = {
  configuration: resolveReplacedConfiguration(),
  nativeShareBaseUrl: isProd
    ? 'https://app.work4all.com'
    : 'https://staging.work4all.de',
  devtools: {
    graphQLExplorer: false,
  },
  entities: {
    task: true,
    note: true,
    appointment: true,
    callMemo: true,
    eMail: true,
    letter: true,
    document: true,
    ticket: true,
    customer: true,
    supplier: true,
    contact: true,
    project: true,
    'file-settings': true,
    eMailTemplate: true,
    textBuildingBlock: true,
    eMailSignature: true,
    timeTracking: true,
    salesOpportunities: true,
    deliveryNote: true,
    article: true,
    ra: true,
    raViewModel: true,
    travelReceipts: true,
    addressConnection: true,
    inboundInvoice: true,
    offer: true,
    invoice: true,
    order: true,
    contract: true,
    calculation: true,
    demand: true,
    inboundDeliveryNote: true,
    reViewModel: true,
  },
};
