import { useMutation } from '@apollo/client';

import { CONVERT_INVOICE_TO_RA_MUTATION } from '../constants';
import {
  ConvertInvoiceToRaMutationConvertFunction,
  ConvertInvoiceToRaMutationResponse,
  ConvertInvoiceToRaMutationVariables,
  UseConvertInvoiceToRaMutationProps,
} from '../types';

export const useConvertInvoiceToRaMutation = ({
  invoiceId,
  reportId,
}: UseConvertInvoiceToRaMutationProps) => {
  const [convertMutation] = useMutation<
    ConvertInvoiceToRaMutationResponse,
    ConvertInvoiceToRaMutationVariables
  >(CONVERT_INVOICE_TO_RA_MUTATION);

  const convert: ConvertInvoiceToRaMutationConvertFunction = async () => {
    return await convertMutation({
      variables: {
        invoiceId,
        reportId,
        createArchivePdf: true,
        awaitArchivePdf: true,
      },
    }).then((response) => response.data);
  };

  return convert;
};
