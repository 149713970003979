import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { AppearanceSettingsInputAddBackgroundImage } from './AppearanceSettingsInputAddBackgroundImage.entity';

export class AppearanceSettingsInput<T extends EMode = EMode.entity> {
  /** Alias: remove */
  remove?: string[];
  /** Alias: add */
  add?: AppearanceSettingsInputAddBackgroundImage<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<AppearanceSettingsInput> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  __typename: {
    alias: '__typename',
  },
};

export const appearanceSettingsInputEntityDefinition: EntitiyDefinition<AppearanceSettingsInput> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'AppearanceSettingsInput',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
