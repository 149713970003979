import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { IconButton, Slider, Stack } from '@mui/material';

export type ZoomValue = 1 | 2 | 3 | 4 | 5;

interface IProps {
  zoom: ZoomValue;
  onZoomChange?: (zoom: ZoomValue) => void;
}

export const TimelineZoom = (props: IProps) => {
  const { zoom, onZoomChange } = props;

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        color="primary"
        disabled={zoom <= 1}
        onClick={() => {
          onZoomChange?.((zoom - 1) as ZoomValue);
        }}
      >
        <ZoomOut />
      </IconButton>
      <Slider
        size="small"
        value={zoom}
        min={1}
        max={5}
        step={1}
        sx={{ width: '6rem' }}
        marks
        onChange={(_, value: ZoomValue) => {
          onZoomChange?.(value);
        }}
      />
      <IconButton
        color="primary"
        disabled={zoom >= 5}
        onClick={() => {
          onZoomChange?.((zoom + 1) as ZoomValue);
        }}
      >
        <ZoomIn />
      </IconButton>
    </Stack>
  );
};
