import styles from './CreateEditFilter.module.scss';

import { Button, Popover, RadioGroup, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs, useTableStateBag } from '@work4all/components';
import { useTablePrefilterContext } from '@work4all/components/lib/components/table/TablePrefilterProvider';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item/CheckboxRadioItem';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useDataMutation, useFormPlus, useUser } from '@work4all/data';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { ObjectTypeByEntity } from '@work4all/models';
import { SavedListFilter } from '@work4all/models/lib/Classes/SavedListFilter.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { useJSONSchemaResolver } from '@work4all/utils';

import { ControllerPlus } from '../../../../containers/mask-overlays/form-plus/controller-plus';
import { ControlWrapper } from '../../../../containers/mask-overlays/mask-overlay/components';
import { stringifyTableSettings } from '../../../data-tables/make-table-settings';
import { VERSION } from '../constants/version';
import { CreateEditFilterProps } from '../interfaces/CreateEditFilterProps';

export const CreateEditFilter = ({
  target,
  onClose,
  defaultValues,
  filtersList,
  entity,
}: CreateEditFilterProps) => {
  const user = useUser();
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const tableStateBag = useTableStateBag();

  const schema = useEntityJsonSchema(Entities.savedListFilter);
  const resolver = useJSONSchemaResolver(schema);
  const { register, control, formState, getValues, watch, setValue } =
    useFormPlus<SavedListFilter>({
      resolver,
      mode: 'onChange',
      defaultValues,
    });

  const [mutate] = useDataMutation({
    entity: Entities.savedListFilter,
    mutationType: EMode.upsert,
    responseData: { id: null, name: null, global: null, filter: null },
  });

  const { prefilter } = useTablePrefilterContext();

  const { dirtyFields } = formState;
  useEffect(() => {
    const filterConfig = JSON.stringify({
      filter: stringifyTableSettings({
        filter: [
          ...tableStateBag.tableState.filters.map((filter) => {
            return { id: filter.id, value: filter.value?.value };
          }),
        ],
        sort: tableStateBag.tableState.sortBy.map((sort) => {
          return {
            id: sort.id,
            desc: sort.desc,
          };
        }),
      }),
      visibleColumns: tableStateBag.visibleColumns.map((col) => col.id),
      groupBy: tableStateBag.tableState.groupBy,
      prefilter,
    });

    setValue('filter', filterConfig, { shouldDirty: true });
  }, [
    prefilter,
    entity,
    setValue,
    tableStateBag.tableState.filters,
    tableStateBag.tableState.groupBy,
    tableStateBag.tableState.sortBy,
    tableStateBag.visibleColumns,
  ]);

  const handleSave = async () => {
    const data: SavedListFilter = {};

    if (defaultValues?.id) {
      data['id'] = defaultValues.id;
    } else {
      data['userId'] = user.benutzerCode;
      data['version'] = VERSION;
      data['objectType'] = ObjectTypeByEntity[entity] as ObjectType;

      if (data?.global === undefined) {
        data['global'] = defaultValues?.global;
      }
    }

    Object.keys(dirtyFields).forEach((key) => {
      data[key] = getValues(key as keyof SavedListFilter);
    });

    const filterWithTheSameName = filtersList.find(
      (filter) => filter.name === data.name
    );
    if (filterWithTheSameName) {
      const confirmed = await dialogs.confirm({
        title: t('ALERTS.CONFIRM'),
        description: t('TICKET.OVERWRITE_ALERT'),
        confirmLabel: t('ALERTS.BTN_SAVE'),
        cancelLabel: t('ALERTS.BTN_ABORT'),
      });

      if (!confirmed) return;
      data['id'] = filterWithTheSameName.id;
    }

    mutate(data);
    onClose();
  };

  const name = watch('name');
  const isSaveButtonDisabled = useMemo(() => {
    if (defaultValues?.id) {
      return Object.keys(dirtyFields).length === 0;
    }

    return !name;
  }, [defaultValues?.id, dirtyFields, name]);

  return (
    <Popover open={!!target} anchorEl={target} onClose={onClose}>
      <div className={styles.container}>
        <Typography variant="body2" fontWeight="700">
          {t('COMMON.FILTER')}
        </Typography>

        <div>
          <LabeledInput
            label={t('COMMON.NAME')}
            required
            defaultValue={defaultValues?.name}
            autoComplete="off"
            autoFocus={true}
            {...register('name')}
          />

          <ControllerPlus
            control={control}
            name="global"
            render={({ field }) => (
              <ControlWrapper
                as={RadioGroup}
                className={styles.radioButtons}
                grid
              >
                <CheckboxRadioItem
                  className={styles.inlineRadio}
                  horizontalPadding={false}
                  label={t('TICKET.GENERAL')}
                  value="global"
                  control="radio"
                  onChange={(e) => {
                    field.onChange(e.target.checked ? true : false);
                  }}
                  checked={field.value ?? defaultValues?.global ? true : false}
                  disabled={!user.isMaster}
                />
                <CheckboxRadioItem
                  className={styles.inlineRadio}
                  horizontalPadding={false}
                  label={t('TICKET.PERSONAL')}
                  value="personal"
                  control="radio"
                  onChange={(e) => {
                    field.onChange(e.target.checked ? false : true);
                  }}
                  checked={field.value ?? defaultValues?.global ? false : true}
                />
              </ControlWrapper>
            )}
          />
        </div>

        <div className={styles.actions}>
          <Button variant="text" onClick={onClose}>
            {t('ALERTS.BTN_ABORT')}
          </Button>

          <Button
            variant="text"
            onClick={handleSave}
            disabled={isSaveButtonDisabled}
          >
            {t('ALERTS.BTN_SAVE')}
          </Button>
        </div>
      </div>
    </Popover>
  );
};
