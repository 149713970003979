import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { LedgerAccount } from './LedgerAccount.entity';
import { TaxGroup } from './TaxGroup.entity';
import { VatRate } from './VatRate.entity';

export class ArticleLedgerAccount<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: articleCode */
  articleId?: number;
  /** Alias: taxGroupCode */
  taxGroupId?: number;
  /** Alias: accountNumber */
  accountNumber?: number;
  /** Alias: ledgerAccount */
  ledgerAccount?: LedgerAccount<T>;
  /** Alias: mehrwertsteuerSatz */
  vatRate?: VatRate<T>;
  /** Alias: taxGroup */
  taxGroup?: TaxGroup<T>;
  /** Alias: vatCode */
  vatRateId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<ArticleLedgerAccount> = {
  id: {
    alias: 'code',
  },
  articleId: {
    alias: 'articleCode',
  },
  taxGroupId: {
    alias: 'taxGroupCode',
  },
  accountNumber: {
    alias: 'accountNumber',
  },
  ledgerAccount: {
    alias: 'ledgerAccount',
    entity: Entities.ledgerAccount,
  },
  vatRate: {
    alias: 'mehrwertsteuerSatz',
    entity: Entities.vatRate,
  },
  taxGroup: {
    alias: 'taxGroup',
    entity: Entities.taxGroup,
  },
  vatRateId: {
    alias: 'vatCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const articleLedgerAccountEntityDefinition: EntitiyDefinition<ArticleLedgerAccount> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ArticleLedgerAccount',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
