/*
 * Some entities e.g. `inboundInvoice` has what we call
 * a `View Modal Entity` which is an entity to query
 * the entity data, and a `Mutation Entity` which
 * is an entity to mutate the entity data.
 */

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const mutationEntityToViewEntity = (entity: Entities) => {
  switch (entity) {
    case Entities.inboundInvoice:
      return Entities.reViewModel;
    case Entities.ra:
      return Entities.raViewModel;
    default:
      return entity;
  }
};
