import {
  ORGANISATION_AREA_SECTIONS_CONFIGS,
  ORGANISATION_AREA_UNAVAILABLE_SECTIONS_BY_ENTITY,
} from '../constants';
import { OrganisationAreaEntity, OrganisationAreaSectionType } from '../types';

export const availableSectionsConfigs = (
  entity: OrganisationAreaEntity,
  unwanted: OrganisationAreaSectionType[] = []
) => {
  const unavailable =
    ORGANISATION_AREA_UNAVAILABLE_SECTIONS_BY_ENTITY[entity] ?? [];
  const sectionsToRemove = [...unavailable, ...unwanted];

  if (sectionsToRemove.length === 0) return ORGANISATION_AREA_SECTIONS_CONFIGS;

  return ORGANISATION_AREA_SECTIONS_CONFIGS.filter(
    (section) => !sectionsToRemove.includes(section.id)
  );
};
