import styles from './../../MaskOverlay.module.scss';

import { DateTime } from 'luxon';
import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks/object-lock';

import { IUser, useDataMutation, useFormPlus, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';
import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { InputSalesOpportunityAttachementRelation } from '@work4all/models/lib/Classes/InputSalesOpportunityAttachementRelation.entity';
import { InputVerkaufschanceRelation } from '@work4all/models/lib/Classes/InputVerkaufschanceRelation.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { SalesOpportunityGroup } from '@work4all/models/lib/Classes/SalesOpportunityGroup.entity';
import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { FieldToErrorMap, useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddSalesOpportunities,
  canDeleteSalesOpportunities,
  canEditSalesOpportunities,
} from '@work4all/utils/lib/permissions';

import {
  getValuesOnProbabilityChange,
  getValuesOnRatingStatusChange,
  getValuesOnStatusChange,
} from '../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-evaluation-picker/utils';
import { SalesOpportunitiesFields } from '../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-preview/SalesOpportunitiesContainer';
import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlayStandardSubmitButton } from '../../components/MaskOverlayStandardSubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';
import {
  CurrencyExchangeInfoContext,
  CurrencyExchangeInfoContextValue,
} from '../inbound-invoice/currency-exchange-info-context';
import { CurrencyExchangeInfoContextProvider } from '../inbound-invoice/CurrencyExchangeInfoContextProvider';

import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { SalesOpportunitiesMaskFormValue } from './types';

export enum TabKeys {
  general = 'general',
  attachments = 'attachments',
  history = 'history',
  individual = 'individual',
}
export const SalesOpportunitiesOverlayController = (
  props: MaskControllerProps
) => {
  return (
    <CurrencyExchangeInfoContextProvider>
      <SalesOpportunitiesOverlayControllerInternal {...props} />;
    </CurrencyExchangeInfoContextProvider>
  );
};

const SalesOpportunitiesOverlayControllerInternal = (
  props: MaskControllerProps
) => {
  const user = useUser();

  const { t } = useTranslation();

  const exchangeInfo = useContext(CurrencyExchangeInfoContext);

  const mask = useMaskConfig(props);

  const template = useAssignableTemplateEntity(mask);

  const customFields = useCustomFieldsConfig({
    entity: Entities.salesOpportunities,
  });

  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: mask.id } }];
    const entity = Entities.salesOpportunities;
    const data: SalesOpportunities<EMode.query> = {
      ...SalesOpportunitiesFields,
      status: null,
      customFieldList: [CUSTOM_FIELDS_DATA],
      competitors: null,
      creatorUserId: null,
      user: {
        id: null,
        displayName: null,
      },
      user2: {
        id: null,
        displayName: null,
      },
      userId: null,
      user2Id: null,
      contactId: null,
      attachementList: [
        {
          id: null,
          fileKey: null,
          fileDisplayname: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          salesOpportunityCode: null,
          insertTime: null,
          updateTime: null,
          userId: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
    };
    return { filter, entity, data };
  }, [mask.id]);

  const initialFormValue = useInitialFormValue<SalesOpportunities>(
    requestData,
    mask.isCreateMode
  );

  const newEntityData = useMemo(() => {
    const data = createSalesOppotunityDefaultData({
      template,
      user,
      exchangeInfo,
      isCreateMode: mask.isCreateMode,
    });

    return data;
  }, [template, user, exchangeInfo, mask.isCreateMode]);

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    return normalizeCustomFields(
      normalizeFormValue<SalesOpportunities>(dataRaw),
      customFields
    );
  }, [dataRaw, customFields]);

  const customRules = useCallback(
    (data: SalesOpportunitiesMaskFormValue) => {
      const errors: FieldToErrorMap = {};

      if (!data?.name)
        errors['name'] = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };

      if (data?.decisionDate) {
        const date = mask.isEditMode
          ? DateTime.fromISO(data.dateCreated)
          : DateTime.now();
        const currentDate = DateTime.fromISO(data.decisionDate);
        if (date > currentDate)
          errors['decisionDate'] = {
            message: t('ERROR.INVALID_VALUE'),
            type: 'customValidation',
          };
      }
      if (data?.dateNextReview) {
        const date = mask.isEditMode
          ? DateTime.fromISO(data.dateCreated)
          : DateTime.now();
        const currentDate = DateTime.fromISO(data.dateNextReview);
        if (date > currentDate)
          errors['dateNextReview'] = {
            message: t('ERROR.INVALID_VALUE'),
            type: 'customValidation',
          };
      }

      return Object.entries(errors).length ? errors : true;
    },
    [t, mask.isEditMode]
  );

  const files = useMemo(() => {
    return data?.attachementList?.map((attachement) => {
      return {
        ...attachement,
        fileName: attachement.fileInfos?.fileEntityFilename,
        date: attachement.updateTime,
      };
    });
  }, [data?.attachementList]);
  const tempFileManager = useTempFileManager(files);
  const attachementsRelation =
    useAttachementsRelation<InputSalesOpportunityAttachementRelation>(
      tempFileManager,
      Entities.salesOpportunityAttachement,
      'id'
    );

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const form = useFormPlus<SalesOpportunitiesMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { formState, handleSubmit, reset, getValues } = form;

  useLock(
    {
      subEntityType: Entities.salesOpportunities,
      subEntityIds: data.id?.toString() ? [data.id.toString()] : [],
    },
    [data]
  );

  const { saveSearchItemFromEnityData } = useSearchHistory();

  const { ...mutationData } = requestData.data as SalesOpportunities;
  const [mutate] = useDataMutation<
    SalesOpportunities,
    EMode.upsert,
    InputVerkaufschanceRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: mutationData,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave?.(data);
    },
  });

  useFormUpdate(
    {
      businessPartner: (businessPartner: BusinessPartner) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType =
          businessPartner?.businessPartnerType ?? SdObjType.KUNDE;
        const contact = businessPartner?.data?.isPrivateCustomer
          ? null
          : businessPartner?.data?.mainContact ?? null;

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;
        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;

        const businessPartnerField = getValues('businessPartner');

        if (!businessPartnerField) {
          const businessPartner = project?.customer ?? null;

          return {
            projectId,
            projectProcess: null,
            businessPartner: businessPartner
              ? {
                  id: businessPartner.id,
                  data: businessPartner,
                  businessPartnerType: SdObjType.KUNDE,
                }
              : null,
          };
        }

        return { projectId, projectProcess: null };
      },
      group: (group: SalesOpportunityGroup) => {
        return {
          groupId: group?.id || 0,
        };
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;

        return { projectProcessId };
      },
      probabilityPercent: (probabilityPercent: number | string) => {
        const status = form.getValues('status');
        return getValuesOnProbabilityChange(status, probabilityPercent);
      },
      status: getValuesOnStatusChange,
      currency: (currency: Currency) => {
        return {
          currencyId: currency?.id || 0,
        };
      },
      user: (user: User) => {
        return {
          userId: user?.id || 0,
        };
      },
      user2: (user: User) => {
        return {
          user2Id: user?.id || 0,
        };
      },
      ratingStatus: (status: SalesOpportunityRatingStatus) => {
        if (!status)
          return {
            ratingStatusId: EMPTY_UID,
          };
        return getValuesOnRatingStatusChange(status.id, status.ratingValue);
      },
    },
    form
  );

  const isDirty = formState.isDirty || attachementsRelation?.isDirty;
  useConfirmBeforeCloseMask(isDirty);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  const onSubmit = useCallback(
    async (formValues: SalesOpportunitiesMaskFormValue) => {
      const relations: InputVerkaufschanceRelation<EMode.entity> = {
        attachements: attachementsRelation?.attachements,
      };

      const updateRaw = mask.isCreateMode
        ? formValues
        : pickUpdateFields(formValues, formState.dirtyFields);

      const updateMapped = prepareInputWithCustomFields(updateRaw);

      await mutate(updateMapped, relations ? { relations } : undefined);
    },
    [attachementsRelation, mask.isCreateMode, formState.dirtyFields, mutate]
  );

  const entityRights = useMemo(
    () => ({
      create: canAddSalesOpportunities(user),
      read: false,
      update: canEditSalesOpportunities(user, data),
      delete: canDeleteSalesOpportunities(user, data),
    }),
    [data, user]
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <TempFileManagerContext.Provider value={tempFileManager}>
          <FormProvider {...form}>
            <MaskTabContext defaultValue={props.openTab || TabKeys.general}>
              <Form
                className={styles.maskForm}
                onSubmit={handleSubmit(onSubmit)}
              >
                <MaskOverlayHeader
                  title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                  subTitle={data.name}
                  actions={
                    <>
                      <MaskOverlayStandardSubmitButton isDirty={isDirty} />
                      <MaskOverlayFullscreenToggleButton />
                      {mask.isEditMode && (
                        <MaskOverlayMenuWrapper>
                          <MaskOverlayDeleteMenuItem
                            key={'remove'}
                            disabled={mask.wip || !entityRights.delete}
                            onClick={handleDeleteEntitiesClick}
                          />
                        </MaskOverlayMenuWrapper>
                      )}
                    </>
                  }
                  tabs={
                    <Tabs
                      isCreateMode={mask.isCreateMode}
                      renderIndividualTab={shouldRenderIndividualTab}
                    />
                  }
                />
                <Content renderIndividualTab={shouldRenderIndividualTab} />
              </Form>
            </MaskTabContext>
          </FormProvider>
        </TempFileManagerContext.Provider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const Tabs = memo(function AppointmentTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value={TabKeys.general} label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab
        value={TabKeys.attachments}
        label={t('MASK.ATTACHMENTS')}
      ></MaskTab>
      <MaskTab
        value={TabKeys.history}
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value={TabKeys.general}>
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value={TabKeys.history}>
        <HistoryTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

type ICreateSalesOppotunityDefaultParams = {
  template: AssignableEntityResult;
  exchangeInfo: CurrencyExchangeInfoContextValue;
  user: Pick<IUser, 'displayName' | 'benutzerCode'>;
  isCreateMode?: boolean;
};

function createSalesOppotunityDefaultData(
  params: ICreateSalesOppotunityDefaultParams
): SalesOpportunities {
  const { template, user, exchangeInfo, isCreateMode } = params;

  const common: SalesOpportunities = {
    status: SalesOpportunityStatus.ACTIVE,
    creatorUserId: user.benutzerCode,
    probabilityPercent: 0,
    dateNextReview: isCreateMode
      ? DateTime.now().plus({ days: 30 }).toFormat(W4ADateFormat.DEFAULT)
      : null,
    decisionDate: isCreateMode
      ? DateTime.now().plus({ days: 30 }).toFormat(W4ADateFormat.DEFAULT)
      : null,
    userId: user.benutzerCode,
    user: {
      id: user.benutzerCode,
      displayName: user.displayName,
    },
    user2Id: user.benutzerCode,
    user2: {
      id: user.benutzerCode,
      displayName: user.displayName,
    },
    currency: exchangeInfo?.defaultCurrency,
    currencyId: exchangeInfo?.defaultCurrency?.id,
    value: 0,
    expectedCosts: 0,
    orderValue: 0,
  };

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const contact =
      template.data.contact || template.data.businessPartner?.data?.mainContact;
    const data: SalesOpportunities = {
      ...common,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      contact: contact,
      contactId: contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id ?? 0,
      projectProcess: template.data.projectProcess,
      projectProcessId: template.data.projectProcess?.id ?? 0,
    };

    return data;
  }

  return {
    ...common,
    businessPartner: null,
    businessPartnerId: 0,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
  };
}
