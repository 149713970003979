import { useSetting } from '@work4all/data/lib/settings';
import {
  EntityPickerSettings,
  filterPickerEntities,
  sortPickerEntities,
} from '@work4all/data/lib/settings/settings';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const commonSortBy = ['name', 'number'];
export const settingsItemsKeyMap = {
  [Entities.customer]: {
    filter: 'extinct',
    sort: commonSortBy,
  },
  [Entities.supplier]: {
    filter: 'extinct',
    sort: commonSortBy,
  },
  [Entities.article]: {
    filter: 'isShutDown',
    sort: commonSortBy,
  },
  // ...
  // Add new sort/filter property for new entity pickers here
};

export type PickerSettingsSortBy = 'name' | 'number';

export function usePickerSettings(entity: Entities) {
  const settings: EntityPickerSettings = settingsItemsKeyMap[entity]
    ? {
        filter: {
          options: [
            {
              key: settingsItemsKeyMap[entity].filter,
              query: { $eq: 0 },
            },
          ],
        },
        sort: {
          options: settingsItemsKeyMap[entity].sort,
        },
      }
    : null;

  const { value: sortedPickerEntities, set: setSortedPickerEntities } =
    useSetting(
      sortPickerEntities({
        entityType: entity,
        defaultValue: settings?.sort.options?.[0],
      })
    );

  const { value: filteredEntitiesSetting, set: setHideClosedPickerEntities } =
    useSetting(filterPickerEntities({ entityType: entity }));

  return {
    settings,
    filteredEntitiesSetting,
    setHideClosedPickerEntities,
    sortedPickerEntities,
    setSortedPickerEntities,
  };
}
