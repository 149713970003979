import { useBlobUrl } from './use-blob-url';
import { useSecureBlob, UseSecureBlobOptions } from './use-secure-blob';

export function useSecureBlobUrl(
  url: string | null,
  options?: UseSecureBlobOptions
) {
  const { blob, error, isLoading } = useSecureBlob(url, options);
  const blobUrl = useBlobUrl(blob);

  return { blobUrl, error, isLoading };
}
