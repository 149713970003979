import styles from './ViewList.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useConfirmDeleteEntities } from '../../mask-overlays/mask-overlay/hooks/use-confirm-delete-entities';
import { AppointmentCalendarView } from '../types';

export interface ViewsListProps {
  views: AppointmentCalendarView[];
  activeView: AppointmentCalendarView | undefined;
  onSelectView: (view: AppointmentCalendarView) => void;
  onDeleteView: (view: AppointmentCalendarView) => void;
  title?: string;
}

export function ViewsList(props: ViewsListProps) {
  const { t } = useTranslation();
  const {
    views,
    activeView,
    onSelectView,
    onDeleteView,
    title = t('CALENDAR.VIEWS.SECTION_LABEL'),
  } = props;

  const confirmDeleteEntities = useConfirmDeleteEntities();

  function renderListItem(view: AppointmentCalendarView) {
    return (
      <ListItem
        key={view.id}
        disablePadding
        secondaryAction={
          view.readOnly ? null : (
            <IconButton
              edge="end"
              color="secondary"
              onClick={() =>
                confirmDeleteEntities({
                  count: 1,
                }).then((confirmed) => {
                  if (confirmed) {
                    onDeleteView(view);
                  }
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      >
        <ListItemButton
          onClick={() => onSelectView(view)}
          sx={{ overflow: 'hidden' }}
        >
          <ListItemText
            className={activeView === view ? styles.activeView : null}
            primary={view.name}
            sx={{
              overflowWrap: 'break-word',
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <List disablePadding>
      <Divider sx={{ p: '1rem' }}>{title}</Divider>
      {views.map(renderListItem)}
    </List>
  );
}
