import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { CustomField } from './CustomField.entity';

export class ProjectGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: info */
  info?: string;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: level */
  level?: number;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: projektnummerLogik */
  projectNumberLogic?: string;
  /** Alias: projektverzeichnisPfad */
  projectDirectoryPath?: string;
  /** Alias: verzeichnisTemplateTitelCode */
  directoryTemplateTitleId?: string;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProjectGroup> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  info: {
    alias: 'info',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  level: {
    alias: 'level',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  name: {
    alias: 'name',
  },
  projectNumberLogic: {
    alias: 'projektnummerLogik',
  },
  projectDirectoryPath: {
    alias: 'projektverzeichnisPfad',
  },
  directoryTemplateTitleId: {
    alias: 'verzeichnisTemplateTitelCode',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectGroupEntityDefinition: EntitiyDefinition<ProjectGroup> = {
  local: {},
  remote: {
    queryName: 'getProjectGroups',
    fragmentName: 'ProjektGruppe',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
