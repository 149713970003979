import styles from './AttachmentsCell.module.scss';

import { ReactComponent as AttachmentIcon } from '@work4all/assets/icons/email-actions/table/attachment.svg';

export type AttachmentsCellProps = {
  row: { original: { articleDocumentList: { id: string }[] } };
};

export const AttachmentsCell = (props: AttachmentsCellProps) => {
  const hasAttachment = props.row.original.articleDocumentList?.length;
  return (
    <div className={styles.attachmentCellRoot}>
      {hasAttachment ? <AttachmentIcon /> : undefined}
    </div>
  );
};
