import { useTranslation } from 'react-i18next';

import { ITabProps } from '../../../../navigation/tabs';
import { Tabs } from '../../../../navigation/tabs/tabs/Tabs';

interface ErpPreviewTabProps {
  tab: 'ORVERVIEW' | 'HISTORY';
  setTab: (value: 'ORVERVIEW' | 'HISTORY') => void;
}

export const ErpPreviewTab = (props: ErpPreviewTabProps) => {
  const { tab, setTab } = props;
  const { t } = useTranslation();

  const tabs: ITabProps[] = [
    { label: t('COMMON.OVERVIEW'), value: 'ORVERVIEW' },
    { label: t('MASK.HISTORY'), value: 'HISTORY' },
  ];
  return (
    <div
      style={{
        background: 'var(--ui01)',
        paddingLeft: '1rem',
        paddingTop: '1rem',
      }}
    >
      <Tabs
        value={tab}
        handleChange={(_e, value: 'ORVERVIEW' | 'HISTORY') => setTab(value)}
        tabs={tabs}
      />
    </div>
  );
};
