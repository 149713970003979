import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputProjektVorgang<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: startDatum */
  startDatum?: string;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: art */
  art?: any;
  /** Alias: artikelCode */
  artikelCode?: number;
  /** Alias: bCode */
  bCode?: number;
  /** Alias: bemerkung */
  bemerkung?: string;
  /** Alias: bewertung */
  bewertung?: number;
  /** Alias: dauer */
  dauer?: number;
  /** Alias: endeDatum */
  endeDatum?: string;
  /** Alias: gespraechspunkteCode */
  gespraechspunkteCode?: number;
  /** Alias: jahr */
  jahr?: number;
  /** Alias: kostenst */
  kostenst?: number;
  /** Alias: ressourcenKlasseCode */
  ressourcenKlasseCode?: number;
  /** Alias: monat */
  monat?: number;
  /** Alias: nummer */
  nummer?: number;
  /** Alias: parentCode */
  parentCode?: number;
  /** Alias: planKosten */
  planKosten?: number;
  /** Alias: planungsAnzahl */
  planungsAnzahl?: number;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: ressource */
  ressource?: string;
  /** Alias: summeErloese */
  summeErloese?: number;
  /** Alias: vorgang */
  vorgang?: string;
  /** Alias: index */
  index?: number;
  /** Alias: kalkulationPositionCode */
  kalkulationPositionCode?: number;
  /** Alias: ueberbuchenMoeglich */
  ueberbuchenMoeglich?: number;
  /** Alias: abgeschlossen */
  abgeschlossen?: boolean;
  /** Alias: positionenCode */
  positionenCode?: number;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: customerCode */
  customerCode?: number;
  /** Alias: supplierCode */
  supplierCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputProjektVorgang> = {
  code: {
    alias: 'code',
  },
  startDatum: {
    alias: 'startDatum',
  },
  creationDate: {
    alias: 'creationDate',
  },
  art: {
    alias: 'art',
  },
  artikelCode: {
    alias: 'artikelCode',
  },
  bCode: {
    alias: 'bCode',
  },
  bemerkung: {
    alias: 'bemerkung',
  },
  bewertung: {
    alias: 'bewertung',
  },
  dauer: {
    alias: 'dauer',
  },
  endeDatum: {
    alias: 'endeDatum',
  },
  gespraechspunkteCode: {
    alias: 'gespraechspunkteCode',
  },
  jahr: {
    alias: 'jahr',
  },
  kostenst: {
    alias: 'kostenst',
  },
  ressourcenKlasseCode: {
    alias: 'ressourcenKlasseCode',
  },
  monat: {
    alias: 'monat',
  },
  nummer: {
    alias: 'nummer',
  },
  parentCode: {
    alias: 'parentCode',
  },
  planKosten: {
    alias: 'planKosten',
  },
  planungsAnzahl: {
    alias: 'planungsAnzahl',
  },
  projektCode: {
    alias: 'projektCode',
  },
  ressource: {
    alias: 'ressource',
  },
  summeErloese: {
    alias: 'summeErloese',
  },
  vorgang: {
    alias: 'vorgang',
  },
  index: {
    alias: 'index',
  },
  kalkulationPositionCode: {
    alias: 'kalkulationPositionCode',
  },
  ueberbuchenMoeglich: {
    alias: 'ueberbuchenMoeglich',
  },
  abgeschlossen: {
    alias: 'abgeschlossen',
  },
  positionenCode: {
    alias: 'positionenCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  customerCode: {
    alias: 'customerCode',
  },
  supplierCode: {
    alias: 'supplierCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjektVorgangEntityDefinition: EntitiyDefinition<InputProjektVorgang> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjektVorgang',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
