import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const emailRequestVars = { rewriteInlineAttachements: true };

export const emailRequestData: EMail<EMode.query> = {
  userId: null,
  id: null,
  subject: null,
  bodyHtml: null,
  rewrittenBodyHtml: null,
  businessPartnerId: null,
  businessPartnerType: null,
  saveSendMailJob: {
    id: null,
    errorMessages: null,
    jobState: null,
  },
  date: null,
  from: null,
  to: null,
  toMailContacts: null,
  cc: null,
  ccMailContacts: null,
  bcc: null,
  businessPartner: {
    id: null,
    data: {
      customer: {
        name: null,
        id: null,
        mainContactId: null,
        number: null,
        website: null,
        isPrivateCustomer: null,
        eMail: null,
        eMail2: null,
        languageId: null,
      },
      supplier: {
        name: null,
        id: null,
        mainContactId: null,
        number: null,
        website: null,
        isPrivateCustomer: null,
        eMail: null,
        eMail2: null,
        languageId: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    eMail: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
  contactId: null,
  project: {
    name: null,
    id: null,
    number: null,
  },
  projectId: null,
  topicMarkList: [
    {
      id: null,
      name: null,
    },
  ],
  kind: null,
  priority: null,
  attachmentList: [
    {
      id: null,
      fileName: null,
      displayFilename: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
    },
  ],
};
