import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputBankAccountRelation } from './InputBankAccountRelation.entity';
import { InputCategoryMarkRelation } from './InputCategoryMarkRelation.entity';
import { InputCompanyDefaultErpText } from './InputCompanyDefaultErpText.entity';
import { InputObjectGroupRelation } from './InputObjectGroupRelation.entity';
import { InputStammdatenAttachementsRelation } from './InputStammdatenAttachementsRelation.entity';
import { InputStammdatenPostIt } from './InputStammdatenPostIt.entity';

export class InputLieferantRelation<T extends EMode = EMode.entity> {
  /** Alias: categoryMarks */
  categoryMarks?: InputCategoryMarkRelation<T>;
  /** Alias: bankAccounts */
  bankAccounts?: InputBankAccountRelation<T>;
  /** Alias: attachements */
  attachements?: InputStammdatenAttachementsRelation<T>;
  /** Alias: postIt */
  postIt?: InputStammdatenPostIt<T>;
  /** Alias: defaultErpTexts */
  defaultErpTexts?: InputCompanyDefaultErpText<T>;
  /** Alias: objectGroups */
  objectGroups?: InputObjectGroupRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputLieferantRelation> = {
  categoryMarks: {
    alias: 'categoryMarks',
  },
  bankAccounts: {
    alias: 'bankAccounts',
  },
  attachements: {
    alias: 'attachements',
  },
  postIt: {
    alias: 'postIt',
  },
  defaultErpTexts: {
    alias: 'defaultErpTexts',
  },
  objectGroups: {
    alias: 'objectGroups',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputLieferantRelationEntityDefinition: EntitiyDefinition<InputLieferantRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputLieferantRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
