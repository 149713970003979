import styles from './ControlViewBottom.module.scss';

import clsx from 'clsx';
import React from 'react';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';

import { useSetting } from '@work4all/data/lib/settings';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings } from '../../../settings';

export interface ControlViewLeftProps {
  children: React.ReactNode;
  entityType: Entities;
  resizable?: boolean;
}

export const ControlViewBottom: React.FC<ControlViewLeftProps> = ({
  children,
  entityType,
  resizable,
}) => {
  const settingsSize = useSetting(settings.bottomAreaSize({ entityType }));
  return resizable !== false ? (
    <ResizableArea
      handles="top"
      size={{ height: settingsSize.value.height }}
      direction="vertical"
      onResize={(e) => settingsSize.set({ height: e.height })}
      minHeight={200}
      maxHeight={750}
    >
      <div className={clsx(styles.control, styles.maxHeight)}>{children}</div>
    </ResizableArea>
  ) : (
    <div className={styles.control}>{children}</div>
  );
};
