import React, { useCallback, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps,
  Location,
  matchPath,
  Navigate as RouterNavigate,
  NavigateOptions,
  NavigateProps,
  To,
  useLocation as useReactLocation,
  useNavigate as useReactNavigate,
  useParams as useReactParams,
} from 'react-router-dom';

import { useTenant } from './TenantProvider';

interface TenantParams {
  tenant?: number;
}

interface UseMatchPath {
  params: Record<string, string>;
  extraSegments: string[];
}

export const TENANT_URL_PART = 'tenantId';

export const prefixToWithTenant = (to: To, tenant?: number) => {
  if (tenant === undefined || tenant === null) return to;
  if (typeof to === 'string') {
    //if its a relative url we dont prefix it with the tenant
    if (!to.startsWith('/')) return to;
    return `/t-${tenant}${to}`;
  }

  //if its a relative url we dont prefix it with the tenant
  if (!to.pathname.startsWith('/')) return to;

  const newTo: To = { ...to };
  newTo.pathname = `/t-${tenant}${to.pathname}`;

  return newTo;
};

const useTeniefiedUrl = (to: To, tenant?: number) => {
  const tenantctx = useTenant();
  const tenantToUse = tenant === undefined ? tenantctx?.activeTenant : tenant;

  const adapted = useMemo(() => {
    return prefixToWithTenant(to, tenantToUse);
  }, [tenantToUse, to]);
  return adapted;
};

export const Navigate = (props: NavigateProps & TenantParams) => {
  const { tenant, to, ...rest } = props;
  const adaptedTo = useTeniefiedUrl(to, tenant);
  return <RouterNavigate {...rest} to={adaptedTo} />;
};

export const Link = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & TenantParams
>(function (props, ref) {
  const { tenant, to, ...rest } = props;

  const adaptedTo = useTeniefiedUrl(to, tenant);
  return <RouterLink ref={ref} {...rest} to={adaptedTo} />;
});

export const useLocation = (): Location & TenantParams => {
  const loc = useReactLocation();

  const cleanedPathParams = useMemo(() => {
    const parsedTenant = getTenantFromPath(loc.pathname);
    if (parsedTenant.tenant !== undefined) {
      return {
        pathname: parsedTenant.restPath,
        tenant: parsedTenant.tenant,
      };
    }
    return { pathname: loc.pathname };
  }, [loc.pathname]);

  return {
    ...loc,
    ...cleanedPathParams,
  };
};

export const getTenantFromPath = (path: string) => {
  /**
   * "/t-123/wassersp/macht/spass".split("/")
   * ['', 't-123', 'wassersp', 'macht', 'spass']
   */
  const split = path.split('/');
  if (split.length > 1 && split[1].match(/t-.*/)) {
    return {
      restPath: '/' + split.slice(2).join('/'),
      tenant: parseInt(split[1].replace('t-', '')),
    };
  }
  return {
    tenant: undefined,
    restPath: path,
  };
};

export const useMatch: (pattern: string) => UseMatchPath | null = (pattern) => {
  const { pathname } = useLocation();

  const match = React.useMemo(
    () => matchPath(pattern, pathname),
    [pathname, pattern]
  );
  if (!match) return null;

  const { params } = match;
  const extraSegments = pathname.split('/').slice(Object.keys(params).length);

  return { params, extraSegments };
};

export const useNavigate = () => {
  const nav = useReactNavigate();
  const tenantctx = useTenant();

  const t = useCallback(
    (to: To | number, options?: NavigateOptions & { tenant?: number }) => {
      if (typeof to === 'number') {
        nav(to);
        return;
      }
      const tenantToUse = options?.tenant || tenantctx?.activeTenant;

      const adaptedTo = prefixToWithTenant(to, tenantToUse);

      nav(adaptedTo, options);
    },
    [nav, tenantctx?.activeTenant]
  );
  return t;
};

export const useParams = () => {
  const orig = useReactParams();
  if (!(orig[TENANT_URL_PART] || '').match(/t-.*/)) {
    console.warn('found unexpected tenant parameter');
    return {
      ...orig,
      tenantId: undefined,
    };
  }
  return orig;
};
