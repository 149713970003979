import styles from './ProfileAvatar.module.scss';

import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { useRef, useState } from 'react';

import { ConfirmDialog } from '@work4all/components';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { useUser } from '@work4all/data';

import { useAvatar } from '../../../../../../hooks/useAvatar';

import { AvatarMenu } from './AvatarMenu';

export const ProfileAvatar = () => {
  const user = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const open = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { removeAvatar, uploadAvatar } = useAvatar();

  const handleMeneClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmRemove = () => {
    removeAvatar();
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton
        className={styles.avatarWrapper}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        aria-controls={open ? 'Avatar-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <HookedUserIcon userId={user?.benutzerCode} size="xl" />
        <SettingsIcon className={styles.settingsIcon} />
      </IconButton>

      <AvatarMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleMeneClose}
        fileInputRef={fileInputRef}
        setIsDialogOpen={setIsDialogOpen}
      />

      <input
        type="file"
        accept="image/*"
        hidden
        ref={fileInputRef}
        onChange={uploadAvatar}
      />

      <ConfirmDialog
        open={isDialogOpen}
        title={t('PROFILE.DELETE_PROFILE_PICTURE')}
        description={t('PROFILE.CONFIRM_DELETE_PORFILE_PICTURE')}
        onConfirm={handleConfirmRemove}
        onCancel={() => setIsDialogOpen(false)}
      />
    </>
  );
};
