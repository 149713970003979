import styles from './simulated-safe-areas.module.scss';

import { useCallback, useState } from 'react';

const BODY_ATTRIBUTE = 'data-simulate-safe-areas';
const OVERLAY_ELEMENTS_ATTRIBUTE = 'data-simulate-safe-areas-overlay-elements';
const OVERLAY_ELEMENTS_SELECTOR = `body > [${OVERLAY_ELEMENTS_ATTRIBUTE}]`;

const SAFE_AREA_PREFIXES: string[] = ['--w4a-safe-area', '--mbsc-safe'];
const SAFE_AREA_INSET_VALUES: [string, string][] = [
  ['top', '44px'],
  ['right', '0px'],
  ['bottom', '34px'],
  ['left', '0px'],
];

const SAFE_AREA_INSETS = SAFE_AREA_PREFIXES.flatMap((prefix) => {
  return SAFE_AREA_INSET_VALUES.map(([property, value]) => {
    return [`${prefix}-${property}`, value] as [string, string];
  });
});

const STORAGE_KEY = 'w4a-dev-simulate-safe-areas';
const STORAGE_VALUE_ENABLED = 'on';

function getSimulateDeviceSafeAreas(): boolean {
  return document.body.hasAttribute(BODY_ATTRIBUTE);
}

function setSimulateDeviceSafeAreas(enabled: boolean): void {
  if (enabled) {
    document.body.setAttribute(BODY_ATTRIBUTE, '');

    for (const [property, value] of SAFE_AREA_INSETS) {
      document.body.style.setProperty(property, value);
    }

    insertSafeAreaOverlayElements();

    sessionStorage.setItem(STORAGE_KEY, STORAGE_VALUE_ENABLED);
  } else {
    document.body.removeAttribute(BODY_ATTRIBUTE);

    for (const [property] of SAFE_AREA_INSETS) {
      document.body.style.removeProperty(property);
    }

    removeSafeAreaOverlayElements();

    sessionStorage.removeItem(STORAGE_KEY);
  }
}

function insertSafeAreaOverlayElements(): void {
  const existingElement = document.querySelector(OVERLAY_ELEMENTS_SELECTOR);
  if (existingElement) {
    return;
  }

  const element = document.createElement('div');
  element.setAttribute(OVERLAY_ELEMENTS_ATTRIBUTE, '');
  document.body.appendChild(element);

  const topElement = document.createElement('div');
  topElement.className = styles['safe-area-top'];
  element.appendChild(topElement);

  const bottomElement = document.createElement('div');
  bottomElement.className = styles['safe-area-bottom'];
  element.appendChild(bottomElement);
}

function removeSafeAreaOverlayElements(): void {
  const element = document.querySelector(OVERLAY_ELEMENTS_SELECTOR);

  if (element) {
    element.remove();
  }
}

export function useSimulatedSafeAreas() {
  const [isEnabled, setEnabled] = useState(getSimulateDeviceSafeAreas);

  const handleChange = useCallback(
    (value: boolean) => {
      setEnabled(value);
      setSimulateDeviceSafeAreas(value);
    },
    [setEnabled]
  );

  return {
    isEnabled,
    onChange: handleChange,
  };
}

document.addEventListener('DOMContentLoaded', () => {
  const storedValue = sessionStorage.getItem(STORAGE_KEY);

  if (storedValue === STORAGE_VALUE_ENABLED) {
    setSimulateDeviceSafeAreas(true);
  }
});
