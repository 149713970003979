import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { useContactActions } from '../../../../../../hooks/use-contact-actions';
import { BusinessPartnerPicker } from '../../../../../entity-picker/business-partner-picker/BusinessPartnerPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const BusinessPartnerPreviewPickerInput = withExtras<
  Customer | Supplier
>(({ value, onChange, ...rest }) => {
  const entity =
    value?.__typename === 'Kunde' ? Entities.customer : Entities.supplier;

  const { actions } = useContactActions(
    {
      ...value,
      id: null,
      businessPartnerId: value?.id,
      businessPartnerType: typeNameToSdObjType(value?.__typename),
      isPrivatePartner: value?.isPrivateCustomer,
    },
    {
      displayEMail: false,
      noButtonPadding: true,
    }
  );

  return (
    <PreviewPickerInput<Customer | Supplier>
      {...rest}
      value={value}
      actions={value?.isPrivateCustomer ? actions : undefined}
      formattedValue={value?.name}
      picker={
        <BusinessPartnerPicker
          value={value}
          onChange={onChange}
          multiple={false}
        />
      }
      onChange={onChange}
      entity={entity}
    />
  );
});
