import styles from './TimelineSection.module.scss';

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { usePickerVisibilityState } from '@work4all/components/lib/input/date-time-input-picker/hooks/usePickerVisibilityState';
import { LabeledTimeInputWithDropdown } from '@work4all/components/lib/input/labeled-time-input';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';
import { useCalendarHoursConfig } from '../../../../../../../calendar/hooks/use-calendar-hours-config';

export function TimelineSection() {
  const { t } = useTranslation();

  const startDayInputRef = useRef<HTMLInputElement>();
  const endDayInputRef = useRef<HTMLInputElement>();

  const [anchorWorkDayStart, setAnchorWorkDayStart] =
    useState<HTMLDivElement | null>(null);
  const [anchorWorkDayEnd, setAnchorWorkDayEnd] =
    useState<HTMLDivElement | null>(null);

  const calendarWorkDayStart = useSetting(settings.calendarWorkDayStart());
  const calendarWorkDayEnd = useSetting(settings.calendarWorkDayEnd());

  const { workDayStart, workDayEnd } = useCalendarHoursConfig();

  const {
    showPicker: showWorkDayStartTimePicker,
    onShowPicker: onWorkDayStartShowTimePicker,
    onHidePicker: onWorkDayStartHideTimePicker,
  } = usePickerVisibilityState();

  const {
    showPicker: showWorkDayEndTimePicker,
    onShowPicker: onWorkDayEndShowTimePicker,
    onHidePicker: onWorkDayEndHideTimePicker,
  } = usePickerVisibilityState();

  function makeHandleKeyDown(handler: () => void) {
    return function handleKeyDown(event: React.KeyboardEvent) {
      if (
        event.key === 'Enter' ||
        event.key === 'Escape' ||
        event.key === 'Tab'
      ) {
        handler();
      }
    };
  }

  return (
    <Collapse title={t('SETTINGS.CALENDAR.TIMELINE')} defaultOpen>
      <div className={styles.xSplit}>
        <Body1>{t('SETTINGS.CALENDAR.WORKTIME_DURATION')}</Body1>

        <div className={styles.labelInput} ref={setAnchorWorkDayStart}>
          <LabeledTimeInputWithDropdown
            value={workDayStart}
            inputProps={{
              ref: startDayInputRef,
            }}
            anchorEl={anchorWorkDayStart}
            showDropdown={showWorkDayStartTimePicker}
            onShowDropdown={onWorkDayStartShowTimePicker}
            onHideDropdown={onWorkDayStartHideTimePicker}
            label={t('COMMON.STARTDATE')}
            placeholder="hh:mm"
            onTimeSelect={(time) => {
              calendarWorkDayStart.set(time.toISO());
              startDayInputRef?.current?.focus();
            }}
            onKeyDown={makeHandleKeyDown(onWorkDayStartHideTimePicker)}
          />
        </div>
        <div className={styles.labelInput} ref={setAnchorWorkDayEnd}>
          <LabeledTimeInputWithDropdown
            className={styles.labelInput}
            value={workDayEnd}
            inputProps={{
              ref: endDayInputRef,
            }}
            anchorEl={anchorWorkDayEnd}
            showDropdown={showWorkDayEndTimePicker}
            onShowDropdown={onWorkDayEndShowTimePicker}
            onHideDropdown={onWorkDayEndHideTimePicker}
            label={t('COMMON.END')}
            placeholder="hh:mm"
            onTimeSelect={(time) => {
              calendarWorkDayEnd.set(time.toISO());
              endDayInputRef?.current?.focus();
            }}
            onKeyDown={makeHandleKeyDown(onWorkDayEndHideTimePicker)}
          />
        </div>
      </div>
    </Collapse>
  );
}
