import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { ValidationMessage } from './ValidationMessage.entity';

export class BzObjectMutationResponse<T extends EMode = EMode.entity> {
  /** Alias: ok */
  ok?: boolean;
  /** Alias: errors */
  errors?: ValidationMessage<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<BzObjectMutationResponse> = {
  ok: {
    alias: 'ok',
  },
  errors: {
    alias: 'errors',
    entity: Entities.validationMessage,
  },
  __typename: {
    alias: '__typename',
  },
};

export const bzObjectMutationResponseEntityDefinition: EntitiyDefinition<BzObjectMutationResponse> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'BzObjectMutationResponse',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'mutateBzObjects',
          args: [{ name: 'input', type: 'BzObjectMutationRequest!' }],
        },
      },
    },
    fieldDefinitions: fields,
  };
