import { createContext } from 'react';

import { BottomTableContextValue } from '../types';

export const BottomTableContext = createContext<BottomTableContextValue>({
  views: [],
  isCollapsed: true,
  togglecollapsibility: () => {},
  activeView: null,
  setActiveView: () => {},
});
