import { BuildSchemaFunction } from './types';

export const buildSchema: BuildSchemaFunction = ({ configs, settings }) => {
  const required = [];
  const properties = {};

  Object.keys(configs).forEach((field) => {
    const { type, settingName } = configs[field];
    const isSettingValueTrue = settings?.[settingName] === true;

    if (isSettingValueTrue) {
      required.push(field);
      properties[field] = {
        type,
      };
    }
  });

  return { required, properties };
};
