import { useMemo } from 'react';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewDataRequest } from '../../hooks/use-entity-preview-data';

interface UseErpPreviewDataProps {
  entity: Entities;
  ids: string[] | number[];
}

export const useErpPreviewData = (props: UseErpPreviewDataProps) => {
  const { entity, ids } = props;
  const requestEntityData = useMemo(() => {
    if (entity === Entities.reViewModel) {
      const reData: ReViewModel<EMode.query> = {
        id: null,
        userId: null,
        invoiceDate: null,
        note: null,
        receipts: [
          {
            id: null,
            parentId: null,
            fileInfos: {
              fileEntityFilename: null,
              downloadUrl: null,
              previewUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
              fileServiceProviderInfos: {
                id: null,
                fileName: null,
                mimeType: null,
                fspUrl: null,
              },
            },
          },
        ],
      };
      return reData;
    } else if (entity === Entities.raViewModel) {
      const raData: RaViewModel<EMode.query> = {
        id: null,
        note: null,
        invoiceDate: null,
        invoice: {
          id: null,
          archivePdf: [
            {
              id: null,
              mimeType: null,
              fileInfos: {
                fileEntityFilename: null,
                downloadUrl: null,
                previewUrl: null,
                previewMimeType: null,
                downloadMimeType: null,
                fileServiceProviderInfos: {
                  id: null,
                  fileName: null,
                  mimeType: null,
                  fspUrl: null,
                },
              },
            },
          ],
        },
      };
      return raData;
    } else {
      const statusProps =
        entity === Entities.invoice
          ? {
              ra: null,
              paid: null,
            }
          : { isClosed: null };
      const signatureProps =
        entity === Entities.deliveryNote
          ? {
              signature: {
                id: null,
                fileInfos: {
                  downloadUrl: null,
                },
              },
            }
          : {};

      const contractData =
        entity === Entities.contract
          ? {
              contractDate: null,
              contractNumber: null,
              paymentDeadline: null,
            }
          : {};

      const commonProps =
        entity === Entities.inboundDeliveryNote
          ? {}
          : {
              additionalAddress1Text: null,
              value: null,
              languageId: null,
              language: {
                id: null,
              },
            };
      const commonPropsPositions =
        entity === Entities.inboundDeliveryNote
          ? {}
          : {
              insteadOfTotalPrice: null,
            };
      const requestErpData: ERPTypes<EMode.query> = {
        id: null,
        note: null,
        // verify  entityType === Entities.contract //--contractDate
        date: null,
        ...contractData,
        number: null,
        ...commonProps,
        user: {
          id: null,
          displayName: null,
          phoneNumber: null,
          eMail: null,
        },
        projectId: null,
        project: {
          id: null,
          name: null,
        },
        ...signatureProps,
        frozen: null,
        ...statusProps,
        headText: null,
        footerText: null,
        rtfHeadText: null,
        rtfFooterText: null,
        currency: { id: null, name: null },
        priceGroup: {
          id: null,
          isGrossPrice: null,
        },
        positionList: [
          {
            id: null,
            vat: null,
            totalPriceNet: null,
            positionKind: null,
            number: null,
            amount: null,
            singlePriceNet: null,
            longtext: null,
            unit: null,
            internalText: null,
            ...commonPropsPositions,
          },
        ],
        businessPartnerId: null,
        contactId: null,
        businessPartnerContactCombined: {
          businessPartner: {
            id: null,
            data: {
              customer: {
                id: null,
                name: null,
                number: null,
              },
              supplier: {
                id: null,
                name: null,
                number: null,
              },
            },
          },
          contact: {
            id: null,
            displayName: null,
          },
        },
        archivePdf: [
          {
            id: null,
            downloadUrl: null,
            mimeType: null,
            xML: null,
            fileInfos: {
              fileEntityFilename: null,
              downloadUrl: null,
              previewUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
              fileServiceProviderInfos: {
                id: null,
                fileName: null,
                mimeType: null,
                fspUrl: null,
              },
            },
          },
        ],
      };
      return requestErpData;
    }
  }, [entity]);

  const previewDataResponse = useEntityPreviewDataRequest<
    ERPTypes & ReViewModel & RaViewModel
  >(ids, entity, requestEntityData);

  const entitiesData = previewDataResponse.data;
  const fileList = useMemo<
    {
      fileInfos?: FileEntity;
      mimeType?: string;
      id?: number;
      parentId?: number;
    }[]
  >(() => {
    if (entity === Entities.reViewModel) {
      let receipts = (entitiesData?.[0] as ReViewModel)?.receipts;
      if (receipts && receipts.length > 1) {
        receipts = [...receipts];
        receipts.sort((a, b) => {
          return !a.parentId && b.parentId
            ? -1
            : a.parentId && !b.parentId
            ? 1
            : 0;
        });
      }

      return receipts;
    }

    if (entity === Entities.raViewModel) {
      return (entitiesData?.[0] as RaViewModel)?.invoice?.archivePdf;
    }

    return (entitiesData?.[0] as ERPTypes)?.archivePdf;
  }, [entitiesData, entity]);

  return { ...previewDataResponse, fileList, requestEntityData };
};
