import styles from '../StatisticPage.module.scss';

import { LinearProgress, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useObservableState } from 'observable-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import {
  InfoCard,
  InfoCards,
} from '@work4all/components/lib/components/entity-preview/components';
import {
  renderTime,
  StatisticTimeCardTable,
} from '@work4all/components/lib/dataDisplay/basic-table/reporting/StatisticTimeCardTable';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { useDataProvider, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { TimeCard } from '@work4all/models/lib/Classes/TimeCard.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { UserPickerField } from '../../../components/entity-picker/UserPickerField';
import { useTableBorders } from '../../../utils/use-table-borders/use-table-borders';

import { DateWheelPickerField } from './date-wheel-picker-field/DateWheelPickerField';
import { TimeCardArchivedPdf } from './time-preview-panel/TimeCardArchivedPdf';
import { TimeCardPreview } from './time-preview-panel/TimeCardPreview';
import { useTimePreview } from './time-preview-panel/useTimePreview';

export function StatisticTimeCardContainer() {
  const [currentDate, setCurrentYear] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    new Date()
  );

  const user = useUser();
  const [currentUser, setCurrentUser] = useState<User>(null);

  useEffect(() => {
    if (currentUser === null) {
      setCurrentUser({
        id: user.benutzerCode,
        lastName: user.lastName,
        firstName: user.firstName,
        displayName: user.displayName,
      });
    }
  }, [currentUser, user]);

  const { t } = useTranslation();

  const requestData: DataRequest = useMemo(() => {
    const data: TimeCard = {
      date: null,
      user: {
        id: null,
        displayName: null,
      },
      maxTargetTimeMinutes: null,
      timeCardDays: [
        {
          amountHoliday: null,
          amountSickness: null,
          amountVacation: null,
          date: null,
          differenceminutes: null,
          isGrossMinutes: null,
          correctionMinutes: null,
          correctionNote: null,
          pauseAmountMinutesStamped: null,
          pauseAmountMinutesAccordingToRule: null,
          pauseAmountMinutes: null,
          targetMinutes: null,
          endDate: null,
          endDateCorrected: null,
          startDate: null,
          startDateCorrected: null,
          timeAccountMinutes: null,
          projectTimeTotal: null,
          sicknessList: [
            {
              date: null,
              amount: null,
              id: null,
              sicknessType: {
                id: null,
                name: null,
              },
            },
          ],
          vacationList: [
            {
              id: null,
              date: null,
              amount: null,
              vacationKind: {
                id: null,
                name: null,
              },
            },
          ],
          presenceList: [
            {
              start: null,
              startCorrected: null,
              end: null,
              endCorrected: null,
              isGrossMinutes: null,
              istNetMinutes: null,
              pauseAmountMinutes: null,
              items: [
                {
                  id: null,
                  timestamp: null,
                  kind: null,
                },
              ],
            },
          ],
        },
      ],
      targetTotalMinutes: null,
      isNetTotalMinutes: null,
      isGrossTotalMinutes: null,
      deductionIncludedMinutes: null,
      deductionPayedOvertimeMinutes: null,
      timeAccountAtMonthEnd: null,
      timeAccountAtPreviousMonthEnd: null,
      lastInterimBalanceDate: null,
      saldoCurrentMonth: null,
      archivedPdfDownloadUrl: null,
    };

    return {
      entity: Entities.timeCard,
      data: data,
      vars: {
        benutzerCode: currentUser?.id, //31517781,
        datum: currentDate, //new Date('2022-08-01'),
      },
      completeDataResponse: true,
    };
  }, [currentUser, currentDate]);

  const { data, loading, pending, refetch } = useDataProvider<TimeCard>(
    requestData,
    !currentUser
  );
  const timeCard = data as TimeCard;

  const isLoading = loading || pending;

  const { showDetails, detailItem, hideDetails } = useTimePreview(
    timeCard,
    refetch
  );

  const tableBorders = useTableBorders();

  return (
    <>
      <div className={styles.controlBar}>
        <div style={{ maxWidth: '50%', width: '15rem' }}>
          <DateWheelPickerField
            max={new Date()}
            min={new Date('1990-01-01')}
            unit="month"
            label={t('COMMON.MONTH')}
            value={currentDate}
            onChange={(value) => {
              setCurrentYear(value);
            }}
          />
        </div>
        <div style={{ maxWidth: 'calc(50% - 1rem)', width: '15rem' }}>
          <UserPickerField
            disabled={
              /**
               * users may have rights to see all timecards
               * ToDo: just the once of a few selected members (this is somehting we currently dont respect)
               * or just their own
               */
              !checkUserRight(UserRights.Zeitkarte_Alle, user.benutzerRechte) &&
              !checkUserRight(
                UserRights.Zeitkarte_AnsichtEigeneUndZugeordneteMitarbeiter,
                user.benutzerRechte
              )
            }
            value={currentUser}
            clearable={false}
            onChange={(value) => {
              setCurrentUser(value);
            }}
          />
        </div>
      </div>

      <Divider className={styles.NoPadding} />
      {timeCard.archivedPdfDownloadUrl ? (
        <TimeCardArchivedPdf
          archivedPdfDownloadUrl={timeCard.archivedPdfDownloadUrl}
        />
      ) : (
        <>
          <div className={styles.TimeTrackingInfoCardWrap}>
            <div className={styles.BorderRight} style={{ width: '50%' }}>
              <div className={styles.tableHead}>
                <Caption>
                  {DateTime.fromJSDate(currentDate)
                    .toFormat('MMMM')
                    .toUpperCase()}
                </Caption>
              </div>
              <InfoCards columns={3}>
                <InfoCard
                  label={t('COMMON.SHOULD')}
                  children={renderTime(timeCard.targetTotalMinutes)}
                />
                <InfoCard
                  label={t('COMMON.SHOULD_TOTAL')}
                  children={renderTime(timeCard.maxTargetTimeMinutes)}
                />
                <InfoCard
                  label={t('COMMON.IS')}
                  children={renderTime(timeCard.isNetTotalMinutes)}
                />
              </InfoCards>
            </div>
            <div className={styles.BorderRight} style={{ width: '50%' }}>
              <div className={styles.tableHead}>
                <Caption>{t('COMMON.BALANCE').toUpperCase()}</Caption>
              </div>
              <InfoCards columns={3}>
                <InfoCard
                  label={DateTime.fromJSDate(currentDate)
                    .minus({ month: 1 })
                    .toFormat('MMM')}
                  children={renderTime(timeCard.timeAccountAtPreviousMonthEnd)}
                />
                <InfoCard
                  label={DateTime.fromJSDate(currentDate).toFormat('MMM')}
                  children={renderTime(
                    timeCard.isNetTotalMinutes - timeCard.targetTotalMinutes
                  )}
                />
                <InfoCard
                  label={t('COMMON.TOTAL')}
                  children={renderTime(timeCard.timeAccountAtMonthEnd)}
                />
              </InfoCards>
            </div>
          </div>
          <Divider className={styles.NoPadding} />
          <div className={styles.tableWrapper}>
            {isLoading && (
              <LinearProgress
                style={{ position: 'absolute', width: '100%', zIndex: 200 }}
              />
            )}

            <div className={styles.sideBySide}>
              <Stack flexGrow={1} minWidth={100}>
                <StatisticTimeCardTable
                  data={timeCard}
                  // @ts-expect-error TECH_DEBT
                  onSelectedRowsChange={showDetails}
                  maxTargetTimeMinutes={timeCard.maxTargetTimeMinutes}
                  tableClasses={tableBorders}
                />
              </Stack>

              {detailItem ? (
                <TimeCardPreview
                  lastInterimBalanceDate={timeCard.lastInterimBalanceDate}
                  user={timeCard.user}
                  value={detailItem}
                  close={hideDetails}
                  refetch={refetch}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
