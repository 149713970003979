import { ChevronLeft } from '@mui/icons-material';
import { Dialog, Divider, Slide, Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DetailsView } from '@work4all/components/lib/dataDisplay/details-view/DetailsView';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { AreaConfig } from '@work4all/models/lib/Tables/Tables';

import { settings, useSetting } from '../../../../../settings';

interface TableRightAreaProps {
  rightArea?: AreaConfig;
  className?: string;
  entityType: Entities;
}

export const TableRightArea: React.FC<TableRightAreaProps> = (
  props: TableRightAreaProps
) => {
  const { rightArea, className, entityType } = props;

  const previewSize = useSetting(settings.listPreviewSize());

  const isSmDown = useMediaQuery<Theme>((t) => t.breakpoints.down('sm'));

  const { t } = useTranslation();

  const { value: previewVisible, set: setPreviewVisible } = useSetting(
    settings.tableRightAreaVisible({
      entityType,
    })
  );

  return (
    <>
      {rightArea && !isSmDown ? (
        <>
          <Divider orientation="vertical" />
          {!previewVisible ? (
            <ExpandButton
              icon={<ChevronLeft />}
              textStart="top"
              title={t('COMMON.DETAILS')}
              color="text03"
              onClick={() => {
                setPreviewVisible(!previewVisible);
              }}
            />
          ) : (
            <DetailsView
              size={previewSize.value}
              onResize={previewSize.set}
              className={className}
            >
              {rightArea.content}
            </DetailsView>
          )}
        </>
      ) : null}

      {rightArea && isSmDown ? (
        <Dialog
          fullScreen
          open={true}
          TransitionComponent={Slide}
          TransitionProps={{
            // @ts-expect-error MUI component seems to be typed
            // incorrectly. The props are passed down to the Slide
            // component and the transition is correct, so everything
            // is working as expected.
            direction: 'left',
          }}
        >
          {rightArea.content}
        </Dialog>
      ) : null}
    </>
  );
};
