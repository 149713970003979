import { gql, useMutation } from '@apollo/client';

import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';

export const MUTATE_BZ_OBJECT = gql`
  mutation bzObjects(
    $bzObjType: BzObjType!
    $ids: [Int]!
    $isClosed: Boolean
    $frozen: Boolean
    $note: String
  ) {
    mutateBzObjects(
      input: {
        bzObjType: $bzObjType
        codes: $ids
        isClosed: $isClosed
        frozen: $frozen
        bodyText: $note
      }
    ) {
      ok
      errors {
        property
        apiValidationErrorType
        validationDataType
      }
    }
  }
`;

type MutateBzResponse = {
  createShadowRe: {
    ok: boolean;
    errors: {
      property: string;
    };
  };
};

export type MutateBzVars = {
  bzObjType: BzObjType;
  ids: number[];
  isClosed?: boolean;
  frozen?: boolean;
  note?: string;
};

export const useMutateBzObject = () => {
  const [mutate] = useMutation<MutateBzResponse, MutateBzVars>(
    MUTATE_BZ_OBJECT
  );
  return mutate;
};
