import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { FileLinkType } from '../Enums/FileLinkType.enum';

import { FileEntity } from './FileEntity.entity';
import { User } from './User.entity';

export class FileLink<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: link */
  link?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: fileLinkType */
  fileLinkType?: FileLinkType;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: user */
  user?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<FileLink> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  link: {
    alias: 'link',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  userId: {
    alias: 'userCode',
  },
  fileLinkType: {
    alias: 'fileLinkType',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const fileLinkEntityDefinition: EntitiyDefinition<FileLink> = {
  local: {},
  remote: {
    queryName: 'getFileLink',
    fragmentName: 'FileLink',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
