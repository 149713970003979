import { UseFormReturn } from 'react-hook-form';

import { MaskExtendedConfig } from '../types';

import { FormUpdateConfig, useFormUpdate } from './use-form-update';

export function createUseFormUpdate<T>(config: ConfigCreator<T>) {
  return (form: UseFormReturn<T>, mask: MaskExtendedConfig) => {
    return useFormUpdate(config(form, mask), form);
  };
}
type ConfigCreator<T> = (
  form: UseFormReturn<T>,
  mask: MaskExtendedConfig
) => FormUpdateConfig<T>;
