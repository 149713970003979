import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ProjectStepLinkType } from '../Enums/ProjectStepLinkType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputProjectStepLink<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: projectStep1Code */
  projectStep1Code?: number;
  /** Alias: projectStep2Code */
  projectStep2Code?: number;
  /** Alias: linkType */
  linkType?: ProjectStepLinkType;
  /** Alias: projectCode */
  projectCode?: number;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectStepLink> = {
  code: {
    alias: 'code',
  },
  projectStep1Code: {
    alias: 'projectStep1Code',
  },
  projectStep2Code: {
    alias: 'projectStep2Code',
  },
  linkType: {
    alias: 'linkType',
  },
  projectCode: {
    alias: 'projectCode',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectStepLinkEntityDefinition: EntitiyDefinition<InputProjectStepLink> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectStepLink',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
