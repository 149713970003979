import { createContext, useCallback, useContext } from 'react';
import {
  createSearchParams,
  NavigateOptions,
  URLSearchParamsInit,
} from 'react-router-dom';

import { useLocation, useNavigate } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { NEW_ENTITY_ID } from '../mask-metadata';
import { MaskConfig, OnOpenMask } from '../types';

import { useMaskMetaData } from './use-mask-meta-data';

interface OpenMaskState {
  onOpenMask: OnOpenMask;
}

const Context = createContext<OpenMaskState | null>(null);
export const OpenMaskOverride = Context.Provider;

const useOnOpenMaskContext = () => {
  const context = useContext(Context);
  return context;
};

interface UseOpenMaskProps {
  entityType?: Entities;
  params?: {
    create?: {
      [key in string]?: string;
    };
    edit?: {
      [key in string]?: string;
    };
  };
}

export const useOpenMask = (props: UseOpenMaskProps = {}) => {
  const { entityType, params } = props;
  const overrideContext = useOnOpenMaskContext();
  const getPathName = useCallback(
    (config: MaskConfig) => {
      const { entity, id } = config;
      return entity === entityType
        ? `details/${id ?? NEW_ENTITY_ID}`
        : `details/${entity}/${id ?? NEW_ENTITY_ID}`;
    },
    [entityType]
  );

  const getParams = useCallback(
    (config: MaskConfig) => {
      const { id } = config;

      return id ? params?.edit : params?.create;
    },
    [params?.create, params?.edit]
  );

  const onOpenMask = useCreateOpenMask({
    getPathName,
    getParams,
  });

  return overrideContext ? overrideContext.onOpenMask : onOpenMask;
};

export const useFileOpenMask = () => {
  const overrideContext = useOnOpenMaskContext();

  const getPathName = useCallback((config: MaskConfig) => {
    const { entity, id } = config;

    const detailsPart = `details/${entity}/${id ?? NEW_ENTITY_ID}`;
    return detailsPart;
  }, []);
  const onOpenMask = useCreateOpenMask({ getPathName });

  return overrideContext ? overrideContext.onOpenMask : onOpenMask;
};

const options = { replace: true };
export const useOpenMaskOverlay = () => {
  const metaData = useMaskMetaData();
  const location = useLocation();
  const getPathName = useCallback(
    (config: MaskConfig) => {
      const { entity, id } = config;
      let pathname = location.pathname;
      if (entity !== metaData.subEntityType) {
        // This might be problematic if the URL contains this fragment already in
        // some other place unrelated to the mask.
        pathname = pathname.replace(metaData.subEntityType, entity);
      }

      pathname = pathname.replace(
        metaData.subEntityId,
        id?.toString() ?? NEW_ENTITY_ID
      );
      return pathname;
    },
    [location.pathname, metaData.subEntityId, metaData.subEntityType]
  );
  return useCreateOpenMask({ getPathName, options });
};

interface UseCreateOpenMaskProps {
  getPathName: (config: MaskConfig) => string;
  options?: NavigateOptions;
  getParams?: (config: MaskConfig) => {
    [key in string]?: string;
  };
}

const useCreateOpenMask = (props: UseCreateOpenMaskProps) => {
  const { getPathName, options, getParams } = props;
  const navigate = useNavigate();
  return useCallback(() => {
    const getNavigationPropeties = (config: MaskConfig) => {
      const { template, openTab, params = {} } = config;
      // We can't use navigate('./{id}') form here because this component doesn't
      // actually render inside react-router's Route.
      // So relative navigation is not possible.
      const pathname = getPathName(config);
      const injectedParams = getParams?.(config) || {};

      const searchInit: URLSearchParamsInit = {};

      if (template) {
        searchInit.template = `${template.entity}:${template.id}`;
      }

      if (openTab) {
        searchInit.openTab = openTab;
      }

      for (const [key, value] of Object.entries({
        ...params,
        ...injectedParams,
      })) {
        if (value != null) {
          searchInit[key] = value;
        }
      }
      return {
        pathname,
        search: `${createSearchParams(searchInit)}`,
      };
    };

    return {
      getHref: (config: MaskConfig) => {
        const { pathname, search } = getNavigationPropeties(config);
        return `${pathname}${search ? '?' + search : ''}`;
      },
      handler: (config: MaskConfig) => {
        const navigationProps = getNavigationPropeties(config);
        navigate(navigationProps, options);
      },
    };
  }, [getPathName, getParams, navigate, options]);
};
