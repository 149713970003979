import { useCallback, useRef } from 'react';

export const useSyncTimelines = (
  timelineClass1: string,
  timelineClass2: string
) => {
  const firstCalCont = useRef(null);
  const secondCalCont = useRef(null);

  const skipScroll1 = useRef(false);
  const skipScroll2 = useRef(false);

  const handleScroll1 = useCallback((ev) => {
    if (secondCalCont.current && !skipScroll2.current) {
      skipScroll1.current = true;
      secondCalCont.current.scrollLeft = ev.target.scrollLeft;
    }
    skipScroll1.current = false;
  }, []);

  const handleScroll2 = useCallback((ev) => {
    if (firstCalCont.current && !skipScroll1.current) {
      skipScroll2.current = true;
      firstCalCont.current.scrollLeft = ev.target.scrollLeft;
    }
    skipScroll2.current = false;
  }, []);

  const handleFirstScroll = useCallback(() => {
    if (!firstCalCont.current) {
      firstCalCont.current = document.querySelector(
        `.${timelineClass1} .mbsc-timeline-grid-scroll`
      );
      firstCalCont.current.addEventListener('scroll', handleScroll1);
    }
  }, [handleScroll1, timelineClass1]);

  const handleSecondScroll = useCallback(() => {
    if (!secondCalCont.current) {
      secondCalCont.current = document.querySelector(
        `.${timelineClass2} .mbsc-timeline-grid-scroll`
      );
      secondCalCont.current.addEventListener('scroll', handleScroll2);
    }
  }, [handleScroll2, timelineClass2]);

  const detachFirstScroll = useCallback(() => {
    if (firstCalCont.current) {
      firstCalCont.current.removeEventListener('scroll', handleScroll1);
    }
  }, [handleScroll1]);

  const detachSecondScroll = useCallback(() => {
    if (secondCalCont.current) {
      secondCalCont.current.removeEventListener('scroll', handleScroll2);
    }
  }, [handleScroll2]);

  return {
    first: {
      onPageLoaded: handleFirstScroll,
      onDestroy: detachFirstScroll,
    },
    second: {
      onPageLoaded: handleSecondScroll,
      onDestroy: detachSecondScroll,
    },
  };
};
