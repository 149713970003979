import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { FileServiceProviderType } from '../Enums/FileServiceProviderType.enum';

export class FileServiceProviderConfig<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: any;
  /** Alias: name */
  name?: string;
  /** Alias: providerType */
  providerType?: FileServiceProviderType;
  /** Alias: directoryNameConvertPath */
  directoryNameConvertPath?: string[];
  /** Alias: directoryNameReplacePath */
  directoryNameReplacePath?: string[];
  /** Alias: isWorkDirectory */
  isWorkDirectory?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<FileServiceProviderConfig> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  providerType: {
    alias: 'providerType',
  },
  directoryNameConvertPath: {
    alias: 'directoryNameConvertPath',
  },
  directoryNameReplacePath: {
    alias: 'directoryNameReplacePath',
  },
  isWorkDirectory: {
    alias: 'isWorkDirectory',
  },
  __typename: {
    alias: '__typename',
  },
};

export const fileServiceProviderConfigEntityDefinition: EntitiyDefinition<FileServiceProviderConfig> =
  {
    local: {},
    remote: {
      queryName: 'getFileServiceProvider',
      fragmentName: 'FileServiceProviderConfig',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
