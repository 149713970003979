import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import {
  useDataMutation,
  useDataProvider,
  useDeleteEntity,
  useFormPlus,
} from '@work4all/data';

import { IncomingMailBlockedSender } from '@work4all/models/lib/Classes/IncomingMailBlockedSender.entity';
import { InputIncomingMailBlockedSender } from '@work4all/models/lib/Classes/InputIncomingMailBlockedSender.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface BlockedSendersProps {
  onAfterBlockedSendersChange: () => void;
}

interface FormValues {
  address: string;
}

export function BlockedSenders(props: BlockedSendersProps) {
  const { onAfterBlockedSendersChange } = props;

  const { t } = useTranslation();

  const blockedSenders = useDataProvider<IncomingMailBlockedSender>(
    useMemo<DataRequest>(() => {
      return {
        entity: Entities.incomingMailBlockedSender,
        data: {
          id: null,
          address: null,
        } satisfies IncomingMailBlockedSender,
        completeDataResponse: true,
      };
    }, [])
  );

  const handleChange = () => {
    blockedSenders.refetch();
    onAfterBlockedSendersChange();
  };

  const [deleteEntity] = useDeleteEntity(false);

  const deleteHiddenAddress = async (sender: IncomingMailBlockedSender) => {
    await deleteEntity(
      {
        type: Entities.incomingMailBlockedSender,
        ids: [sender.id],
      },
      {
        onCompleted: handleChange,
      }
    );
  };

  const [upsertHiddenAddress] = useDataMutation<
    IncomingMailBlockedSender,
    EMode.upsert
  >({
    entity: Entities.incomingMailBlockedSender,
    mutationType: EMode.upsert,
    responseData: { id: null },
    onCompleted: handleChange,
    resetStore: false,
  });

  const form = useFormPlus<FormValues>({
    defaultValues: {
      address: '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    const { address } = data;

    await upsertHiddenAddress<InputIncomingMailBlockedSender>({ address });

    form.reset({
      address: '',
    });
  };

  return (
    <Box>
      {blockedSenders.data.map((sender) => {
        return (
          <Box
            key={sender.id}
            sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
          >
            <Typography noWrap flex={1} title={sender.address}>
              {sender.address}
            </Typography>
            <IconButton onClick={() => deleteHiddenAddress(sender)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      })}

      <Box>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LabeledInput
            label={t('ASSIGN_INCOMING_EMAILS.ADD_BLOCKED_SENDER_INPUT')}
            autoComplete="off"
            {...form.register('address')}
          />
          <button hidden disabled={form.formState.isSubmitting} type="submit" />
        </form>
      </Box>
    </Box>
  );
}
