import styles from './FullAddressPicker.module.scss';

import ExpandLess from '@mui/icons-material/ExpandLess';
import { Box, IconButton, Stack } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useContactActions } from '@work4all/components/lib/hooks/use-contact-actions';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartnerPickerPopover } from '../../../../../../../components/entity-picker/BusinessPartnerPickerPopover';
import { ContactPickerField } from '../../../../../../../components/entity-picker/ContactPickerField';
import { LabeledInput, PickerTargetButton } from '../../../../../locked-inputs';

const BUSINESS_PARTNER_PICKER_DATA: Customer | Supplier = {
  id: null,
  mainContactId: null,
  contactList: [
    {
      id: null,
      displayName: null,
      role: null,
      layedOff: null,
      salutation: {
        id: null,
        isMale: null,
        isFemale: null,
      },
    },
  ],
  isPrivateCustomer: null,
};

export interface FullAddressPickerProps {
  entity: Entities;
  businessPartner: Customer | Supplier;
  businessPartnerType: SdObjType;
  contact?: Contact;
  addressString?: string;
  compareBusinessPartnerId?: number;
  compareContactId?: number;
  compareAddressString?: string;
  label?: string;
  combinedBusinessPartner?: boolean;
  onChange?: (value: {
    businessPartner?: Customer | Supplier;
    contact?: Contact;
    addressString?: string;
  }) => void;
  onExpandChange?: (expanded: boolean) => void;
  expanded?: boolean;
  disableBusinessPartnerField?: boolean;
}

export const FullAddressPicker = (props: FullAddressPickerProps) => {
  const {
    entity,
    businessPartnerType,
    businessPartner,
    label,
    contact,
    onChange,
    addressString,
    compareBusinessPartnerId,
    compareContactId,
    combinedBusinessPartner,
    expanded = false,
    onExpandChange,
    disableBusinessPartnerField,
    compareAddressString,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const businessPartnerWrapperRef = useRef(null);

  const { actions } = useContactActions({
    businessPartnerType,
    businessPartnerId: businessPartner?.id,
    eMail: contact?.eMail || businessPartner?.eMail,
    id: contact?.id,
  });

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const finalLabel = useMemo(() => {
    return (
      label ||
      (businessPartnerType === SdObjType.LIEFERANT
        ? t('COMMON.SUPPLIER')
        : t('COMMON.CUSTOMER')) +
        (businessPartner?.number ? ' #' + businessPartner?.number : '')
    );
  }, [label, businessPartnerType, t, businessPartner?.number]);

  const placeholder = useMemo(
    () =>
      businessPartnerType === SdObjType.LIEFERANT
        ? t('COMMON.SUPPLIER')
        : businessPartnerType === SdObjType.KUNDE
        ? t('COMMON.CUSTOMER')
        : t('COMMON.BUSINESS_PARTNER'),
    [businessPartnerType, t]
  );

  const [editedAddressString, setEditedAddressString] = useState('');

  const CollapsedView = useMemo(() => {
    const isIdentical =
      addressString === compareAddressString &&
      businessPartner?.id === compareBusinessPartnerId &&
      contact?.id === compareContactId;

    const value =
      isIdentical && !!businessPartner?.id
        ? t('COMMON.IDENTICAL')
        : businessPartner
        ? businessPartner?.name +
          (contact ? ' | ' + contact?.firstName + ' ' + contact?.name : '')
        : '';

    return (
      <PickerTargetButton
        onClick={() => setIsExpanded(true)}
        label={finalLabel}
        value={value}
        placeholder={placeholder}
        iconRight={actions}
      />
    );
  }, [
    actions,
    addressString,
    businessPartner,
    compareAddressString,
    compareBusinessPartnerId,
    compareContactId,
    contact,
    finalLabel,
    placeholder,
    t,
  ]);

  useEffect(() => {
    setEditedAddressString(addressString?.replace(/^\n+|\n+$/g, ''));
  }, [setEditedAddressString, addressString]);

  const ExpandedView = useMemo(() => {
    return (
      <div ref={businessPartnerWrapperRef} className={styles.wrapper}>
        <IconButton
          className={styles.expandLess}
          onClick={() => {
            setIsExpanded(false);
            onExpandChange?.(false);
          }}
        >
          <ExpandLess className={styles.icon} />
        </IconButton>
        <Stack gap="0.5rem">
          <Box>
            <BusinessPartnerPickerPopover
              anchorEl={businessPartnerWrapperRef}
              entity={entity}
              multiple={false}
              withActionBar
              data={BUSINESS_PARTNER_PICKER_DATA}
              combinedBusinessPartner={combinedBusinessPartner}
              value={businessPartner}
              onChange={(selectedBusinessPartner) => {
                onChange &&
                  onChange({
                    businessPartner: selectedBusinessPartner as
                      | Customer
                      | Supplier,
                  });
              }}
            >
              <PickerTargetButton
                value={businessPartner?.name || ''}
                label={finalLabel}
                disabled={disableBusinessPartnerField}
              />
            </BusinessPartnerPickerPopover>
          </Box>
          <Box>
            {businessPartner && !businessPartner?.isPrivateCustomer && (
              <ContactPickerField
                value={contact}
                businessPartnerId={businessPartner?.id}
                businessPartnerType={businessPartnerType}
                clearable={true}
                onChange={(selectedContact) => {
                  onChange &&
                    onChange({
                      contact: selectedContact,
                    });
                }}
                showContactActions={false}
              />
            )}
          </Box>
          <Box>
            <LabeledInput
              label=""
              type="textarea"
              multiline={true}
              minRows={4}
              value={editedAddressString}
              onChange={(e) => {
                setEditedAddressString(e.target.value);
              }}
              onBlur={() => {
                onChange &&
                  onChange({
                    addressString: editedAddressString.replace(
                      /^\n+|\n+$/g,
                      ''
                    ),
                  });
              }}
            />
          </Box>
        </Stack>
      </div>
    );
  }, [
    businessPartner,
    businessPartnerType,
    contact,
    disableBusinessPartnerField,
    editedAddressString,
    entity,
    finalLabel,
    combinedBusinessPartner,
    onChange,
    onExpandChange,
  ]);

  return isExpanded ? ExpandedView : CollapsedView;
};
