export enum ConvertTempfileTarget {
  BRIEF = 'BRIEF',
  DOKUMENT = 'DOKUMENT',
  KUNDE_STAMMDATEN_BILD = 'KUNDE_STAMMDATEN_BILD',
  LIEFERANT_STAMMDATEN_BILD = 'LIEFERANT_STAMMDATEN_BILD',
  KUNDEN_AP_STAMMDATEN_BILD = 'KUNDEN_AP_STAMMDATEN_BILD',
  LIEFERANTEN_AP_STAMMDATEN_BILD = 'LIEFERANTEN_AP_STAMMDATEN_BILD',
  CRM_ANHANG_TELEFONAT = 'CRM_ANHANG_TELEFONAT',
  CRM_ANHANG_NOTIZ = 'CRM_ANHANG_NOTIZ',
  CRM_ANHANG_AUFGABE = 'CRM_ANHANG_AUFGABE',
  CRM_ANHANG_TERMIN = 'CRM_ANHANG_TERMIN',
  EINGANGSRECHNUNG_BELEG = 'EINGANGSRECHNUNG_BELEG',
  ERP_ANHANG_EINGANGSRECHNUNG = 'ERP_ANHANG_EINGANGSRECHNUNG',
  ERP_ANHANG_KALKULATION = 'ERP_ANHANG_KALKULATION',
  ERP_ANHANG_ANGEBOT = 'ERP_ANHANG_ANGEBOT',
  ERP_ANHANG_AUFTRAG = 'ERP_ANHANG_AUFTRAG',
  ERP_ANHANG_RECHNUNG = 'ERP_ANHANG_RECHNUNG',
  ERP_ANHANG_LIEFERSCHEIN = 'ERP_ANHANG_LIEFERSCHEIN',
  ERP_ANHANG_BEDARF = 'ERP_ANHANG_BEDARF',
  ERP_ANHANG_BESTELLUNG = 'ERP_ANHANG_BESTELLUNG',
  ERP_ANHANG_EINGANGSLIEFERSCHEIN = 'ERP_ANHANG_EINGANGSLIEFERSCHEIN',
  DOCUMENT_SIGNATURE = 'DOCUMENT_SIGNATURE',
  TRAVEL_RECEIPT = 'TRAVEL_RECEIPT',
  FILE_LINK = 'FILE_LINK',
  PROFILE_PICTURE = 'PROFILE_PICTURE',
  ARCHIV_PDF = 'ARCHIV_PDF',
  REPORT_2 = 'REPORT_2',
}
