import { DateTime } from 'luxon';

import { ICardWidgetColumnDef } from '@work4all/components/lib/dataDisplay/card-widget';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { ProductionContract } from '@work4all/models/lib/Classes/ProductionContract.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const taskData: Task = {
  date: null,
  title: null,
  user: {
    id: null,
    shortName: null,
  },
};
const taskCols: ICardWidgetColumnDef<Task>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'title',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const ticketData: Ticket = {
  followUpDate: null,
  title: null,
  editor1: {
    id: null,
    shortName: null,
  },
};
const ticketCols: ICardWidgetColumnDef<Ticket>[] = [
  {
    field: 'followUpDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'title',
    primary: true,
  },
  {
    field: 'editor1.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const offerData: Offer = {
  date: null,
  note: null,
  user: {
    id: null,
    shortName: null,
  },
};
const offerCols: ICardWidgetColumnDef<Offer>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const productionContractData: ProductionContract = {
  creationDate: null,
  note: null,
  creator: { id: null, shortName: null },
};
const productionContractCols: ICardWidgetColumnDef<ProductionContract>[] = [
  {
    field: 'creationDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'creator.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const appointmentData: Appointment = {
  startDate: null,
  title: null,
  user: { id: null, shortName: null },
};
const appointmentCols: ICardWidgetColumnDef<Appointment>[] = [
  {
    field: 'startDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'title',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const checklistData: CheckList = {
  date: null,
  title: null,
  user: { id: null, shortName: null },
};
const checklistCols: ICardWidgetColumnDef<CheckList>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'title',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const note: Note = {
  date: null,
  title: null,
  user: { id: null, shortName: null },
};
const noteCols: ICardWidgetColumnDef<Note>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'title',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const project: Project = {
  startDateInner: null,
  name: null,
  internalProjectManager: { id: null, shortName: null },
};
const projectCols: ICardWidgetColumnDef<Project>[] = [
  {
    field: 'startDateInner',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'name',
    primary: true,
  },
  {
    field: 'internalProjectManager.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const callMemoData: CallMemo = {
  date: null,
  title: null,
  user: { id: null, shortName: null },
};
const callMemoCols: ICardWidgetColumnDef<CallMemo>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'title',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const salesOpportunitiesData: SalesOpportunities = {
  dateCreated: null,
  name: null,
  user: { id: null, shortName: null },
};
const salesOpportunitiesCols: ICardWidgetColumnDef<SalesOpportunities>[] = [
  {
    field: 'dateCreated',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'name',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const contractData: Contract = {
  contractDate: null,
  note: null,
  user: { id: null, shortName: null },
};
const contractCols: ICardWidgetColumnDef<Contract>[] = [
  {
    field: 'contractDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const calculationData: Calculation = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const calculationCols: ICardWidgetColumnDef<Calculation>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const deliveryNote: DeliveryNote = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const deliveryNoteCols: ICardWidgetColumnDef<DeliveryNote>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const invoiceData: Invoice = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const invoiceCols: ICardWidgetColumnDef<DeliveryNote>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const visitationReportData: VisitationReport = {
  creationDate: null,
  note: null,
  user: { id: null, shortName: null },
};
const visitationReportCols: ICardWidgetColumnDef<VisitationReport>[] = [
  {
    field: 'creationDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const letterData: Letter = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const letterCols: ICardWidgetColumnDef<Letter>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const documentData: Document = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const documentCols: ICardWidgetColumnDef<Document>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const eMailData: EMail = {
  date: null,
  subject: null,
  user: { id: null, shortName: null },
};
const eMailCols: ICardWidgetColumnDef<EMail>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'subject',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const demandData: Demand = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const demandCols: ICardWidgetColumnDef<Demand>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const orderData: Order = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const orderCols: ICardWidgetColumnDef<Order>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const travelExpensesData: TravelExpenses = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const travelExpensesCols: ICardWidgetColumnDef<TravelExpenses>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const travelReceiptsData: TravelReceipts = {
  date: null,
  description: null,
  receiptKind: {
    id: null,
    name: null,
  },
};
const travelReceiptsCols: ICardWidgetColumnDef<TravelReceipts>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'description',
    primary: true,
  },
  {
    field: 'receiptKind',
    renderCell: (item) => item.value?.name,
  },
];

const reData: ReViewModel = {
  invoiceDate: null,
  note: null,
  user: { id: null, shortName: null },
};
const reCols: ICardWidgetColumnDef<ReViewModel>[] = [
  {
    field: 'invoiceDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const raData: RaViewModel = {
  invoiceDate: null,
  note: null,
  createdByUser: { id: null, shortName: null },
};
const raCols: ICardWidgetColumnDef<RaViewModel>[] = [
  {
    field: 'invoiceDate',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'createdByUser.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

const inboundDeliveryNoteData: InboundDeliveryNote = {
  date: null,
  note: null,
  user: { id: null, shortName: null },
};
const inboundDeliveryNoteCols: ICardWidgetColumnDef<InboundDeliveryNote>[] = [
  {
    field: 'date',
    renderCell: (item) => DateTime.fromISO(item.value).toFormat('dd.MM.yy'),
  },
  {
    field: 'note',
    primary: true,
  },
  {
    field: 'user.shortName',
    renderCell: (item) => item.value?.toUpperCase(),
  },
];

export const widgetColsByEntity: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ICardWidgetColumnDef<any>[]
> = {
  [Entities.task]: taskCols,
  [Entities.ticket]: ticketCols,
  [Entities.offer]: offerCols,
  [Entities.order]: orderCols,
  [Entities.productionContract]: productionContractCols,
  [Entities.appointment]: appointmentCols,
  [Entities.checkList]: checklistCols,
  [Entities.note]: noteCols,
  [Entities.project]: projectCols,
  [Entities.callMemo]: callMemoCols,
  [Entities.salesOpportunities]: salesOpportunitiesCols,
  [Entities.contract]: contractCols,
  [Entities.calculation]: calculationCols,
  [Entities.deliveryNote]: deliveryNoteCols,
  [Entities.invoice]: invoiceCols,
  [Entities.visitationReport]: visitationReportCols,
  [Entities.letter]: letterCols,
  [Entities.document]: documentCols,
  [Entities.eMail]: eMailCols,
  [Entities.demand]: demandCols,
  [Entities.travelExpenses]: travelExpensesCols,
  [Entities.travelReceipts]: travelReceiptsCols,
  [Entities.reViewModel]: reCols,
  [Entities.raViewModel]: raCols,
  [Entities.inboundDeliveryNote]: inboundDeliveryNoteCols,
};

export const widgetDataByEntity = {
  [Entities.salesOpportunities]: salesOpportunitiesData,
  [Entities.callMemo]: callMemoData,
  [Entities.project]: project,
  [Entities.productionContract]: productionContractData,
  [Entities.order]: orderData,
  [Entities.checkList]: checklistData,
  [Entities.note]: note,
  [Entities.appointment]: appointmentData,
  [Entities.task]: taskData,
  [Entities.ticket]: ticketData,
  [Entities.offer]: offerData,
  [Entities.contract]: contractData,
  [Entities.calculation]: calculationData,
  [Entities.deliveryNote]: deliveryNote,
  [Entities.invoice]: invoiceData,
  [Entities.visitationReport]: visitationReportData,
  [Entities.letter]: letterData,
  [Entities.document]: documentData,
  [Entities.eMail]: eMailData,
  [Entities.demand]: demandData,
  [Entities.travelExpenses]: travelExpensesData,
  [Entities.travelReceipts]: travelReceiptsData,
  [Entities.reViewModel]: reData,
  [Entities.raViewModel]: raData,
  [Entities.inboundDeliveryNote]: inboundDeliveryNoteData,
};
