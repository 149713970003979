import { MbscCalendarEvent } from '@mobiscroll/react';

import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';

import { isVacationApproved } from '../../../containers/vacations/utils';
import { APPROVED_COLOR, PENDING_COLOR, SICKNESS_COLOR } from '../constants';

import { getWholeDayInformation } from './getWholeDayInformation';

export const absenceBackground = (
  events: MbscCalendarEvent[],
  absence: Vacation | Sickness
) => {
  const isSickness = absence.__typename === 'Krankheit';
  const vacation = absence as Vacation;

  const color = isVacationApproved(absence)
    ? isSickness
      ? SICKNESS_COLOR
      : vacation?.simpleVacationKind?.color ?? APPROVED_COLOR
    : PENDING_COLOR;

  if (isSickness) return color;

  const info = getWholeDayInformation(events, absence);
  if (info.firstHalf)
    return `linear-gradient(135deg, ${color} 0%, ${color} 50%, transparent 50%)`;
  else if (info.secondHalf)
    return `linear-gradient(-45deg, ${color} 0%, ${color} 50%, transparent 50%)`;
  else return color;
};
