import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';

export const PROJECT_PROCESS_DATA: ProjectProcess = {
  id: null,
  process: null,
  startDatum: null,
  endDateInner: null,
  kindId: null,
  planningAmount: null,
  comment: null,
  parent: {
    id: null,
    process: null,
  },
  completionFeedbacks: [
    {
      id: null,
      completionPercent: null,
      timestamp: null,
    },
  ],
  duration: null,
  isClosed: null,
  overBookingPossible: null,
  parentId: null,
  planningCosts: null,
  projectPlanningFeedbackList: [
    {
      id: null,
      date: null,
      processDegree: null,
    },
  ],
  customerId: null,
  customer: {
    id: null,
    name: null,
    number: null,
    website: null,
  },
  projectId: null,
  project: {
    id: null,
    number: null,
    name: null,
  },
  supplierId: null,
  supplier: {
    id: null,
    name: null,
    number: null,
    website: null,
  },
  ressourceClass: {
    id: null,
    name: null,
  },
  ressourceClassId: null,
  creatorUserId: null,
  user: {
    id: null,
    displayName: null,
  },
  valuation: null,
  projectStepLinksIncomming: [
    {
      id: null,
      linkType: null,
      projectProcess1Id: null,
      projectProcess2Id: null,
      projectStep1: {
        id: null,
        process: null,
        kindId: null,
      },
      projectStep2: {
        id: null,
        process: null,
        kindId: null,
      },
    },
  ],
  projectStepLinksOutgoing: [
    {
      id: null,
      linkType: null,
      projectProcess1Id: null,
      projectProcess2Id: null,
      projectStep1: {
        id: null,
        process: null,
        kindId: null,
      },
      projectStep2: {
        id: null,
        process: null,
        kindId: null,
      },
    },
  ],
};
