import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ErpStatus } from './ErpStatusPicker';

export const mapErpStatus = (frozen: boolean, isClosed: boolean) => {
  if (!frozen && !isClosed) return ErpStatus.Open;
  if (frozen && !isClosed) return ErpStatus.Frozen;
  if (frozen && isClosed) return ErpStatus.FrozenAndClosed;
  return ErpStatus.Incorrect;
};

export const useMapErpStatus = (frozen: boolean, isClosed: boolean) => {
  const { t } = useTranslation();
  const status: ErpStatus = useMemo(
    () => mapErpStatus(frozen, isClosed),
    [isClosed, frozen]
  );

  const stringValue = t('COMMON.ERP_STATUS_PICKER_' + status);

  return { status, stringValue };
};

export function mapFromErpStatus(status: ErpStatus): {
  frozen: boolean;
  isClosed: boolean;
} {
  switch (status) {
    case ErpStatus.Open:
      return { frozen: false, isClosed: false };
    case ErpStatus.Frozen:
      return { frozen: true, isClosed: false };
    case ErpStatus.FrozenAndClosed:
      return { frozen: true, isClosed: true };
    default:
      throw new Error('Invalid ErpStatus');
  }
}
