import { gql, useQuery } from '@apollo/client';
import { Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';

import { ListEntityPicker } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { ArticlePurchaseInformation } from '@work4all/models/lib/Classes/ArticlePurchaseInformation.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const FIELDS: ArticlePurchaseInformation = {
  price: null,
  objectDate: null,
  currency: {
    id: null,
    name: null,
  },
  supplier: {
    id: null,
    name: null,
  },
};

const ARTICLE_PURCHASE_INFO = gql`
  query getArticlePurchaseInformation($articleId: Int!) {
    getArticlePurchaseInformation(articleCode: $articleId) {
      price
      objectId: objectCode
      purchaseInfoType
      supplier {
        id: code
        name
      }
      currency {
        id: code
        name
      }
      objectDate
    }
  }
`;

interface ArticlePurchaseInformationResponse {
  getArticlePurchaseInformation: ArticlePurchaseInformation[];
}

interface ArticlePurchaseInformationVariables {
  articleId: number;
}

export type SupplierPricePickerProps = { articleId: number } & IPickerProps<
  { id: number; name: string },
  false
>;

export const SupplierPricePicker = (props: SupplierPricePickerProps) => {
  const { data, articleId, ...rest } = props;
  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const variables = useMemo(() => {
    return {
      articleId,
    };
  }, [articleId]);

  const response = useQuery<
    ArticlePurchaseInformationResponse,
    ArticlePurchaseInformationVariables
  >(ARTICLE_PURCHASE_INFO, {
    variables,
  });

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return {
      data: response.data?.getArticlePurchaseInformation || [],
      total: response.data?.getArticlePurchaseInformation.length || 0,
    };
  }, [response]);

  const renderItem = (priceGroup: { id: number; name: string }) => {
    const label = priceGroup.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.articlePurchaseInformation}
      data={allFields}
      filterBy={['supplier.name']}
      sortBy={'objectDate'}
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
};
