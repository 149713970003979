import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ErpObjectConversionPositionDetails<
  T extends EMode = EMode.entity
> {
  /** Alias: positionCode */
  positionCode?: number;
  /** Alias: newAmount */
  newAmount?: number;
  /** Alias: doNotConvert */
  doNotConvert?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<ErpObjectConversionPositionDetails> = {
  positionCode: {
    alias: 'positionCode',
  },
  newAmount: {
    alias: 'newAmount',
  },
  doNotConvert: {
    alias: 'doNotConvert',
  },
  __typename: {
    alias: '__typename',
  },
};

export const erpObjectConversionPositionDetailsEntityDefinition: EntitiyDefinition<ErpObjectConversionPositionDetails> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ErpObjectConversionPositionDetails',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
