import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ErpStatusMobileEditing } from '../Enums/ErpStatusMobileEditing.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { ArchivePdf } from './ArchivePdf.entity';
import { BankDetails } from './BankDetails.entity';
import { BaseDataLanguage } from './BaseDataLanguage.entity';
import { BusinessPartnerContactCombined } from './BusinessPartnerContactCombined.entity';
import { BzObjectReleaseInformation } from './BzObjectReleaseInformation.entity';
import { CostCenter } from './CostCenter.entity';
import { Currency } from './Currency.entity';
import { CustomField } from './CustomField.entity';
import { DeliveryKind } from './DeliveryKind.entity';
import { ErpAttachment } from './ErpAttachment.entity';
import { PaymentKind } from './PaymentKind.entity';
import { Position } from './Position.entity';
import { PriceGroup } from './PriceGroup.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { User } from './User.entity';

export class Order<T extends EMode = EMode.entity> {
  /** Alias: abgeschlossen */
  isClosed?: boolean;
  /** Alias: abrechenbar */
  isBillable?: boolean;
  /** Alias: archivPdf */
  archivePdf?: ArchivePdf<T>[];
  /** Alias: auftragsDatum */
  contractDate?: string;
  /** Alias: auftragsNummer */
  contractNumber?: number;
  /** Alias: auftragsbeginn */
  contractStartDate?: string;
  /** Alias: automatischeNummerierung */
  automaticNumbering?: boolean;
  /** Alias: bankverbindungCode */
  bankDetailsId?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzer2 */
  user2?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: benutzerCode2 */
  user2Id?: number;
  /** Alias: bestaetigt */
  isConfirmed?: boolean;
  /** Alias: bestelldatum */
  orderDate?: string;
  /** Alias: brutto */
  gross?: boolean;
  /** Alias: code */
  id?: number;
  /** Alias: createdByLoginId */
  createdByLoginId?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: dispositionsbeginn */
  dispositionStart?: string;
  /** Alias: dispositionsende */
  dispositionEnd?: string;
  /** Alias: eingefroren */
  frozen?: boolean;
  /** Alias: erpAnhaenge */
  erpAttachmentList?: ErpAttachment<T>[];
  /** Alias: feld1 */
  individualField1?: string;
  /** Alias: feld2 */
  individualField2?: string;
  /** Alias: feld3 */
  individualField3?: string;
  /** Alias: feld4 */
  individualField4?: string;
  /** Alias: feld5 */
  individualField5?: string;
  /** Alias: feld6 */
  individualField6?: string;
  /** Alias: feld7 */
  individualField7?: string;
  /** Alias: hauptAdresseAbteilungCode */
  businessPartnerDepartmentId?: number;
  /** Alias: hauptadresse */
  businessPartnerContactCombined?: BusinessPartnerContactCombined<T>;
  /** Alias: hauptadresseAnPostfach */
  businessPartnerToPostalBox?: boolean;
  /** Alias: hauptadresseApCode */
  contactId?: number;
  /** Alias: hauptadresseCompanyCode */
  businessPartnerId?: number;
  /** Alias: hauptadresseCompanyType */
  businessPartnerType?: SdObjType;
  /** Alias: hauptadresseText */
  businessPartnerText?: string;
  /** Alias: ihrZeichen */
  yourSign?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kopftext */
  headText?: string;
  /** Alias: kostenstelle */
  costCenter?: CostCenter<T>;
  /** Alias: kurs */
  course?: number;
  /** Alias: kursDatum */
  courseDate?: string;
  /** Alias: leistungsbeginn */
  serviceStartDate?: string;
  /** Alias: leistungsende */
  serviceEndDate?: string;
  /** Alias: leistungsort */
  serviceLocation?: string;
  /** Alias: lieferadresseGeaendert */
  deliveryAddressChanged?: boolean;
  /** Alias: lieferterminAbgehend */
  outgoingDeliveryDate?: string;
  /** Alias: lieferterminTatsaechlich */
  actualDeliveryDate?: string;
  /** Alias: lieferungsArtCode */
  deliveryKindId?: number;
  /** Alias: lieferungsArtZiel */
  deliveryKindTarget?: number;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: mobileBearbeitung */
  mobileEditing?: ErpStatusMobileEditing;
  /** Alias: nebenadresse1 */
  additionalAddress1?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse1AbteilungCode */
  additionalAddress1DepartmentId?: number;
  /** Alias: nebenadresse1AnPostfach */
  additionalAddress1ToPostalBox?: boolean;
  /** Alias: nebenadresse1ApCode */
  additionalAddress1ContactId?: number;
  /** Alias: nebenadresse1CompanyCode */
  additionalAddress1CompanyId?: number;
  /** Alias: nebenadresse1CompanyType */
  additionalAddress1CompanyType?: SdObjType;
  /** Alias: nebenadresse1Text */
  additionalAddress1Text?: string;
  /** Alias: nebenadresse2 */
  additionalAddress2?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse2AbteilungCode */
  additionalAddress2DepartmentId?: number;
  /** Alias: nebenadresse2AnPostfach */
  additionalAddress2ToPostalBox?: boolean;
  /** Alias: nebenadresse2ApCode */
  additionalAddress2ContactId?: number;
  /** Alias: nebenadresse2CompanyCode */
  additionalAddress2CompanyId?: number;
  /** Alias: nebenadresse2CompanyType */
  additionalAddress2CompanyType?: SdObjType;
  /** Alias: nebenadresse2Text */
  additionalAddress2Text?: string;
  /** Alias: nebenadresse3 */
  additionalAddress3?: BusinessPartnerContactCombined<T>;
  /** Alias: nebenadresse3AbteilungCode */
  additionalAddress3DepartmentId?: number;
  /** Alias: nebenadresse3AnPostfach */
  additionalAddress3ToPostalBox?: boolean;
  /** Alias: nebenadresse3ApCode */
  additionalAddress3ContactId?: number;
  /** Alias: nebenadresse3CompanyCode */
  additionalAddress3CompanyId?: number;
  /** Alias: nebenadresse3CompanyType */
  additionalAddress3CompanyType?: SdObjType;
  /** Alias: nebenadresse3Text */
  additionalAddress3Text?: string;
  /** Alias: niederlassungsCode */
  branchOfficeId?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: positionen */
  positionList?: Position<T>[];
  /** Alias: preisgruppe */
  priceGroupId?: number;
  /** Alias: preisgruppeItem */
  priceGroup?: PriceGroup<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektVorgangCode */
  projectProcessId?: number;
  /** Alias: rtfKopftext */
  rtfHeadText?: string;
  /** Alias: rtfSchlusstext */
  rtfFooterText?: string;
  /** Alias: schlusstext */
  footerText?: string;
  /** Alias: shopGenehmigt */
  shopApproved?: boolean;
  /** Alias: shopGenehmigtDatum */
  shopApprovedDate?: string;
  /** Alias: shopGenehmigtDurchLoginID */
  shopApprovedByLoginID?: string;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skonto2 */
  skonto2?: number;
  /** Alias: skontoFrist2 */
  skontoDurationDays2?: number;
  /** Alias: skontofrist */
  skontoDurationDays?: number;
  /** Alias: sprachCode */
  languageId?: number;
  /** Alias: status1 */
  status1?: number;
  /** Alias: status2 */
  status2?: number;
  /** Alias: status3 */
  status3?: number;
  /** Alias: status4 */
  status4?: number;
  /** Alias: status5 */
  status5?: number;
  /** Alias: unserZeichen */
  ourSign?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: wartungsCode */
  serviceId?: number;
  /** Alias: webShopOrderDefinitionData */
  shopOrderDefinitionData?: string;
  /** Alias: wert */
  value?: number;
  /** Alias: zahlungCode */
  paymentId?: number;
  /** Alias: zahlungsfrist */
  paymentDeadline?: number;
  /** Alias: mietdauer */
  rentDuration?: number;
  /** Alias: mietfaktor */
  rentFactor?: number;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: bankverbindung */
  bankDetails?: BankDetails<T>;
  /** Alias: lieferungArt */
  deliveryKind?: DeliveryKind<T>;
  /** Alias: bruttowert */
  grossValue?: number;
  /** Alias: zahlungsart */
  paymentKind?: PaymentKind<T>;
  /** Alias: wertLeitwaehrung */
  valueReserveCurrency?: number;
  /** Alias: sprache */
  language?: BaseDataLanguage<T>;
  /** Alias: bearbeitungsstatusShopErlaubt */
  editStatusShopAllowed?: boolean;
  /** Alias: vorgesetzter */
  supervisor?: User<T>;
  /** Alias: kostenstelleCode */
  costCenterId?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: releaseInformation */
  releaseInformation?: BzObjectReleaseInformation<T>;
  /** Alias: headerTextConverted */
  headerTextConverted?: string;
  /** Alias: footerTextConverted */
  footerTextConverted?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Order> = {
  isClosed: {
    alias: 'abgeschlossen',
  },
  isBillable: {
    alias: 'abrechenbar',
  },
  archivePdf: {
    alias: 'archivPdf',
    entity: Entities.archivePdf,
  },
  contractDate: {
    alias: 'auftragsDatum',
  },
  contractNumber: {
    alias: 'auftragsNummer',
  },
  contractStartDate: {
    alias: 'auftragsbeginn',
  },
  automaticNumbering: {
    alias: 'automatischeNummerierung',
  },
  bankDetailsId: {
    alias: 'bankverbindungCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  user2: {
    alias: 'benutzer2',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  user2Id: {
    alias: 'benutzerCode2',
  },
  isConfirmed: {
    alias: 'bestaetigt',
  },
  orderDate: {
    alias: 'bestelldatum',
  },
  gross: {
    alias: 'brutto',
  },
  id: {
    alias: 'code',
  },
  createdByLoginId: {
    alias: 'createdByLoginId',
  },
  date: {
    alias: 'datum',
  },
  dispositionStart: {
    alias: 'dispositionsbeginn',
  },
  dispositionEnd: {
    alias: 'dispositionsende',
  },
  frozen: {
    alias: 'eingefroren',
  },
  erpAttachmentList: {
    alias: 'erpAnhaenge',
    entity: Entities.erpAttachment,
  },
  individualField1: {
    alias: 'feld1',
  },
  individualField2: {
    alias: 'feld2',
  },
  individualField3: {
    alias: 'feld3',
  },
  individualField4: {
    alias: 'feld4',
  },
  individualField5: {
    alias: 'feld5',
  },
  individualField6: {
    alias: 'feld6',
  },
  individualField7: {
    alias: 'feld7',
  },
  businessPartnerDepartmentId: {
    alias: 'hauptAdresseAbteilungCode',
  },
  businessPartnerContactCombined: {
    alias: 'hauptadresse',
    entity: Entities.businessPartnerContactCombined,
  },
  businessPartnerToPostalBox: {
    alias: 'hauptadresseAnPostfach',
  },
  contactId: {
    alias: 'hauptadresseApCode',
  },
  businessPartnerId: {
    alias: 'hauptadresseCompanyCode',
  },
  businessPartnerType: {
    alias: 'hauptadresseCompanyType',
  },
  businessPartnerText: {
    alias: 'hauptadresseText',
  },
  yourSign: {
    alias: 'ihrZeichen',
  },
  insertTime: {
    alias: 'insertTime',
  },
  headText: {
    alias: 'kopftext',
  },
  costCenter: {
    alias: 'kostenstelle',
    entity: Entities.costCenter,
  },
  course: {
    alias: 'kurs',
  },
  courseDate: {
    alias: 'kursDatum',
  },
  serviceStartDate: {
    alias: 'leistungsbeginn',
  },
  serviceEndDate: {
    alias: 'leistungsende',
  },
  serviceLocation: {
    alias: 'leistungsort',
  },
  deliveryAddressChanged: {
    alias: 'lieferadresseGeaendert',
  },
  outgoingDeliveryDate: {
    alias: 'lieferterminAbgehend',
  },
  actualDeliveryDate: {
    alias: 'lieferterminTatsaechlich',
  },
  deliveryKindId: {
    alias: 'lieferungsArtCode',
  },
  deliveryKindTarget: {
    alias: 'lieferungsArtZiel',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  mobileEditing: {
    alias: 'mobileBearbeitung',
  },
  additionalAddress1: {
    alias: 'nebenadresse1',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress1DepartmentId: {
    alias: 'nebenadresse1AbteilungCode',
  },
  additionalAddress1ToPostalBox: {
    alias: 'nebenadresse1AnPostfach',
  },
  additionalAddress1ContactId: {
    alias: 'nebenadresse1ApCode',
  },
  additionalAddress1CompanyId: {
    alias: 'nebenadresse1CompanyCode',
  },
  additionalAddress1CompanyType: {
    alias: 'nebenadresse1CompanyType',
  },
  additionalAddress1Text: {
    alias: 'nebenadresse1Text',
  },
  additionalAddress2: {
    alias: 'nebenadresse2',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress2DepartmentId: {
    alias: 'nebenadresse2AbteilungCode',
  },
  additionalAddress2ToPostalBox: {
    alias: 'nebenadresse2AnPostfach',
  },
  additionalAddress2ContactId: {
    alias: 'nebenadresse2ApCode',
  },
  additionalAddress2CompanyId: {
    alias: 'nebenadresse2CompanyCode',
  },
  additionalAddress2CompanyType: {
    alias: 'nebenadresse2CompanyType',
  },
  additionalAddress2Text: {
    alias: 'nebenadresse2Text',
  },
  additionalAddress3: {
    alias: 'nebenadresse3',
    entity: Entities.businessPartnerContactCombined,
  },
  additionalAddress3DepartmentId: {
    alias: 'nebenadresse3AbteilungCode',
  },
  additionalAddress3ToPostalBox: {
    alias: 'nebenadresse3AnPostfach',
  },
  additionalAddress3ContactId: {
    alias: 'nebenadresse3ApCode',
  },
  additionalAddress3CompanyId: {
    alias: 'nebenadresse3CompanyCode',
  },
  additionalAddress3CompanyType: {
    alias: 'nebenadresse3CompanyType',
  },
  additionalAddress3Text: {
    alias: 'nebenadresse3Text',
  },
  branchOfficeId: {
    alias: 'niederlassungsCode',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  positionList: {
    alias: 'positionen',
    entity: Entities.position,
  },
  priceGroupId: {
    alias: 'preisgruppe',
  },
  priceGroup: {
    alias: 'preisgruppeItem',
    entity: Entities.priceGroup,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektVorgangCode',
  },
  rtfHeadText: {
    alias: 'rtfKopftext',
  },
  rtfFooterText: {
    alias: 'rtfSchlusstext',
  },
  footerText: {
    alias: 'schlusstext',
  },
  shopApproved: {
    alias: 'shopGenehmigt',
  },
  shopApprovedDate: {
    alias: 'shopGenehmigtDatum',
  },
  shopApprovedByLoginID: {
    alias: 'shopGenehmigtDurchLoginID',
  },
  skonto: {
    alias: 'skonto',
  },
  skonto2: {
    alias: 'skonto2',
  },
  skontoDurationDays2: {
    alias: 'skontoFrist2',
  },
  skontoDurationDays: {
    alias: 'skontofrist',
  },
  languageId: {
    alias: 'sprachCode',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  status3: {
    alias: 'status3',
  },
  status4: {
    alias: 'status4',
  },
  status5: {
    alias: 'status5',
  },
  ourSign: {
    alias: 'unserZeichen',
  },
  updateTime: {
    alias: 'updateTime',
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  serviceId: {
    alias: 'wartungsCode',
  },
  shopOrderDefinitionData: {
    alias: 'webShopOrderDefinitionData',
  },
  value: {
    alias: 'wert',
  },
  paymentId: {
    alias: 'zahlungCode',
  },
  paymentDeadline: {
    alias: 'zahlungsfrist',
  },
  rentDuration: {
    alias: 'mietdauer',
  },
  rentFactor: {
    alias: 'mietfaktor',
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  bankDetails: {
    alias: 'bankverbindung',
    entity: Entities.bankDetails,
  },
  deliveryKind: {
    alias: 'lieferungArt',
    entity: Entities.deliveryKind,
  },
  grossValue: {
    alias: 'bruttowert',
  },
  paymentKind: {
    alias: 'zahlungsart',
    entity: Entities.paymentKind,
  },
  valueReserveCurrency: {
    alias: 'wertLeitwaehrung',
  },
  language: {
    alias: 'sprache',
    entity: Entities.baseDataLanguage,
  },
  editStatusShopAllowed: {
    alias: 'bearbeitungsstatusShopErlaubt',
  },
  supervisor: {
    alias: 'vorgesetzter',
    entity: Entities.user,
  },
  costCenterId: {
    alias: 'kostenstelleCode',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  releaseInformation: {
    alias: 'releaseInformation',
    entity: Entities.bzObjectReleaseInformation,
  },
  headerTextConverted: {
    alias: 'headerTextConverted',
  },
  footerTextConverted: {
    alias: 'footerTextConverted',
  },
  __typename: {
    alias: '__typename',
  },
};

export const orderEntityDefinition: EntitiyDefinition<Order> = {
  local: {},
  remote: {
    queryName: 'getBestellung',
    fragmentName: 'Bestellung',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
