import styles from './TextBuildingBlockPreview.module.scss';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';

import { PreviewTitle, PreviewWrapper } from '../components';

export type ITextBuildingBlockPreviewProps = {
  textBuildingBlock: TextBuildingBlock;
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onVisibilityToggle?: (visible: boolean) => void;
};

export function TextBuildingBlockPreview(
  props: ITextBuildingBlockPreviewProps
) {
  const { textBuildingBlock, onCloseClick, onEditClicked, onVisibilityToggle } =
    props;

  return (
    <PreviewWrapper>
      <div className={styles.wrap}>
        <PreviewTitle
          onCloseClick={onCloseClick}
          onEditClicked={onEditClicked}
          onVisibilityToggle={onVisibilityToggle}
        >
          {textBuildingBlock.name}
        </PreviewTitle>
        <pre className={styles.content}>{textBuildingBlock?.text}</pre>
      </div>
    </PreviewWrapper>
  );
}
