import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IProjectStatusPickerProps<TMultiple extends boolean> = IPickerProps<
  LookUp,
  TMultiple
>;

export function ProjectStatusPicker<TMultiple extends boolean>(
  props: IProjectStatusPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...PROJECT_STATUS_FIELDS, ...data };
  }, [data]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.lookUp,
      data: allFields,
      completeDataResponse: true,
      vars: {
        type: LookupType.PROJEKT_STATI,
      },
    };
  }, [allFields]);

  const response = useDataProvider(requestData);

  const dataset = useMemo(() => {
    const projectStatuses = [...response.data];
    return {
      total: projectStatuses.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: projectStatuses,
    };
  }, [response.data]);

  const additionalItems = [{ id: 0, name: t('COMMON.PROJECT_EMPTY_STATUS') }];

  return (
    <ListEntityPicker
      entity={Entities.lookUp}
      data={allFields}
      fixedDataSet={dataset}
      filterBy="name"
      sortBy="name"
      additionalItems={additionalItems}
      {...rest}
      renderItemContent={(projectStatus) => (
        <Tooltip title={projectStatus.name}>
          <Typography variant="body2" noWrap>
            {projectStatus.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const PROJECT_STATUS_FIELDS: LookUp = {
  id: null,
  name: null,
};
