import React from 'react';
import { TableInstance } from 'react-table';

import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';

import { EntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/travelExpenses-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

//TODO: this table is in progress  - there is another ticket better not touch now I will create a ticket for it
export const TravelExpensesTable = React.forwardRef<TableInstance, Props>(
  function TravelExpensesTable(props, ref) {
    const dataTable = useEntityDataTable<TravelExpenses, never>({
      schema: schema as never,
      defaultSort,
    });
    return <EntityTable ref={ref} {...dataTable} />;
  }
);
