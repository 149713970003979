import { styled } from '@mui/material/styles';
import { useEventCallback } from '@mui/material/utils';
import { noop, omit, pick } from 'lodash';
import { useRef, useState } from 'react';

import { ReactComponent as CornerArrowIcon } from '@work4all/assets/icons/corner-arrow.svg';

type ResizeHandleId<T extends ResizeDirection = 'horizontal'> =
  T extends 'horizontal' ? 'left' | 'right' : 'top';
type ResizeDirection = 'horizontal' | 'vertical';

export type Size<T extends ResizeDirection = 'horizontal'> =
  T extends 'horizontal'
    ? {
        width: number;
      }
    : T extends 'vertical'
    ? {
        height: number;
      }
    : never;

type ResizableAreaOwnProps =
  | {
      direction?: 'horizontal' | undefined;
      size: Size;
      onResize?: (size: Size) => void;
      minWidth?: number | string;
      maxWidth?: number | string;
      handles: ResizeHandleId | ResizeHandleId[];
      hasIcon?: boolean;
    }
  | {
      direction: 'vertical';
      size: Size<'vertical'>;
      onResize?: (size: Size<'vertical'>) => void;
      minHeight?: number | string;
      maxHeight?: number | string;
      handles: ResizeHandleId<'vertical'> | ResizeHandleId<'vertical'>[];
      hasIcon?: boolean;
    };

export type ResizableAreaProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof ResizableAreaOwnProps
> &
  ResizableAreaOwnProps;

export function ResizableArea(props: ResizableAreaProps) {
  const {
    size: sizeProp,
    handles: handlesProp,
    style,
    onResize: onResizeProp = noop,
    children,
    direction,
    hasIcon,
    ...others
  } = props;

  const onResize = useEventCallback(onResizeProp);

  const handlesArray = Array.isArray(handlesProp) ? handlesProp : [handlesProp];
  const handles = new Set(handlesArray);

  const [isResizing, setIsResizing] = useState(false);
  const [size, setSize] = useState<typeof sizeProp>();

  const rootRef = useRef<HTMLDivElement>(null);

  const onResizeStart =
    (edge: 'left' | 'right' | 'top') =>
    (event: React.MouseEvent | React.TouchEvent) => {
      if (isResizing) {
        return;
      }

      setIsResizing(true);

      const sizeKey = direction === 'vertical' ? 'height' : 'width';
      const maxSizeKey = direction === 'vertical' ? 'maxHeight' : 'maxWidth';
      const minSizeKey = direction === 'vertical' ? 'minHeight' : 'minWidth';
      const positionKey = direction === 'vertical' ? 'clientY' : 'clientX';
      const rootStyle = getComputedStyle(rootRef.current);
      const sizeValue = parseFloat(rootStyle[sizeKey]);

      setSize((prev) => ({ ...prev, [sizeKey]: sizeValue }));

      const isTouch = event.type === 'touchstart';

      const start = isTouch
        ? (event as React.TouchEvent).touches[0][positionKey]
        : (event as React.MouseEvent)[positionKey];

      const handleMove = ({ position }: { position: number }) => {
        const rootStyle = getComputedStyle(rootRef.current);
        const minSize = tryParsePx(rootStyle[minSizeKey]) ?? 0;
        const maxSize = tryParsePx(rootStyle[maxSizeKey]) ?? Infinity;

        const delta =
          (edge === 'left' || edge === 'top' ? -1 : 1) * (position - start);

        const newSize = Math.max(minSize, Math.min(maxSize, sizeValue + delta));

        setSize((prev) => ({ ...prev, [sizeKey]: newSize }));
      };

      const handleUp = () => {
        const rootStyle = getComputedStyle(rootRef.current);
        const sizeValue = parseFloat(rootStyle[sizeKey]);
        onResize({ [sizeKey]: sizeValue });
        setIsResizing(false);
      };

      const onMouseMove = (e: MouseEvent | PointerEvent) => {
        handleMove({ position: e[positionKey] });
      };

      const onTouchMove = (e: TouchEvent) => {
        handleMove({ position: e.touches[0][positionKey] });
      };

      const onMouseUp = () => {
        handleUp();

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      const onTouchEnd = () => {
        handleUp();

        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', onTouchEnd);
      };

      if (isTouch) {
        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);
      } else {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      }
    };

  return (
    <ResizableAreaRoot
      ref={rootRef}
      style={{
        ...style,
        ...pick(others, 'maxWidth', 'minWidth', 'maxHeight', 'minHeight'),
        ...sizeProp,
        ...(isResizing && size),
      }}
      {...omit(others, 'maxWidth', 'minWidth', 'maxHeight', 'minHeight')}
    >
      {children}

      {isResizing && <ResizeAreaBackdrop direction={direction} />}

      <ResizableAreaHandles>
        {handles.has('top') && (
          <ResizableAreaEdgeHandle
            edge="top"
            onMouseDown={onResizeStart('top')}
            onTouchStart={onResizeStart('top')}
          />
        )}

        {handles.has('left') &&
          (hasIcon ? (
            <ResizableAreaEdgeIcon
              edge="left"
              onMouseDown={onResizeStart('left')}
              onTouchStart={onResizeStart('left')}
            >
              <CornerArrowIcon />
            </ResizableAreaEdgeIcon>
          ) : (
            <ResizableAreaEdgeHandle
              edge="left"
              onMouseDown={onResizeStart('left')}
              onTouchStart={onResizeStart('left')}
            />
          ))}

        {handles.has('right') &&
          (hasIcon ? (
            <ResizableAreaEdgeIcon
              edge="right"
              onMouseDown={onResizeStart('right')}
              onTouchStart={onResizeStart('right')}
            >
              <CornerArrowIcon />
            </ResizableAreaEdgeIcon>
          ) : (
            <ResizableAreaEdgeHandle
              edge="right"
              onMouseDown={onResizeStart('right')}
              onTouchStart={onResizeStart('right')}
            />
          ))}
      </ResizableAreaHandles>
    </ResizableAreaRoot>
  );
}

const ResizableAreaRoot = styled('div', {
  name: 'ResizableArea',
  slot: 'Root',
})({
  position: 'relative',
});

const ResizableAreaHandles = styled('div', {
  name: 'ResizableArea',
  slot: 'Handles',
})({
  width: 0,
  height: 0,
});

interface ResizableAreaEdgeHandleProps {
  edge: 'left' | 'right' | 'top';
}

const ResizableAreaEdgeHandle = styled('div', {
  name: 'ResizableArea',
  slot: 'EdgeHandle',
  shouldForwardProp: (prop) => prop !== 'edge',
})<ResizableAreaEdgeHandleProps>(({ theme, edge }) => ({
  position: 'absolute',
  // opacity: 0,
  userSelect: 'none',
  backgroundClip: 'content-box',
  transition: 'opacity 0.2s, background-color 0.2s',
  backgroundColor: theme.palette.ui4.main,
  touchAction: 'none',
  zIndex: 1,

  ':before, :after': {
    content: '""',
    height: edge !== 'top' ? '1rem' : '1px',
    width: edge !== 'top' ? '1px' : '1rem',
    background: 'var(--ui05)',
    display: 'block',
    position: 'absolute',
    zIndex: 10,
    top: edge !== 'top' ? '50%' : '6px',
    left: edge !== 'top' ? '1px' : '50%',
    marginTop: edge === 'top' ? undefined : '-0.5rem',
    marginLeft: edge === 'top' ? '-0.5rem' : undefined,
  },

  ':after': {
    top: edge !== 'top' ? '50%' : '1px',
    left: edge !== 'top' ? '6px' : '50%',
  },

  '&:hover': {
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
  },

  '&:active': {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    filter: `drop-shadow(0 0 5px ${theme.palette.primary.main})`,
  },

  ...(edge === 'top' && {
    top: -4,
    left: 0,
    width: '100%',
    height: 8,
    paddingTop: 3,
    paddingBottom: 3,
    cursor: 'ns-resize',
  }),
  ...(edge === 'left' && {
    left: -5,
    top: 0,
    height: '100%',
    width: 8,
    paddingLeft: 3,
    paddingRight: 3,
    cursor: 'ew-resize',
  }),
  ...(edge === 'right' && {
    right: -4,
    top: 0,
    height: '100%',
    width: 8,
    paddingLeft: 3,
    paddingRight: 3,
    cursor: 'ew-resize',
  }),
}));

const ResizableAreaEdgeIcon = styled('div', {
  name: 'ResizableArea',
  slot: 'EdgeIcon',
  shouldForwardProp: (prop) => prop !== 'edge',
})<ResizableAreaEdgeHandleProps>(({ edge }) => ({
  position: 'absolute',
  bottom: 0,
  padding: '2px 3px 0',

  ...(edge === 'left' && {
    left: 0,
    transform: 'scaleX(-1)',
    cursor: 'ew-resize',
  }),
  ...(edge === 'right' && {
    right: 0,
    cursor: 'ew-resize',
  }),
}));

interface ResizeAreaBackdropProps {
  direction: ResizeDirection;
}

const ResizeAreaBackdrop = styled('div', {
  name: 'ResizableArea',
  slot: 'Backdrop',
})<ResizeAreaBackdropProps>(({ direction }) => ({
  position: 'fixed',
  inset: 0,
  zIndex: 9999,
  userSelect: 'none',
  cursor: direction === 'vertical' ? 'ns-resize' : 'ew-resize',
}));

function tryParsePx(value: string): number | null {
  if (value.endsWith('px')) {
    return parseFloat(value);
  }

  return null;
}
