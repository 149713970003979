import { useApolloClient } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';

import { useNavigate } from './routing';
import { useTenant } from './routing/TenantProvider';

export function useChangeTenant() {
  const apolloClient = useApolloClient();
  const { setActiveTenant } = useTenant();
  const navigate = useNavigate();

  const changeTenant = useEventCallback(async (tenantId: number) => {
    await apolloClient.clearStore();

    setActiveTenant(tenantId);
    navigate('/home', { tenant: tenantId });
  });

  return changeTenant;
}
