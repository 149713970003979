import { ApolloError, gql, useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ObjectTypeByEntity, ObjectTypesUnion } from '@work4all/models';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { useApolloExtrasContext } from './lib/apollo-extras/apollo-extras-context';
import { evictEntityCache } from './lib/apollo-extras/evictEntityCache';
import { useEntityEventsContext } from './lib/entity-events/entity-events-context';

const DELETE_ENTITY = gql`
  mutation DeleteEntity($objectType: ObjectType!, $keys: [String]!) {
    deleteEntity(objectType: $objectType, keys: $keys) {
      deletedRecords
      errorMessages {
        primaryKey
        message
        type
      }
    }
  }
`;

type IDeleteEntityPrivateVars = {
  objectType: ObjectTypesUnion;
  keys: string[];
};

type IDeleteEntityVars = {
  type: Entities;
  fallbackType?: ObjectTypesUnion;
  readonly ids: (string | number)[];
};

type IDeleteEntityResponse = {
  deleteEntity: {
    deletedRecords: number;
  };
};

type IDeleteEntityOptions = {
  onCompleted?: (data: IDeleteEntityResponse) => void;
  onError?: (error: ApolloError) => void;
};

export function useDeleteEntity(resetStore = true) {
  const client = useApolloClient();
  const { emit: emitEntityEvent } = useEntityEventsContext();
  const { isAutoInvalidationDisabled } = useApolloExtrasContext();
  const isAutoInvalidationDisabledRef = useLatest(isAutoInvalidationDisabled);

  const [mutate, result] = useMutation<
    IDeleteEntityResponse,
    IDeleteEntityPrivateVars
  >(DELETE_ENTITY);

  const deleteEntity = useCallback(
    (variables: IDeleteEntityVars, options?: IDeleteEntityOptions) => {
      return mutate({
        ...options,
        variables: {
          objectType:
            ObjectTypeByEntity[variables.type] || variables.fallbackType,
          keys: variables.ids.map((id) =>
            typeof id === 'number' ? String(id) : id
          ),
        },
        onCompleted(data) {
          emitEntityEvent({
            type: 'delete',
            entity: variables.type,
            ids: variables.ids,
          });

          evictEntityCache(
            client,
            variables.type,
            resetStore && !isAutoInvalidationDisabledRef?.current
          );

          options?.onCompleted?.(data);
        },
      });
    },
    [client, emitEntityEvent, isAutoInvalidationDisabledRef, mutate, resetStore]
  );

  return [deleteEntity, result] as const;
}
