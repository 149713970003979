import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { ReactComponent as PersonIcon } from '@work4all/assets/icons/drag_person.svg';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  canAddCustomer,
  canAddSupplier,
} from '@work4all/utils/lib/permissions';

import { useDragLayer } from '../../DragLayerProvider';

import { ImprintOverlayView } from './ImprintOverlayView';
import { UnauhtorizedOverlayView } from './UnauhtorizedOverlayView';

type ItemType = { html: string };
const getString = (item: ItemType) => sanitizeHtml(item.html);

export const useImprintDropActions = () => {
  const { show } = useDragLayer();
  const { t } = useTranslation();

  const user = useUser();

  const action = useCallback(
    (entity: Entities, defaultValue: ItemType, permission: boolean) => {
      if (!permission) {
        show(<UnauhtorizedOverlayView entity={entity} />);
        return;
      }

      const text = getString(defaultValue).normalize();
      show(<ImprintOverlayView entity={entity} text={text} />);
    },
    [show]
  );

  return useMemo(() => {
    const customerPermission = canAddCustomer(user);
    const supplierPermission = canAddSupplier(user);
    const imprintItems = [
      {
        text: t('DRAG.CUSTOMER_CREATE'),
        action: (value: ItemType) =>
          action(Entities.customer, value, customerPermission),
        icon: <PersonIcon />,
        hasPermission: customerPermission,
      },
      {
        text: t('DRAG.SUPPLIER_CREATE'),
        action: (value: ItemType) =>
          action(Entities.supplier, value, supplierPermission),
        icon: <PersonIcon />,
        hasPermission: supplierPermission,
      },
    ];
    return imprintItems;
  }, [action, user, t]);
};
