import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputProjectStepLink } from './InputProjectStepLink.entity';

export class InputProjectStepLinkRelation<T extends EMode = EMode.entity> {
  /** Alias: remove */
  remove?: number[];
  /** Alias: add */
  add?: InputProjectStepLink<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectStepLinkRelation> = {
  remove: {
    alias: 'remove',
  },
  add: {
    alias: 'add',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectStepLinkRelationEntityDefinition: EntitiyDefinition<InputProjectStepLinkRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectStepLinkRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
