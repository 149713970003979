import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputArticleTextModifyRelation<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: longText */
  longText?: any;
  /** Alias: shortText */
  shortText?: any;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleTextModifyRelation> = {
  code: {
    alias: 'code',
  },
  longText: {
    alias: 'longText',
  },
  shortText: {
    alias: 'shortText',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleTextModifyRelationEntityDefinition: EntitiyDefinition<InputArticleTextModifyRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleTextModifyRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
