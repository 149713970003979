import PaymentIcon from '@mui/icons-material/Payment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PaymentMask } from '../containers/mask-overlays/mask-overlay/views/payment';

export const usePaymentMask = (
  entity: Entities.reViewModel | Entities.raViewModel,
  onClose?: () => void
) => {
  const { t } = useTranslation();
  const { open, close } = useDialogs();
  const action = useMemo<CustomToolbarAction>(() => {
    return {
      actionKey: 'openPaymentMask',
      IconComponent: PaymentIcon,
      title: t('INPUTS.OPEN_PAYMENT_MASK'),
      mode: 'Edit',
      handler: (item: string | number) => {
        const { id } = open(
          ({ open }) => (
            <NavigationOverlay
              open={open}
              initialView={{
                view: <PaymentMask id={item} entity={entity} />,
              }}
              close={() => {
                close(id);
                onClose?.();
              }}
            />
          ),
          {}
        );
      },
    };
  }, [close, entity, open, t, onClose]);

  return action;
};
