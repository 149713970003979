import styles from './SuggestionPickerField.module.scss';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { useObservableState } from 'observable-hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { useDataProvider } from '@work4all/data';

import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';

import { Autocomplete } from '../../containers/mask-overlays/locked-inputs';

interface SuggestionPickerFieldProps {
  error?: string;
  label?: string;
  onChange: (e) => void;
  value: string;
  field: string;
  entity: Entities;
}

const CssTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    paddingTop: '1rem',
    paddingBottom: '0.25rem !important',
    borderRadius: '0.25rem',
    paddingLeft: '0.25rem',
    backgroundColor: 'var(--ui02)',
    border: '1px solid var(--ui04)',
  },
  '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
    display: 'none',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(7px, 11px) scale(1)',
  },
  '& .Mui-focused.MuiInputLabel-root, .MuiFormLabel-filled.MuiInputLabel-root':
    {
      transform: 'translate(7px, 4px) scale(0.75)',
      fontSize: '0.85rem',
      fontWeight: '500',
    },
});

export const SuggestionPickerField = React.forwardRef<
  HTMLDivElement,
  SuggestionPickerFieldProps
>(function SuggestionPickerField(props, ref) {
  const { label, onChange, ...pickerProps } = props;
  const { value, error } = pickerProps;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { field, entity } = props;

  const [searchTerm, setSearchTerm] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const [defaultValue, setDefaultValue] = useState('');

  const requestData = useMemo<DataRequest>(() => {
    return {
      filter: [
        { [field]: { $eq: searchTerm + '%' } },
        { [field]: { $ne: '' } },
        { [field]: { $ne: ' ' } },
      ],
      entity: entity,
      data: [field],
      sort: [{ field, direction: SortDirection.ASCENDING }],
    };
  }, [entity, field, searchTerm]);

  const response = useDataProvider(
    requestData,
    !searchTerm || searchTerm?.trim().length === 0
  );

  const patchedData = useMemo(() => {
    const result: string[] = [...new Set(response.data.map((x) => x[field]))];
    return result;
  }, [field, response.data]);

  useEffect(() => {
    setDefaultValue(value);
  }, [setDefaultValue, value]);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <Autocomplete
        freeSolo
        ListboxProps={{ style: { maxHeight: '10rem' } }}
        getOptionLabel={(option) => option}
        options={patchedData}
        onInputChange={(e, value) => {
          setSearchTerm(value ?? '');
          onChange(value);
        }}
        value={defaultValue}
        renderInput={(params) => (
          <div
            className={clsx(styles.wrapper, {
              [styles.error]: error !== undefined,
            })}
          >
            <CssTextField
              {...params}
              label={label}
              variant="filled"
              size="small"
            />
            {error !== undefined && (
              <div className={styles.errorMessage}>
                <Caption color="error">{error}</Caption>
              </div>
            )}
          </div>
        )}
        onChange={(e, value) => {
          onChange(value ?? '');
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} style={{ textAlign: 'left' }}>
              <div>{option}</div>
            </li>
          );
        }}
      />
    </div>
  );
});
