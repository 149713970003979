import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputArticleTextAddRelation } from './InputArticleTextAddRelation.entity';
import { InputArticleTextModifyRelation } from './InputArticleTextModifyRelation.entity';

export class InputArticleTextRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: InputArticleTextAddRelation<T>[];
  /** Alias: remove */
  remove?: number[];
  /** Alias: modify */
  modify?: InputArticleTextModifyRelation<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleTextRelation> = {
  add: {
    alias: 'add',
  },
  remove: {
    alias: 'remove',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleTextRelationEntityDefinition: EntitiyDefinition<InputArticleTextRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleTextRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
