import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ConvertInvoiceToRaStatuses } from '@work4all/models/lib/Enums/ConvertInvoiceToRaStatuses.enum';

import { ConvertInvoiceToRaAlertFunction } from '../types';

export const useConvertInvoiceToRaAlert = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const alert: ConvertInvoiceToRaAlertFunction = (type) => {
    const variant =
      type === ConvertInvoiceToRaStatuses.SUCCESS ? 'success' : 'error';

    enqueueSnackbar(t(`ALERTS.CONVERT_INVOICE_TO_RA_STATUSES.${type}`), {
      variant,
      autoHideDuration: 6000,
    });
  };

  return alert;
};
