import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { NavigationOverlayHeader } from '../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { NoAdminRightsBanderole } from '../../../../components/banderoles/NoAdminRightsBanderole';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../mask-tabs';
import { MaskContent } from '../../../components/MaskContent/MaskContent';
import {
  MaskContextProvider,
  useMaskConfig,
} from '../../../hooks/mask-context';

import { ExpiryDateSection } from './components/expiry-date-section/ExpiryDateSection';
import { PreDocumentsSection } from './components/pre-documents-section/PreDocumentsSection';

type InboundInvoiceSettingsProps = {
  amplitudeEntryPoint: string;
};

export function InboundInvoiceSettings(props: InboundInvoiceSettingsProps) {
  const { amplitudeEntryPoint } = props;

  const { t } = useTranslation();
  const user = useUser();

  const mask = useMaskConfig({ entity: Entities.reViewModel });

  useEffect(() => {
    sendAmplitudeData(EventType.OpenInboundInvoiceSettings, {
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint]);

  return (
    <MaskContextProvider value={mask}>
      <MaskTabContext defaultValue="personal">
        <NavigationOverlayHeader
          title={t('SETTINGS.SETTINGS')}
          subTitle={t('COMMON.INBOUNDINVOICE')}
          breadcrumbsChildren={<div></div>}
          tabs={<SettingsTabs />}
          hideBreadcrumbs={true}
        />
        {!user.isMaster && <NoAdminRightsBanderole />}
        <Content />
      </MaskTabContext>
    </MaskContextProvider>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('COMMON.PERSONAL')}></MaskTab>
      <MaskTab value="general" label={t('COMMON.GENERAL')} />
    </MaskTabs>
  );
});

const Content = memo(function TabPanels() {
  return (
    <MaskContent>
      <MaskTabPanel value="personal">
        <PreDocumentsSection />
      </MaskTabPanel>
      <MaskTabPanel value="general">
        <ExpiryDateSection />
      </MaskTabPanel>
    </MaskContent>
  );
});
