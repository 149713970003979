import { createContext, useContext } from 'react';

interface TableVariantState {
  variant?: string | null;
}

const Context = createContext<TableVariantState | null>({
  variant: null,
});

export const TableVariantProvider = Context.Provider;

export const useTableVariant = () => useContext(Context);
