import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputAnsprechpartner<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: abteilungCode */
  abteilungCode?: number;
  /** Alias: abteilungInAdresseZeigen */
  abteilungInAdresseZeigen?: boolean;
  /** Alias: adressErweiterung */
  adressErweiterung?: string;
  /** Alias: anredeCode */
  anredeCode?: number;
  /** Alias: briefanrede */
  briefanrede?: string;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: eMailPrivat */
  eMailPrivat?: string;
  /** Alias: entlassen */
  entlassen?: boolean;
  /** Alias: faxPrivat */
  faxPrivat?: string;
  /** Alias: funktion */
  funktion?: string;
  /** Alias: funktionInAdresseZeigen */
  funktionInAdresseZeigen?: boolean;
  /** Alias: geburtsdatum */
  geburtsdatum?: string;
  /** Alias: geschaeftspartnerCode */
  geschaeftspartnerCode?: number;
  /** Alias: letzteAenderung */
  letzteAenderung?: string;
  /** Alias: mailanPrivat */
  mailanPrivat?: number;
  /** Alias: mailsperre */
  mailsperre?: boolean;
  /** Alias: mobilfunk */
  mobilfunk?: string;
  /** Alias: mobilPrivat */
  mobilPrivat?: string;
  /** Alias: name */
  name?: string;
  /** Alias: namenserweiterung */
  namenserweiterung?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: notizRTF */
  notizRTF?: string;
  /** Alias: ort */
  ort?: string;
  /** Alias: plz */
  plz?: string;
  /** Alias: primaereAdresse */
  primaereAdresse?: number;
  /** Alias: serienbriefsperre */
  serienbriefsperre?: boolean;
  /** Alias: skypename */
  skypename?: string;
  /** Alias: staat */
  staat?: string;
  /** Alias: strasse */
  strasse?: string;
  /** Alias: telefax */
  telefax?: string;
  /** Alias: telefon */
  telefon?: string;
  /** Alias: telefon2 */
  telefon2?: string;
  /** Alias: telefon3 */
  telefon3?: string;
  /** Alias: telefonPrivat */
  telefonPrivat?: string;
  /** Alias: titelerweiterung */
  titelerweiterung?: string;
  /** Alias: vorname */
  vorname?: string;
  /** Alias: hauptansprechpartner */
  hauptansprechpartner?: boolean;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: sdObjType */
  sdObjType?: SdObjType;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: geburtstagTag */
  birthdayDay?: number;
  /** Alias: geburtstagMonat */
  birthdayMonth?: number;
  /** Alias: geburtstagJahr */
  birthdayYear?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputAnsprechpartner> = {
  code: {
    alias: 'code',
  },
  abteilungCode: {
    alias: 'abteilungCode',
  },
  abteilungInAdresseZeigen: {
    alias: 'abteilungInAdresseZeigen',
  },
  adressErweiterung: {
    alias: 'adressErweiterung',
  },
  anredeCode: {
    alias: 'anredeCode',
  },
  briefanrede: {
    alias: 'briefanrede',
  },
  eMail: {
    alias: 'eMail',
  },
  eMailPrivat: {
    alias: 'eMailPrivat',
  },
  entlassen: {
    alias: 'entlassen',
  },
  faxPrivat: {
    alias: 'faxPrivat',
  },
  funktion: {
    alias: 'funktion',
  },
  funktionInAdresseZeigen: {
    alias: 'funktionInAdresseZeigen',
  },
  geburtsdatum: {
    alias: 'geburtsdatum',
  },
  geschaeftspartnerCode: {
    alias: 'geschaeftspartnerCode',
  },
  letzteAenderung: {
    alias: 'letzteAenderung',
  },
  mailanPrivat: {
    alias: 'mailanPrivat',
  },
  mailsperre: {
    alias: 'mailsperre',
  },
  mobilfunk: {
    alias: 'mobilfunk',
  },
  mobilPrivat: {
    alias: 'mobilPrivat',
  },
  name: {
    alias: 'name',
  },
  namenserweiterung: {
    alias: 'namenserweiterung',
  },
  notiz: {
    alias: 'notiz',
  },
  notizRTF: {
    alias: 'notizRTF',
  },
  ort: {
    alias: 'ort',
  },
  plz: {
    alias: 'plz',
  },
  primaereAdresse: {
    alias: 'primaereAdresse',
  },
  serienbriefsperre: {
    alias: 'serienbriefsperre',
  },
  skypename: {
    alias: 'skypename',
  },
  staat: {
    alias: 'staat',
  },
  strasse: {
    alias: 'strasse',
  },
  telefax: {
    alias: 'telefax',
  },
  telefon: {
    alias: 'telefon',
  },
  telefon2: {
    alias: 'telefon2',
  },
  telefon3: {
    alias: 'telefon3',
  },
  telefonPrivat: {
    alias: 'telefonPrivat',
  },
  titelerweiterung: {
    alias: 'titelerweiterung',
  },
  vorname: {
    alias: 'vorname',
  },
  hauptansprechpartner: {
    alias: 'hauptansprechpartner',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  sdObjType: {
    alias: 'sdObjType',
  },
  customFields: {
    alias: 'customFields',
  },
  birthdayDay: {
    alias: 'geburtstagTag',
  },
  birthdayMonth: {
    alias: 'geburtstagMonat',
  },
  birthdayYear: {
    alias: 'geburtstagJahr',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAnsprechpartnerEntityDefinition: EntitiyDefinition<InputAnsprechpartner> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAnsprechpartner',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
