import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const typeNameToEntity = (typeName: string, throwError = false) => {
  switch (typeName) {
    case 'Angebot':
      return Entities.offer;
    case 'Auftrag':
      return Entities.contract;
    case 'Rechnung':
      return Entities.invoice;
    case 'Lieferschein':
      return Entities.deliveryNote;
    case 'Eingangslieferschein':
      return Entities.inboundDeliveryNote;
    case 'Bedarfsanforderung':
      return Entities.demand;
    case 'Bestellung':
      return Entities.order;
    case 'Kalkulation':
      return Entities.calculation;
    default:
      console.warn(`There is unkown type ${typeName}`);
  }

  if (throwError) throw new Error(`Type name ${typeName} is not defined`);
  return null;
};
