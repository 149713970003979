import styles from './VendorAndCurrency.module.scss';

import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { CurrencyPicker } from '@work4all/components/lib/components/entity-picker/CurrencyPicker';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatCurrency } from '@work4all/utils';

import { BankDetailsPickerField } from '../../../../../../../../../../components/entity-picker/bank-details-picker-field/BankDetailsPickerField';
import { SupplierPickerField } from '../../../../../../../../../../components/entity-picker/SupplierPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  LabeledInput,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../../../../../components';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { CurrencyExchangeInfoContext } from '../../../../../currency-exchange-info-context';
import { ReViewModelFormValue } from '../../../../../types';

export function VendorAndCurrency() {
  const { t } = useTranslation();

  const mask = useMaskContext();

  const form = useFormContextPlus<ReViewModelFormValue>();
  const { watch, control } = form;

  const currencyWrapperRef = useRef<HTMLDivElement>(null);

  const currency = watch('currency');
  const supplier = watch('supplier');

  const exchangeInfoContext = useContext(CurrencyExchangeInfoContext);

  return (
    <Collapse
      className={styles.vendorAndCurrency}
      title={t('MASK.VENDOR_AND_CURRENCY')}
      defaultOpen
    >
      <ControlWrapper columns={2}>
        <ControlWrapper paddingBottom={false} gridColumn="1 / 3">
          <ControllerPlus
            control={control}
            name="supplier"
            render={({ field }) => {
              return (
                <SupplierPickerField
                  label={t('COMMON.CREDITOR')}
                  placeholder={t('COMMON.PICK_FROM', {
                    from: t('COMMON.CREDITOR'),
                  })}
                  {...field}
                  clearable={false}
                  required
                />
              );
            }}
          />
        </ControlWrapper>
        <ControllerPlus
          control={control}
          name="bankAccount"
          render={({ field }) => {
            const { value, onChange } = field;
            const disabled = !supplier?.id;
            return (
              <BankDetailsPickerField
                disabled={disabled}
                value={value}
                onChange={onChange}
                entity={Entities.supplier}
                entityId={supplier?.id}
              />
            );
          }}
        />

        <ControlWrapper ref={currencyWrapperRef} paddingBottom={false}>
          <MultiStepControls>
            {renderCurrencyInput()}
            {maybeRenderCurrencyExchangeRateInput()}
          </MultiStepControls>
        </ControlWrapper>
      </ControlWrapper>
    </Collapse>
  );

  function renderCurrencyInput() {
    function formatCurrencyConversionDisplay(currency: Currency) {
      const defaultCurrency = exchangeInfoContext?.defaultCurrency;

      if (!currency) {
        return '';
      }

      if (!defaultCurrency || currency.id === defaultCurrency.id) {
        return formatCurrency(currency, t);
      }

      return t('CURRENCY.CONVERSION', {
        replace: {
          from: formatCurrency(currency, t),
          to: formatCurrency(defaultCurrency, t),
        },
      });
    }

    return (
      <Step active index={0}>
        <ControllerPlus
          control={control}
          name="currency"
          render={({ field }) => {
            const { value, onChange } = field;

            return (
              <EntityPickerPopover
                anchorEl={currencyWrapperRef}
                picker={
                  <CurrencyPicker
                    multiple={false}
                    value={value}
                    onChange={onChange}
                    clearable={false}
                  />
                }
              >
                <PickerTargetButton
                  label={t('INPUTS.CURRENCY')}
                  value={formatCurrencyConversionDisplay(value)}
                  disabled={mask.isEditMode}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </Step>
    );
  }

  function maybeRenderCurrencyExchangeRateInput() {
    if (
      !currency ||
      exchangeInfoContext === null ||
      currency.id === exchangeInfoContext.defaultCurrency.id
    ) {
      return null;
    }

    const { defaultCurrency, getFormattedRate } = exchangeInfoContext;

    const formattedRate = getFormattedRate(currency.id);

    const from = formatCurrency(currency, t);
    const to = formatCurrency(defaultCurrency, t);

    return (
      <Step active index={1}>
        <LabeledInput
          label={t('INPUTS.EXCHANGE_RATE')}
          value={
            <span className={styles.exchangeDisplay}>
              {formattedRate}
              <span>
                {from} ={' '}
                {(1).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                {to}
              </span>
            </span>
          }
        />
      </Step>
    );
  }
}
