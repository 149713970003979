import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { EntityGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';

import { Category } from '@work4all/models/lib/Classes/Category.entity';
import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PersonalAndGeneralView } from '../../../filters/PersonalAndGeneralView';
import { ProjectCategoryPicker } from '../../../filters/ProjectCategoryPicker';
import {
  TableQuickFilters,
  TableQuickFiltersSection,
  TableQuickFiltersSectionHeader,
} from '../../table-quick-filters';
import { createHandleColumnFilterChange } from '../../table-quick-filters/utils';

export function ProjectTableQuickFilters() {
  const { t } = useTranslation();

  const tableStateBag = useTableStateBag();

  const columns = {
    group: tableStateBag.columnsById['groupId'],
    categories: tableStateBag.columnsById['categoryAssignmentList.categoryId'],
    closed: tableStateBag.columnsById['projectStatus.closedStatus'],
  };

  const groups = columns.group?.filterValue?.value ?? null;
  const handleGroupsChange = createHandleColumnFilterChange<Group>(
    columns.group
  );

  const categories = columns.categories?.filterValue?.value ?? null;

  const handleCategoriesChange = createHandleColumnFilterChange<Category>(
    columns.categories
  );

  return (
    <TableQuickFilters>
      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.GROUPS')}
        </TableQuickFiltersSectionHeader>
        <EntityGroupPicker
          entity={Entities.projectGroup}
          value={groups}
          onChange={handleGroupsChange}
        />
      </TableQuickFiltersSection>

      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.CATEGORIES')}
        </TableQuickFiltersSectionHeader>
        <ProjectCategoryPicker
          value={categories}
          onChange={handleCategoriesChange}
        />
      </TableQuickFiltersSection>

      <PersonalAndGeneralView
        value={groups ?? categories}
        entity={Entities.project}
      />
    </TableQuickFilters>
  );
}
