import styles from './Document.module.scss';

import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard } from '@work4all/components/lib/components/entity-preview/components';
import { LabeledDateInput } from '@work4all/components/lib/input/labeled-date-input';

import { useDataProvider } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Tenant } from '@work4all/models/lib/Classes/Tenant.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { ProjectPickerField } from '../../../../../../../../../../components/entity-picker/ProjectPickerField';
import { UserPickerField } from '../../../../../../../../../../components/entity-picker/UserPickerField';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpDataIntersection } from '../../../../../ErpData';
import { EditWrap } from '../../../../edit-wrap/EditWrap';
import { Signature } from '../../../../signature/Signature';
import { CompanyLogoFileController } from '../../../../simple-pdf-report/components/company-logo-file-controller/CompanyLogoFileController';
import { PositionsWithAutoDisable } from '../../../positions/components/Positions';
import { ErpTextEditor } from '../erp-text-editor/ErpTextEditor';
import { ParticipantsAndAddresses } from '../participants-and-addresses/ParticipantsAndAddresses';

const allowedColumns = [
  'number',
  'longtext',
  'amount',
  'unit',
  'singlePriceNet',
  'totalPriceNet',
];

export const Document: React.FC = () => {
  const { entity } = useMaskContext();
  const { watch, setValue } = useFormContextPlus<ErpDataIntersection>();

  const headText = watch('rtfHeadText');
  const footerText = watch('rtfFooterText');

  const date = watch(entity === Entities.contract ? 'contractDate' : 'date');
  const responsible = watch('user');
  const project = watch('project');
  const businessPartnerContactCombined = watch(
    'businessPartnerContactCombined'
  );

  const mainAddressAccessor =
    entity === Entities.deliveryNote
      ? 'additionalAddress1Text'
      : 'businessPartnerText';

  const businessPartnerText = watch(mainAddressAccessor);

  const number = watch(
    entity === Entities.contract ? 'contractNumber' : 'number'
  );

  const { activeTenant } = useTenant();

  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.tenant,
      data: {
        id: null,
        name: null,
        imageFileName: null,
        street: null,
        postalCode: null,
        city: null,
      } as Tenant<EMode.query>,
      filter: [{ id: { $eq: activeTenant } }],
      completeDataResponse: true,
    };
  }, [activeTenant]);

  const tenantResponse = useDataProvider<Tenant>(request);

  const currentTenantData = tenantResponse.data?.find(
    (x) => x.id === activeTenant
  );

  const addressLabel = useMemo(() => {
    if (!currentTenantData) {
      return '';
    }
    const { name, street, postalCode, city } = currentTenantData;
    return `${name} - ${street} - ${postalCode} ${city}`;
  }, [currentTenantData]);

  const [addressEditMode, setAddressEditMode] = useState(false);

  const { t } = useTranslation();

  const [dateEdit, setDateEdit] = useState(false);

  const handleDateChange = useCallback(
    (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(
        entity === Entities.contract ? 'contractDate' : 'date',
        DateTime.fromFormat(v.target.value, 'dd.MM.yyyy').toISO(),
        { shouldDirty: true, shouldValidate: true }
      );
    },
    [entity, setValue]
  );

  const padding = '0 3rem 0 6rem';
  return (
    <Box position="relative" display="flex" flex="1" width="100%">
      <Box
        position="relative"
        zIndex="100"
        display="flex"
        flexDirection="column"
        flex="1"
      >
        <Box textAlign="right" padding="6rem 3rem 6rem 6rem">
          <Box display="inline-block" style={{ width: '15rem' }}>
            <CompanyLogoFileController />
          </Box>
        </Box>
        <Box
          padding={padding}
          marginBottom="4rem"
          display="flex"
          gap="1rem"
          alignItems="start"
        >
          <Box flex={1}>
            <EditWrap
              onEditModeToggle={setAddressEditMode}
              editMode={addressEditMode}
            >
              {addressEditMode ? (
                <ParticipantsAndAddresses />
              ) : (
                <InfoCard label={addressLabel} disabled>
                  <Typography whiteSpace="pre-line">
                    {businessPartnerText}
                  </Typography>
                </InfoCard>
              )}
            </EditWrap>
          </Box>
        </Box>
        <Box
          padding={padding}
          marginBottom="2rem"
          display="flex"
          alignItems="center"
          gap="2rem"
        >
          <Typography flex="1" variant="h3" className={styles.bzObjTypeHeading}>
            {t('COMMON.' + entity.toUpperCase())} {number}
          </Typography>
        </Box>
        <Box
          padding={padding}
          marginBottom="1rem"
          display="flex"
          alignItems="top"
          gap="4rem"
        >
          <InfoCard
            label={t(
              businessPartnerContactCombined?.businessPartner
                ?.businessPartnerType === SdObjType.KUNDE
                ? 'INPUTS.CLIENT_NUMBER'
                : 'INPUTS.SUPPLIER_NUMBER'
            )}
            staticField
          >
            {businessPartnerContactCombined?.businessPartner?.['data']
              ?.number || '-'}
          </InfoCard>

          <UserPickerField
            fieldComp={
              <EditWrap onEditModeToggle={() => null} editMode={false}>
                <InfoCard label={t('COMMON.RESPONSIBLE')} staticField>
                  {responsible?.displayName}
                </InfoCard>
              </EditWrap>
            }
            clearable={false}
            value={responsible}
            onChange={(value) => {
              setValue('user', value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
          <Box flex={1}>
            <ProjectPickerField
              fieldComp={
                <EditWrap onEditModeToggle={() => null} editMode={false}>
                  <InfoCard label={t('COMMON.PROJECT')} staticField>
                    {project ? `${project?.number} | ${project?.name}` : '-'}
                  </InfoCard>
                </EditWrap>
              }
              value={project}
              onChange={(value) => {
                setValue('project', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </Box>

          <EditWrap onEditModeToggle={setDateEdit} editMode={dateEdit}>
            {dateEdit ? (
              <LabeledDateInput
                style={{ width: '10rem' }}
                label={t('COMMON.DATE')}
                value={DateTime.fromISO(date).toFormat('dd.MM.yyyy')}
                onBlur={handleDateChange}
              />
            ) : (
              <InfoCard label={t('COMMON.DATE')} staticField>
                {formatDateString(date, DateFormatPreset.DATE_SIMPLE_4YEAR)}
              </InfoCard>
            )}
          </EditWrap>
        </Box>

        <Box padding={padding} marginBottom="1rem">
          <ErpTextEditor
            onChange={(value: string) => {
              setValue('rtfHeadText', value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            text={headText}
          />
        </Box>

        <Box
          minHeight={`50rem`}
          height="100%"
          display="flex"
          overflow="auto"
          width="100%"
          maxWidth="min(65rem, 90vw)"
        >
          <PositionsWithAutoDisable
            showArticleSelectionBig={false}
            showPositionMask={false}
            showBorders={false}
            showSelectionColumn={false}
            singleLine={false}
            allowedColumns={allowedColumns}
          />
        </Box>
        <Box padding={padding} marginTop="1rem" paddingBottom="3rem">
          <ErpTextEditor
            onChange={(value: string) => {
              setValue('rtfFooterText', value, { shouldDirty: true });
            }}
            text={footerText}
          />
        </Box>
        {entity === Entities.deliveryNote ? (
          <Box
            padding={padding}
            paddingBottom={'4rem'}
            width="100%"
            display="flex"
          >
            <Box flex={1}></Box>
            <Signature showSignature />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
