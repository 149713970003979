import style from './ErpDialogPositions.module.scss';

import { Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import i18next from 'i18next';
import { noop } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableInstance } from 'react-table';

import {
  ColumnAdditionalData,
  CURRENCY_PARAMS,
  NUMBER_PARAMS,
  NumberCell,
} from '@work4all/components';
import {
  Collapse,
  ICollapseProps,
} from '@work4all/components/lib/components/collapse';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { EditableCell } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';

import { useDataProvider, useUser } from '@work4all/data';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { currencyAsSign, parseAsFloat } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import { canSeeSellingPrices } from '@work4all/utils/lib/permissions';

import { EditTable } from '../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/EditTable';
import { useEditableState } from '../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import {
  CellDefinition,
  EditTableEntry,
} from '../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/types';
import { recalculatePositions } from '../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/hooks/recalculatePositions';
import { RowSizePicker } from '../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/row-size-picker/RowSizePicker';
import { settings, useSetting } from '../../../../settings';
import { NOT_EDITABLE_ROWS } from '../consts';
import { ErpObject, GroupedPosition, SelectedPosition } from '../types';

type ErpDialogPosition = Position & EditTableEntry;
export interface OnItemsChangedOptions {
  forceEnabled: boolean;
}

const de = i18next.getFixedT('de');

export interface ErpDialogPositionsProps extends ICollapseProps {
  commonCollapseProps?: ICollapseProps;
  entry: ErpObject;
  sourceEntity: Entities;
  targetEntity: Entities;
  onItemsChanged: (
    positions: SelectedPosition[],
    options: OnItemsChangedOptions
  ) => void;

  showRowSize?: boolean;
  historicalItems?: boolean;
  maximizedMask?: boolean;
  autoOpen?: boolean;
}

const changeColor = (cell) => {
  return {
    style:
      cell.row.original.amount === 0
        ? {
            color: 'var(--text03)',
          }
        : undefined,
  };
};

const DisabledNumberCell = (cell) => (
  <NumberCell {...cell} {...changeColor(cell)} />
);
const DisabledCell = (cell) => <div {...changeColor(cell)}>{cell.value}</div>;
const Cell = (cell) => cell.value;

export const ErpDialogPositions = (props: ErpDialogPositionsProps) => {
  const {
    maximizedMask,
    historicalItems,
    sourceEntity,
    targetEntity,
    showRowSize,
    autoOpen,
    entry,
    ...collapseProps
  } = props;

  // special cases
  const orderToIncomingDeliveryNote =
    sourceEntity === Entities.order &&
    targetEntity === Entities.inboundDeliveryNote;

  const contractToDeliveryNote =
    orderToIncomingDeliveryNote ||
    (sourceEntity === Entities.contract &&
      targetEntity === Entities.deliveryNote);

  const firstTimeLoaded = useRef(false);

  const erpQuantitySuggestion = useSetting(
    settings.erpConvertQuantitySuggestion()
  );

  const { t } = useTranslation();
  const convertDialogPositionMaximized = useSetting(
    settings.convertDialogPositionMaximized()
  );
  const [maximized, setMaximized] = useState(
    autoOpen ? convertDialogPositionMaximized.value : false
  );

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: sourceEntity,
      data: {
        id: null,
        currency: {
          id: null,
          name: null,
        },
        positionList: [
          {
            id: null,
            index: null,
            number: null,
            longtext: null,
            positionKind: null,
            amount: null,
            backlog: null,
            unit: null,
            insteadOfTotalPrice: null,
            singlePriceNet: null,
            totalPriceNet: null,
            posId: null,
          },
        ],
      },
      filter: [{ id: { $eq: entry.id } }],
    };
  }, [entry, sourceEntity]);

  const { data, loading } = useDataProvider<ErpObject>(
    requestData,
    !maximized || firstTimeLoaded.current
  );

  const [positionList, setPositionList] = useState<Position[]>([]);
  const originalData = useRef<Position[]>();

  const mutateState = useCallback((input: Position[]) => {
    const { result } = recalculatePositions(input, false);
    return result;
  }, []);

  const { positions, onEditPosition } = useEditableState({
    positions: positionList,
    onAddPosition: noop,
    onEditPosition: noop,
    onMovePosition: noop,
    onRemovePosition: noop,
    mutateState,
  });

  const updatePositionList = useCallback(
    (positionList?: ErpDialogPosition[], quantitySuggestion?: boolean) => {
      const newPositionList = positionList
        .filter((x) =>
          orderToIncomingDeliveryNote
            ? x.positionKind !== ErpPositionsKind.STUECKLISTE
            : !x.posId
        )
        .filter(
          (x) =>
            historicalItems ||
            (!historicalItems &&
              x.positionKind !== ErpPositionsKind.UMWANDLUNGSHISTORIE)
        )
        .map((x) =>
          orderToIncomingDeliveryNote || contractToDeliveryNote
            ? {
                ...x,
                should: x.amount,
                amount: quantitySuggestion ? x.amount : x.backlog,
              }
            : x
        );
      if (historicalItems) {
        newPositionList.unshift({
          id: -1,
          longtext: de('COMMON.ERP.HISTORY_POSITION', {
            entity: de(`COMMON.${sourceEntity.toUpperCase()}`),
            number:
              sourceEntity === Entities.contract
                ? entry.contractNumber
                : entry.number,
            date: DateTime.fromISO(
              sourceEntity === Entities.contract
                ? entry.contractDate
                : entry.date
            ).toFormat('dd.MM.yyyy'),
          }),
          amount: 0,
          should: 0,
          positionKind: ErpPositionsKind.UMWANDLUNGSHISTORIE,
          disableSelect: true,
        });
      }
      setPositionList(newPositionList);
    },
    [
      contractToDeliveryNote,
      entry.contractDate,
      entry.contractNumber,
      entry.date,
      entry.number,
      historicalItems,
      orderToIncomingDeliveryNote,
      sourceEntity,
    ]
  );

  useEffect(() => {
    if (originalData.current?.length)
      updatePositionList(
        originalData.current,
        erpQuantitySuggestion.value === 'SHOULD'
      );
  }, [historicalItems, updatePositionList, erpQuantitySuggestion.value]);

  const memoData = useDeepMemo(() => data, [data]);
  useEffect(() => {
    if (memoData[0]?.positionList.length) {
      originalData.current = memoData[0]?.positionList;
      updatePositionList(
        memoData[0]?.positionList,
        erpQuantitySuggestion.value === 'SHOULD'
      );
    }
  }, [erpQuantitySuggestion.value, memoData, updatePositionList]);

  const formatTitleWithCurrency = useCallback(
    (title: string) => {
      return data[0]?.currency
        ? `${title} ${currencyAsSign(data[0]?.currency.name)}`
        : title;
    },
    [data]
  );

  const user = useUser();

  // Update list when changing amount
  useEffect(() => {
    if (contractToDeliveryNote) updateSelected(() => true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  const updateSelected = useEventCallback(
    (isSelected: (id: string) => boolean) => {
      const selectedPositions: SelectedPosition[] = [];
      const removedParents: number[] = [];
      const original = originalData.current || [];
      original.forEach((originalPos) => {
        const currentPos = positions.find((x) => x.id === originalPos.id);

        if (!currentPos) {
          if (removedParents.includes(originalPos.posId))
            selectedPositions.push({
              position: originalPos,
              selected: false,
            });
          return;
        }

        const selected = isSelected(currentPos.localId);
        if (
          originalPos.positionKind === ErpPositionsKind.STUECKLISTE &&
          !selected
        ) {
          removedParents.push(originalPos.id);
        }

        selectedPositions.push({
          position: currentPos,
          selected: currentPos.amount === 0 ? false : selected,
          newAmount: currentPos.amount,
          oldAmount: originalPos.amount,
        });
      });

      props.onItemsChanged(selectedPositions, { forceEnabled: false });
    }
  );

  // verify this one
  const columns = useMemo(() => {
    let columns: (ColumnAdditionalData & Column<GroupedPosition>)[] = [
      {
        Header: t('COMMON.ERP.NUMBER'),
        accessor: 'number',
        width: 42,
        Cell: contractToDeliveryNote ? DisabledCell : Cell,
      },
    ];
    if (contractToDeliveryNote) {
      columns = [
        ...columns,
        {
          Header: t('COMMON.ERP.SHOULD'),
          accessor: 'should',
          width: 70,
          className: style.backgroundCell,
          Cell: contractToDeliveryNote ? DisabledNumberCell : NumberCell,
          ...NUMBER_PARAMS,
        },
        {
          Header: t('COMMON.ERP.REST'),
          accessor: 'backlog',
          width: 56,
          Cell: contractToDeliveryNote ? DisabledNumberCell : NumberCell,
          className: style.backgroundCell,
          ...NUMBER_PARAMS,
        },
        {
          Header: t('COMMON.ERP.AMOUNT'),
          accessor: 'amount',
          width: 84,
          ...NUMBER_PARAMS,
          Cell: (cell) => {
            if (
              cell.row.original.positionKind ===
              ErpPositionsKind.UMWANDLUNGSHISTORIE
            )
              return '';

            if (
              cell.row.original.positionKind !== ErpPositionsKind.STANDARD &&
              cell.row.original.positionKind !== ErpPositionsKind.STUECKLISTE
            )
              return (
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {cell.value}
                </div>
              );

            return (
              <EditableCell
                {...(cell as object)}
                showZeros
                onChange={(value: string) => {
                  const amount = parseAsFloat(value) || 0;
                  onEditPosition({
                    forceUpdate: true,
                    position: {
                      localId: cell.row.id,
                      id: cell.row.original.id,
                      amount: Math.max(amount, 0),
                    },
                  });
                }}
              />
            );
          },
        },
        {
          Header: t('COMMON.ERP.UNIT'),
          accessor: 'unit',
          Cell: contractToDeliveryNote ? DisabledCell : Cell,
          width: 56,
        },
        {
          Header: t('COMMON.ERP.DESCRIPTION'),
          accessor: 'longtext',
          Cell: contractToDeliveryNote ? DisabledCell : Cell,
          width: 252,
        },
      ];
    } else {
      columns = [
        ...columns,
        {
          Header: t('COMMON.ERP.DESCRIPTION'),
          accessor: 'longtext',
          width: 252,
        },
        {
          Header: t('COMMON.ERP.AMOUNT'),
          accessor: 'amount',
          width: 84,
          Cell: NumberCell,
          ...NUMBER_PARAMS,
        },
        {
          Header: t('COMMON.ERP.UNIT'),
          accessor: 'unit',
          width: 56,
        },
      ];
    }
    if (canSeeSellingPrices(user)) {
      columns = [
        ...columns,
        {
          Header: formatTitleWithCurrency(t('COMMON.ERP.PRICE')),
          accessor: 'singlePriceNet',
          width: 98,
          Cell: contractToDeliveryNote ? DisabledNumberCell : NumberCell,
          ...CURRENCY_PARAMS,
        },
        {
          Header: formatTitleWithCurrency(t('COMMON.ERP.TOTAL_PRICE')),
          accessor: 'totalPriceNet',
          width: 98,
          Cell: contractToDeliveryNote ? DisabledNumberCell : NumberCell,

          ...CURRENCY_PARAMS,
        },
        {
          Header: t('COMMON.ERP.INSTEAD_OF_PRICE'),
          accessor: 'insteadOfTotalPrice',
          Cell: contractToDeliveryNote ? DisabledCell : Cell,
          width: 168,
        },
      ];
    }

    return columns;
  }, [
    t,
    contractToDeliveryNote,
    user,
    onEditPosition,
    formatTitleWithCurrency,
  ]);

  const cellDefinition = useMemo(() => {
    const defs: Partial<
      Record<keyof GroupedPosition, CellDefinition<GroupedPosition>>
    > = {
      number: {
        type: 'number',
      },
      longtext: {
        type: 'text',
      },
      amount: {
        type: 'number',
        transformInputValue: (input: string) => {
          const number = parseAsFloat(input);
          if (number < 0) return '0';
          return input;
        },
      },
      unit: {
        type: 'text',
      },
      singlePriceNet: {
        type: 'number',
      },
      totalPriceNet: {
        type: 'number',
      },
      insteadOfTotalPrice: {
        type: 'text',
      },
    };

    if (contractToDeliveryNote) {
      defs.should = {
        type: 'number',
      };
      defs.backlog = {
        type: 'number',
      };
    }

    return defs;
  }, [contractToDeliveryNote]);

  const tableInstanceRef = useRef<TableInstance>();
  useEffect(() => {
    if (
      tableInstanceRef.current &&
      positionList.length &&
      !loading &&
      !firstTimeLoaded.current
    ) {
      firstTimeLoaded.current = true;
    }
  }, [loading, positionList.length]);

  const onSelectedItemIdsChange = useEventCallback((items: string[]) => {
    if (contractToDeliveryNote) return;
    updateSelected((localId) => items.includes(localId));
  });

  const erpSize = useSetting(settings.erpRowSize());

  const allowedColumns = useMemo(() => {
    return Object.keys(cellDefinition);
  }, [cellDefinition]);

  const selectedRowIds = useMemo(() => {
    return positions.reduce((prev, cur) => {
      prev[cur.localId] = true;
      return prev;
    }, {} as Record<string, boolean>);
  }, [positions]);

  return (
    <Collapse
      {...collapseProps}
      defaultOpen={maximized ? maximized : undefined}
      onToggled={(open) => {
        props.onToggled(open);
        setMaximized(open);
      }}
      headerBar={
        showRowSize &&
        maximizedMask && (
          <div className={style.collapse}>
            <div className={style.collapseInner}>
              {contractToDeliveryNote && (
                <>
                  <div
                    className={clsx(style.rowSizePicker, style.positionTitle)}
                  >
                    <Typography>
                      {t('COMMON.ERP.QUANTITY_SUGGESTION')}:
                    </Typography>
                  </div>
                  <div className={style.rowSizePicker}>
                    <ToggleButtonGroup
                      value={erpQuantitySuggestion.value}
                      exclusive
                    >
                      <Tooltip
                        title={t('COMMON.ERP.QUANTITY_SUGGESTION.SHOULD')}
                        placement="top"
                      >
                        <ToggleButton
                          value="SHOULD"
                          onClick={() => erpQuantitySuggestion.set('SHOULD')}
                          className={style.paddingButton}
                        >
                          {t('COMMON.ERP.SHOULD')}
                        </ToggleButton>
                      </Tooltip>
                      <Tooltip
                        title={t('COMMON.ERP.QUANTITY_SUGGESTION.REST')}
                        placement="top"
                      >
                        <ToggleButton
                          value="REST"
                          onClick={() => erpQuantitySuggestion.set('REST')}
                          className={style.paddingButton}
                        >
                          {t('COMMON.ERP.REST')}
                        </ToggleButton>
                      </Tooltip>
                    </ToggleButtonGroup>
                  </div>
                </>
              )}
              <div className={style.rowSizePicker}>
                <RowSizePicker />
              </div>
            </div>
          </div>
        )
      }
    >
      <EditTable<GroupedPosition>
        tableInstanceRef={tableInstanceRef}
        items={positions}
        columns={columns}
        cellDefinition={cellDefinition}
        classes={{
          table: style.table,
          tbody: style.tbody,
        }}
        initialState={{
          selectedRowIds,
        }}
        editableRows={NOT_EDITABLE_ROWS}
        showSelectionColumn={!contractToDeliveryNote}
        onSelectedItemIdsChange={onSelectedItemIdsChange}
        singleLine={erpSize.value === 'SINGLE'}
        allowedColumns={allowedColumns}
        fitSpace
      />
    </Collapse>
  );
};
