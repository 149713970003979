import { OrganisationAreaSectionProps } from '../types';

export const OrganisationAreaSection = ({
  isVisible,
  children,
}: OrganisationAreaSectionProps) => {
  if (!isVisible) return null;

  return <div>{children}</div>;
};
