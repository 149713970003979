import { useCallback } from 'react';

import {
  IsMultiselectableFunction,
  ToggleMultiselectabilityFunction,
} from '../types';

import { useOrganisationAreaContext } from './use-organisation-area-context';

export const useOrganisationAreaSectionMultiselectability = () => {
  const { multiselectableSections, setMultiselectableSections } =
    useOrganisationAreaContext();

  const isMultiselectable = useCallback<IsMultiselectableFunction>(
    (section) => {
      return multiselectableSections.includes(section);
    },
    [multiselectableSections]
  );

  const toggleMultiselectability =
    useCallback<ToggleMultiselectabilityFunction>(
      (section) => {
        const isMultiselectableSection = isMultiselectable(section);
        let newSections = [...multiselectableSections];

        if (isMultiselectableSection) {
          newSections = newSections.filter((_section) => section !== _section);
        } else {
          newSections.push(section);
        }

        setMultiselectableSections(newSections);
      },
      [isMultiselectable, multiselectableSections, setMultiselectableSections]
    );

  return { isMultiselectable, toggleMultiselectability };
};
