import { ApolloClient } from '@apollo/client';

import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const evictEntityCache = (
  client: ApolloClient<object>,
  entity?: Entities | Entities[],
  resetStore?: boolean
) => {
  if (!entity) return;

  const entities = Array.isArray(entity) ? entity : [entity];
  entities.forEach((entityType) => {
    const queryName = entityDefinition[entityType]?.remote.queryName;

    // ToDo: Get rid of the full cache reset https://work4all.atlassian.net/browse/WW-4752
    if (resetStore && !queryName) {
      // Clears out the cache and then re-executes all active queries.
      // This will ensure no stale data is displayed.
      client.resetStore();
      return;
    }
    if (queryName) {
      // Remove related field from the cache and then re-executes related query if exist.
      // This helps not to trigger a whole cache reset
      client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: queryName,
      });
      return;
    }
  });
};
