import { useEventCallback } from '@mui/material/utils';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { useDialogs } from '@work4all/components';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';

import { useDataProvider } from '@work4all/data';
import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useDebouncedCallback } from '@work4all/utils/lib/hooks/use-debounce-callback';
import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { settings, useSetting } from '../../../../../../settings';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../hooks/mask-context';
import { EMAILMODES } from '../EmailActions';
import { EmailMaskFormValue } from '../types';

import {
  ApplyProps,
  EmailTemplaterContext,
  EmailTemplaterContextProvider,
} from './email-templater-context';
import {
  GetProcessedEmailTemplateVariables,
  useGetProcessedEmailTemplate,
} from './use-get-processed-email-template-query';

interface LastParams {
  contactId?: number;
  projectId?: number;
  businessPartnerType?: SdObjType;
  ticketId?: string;
  customerId?: number;
  supplierId?: number;
  emailContactIds?: string[];
  createTemplateAttachments?: boolean;
  templateContent: string;
}
const htmlToPlainText = (html: string): string => {
  return sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {},
  });
};
// TODO: this component need to be refactored in WW-3197:https://work4all.atlassian.net/browse/WW-3197
export interface EmailTemplaterProps {
  /** If `true`, will disable email templates and hide the template picker. */
  disabled?: boolean;
  children?: React.ReactNode;
  additionalTemplateVariables?: Record<string, unknown>;
  initialTemplate?: EMailTemplate;
  initialSignature?: EMailSignature;
  eMailMode?: EMAILMODES | string;
  previousEMail?: EMail;
  preloadDone?: boolean;
  isCreateMode?: boolean;
  isDraftMode?: boolean;
  onInitialized: () => void;
  forceNoTemplate?: boolean;
  languageId?: number;
}

export function EmailTemplater(props: EmailTemplaterProps) {
  const {
    disabled,
    children,
    additionalTemplateVariables,
    initialTemplate,
    initialSignature,
    eMailMode,
    previousEMail,
    preloadDone,
    isCreateMode,
    isDraftMode,
    onInitialized: onInitializedProp,
    forceNoTemplate = false,
    languageId = 0,
  } = props;

  const dialogs = useDialogs();

  const { t } = useTranslation();

  const onInitialized = useEventCallback(onInitializedProp);

  const mask = useMaskContext();

  const tempFileManager = useContext(TempFileManagerContext);

  const form = useFormContextPlus<EmailMaskFormValue>();
  const { setValue, watch, getValues, getFieldState } = form;

  const currentToMailContactsInstance = watch('toMailContactsInstance');
  const currentCcMailContactsInstance = watch('ccMailContactsInstance');
  const currentBusinessPartner = watch('businessPartner');
  const currentContact = watch('contact');
  const currentProject = watch('project');
  const currentBodyHtml = watch('bodyHtml');

  const [template, setTemplate] = useState<EMailTemplate | null>(null);
  const [signature, setSignature] = useState<EMailSignature | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const {
    value: lastUsedTemplateValue,
    set: setLastUsedTemplate,
    delete: deleteLastUsedTemplate,
  } = useSetting(settings.lastUsedEmailTemplate());
  const lastUsedTemplate = useMemo(
    () => (forceNoTemplate ? null : lastUsedTemplateValue),
    [lastUsedTemplateValue, forceNoTemplate]
  );
  const {
    value: lastUsedEmailSignatureValue,
    set: setLastUsedSignature,
    delete: deleteLastUsedSignature,
  } = useSetting(settings.lastUsedEmailSignature());

  const lastUsedSignature = useMemo(
    () => (forceNoTemplate ? null : lastUsedEmailSignatureValue),
    [lastUsedEmailSignatureValue, forceNoTemplate]
  );

  const lastUsedSignatureRef = useLatest(lastUsedSignature);
  const lastUsedTemplateRef = useLatest(lastUsedTemplate);

  const getProcessedEmailTemplate = useGetProcessedEmailTemplate();

  const prevEmailBody = useMemo(() => {
    if (!preloadDone) {
      return null;
    }

    if (!previousEMail) {
      return '';
    }

    let sentDate: DateTime;
    if (previousEMail?.date) {
      sentDate = DateTime.fromFormat(
        previousEMail?.date.replace(/(.*)Z$/, '$1+0'),
        W4ADateFormat.DEFAULT
      );
    }
    let body =
      eMailMode === EMAILMODES.sendAgain
        ? previousEMail?.bodyHtml
        : `<hr/>
        <p>
        <b>${t('COMMON.FROM')}:</b> ${
            previousEMail?.from || t('COMMON.UNKNOWN')
          }</br>
        <b>${t('MASK.SENT')}:</b> ${sentDate?.toLocaleString(
            DateTime.DATETIME_SHORT
          )}</br>
        <b>${t('MASK.TO')}:</b> ${previousEMail?.to}</br>
        <b>${t('COMMON.SUBJECT')}:</b> ${previousEMail?.subject}</br>
        </p>
        <p>
        ${previousEMail?.bodyHtml}
        </p>
        `;

    //replacing any signature markings and textmark data-items
    body = htmlParser.disableTextmarks(body);

    return body;
  }, [eMailMode, previousEMail, t, preloadDone]);

  const currentValues = useMemo<EmailMaskFormValue>(() => {
    return {
      businessPartner: currentBusinessPartner,
      contact: currentContact,
      toMailContactsInstance: currentToMailContactsInstance,
      ccMailContactsInstance: currentCcMailContactsInstance,
      project: currentProject,
    };
  }, [
    currentBusinessPartner,
    currentContact,
    currentProject,
    currentToMailContactsInstance,
    currentCcMailContactsInstance,
  ]);

  const [initTemplate, setInitTemplate] = useState(false);
  const [initSignature, setInitSignature] = useState(false);
  const [appliedTemplateString, setAppliedTemplateString] = useState('');
  const [appliedSignatureString, setAppliedSignatureString] = useState('');
  const [appliedSubjectString, setAppliedSubjectString] = useState('');

  const [templateProcessing, setTemplateProcessing] = useState(false);

  const isSendAgainMode = eMailMode === EMAILMODES.sendAgain;

  const lastBody = useRef<LastParams>();
  const lastSubject = useRef<LastParams>();
  // This function can be called multiple times if related fields are changed,
  // (e.g. a user changes BusinessPartner which resets Contact). Debounce it
  // with a simple timeout.
  const refreshTemplate = useCallback(
    async (body?: string) => {
      if (disabled || !preloadDone) return;
      setTemplateProcessing(true);
      try {
        //eslint-disable-next-line
        //@ts-ignore - TECH_DEBT error is occuring in azure pipeline
        const bodyContent = htmlParser.applyBody(body, getValues('bodyHtml'));

        if (bodyContent.trim()) {
          const params = {
            templateContent: htmlParser.toApi(bodyContent),
            ...extractEmailTemplateVariables(
              currentValues,
              additionalTemplateVariables
            ),
          };

          if (!isEqual(lastBody.current, params)) {
            lastBody.current = params;
            const { body: processedBody } = await getProcessedEmailTemplate(
              params
            );
            const body = isSendAgainMode
              ? processedBody
              : htmlParser.toFroala(processedBody);

            setValue('bodyHtml', body, {
              shouldDirty: getFieldState('bodyHtml').isDirty,
            });
          }
        }

        const currentSubject = getValues('subject');
        const subjectContent = currentSubject
          ? currentSubject
          : appliedSubjectString || '';

        if (subjectContent.trim()) {
          const params = {
            templateContent: htmlParser.toApi(subjectContent),
            ...extractEmailTemplateVariables(
              currentValues,
              additionalTemplateVariables
            ),
          };
          if (!isEqual(lastSubject.current, params)) {
            lastSubject.current = params;
            const { body: subject } = await getProcessedEmailTemplate(params);

            const cleanSubject = htmlParser.toFroala(subject);
            setValue('subject', htmlToPlainText(cleanSubject), {
              shouldDirty: getFieldState('subject').isDirty,
              shouldValidate: true,
            });
          }
        }
      } finally {
        setTemplateProcessing(false);
      }
    },
    [
      disabled,
      preloadDone,
      isSendAgainMode,
      getValues,
      appliedSubjectString,
      currentValues,
      additionalTemplateVariables,
      getProcessedEmailTemplate,
      setValue,
      getFieldState,
    ]
  );

  const applyTemplate = useCallback(
    async (props: ApplyProps) => {
      if (disabled) return;
      const {
        template,
        force = false,
        skipSave = false,
        shouldDirtyAffectedFields = true,
      } = props;

      if (template === null) {
        setTemplate(null);

        if (lastUsedTemplateRef.current !== null && !skipSave) {
          deleteLastUsedTemplate();
        }

        return;
      }

      if (currentBodyHtml?.trim().length) {
        // Ignore the current form value if `force` is true.
        if (!force) {
          const confirmed = await dialogs.confirm({
            title: t('ALERTS.CONFIRM'),
            description: t('ALERTS.TEMPLATE_APPLICATION'),
            confirmLabel: t('ALERTS.BTN_OK'),
            cancelLabel: t('ALERTS.BTN_ABORT'),
          });

          if (!confirmed) {
            // If the user did not confirm the changes, do nothing.
            return;
          }
        }
      }

      setTemplate(template);

      // Save only the properties that are required to use the template. Avoid
      // saving things like template body to save space.
      if (!template) {
        return;
      }
      const templateData = pickRequiredEmailTemplateProperties(template);

      const languageTemplate =
        template.childs?.find((child) => child?.languageCode === languageId) ??
        template;

      if (languageTemplate?.signature) {
        setSignature(languageTemplate.signature);
        setAppliedSignatureString(languageTemplate.signature?.body);
        const signatureData = pickRequiredEmailTemplateProperties(
          languageTemplate?.signature
        );
        if (
          !props.skipSave &&
          !isEqual(signatureData, lastUsedSignatureRef.current)
        ) {
          setLastUsedSignature(signatureData as EMailSignature);
        }
      }

      const isTemplateEqualLastUsedTemplate = isEqual(
        templateData,
        lastUsedTemplateRef.current
      );
      // If the selected template is the same that the saved one, it means this
      // function was called in the init effect. Avoid saving the same template
      // again to reduce on network requests.
      if (!skipSave && !isTemplateEqualLastUsedTemplate) {
        setLastUsedTemplate(templateData as EMailTemplate);
      }

      if (languageTemplate === null) {
        enqueueSnackbar(t('ERROR.BROKEN_EMAIL_TEMPLATE'), {
          variant: 'error',
        });
      } else {
        setAppliedTemplateString(languageTemplate.body);
        setAppliedSubjectString(languageTemplate.subject);
        setValue('subject', htmlToPlainText(languageTemplate.subject), {
          shouldDirty: shouldDirtyAffectedFields,
        });

        if (isCreateMode || (!isTemplateEqualLastUsedTemplate && isDraftMode)) {
          // To select non-templates files
          const uploadedTempFiles =
            tempFileManager.fileList?.filter(
              (tempFile) => tempFile?.fileInfos?.downloadUrl
            ) ?? [];

          if (tempFileManager.fileList.length > 0) {
            tempFileManager.markFilesToDelete(
              tempFileManager.fileList.map((file) => file.id as string)
            );
          }

          if (languageTemplate?.attachmentList?.length > 0) {
            const { createdAttachementTempfiles } =
              await getProcessedEmailTemplate({
                templateId: languageTemplate.id,
                createTemplateAttachments: true,
              });

            const processedTemplateTempfiles = createdAttachementTempfiles.map(
              (tempFile) => ({
                id: tempFile.id,
                fileName: tempFile.name,
                fileInfos: {
                  fileSize: tempFile.size,
                },
              })
            );

            tempFileManager.setTemporaryFileUploads([
              ...processedTemplateTempfiles,
              ...uploadedTempFiles,
            ]);
          } else if (tempFileManager.temporaryFileUploads.length > 0) {
            tempFileManager.setTemporaryFileUploads([...uploadedTempFiles]);
          }
        }
      }
    },
    [
      disabled,
      currentBodyHtml,
      lastUsedTemplateRef,
      deleteLastUsedTemplate,
      dialogs,
      t,
      lastUsedSignatureRef,
      setLastUsedSignature,
      setLastUsedTemplate,
      enqueueSnackbar,
      setValue,
      isCreateMode,
      isDraftMode,
      languageId,
      tempFileManager,
      getProcessedEmailTemplate,
    ]
  );

  const applySignature = useCallback(
    async (props: ApplyProps) => {
      if (disabled) return;
      const { signature, shouldDirtyAffectedFields = true } = props;
      setSignature(signature);

      if (signature) {
        const _signature = pickRequiredEmailTemplateProperties(signature);
        if (!isEqual(_signature, lastUsedSignatureRef.current)) {
          setLastUsedSignature(_signature as EMailSignature);
        }
      } else {
        deleteLastUsedSignature();
      }

      let body = currentBodyHtml || '';

      body = htmlParser.replaceSignature(body, signature?.body ?? '');
      setValue('bodyHtml', body, { shouldDirty: shouldDirtyAffectedFields });
      setAppliedSignatureString(signature?.body ?? '');
    },
    [
      disabled,
      lastUsedSignatureRef,
      setLastUsedSignature,
      setValue,
      currentBodyHtml,
      deleteLastUsedSignature,
    ]
  );

  const requestSignature = useMemo<DataRequest>(() => {
    const data: EMailSignature = {
      id: null,
      name: null,
      body: null,
    };

    return {
      entity: Entities.eMailSignature,
      data,
      filter: [{ id: { $eq: lastUsedSignature?.id } }],
      keysArguments: EMAIL_SIGNATURE_KEYS_ARGUMENTS,
    };
  }, [lastUsedSignature?.id]);

  const requestTemplate = useMemo<DataRequest>(() => {
    const data: EMailTemplate = {
      id: null,
      name: null,
      body: null,
      subject: null,
      attachmentList: [{ id: null }],
      signature: {
        body: null,
        id: null,
        name: null,
      },
      childs: [
        {
          id: null,
          languageCode: null,
          name: null,
          body: null,
          subject: null,
          signature: {
            body: null,
            id: null,
            name: null,
          },
          attachmentList: [{ id: null }],
        },
      ],
    };

    return {
      entity: Entities.eMailTemplate,
      data,
      filter: [{ id: { $eq: lastUsedTemplate?.id } }],
    };
  }, [lastUsedTemplate?.id]);

  const lastUsedTemplateDone = useRef(false);
  const lastUsedSignatureDone = useRef(false);

  const emailSignatureData = useDataProvider<EMailSignature>(
    requestSignature,
    !lastUsedSignature || lastUsedSignatureDone.current
  );
  const emailSignature = emailSignatureData?.data;

  const emailTemplateData = useDataProvider<EMailTemplate>(
    requestTemplate,
    !lastUsedTemplate || lastUsedTemplateDone.current
  );

  const emailTemplate = emailTemplateData?.data;

  const resolvedTemplateString = useMemo<string | null>(() => {
    if (!preloadDone) {
      return null;
    }
    if (isSendAgainMode) {
      return '';
    } else if (initTemplate) {
      return appliedTemplateString;
    } else if (initialTemplate) {
      setInitTemplate(true);
      applyTemplate({
        force: true,
        template: initialTemplate,
        skipSave: true,
        shouldDirtyAffectedFields: false,
      });
      return initialTemplate?.body;
    } else if (lastUsedTemplate && emailTemplate.length) {
      const template =
        emailTemplate?.[0]?.childs?.find(
          (child) => child.languageCode === languageId
        ) ?? emailTemplate?.[0];
      setInitTemplate(true);
      applyTemplate({
        force: true,
        template,
        skipSave: true,
        shouldDirtyAffectedFields: false,
      });
      return template?.body;
    } else if (!lastUsedTemplate) {
      return '';
    }

    return null;
  }, [
    preloadDone,
    isSendAgainMode,
    initTemplate,
    initialTemplate,
    lastUsedTemplate,
    emailTemplate,
    appliedTemplateString,
    applyTemplate,
    languageId,
  ]);

  const resolvedSignatureString = useMemo<string | null>(() => {
    if (!preloadDone) {
      return null;
    }
    if (isSendAgainMode) {
      return '';
    } else if (initSignature) {
      return appliedSignatureString;
    } else if (initialSignature) {
      setInitSignature(true);
      applySignature({
        force: true,
        signature: initialSignature,
        skipSave: true,
        shouldDirtyAffectedFields: false,
      });
      return initialSignature?.body;
    } else if (
      lastUsedSignature &&
      emailSignature?.find((x) => x.id === lastUsedSignature?.id)
    ) {
      setInitSignature(true);
      applySignature({
        force: true,
        signature: emailSignature?.find((x) => x.id === lastUsedSignature?.id),
        skipSave: true,
        shouldDirtyAffectedFields: false,
      });
      return emailSignature?.find((x) => x.id === lastUsedSignature?.id)?.body;
    } else if (!lastUsedSignature) {
      return '';
    }

    return null;
  }, [
    preloadDone,
    isSendAgainMode,
    initSignature,
    initialSignature,
    lastUsedSignature,
    emailSignature,
    appliedSignatureString,
    applySignature,
  ]);

  const initialized = useRef(false);

  const resolvedString = useMemo(() => {
    if (resolvedSignatureString === null || resolvedTemplateString === null) {
      return '';
    }

    const newBody =
      resolvedTemplateString +
      htmlParser.asSignature(resolvedSignatureString) +
      prevEmailBody;
    setValue('bodyHtml', newBody);

    return newBody;
    // TODO: we need to refactor resolving this string, resolvedSignatureString is already applied in body and should not set body
  }, [
    prevEmailBody,
    resolvedSignatureString,
    resolvedTemplateString,
    setValue,
  ]);

  const refreshTemplateDebounced = useDebouncedCallback(refreshTemplate, 200);

  useEffect(() => {
    if (preloadDone && mask.isCreateMode) {
      refreshTemplateDebounced(resolvedString);

      if (!initialized.current) {
        onInitialized();
      }
      initialized.current = true;
    }
  }, [
    preloadDone,
    mask.isCreateMode,
    resolvedString,
    refreshTemplateDebounced,
    appliedSignatureString,
    onInitialized,
  ]);

  const context = useMemo<EmailTemplaterContext>(() => {
    return {
      disabled,
      template,
      signature,
      applyTemplate,
      applySignature,
      processing: templateProcessing || !preloadDone,
      initialTemplate,
    };
  }, [
    disabled,
    template,
    signature,
    applyTemplate,
    applySignature,
    templateProcessing,
    preloadDone,
    initialTemplate,
  ]);

  return (
    <EmailTemplaterContextProvider value={context}>
      {children}
    </EmailTemplaterContextProvider>
  );
}

function extractEmailTemplateVariables(
  values: EmailMaskFormValue,
  additionalTemplateVariables: Record<string, unknown> = {}
): Omit<
  GetProcessedEmailTemplateVariables,
  'userId' | 'templateId' | 'templateContent'
> {
  const businessPartner = values.businessPartner?.data;
  const customer = isCustomer(businessPartner) ? businessPartner : null;
  const supplier = isSupplier(businessPartner) ? businessPartner : null;
  const project = values.project ?? null;
  const contact = values.contact;
  const emailContactIds = values.toMailContactsInstance
    ?.map((value) => value.id)
    .filter((id) => {
      //we dont send generic email addresses to the template, they can be used anyhow
      return id != null && !id.startsWith('Email_');
    });

  return {
    customerId: customer?.id,
    supplierId: supplier?.id,
    projectId: project?.id,
    emailContactIds,
    ...(contact != null && (customer != null || supplier != null)
      ? {
          contactId: contact.id,
          businessPartnerType:
            customer != null ? SdObjType.KUNDE : SdObjType.LIEFERANT,
        }
      : null),
    ...additionalTemplateVariables,
  };
}

function isCustomer(
  businessPartner: Customer | Supplier
): businessPartner is Customer {
  return businessPartner?.__typename === 'Kunde';
}

function isSupplier(
  businessPartner: Customer | Supplier
): businessPartner is Supplier {
  return businessPartner?.__typename === 'Lieferant';
}

function pickRequiredEmailTemplateProperties(
  template: EMailTemplate | EMailSignature
): EMailTemplate | EMailSignature {
  const { id, name, __typename } = template || {
    id: null,
    name: '',
    __typename: '',
  };
  return { id, name, __typename };
}
