import React, { MouseEventHandler } from 'react';
import {
  Cell,
  Column,
  IdType as ReactTableIdType,
  TableInstance,
  TableState,
} from 'react-table';

import { EditableCellNumericProps } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';
import {
  EditModeConfig,
  EditModeTableInstance,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { UseEditableColumnsSettingsProps } from './hooks/use-editable-columns-settings';

export interface EditTableMask<T extends object> {
  item?: T;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
}

export type IdType = number | string;

// eslint-disable-next-line @typescript-eslint/ban-types
export interface EditTableEntry<TId = IdType> extends Object {
  id?: TId;
  // TODO: This would be nice to replace & map - for simplicity use same as on Position
  positionKind?: ErpPositionsKind;
  posId?: TId;
  index?: number;

  /**
   * Determinses that data item is not saved on server side.
   */
  cacheOnly?: boolean;

  /**
   * Mark position as dirty and ignore merges to those fields of position.
   */
  dirtyFields?: Record<string, unknown>;

  /**
   * localId is UUID generated locally to keep reference to all rows
   */
  localId?: string;

  collapsed?: boolean;

  disableSelect?: boolean;
}

export interface CellDefinition<T extends EditTableEntry = EditTableEntry>
  extends EditableCellNumericProps {
  type: string;
  style?: React.CSSProperties;
  editable?: boolean;
  render?: (
    input: Cell<T>,
    options: {
      isEditMode: boolean;
      tableInstance: EditModeTableInstance;
    }
  ) => JSX.Element;
  skeleton?: boolean;
  shouldRender?: (input: T) => boolean;
}

export interface OnEditPosition<T> {
  position: T;
  /**
   * With this flag state will be updated always, even if value is equal to initial value.
   * Flag by defualt is set to false and update state only with diff.
   */
  forceUpdate?: boolean;
}

export interface EditTableProps<T extends EditTableEntry> {
  columns: Column<T>[];
  items: T[];
  tableInstanceRef: React.RefObject<TableInstance<T>>;
  mask?: React.ComponentType<EditTableMask<T>>;
  renderMobileItem?: (
    item: T,
    select: (item: T) => void,
    allPositions: T[]
  ) => JSX.Element;
  renderMobileFooter?: (allPositions: T[]) => JSX.Element;
  onCollapsePosition?: (result: T) => void;

  editableRows?: Partial<Record<ErpPositionsKind, string[]>>;
  classes?: {
    tbody?: string;
    mobile?: string;
    table?: string;
    thead?: string;
    tfoot?: string;
  };
  refs?: {
    scroll?: React.MutableRefObject<HTMLDivElement | null>;
  };
  cellDefinition?: Partial<Record<keyof T, CellDefinition<T>>>;

  disabled?: boolean;
  onSelectedItemIdsChange?: (selectedPositionIds: IdType[]) => void;
  onMove?: (id: IdType, index: number) => void;
  onPick?: (article?: Article) => void;
  loadingRows?: IdType[];
  noIdEditableCells?: IdType[];
  itemSize?: 'auto' | ((index: number) => number);
  decorators?: {
    getRelation: (pos: T) => 'parent' | 'child' | 'normal';
    isEditableCell?: (cell: string, original: T) => boolean;
  };
  pending?: boolean;
  showBorders?: boolean;
  showSelectionColumn?: boolean;
  allowedColumns?: string[];
  onRowOut?: (row: T, dir: 1 | -1) => void;
  columnSettings?: UseEditableColumnsSettingsProps<T>['columnSettings'];
  singleLine?: boolean;
  handleOrderChange?: (columns: ReactTableIdType<T>[]) => ReactTableIdType<T>[];
  entity?: Entities;
  bordersKey?: 'ERP' | 'INCOMING_INVOICE' | 'STANDARD';
  fitSpace?: boolean;
  updateMode?: 'row' | 'cell';
  initialState?: Partial<Pick<TableState<T>, 'selectedRowIds'>>;
}
export interface IEditTable {
  scrollTo: (index?: number) => void;
  setEditMode: (
    rowId: string,
    autofocus?: EditModeConfig['autoFocus'],
    columns?: EditModeConfig['columns']
  ) => void;
}
export const NO_BORDER_KIND = [
  ErpPositionsKind.TITEL,
  ErpPositionsKind.TITELSUMME,
  ErpPositionsKind.ZWISCHENSUMME,
  ErpPositionsKind.UMWANDLUNGSHISTORIE,
];
