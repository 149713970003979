import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import {
  CombinedType,
  EntitiyDefinition,
  IFieldData,
  IFieldParam,
  KeyArguments,
  KeysArguments,
  SubDataType,
} from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant, throwInDev, uppercaseFirstLetter } from '@work4all/utils';

export const fragmentDefinition = 'fragment:';

function generateArrayFromObj(
  obj,
  def: EntitiyDefinition<unknown>,
  handleAsUnionType = false
): CombinedType[] | SubDataType {
  const resultArr: CombinedType[] = [];
  const resultObj: SubDataType = {};
  let returnAsObj = false;
  Object.keys(obj).forEach((key) => {
    const item = obj[key];
    let nextDef = entityDefinition[key];

    try {
      if (
        !nextDef &&
        def.fieldDefinitions[key] &&
        def.fieldDefinitions[key].entity
      ) {
        nextDef = entityDefinition[def.fieldDefinitions[key].entity];
      }
    } catch (e) {
      throw new Error(`fieldDefinition missing for ${key}`);
    }

    if (item && typeof item == 'object') {
      if (Array.isArray(item)) {
        resultArr.push({
          [key]: generateArrayFromObj(item[0], nextDef),
        });
      } else if (
        def &&
        def.fieldDefinitions[key] &&
        def.fieldDefinitions[key].entity &&
        Array.isArray(def.fieldDefinitions[key].entity)
      ) {
        resultArr.push({
          [key]: generateArrayFromObj(item, nextDef, true),
        });
      } else if (handleAsUnionType) {
        resultObj[key] = generateArrayFromObj(item, nextDef);
        returnAsObj = true;
      } else {
        resultArr.push({
          [key]: generateArrayFromObj(item, nextDef),
        });
      }
    } else {
      resultArr.push(key);
    }
  });
  if (returnAsObj) return resultObj;
  else return resultArr;
}

export const fieldsToQueryFields = (
  fields: CombinedType[] | unknown,
  entity: Entities,
  keysArguments?: KeysArguments
) => {
  let fieldsFinal: CombinedType[] | SubDataType | unknown = fields;
  if (typeof fields == 'object' && !Array.isArray(fields)) {
    fieldsFinal = generateArrayFromObj(fields, entityDefinition[entity]);
  }

  const fieldsToQuery: IFieldData[] = [];

  const prepareDataFields = (
    data: CombinedType[] | SubDataType | unknown,
    entityDef: EntitiyDefinition<unknown>,
    keyPath: string,
    aliasPath: string
  ) => {
    if (entityDef.kind === 'union') {
      // When querying a union `data` must be an array of objects where each
      // object must have 1 property representing a concrete GraphQL type.
      invariant(
        Array.isArray(data) &&
          data.every((concreteType) => {
            return (
              typeof concreteType === 'object' &&
              concreteType !== null &&
              Object.keys(concreteType).length === 1
            );
          }),
        'Malformed input: When querying a union, `data` must be an array of object with each object having 1 property representing a concrete GraphQL type.'
      );

      for (const concreteType of data) {
        const key = Object.keys(concreteType)[0];

        const nextDef = entityDefinition[key];

        invariant(
          nextDef,
          // TODO There is no way to get the union name at this point?
          `Could not find a definition for *${key}* on union *unknown*`
        );

        prepareDataFields(
          concreteType[key],
          nextDef,
          `${keyPath}${fragmentDefinition}${nextDef.remote.fragmentName}.`,
          `${aliasPath}`
        );
      }

      return;
    }
    (data as CombinedType[]).forEach((data) => {
      if (typeof data === 'string') {
        const def = entityDef.fieldDefinitions[data];
        if (!def) {
          throwInDev(
            `querying undefined field ${data} on ${JSON.stringify(entityDef)}`
          );
          return;
        }

        const alias = `${aliasPath}${data}`;

        fieldsToQuery.push({
          name: `${keyPath}${def.alias}`,
          alias,
          params: getKeyArguments(keysArguments?.[alias]),
        });
      } else if (typeof data === 'object') {
        const keys = Object.keys(data);
        const def = entityDef.fieldDefinitions[keys[0]];

        // if we have union types, we need to generate fragments to properly
        // query the data
        if (def && def.entity && !def.resolve && Array.isArray(def.entity)) {
          const obj = data[keys[0]];
          const entityNames = Object.keys(obj);
          entityNames.forEach((name) => {
            const nextDef = entityDefinition[name];
            if (!nextDef) {
              throwInDev(`entitiyDefinition (${name}) is undefined`);
              return;
            }
            prepareDataFields(
              obj[name],
              nextDef,
              `${keyPath}${def.alias}.${fragmentDefinition}${nextDef.remote.fragmentName}.`,
              `${aliasPath}${keys[0]}.`
            );
          });
        }
        // if there is no resolve function, we use normal graphql generation logic
        else if (def.entity && !def.resolve) {
          const nextDef = entityDefinition[def.entity];
          prepareDataFields(
            // todo: fix this type
            (data as unknown as CombinedType[])[keys[0]],
            nextDef,
            `${keyPath}${def.alias}.`,
            `${aliasPath}${keys[0]}.`
          );
        }
        // prepare related data
      }
    });
  };

  const entityDef = entityDefinition[entity];
  prepareDataFields(fieldsFinal, entityDef, '', '');

  return fieldsToQuery;
};

const getKeyArguments = (
  keyArguments: KeyArguments | undefined
): IFieldParam[] | undefined => {
  if (!keyArguments) return undefined;

  return Object.keys(keyArguments).map((keyArgument) => {
    const type = typeof keyArguments[keyArgument];

    return {
      name: keyArgument,
      type: uppercaseFirstLetter(type),
    };
  });
};
