import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class VacationEntitlement<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: anspruch */
  anspruch?: number;
  /** Alias: bisJetzt */
  bisJetzt?: number;
  /** Alias: jahr */
  jahr?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: vorjahr */
  vorjahr?: number;

  __typename?: string;
}

const fields: FieldDefinitions<VacationEntitlement> = {
  id: {
    alias: 'code',
  },
  anspruch: {
    alias: 'anspruch',
  },
  bisJetzt: {
    alias: 'bisJetzt',
  },
  jahr: {
    alias: 'jahr',
  },
  userId: {
    alias: 'benutzerCode',
  },
  vorjahr: {
    alias: 'vorjahr',
  },
  __typename: {
    alias: '__typename',
  },
};

export const vacationEntitlementEntityDefinition: EntitiyDefinition<VacationEntitlement> =
  {
    local: {},
    remote: {
      queryName: 'getUrlaubsanspruch',
      fragmentName: 'Urlaubsanspruch',
      withPaginationWrapper: false,
      params: [
        { name: 'years', type: '[Int]' },
        { name: 'userCode', type: 'Int!' },
      ],
    },
    fieldDefinitions: fields,
  };
