import { useEventCallback } from '@mui/material/utils';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableInstance } from 'react-table';

import {
  ColumnAdditionalData,
  CURRENCY_PARAMS,
  NUMBER_PARAMS,
  NumberCell,
} from '@work4all/components';
import { EditableCell } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { parseAsFloat } from '@work4all/utils';

import { useEditableState } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import { CellDefinition } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/types';
import { recalculatePositions } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/hooks/recalculatePositions';
import { settings, useSetting } from '../../../../../settings';
import { GroupedPosition, SelectedPosition } from '../../types';
import { OnItemsChangedOptions } from '../ErpDialogPositions';
import { SupplierPricePickerField } from '../SupplierPricePicker/SupplierPricePickerField';

interface UseGroupedPositionsProps {
  onItemsChanged: (
    positions: SelectedPosition[],
    options: OnItemsChangedOptions
  ) => void;
  positionList: GroupedPosition[];
}
export const useGroupedPositions = (props: UseGroupedPositionsProps) => {
  const { positionList } = props;

  const { t } = useTranslation();

  const mutateState = useCallback((input: GroupedPosition[]) => {
    const { result } = recalculatePositions(input, false, false);
    return result as GroupedPosition[];
  }, []);

  const { positions, onEditPosition, onCollapsePosition } = useEditableState({
    positions: positionList,
    onAddPosition: noop,
    onEditPosition: noop,
    onMovePosition: noop,
    onRemovePosition: noop,
    mutateState,
  });

  const columns = useMemo(() => {
    const columns: (ColumnAdditionalData & Column<GroupedPosition>)[] = [
      {
        Header: t('COMMON.ERP.NUMBER'),
        accessor: 'number',
        width: remToPx(5),
      },
      {
        Header: t('COMMON.ERP.DESCRIPTION'),
        accessor: 'name',
        width: remToPx(14),
      },
      {
        Header: t('COMMON.ERP.SHOULD'),
        accessor: 'should',
        width: remToPx(6),
        Cell: NumberCell,
        ...NUMBER_PARAMS,
      },
      {
        Header: t('COMMON.ERP.AMOUNT'),
        accessor: 'amount',
        width: remToPx(6),
        Cell: (cell) => {
          if (cell.row.original.posId) return <NumberCell {...cell} />;
          return (
            <EditableCell
              {...(cell as object)}
              onChange={(value: string) => {
                const amount = parseAsFloat(value) || 0;
                onEditPosition({
                  forceUpdate: true,
                  position: {
                    localId: cell.row.id,
                    id: cell.row.original.id,
                    amount: Math.max(amount, 0),
                  },
                });
              }}
            />
          );
        },
        ...NUMBER_PARAMS,
      },
      {
        Header: t('COMMON.ERP.UNIT'),
        accessor: 'unit',
        width: remToPx(4),
      },
      {
        Header: t('COMMON.SUPPLIER'),
        accessor: 'supplier',
        width: remToPx(7),
        Cell: (cell) => {
          return (
            <SupplierPricePickerField
              onChange={() => {}}
              articleId={cell.row.original.articleId}
              value={cell.value as { id: number; name: string }}
            />
          );
        },
      },
      {
        Header: t('COMMON.ERP.PRICE'),
        accessor: 'singlePriceNet',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
      },
      {
        Header: t('FIELDS.discount'),
        accessor: 'discount',
        width: remToPx(7),
        Cell: NumberCell,
      },
      {
        Header: t('COMMON.ERP.TOTAL_PRICE'),
        accessor: 'totalPriceNet',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
      },
    ];

    return columns;
  }, [t, onEditPosition]);

  const cellDefinition = useMemo(() => {
    const defs: Partial<
      Record<keyof GroupedPosition, CellDefinition<GroupedPosition>>
    > = {
      number: {
        type: 'number',
      },
      name: {
        type: 'text',
      },
      should: {
        type: 'number',
      },
      amount: {
        type: 'number',
        transformInputValue: (input: string) => {
          const number = parseAsFloat(input);
          if (number < 0) return '0';
          return input;
        },
      },
      supplier: {
        type: 'text',
      },
      unit: {
        type: 'text',
      },
      singlePriceNet: {
        type: 'number',
      },
      discount: {
        type: 'number',
        unit: '%',
      },
      totalPriceNet: {
        type: 'number',
      },
    };

    return defs;
  }, []);

  const tableInstanceRef = useRef<TableInstance<GroupedPosition>>();

  const selectedItems = useRef<string[]>([]);
  const onSelectedItemIdsChange = useEventCallback((items: string[]) => {
    selectedItems.current = items;
    updateSelected();
  });

  // Update list when changing amount
  useEffect(() => {
    updateSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  const updateSelected = useEventCallback(() => {
    const selectedPositions: SelectedPosition[] = [];
    const original = positionList || [];
    original.forEach((originalPos) => {
      if (!originalPos.isHeader) return;
      const currentPos = positions.find((x) => x.id === originalPos.id);
      if (!currentPos) return;
      const selected = selectedItems.current.includes(currentPos.localId);

      selectedPositions.push({
        position: currentPos,
        selected,
        newAmount: currentPos.amount,
        oldAmount: originalPos.amount,
      });
    });
  });

  const erpSize = useSetting(settings.erpRowSize());

  const allowedColumns = useMemo(() => {
    return Object.keys(cellDefinition);
  }, [cellDefinition]);

  const decorators = useMemo(() => {
    const getRelation = (position: GroupedPosition) => {
      if (position.posId) return 'normal';
      if (
        position.positionKind === ErpPositionsKind.STUECKLISTE ||
        ('collapsed' in position && position.collapsed)
      ) {
        return 'parent';
      }
      return 'normal';
    };
    return {
      getRelation,
    };
  }, []);

  return {
    tableInstanceRef,
    items: positions,
    onCollapsePosition,
    columns,
    onSelectedItemIdsChange,
    allowedColumns,
    erpSize,
    decorators,
    cellDefinition,
  };
};
