import { useState } from 'react';
import { TableInstance } from 'react-table';

import { TableStateBagProvider } from '@work4all/components';
import { TablePrefilterProvider } from '@work4all/components/lib/components/table/TablePrefilterProvider';

import { EntityToTable } from '../../../containers/file-entities-lists';
import { useBottomTableContext } from '../hooks';

export const BottomViewTable = () => {
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  const { prefilter, activeView } = useBottomTableContext();

  const Table = EntityToTable[activeView.entity];

  if (!Table) {
    throw new Error(`Table for entity ${activeView.entity} is not defined.`);
  }

  return (
    <TablePrefilterProvider value={{ prefilter }}>
      <TableStateBagProvider tableInstance={tableInstance}>
        <Table ref={setTableInstance} />
      </TableStateBagProvider>
    </TablePrefilterProvider>
  );
};
