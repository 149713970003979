import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticSalesVolumeYearItem } from './StatisticSalesVolumeYearItem.entity';

export class StatisticSalesVolumeYear<T extends EMode = EMode.entity> {
  /** Alias: items */
  items?: StatisticSalesVolumeYearItem<T>[];
  /** Alias: start */
  start?: string;
  /** Alias: end */
  end?: string;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticSalesVolumeYear> = {
  items: {
    alias: 'items',
    entity: Entities.statisticSalesVolumeYearItem,
  },
  start: {
    alias: 'start',
  },
  end: {
    alias: 'end',
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticSalesVolumeYearEntityDefinition: EntitiyDefinition<StatisticSalesVolumeYear> =
  {
    local: {},
    remote: {
      queryName: 'statisticSalesAmountByYear',
      fragmentName: 'StatisticSalesVoumeYear',
      withPaginationWrapper: false,
      params: [
        { name: 'onlyRaInvoices', type: 'Boolean' },
        { name: 'years', type: '[Int]!' },
        { name: 'costCenterFilter', type: 'Int' },
        { name: 'useDeliveryPeriod', type: 'Boolean' },
      ],
    },
    fieldDefinitions: fields,
  };
