import React, { useCallback, useMemo } from 'react';
import { TableInstance } from 'react-table';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useCloneConvertErp } from './offers-table/hooks/use-clone-convert-erp';
import schema from './schemata/calculation-table-schema.json';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const CalculationsTable = React.forwardRef<TableInstance, IEntityTable>(
  function CalculationsTable(_props, ref) {
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Calculation,
      never
    >({
      schema: schema as never,
      defaultSort,
    });

    const template = useErpHandlersTemplate();

    const rowModifiers = useCallback(
      (value: Calculation) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { actions } = useCloneConvertErp({
      entity: Entities.calculation,
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <EntityTable
        ref={ref}
        template={template}
        {...dataTable}
        actions={{
          custom,
        }}
        prepareRowDisplayModifiers={rowModifiers}
      />
    );
  }
);
