import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

const CONTACT_DATA: Contact = {
  id: null,
  eMail: null,
};

const BUSINESSPARTNER_DATA: Customer | Supplier = {
  id: null,
  eMail: null,
};

export const useEmailContactData = (props: { to: string; cc: string }) => {
  const { to, cc } = props;

  const mailList = useMemo(() => {
    return (
      to
        ?.split(';')
        .concat(cc?.split(';') || [])
        .filter((x) => x.trim().length > 0)
        .map((x) => x.trim()) || []
    );
  }, [cc, to]);

  const customerMailRequest = useMemo(() => {
    const filter = [
      {
        eMail: {
          $in: mailList,
        },
      },
    ];

    return {
      operationName: 'GetCustomerByEmail',
      filter,
      entity: Entities.customer,
      data: BUSINESSPARTNER_DATA,
    };
  }, [mailList]);

  const customerMailData = useDataProvider<Customer>(
    customerMailRequest,
    mailList.length === 0
  );

  const supplierMailRequest = useMemo(() => {
    const filter = [
      {
        eMail: {
          $in: mailList,
        },
      },
    ];

    return {
      operationName: 'GetSupplierByEmail',
      filter,
      entity: Entities.supplier,
      data: BUSINESSPARTNER_DATA,
    };
  }, [mailList]);

  const supplierMailData = useDataProvider<Supplier>(
    supplierMailRequest,
    mailList.length === 0
  );

  const customerApRequestData = useMemo(() => {
    const filter = [
      {
        eMail: {
          $in: mailList,
        },
      },
      {
        businessPartnerType: {
          $eq: SdObjType.KUNDE,
        },
      },
    ];

    return {
      operationName: 'GetCustomerContactByEmail',
      filter,
      entity: Entities.contact,
      data: CONTACT_DATA,
    };
  }, [mailList]);

  const customerApMailData = useDataProvider<Contact>(
    customerApRequestData,
    mailList.length === 0
  );

  const supplierApRequestData = useMemo(() => {
    const filter = [
      {
        eMail: {
          $in: mailList,
        },
      },
      {
        businessPartnerType: {
          $eq: SdObjType.LIEFERANT,
        },
      },
    ];

    return {
      operationName: 'GetSupplierContactByEmail',
      filter,
      entity: Entities.contact,
      data: CONTACT_DATA,
    };
  }, [mailList]);

  const supplierApMailData = useDataProvider<Contact>(
    supplierApRequestData,
    mailList.length === 0
  );

  return {
    customerMailData,
    supplierMailData,
    customerApMailData,
    supplierApMailData,
  };
};
