import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';
import { ReportMode } from '../Enums/ReportMode.enum';
import { ReportType } from '../Enums/ReportType.enum';
import { ReportTypeOfSource } from '../Enums/ReportTypeOfSource.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputReport2<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: any;
  /** Alias: name */
  name?: any;
  /** Alias: reportType */
  reportType?: ReportType;
  /** Alias: location */
  location?: any;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: reportMode */
  reportMode?: ReportMode;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: typeOfSource */
  typeOfSource?: ReportTypeOfSource;
  /** Alias: sourceDetails */
  sourceDetails?: string;
  /** Alias: specialSourceType */
  specialSourceType?: string;
  /** Alias: deploymentId */
  deploymentId?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputReport2> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  reportType: {
    alias: 'reportType',
  },
  location: {
    alias: 'location',
  },
  objectType: {
    alias: 'objectType',
  },
  reportMode: {
    alias: 'reportMode',
  },
  customFields: {
    alias: 'customFields',
  },
  typeOfSource: {
    alias: 'typeOfSource',
  },
  sourceDetails: {
    alias: 'sourceDetails',
  },
  specialSourceType: {
    alias: 'specialSourceType',
  },
  deploymentId: {
    alias: 'deploymentId',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputReport2EntityDefinition: EntitiyDefinition<InputReport2> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputReport2',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
