import { AccesorsOf } from '@work4all/utils/lib/paths-of/paths-of';

export type NewBusinessPartnerTemplate = {
  firm1?: string;
  country?: string;
  city?: string;
  street?: string;
  postalCode?: string;
  name?: string;
  phoneNumber?: string;
  website?: string;
};

export const accessorsOfNewbusinessPartnerTemplate: Array<
  AccesorsOf<NewBusinessPartnerTemplate>
> = [
  'firm1',
  'country',
  'city',
  'street',
  'postalCode',
  'name',
  'phoneNumber',
  'website',
];

export function prepareBusinessPartnerTemplate(
  details: google.maps.places.PlaceResult
): NewBusinessPartnerTemplate {
  function findAddressComponent(type: string) {
    return details.address_components?.find((component) => {
      return component.types.includes(type);
    });
  }

  const firm1 = details.name;

  const country = findAddressComponent('country')?.short_name;
  const city = findAddressComponent('locality')?.short_name;
  const street =
    [
      findAddressComponent('route')?.short_name,
      findAddressComponent('street_number')?.short_name,
    ]
      .filter(Boolean)
      .join(' ') || undefined;

  const postalCode = findAddressComponent('postal_code')?.short_name;

  const name = [firm1, city].filter(Boolean).join(', ') || undefined;
  const phoneNumber = details.international_phone_number;
  const website = details.website;

  return {
    firm1,
    country,
    city,
    street,
    postalCode,
    name,
    phoneNumber,
    website,
  };
}
