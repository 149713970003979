import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsePreviewInputValueProps } from '../../types';

export const usePreviewInputValue = <TValue,>({
  renderPreviewValue,
  formattedValue,
  isMultiselect,
  numberOfSelectedRows,
  accessor,
  commonFields: commonInputs,
}: UsePreviewInputValueProps<TValue>) => {
  const { t } = useTranslation();

  const editValueRef = useRef(formattedValue);
  const [editValue, setValue] = useState(formattedValue);

  const previewValue = useMemo(() => {
    const renderdPreviewValue = renderPreviewValue?.({
      isMultiselect,
      numberOfSelectedRows,
    });

    if (renderdPreviewValue) {
      return renderdPreviewValue;
    }

    if (!isMultiselect || commonInputs.includes(accessor)) {
      return editValue;
    }

    return `(${t('COMMON.MULTIPLE')})`;
  }, [
    renderPreviewValue,
    isMultiselect,
    commonInputs,
    accessor,
    t,
    editValue,
    numberOfSelectedRows,
  ]);

  useEffect(() => {
    setValue(formattedValue);
  }, [formattedValue]);

  useEffect(() => {
    editValueRef.current = editValue;
  }, [editValue]);

  return { previewValue, editValueRef, setValue };
};
