import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputSalesOpportunityRatingStatus<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: ratingValue */
  ratingValue?: number;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: comment */
  comment?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputSalesOpportunityRatingStatus> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  ratingValue: {
    alias: 'ratingValue',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  comment: {
    alias: 'comment',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSalesOpportunityRatingStatusEntityDefinition: EntitiyDefinition<InputSalesOpportunityRatingStatus> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSalesOpportunityRatingStatus',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
