import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { LedgerAccount } from './LedgerAccount.entity';
import { User } from './User.entity';

export class RaPayment<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: buchungsDatum */
  buchungsDatum?: string;
  /** Alias: datev */
  datev?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: konto */
  konto?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: sachkontoCode */
  sachkontoCode?: number;
  /** Alias: sachkonto */
  ledgerAccountId?: number;
  /** Alias: summe */
  summe?: number;
  /** Alias: raCode */
  raId?: number;
  /** Alias: createdByUser */
  createdByUser?: User<T>;
  /** Alias: ledgerAccount */
  ledgerAccount?: LedgerAccount<T>;

  __typename?: string;
}

const fields: FieldDefinitions<RaPayment> = {
  id: {
    alias: 'code',
  },
  userId: {
    alias: 'benutzerCode',
  },
  buchungsDatum: {
    alias: 'buchungsDatum',
  },
  datev: {
    alias: 'datev',
  },
  date: {
    alias: 'datum',
  },
  konto: {
    alias: 'konto',
  },
  note: {
    alias: 'notiz',
  },
  sachkontoCode: {
    alias: 'sachkontoCode',
  },
  ledgerAccountId: {
    alias: 'sachkonto',
  },
  summe: {
    alias: 'summe',
  },
  raId: {
    alias: 'raCode',
  },
  createdByUser: {
    alias: 'createdByUser',
    entity: Entities.user,
  },
  ledgerAccount: {
    alias: 'ledgerAccount',
    entity: Entities.ledgerAccount,
  },
  __typename: {
    alias: '__typename',
  },
};

export const raPaymentEntityDefinition: EntitiyDefinition<RaPayment> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'RaZahlung',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'upsertRaPayment',
        args: [
          { name: 'input', type: 'InputRaZahlung!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
  },
  fieldDefinitions: fields,
};
