import { useTableStateBag } from '@work4all/components';

import { throwInDev } from '@work4all/utils';

import { createHandleColumnFilterChange } from '../../utils';
import { QUICK_COLUMN_FILTER_PICKERS_CONFIGS_BY_ENTITY } from '../constants';
import {
  QuickColumnFilterPickerConfigs,
  QuickColumnFilterPickerProps,
} from '../types';

export const QuickColumnFilterPicker = ({
  entity,
  type,
  multiple,
}: QuickColumnFilterPickerProps) => {
  const configs = QUICK_COLUMN_FILTER_PICKERS_CONFIGS_BY_ENTITY[entity]?.[type];

  if (!configs) {
    throwInDev(
      `Missing QuickColumnFilterPicker configs for entity ${entity} and type ${type} `
    );

    return null;
  }

  return <QuickColumnFilterPickerChild configs={configs} multiple={multiple} />;
};

export const QuickColumnFilterPickerChild = ({
  configs,
  multiple,
}: {
  configs: QuickColumnFilterPickerConfigs;
} & Pick<QuickColumnFilterPickerProps, 'multiple'>) => {
  const tableStateBag = useTableStateBag();

  const column = tableStateBag.columnsById[configs.id];
  const value = column?.filterValue?.value ?? null;
  const handleChange = createHandleColumnFilterChange(column);

  const Picker = configs.picker;

  return <Picker value={value} onChange={handleChange} multiple={multiple} />;
};
