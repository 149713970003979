import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IUser, MutationExtraArgs, usePermissions } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { useDialogs } from '../../../dialog-manager';
import { useLock } from '../../../hooks/object-lock';
import {
  EDIT_ENTITY_ADMIN_MAX_COUNT,
  EDIT_ENTITY_MAX_COUNT,
} from '../constants';

type Entry<T extends EMode = EMode.entity> =
  | Task<T>
  | Ticket<T>
  | Appointment<T>
  | Note<T>
  | CheckList<T>
  | VisitationReport<T>;

interface EntityPreviewProps {
  user: IUser;
  subEntityType: Entities;
  entries: Entry[];
  openPicker?: (picker) => void;
  mutate: <TInput extends object>(
    input: TInput,
    extraArgs: MutationExtraArgs<TInput, Record<string, never>>,
    options?: {
      skipOnComplete?: boolean;
    }
  ) => void;
  mutateAll?: <TInput extends object>(
    input: TInput,
    extraArgs: MutationExtraArgs<TInput, Record<string, never>>,
    options?: {
      skipOnComplete?: boolean;
    }
  ) => void;
}

export const useEntityPreview = ({
  user,
  subEntityType,
  entries,
  openPicker,
  mutate,
  mutateAll,
}: EntityPreviewProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dialogs = useDialogs();
  const {
    locked,
    loading,
    lock,
    unlock,
    user: lockedByUser,
  } = useLock({
    subEntityIds: entries?.map((t) => String(t.id)),
    subEntityType,
  });

  const { untypedPermissions } = usePermissions();
  const canEditAllSelected = useMemo(
    () =>
      entries.every((data) => untypedPermissions(subEntityType).canEdit(data)),
    [entries, untypedPermissions, subEntityType]
  );

  const isMultiselect = entries.length > 1;

  const commonFields = useDeepMemo(
    () =>
      Object.keys(
        entries.reduce(
          (acc, obj) => {
            for (const key in obj) {
              if (
                !Object.prototype.hasOwnProperty.call(acc, key) ||
                obj[key] !== entries[0][key]
              ) {
                delete acc[key];
                continue;
              }
              if (!key.startsWith('_')) acc[key] = obj[key];
            }
            return acc;
          },
          { ...entries[0] }
        )
      ),
    [entries]
  );

  useEffect(() => {
    return () => {
      unlock();
    };
    // unlock as a dependency will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activePicker, setActivePicker] = useState(null);

  useEffect(() => {
    if (!canEditAllSelected && activePicker !== null) {
      enqueueSnackbar(
        `${t('MASK.EDITING_NOT_POSSIBLE')} | ${t('COMMON.NO_EDIT_RIGHT')}`,
        {
          variant: 'warning',
        }
      );
      return;
    }
    if (!locked && !loading && activePicker !== null) {
      openPicker?.(activePicker);
    }

    if (!loading && locked && activePicker !== null) {
      enqueueSnackbar(
        `${t('MASK.EDITING_NOT_POSSIBLE')} | ${t('MASK.MASK_OPENED_BY', {
          userName: lockedByUser.displayName,
        })}`,
        {
          variant: 'warning',
        }
      );
      setActivePicker(null);
    }
  }, [
    loading,
    locked,
    activePicker,
    setActivePicker,
    canEditAllSelected,
    enqueueSnackbar,
    t,
    openPicker,
    lockedByUser?.displayName,
  ]);

  const handleLock = useCallback(
    (picker) => {
      if (!loading) {
        lock();
        setActivePicker(picker);
      }
    },
    [loading, lock]
  );

  const onPopoverClose = useCallback(() => {
    unlock();
    setActivePicker(null);
  }, [unlock, setActivePicker]);

  const onEdit = async (field, otherArgs?) => {
    const maxCount = user.isMaster
      ? EDIT_ENTITY_ADMIN_MAX_COUNT
      : EDIT_ENTITY_MAX_COUNT;

    if (entries.length > maxCount) {
      await dialogs.alert({
        title: t('ALERTS.EDIT.NOT_ALLOWED'),
        description: t('ALERTS.EDIT.MAX_WARNING', {
          count: maxCount,
        }),
      });
      unlock();
      return;
    } else if (isMultiselect) {
      const confirmed = await dialogs.confirm({
        title: t('ALERTS.EDIT.MULTIPLE_TITLE'),
        description: t('ALERTS.EDIT.MULTIPLE_WARNING', {
          count: entries.length,
        }),
        cancelLabel: 'Abbrechen',
        confirmLabel: 'Ok',
      });
      if (!confirmed) {
        unlock();
        return;
      }
    }

    if (mutateAll) {
      await mutateAll(field, otherArgs);
      unlock();
      return;
    }

    await Promise.all(
      entries.map(async (entry) => {
        const update = {
          id: entry.id,
          ...field,
        };
        await mutate(update, otherArgs);
      })
    )
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        unlock();
      });
  };

  return {
    user,
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    unlock,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  };
};
