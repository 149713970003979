const isRtf = (input: string) => {
  if (!input) return false;

  const cleanInput = input.split('\n').join('').split('\r').join('');
  return (
    (cleanInput.startsWith('{\\rtf') || cleanInput.startsWith('{\rtf')) &&
    cleanInput.endsWith('}')
  );
};

export const rtf = { isRtf };
