import styles from './Bookings.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ReactComponent as ColumnsIcon } from '@work4all/assets/icons/columns.svg';
import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { useDataProvider } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatCurrency } from '@work4all/utils';

import { ColumnsVisibility } from '../../../../../../../../../../components/data-tables/table/components/toolbar/components/columns-visibility/ColumnsVisibility';
import { TableSettingsOverlay } from '../../../../../../../../../../components/data-tables/table/components/toolbar/components/TableSettingsOverlay';
import { settings, useSetting } from '../../../../../../../../../../settings';
import { DEFAULT_VISIBLE_INCOMING_INVOICE_COLUMNS } from '../../../../../../../../../../settings/settings';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { Collapse } from '../../../../../../../components';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { withAutoDisable } from '../../../../../../../utils/with-auto-disable';
import { useResetColumns } from '../../../../../../erp/components/tab-panels/positions/components/hooks/use-reset-columns';
import { CurrencyExchangeInfoContext } from '../../../../../currency-exchange-info-context';
import { ReViewModelFormValue } from '../../../../../types';
import { useShadowReObjectApiContext } from '../../../../../use-re-shadow-object-api/use-shadow-re-object-api-context';

import { ErpBookingTable } from './components/ErpBookingsTable/ErpBookingsTable';

export function Bookings({ disabled = false }: { disabled?: boolean }) {
  const { t } = useTranslation();

  const { watch } = useFormContextPlus<ReViewModelFormValue>();

  const bookings = watch('bookings');
  const defaultLedgerAccountNumber = watch('supplier.standardAccount');

  const shadowObjectApi = useShadowReObjectApiContext();

  const currency = watch('currency');

  const [selectedBookings, setSelectedBookings] = useState<number[]>([]);

  const ids = bookings?.map((booking) => booking.id) ?? [];
  const existingSelectedBookings = selectedBookings.filter((selectedBooking) =>
    ids.includes(selectedBooking)
  );

  const defaultLedgerAccount = useDefaultLedgerAccount({
    ledgerAccountNumber: defaultLedgerAccountNumber,
  });
  const [showSettingsOverlay, setSettingsOverlay] = useState(false);

  const columnsPopoverState = usePopoverState();
  const openColumnsPopover = columnsPopoverState.handleClick;

  const headerBar = (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
      justifyContent="space-between"
      alignItems="center"
      flex={1}
    >
      {!disabled && (
        <Stack direction="row" spacing={1}>
          <Tooltip title={t('MASK.REMOVE')}>
            <IconButton
              type="button"
              disabled={existingSelectedBookings.filter(Boolean).length === 0}
              size="large"
              color="primary"
              onClick={() => {
                shadowObjectApi.removeBookings(
                  existingSelectedBookings.filter((id) => id !== 0)
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      <div className={styles.actionsWrapper}>
        <CurrencyConversionWarning currency={currency} />
        <IconButton
          color="primary"
          onClick={(event) => {
            return openColumnsPopover(event);
          }}
        >
          <ColumnsIcon />
        </IconButton>
        <IconButton color="primary" onClick={() => setSettingsOverlay(true)}>
          <SettingsIcon />
        </IconButton>
      </div>
    </Stack>
  );

  const tableInstanceRef = useRef<TableInstance>();
  const columnSettings = useSetting(settings.incomingInvoicePositionsColumn());
  const handleVisibilityChange = useCallback(
    (columns: string[]) => {
      if (!columns.filter((col) => SELECTION_COLUMN_ID !== col).length) return;
      columnSettings.set({
        ...columnSettings.value,
        order: columns,
        visibility: columns,
      });
    },
    [columnSettings]
  );

  const columnsVisibilityManager = useColumnVisibilityManager({
    tableInstanceRef,
    onVisibilityChange: handleVisibilityChange,
  });

  const resetColumns = useResetColumns({
    columnSettings,
    tableInstanceRef,
    defaultColumns: DEFAULT_VISIBLE_INCOMING_INVOICE_COLUMNS,
  });

  return (
    <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
      <ColumnsVisibility
        popoverState={columnsPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns ?? []}
        onResetColumns={resetColumns}
      />
      <TableSettingsOverlay
        settingsKey={'INCOMING_INVOICE'}
        entityType={Entities.reViewModel}
        isOpen={showSettingsOverlay}
        close={() => setSettingsOverlay(false)}
      />
      <Collapse
        className={styles.bookings}
        title={t('MASK.BOOKINGS')}
        classes={{
          root: styles.flex1,
          wrapperInner: styles.flex1,
        }}
        defaultOpen
        headerBar={headerBar}
      >
        <div className={clsx(styles.tableWrapper, styles.flex1)}>
          <ErpBookingTable
            tableInstanceRef={tableInstanceRef}
            disabled={disabled}
            defaultLedgerAccount={defaultLedgerAccount}
            bookings={bookings ?? []}
            onSelectedPositionIdsChange={setSelectedBookings}
            onAddBooking={shadowObjectApi.addBooking}
            onEditBooking={shadowObjectApi.editBooking}
          />
        </div>
      </Collapse>
    </ColumnVisibilityContext.Provider>
  );
}

export const BookingsWithAutoDisable = withAutoDisable(Bookings);

interface ICurrencyConversionWarningProps {
  currency: Currency | null;
}

function CurrencyConversionWarning(props: ICurrencyConversionWarningProps) {
  const { currency } = props;

  const { t } = useTranslation();

  const exchangeInfo = useContext(CurrencyExchangeInfoContext);

  const mask = useMaskContext();

  if (!currency || !exchangeInfo) return null;

  const { defaultCurrency } = exchangeInfo;

  if (currency.id === defaultCurrency.id) return null;

  const formattedRate = exchangeInfo.getFormattedRate(currency.id);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {mask.isCreateMode ? (
        <WarningIcon color="warning" fontSize="medium" />
      ) : (
        <InfoIcon fontSize="medium" color="primary" />
      )}

      <Typography variant="caption">
        <Trans
          t={t}
          i18nKey={`OTHER.CURRENCY_CONVERSION_${
            mask.isCreateMode ? 'WARNING' : 'INFO'
          }`}
          values={{
            original: formatCurrency(currency, t),
            converted: formatCurrency(defaultCurrency, t),
            rate: formattedRate,
          }}
          components={{
            1: <Typography variant="caption" fontWeight={900} />,
          }}
        />
      </Typography>
    </Stack>
  );
}

function useDefaultLedgerAccount({
  ledgerAccountNumber,
}: {
  ledgerAccountNumber: number;
}): LedgerAccount {
  const request = useMemo((): DataRequest => {
    const data: LedgerAccount = {
      id: null,
      name: null,
      number: null,
      taxKeyValue: null,
    };
    return {
      entity: Entities.ledgerAccount,
      data,
      // Filtering by the number doesn't do anything atm.
      // vars: { nummer: ledgerAccountNumber },
    };
  }, []);

  const query = useDataProvider<LedgerAccount>(request, false, 9999);

  const found = useMemo(() => {
    return query.data?.find(
      (account) => account.number === ledgerAccountNumber
    );
  }, [query.data, ledgerAccountNumber]);

  return found ?? null;
}
