import { useMemo } from 'react';

import { useDocumentClasses } from '@work4all/data/lib/hooks/document-classes';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DropProps } from './DropOverlay';

type Config = Omit<DropProps, 'onDrop' | 'entityVariant'> & {
  variants?: string[];
  allowedOpenedEntities?: Entities[];
};

type Props = {
  openedEntity: Entities;
};

export const useDropOverlayItems = ({
  openedEntity,
}: Props): Omit<DropProps, 'onDrop'>[] => {
  const documentClasses = useDocumentClasses();
  const uniqueDocumentClassNames = useMemo(
    () => [...new Set(documentClasses.map((dc) => dc.name))],
    [documentClasses]
  );

  const configs: Config[] = useMemo(() => {
    return [
      {
        entity: Entities.document,
        variants: uniqueDocumentClassNames,
      },
      {
        entity: Entities.eMail,
      },
      {
        entity: Entities.reViewModel,
        allowedOpenedEntities: [Entities.supplier, Entities.project],
      },
    ];
  }, [uniqueDocumentClassNames]);

  const items: Omit<DropProps, 'onDrop'>[] = useMemo(() => {
    return configs
      .filter((config) => {
        return (
          !config.allowedOpenedEntities ||
          config.allowedOpenedEntities.includes(openedEntity)
        );
      })
      .flatMap(({ entity, disabled = false, variants = [] }) => {
        const entityItem = {
          entity,
          disabled,
        };

        const entityVariantsItems = variants.map((entityVariant) => {
          return { ...entityItem, entityVariant };
        });

        return [entityItem, ...entityVariantsItems];
      });
  }, [configs, openedEntity]);

  return items;
};
