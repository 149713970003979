import { useCallback, useMemo } from 'react';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { SortedListItem } from '../components/SortedListItem';
import { usePickerSettings } from '../hooks/use-picker-settings';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type ICustomerPickerProps<TMultiple extends boolean> = IPickerProps<
  Customer,
  TMultiple
>;

export function CustomerPicker<TMultiple extends boolean>(
  props: ICustomerPickerProps<TMultiple>
) {
  const { data, onChange, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const normalizeValue = useCallback(
    (item: Customer | BusinessPartner, __typename: string): Customer => {
      if (!props.filterConfig) return item as Customer;

      return 'data' in item
        ? ({ ...item.data, __typename } as Customer)
        : ({ ...item, __typename: item.__typename || __typename } as Customer);
    },
    [props.filterConfig]
  );

  const { sortedPickerEntities } = usePickerSettings(Entities.customer);

  const handlePickedItems = useCallback(
    (selectedItems: Selection<Customer | BusinessPartner, TMultiple>) => {
      const newItems = Array.isArray(selectedItems)
        ? selectedItems.map((selectedItem) =>
            normalizeValue(selectedItem, 'Kunde')
          )
        : normalizeValue(selectedItems, 'Kunde');
      onChange(newItems as Selection<Customer, TMultiple>);
    },
    [normalizeValue, onChange]
  );

  const renderItem = (item: Customer) => {
    const name = normalizeValue(item, 'Kunde').name;
    return (
      <SortedListItem
        sortedBy={sortedPickerEntities as 'name' | 'number'}
        name={name}
        number={item.number}
        isInactive={item.extinct === -1}
        website={item?.website}
      />
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.customer}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy={sortedPickerEntities}
      onChange={handlePickedItems}
      useSearchHistory
      favorites
      {...rest}
      renderItemContent={renderItem}
    />
  );
}

const FIELDS: Customer = {
  id: null,
  name: null,
  website: null,
  number: null,
  extinct: null,
  mainContact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
  },
  isPrivateCustomer: null,
};
