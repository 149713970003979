import styles from './ArticleTextInputs.module.scss';

import { Divider, Tab, Tabs } from '@mui/material';
import produce from 'immer';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ILabeledInput } from '@work4all/components/lib/input/labeled-input/LabeledInput';

import { useDataProvider } from '@work4all/data';

import { BaseDataLanguage } from '@work4all/models/lib/Classes/BaseDataLanguage.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../locked-inputs';
import { ArticleMaskFormValue } from '../../../types';

export function ArticleTextInputs() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();

  const languagesRequest = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetBaseDataLanguages',
      entity: Entities.baseDataLanguage,
      data: {
        id: null,
        name: null,
      } satisfies BaseDataLanguage<EMode.query>,
      completeDataResponse: true,
    };
  }, []);

  const languagesResponse = useDataProvider<BaseDataLanguage>(languagesRequest);

  const languages = useMemo(() => {
    return languagesResponse.data.slice().sort((a, b) => a.id - b.id);
  }, [languagesResponse.data]);

  const [language, setLanguage] = useState(0);

  function renderTextInputs() {
    const textareaMultilineProps: ILabeledInput = {
      className: styles.textarea,
      multiline: true,
      inputProps: { style: { resize: 'none' } },
    };

    switch (language) {
      case -1: {
        const label = t('MASK.ARTICLE.FIELDS.internalText');

        return (
          <ControllerPlus
            key="internalText"
            control={form.control}
            name="internalText"
            render={({ field }) => {
              return (
                <LabeledInput
                  label={label}
                  {...field}
                  {...textareaMultilineProps}
                />
              );
            }}
          />
        );
      }

      case 0: {
        const labelShort = t('MASK.ARTICLE.FIELDS.shortText');
        const labelLong = t('MASK.ARTICLE.FIELDS.longText');

        return (
          <Fragment key="defaultText">
            <ControllerPlus
              control={form.control}
              name="name"
              render={({ field }) => {
                return <LabeledInput label={labelShort} {...field} />;
              }}
            />
            <ControllerPlus
              control={form.control}
              name="longtext"
              render={({ field }) => {
                return (
                  <LabeledInput
                    label={labelLong}
                    {...field}
                    {...textareaMultilineProps}
                  />
                );
              }}
            />
          </Fragment>
        );
      }

      default: {
        const textList = form.watch('articleTextList');
        const text = textList?.find((text) => text.sprachCode === language);

        const name = text?.kurztext ?? '';
        const longtext = text?.longtext ?? '';

        const makeHandleChange = (property: 'kurztext' | 'longtext') => {
          return (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            const newValue = produce(textList ?? [], (draft) => {
              let text = draft.find((text) => text.sprachCode === language);

              if (!text) {
                text = {
                  sprachCode: language,
                  kurztext: '',
                  longtext: '',
                };

                draft.push(text);
              }

              text[property] = value;
            });

            form.setValue('articleTextList', newValue, { shouldDirty: true });
          };
        };

        const labelShort = t('MASK.ARTICLE.FIELDS.shortText');
        const labelLong = t('MASK.ARTICLE.FIELDS.longText');

        return (
          <Fragment key={`customText-${language}`}>
            <LabeledInput
              label={labelShort}
              value={name}
              onChange={makeHandleChange('kurztext')}
            />
            <LabeledInput
              label={labelLong}
              value={longtext}
              onChange={makeHandleChange('longtext')}
              {...textareaMultilineProps}
            />
          </Fragment>
        );
      }
    }
  }

  return (
    <>
      <Tabs value={language} onChange={(_, value) => setLanguage(value)}>
        {languages.map((language) => {
          const { id, name } = language;

          const translatedName = t(`LANGUAGE.${name}`, {
            defaultValue: language.name,
          });

          return <Tab key={id} value={id} label={translatedName} />;
        })}

        <Divider orientation="vertical" flexItem />

        <Tab value={-1} label={t('MASK.ARTICLE.FIELDS.internalText')} />
      </Tabs>

      {renderTextInputs()}
    </>
  );
}
