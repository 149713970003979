import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputAttachementAdd<T extends EMode = EMode.entity> {
  /** Alias: tempFileId */
  tempFileId?: string;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputAttachementAdd> = {
  tempFileId: {
    alias: 'tempFileId',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAttachementAddEntityDefinition: EntitiyDefinition<InputAttachementAdd> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputAttachementAdd',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
