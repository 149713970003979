import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTablePrefilterContext } from '@work4all/components/lib/components/table/TablePrefilterProvider';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { mapAppointmentToEmailParams } from '../../../containers/mask-overlays/mask-overlay/views/appointment/utils/mapAppointmentToEmailParams';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/appointment-table-schema.json';

import { useEmailTemplate } from './hooks/use-email-template';
import { useFixedAppointmentsData } from './hooks/use-fixed-appointments-data';

const APPOINTMENT_FORCE_REQUEST_FIELDS: Appointment<EMode.query> = {
  fromAbsolute: null,
  toAbsolute: null,
  appointmentAttendeeList: [
    {
      id: null,
      ressource: {
        id: null,
        eMail: null,
        displayName: null,
        loginName: null,
      },
      user: {
        id: null,
        eMail: null,
        displayName: null,
        loginName: null,
      },
      contact: {
        id: null,
        eMail: null,
        displayName: null,
      },
      businessPartner: {
        id: null,
        data: {
          customer: { id: null, eMail: null },
          supplier: { id: null, eMail: null },
        },
      },
    },
  ],
  contact: {
    id: null,
    displayName: null,
    eMail: null,
  },
  startDate: null,
  endDate: null,
  isWholeDay: null,
  city: null,
  title: null,
  note: null,
  contract: {
    id: null,
    contractNumber: null,
  },
  deliveryNote: {
    id: null,
    number: null,
  },
};

const defaultSort = [
  {
    field: nameof<Appointment>('startDate'),
    direction: SortDirection.DESCENDING,
  },
];

export const AppointmentsTable = React.forwardRef<TableInstance, IEntityTable>(
  function AppointmentsTable(_props, ref) {
    const { prefilter } = useTablePrefilterContext();

    const dataTable = useEntityDataTable<Appointment, never>({
      schema: schema as never,
      prefilter,
      defaultSort,
      forceRequestFields: APPOINTMENT_FORCE_REQUEST_FIELDS,
    });

    // Fix appointment start and end dates for full day appointments.
    const data = useFixedAppointmentsData(dataTable.data);
    const { t } = useTranslation();

    const getEmailParams = useCallback(
      async (_template, context: Appointment) => {
        // HACK: The mobile preview buttons do not know what is selected and
        // cannot pass the context. This simply doesn't work and needs to be
        // changed.
        context ??= dataTable.selectedEntity;

        const entityTemplate = {
          entity: Entities.appointment,
          id: context.id,
        };

        return {
          entityTemplate,
          params: mapAppointmentToEmailParams(context, {
            hourKey: t('COMMON.CLOCK'),
            wholeDay: t('COMMON.WHOLEDAYEVENT'),
          }),
        };
      },
      [dataTable.selectedEntity, t]
    );

    const { action, component } = useEmailTemplate({
      getEmailParams,
      kind: [
        EMailTemplateKind.KEINE,
        EMailTemplateKind.APPOINTMENT_NOTIFICATION,
      ],
      mainKind: EMailTemplateKind.APPOINTMENT_NOTIFICATION,
      noTemplate: true,
    });

    return (
      <React.Fragment>
        {component}
        <EntityTable
          ref={ref}
          {...dataTable}
          data={data}
          actions={{
            custom: {
              right: [action],
            },
          }}
        />
      </React.Fragment>
    );
  }
);
