import { MbscEventConnection } from '@mobiscroll/react';
import { useMemo } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectStepLinkType } from '@work4all/models/lib/Enums/ProjectStepLinkType.enum';

type MbscEventConnectionType = 'fs' | 'sf' | 'ss' | 'ff';

const linkType: Record<ProjectStepLinkType, MbscEventConnectionType> = {
  [ProjectStepLinkType.FINISH_TO_FINISH]: 'ff',
  [ProjectStepLinkType.FINISH_TO_START]: 'fs',
  [ProjectStepLinkType.OLD_FINISH_TO_START]: 'fs',
  [ProjectStepLinkType.START_TO_FINISH]: 'sf',
  [ProjectStepLinkType.START_TO_START]: 'ss',
};

export const useConnections = (props: { projectList: Project[] }) => {
  const { projectList } = props;

  return useMemo<MbscEventConnection[]>(() => {
    const result: MbscEventConnection[] = [];

    if (!projectList?.length) return result;

    for (const project of projectList) {
      if (project.projectProcessList?.length) {
        for (const process of project.projectProcessList) {
          if (process.projectStepLinksOutgoing?.length) {
            for (const link of process.projectStepLinksOutgoing) {
              result.push({
                from: process.id,
                to:
                  process.id === link.projectProcess1Id
                    ? link.projectProcess2Id
                    : link.projectProcess1Id,
                type: linkType[link.linkType] as MbscEventConnectionType,
                arrow: 'to',
                color: 'var(--ui06)',
              });
            }
          }
        }
      }
    }

    return result;
  }, [projectList]);
};
