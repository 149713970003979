import { Tooltip, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker';
import { EntityLike, IPickerProps } from './types';

interface NamedEntity extends EntityLike {
  name?: string;
}

export interface IEntityPicker<TEntity, TMultiple extends boolean>
  extends IPickerProps<TEntity, TMultiple> {
  sortBy?: string;
  filterBy?: string | string[];
  entity: Entities;
  operationName?: string;
  overrides?: {
    data?: TEntity;
    completeDataResponse?: boolean;
    freeSolo?: boolean;
    displayProperty?: keyof TEntity;
    vars?: Record<string, string | number | boolean>;
  };
}

/**
 * Entity picker to unify most of code in all pickers we have (at least the new ones)
 * For now it contains implementation of fixedDataSet.
 * TODO: https://work4all.atlassian.net/browse/WW-4012
 */
export function EntityPicker<
  TEntity extends NamedEntity,
  TMultiple extends boolean
>(props: IEntityPicker<TEntity, TMultiple>) {
  const {
    data,
    sortBy = 'name',
    filterBy = 'name',
    operationName,
    entity,
    ...rest
  } = props;
  const overrides = useRef(props.overrides || {}).current;

  const [searchTerm, setSearchTerm] = useState('');

  const allFields = useMemo(() => {
    return overrides.data ? overrides.data : { ...FIELDS, ...data };
  }, [data, overrides]);

  const completeDataResponse =
    overrides.completeDataResponse !== undefined
      ? overrides.completeDataResponse
      : true;

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity,
      completeDataResponse,
      operationName,
      filter: props?.prefilter,
      vars: overrides?.vars,
    };
  }, [
    allFields,
    entity,
    operationName,
    overrides?.vars,
    props?.prefilter,
    completeDataResponse,
  ]);

  const response = useDataProvider<TEntity>(request);

  const displayProperty = useMemo(() => {
    return overrides.displayProperty ? overrides.displayProperty : 'name';
  }, [overrides.displayProperty]);

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const completeDataSourceResponse = useMemo(() => {
    if (!completeDataResponse) return response;
    return { ...response, total: response.data.length };
  }, [response, completeDataResponse]);

  const renderItem = (entity: TEntity) => {
    const label = `${entity[displayProperty]}`;
    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  function transformResponse<T>(response: IResponse<T>): IResponse<TEntity> {
    let data = response.data as unknown as TEntity[];
    let offset = 0;
    if (overrides.freeSolo) {
      const exist = data.find((x) => `${x[displayProperty]}` === searchTerm);
      if (!exist) {
        data = [
          ...data,
          {
            id: -1,
            [displayProperty]: searchTerm,
          } as unknown as TEntity,
        ];
        offset++;
      }
    }
    return {
      ...response,
      data,
      total: response.total + offset,
    };
  }

  return (
    <ListEntityPicker<TEntity, TMultiple>
      entity={entity}
      data={allFields}
      filterBy={filterBy}
      sortBy={sortBy}
      {...rest}
      fixedDataSet={
        completeDataResponse ? completeDataSourceResponse : undefined
      }
      transformResponse={transformResponse}
      renderItemContent={renderItem}
      onSearchValueChange={setSearchTerm}
      completeDataResponse={completeDataResponse}
    />
  );
}

const FIELDS = {
  id: null,
  name: null,
};
