import React, { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ArticleGroupPicker,
  EntityGroupPickerProps,
} from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';

import { reactRefSetter } from '@work4all/utils';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<EntityGroupPickerProps, 'entity' | 'multiple'> {
  error?: string;
  label: string;
  disabled?: boolean;
}

export const ArticleGroupPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const wrapperRef = useRef();

    const { label, disabled, error, ...pickerProps } = props;
    const { value } = pickerProps;

    const displayValue = value && value.length > 0 ? value[0].name : '';

    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          disabled={disabled}
          picker={<ArticleGroupPicker {...pickerProps} multiple={false} />}
        >
          <PickerTargetButton
            label={label}
            error={error}
            disabled={disabled}
            value={displayValue}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
