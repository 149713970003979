import styles from './InfoCards.module.scss';

import React from 'react';

export type IInfoCardsProps = {
  /**
   * @default 2
   */
  columns?: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export function InfoCards(props: IInfoCardsProps) {
  const { columns, children, style } = props;

  return (
    <div
      className={styles['info-cards']}
      style={{ '--columns': columns, ...style }}
    >
      {children}
    </div>
  );
}
