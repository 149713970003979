import { Tooltip, Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { LedgerAccountPicker } from '@work4all/components/lib/components/entity-picker/ledger-account-picker/LedgerAccountPicker';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';

import { PickerTargetButton } from './PickerTargetButton';

const createPrefilter = (ids: number[]) => [
  {
    kind: {
      $in: ids,
    },
  },
  {
    disabled: {
      $eq: false,
    },
  },
];

/**
 * 1 mean Anlage- und Kapitalkonten
 * 3 Abgrenzungskonten
 * 4 Betriebliche Aufwendungen
 * 6 Erlöskonten
 * 10 Sachkonto -> Niya asked to add it
 */
const LEDGER_ARRAY_OF_KIND_ID = [3, 6];
export const LEDGER_PREFILTER = createPrefilter(LEDGER_ARRAY_OF_KIND_ID);
const COST_ARRAY_OF_KIND_ID = [1, 3, 4, 10];
const COST_PREFILTER = createPrefilter(COST_ARRAY_OF_KIND_ID);

export function LedgerAccountCell(props: {
  disabled?: boolean;
  value: LedgerAccount;
  onChange: (value: LedgerAccount | null) => void;
  label?: (value: LedgerAccount | null) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  data?: LedgerAccount;
  overridePrefilter?: unknown[];
  hideTooltip?: boolean;
  autoFocus?: boolean;
  alignValue?: 'right' | 'left';
}) {
  const {
    disabled = false,
    value,
    onChange,
    label,
    className,
    hover = false,
    style,
    data,
    overridePrefilter,
    hideTooltip,
    autoFocus,
    alignValue = 'left',
  } = props;

  const popoverRef = useRef<EntityPickerPopover>(null);

  const resultingLabel = label ? (
    <div style={{ color: 'var(--text01)', textAlign: alignValue }}>
      {label(value)}
    </div>
  ) : value ? (
    <>
      <Typography component="span" variant="body2" color="text.tertiary">
        {value.number}
        {value.number && value.name ? ' | ' : ''}
      </Typography>
      <Typography component="span" variant="body2" color={'var(--text01)'}>
        {value.name}
      </Typography>
    </>
  ) : null;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <LedgerAccountPicker
          value={value}
          onChange={onChange}
          multiple={false}
          prefilter={overridePrefilter ?? COST_PREFILTER}
          data={data}
        />
      }
    >
      <Tooltip title={hideTooltip ? '' : value?.name ?? ''}>
        <PickerTargetButton
          disabled={disabled}
          style={style}
          hover={hover}
          className={className}
          autoFocus={autoFocus}
        >
          {resultingLabel}
        </PickerTargetButton>
      </Tooltip>
    </EntityPickerPopover>
  );
}
