import { useTranslation } from 'react-i18next';

import { checkModuleRight, useModuleRights, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { ServiceContract } from '@work4all/models/lib/Classes/ServiceContract.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';
import { ObjectProtectionState } from '@work4all/models/lib/Enums/ObjectProtectionState.enum';

import { dsiplyItemsFilter } from '../utils/display-items-filter';

import { customerDisplayItems } from './consts';
import { FileInfoPanelItem, FileInfoPanelProps } from './FileInfoPanelItem';
import {
  FilePreviewPanel,
  FilePreviewPanelBaseProps,
} from './FilePreviewPanel';
import { IFileInfoPanelItems } from './types';

export interface ICustomerFileInfoPanelProps
  extends FileInfoPanelProps,
    FilePreviewPanelBaseProps {
  customer: Customer;
  activeServiceContractList: ServiceContract[];
  openSalesOpportunityList: SalesOpportunities[];
  loading?: boolean;
}

export function CustomerFileInfoPanel(props: ICustomerFileInfoPanelProps) {
  const {
    customer,
    onEdit,
    onSettings,
    onCloseClick,
    displayItems = customerDisplayItems,
    activeServiceContractList,
    openSalesOpportunityList,
    loading,
    onVisibilityToggle,
  } = props;

  const { t } = useTranslation();
  const entity = Entities.customer;

  const { rights } = useModuleRights();
  const user = useUser();

  const resultingDisplayItems = displayItems.filter((item) => {
    if (item === IFileInfoPanelItems.OpenDuePositions) {
      if (
        !checkModuleRight(
          rights,
          ModuleAccessRightType.FEATURE_ORDER_MANAGEMENT_MODULE
        ) ||
        !checkUserRight(
          UserRights.OffenePostenKundenEinsehen,
          user.benutzerRechte
        )
      ) {
        return false;
      }
    }
    if (item === IFileInfoPanelItems.SubscriptionContract) {
      if (
        !checkModuleRight(
          rights,
          ModuleAccessRightType.FEATURE_MAINTENANCE_CONTRACT_MODULE
        ) ||
        !checkUserRight(UserRights.WartungsmodulNutzen, user.benutzerRechte)
      ) {
        return false;
      }
    }

    return true;
  });

  return (
    <FilePreviewPanel
      entity={entity}
      title={customer?.name}
      website={customer?.website}
      onEdit={
        customer?.objectGroupProtectionState !== ObjectProtectionState.LOCKED
          ? onEdit
          : undefined
      }
      onVisibilityToggle={onVisibilityToggle}
      onSettings={onSettings}
      onCloseClick={onCloseClick}
      displayItems={dsiplyItemsFilter(customer, resultingDisplayItems)}
      renderItem={(id) => (
        <FileInfoPanelItem
          {...props}
          key={id}
          item={id}
          entity={entity}
          partner={customer}
          salesOpportunityList={openSalesOpportunityList}
          serviceContractList={activeServiceContractList}
          loading={loading}
        />
      )}
      warning={
        customer?.extinct === -1
          ? t('FILES_PREVIEW.CLOSED_WARNING.CUSTOMER')
          : undefined
      }
    />
  );
}
