import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

export const formatters = {
  dateSimple2year: (input: string) =>
    formatDateString(input, DateFormatPreset.DATE_SIMPLE_2YEAR),
  dateTimeSimple: (input: string) =>
    formatDateString(input, DateFormatPreset.DATE_TIME_SIMPLE),
};
