import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { ErpObjectConversionPositionDetails } from './ErpObjectConversionPositionDetails.entity';
import { ErpObjectConversionRequestOptions } from './ErpObjectConversionRequestOptions.entity';

export class ErpObjectConversionRequest<T extends EMode = EMode.entity> {
  /** Alias: sourceBzObjectType */
  sourceBzObjectType?: BzObjType;
  /** Alias: sourceBzObjectCodes */
  sourceBzObjectCodes?: number[];
  /** Alias: targetBzObjectType */
  targetBzObjectType?: BzObjType;
  /** Alias: newTargetSdObjType */
  newTargetSdObjType?: SdObjType;
  /** Alias: newTargetSdObjMemberCode */
  newTargetSdObjMemberCode?: number;
  /** Alias: positionCodes */
  positionCodes?: number[];
  /** Alias: options */
  options?: ErpObjectConversionRequestOptions<T>;
  /** Alias: targetNewName */
  targetNewName?: string;
  /** Alias: positionDetails */
  positionDetails?: ErpObjectConversionPositionDetails<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ErpObjectConversionRequest> = {
  sourceBzObjectType: {
    alias: 'sourceBzObjectType',
  },
  sourceBzObjectCodes: {
    alias: 'sourceBzObjectCodes',
  },
  targetBzObjectType: {
    alias: 'targetBzObjectType',
  },
  newTargetSdObjType: {
    alias: 'newTargetSdObjType',
  },
  newTargetSdObjMemberCode: {
    alias: 'newTargetSdObjMemberCode',
  },
  positionCodes: {
    alias: 'positionCodes',
  },
  options: {
    alias: 'options',
  },
  targetNewName: {
    alias: 'targetNewName',
  },
  positionDetails: {
    alias: 'positionDetails',
  },
  __typename: {
    alias: '__typename',
  },
};

export const erpObjectConversionRequestEntityDefinition: EntitiyDefinition<ErpObjectConversionRequest> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ErpObjectConversionRequest',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
