import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputArticleSetLedgerAccountsAssignmentRelation } from './InputArticleSetLedgerAccountsAssignmentRelation.entity';

export class InputArticleLedgerAccountsAssignmentsRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: removeByTaxGroupCode */
  removeByTaxGroupCode?: number[];
  /** Alias: set */
  set?: InputArticleSetLedgerAccountsAssignmentRelation<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleLedgerAccountsAssignmentsRelation> =
  {
    removeByTaxGroupCode: {
      alias: 'removeByTaxGroupCode',
    },
    set: {
      alias: 'set',
    },
    __typename: {
      alias: '__typename',
    },
  };

export const inputArticleLedgerAccountsAssignmentsRelationEntityDefinition: EntitiyDefinition<InputArticleLedgerAccountsAssignmentsRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleLedgerAccountsAssignmentsRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
