import { IconButton } from '@mui/material';

import { ReactComponent as CheckBoxOutline } from '@work4all/assets/icons/checkbox-outline.svg';

import { useOrganisationAreaSectionMultiselectability } from '../hooks';
import { OrganisationAreaSectionMultiselectActionProps } from '../types';

const MULTISELECTABLE_COLOR_FILTER =
  'brightness(0) saturate(100%) invert(56%) sepia(76%) saturate(937%) hue-rotate(181deg) brightness(91%) contrast(92%)';

export const OrganisationAreaSectionMultiselectAction = ({
  section,
}: OrganisationAreaSectionMultiselectActionProps) => {
  const { isMultiselectable, toggleMultiselectability } =
    useOrganisationAreaSectionMultiselectability();

  return (
    <IconButton
      onClick={() => {
        toggleMultiselectability(section);
      }}
    >
      <CheckBoxOutline
        width={20}
        filter={
          isMultiselectable(section) ? MULTISELECTABLE_COLOR_FILTER : undefined
        }
      />
    </IconButton>
  );
};
