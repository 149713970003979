import { Lock } from '@mui/icons-material';
import { Box } from '@mui/material';

export const ObjectProtectionOverlay = () => {
  return (
    <Box
      position="absolute"
      p="1rem"
      height="100%"
      width="100%"
      zIndex={10000}
      style={{ opacity: 0.25, pointerEvents: 'none' }}
    >
      <Lock style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};
