import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export const useProjectProcessKind = (): { id: string; name: string }[] => {
  const { t } = useTranslation();
  const dataSet = useMemo(() => {
    return [
      {
        id: ProjectProcessKind.VORGANG,
        name: t(`PROJECT_PROCESS_KIND.VORGANG`),
      },
      {
        id: ProjectProcessKind.MEILENSTEIN,
        name: t(`PROJECT_PROCESS_KIND.MEILENSTEIN`),
      },
      {
        id: ProjectProcessKind.GLIEDERUNGSPUNKT,
        name: t(`PROJECT_PROCESS_KIND.GLIEDERUNGSPUNKT`),
      },
    ];
  }, [t]);

  return dataSet;
};

export type IProjectProcessKindPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: ProjectProcessKind; name: string }, TMultiple>;

export function ProjectProcessKindPicker<TMultiple extends boolean>(
  props: IProjectProcessKindPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const dataSet: { id: string; name: string }[] = useProjectProcessKind();

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
