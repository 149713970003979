import { Typography } from '@mui/material';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { FileData } from '@work4all/models/lib/File';

import { SearchResultItem } from '../../input/search-result-item/SearchResultItem';

interface Props {
  items: (Customer | Contact | Supplier)[];
  pathname: string;
  elementSelected: (item: FileData) => void;
}

export const prepareSuggestionEls = (props: Props) => {
  const { items } = props;

  const elements = items
    .filter((item) => {
      const typename: string = item.__typename;
      if (typename !== 'Ansprechpartner') return true;

      const contact: Contact = item as Contact;
      const businessPartner = contact.businessPartner;

      return businessPartner !== null;
    })
    .map((item) => {
      const typename: string = item.__typename;

      if (typename === 'Ansprechpartner') {
        const contact: Contact = item as Contact;

        const businessPartner = contact?.businessPartner?.data;
        return (
          <SearchResultItem
            key={`businesspartner-${contact.id}`}
            id={contact.id?.toString()}
            href={`${props.pathname}/${businessPartner.id}?contact=${contact.id}`}
            website={businessPartner.website || ''}
            title={
              <Typography component="span">
                {contact.name} {contact.firstName}
                <Typography component="span" color="secondary">
                  {' '}
                  | {businessPartner.name}
                </Typography>
              </Typography>
            }
            onClick={() =>
              props.elementSelected({
                id: businessPartner.id?.toString() || '',
                number: businessPartner.number?.toString() || '',
                name: businessPartner.name || '',
              })
            }
          />
        );
      } else {
        const business: Supplier | Customer =
          typename === 'Kunde' ? (item as Customer) : (item as Supplier);

        return (
          <SearchResultItem
            key={`customer-${business.id}`}
            id={business.id?.toString()}
            number={business.number?.toString()}
            href={`${props.pathname}/${business.id}`}
            title={business.name || ''}
            website={business.website || ''}
            onClick={() =>
              props.elementSelected({
                id: business.id?.toString() || '',
                number: business.number?.toString() || '',
                website: business.website,
                name: business.name || '',
              })
            }
          />
        );
      }
    });

  return elements;
};
