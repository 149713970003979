import React from 'react';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';

import { useSetting } from '@work4all/data/lib/settings';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings } from '../../../settings';

export interface ControlViewCenterProps {
  children: React.ReactNode;
  entityType: Entities;
  resizable?: boolean;
}

export const ControlViewCenter: React.FC<ControlViewCenterProps> = ({
  children,
  entityType,
  resizable,
}) => {
  const settingsSize = useSetting(settings.centerAreaSize({ entityType }));
  return resizable !== false ? (
    <ResizableArea
      handles="right"
      size={{ width: settingsSize.value.width }}
      direction="horizontal"
      onResize={(e) => settingsSize.set({ width: e.width })}
      minWidth={200}
      maxWidth={1500}
    >
      {children}
    </ResizableArea>
  ) : (
    children
  );
};
