import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableInstance } from 'react-table';

import {
  ColumnAdditionalData,
  CURRENCY_PARAMS,
  CurrencyCell,
  DateCell,
  HoursCell,
  NUMBER_PARAMS,
  NumberCell,
} from '@work4all/components';

import { useUser } from '@work4all/data';
import { useCustomFieldsConfigContext } from '@work4all/data/lib/custom-fields';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { currencyAsSign } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import { canViewPurchasePriceColumn } from '@work4all/utils/lib/permissions';

import { settings, useSetting } from '../../../../../../../../../../settings';
import {
  DEFAULT_VISIBLE_ERP_COLUMNS,
  SUPPLIER_ERP_ENTITIES,
} from '../../../../../../../../../../settings/settings';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { ForbiddenPriceCell } from '../edit-table/EditTable';

import { CustomCell } from './components/CustomCell';
import {
  EditableDisabledCell,
  editableDisabledCellWrapper,
} from './components/EditableDisabledCell';
import { useResetColumns } from './use-reset-columns';

type PositionColumnData = Column<Position> & ColumnAdditionalData;

interface UsePositionsColumnsProps {
  tableInstanceRef: React.MutableRefObject<TableInstance<object>>;
  allowedColumns?: string[];
  total: number;
}

export const usePositionsColumns = (props: UsePositionsColumnsProps) => {
  const { tableInstanceRef, allowedColumns, total } = props;

  const mask = useMaskContext();
  const columnSettings = useSetting(settings.erpPositionsConfig(mask.entity));

  const { t } = useTranslation();
  const { watch } = useFormContextPlus<ErpData>();
  const currency = watch('currency');
  const formatTitleWithCurrency = useCallback(
    (title: string) => {
      return currency ? `${title} ${currencyAsSign(currency.name)}` : title;
    },
    [currency]
  );

  const isSupplierEntity = SUPPLIER_ERP_ENTITIES.includes(mask.entity);

  const isDeliveryNote = mask.entity === Entities.deliveryNote;

  const width = useDeepMemo(
    () => columnSettings.value.width,
    [columnSettings.value.width]
  );

  const { customFields } = useCustomFieldsConfigContext();

  const user = useUser();

  const columns = useMemo(() => {
    const baseColumns: PositionColumnData[] = [
      {
        Header: t('COMMON.ERP.NUMBER'),
        accessor: 'number',
        width: remToPx(6),
        sticky: 'left',
        Cell: EditableDisabledCell,
      },
      {
        Header: t('COMMON.ERP.DESCRIPTION'),
        accessor: 'longtext',
        sticky: 'left',
        width: (isDeliveryNote ? remToPx(36) : remToPx(22)) + remToPx(2.8),
      },
      {
        Header: t('COMMON.ERP.AMOUNT_PARTS'),
        accessor: 'partsListAmount',
        disableOrder: true,
        width: remToPx(5),
        Cell: (cell) =>
          cell.row.original.posId ? <NumberCell {...cell} /> : <div></div>,
        ...NUMBER_PARAMS,
      },
      {
        Header: t('COMMON.ERP.AMOUNT'),
        accessor: 'amount',
        id: 'amount',
        width: remToPx(5),
        Cell: NumberCell,
        ...NUMBER_PARAMS,
      },
      {
        Header: t('COMMON.ERP.UNIT'),
        accessor: 'unit',
        width: remToPx(4.5),
      },
    ];

    const pricingColumns: PositionColumnData[] = [
      {
        Header: formatTitleWithCurrency(t('COMMON.ERP.PRICE')),
        accessor: 'singlePriceNet',
        Cell: (cellProps) => {
          if (
            cellProps.row.original.positionKind ===
              ErpPositionsKind.INTERNE_POSITION ||
            cellProps.row.original.positionKind ===
              ErpPositionsKind.INTERNE_STUECKLISTE
          )
            return (
              <div
                style={{
                  color: `var(--text03)`,
                  textAlign: 'right',
                  width: '100%',
                }}
              >
                {t('COMMON.ERP.INTERN').toUpperCase()}
              </div>
            );
          return isDeliveryNote ? (
            <ForbiddenPriceCell />
          ) : (
            <NumberCell {...cellProps} />
          );
        },
        width: remToPx(7),
        ...CURRENCY_PARAMS,
      },
      {
        Header: formatTitleWithCurrency(t('COMMON.ERP.TOTAL_PRICE')),
        accessor: 'totalPriceNet',
        Cell: isDeliveryNote
          ? ForbiddenPriceCell
          : editableDisabledCellWrapper(NumberCell),
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        Footer: (footerProps) => {
          return (
            <span>
              {isDeliveryNote ? (
                <ForbiddenPriceCell />
              ) : (
                <CurrencyCell
                  value={total || 0}
                  {...footerProps}
                  currency={currency?.name}
                />
              )}
            </span>
          );
        },
      },
      {
        Header: t('COMMON.ERP.ORDER_AMOUNT'),
        accessor: 'orderAmount',
        width: remToPx(7),
        Cell: NumberCell,
        ...NUMBER_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.DISCOUNT'),
        accessor: 'discount',
        width: remToPx(7),
        Cell: isDeliveryNote ? ForbiddenPriceCell : NumberCell,
        ...CURRENCY_PARAMS,
      },
      {
        Header: t('COMMON.ERP.INSTEAD_OF_PRICE'),
        accessor: 'insteadOfTotalPrice',
        width: remToPx(14),
      },
      canViewPurchasePriceColumn(user) && {
        Header: formatTitleWithCurrency(t('COMMON.ERP.PURCHASE_PRICE')),
        accessor: 'purchasePrice',
        width: remToPx(7),
        Cell: isDeliveryNote ? ForbiddenPriceCell : NumberCell,
        ...CURRENCY_PARAMS,
      },
      {
        Header: t('COMMON.ERP.VAT'),
        accessor: 'vat',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.GROSS_PROFIT'),
        accessor: 'rohertrag',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.PURCHASE_PRICE_SURCHARGE'),
        accessor: 'purchasePriceSurcharge',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.SINGLE_PRICE_GROSS'),
        accessor: 'singlePriceGross',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.TOTAL_PRICE_GROSS'),
        accessor: 'totalPriceGross',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.MINUTE_PRICE'),
        accessor: 'minutePrice',
        width: remToPx(7),
        Cell: NumberCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
    ];

    const additionalColumns: PositionColumnData[] = [
      {
        Header: t('COMMON.ERP.SHORT_TEXT'),
        accessor: 'shortText',
        width: remToPx(25),
      },
      {
        Header: t('COMMON.ERP.INTERNAL_TEXT'),
        accessor: 'internalText',
        width: remToPx(25),
      },
      isSupplierEntity
        ? {
            Header: t('COMMON.ERP.COST_ACCOUNT'),
            accessor: 'costAccount',
            width: remToPx(7),
            Cell: (item) => item.value || '',
            filterSubgroupPath: ['COMMON.ERP.BOOKING'],
          }
        : {
            Header: t('COMMON.ERP.LEDGER_ACCOUNT'),
            accessor: 'ledgerAccountNumber',
            width: remToPx(7),
            Cell: (item) => item.value || '',
            filterSubgroupPath: ['COMMON.ERP.BOOKING'],
          },
      {
        Header: t('COMMON.ERP.COST_CENTER'),
        accessor: 'costCenter1Number',
        width: remToPx(7),
        Cell: (item) => {
          return item.value || '';
        },
        filterSubgroupPath: ['COMMON.ERP.BOOKING'],
      },
      {
        Header: t('COMMON.ERP.MEASUREMENT'),
        accessor: 'measurement',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.WIDTH'),
        accessor: 'width',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.WEIGHT_TOTAL'),
        accessor: 'weight',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.VOLUME'),
        accessor: 'volume',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.VOLUME_TOTAL'),
        accessor: 'totalVolume',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.WEIGHT_SINGLE'),
        accessor: 'singleWeight',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.LENGTH'),
        accessor: 'length',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
      },
      {
        Header: t('COMMON.ERP.FACTOR'),
        accessor: 'factor',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.START_TIME'),
        accessor: 'startTime',
        width: remToPx(7),
        Cell: HoursCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.END_TIME'),
        accessor: 'endTime',
        width: remToPx(7),
        Cell: HoursCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
      },
      {
        Header: t('COMMON.ERP.ARTICLE_NUMBER'),
        accessor: 'articleNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.DISPO_START'),
        accessor: 'dispositionStart',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.DISPO_END'),
        accessor: 'dispositionEnd',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.OWN_ARTICLE_NUMBER'),
        accessor: 'ownArticleNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.SCHEDULED_DELIVERY_DATE'),
        accessor: 'deliveryDate',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.ACTUAL_DELIVERY_DATE'),
        accessor: 'actualDeliveryDate',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.MANUFACTURER_NUMBER'),
        accessor: 'manufacturerNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.ERP.PROCEDURE'),
        accessor: 'process',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.SUPPLIER'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: 'supplier.name' as any,
        width: remToPx(10),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.RESPONSIBLE'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: 'responsibleUser.displayName' as any,
        width: remToPx(10),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
    ];

    const filtered = [
      ...baseColumns,
      ...(!isDeliveryNote ? pricingColumns : []),
      ...additionalColumns,
    ].filter((col) => {
      return (
        Boolean(col) &&
        (allowedColumns
          ? allowedColumns.includes(col.accessor as string)
          : true)
      );
    });

    if (!allowedColumns)
      Object.entries(width).forEach((colWidth) => {
        const [prop, value] = colWidth;
        const colIdx = filtered.findIndex((col) => col.accessor === prop);
        if (filtered[colIdx]) {
          filtered[colIdx].width = value;
        }
      });

    return filtered;
  }, [
    t,
    isDeliveryNote,
    formatTitleWithCurrency,
    user,
    isSupplierEntity,
    allowedColumns,
    width,
    total,
    currency?.name,
  ]);

  const allColumns = useMemo(() => {
    if (!customFields || allowedColumns) return columns;
    return [
      ...columns,
      ...customFields
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((cField, order) => ({
          Header: cField.name,
          accessor: cField.id.toString(),
          width: 120,
          filterSubgroupPath: ['MASK.INDIVIDUAL'],
          order,
          Cell: (data) => <CustomCell field={cField} cellData={data} />,
        })),
    ] as Column<Position>[];
  }, [allowedColumns, columns, customFields]);

  // resetColumns
  const resetColumns = useResetColumns({
    columnSettings,
    tableInstanceRef,
    defaultColumns: DEFAULT_VISIBLE_ERP_COLUMNS,
  });
  return {
    columns: allColumns,
    resetColumns,
  };
};
