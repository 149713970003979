import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse/Collapse';

import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { ControllerPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/use-form-context-plus';
import { CustomerPickerField } from '../../../../../../../../../../entity-picker/CustomerPickerField';
import { ProjectPickerField } from '../../../../../../../../../../entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../../../../../../../../entity-picker/ProjectProcessPickerField';
import { RessourceClassPickerField } from '../../../../../../../../../../entity-picker/RessourceClassPickerField';
import { SupplierPickerField } from '../../../../../../../../../../entity-picker/SupplierPickerField';
import { UserPickerField } from '../../../../../../../../../../entity-picker/UserPickerField';
import { ProjectProcessFormValue } from '../../types';

export const AssignmentSection = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContextPlus<ProjectProcessFormValue>();
  const projectId = watch('projectId');

  return (
    <Collapse title={t('COMMON.ASSIGNMENT')} defaultOpen={true}>
      <Grid container spacing="1rem">
        <Grid item xs={6}>
          <ControllerPlus
            control={control}
            name="project"
            render={({ field }) => {
              return <ProjectPickerField {...field} disabled />;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerPlus
            control={control}
            name="parent"
            render={({ field }) => {
              return (
                <ProjectProcessPickerField
                  {...field}
                  projectId={projectId}
                  prefilter={[
                    {
                      kindId: {
                        $eq: ProjectProcessKind.GLIEDERUNGSPUNKT,
                      },
                    },
                  ]}
                  label={t('COMMON.PARENT_PROCESS')}
                  allowSelection={[ProjectProcessKind.GLIEDERUNGSPUNKT]}
                  clearable
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerPlus
            control={control}
            name="customer"
            render={({ field }) => {
              return <CustomerPickerField {...field} />;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerPlus
            control={control}
            name="supplier"
            render={({ field }) => {
              return <SupplierPickerField {...field} />;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerPlus
            control={control}
            name="user"
            render={({ field }) => {
              return <UserPickerField {...field} />;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerPlus
            control={control}
            name="ressourceClass"
            render={({ field }) => {
              return (
                <RessourceClassPickerField
                  multiple={false}
                  onChange={(e) => field.onChange(e?.[0] || null)}
                  value={[field.value]}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
