import styles from './SinglePriceTable.module.scss';

import { useEventCallback } from '@mui/material/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { BasicTable } from '@work4all/components';
import { ColumnAdditionalData } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { SinglePrice } from '@work4all/models/lib/Classes/SinglePrice.entity';

import { formatNumberAsCurrency } from '@work4all/utils/lib/formatNumberAsCurrency';

import { NumberInputCell } from '../../../components/table-cells/NumberInputCell';

import { calculatePriceWithSurcharge } from './calculate-price-with-surcharge';

export interface SinglePriceTableProps {
  entryPrice: number;
  onEntryPriceChange: (value: number) => void;
  entryPriceDisabled: boolean;
  singlePriceList: SinglePrice[];
  onChange: (value: SinglePrice[]) => void;
}

export function SinglePriceTable(props: SinglePriceTableProps) {
  const {
    entryPrice,
    entryPriceDisabled,
    onEntryPriceChange,
    singlePriceList,
    onChange,
  } = props;

  const { t } = useTranslation();

  const handleEntryPriceChange = useEventCallback((value: number) => {
    onEntryPriceChange(value);
  });

  const handleEdit = useEventCallback(
    (original: SinglePrice, change: Partial<SinglePrice>) => {
      const foundIndex = singlePriceList.findIndex((item) => item === original);
      const newValue = singlePriceList.slice();
      const updatedPrice = { ...newValue[foundIndex], ...change };

      const surcharge = updatedPrice.surcharge;
      // If surcharge is set, recalculate the price
      if (surcharge) {
        updatedPrice.price = calculatePriceWithSurcharge(entryPrice, surcharge);
      }

      newValue.splice(foundIndex, 1, updatedPrice);

      onChange(newValue);
    }
  );

  const columns = useMemo(() => {
    const columns: (Column<SinglePrice> & ColumnAdditionalData)[] = [
      {
        id: 'priceGroup',
        Header: t('COMMON.PRICE_GROUP'),
        accessor: (row) => row.priceGroup?.name ?? '',
        width: 180,
      },
      {
        id: 'entryPrice',
        Header: t('MASK.ARTICLE.SINGLE_PRICE.FIELDS.entryPrice'),
        alignment: 'right',
        accessor: () => {
          return entryPrice;
        },
        width: 120,
        Cell: (props) => {
          return (
            <NumberInputCell
              disabled={entryPriceDisabled}
              value={props.value}
              onChange={(value) => {
                if (value !== props.value) {
                  handleEntryPriceChange(value);
                }
              }}
              formatValue={(value) => {
                return `${formatNumberAsCurrency(value)} €`;
              }}
              parseValue={(value) => {
                value = value.replace(/\./g, '');
                value = value.replace(/,/g, '.');

                return parseFloat(value) || 0;
              }}
            />
          );
        },
      },
      {
        id: 'surcharge',
        Header: t('MASK.ARTICLE.SINGLE_PRICE.FIELDS.surcharge'),
        alignment: 'right',
        accessor: (row) => {
          return row.surcharge;
        },
        width: 120,
        Cell: (props) => {
          return (
            <NumberInputCell
              value={props.value}
              onChange={(value) => {
                handleEdit(props.row.original, { surcharge: value });
              }}
              formatValue={(value) => {
                if (!value) return '';
                return `${formatNumberAsCurrency(value)} %`;
              }}
              parseValue={(value) => {
                value = value.replace(/\./g, '');
                value = value.replace(/,/g, '.');

                return parseFloat(value) || 0;
              }}
            />
          );
        },
      },
      // {
      //   id: 'minutes',
      //   Header: 'Minuten',
      //   alignment: 'right',
      //   accessor: (row) => {
      //     return null;
      //   },
      //   width: 120,
      //   Cell: (props) => {
      //     return (
      //       <NumberInputCell
      //         value={props.value}
      //         onChange={(value) => {
      //           // handleEdit(props.row.original, { surcharge: value })
      //         }}
      //         formatValue={(value) => {
      //           if (value === null) return '';
      //           return `${formatNumberAsCurrency(value)} %`;
      //         }}
      //         parseValue={(value) => {
      //           value = value.replace(/\./g, '');
      //           value = value.replace(/,/g, '.');

      //           return parseFloat(value) || 0;
      //         }}
      //       />
      //     );
      //   },
      // },
      // {
      //   id: 'minuteWage',
      //   Header: 'Minutenlohn',
      //   alignment: 'right',
      //   accessor: (row) => {
      //     return row.minuteWage;
      //   },
      //   width: 120,
      //   Cell: (props) => {
      //     return (
      //       <NumberInputCell
      //         value={props.value}
      //         onChange={(value) => {
      //           handleEdit(props.row.original, { minuteWage: value });
      //         }}
      //         formatValue={(value) => {
      //           return `${formatNumberAsCurrency(value)} €`;
      //         }}
      //         parseValue={(value) => {
      //           value = value.replace(/\./g, '');
      //           value = value.replace(/,/g, '.');

      //           return parseFloat(value) || 0;
      //         }}
      //       />
      //     );
      //   },
      // },
      {
        id: 'price',
        Header: t('MASK.ARTICLE.SINGLE_PRICE.FIELDS.price'),
        alignment: 'right',
        accessor: (row) => row.price,
        width: 120,
        Cell: (props) => {
          return (
            <NumberInputCell
              value={props.value}
              onChange={(value) => {
                if (value !== props.value) {
                  handleEdit(props.row.original, {
                    price: value,
                    surcharge: 0,
                  });
                }
              }}
              formatValue={(value) => {
                if (!value) return '';
                return `${formatNumberAsCurrency(value)} €`;
              }}
              parseValue={(value) => {
                value = value.replace(/\./g, '');
                value = value.replace(/,/g, '.');

                return parseFloat(value) || 0;
              }}
            />
          );
        },
      },
    ];

    return columns;
  }, [t, entryPrice, entryPriceDisabled, handleEntryPriceChange, handleEdit]);

  return (
    <BasicTable
      className={styles.table}
      mode="client"
      columns={columns}
      data={singlePriceList}
      isVirtual={false}
      fitContentHeight={true}
      resizableColumns={true}
    />
  );
}
