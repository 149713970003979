import styles from './EditTable.module.scss';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import {
  Box,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { isEqual, omit } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
  Column,
  ColumnInstance,
  HeaderGroup,
  IdType,
  Renderer,
  TableInstance,
  useBlockLayout,
  useColumnOrder,
  useResizeColumns,
  useRowSelect,
  useTable,
} from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { ColumnAdditionalData } from '@work4all/components';
import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { HeaderCheckbox } from '@work4all/components/lib/dataDisplay/basic-table/components/header/components/header-checkbox/HeaderCheckbox';
import {
  EditableCell,
  EditableCellProps,
} from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';
import { TABLE_ROW_DRAG_DROP_KEY } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/row/Row';
import { TableHeader } from '@work4all/components/lib/dataDisplay/basic-table/components/table-header/TableHeader';
import { useSelectionRowClick } from '@work4all/components/lib/dataDisplay/basic-table/hooks/use-selection-row-click';
import {
  CellEditHandler,
  EditModeConfig,
  EditModeTableInstance,
  useEditMode,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';
import { Checkbox } from '@work4all/components/lib/input/checkbox-radio/CheckboxRadio';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { ERP_EXPAND_COLUMN_ID } from '../../../../../../../../../../components/data-tables/generic-column/GenericColumns';
import { settings, useSetting } from '../../../../../../../../../../settings';
import { ArticleGroupTree } from '../position-group-tree/PositionGroupsTree';

import { TableCell, TableHeaderRow, TableRow } from './components';
import { useEditTable } from './components/edit-table-provider/EditTableProvider';
import { TableCellContainer } from './components/table-cell-container/TableCellContainer';
import { genericMemo } from './generic-memo';
import { EditRowSelectionProvider } from './hooks/use-edit-row-selection';
import { useEditableColumnsSettings } from './hooks/use-editable-columns-settings';
import { useEditableRow } from './hooks/use-editable-row';
import { InfiniteList, InfiniteListState, InfiniteRow } from './InfiniteList';
import {
  CellDefinition,
  EditTableEntry,
  EditTableProps,
  IEditTable,
} from './types';

export const ForbiddenPriceCell = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('ALERTS.NOT_AUTHORIZED_TO_SEE_PRICES')}>
      <Box textAlign="center">-</Box>
    </Tooltip>
  );
};

const CONST_OBJECT = {};
const CONST_ARRAY = [];
function EditTableInternal<T extends EditTableEntry>(
  props: EditTableProps<T>,
  ref?: React.ForwardedRef<IEditTable>
) {
  const {
    disabled = false,
    fitSpace = false,
    items,
    onSelectedItemIdsChange,
    onMove,
    onCollapsePosition,
    tableInstanceRef,
    columns,
    mask: Mask,
    editableRows = CONST_OBJECT,
    renderMobileItem,
    renderMobileFooter,
    classes: overrideClasses,
    cellDefinition,
    loadingRows = CONST_ARRAY,
    noIdEditableCells = CONST_ARRAY,
    itemSize = 'auto',
    decorators,
    showBorders = true,
    showSelectionColumn = false,
    allowedColumns,
    onRowOut,
    columnSettings,
    singleLine,
    handleOrderChange,
    entity,
    bordersKey = 'STANDARD',
    updateMode = 'row',
  } = props;

  const tablesLayoutBorders = useSetting(
    settings.tablesLayoutBorders(bordersKey)
  );

  const classes =
    bordersKey === 'ERP'
      ? overrideClasses
      : {
          table: styles['classes-table'],
          thead: styles['classes-thead'],
          tbody: !tablesLayoutBorders.value.vertical
            ? styles['classes-tbody']
            : undefined,
          tfoot: clsx({
            [styles['classes-tfoot']]:
              tablesLayoutBorders.value.vertical &&
              tablesLayoutBorders.value.horizontal,
            [styles['classes-tfootVertical']]:
              tablesLayoutBorders.value.vertical &&
              !tablesLayoutBorders.value.horizontal,
            [styles['classes-tfootHorizontal']]:
              !tablesLayoutBorders.value.vertical,
          }),
          ...overrideClasses,
        };

  const allCells = useMemo(
    () =>
      Object.entries(cellDefinition)
        .filter((definition) => definition[1].editable !== false)
        .map((definition) => definition[0]),
    [cellDefinition]
  );

  const defaultColumn: Partial<Column<T>> & {
    EditableCell: Renderer<Partial<EditableCellProps>>;
  } = useMemo(
    () => ({
      EditableCell,
    }),
    []
  );

  const { set: setTableRightAreaVisible, value: tableRightAreaVisible } =
    useSetting(
      settings.tableRightAreaVisible({
        entityType: entity,
      })
    );

  const { onEdit: onEditPosition } = useEditTable();

  const { t } = useTranslation();

  const data = useRef<T[]>([]);
  const listRef = useRef<VariableSizeList>();
  useMemo(() => {
    data.current = items;
  }, [items]);

  // update height of rows
  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [items, itemSize]);

  function getRowId(position: T) {
    return position.localId;
  }

  // aggregate this events if it's same row
  const rowInEditModeValues = useRef<T>(null);
  const onRowEdit = useCallback(() => {
    if (!onEditPosition) return;
    if (!rowInEditModeValues.current || !rowInEditModeValues.current.localId) {
      console.error('There is no position');
      return;
    }
    const onEditPositionArgs = {
      position: rowInEditModeValues.current,
    };
    onEditPosition(onEditPositionArgs);
    rowInEditModeValues.current = null;
  }, [onEditPosition]);

  const onCellEdit: CellEditHandler<T> = useCallback(
    (result) => {
      const { cell, value } = result;
      rowInEditModeValues.current = {
        ...rowInEditModeValues.current,
        ...{
          id: cell.row.original.id,
          [cell.column.id]: value,
          localId: cell.row.id,
        },
      };
      const dirtyFields = omit(rowInEditModeValues.current, 'id');
      cell.row.original.dirtyFields = dirtyFields;
      Object.entries(dirtyFields).forEach(
        (field) => (cell.row.original[field[0]] = field[1])
      );
      if (updateMode === 'cell' && result.interaction === 'blur-update') {
        onRowEdit();
      }
    },
    [updateMode, onRowEdit]
  );

  // save all changes when unmout position list
  useEffect(() => {
    return () => {
      onRowEdit();
    };
  }, [onRowEdit]);

  const hiddenColumns = useMemo(() => {
    if (!columnSettings) return [];

    if (allowedColumns) {
      return columns
        .filter((col) => !allowedColumns.includes(col.accessor as string))
        .map((col) => col.accessor as string);
    }

    const _hiddenColumns = columns
      .filter(
        (col) =>
          !columnSettings.value.visibility.includes(String(col.accessor)) &&
          col.accessor !== ERP_EXPAND_COLUMN_ID
      )
      .map((col) => col.accessor as string);

    return [..._hiddenColumns, SELECTION_COLUMN_ID];
  }, [columns, columnSettings, allowedColumns]);

  const lastEmittedSelectedItemIds = useRef<Record<IdType<T>, boolean>>(
    {} as Record<IdType<T>, boolean>
  );

  const tableInstance = useTable<T>(
    {
      columns,
      defaultColumn,
      data: items,
      getRowId,
      onCellEdit,
      initialState: {
        hiddenColumns,
        selectedRowIds:
          `{}` === JSON.stringify(lastEmittedSelectedItemIds.current) &&
          props.initialState?.selectedRowIds
            ? props.initialState.selectedRowIds
            : lastEmittedSelectedItemIds.current,
      },
    },
    useBlockLayout,
    useColumnOrder,
    useRowSelect,
    useResizeColumns,
    useEditMode,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const newColumns = [];
        // TODO: use makeRowSelectable (look BasicTable)
        if (onSelectedItemIdsChange && showSelectionColumn) {
          const attr = { [`data-table-row-selectbox-marker`]: true };
          newColumns.push({
            id: SELECTION_COLUMN_ID,
            disableResizing: true,
            minWidth: 40,
            width: 40,
            maxWidth: 40,
            sticky: 'left',
            Header: (props) => {
              const { getToggleAllRowsSelectedProps } = props;
              const { checked, indeterminate, onChange, role } =
                getToggleAllRowsSelectedProps();

              return (
                <HeaderCheckbox
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={(e, checked) => {
                    const prevValue = rows
                      .filter((x) => x.original.disableSelect)
                      .filter((x) => x.isSelected);
                    onChange(e, checked);

                    prevValue.forEach((x) => x.toggleRowSelected(x.isSelected));
                  }}
                  role={role}
                />
              );
            },
            Cell: ({ row }) => {
              const { checked, onChange, indeterminate, role } =
                row.getToggleRowSelectedProps();
              return (
                <div {...attr}>
                  <Checkbox
                    disabled={row.original.disableSelect}
                    className={clsx(styles['checkbox'], {
                      [styles['checked']]: checked,
                    })}
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={onChange}
                    role={role}
                  />
                </div>
              );
            },
          });
        }
        // TODO: refactor to utils to get rid of code from here
        if (decorators?.getRelation) {
          newColumns.push({
            id: ERP_EXPAND_COLUMN_ID,
            disableResizing: true,
            minWidth: 24,
            width: 24,
            maxWidth: 24,
            sticky: 'left',
            Header: () => (
              <Box
                bgcolor="var(--ui01)"
                right={0}
                left={0}
                position="absolute"
              />
            ),
            Cell: ({ row }) => {
              const relation = decorators
                ? decorators.getRelation(row.original)
                : 'normal';
              if (relation === 'normal') return <Box />;
              if (relation === 'child') {
                return <Box className={styles.relationMarker} />;
              }

              return (
                <Box className={styles.relationMarker} height="100%">
                  <IconButton
                    className={clsx(styles.icon)}
                    onClick={() => onCollapsePosition?.(row.original)}
                  >
                    <ExpandMoreIcon
                      className={clsx({
                        [styles.iconCollapsed]: row.original.collapsed,
                      })}
                    />
                  </IconButton>
                </Box>
              );
            },
          });
        }

        const newColumnsEnd = [];
        if (!showBorders) {
          newColumnsEnd.push({
            id: 'PLACEHOLDER',
            disableResizing: true,
            minWidth: remToPx(3),
            width: remToPx(3),
            maxWidth: remToPx(3),
          });
        }
        return [...newColumns, ...columns, ...newColumnsEnd];
      });
    }
  ) as TableInstance<T> & EditModeTableInstance;

  const {
    headerGroups,
    getTableBodyProps,
    getTableProps,
    prepareRow,
    rows,
    setEditModeConfig,
    state: { selectedRowIds, groupBy },
    visibleColumns,
    setColumnOrder,
  } = tableInstance;

  useEditableColumnsSettings({ tableInstance, allowedColumns, columnSettings });

  useEffect(() => {
    reactRefSetter(tableInstanceRef)(tableInstance);

    return () => {
      reactRefSetter(tableInstanceRef)(null);
    };
  }, [tableInstanceRef, tableInstance]);

  useEffect(() => {
    if (!isEqual(selectedRowIds, lastEmittedSelectedItemIds.current)) {
      const selectedIds = Object.entries(selectedRowIds)
        .filter(([, selected]) => selected)
        .map(([id]) => id);

      const localIds = data.current.filter((item) =>
        selectedIds.includes(item.localId)
      );
      lastEmittedSelectedItemIds.current = localIds
        .map((withLocalId) => withLocalId.localId)
        .reduce((acc, p) => {
          acc[p] = true;
          return acc;
        }, {} as Record<string, boolean>) as Record<IdType<T>, boolean>;
      onSelectedItemIdsChange?.(selectedIds);
    }
  }, [lastEmittedSelectedItemIds, onSelectedItemIdsChange, selectedRowIds]);

  const { calculateEditableCols, calculateRowProperties } = useEditableRow({
    allCells,
    data,
    decorators,
    editableRows,
    onMove,
  });

  const localScrollRef = useRef<HTMLDivElement>();

  const setEditMode = useCallback(
    (
      rowId: string,
      autofocus?: EditModeConfig['autoFocus'],
      columns?: EditModeConfig['columns']
    ) => {
      const row = tableInstanceRef.current.rows.find(
        (row) => String(row.original.id) === rowId
      );
      if (row) {
        if (!columns) {
          const { editableCols } = calculateEditableCols(row.original);
          columns = editableCols;
        }
        tableInstanceRef.current.setEditModeConfig({
          columns,
          row: row.id,
          autoFocus: autofocus,
        });
        tableInstanceRef.current.toggleAllRowsSelected(false);
        tableInstanceRef.current.toggleRowSelected(row.id, true);
      }
    },
    [calculateEditableCols, tableInstanceRef]
  );

  const handleOnRowOut = useCallback(
    (position: T, dir: 1 | -1) => {
      const positionIndex = items.findIndex((item) => item.id === position?.id);
      const nextPosition = items[positionIndex + dir];
      if (nextPosition) setEditMode(nextPosition.id.toString(), allCells[0]);
      // scroll to the left to see longtext popover
      if (localScrollRef.current) localScrollRef.current.scrollLeft = 0;
    },
    [items, localScrollRef, setEditMode, allCells]
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        scrollTo: (to?: number) => {
          const tableInstance = tableInstanceRef.current as TableInstance<T> &
            EditModeTableInstance;
          // ScrollTo should work only if there is no current cache row editing
          const item = items.find(
            (x) => x.localId === tableInstance.state.editModeConfig?.row
          );
          if (item?.cacheOnly) return;

          if (to === undefined) {
            const notCache = items.filter((x) => !x.cacheOnly);
            to = notCache.length - 1;
          }
          listRef.current?.scrollToItem(to);

          // make new row editable
          const row = tableInstance.rows[to];
          if (row) {
            const { editableCols } = calculateEditableCols(row.original);
            const columns = editableCols;
            tableInstance.setEditModeConfig({
              columns,
              row: row.id,
              autoFocus: editableCols.includes('amount')
                ? 'amount'
                : editableCols[0],
            });
            tableInstance.toggleAllRowsSelected(false);
            tableInstance.toggleRowSelected(row.id, true);
          }
        },
        setEditMode,
      };
    },
    [calculateEditableCols, items, setEditMode, tableInstanceRef]
  );

  const onClickOutSide = useEventCallback((localId: string) => {
    if (
      rowInEditModeValues.current &&
      rowInEditModeValues.current.localId === localId
    ) {
      if (!localId) {
        setEditModeConfig({
          columns: [],
          row: '',
        });
        return;
      }

      onRowEdit();
      setEditModeConfig({
        columns: [],
        row: '',
      });
    }
  });
  const skeletonFirstRowAffectedIndex = items.findIndex(
    (item) => item.id === loadingRows[0]
  );
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const onRowClick = useSelectionRowClick<T>({
    tableBodyRef,
    tableInstance,
    ctrlOnly: true,
    selectableMultiple: true,
    selectableRows: true,
  });

  const shouldAutoSizeRows = itemSize === 'auto';

  const renderRow = useCallback(
    (
      props: ListChildComponentProps<InfiniteRow<T>[]>,
      state: InfiniteListState,
      measureRef: React.RefCallback<HTMLElement>,
      maxRowSize: number
    ) => {
      const { isRowVisible } = state;
      const { index, data: rowData, style } = props;
      const row = rowData[index];

      prepareRow(row);
      const {
        canDrop,
        handleMoveRow,
        isParentSelected,
        editableCols,
        isNormal,
        isParent,
        isChild,
      } = calculateRowProperties(row, rowData);

      const renderRow = () => {
        switch (row.original.positionKind) {
          case ErpPositionsKind.SEITENUMBRUCH:
            return (
              <TableRow
                ref={measureRef}
                id={row.original.localId}
                disabled={disabled}
                key={row.original.localId}
                index={index}
                isSimpleMode={!showBorders}
                canDrop={canDrop}
                isNormal={isNormal}
                onMoveRow={handleMoveRow}
                isMenuVisible={!!onMove}
                kind={row.original.positionKind}
                onClick={(e) => {
                  e.stopPropagation();
                  onRowClick(e, row);
                }}
                {...row.getRowProps({
                  className: clsx(styles['tr-border'], {
                    [styles.selectedRow]: row.isSelected,
                  }),
                  style: {
                    ...style,
                    ...(shouldAutoSizeRows && {
                      height: 'auto',
                      maxHeight: maxRowSize,
                    }),
                  },
                })}
              >
                <TableCell
                  {...row.cells[0].getCellProps()}
                  className={styles['table-cell']}
                >
                  {row.cells[0].render('Cell')}
                </TableCell>
                <div className={styles['page-break']}>
                  <InsertPageBreakIcon />
                  <div>{t('COMMON.PAGE_BREAK')}</div>
                </div>
              </TableRow>
            );
          default:
            return (
              <TableRow
                ref={measureRef}
                id={row.original.localId}
                key={row.original.localId}
                canDrop={canDrop}
                isSimpleMode={!showBorders}
                isMenuVisible={!!onMove}
                className={clsx({
                  [styles.selectedRow]: row.isSelected,
                  [styles['parent-selected']]: isParentSelected,
                  [styles['relationParent']]:
                    isParent && !row.original.collapsed,
                  [styles['relationChild']]: isChild,
                })}
                disabled={disabled}
                index={index}
                onMoveRow={row.original.cacheOnly ? undefined : handleMoveRow}
                kind={row.original.positionKind}
                onClickOutside={onClickOutSide}
                isNormal={isNormal}
                hasBorder={showBorders ? tablesLayoutBorders.value : undefined}
                onClick={(e) => {
                  e.stopPropagation();
                  onRowClick(e, row);
                }}
                {...row.getRowProps({
                  style: {
                    ...style,
                    ...(shouldAutoSizeRows && {
                      height: 'auto',
                      maxHeight: maxRowSize,
                    }),
                  },
                })}
              >
                <TableCellContainer
                  key={row.original.localId}
                  editableCols={editableCols}
                  index={index}
                  isRowVisible={isRowVisible}
                  listRef={listRef}
                  onRowEdit={onEditPosition ? onRowEdit : null}
                  row={row}
                  rowInEditModeValues={rowInEditModeValues}
                  setEditModeConfig={setEditModeConfig}
                  singleLine={singleLine}
                  skeletonFirstRowAffectedIndex={skeletonFirstRowAffectedIndex}
                  tableInstance={tableInstance}
                  tablesLayoutBorders={tablesLayoutBorders}
                  cellDefinition={cellDefinition}
                  disabled={disabled}
                  onRowOut={onRowOut ?? handleOnRowOut}
                  loadingRows={loadingRows}
                  showBorders={showBorders}
                  noIdEditableCells={noIdEditableCells}
                />
              </TableRow>
            );
        }
      };

      return renderRow();
    },
    [
      prepareRow,
      calculateRowProperties,
      disabled,
      showBorders,
      onMove,
      shouldAutoSizeRows,
      t,
      onClickOutSide,
      tablesLayoutBorders,
      onEditPosition,
      onRowEdit,
      setEditModeConfig,
      singleLine,
      skeletonFirstRowAffectedIndex,
      tableInstance,
      cellDefinition,
      onRowOut,
      loadingRows,
      noIdEditableCells,
      onRowClick,
      handleOnRowOut,
    ]
  );

  const positionMaskAreaSetting = useSetting(settings.positionMaskAreaSize());

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: TABLE_ROW_DRAG_DROP_KEY,
    drop: (draggedItem: Article | ArticleGroupTree) => {
      if (
        // eslint-disable-next-line no-prototype-builtins
        draggedItem.hasOwnProperty('__typename') &&
        draggedItem['__typename'] === 'Artikel'
      )
        props?.onPick(draggedItem);
      else {
        props?.onPick(draggedItem.name);
      }
    },
    collect: (monitor: DropTargetMonitor) => {
      return {
        canDrop: monitor.canDrop(),
      };
    },
  }));

  const selectedPositionIds = useMemo(() => {
    return Object.keys(selectedRowIds);
  }, [selectedRowIds]);

  const handleSetColumnOrder = useCallback(
    (updater: ((columnOrder: IdType<T>[]) => IdType<T>[]) | IdType<T>[]) => {
      if (typeof updater !== 'function' && handleOrderChange)
        setColumnOrder(handleOrderChange(updater));
      else setColumnOrder(updater);
    },
    [setColumnOrder, handleOrderChange]
  );

  const selectedRow = useMemo(() => {
    const values = tableInstance.rows.filter((row) =>
      selectedPositionIds.includes(row.id)
    );
    if (!(values.length === 1)) return null;
    return values[0];
  }, [selectedPositionIds, tableInstance.rows]);

  const selectedItem = selectedRow?.original;

  const maskProperties = useMemo(() => {
    if (!selectedRow) return;
    return calculateRowProperties(selectedRow, tableInstance.rows);
  }, [calculateRowProperties, selectedRow, tableInstance.rows]);

  const toggleEditedItem = (pos: T) => {
    if (!pos) {
      return;
    }
    const rowToSelect = tableInstance.rows.find(
      (row) => row.id === pos.localId
    );
    tableInstanceRef.current.toggleRowSelected(rowToSelect.id, true);
  };

  useEffect(() => {
    if (!fitSpace) return;
    if (tableBodyRef.current) {
      const intinitelist =
        tableBodyRef.current.getElementsByClassName('initite-list')[0];
      if (intinitelist && intinitelist.children[0]) {
        const child = intinitelist.children[0] as HTMLDivElement;
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const height = entry.contentRect.height;
            if (tableBodyRef.current)
              tableBodyRef.current.style.height = `${height}px`;
          }
        });

        resizeObserver.observe(child);
        return () => resizeObserver.unobserve(child);
      }
    }
  }, [tableBodyRef, fitSpace]);

  return isMobile && renderMobileItem ? (
    <React.Fragment>
      <table className={classes?.mobile}>
        <tbody>
          {items.map((item) => {
            return renderMobileItem(item, toggleEditedItem, items);
          })}
        </tbody>
        {renderMobileFooter ? <tfoot>{renderMobileFooter(items)}</tfoot> : null}
      </table>
      {selectedItem && Mask && (
        <NavigationOverlay
          initialView={{
            view: (
              <Mask
                item={selectedItem}
                onCloseClick={() => {
                  onSelectedItemIdsChange([]);
                  tableInstanceRef.current.toggleAllRowsSelected(false);
                }}
                {...maskProperties}
              />
            ),
          }}
          close={() => {
            onSelectedItemIdsChange([]);
            tableInstanceRef.current.toggleAllRowsSelected(false);
          }}
          open={selectedItem !== null}
        />
      )}
    </React.Fragment>
  ) : (
    <div
      ref={drop}
      className={clsx(styles.tableWrapper, {
        [styles.disabled]: disabled,
        [styles.canDrop]: canDrop,
      })}
    >
      <div className={clsx(styles.outerWrap)}>
        <div
          ref={
            props.refs
              ? reactRefSetter(localScrollRef, props.refs.scroll)
              : localScrollRef
          }
          className={clsx(styles.tableWrapper, {
            [styles.disabled]: disabled,
            [styles.simpleWrapper]: !showBorders,
          })}
        >
          <div
            className={clsx(styles.table, classes?.table)}
            {...getTableProps()}
          >
            <div
              className={clsx(styles.thead, {
                [styles.noBorder]: !showBorders,
              })}
            >
              <TableHeader
                flatColumns={visibleColumns as unknown as ColumnInstance[]}
                headerGroups={
                  headerGroups as unknown as HeaderGroup<ColumnAdditionalData>[]
                }
                setColumnOrder={handleSetColumnOrder}
                reordableColumns={showBorders && !allowedColumns?.length}
                resizableColumns={showBorders && !allowedColumns?.length}
                noSeperator={!showBorders}
                classes={{
                  headerWrapper: clsx(classes?.thead, {
                    [styles.autoScroll]: !showBorders,
                    [styles.noBorder]: !showBorders,
                  }),
                  headerCellRoot: showBorders ? undefined : styles.border,
                }}
                styles={{
                  row: {
                    width: 'max-content',
                  },
                }}
                additionalHeaders={
                  disabled || !onMove ? null : (
                    <TableCell
                      role="cell"
                      className={clsx(styles['drag-handler'], {
                        [styles['drag-handler-simple']]: !showBorders,
                      })}
                    />
                  )
                }
                groupBy={groupBy}
              />
            </div>
            <div
              ref={tableBodyRef}
              className={clsx(styles.tbody, classes?.tbody)}
              {...getTableBodyProps()}
            >
              <EditRowSelectionProvider>
                <AutoSizer>
                  {({ width, height }) => {
                    return (
                      <InfiniteList
                        width={width}
                        height={height}
                        listRef={listRef}
                        itemSize={itemSize}
                        renderRow={renderRow}
                        rows={rows}
                      />
                    );
                  }}
                </AutoSizer>
              </EditRowSelectionProvider>
            </div>

            {columns.some((col) => col.Footer) ? (
              <div
                className={clsx(styles.tfoot, classes?.tfoot, {
                  [styles.noBorder]: !showBorders,
                  [styles.noBorderHorizontal]:
                    !tablesLayoutBorders.value.horizontal || !showBorders,
                  [styles.noBorderVertical]:
                    !tablesLayoutBorders.value.vertical || !showBorders,
                })}
              >
                {headerGroups.map((headerGroup) => {
                  const props = headerGroup.getFooterGroupProps({
                    style: {
                      width: 'unset',
                      display: 'flex',
                    },
                  });
                  return (
                    <TableHeaderRow
                      isSimpleMode={!showBorders}
                      noDrag={!onMove}
                      hasBorder={
                        showBorders ? tablesLayoutBorders.value : undefined
                      }
                      disabled={disabled}
                      {...props}
                    >
                      {headerGroup.headers.map((column) => {
                        const definition: CellDefinition<T> = cellDefinition[
                          column.id as string
                        ] ?? { type: 'number' };
                        return (
                          <TableCell
                            {...column.getFooterProps()}
                            className={clsx(styles['table-cell'], {
                              [styles.skeleton]:
                                loadingRows.length && definition.skeleton,
                              [styles.borderTop]:
                                !showBorders && column.id !== 'PLACEHOLDER',
                              [styles.borderLeft]:
                                showBorders &&
                                tablesLayoutBorders.value.vertical,
                            })}
                          >
                            {column.render('Footer')}
                          </TableCell>
                        );
                      })}
                    </TableHeaderRow>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {selectedItem && Mask && (
        <Box
          sx={{
            height: '100%',
            background: 'var(--ui01)',
          }}
        >
          {!tableRightAreaVisible && (
            <div className={styles.positionMaskToggle}>
              <ExpandButton
                icon={
                  tableRightAreaVisible ? <ChevronRight /> : <ChevronLeft />
                }
                textStart="top"
                title={t('COMMON.POSITION')}
                color="text03"
                onClick={() => setTableRightAreaVisible(!tableRightAreaVisible)}
              />
            </div>
          )}
          {tableRightAreaVisible && selectedItem && (
            <AutoSizer disableWidth>
              {({ height }) => {
                return (
                  <ResizableArea
                    minWidth={250}
                    maxWidth={1200}
                    size={{ width: positionMaskAreaSetting.value.width }}
                    onResize={(size) => positionMaskAreaSetting.set(size)}
                    handles="left"
                  >
                    <Stack direction="row" height={height}>
                      <Mask
                        item={selectedItem}
                        onCloseClick={() => setTableRightAreaVisible(false)}
                        {...maskProperties}
                      />
                    </Stack>
                  </ResizableArea>
                );
              }}
            </AutoSizer>
          )}
        </Box>
      )}
    </div>
  );
}

const EditTableInternalRef = forwardRef(EditTableInternal) as <T>(
  props: EditTableProps<T> & { ref?: React.ForwardedRef<IEditTable> }
) => ReturnType<typeof EditTableInternal>;

export const EditTable = genericMemo(EditTableInternalRef);
