import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type TimeUnit = 'year' | 'day' | 'week' | 'month';

interface IProps {
  unit: TimeUnit;
  onChange?: (unit: TimeUnit) => void;
}

export const TimeUnitSwitch = (props: IProps) => {
  const { unit, onChange } = props;
  const { t } = useTranslation();
  return (
    <Tabs value={unit} onChange={(_, value) => onChange?.(value)}>
      <Tab value="day" label={t('COMMON.DAY')} />
      <Tab value="week" label={t('COMMON.WEEK')} />
      <Tab value="month" label={t('COMMON.MONTH')} />
      <Tab value="year" label={t('COMMON.YEAR')} />
    </Tabs>
  );
};
