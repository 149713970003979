import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ILookUpProps,
  LookUpPicker,
} from '@work4all/components/lib/components/entity-picker/look-up-picker/LookUpPicker';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

export interface LookUpPickerFieldProps<TMultiple extends boolean = false>
  extends ILookUpProps<TMultiple> {
  error?: string;
  label?: string;
}

export function LookUpPickerField<TMultiple extends boolean = false>(
  props: LookUpPickerFieldProps<TMultiple>,
  ref
) {
  const { error, ...pickerProps } = props;
  const { multiple } = props;
  const { value, onChange } = pickerProps;

  const popoverRef = useRef<EntityPickerPopover>(null);

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<LookUpPicker {...pickerProps} />}
    >
      <PickerTargetButton
        ref={ref}
        value={
          multiple
            ? (value as LookUp[]).length === 0
              ? null
              : `${(value as LookUp[]).length} ${t('COMMON.CHOSEN')}`
            : (value as LookUp)?.name ?? ''
        }
        label={props.label || ''}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
}
