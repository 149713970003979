import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileType } from '@work4all/models/lib/File';

import { WidgetArrayColumn, WidgetArrayColumnsEntity } from './types';

const PROJECT_WIDGETS_ARRAY_COLUMNS_BY_ENTITY: Record<
  WidgetArrayColumnsEntity,
  WidgetArrayColumn[]
> = {
  // TODO WW-4671
  // We should remove `Entities.inboundInvoice` columns after
  // updating the widget entity type from `InboundInvoice` to
  // `ReViewModel` in the widgets configs for all tenants
  // and after releasing a new release.
  // I only updated the entity type for suppliers and projects
  // in tenant 3.
  // Also we should wait for the new release because
  // `EntityByLayoutType` was missing the `ReViewModel` and
  // without it, it will break the app.
  [Entities.inboundInvoice]: [
    {
      accessor: 'bookings',
      type: 'bookings-total-amount-percentage',
      data: {
        id: null,
        proportionDM: null,
        projectId: null,
      },
    },
  ],
  [Entities.reViewModel]: [
    {
      accessor: 'bookings',
      type: 'bookings-total-amount-percentage',
      data: {
        id: null,
        proportionDM: null,
        projectId: null,
      },
    },
  ],
};

export const WIDGETS_ARRAY_COLUMNS_BY_FILE_TYPE = {
  [FileType.PROJECT]: PROJECT_WIDGETS_ARRAY_COLUMNS_BY_ENTITY,
};
