import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { WordDocumentTemplatePickerField } from '../../../../../components/entity-picker/WordDocumentTemplatePickerField';
import { WordLetterTemplatePickerField } from '../../../../../components/entity-picker/WordLetterTemplatePickerField';
import { Collapse } from '../../components';
import { useMaskContext } from '../../hooks/mask-context';

const templateAddData = {
  fileInfos: { previewMimeType: null, previewUrl: null },
};

export const Template = () => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const mask = useMaskContext();

  return (
    <Collapse title={t('COMMON.TEMPLATE')} defaultOpen>
      <Stack spacing={2}>
        <Controller
          control={control}
          name={register('basedOnTemplate').name}
          render={({ field, fieldState }) => {
            const FieldComp =
              mask.entity === Entities.letter
                ? WordLetterTemplatePickerField
                : WordDocumentTemplatePickerField;

            return (
              <FieldComp
                key="1"
                data={templateAddData}
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={fieldState.error?.message}
                prefilter={[
                  {
                    documentSubClass: {
                      $eq: mask.entityVariant || null,
                    },
                  },
                ]}
              />
            );
          }}
        />

        <Typography variant="body1" whiteSpace="normal">
          {t('MASK.WORD_TEMPLATE_DESCRIPTION')}
        </Typography>
      </Stack>
    </Collapse>
  );
};
