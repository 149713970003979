import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

type Props<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function PaymentStatusPicker<TMultiple extends boolean>(
  props: Props<TMultiple>
) {
  const { t } = useTranslation();
  const data = useMemo(() => {
    return Object.keys(PaymentStatus)
      .map((key) => {
        const id = PaymentStatus[key];

        return {
          id,
          name: t(`PAYMENT_STATUSES.${id}`),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [t]);

  return <FixedDataPicker dataSet={data} {...props} />;
}
