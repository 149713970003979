import { IResponse } from '@work4all/data';

import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PaymentEntity, PaymentMaskData } from '../types';

export const prepareData = (
  response: IResponse,
  entity: PaymentEntity
): PaymentMaskData => {
  const data = response.data[0] ?? {};

  if (entity === Entities.reViewModel) {
    const re = data as ReViewModel;

    return {
      id: re?.id,
      number: re?.invoiceNumber,
      note: re?.note,
      date: re?.invoiceDate,
      dueDate: re?.dueDate,
      businessPartner: re?.supplier,
      currency: re?.currency,
      exchangeRate: re?.exchangeRate,
      discountPercent: re?.discountPerCent,
      discountDate: re?.discountDate,
      payments: re?.payments?.map((payment) => {
        return {
          id: payment?.id,
          bookingDate: payment?.bookingDate,
          datevDate: payment?.datevDate,
          amount: payment?.amount,
          ledgerAccount: payment?.ledgerAccount,
          note: payment?.text,
          user: payment?.user,
          date: payment?.date,
        };
      }),
      grossAmount: re?.amountGross,
      remainingAmount: re?.valueNotPayed,
    };
  }

  if (entity === Entities.raViewModel) {
    const ra = data as RaViewModel;

    return {
      id: ra?.id,
      number: ra?.invoiceNumber,
      note: ra?.note,
      date: ra?.invoiceDate,
      dueDate: ra?.dueDate,
      businessPartner: ra?.customer,
      currency: ra?.currency,
      exchangeRate: ra?.exchangeRate,
      discountPercent: ra?.invoice?.skonto,
      discountDate: ra?.invoice?.skontoDeadlineDate,
      payments: ra?.payments?.map((payment) => {
        return {
          id: payment?.id,
          bookingDate: payment?.buchungsDatum,
          datevDate: payment?.datev,
          amount: payment?.summe,
          ledgerAccount: payment?.ledgerAccount,
          note: payment?.note,
          user: payment?.createdByUser,
          date: payment?.date,
        };
      }),
      grossAmount: ra?.grossValue,
      remainingAmount: ra?.valueToPay,
    };
  }
};
