import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

import { useSecureBlobUrl } from '@work4all/data';
import { useRemToPx } from '@work4all/data/lib/hooks/useRemToPx';

type SignatureCanvas = {
  getDataURL: (
    fileType: string,
    useBgImage: boolean,
    backgroundColour: string
  ) => string;
  clear: () => void;
  canvas: Record<string, HTMLCanvasElement>;
};

const BORDER = 2;
const MAX_BRUSH = 3;
const MAX_HEIGHT = 220 + BORDER;
const MAX_WIDTH = 440 + BORDER;

function resizeImage(dataURL: string, maxWidth: number, maxHeight: number) {
  return new Promise<Blob>((resolve) => {
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      canvas.getContext('2d').drawImage(image, 0, 0, maxWidth, maxHeight);

      canvas.toBlob(
        function (blob) {
          resolve(blob);
        },
        'image/png',
        1
      );
    };
    image.src = dataURL;
  });
}

export const useSignatureCanvas = (url?: string) => {
  const isSmDown = useMediaQuery<Theme>((t) => t.breakpoints.down('sm'));

  const { blobUrl } = useSecureBlobUrl(url ?? null);
  const rem = useRemToPx(2);

  const canvasRef = useRef<SignatureCanvas>();
  const sizes = useMemo(() => {
    if (!isSmDown)
      return {
        width: MAX_WIDTH,
        height: MAX_HEIGHT,
        brush: 1,
      };
    const smWidth = window.innerWidth - (rem + BORDER + 2 * 32);
    const ratio = smWidth / MAX_WIDTH;
    const smHeight = MAX_HEIGHT * ratio;
    const smBrush = MAX_BRUSH * ratio;
    return {
      width: smWidth,
      height: smHeight,
      brush: smBrush,
    };
  }, [isSmDown, rem]);

  const clear = useCallback(() => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      Object.values(canvasRef.current.canvas).forEach((c) =>
        c.getContext('2d').clearRect(0, 0, c.width, c.height)
      );
    }
  }, []);

  const canvas = useMemo(() => {
    return (
      <CanvasDraw
        ref={(canvasDraw) =>
          (canvasRef.current = canvasDraw as SignatureCanvas)
        }
        hideGrid
        canvasWidth={sizes.width}
        canvasHeight={sizes.height}
        brushColor="#000"
        brushRadius={sizes.brush}
        lazyRadius={sizes.brush}
        hideInterface={true}
        imgSrc={blobUrl}
        disabled={url}
      />
    );
  }, [sizes, blobUrl, url]);

  const getFile = useCallback(async (useGridChannel: boolean) => {
    if (!canvasRef.current) {
      console.error('Check the ref of the canvas');
      return;
    }
    const dataUrl = useGridChannel
      ? canvasRef.current.canvas.grid.toDataURL('png', 1)
      : canvasRef.current.getDataURL('png', false, '#fff');
    const blob = await resizeImage(dataUrl, 440, 220);
    const resizedFile = new File([blob], 'signature.png', {
      type: 'image/png',
    });
    return resizedFile;
  }, []);

  return {
    canvas,
    getFile,
    clear,
  };
};
