import styles from './appointmentPreview.module.scss';

import { MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  fixAppointmentStartEndDates,
  typeNameToSdObjType,
} from '@work4all/utils';
import {
  DatePeriodPresets,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';
import { filterAttendeesList } from '@work4all/utils/lib/filterAttendeesList';

import { usePreviewToolbarCustomActionsComponents } from '../../../dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import {
  CustomToolbar,
  CustomToolbarAction,
} from '../../../dataDisplay/actions-bar/ToolbarTypes';
import { Divider } from '../../../dataDisplay/divider/Divider';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import {
  InfoCard,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewWrapper,
} from '../components';
import {
  AppointmentStatePreviewPickerInput,
  BusinessPartnerPreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { appointmentPreviewFields } from './AppointmentPreviewContainer';

export type IAppointmentPreviewProps = {
  appointments: Appointment[];
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  customActions?: CustomToolbar<CustomToolbarAction>;
  onVisibilityToggle?: (visible: boolean) => void;
};

export function AppointmentPreview(props: IAppointmentPreviewProps) {
  const {
    appointments,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    customActions: custom,
    onVisibilityToggle,
  } = props;

  const { t } = useTranslation();

  const user = useUser();
  const { isInaccessible, isSomeInaccessible } = useInaccessibleFields();

  const titleIsAccessible = !isInaccessible(Entities.appointment, 'title');

  const timePeriodsIsAccessible = !isSomeInaccessible(Entities.appointment, [
    'startDate',
    'endDate',
    'isWholeDay',
  ]);

  const appointmentAttendeeListIsAccessible = !isInaccessible(
    Entities.appointment,
    'appointmentAttendeeList'
  );
  const noteIsAccessible = !isInaccessible(Entities.appointment, 'note');

  const [mutate] = useDataMutation<Appointment, EMode.upsert>({
    entity: Entities.appointment,
    mutationType: EMode.upsert,
    responseData: appointmentPreviewFields as unknown as Appointment,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.appointment,
    entries: appointments,
    mutate,
  });

  const originalAppointment = appointments[0];
  const appointment = useMemo(() => {
    const appointment = originalAppointment
      ? fixAppointmentStartEndDates(originalAppointment)
      : originalAppointment;
    return appointment;
  }, [originalAppointment]);

  const filteredAttendeesList: AppointmentAttendee[] = useMemo(() => {
    return filterAttendeesList(appointment);
  }, [appointment]);

  const selectedRowsIdsList = useMemo(() => {
    return appointments.map((x) => x.id);
  }, [appointments]);

  const customActions = usePreviewToolbarCustomActionsComponents({
    custom,
    selectedRowsIdsList,
    selectedRowsData: appointments,
  });

  return (
    <PreviewInputsProvider
      loading={loading}
      activePicker={activePicker}
      handleLock={handleLock}
      commonFields={commonFields}
      isMultiselect={isMultiselect}
      entity={Entities.appointment}
      locked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={appointments.length}
      canEditAllSelected={canEditAllSelected}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== appointment?.title) {
              onEdit({ title: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClick={onCloseClick}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          convertProps={convertProps}
          value={appointment?.title}
          accessor="title"
          disabled={isMultiselect || !titleIsAccessible}
          leftActions={customActions}
          onVisibilityToggle={onVisibilityToggle}
        />

        {isMultiselect && (
          <PreviewEditMessage entityId={String(appointment.id)} />
        )}

        <div className={styles.infoCardWrapper}>
          <AppointmentStatePreviewPickerInput
            labelTranslationKey="COMMON.STATUS"
            onClose={onPopoverClose}
            value={appointment?.appointmentState}
            onChange={(value) => {
              onEdit({ colorId: value.id });
            }}
            accessor="appointmentState"
          />

          {timePeriodsIsAccessible && (
            <InfoCard staticField={true} label={t('COMMON.TIMESPAN')}>
              {formatDateString(
                appointment?.startDate,
                DatePeriodPresets.PERIOD,
                {
                  periodEnd: appointment?.endDate,
                  isWholeDay: appointment?.isWholeDay,
                }
              )}
            </InfoCard>
          )}

          <BusinessPartnerPreviewPickerInput
            labelTranslationKey="COMMON.BUSINESS_PARTNER"
            onClose={onPopoverClose}
            value={appointment?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: typeNameToSdObjType(
                        value.__typename
                      ),
                      id: value.id,
                    }
                  : null,
                businessPartnerId: value ? value.id : 0,
                businessPartnerType: typeNameToSdObjType(value?.__typename),
                contactId: value?.mainContact?.id ?? null,
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={appointment?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />

          {appointmentAttendeeListIsAccessible && (
            <InfoCard staticField={true} label={t('COMMON.PARTICIPANT')}>
              {filteredAttendeesList
                ?.map(
                  (el) =>
                    el.user?.displayName ||
                    el.contact?.displayName ||
                    el.ressource?.displayName ||
                    el?.businessPartner?.data?.name
                )
                .join(', ')}
            </InfoCard>
          )}
        </div>
        <Divider className={styles.divider} />

        {!isMultiselect && noteIsAccessible ? (
          <PreviewTextContent>{appointment?.note}</PreviewTextContent>
        ) : null}

        <Divider className={styles.divider} />

        <EntityHistory
          fitContainer={false}
          entity={Entities.appointment}
          fullWidth
          id={appointment.id}
          className={styles.hist}
          breadcrumbTitle={appointment.title}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
