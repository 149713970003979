import { useCallback, useMemo } from 'react';

import { settings, useSetting } from '../../../settings';
import { UseCollapsedAreasProps } from '../types';

export const useCollapsedAreas = ({ entity, area }: UseCollapsedAreasProps) => {
  const collapsedAreas = useSetting(settings.tableCollapsedAreas());

  const isCollapsed = useMemo(() => {
    return collapsedAreas.value[area][entity] ?? true;
  }, [collapsedAreas.value, area, entity]);

  const toggleCollapse = useCallback(() => {
    collapsedAreas.set({
      ...collapsedAreas.value,
      [area]: {
        ...collapsedAreas.value[area],
        [entity]: !isCollapsed,
      },
    });
  }, [area, collapsedAreas, entity, isCollapsed]);

  return { isCollapsed, toggleCollapse };
};
