import styles from './ResourcesTimeline.module.scss';

import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventCreatedEvent,
  MbscResource,
} from '@mobiscroll/react';
import { Close, Groups, Save, Search } from '@mui/icons-material';
import { Box, IconButton, Popover, Stack } from '@mui/material';
import { popoverClasses } from '@mui/material/Popover';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  AppointmentQuickCreateDialog,
  AppointmentTemplate,
} from '../../../../../../../containers/calendar/components/AppointmentQuickCreateDialog';
import { CreateViewDialog } from '../../../../../../../containers/calendar/components/CreateViewDialog';
import { ViewsList } from '../../../../../../../containers/calendar/components/ViewsList';
import { useCalendarViews } from '../../../../../../../containers/calendar/hooks/use-calendar-views';
import { mapUsersToAppointmentAttendees } from '../../../../../../../containers/calendar/utils/map-users-to-appointment-attendees';
import { UserCompilationPicker } from '../../../../../../entity-picker/UsersCompilationPicker';
import { useColors } from '../project-planning-view/hooks/use-colors';
import {
  ZoomableTimeline,
  ZoomableTimelineProps,
} from '../zoomable-timeline/ZoomableTimeline';

import { useUsersById } from './hooks/get-users-by-id';
import { useResourceInfo } from './hooks/use-resource-info';

export interface DateRangeConf {
  start: Date;
  end: Date;
}

type ResourcesTimelineProps = Pick<
  ZoomableTimelineProps,
  | 'compactView'
  | 'date'
  | 'leftAreaCollapsed'
  | 'leftAreaWidth'
  | 'onSelectedDateChange'
  | 'componentRef'
  | 'zoom'
  | 'view'
  | 'onDestroy'
  | 'onPageLoaded'
> & {
  className?: string;
  highlightedDateRanges: DateRangeConf[];
};

export const ResourcesTimeline = (props: ResourcesTimelineProps) => {
  const { date, className, highlightedDateRanges, ...rest } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [events, setEvents] = useState<MbscCalendarEvent[]>([]);
  const [appointmentTemplate, setAppointmentTemplate] =
    useState<AppointmentTemplate>({});

  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [teamsDialogOpen, setTeamsDialogOpen] = useState(false);

  const [userList, setUserList] = useState<User[]>([]);
  const userIdList = useMemo(() => {
    return userList.map((x) => x.id);
  }, [userList]);

  const { t } = useTranslation();

  const userSearchBtnRef = useRef(null);
  const teamsButton = useRef(null);

  const resourceInfoRes = useResourceInfo({
    endDate: DateTime.fromJSDate(date).endOf('year').toJSDate(),
    startDate: DateTime.fromJSDate(date).startOf('year').toJSDate(),
    userIdList: userList.map((user) => user.id),
  });

  const { views, createView, deleteView } = useCalendarViews();

  const { data: usersFromIdList } = useUsersById({ idList: userIdList });

  useEffect(() => {
    setUserList(usersFromIdList);
  }, [usersFromIdList]);

  useEffect(() => {
    const events: MbscCalendarEvent[] = resourceInfoRes.data
      .filter((x) => Boolean(x.utilizedValue))
      .map((info) => {
        const tooltip = [
          info.vacationList.length ? t('COMMON.VACATION') : undefined,
          info.appointmentList?.length
            ? info.appointmentList?.length +
              ' ' +
              t(
                info.appointmentList?.length !== 1
                  ? 'COMMON.APPOINTMENT_plural'
                  : 'COMMON.APPOINTMENT'
              )
            : undefined,
          info.absenceList.length ? t('COMMON.SICKNESSDAY') : undefined,
        ]
          .filter(Boolean)
          .join('\n');

        return {
          id: info.userId + '-' + info.date.toString(),
          start: info.date,
          end: info.date,
          title: info.utilizedValue
            ? Math.round(info.utilizedValue).toString()
            : '',
          resource: info.userId,
          color: 'transparent',
          tooltip,
        };
      });
    setEvents(events);
  }, [resourceInfoRes.data, t]);

  const [createViewDialogOpen, setCreateViewDialogOpen] = useState(false);

  const onCreateView = useCallback(
    (name: string) => {
      createView({
        name,
        params: {
          appointmentStates: [],
          focusedUserIds: [],
          groupMode: 'vertical',
          range: 'month',
          userIds: userList.map((user) => user.id),
        },
      });
    },
    [createView, userList]
  );

  const renderResourceHeader = useCallback(() => {
    return (
      <Stack direction="row" p="0.125rem" pr={0} gap="0.5rem">
        <Box width="100%" ref={userSearchBtnRef}>
          <BaseActionButton
            icon={<Search />}
            title={t('COMMON.CHOOSE', { name: t('COMMON.USER_RESOURCE') })}
            onClick={() => {
              setUsersDialogOpen(true);
            }}
          />
        </Box>

        <Tooltip title={t('COMMON.TEAMS')}>
          <IconButton
            size="small"
            onClick={() => {
              setTeamsDialogOpen(true);
            }}
            ref={teamsButton}
          >
            <Groups />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('CALENDAR.VIEWS.SAVE_VIEW')}>
          <IconButton
            size="small"
            onClick={() => {
              setCreateViewDialogOpen(true);
            }}
            disabled={userList.length === 0}
          >
            <Save />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('INPUTS.CLEAR_ALL')}>
          <IconButton
            disabled={userList.length === 0}
            size="small"
            onClick={() => {
              setUserList([]);
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }, [t, userList.length]);

  const renderResource = useCallback(
    (resource: MbscResource) => {
      const user = userList.find((x) => x.id === resource.id);
      return !user ? (
        <div>{resource.id}</div>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          p="0.25rem 0.5rem"
          gap="0.5rem"
          pr="0"
          width="100%"
        >
          <HookedUserIcon userId={user.id} size="m" />
          <Box width="100%">{user.displayName}</Box>
          <IconButton
            size="small"
            onClick={() => {
              setUserList(userList.filter((x) => x.id !== user.id));
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      );
    },
    [userList]
  );

  const resources = useMemo<MbscResource[]>(() => {
    return userList.map(
      (user) => ({ id: user.id, name: user.displayName } as MbscResource)
    );
  }, [userList]);

  const defaultColors = useColors({ resources });

  const colors = useMemo<MbscCalendarColor[]>(() => {
    const statusColors = resourceInfoRes.data.map((item) => {
      return {
        start: item.date,
        end: item.date,
        resource: item.userId,
        background: item.utilizedValue >= 8 ? 'var(--userShade7)' : undefined,
      } as MbscCalendarColor;
    });

    const highlightColors = resources
      .map((resource) => {
        return highlightedDateRanges
          .map((item) => {
            const result = [];
            const currentDate = new Date(item.start);
            while (currentDate <= new Date(item.end)) {
              result.push({
                start: DateTime.fromJSDate(currentDate)
                  .startOf('day')
                  .toJSDate(),
                end: DateTime.fromJSDate(currentDate).endOf('day').toJSDate(),
                resource: resource.id,
                background: 'var(--userShade5)',
              } as MbscCalendarColor);
              currentDate.setDate(currentDate.getDate() + 1);
            }

            return result;
          })
          .flat();
      })
      .flat();

    return [...defaultColors, ...highlightColors, ...statusColors];
  }, [defaultColors, resourceInfoRes.data, resources, highlightedDateRanges]);

  const handleOnEventCreated = useCallback(
    (args: MbscEventCreatedEvent) => {
      const { event } = args;

      const users = (
        userList.length === 1
          ? userList
          : userList.filter((u) => u.id === event.resource)
      ).map((user) => ({ ...user, __typename: undefined }));

      const appointmentAttendeeList = mapUsersToAppointmentAttendees(users);

      const process = event.process as ProjectProcess;
      const businessPartnerType = process.customerId
        ? SdObjType.KUNDE
        : process.supplierId
        ? SdObjType.LIEFERANT
        : undefined;
      const businessPartnerId = process.customerId || process.supplierId;

      const template: AppointmentTemplate = {
        appointmentAttendeeList,
        startDate: DateTime.fromJSDate(event.start as Date).toISO(),
        endDate: DateTime.fromJSDate(event.end as Date).toISO(),
        isWholeDay: true,
        title: event.title,
        project: process.project,
        projectId: process.projectId,
        businessPartner: {
          businessPartnerType,
          data: process.customer || process.supplier,
          id: businessPartnerId,
        },
        businessPartnerId,
        businessPartnerType,
        projectProcess: process,
        projectProcessId: process.id,
        isRealAppointment: false,
      };

      setAppointmentTemplate(template);
      setDialogOpen(true);
      setEvents(events);
    },
    [events, userList]
  );

  return (
    <>
      {createViewDialogOpen && (
        <CreateViewDialog
          open={createViewDialogOpen}
          onClose={() => setCreateViewDialogOpen(false)}
          onConfirm={(name) => {
            setCreateViewDialogOpen(false);
            onCreateView(name);
          }}
          options={views.map((x) => x.name)}
        />
      )}
      {dialogOpen && (
        <AppointmentQuickCreateDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            resourceInfoRes.refetch();
          }}
          onOpenMask={() => null}
          template={appointmentTemplate}
        />
      )}

      {usersDialogOpen && (
        <Popover
          open={usersDialogOpen}
          onClose={() => setUsersDialogOpen(false)}
          anchorEl={userSearchBtnRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            [`.${popoverClasses.paper}`]: {
              height: 500,
            },
          }}
        >
          <UserCompilationPicker
            value={userList}
            onChange={setUserList}
            autoFocusTextInput
          />
        </Popover>
      )}

      {teamsDialogOpen && (
        <Popover
          open={teamsDialogOpen}
          onClose={() => setTeamsDialogOpen(false)}
          anchorEl={teamsButton.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ViewsList
            views={views}
            activeView={null}
            onSelectView={(view) => {
              setUserList([]);
              setTimeout(() => {
                setUserList(view.params.userIds.map((id) => ({ id })));
                setTeamsDialogOpen(false);
              });
            }}
            onDeleteView={(view) => deleteView(view.id)}
            title={t('COMMON.TEAMS')}
          />
        </Popover>
      )}

      <ZoomableTimeline
        events={events}
        showControls={false}
        renderResourceHeader={renderResourceHeader}
        renderResource={renderResource}
        resources={resources}
        colors={colors}
        externalDrop
        dragToMove={false}
        onEventCreated={handleOnEventCreated}
        className={clsx(styles.resourceTimeline, className || '')}
        date={date}
        {...rest}
      />
    </>
  );
};
