import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class UserApperanceConfigurationInput<T extends EMode = EMode.entity> {
  /** Alias: selectedBackgroundImage */
  selectedBackgroundImage?: string;
  /** Alias: disableBackgroundImage */
  disableBackgroundImage?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<UserApperanceConfigurationInput> = {
  selectedBackgroundImage: {
    alias: 'selectedBackgroundImage',
  },
  disableBackgroundImage: {
    alias: 'disableBackgroundImage',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userApperanceConfigurationInputEntityDefinition: EntitiyDefinition<UserApperanceConfigurationInput> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'UserApperanceConfigurationInput',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
