import { WithDeleted } from 'rxdb';

import { DbEntities } from '@work4all/models/lib/DB';

import { generateGraphQLQuery } from '@work4all/utils/lib/graphql-query-generation/generateGraphQLQuery';

import { SETTING_NAME_PREFIX } from '../../settings/config';
import { SettingEntity } from '../settingEntity';

import { createReplicateTenantCollection } from './create-replicate-tenant-collection';

const { query, queryString } = generateGraphQLQuery({
  operationName: 'ReplicateSettings',
  rootField: {
    name: 'getSettings',
    params: [
      { name: 'filterPrefix', type: 'String' },
      { name: 'syncDate', type: 'DateTime' },
    ],
  },
  fields: [
    { name: 'name' },
    { name: 'value' },
    { name: 'settingType' },
    { name: 'updateTime' },
    { name: 'insertTime' },
  ],
});

interface GetSettingsQueryCheckpoint {
  syncDate?: string | null;
}

export const replicateSettings = createReplicateTenantCollection(
  DbEntities.Setting
)<GetSettingsQueryCheckpoint, SettingEntity>(({ collection }) => {
  return {
    queryBuilder(checkpoint) {
      return {
        query: queryString,
        variables: {
          syncDate: checkpoint?.syncDate,
          filterPrefix: SETTING_NAME_PREFIX,
        },
        context: {
          hideError: true,
        },
      };
    },
    responseModifier(docs: SettingEntity[], origin, requestCheckpoint) {
      function createCheckpoint(
        doc: SettingEntity
      ): GetSettingsQueryCheckpoint {
        return {
          syncDate: doc.updateTime,
        };
      }

      function mapDocument(doc: SettingEntity): WithDeleted<SettingEntity> {
        return {
          ...doc,
          _id: collection.schema.getPrimaryOfDocumentData({
            name: doc.name,
            settingType: doc.settingType,
          }),
          _deleted: false,
        };
      }

      return {
        documents: docs.map(mapDocument),
        checkpoint:
          docs.length === 0 ? requestCheckpoint : createCheckpoint(docs[0]),
      };
    },
  };
});

export const GET_SETTINGS_QUERY = query;
