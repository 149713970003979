import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Article } from './Article.entity';
import { Contact } from './Contact.entity';
import { Customer } from './Customer.entity';
import { Project } from './Project.entity';
import { Supplier } from './Supplier.entity';

export type SearchResultItem<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? Customer<T> | Supplier<T> | Project<T> | Contact<T> | Article<T>
    : {
        /** Alias: Kunde */
        customer?: Customer<T>;
        /** Alias: Lieferant */
        supplier?: Supplier<T>;
        /** Alias: Projekt */
        project?: Project<T>;
        /** Alias: Ansprechpartner */
        contact?: Contact<T>;
        /** Alias: Artikel */
        article?: Article<T>;
      };

const fields: FieldDefinitions<SearchResultItem<EMode.query>> = {
  customer: {
    alias: 'customer',
    entity: Entities.customer,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  contact: {
    alias: 'contact',
    entity: Entities.contact,
  },
  article: {
    alias: 'article',
    entity: Entities.article,
  },
};

export const searchResultItemEntityDefinition: EntitiyDefinition<
  SearchResultItem<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: 'search',
    fragmentName: 'SearchResultItem',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'search',
        args: [
          { name: 'term', type: 'String!' },
          { name: 'types', type: '[SearchObjectType]!' },
          { name: 'querySize', type: 'Int' },
          { name: 'queryPage', type: 'Int' },
        ],
      },
    },
    params: [
      { name: 'term', type: 'String!' },
      { name: 'types', type: '[SearchObjectType]!' },
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
    ],
  },
  fieldDefinitions: fields,
};
