import styles from './control-view-left-content.module.scss';

import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useCollapsedAreas } from '../../../../hooks';
import { ControlViewLeftContantProps } from '../types';

export const ControlViewLeftContent = ({
  title,
  entity,
  children,
  custom,
  collapsible,
}: ControlViewLeftContantProps) => {
  const { toggleCollapse } = useCollapsedAreas({ entity, area: 'left' });

  if (custom) return children;

  if (!collapsible) {
    return <div className={styles.control}>{children}</div>;
  }

  return (
    <div className={styles.control}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>{title}</div>
          <IconButton onClick={toggleCollapse}>
            <ChevronLeft />
          </IconButton>
        </div>

        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};
