import { useMemo, useState } from 'react';

import { BottomTable } from '../components';
import { UseBottomTableProps, UseBottomTableReturn } from '../types';

export const useBottomTable = ({
  views,
  skip,
  prefilter,
}: UseBottomTableProps): UseBottomTableReturn => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return useMemo(() => {
    if (skip) return undefined;

    return {
      content: (
        <BottomTable
          views={views}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          prefilter={prefilter}
        />
      ),
      resizable: !isCollapsed,
    };
  }, [isCollapsed, prefilter, skip, views]);
};
