import { useEffect, useMemo, useState } from 'react';

import { useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ErpData } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/ErpData';
import { ErpObject } from '../../types';

interface GroupByArticleQueryProps {
  sourceEntity: Entities;
  data: ErpData[];
}

export const useGroupByArticleQuery = (props: GroupByArticleQueryProps) => {
  const { sourceEntity, data: inData } = props;
  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: sourceEntity,
      data: {
        id: null,
        contractNumber: null,
        currency: {
          id: null,
          name: null,
        },
        businessPartnerContactCombined: {
          businessPartner: {
            id: null,
            businessPartnerType: null,
            data: {
              customer: {
                id: null,
                name: null,
              },
              supplier: {
                id: null,
                name: null,
              },
            },
          },
        },
        positionList: [
          {
            id: null,
            index: null,
            number: null,
            longtext: null,
            positionKind: null,
            amount: null,
            backlog: null,
            unit: null,
            insteadOfTotalPrice: null,
            singlePriceNet: null,
            totalPriceNet: null,
            supplierId: null,
            supplier: {
              id: null,
              name: null,
            },
            articleId: null,
            article: {
              id: null,
              number: null,
              name: null,
              unit: {
                id: null,
                name: null,
              },
            },
          },
        ],
      },
      filter: [{ id: { $in: inData.map((x) => x.id) } }],
    };
  }, [inData, sourceEntity]);

  const erpData = useDataProvider<ErpObject>(requestData);
  const [lastErpData, setLastErpData] = useState<ErpObject[]>([]);
  useEffect(() => {
    if (erpData.data.length) setLastErpData(erpData.data);
  }, [erpData.data]);

  return {
    erpData: { loading: erpData.loading, data: lastErpData },
  };
};
