import { Document } from '../Classes/Document.entity';
import { Entities } from '../Enums/Entities.enum';

export const PARENT_DOCUMENT_ID_PROPERTY: keyof Document = 'documentParentCode';

export const DOCUMENT_SHARED_DEFAULT_PREFILTER = {
  $or: [
    { [PARENT_DOCUMENT_ID_PROPERTY]: { $eq: 0 } },
    { [PARENT_DOCUMENT_ID_PROPERTY]: { $eq: null } },
  ],
};

export const getDefaultPrefilter = (entity: Entities, variant?: string) => {
  switch (entity) {
    case Entities.document: {
      const infoWindowName = variant || '';
      return [
        DOCUMENT_SHARED_DEFAULT_PREFILTER,
        { infoWindowName: { $eq: infoWindowName } },
      ];
    }
    case Entities.appointment:
      return [{ parentId: { $eq: 0 } }];
  }
  return null;
};

export const EMAIL_SIGNATURE_KEYS_ARGUMENTS = {
  body: {
    resolveInlineImages: true,
    resolveTextmarken: true,
  },
};
