import { IconButton, IconButtonProps } from '@mui/material';
import React, { MutableRefObject, useMemo } from 'react';

import { Tooltip } from '../../components/tooltip/Tooltip';
import { getLinkData } from '../base-action-button/utils';

import { BaseActionProps } from './types';

export type ActionTooltipProps = BaseActionProps & {
  icon: JSX.Element;
  tooltip: string;
  className?: string;
  rootRef?: MutableRefObject<HTMLButtonElement>;
  hideTooltip?: boolean;
  disableReason?: string;
};

export function IconButtonWithTooltip<T extends React.ElementType>(
  props: IconButtonProps<T, ActionTooltipProps>
) {
  const {
    href,
    tooltip,
    className,
    icon,
    rootRef,
    dataTestId,
    disableReason: _disableReason,
    hideTooltip = false,
    ...other
  } = props;
  const { linkProps } = getLinkData(href);

  const tooltipValue = useMemo(() => {
    if (props.disabled && _disableReason) return _disableReason;

    if (tooltip) return tooltip;
  }, [_disableReason, props.disabled, tooltip]);

  const element = (
    <IconButton
      ref={rootRef}
      sx={{ height: '100%' }}
      color="primary"
      data-testid={dataTestId}
      {...other}
      {...linkProps}
    >
      {icon}
    </IconButton>
  );

  if (className) return <></>;

  return (
    <Tooltip hide={hideTooltip} title={tooltipValue} placement="top">
      {className ? <span className={className}>{element}</span> : element}
    </Tooltip>
  );
}
