import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { CreateProjectDirectoryType } from '../Enums/CreateProjectDirectoryType.enum';
import { EMode } from '../Enums/EMode.enum';

export class InputSetProjectDirectory<T extends EMode = EMode.entity> {
  /** Alias: fspId */
  fspId?: any;
  /** Alias: createProjectDirectoryType */
  createProjectDirectoryType?: CreateProjectDirectoryType;
  /** Alias: projectDirectoryName */
  projectDirectoryName?: any;

  __typename?: string;
}

const fields: FieldDefinitions<InputSetProjectDirectory> = {
  fspId: {
    alias: 'fspId',
  },
  createProjectDirectoryType: {
    alias: 'createProjectDirectoryType',
  },
  projectDirectoryName: {
    alias: 'projectDirectoryName',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSetProjectDirectoryEntityDefinition: EntitiyDefinition<InputSetProjectDirectory> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSetProjectDirectory',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
