import { Fragment } from 'react';

export interface ISearchTextResultProps {
  result: ISearchTextResult;
}

export type ISearchTextResultFragment = {
  match: boolean;
  position: [start: number, end: number];
  text: string;
};

export type ISearchTextResult = {
  match: boolean;
  fragments: ISearchTextResultFragment[];
};

export function searchText(
  text: string,
  searchText: string
): ISearchTextResult {
  const fragments = findFragments(text, searchText);
  const match = fragments.length > 1 || !!fragments[0]?.match;
  return { match, fragments };
}

function findFragments(
  text: string,
  searchText: string
): ISearchTextResultFragment[] {
  if (!searchText) {
    return [
      {
        match: false,
        text,
        position: [0, text?.length || 0],
      },
    ];
  }

  if (!text) {
    return [];
  }

  //replace dashes too eg, to find E-Mail if searching for email
  //we replace dashes with a whitespace character so we can
  //have a correct calculation for the position
  const normalizedText = text?.toString().toLowerCase().replace('-', ' ');
  const normalizedSearchText = searchText?.toLowerCase().replace('-', ' ');

  const fragments: ISearchTextResultFragment[] = [];

  let currentIndex = 0;

  let foundIndex = normalizedText.indexOf(normalizedSearchText, currentIndex);

  while (foundIndex !== -1) {
    if (currentIndex !== foundIndex) {
      fragments.push({
        match: false,
        text: text.toString().slice(currentIndex, foundIndex),
        position: [currentIndex, foundIndex],
      });
    }

    const foundIndexEnd = foundIndex + normalizedSearchText.length;

    fragments.push({
      match: true,
      text: text.toString().slice(foundIndex, foundIndexEnd),
      position: [foundIndex, foundIndexEnd],
    });

    currentIndex = foundIndexEnd;
    foundIndex = normalizedText.indexOf(normalizedSearchText, currentIndex);
  }

  if (currentIndex !== normalizedText.length) {
    fragments.push({
      match: false,
      text: text.toString().slice(currentIndex, normalizedText.length),
      position: [currentIndex, normalizedText.length],
    });
  }

  return fragments;
}

export function SearchTextResult(props: ISearchTextResultProps) {
  const { result } = props;

  function renderFragment(fragment: ISearchTextResultFragment, index: number) {
    return fragment.match ? (
      <mark key={index}>{fragment.text}</mark>
    ) : (
      <Fragment key={index}>{fragment.text}</Fragment>
    );
  }

  return <>{result.fragments.map(renderFragment)}</>;
}
