import React, { useCallback, useMemo } from 'react';
import { TableInstance } from 'react-table';

import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';

import { EntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useCloneConvertErp } from './offers-table/hooks/use-clone-convert-erp';
import schema from './schemata/demand-table-schema.json';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const DemandsTable = React.forwardRef<TableInstance, Props>(
  function DemandsTable(_props, ref) {
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Demand,
      never
    >({
      schema: schema as never,
      defaultSort,
    });
    const template = useErpHandlersTemplate();

    const rowModifiers = useCallback(
      (value: Demand) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { actions } = useCloneConvertErp({
      entity: Entities.demand,
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <EntityTable
        ref={ref}
        template={template}
        actions={{
          custom,
        }}
        {...dataTable}
        prepareRowDisplayModifiers={rowModifiers}
      />
    );
  }
);
