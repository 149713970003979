import { useCallback } from 'react';

import { LanguageResource } from '@work4all/utils/lib/i18n/LanguageResource.enum';
import deBase from '@work4all/utils/lib/i18n/translation-gen/de/de-base.json';
import deDomainServiceProvider from '@work4all/utils/lib/i18n/translation-gen/de/de-domain-service-provider.json';
import enBase from '@work4all/utils/lib/i18n/translation-gen/en/en-base.json';
import enDomainServiceProvider from '@work4all/utils/lib/i18n/translation-gen/en/en-domain-service-provider.json';

export const useErpTranslation = (props?: {
  languageResources?: LanguageResource[];
}) => {
  const { languageResources } = props;

  const t = useCallback(
    (
      lang: 'de' | 'en',
      translationKey: string,
      params?: Record<string, string | number>
    ) => {
      let translationFile = lang === 'de' ? deBase : enBase;

      languageResources?.forEach((resource) => {
        if (resource === LanguageResource.ServiceProvider) {
          translationFile = {
            ...translationFile,
            ...(lang === 'de'
              ? deDomainServiceProvider
              : enDomainServiceProvider),
          };
        }
      });

      let translation = translationFile[translationKey] || '';

      if (params) {
        Object.keys(params).forEach((key) => {
          translation = translation.replace('{{' + key + '}}', params[key]);
        });
      }

      return translation;
    },
    [languageResources]
  );

  return { t };
};
