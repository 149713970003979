import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { User } from './User.entity';

export class AppointmentAttendee<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: erstellerBenutzerCode */
  creatorUserId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: terminCode */
  appointmentId?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ressource */
  ressource?: User<T>;
  /** Alias: anzeigename */
  displayName?: string;

  __typename?: string;
}

const fields: FieldDefinitions<AppointmentAttendee> = {
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  creatorUserId: {
    alias: 'erstellerBenutzerCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  appointmentId: {
    alias: 'terminCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  ressource: {
    alias: 'ressource',
    entity: Entities.user,
  },
  displayName: {
    alias: 'anzeigename',
  },
  __typename: {
    alias: '__typename',
  },
};

export const appointmentAttendeeEntityDefinition: EntitiyDefinition<AppointmentAttendee> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'TerminTeilnehmer',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
