import { useMemo } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { nameof } from '../../helper/nameof';
import { useDataProvider } from '../data-provider/useDataProvider';

import { UsersContextProvider } from './users-context';

export interface UsersContextProviderProps {
  children?: React.ReactNode;
}

const request: DataRequest = {
  operationName: 'GetAllUsersForMentions',
  entity: Entities.user,
  filter: [
    {
      $or: [
        { userKind: { $eq: UserClass.BENUTZER } },
        { userKind: { $eq: UserClass.MITARBEITER } },
      ],
    },
  ],
  sort: [
    { field: nameof<User>('displayName'), direction: SortDirection.ASCENDING },
  ],
  data: {
    id: null,
    firstName: null,
    lastName: null,
    displayName: null,
    hasLeft: null,
    groupId: null,
  } as User,
};

export function UsersContext(props: UsersContextProviderProps) {
  const { children } = props;

  const response = useDataProvider(request, false, 9999);

  const users = response.data;

  const usersById = useMemo(() => {
    return users.reduce<Record<string, User>>((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});
  }, [users]);

  const context = useMemo(() => ({ users, usersById }), [users, usersById]);

  return (
    <UsersContextProvider value={context}>{children}</UsersContextProvider>
  );
}
