import { gql } from '@apollo/client';

export const CONVERT_INVOICE_TO_RA_MUTATION = gql`
  mutation ConvertInvoiceToRe(
    $invoiceId: Int!
    $reportId: Int!
    $createArchivePdf: Boolean
    $awaitArchivePdf: Boolean
  ) {
    invoiceToOutgoingInvoiceLedger(
      input: {
        invoiceCode: $invoiceId
        archivePdfReportCode: $reportId
        createArchivePdf: $createArchivePdf
        awaitArchivePdf: $awaitArchivePdf
      }
    )
  }
`;
