import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LanguageToggle = () => {
  const { t, i18n } = useTranslation();

  return (
    <ToggleButtonGroup
      value={i18n.language}
      exclusive
      aria-label="Language"
      sx={{ flex: 'none' }}
    >
      <ToggleButton value="en" onClick={() => i18n.changeLanguage('en')}>
        {t('USER.LANGUAGE.EN')}
      </ToggleButton>
      <ToggleButton value="de" onClick={() => i18n.changeLanguage('de')}>
        {t('USER.LANGUAGE.DE')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
