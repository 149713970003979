import { useCallback } from 'react';

import { useLocation, useNavigate } from '@work4all/data';

import { useHistoryStack } from '../history-stack';

export const useHistoryStackTitleUpdate = (isCreateMode: boolean) => {
  const { updateCurrent } = useHistoryStack();

  const location = useLocation();
  const navigate = useNavigate();
  return useCallback(
    (id: string | number, title: string) => {
      let replaced = false;
      if (isCreateMode) {
        replaced = location.pathname.slice().includes('new');
        if (replaced)
          navigate(location.pathname.slice().replace('new', `${id}`), {
            replace: true,
          });
      }

      updateCurrent((baseState) => {
        const newState = { ...baseState };
        newState.subTitle = title;
        newState.breadcrumbTitle = title;
        return newState;
      });
      return replaced;
    },
    [isCreateMode, updateCurrent, navigate, location.pathname]
  );
};
