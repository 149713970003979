import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectStepLinkType } from '@work4all/models/lib/Enums/ProjectStepLinkType.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type ProjectStepLinkTypePickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: ProjectStepLinkType; name: string }, TMultiple>;

export function ProjectStepLinkTypePicker<TMultiple extends boolean>(
  props: ProjectStepLinkTypePickerProps<TMultiple>
) {
  const { t } = useTranslation();
  const data = useMemo(() => {
    return Object.keys(ProjectStepLinkType)
      .filter((x) => x !== ProjectStepLinkType.OLD_FINISH_TO_START)
      .map((key) => {
        const id = ProjectStepLinkType[key];

        return {
          id,
          name: t(`PROJECT_STEP_LINK_TYPE.${id}`),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [t]);

  return <FixedDataPicker dataSet={data} {...props} />;
}
