import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputSalesOpportunityRatingSet<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: ratingDate */
  ratingDate?: string;
  /** Alias: userCode */
  userCode?: number;
  /** Alias: ratingPercent */
  ratingPercent?: number;
  /** Alias: comment */
  comment?: string;
  /** Alias: value */
  value?: number;
  /** Alias: status */
  status?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputSalesOpportunityRatingSet> = {
  code: {
    alias: 'code',
  },
  ratingDate: {
    alias: 'ratingDate',
  },
  userCode: {
    alias: 'userCode',
  },
  ratingPercent: {
    alias: 'ratingPercent',
  },
  comment: {
    alias: 'comment',
  },
  value: {
    alias: 'value',
  },
  status: {
    alias: 'status',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputSalesOpportunityRatingSetEntityDefinition: EntitiyDefinition<InputSalesOpportunityRatingSet> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputSalesOpportunityRatingSet',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
