import { useRef, useState } from 'react';

export function useKeyboardNavigation<T>(
  items: T[],
  interactiveItems: (item: T) => boolean,
  onSelect: (item: T) => void
) {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(0);

  const interactiveIndices = items.reduce<number[]>((acc, _, index) => {
    if (interactiveItems(items[index])) {
      acc.push(index);
    }
    return acc;
  }, []);

  const moveFocus = (newIndex: number) => {
    setFocusedIndex(newIndex);
    itemRefs.current[newIndex]?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const currentIndex =
      focusedIndex !== null ? interactiveIndices.indexOf(focusedIndex) : -1;

    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (currentIndex > 0) {
          const newIndex = interactiveIndices[currentIndex - 1];
          moveFocus(newIndex);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (currentIndex < interactiveIndices.length - 1) {
          const newIndex = interactiveIndices[currentIndex + 1];
          moveFocus(newIndex);
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (focusedIndex !== null && items[focusedIndex]) {
          onSelect(items[focusedIndex]);
        }
        break;
    }
  };

  return { onKeyDown, itemRefs, focusedIndex };
}
