import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InvoiceFormPicker } from '../../../../components/entity-picker/invoice-form-picker/InvoiceFormPicker';
import { FilterCard } from '../filter-card/FilterCard';

type Props = {
  title: string;
  value: { id: string; name: string }[] | null;
  onChange: (value: { id: string; name: string }[]) => void;
  expanded: boolean;
  onExpandedChange: (val: boolean) => void;
};

export const InvoiceFormFilterCard: React.FC<Props> = (props) => {
  const { title, value, onChange, expanded, onExpandedChange } = props;

  const { t } = useTranslation();

  const filterValueText: string = useMemo(() => {
    if (!value || value.length === 0) return null;
    if (value.length === 1) return `INVOICE_FORM.${value[0].id}`;
    return `${value.length} ${t('COMMON.CHOSEN')}`;
  }, [value, t]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!!filterValueText}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onChange([]);
      }}
    >
      <InvoiceFormPicker
        multiple={true}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
    </FilterCard>
  );
};
