import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

export const getSortedEmailTemplateList = (
  templates: EMailTemplate[],
  standardTemplates:
    | {
        [K in EMailTemplateKind]?: string;
      }
    | null,
  kind: EMailTemplateKind | EMailTemplateKind[],
  mainKind: EMailTemplateKind
) => {
  const sortedTemplates = [...templates].sort((a, b) => {
    // Check if either a or b is a standard template
    const isAStandardTemplate =
      a.id === standardTemplates?.[a.eMailTemplateKind];
    const isBStandardTemplate =
      b.id === standardTemplates?.[b.eMailTemplateKind];

    // If one of them is a standard template, prioritize it on the list
    if (isAStandardTemplate && !isBStandardTemplate) {
      return -1;
    } else if (!isAStandardTemplate && isBStandardTemplate) {
      return 1;
    } else {
      // Get the index of eMailTemplateKind
      const indexA = kind.indexOf(a.eMailTemplateKind);
      const indexB = kind.indexOf(b.eMailTemplateKind);

      if (indexA === indexB) {
        // Sort alphabeticaly in groups defined by eMailTemplateKind
        return a.name.localeCompare(b.name);
      }

      return indexA - indexB;
    }
  });

  const mappedTemplates = sortedTemplates.map((item) => {
    const isStandard = standardTemplates?.[item.eMailTemplateKind] === item.id;
    return { ...item, isStandard };
  });

  // As all of the templates for the language versions are separate entities,
  // we must therefore filter out only the main ones with the code 0 to display
  // the on the email template lists
  const mainLanguageTemplates = mappedTemplates.filter(
    (template) => template.languageCode === 0
  );

  const mainKindTemplates = mainLanguageTemplates.filter(
    (template) => template.eMailTemplateKind === mainKind
  );
  const restTemplates = mainLanguageTemplates.filter(
    (template) => template.eMailTemplateKind !== mainKind
  );

  return {
    mainLanguageTemplates,
    mainKindTemplates,
    restTemplates,
  };
};
