import { DependencyList, useCallback, useContext, useEffect } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { LockContext } from './LockContext';

export const useLock = (
  args?: {
    subEntityType?: Entities;
    subEntityIds?: string[] | number[];
    forcedObjectType?: string;
  },
  /**
   * Indicates if auto lock is needed and what are the dependencies.
   */
  dependencies?: DependencyList
) => {
  const ctx = useContext(LockContext);

  const { lock: orginalLock, unlock: originalUnlock } = ctx;

  const memoArgs = useDeepMemo(() => args, [args]);
  const { subEntityType, subEntityIds, forcedObjectType } = memoArgs ?? {};
  const hasRequiredArgs =
    (subEntityType || forcedObjectType) && subEntityIds?.length;

  const lock = useCallback(() => {
    if (hasRequiredArgs) {
      orginalLock(memoArgs);
    }
  }, [hasRequiredArgs, memoArgs, orginalLock]);

  const unlock = useCallback(() => {
    if (hasRequiredArgs) {
      originalUnlock(memoArgs);
    }
  }, [hasRequiredArgs, memoArgs, originalUnlock]);

  const deps = dependencies ? [...dependencies, lock, unlock] : [lock, unlock];
  useEffect(() => {
    if (!dependencies) return;
    lock();
    return () => {
      unlock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    ...ctx,
    lock,
    unlock,
  };
};
