import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { MaskExtendedConfig } from '../../../types';
import { useAssignableTemplateEntity } from '../../../utils/use-assignable-template-entity';

// Default project status id for new projects: '01 Ungeplant'
const DEFAULT_PROJECT_STATUS_ID = 20214219;

export const useProjectDefaultData = (mask: MaskExtendedConfig): Project => {
  const template = useAssignableTemplateEntity(mask);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.lookUp,
      data: { id: null, name: null },
      completeDataResponse: true,
      vars: {
        type: LookupType.PROJEKT_STATI,
      },
    };
  }, []);

  const response = useDataProvider(requestData);

  const defaultProjectStatus = useMemo(() => {
    return response?.data?.find(
      (status) => status.id === DEFAULT_PROJECT_STATUS_ID
    );
  }, [response?.data]);

  return useMemo<Project>(() => {
    const project: Project = {};
    project.startDateOuter = new Date().toISOString();
    project.projectStatus = defaultProjectStatus ?? null;
    project.projectStatusId = defaultProjectStatus?.id ?? 0;

    const contact =
      template?.data?.contact ||
      template?.data?.businessPartner?.data?.mainContact ||
      null;
    if (template?.data?.businessPartnerType === SdObjType.KUNDE) {
      project.customer = template?.data?.businessPartner?.data;
      project.customerId = template?.data?.businessPartner?.data?.id;
      project.customerContact = contact;
      project.customerContactId = contact?.id;
    } else if (template?.data?.businessPartnerType === SdObjType.LIEFERANT) {
      project.supplier = template?.data?.businessPartner?.data;
      project.supplierId = template?.data?.businessPartner?.data?.id;
      project.supplierContact = contact;
      project.supplierContactId = contact?.id;
    }
    return project;
  }, [template, defaultProjectStatus]);
};
