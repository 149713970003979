import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ProjectStepLinkDirectionPicker,
  ProjectStepLinkDirectionPickerProps,
} from '@work4all/components/lib/components/entity-picker/project-step-link-direction-picker copy/ProjectStepLinkDirectionPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<ProjectStepLinkDirectionPickerProps<false>, 'multiple'> {
  error?: string;
}

export const ProjectStepLinkDirectionPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { t } = useTranslation();
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  return (
    <EntityPickerPopover
      picker={
        <ProjectStepLinkDirectionPicker {...pickerProps} multiple={false} />
      }
    >
      <PickerTargetButton
        ref={ref}
        onClear={() => {
          onChange(null);
        }}
        label={t('PROJECT_STEP_LINK_DIRECTION.LABEL')}
        error={error}
        value={value?.name || ''}
      />
    </EntityPickerPopover>
  );
});
