import styles from './ActionsMenuMobile.module.scss';

import { Settings } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { reactRefSetter } from '@work4all/utils';

export interface ActionConfigOptional {
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  href?: string;
  label?: string;
  icon?: React.ReactNode;
}

export type ActionConfigRequired =
  | { onClick: React.MouseEventHandler<HTMLElement> }
  | { href: string };

export type ActionConfig = ActionConfigOptional & ActionConfigRequired;

export type ActionType =
  | 'group'
  | 'filter'
  | 'columns'
  | 'export'
  | 'remove'
  | 'edit'
  | 'add'
  | 'sortColumn'
  | 'sortOrder'
  | 'layout'
  | 'settings'
  | 'createWidget';

const listActions: ActionType[] = [
  'group',
  'columns',
  'layout',
  'createWidget',
  'settings',
];
const sortActions: ActionType[] = ['sortColumn', 'sortOrder'];
const entityActions: ActionType[] = ['export', 'remove', 'edit'];

const icons: Partial<Record<ActionType, React.ReactNode>> = {
  add: <AddIcon />,
  columns: <ViewColumnIcon />,
  edit: <EditIcon />,
  export: <GroupWorkIcon />,
  filter: <TuneIcon />,
  group: <GroupWorkIcon />,
  remove: <DeleteIcon />,
  settings: <Settings />,
  createWidget: <WidgetsIcon />,
};

export interface ActionsMenuMobileProps
  extends Partial<Record<ActionType, ActionConfig | null>> {
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
}

// TODO Add i18n

export function ActionsMenuMobile(props: ActionsMenuMobileProps) {
  const { buttonRef } = props;
  const { t } = useTranslation();

  const labels: Partial<Record<ActionType, string>> = {
    add: t('COMMON.NEW'),
    columns: t('MASK.COLUMNS'),
    edit: t('COMMON.EDIT'),
    export: t('MASK.EXPORT'),
    filter: t('COMMON.FILTER'),
    group: t('INPUTS.GROUPING'),
    remove: t('MASK.REMOVE'),
    settings: t('SETTINGS.SETTINGS'),
    createWidget: t('INPUTS.CREATE_WIDGET'),
  };

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const { handleClick, ...menuState } = usePopoverState('top');
  const { onClose: closeMenu } = menuState;

  const navigate = useNavigate();

  const withAutoClose =
    (handler: React.MouseEventHandler<HTMLElement>) =>
    (event: React.MouseEvent<HTMLElement>) => {
      handler(event);
      closeMenu();
    };

  const action = (actionType: ActionType) => {
    const config = props[actionType];

    if (!config) {
      return null;
    }

    const { disabled, onClick, href } = config;

    const icon = config.icon ?? icons[actionType];
    const label = config.label ?? labels[actionType];

    return (
      <Tooltip
        activateForDisabled={{ activeateOnClick: true }}
        title={disabled ? t('RIGHTS.MISSING') : undefined}
      >
        <MenuItem
          disabled={disabled}
          onClick={withAutoClose((event) => {
            if (onClick) onClick(event);
            else if (href) navigate(href);
            else throw new Error('No action handler or href!');
          })}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      </Tooltip>
    );
  };

  const listActionsPresent = listActions.some((actionType) => {
    return props[actionType];
  });

  const sortActionsPresent = sortActions.some((actionType) => {
    return props[actionType];
  });

  const entityActionsPresent = entityActions.some((actionType) => {
    return props[actionType];
  });

  const isAnyActionPresent =
    listActionsPresent || sortActionsPresent || entityActionsPresent;

  if (!isAnyActionPresent) {
    return null;
  }

  return (
    <>
      <IconButton
        ref={reactRefSetter(buttonRef, menuButtonRef)}
        color="primary"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      {/*
        Menu does not accept fragments as children, so we pass them as an array here.
        MUI will add the keys to the array elements itself.
      */}
      <Menu
        {...menuState}
        PopoverClasses={{ paper: styles['actions-menu-mobile'] }}
      >
        {[
          listActionsPresent
            ? [
                <Divider className={styles.divider}>Liste</Divider>,

                ...listActions.map((actionType) => action(actionType)),
              ]
            : null,

          sortActionsPresent
            ? [
                <Divider className={styles.divider}>Sortierung</Divider>,

                ...sortActions.map((actionType) => action(actionType)),
              ]
            : null,

          entityActionsPresent
            ? [
                <Divider className={styles.divider}>Entity</Divider>,

                ...entityActions.map((actionType) => action(actionType)),
              ]
            : null,
        ]}
      </Menu>
    </>
  );
}
