import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IProjectProcessPickerProps,
  ProjectProcessPicker,
} from '@work4all/components/lib/components/entity-picker/project-process-picker/ProjectProcessPicker';

import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface ProjectProcessPickerFieldProps
  extends Omit<IProjectProcessPickerProps, 'multiple'> {
  error?: string;
  name?: string;
  label?: string;
  allowSelection?: ProjectProcessKind[];
}

export const ProjectProcessPickerField = React.forwardRef<
  HTMLDivElement,
  ProjectProcessPickerFieldProps
>(function ProjectProcessPickerField(props, ref) {
  const { error, label, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <EntityPickerPopover
      fullscreen={isMobile}
      picker={<ProjectProcessPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value?.process ?? ''}
        name={props.name}
        label={label || t('COMMON.PROJECTPROCESS')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
