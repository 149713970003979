import styles from './General.module.scss';

import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../../../../../components';
import { ReViewModelFormValue } from '../../../../../types';

export function General() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ReViewModelFormValue>();
  const { control, register } = form;

  return (
    <Collapse className={styles.general} title={t('MASK.GENERAL')} defaultOpen>
      <ControlWrapper columns={3}>
        <DateTimeInputPicker
          dateLabel={t('INPUTS.RECEIVED_DATE')}
          withTime={false}
          clearable={false}
          {...register('dateOfReceipt')}
        />

        <DateTimeInputPicker
          dateLabel={t('INPUTS.DATE_OF_INVOICE')}
          withTime={false}
          clearable={false}
          {...register('invoiceDate')}
        />

        <DateTimeInputPicker
          dateLabel={t('INPUTS.BOOKING_DATE')}
          withTime={false}
          clearable={false}
          {...register('bookingDate')}
        />

        <Tooltip title={t('TOOLTIP.INTERNAL_DOCUMENT')}>
          <div>
            <ControllerPlus
              control={control}
              name="invoiceNumber"
              render={({ field }) => (
                <LabeledInput
                  label={t('INPUTS.DOCUMENT_NUMBER_INTERNAL')}
                  value={field.value === 0 ? '' : field.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = value ? parseInt(value) : 0;
                    field.onChange({
                      target: {
                        value: newValue,
                      },
                    });
                  }}
                  error={field.error}
                  type="number"
                />
              )}
            />
          </div>
        </Tooltip>

        {/* TODO: REMOVE if not possible to prefill from API */}
        {/* <LabeledInput
          label={t('INPUTS.DOCUMENT_NUMBER_INTERNAL')}
          {...register('rNumber')}
          type="number"
        /> */}

        <LabeledInput
          label={t('INPUTS.BILL_NUMBER')}
          {...register('originalInvoiceNumber')}
        />

        <LabeledInput
          label={t('INPUTS.REFERENCE')}
          {...register('reference')}
        />
      </ControlWrapper>

      <LabeledInput
        label={t('INPUTS.NOTE')}
        {...register('note')}
        multiline={true}
      />
    </Collapse>
  );
}
