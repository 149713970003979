import styles from './RelationsPopover.module.scss';

import { CloseRounded } from '@mui/icons-material';
import {
  Backdrop,
  IconButton,
  Popover,
  PopoverProps,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDataMutation, useFormPlus } from '@work4all/data';

import { AddressConnection } from '@work4all/models/lib/Classes/AddressConnection.entity';
import { InputAddressConnectionRelationAdd } from '@work4all/models/lib/Classes/InputAddressConnectionRelationAdd.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { BaseActionButton } from '../../../containers/mask-overlays/locked-inputs';
import { ControlWrapper } from '../../../containers/mask-overlays/mask-overlay/components';
import { useRelationData } from '../hooks';
import { RelationsPopoverProps } from '../interfaces';
import { RelationsPopoverFormValue } from '../types';

import { BusinessPartnerPickerWithPrefix } from './BusinessPartnerPickerWithPrefix';
import { RelationPickerWithArrow } from './RelationPickerWithArrow';

export const RelationsPopover = (props: RelationsPopoverProps) => {
  if (!props.open) {
    return null;
  }

  return <RelationsPopoverContent {...props} />;
};

export const RelationsPopoverContent = ({
  open,
  anchorEl,
  onClose,
  fullScreenMode,
  businessPartnerId,
  businessPartnerType,
  relationId,
  isCreateMode,
}: RelationsPopoverProps) => {
  const { t } = useTranslation();
  const popoverProps: PopoverProps = useMemo(() => {
    const common = {
      open: open,
      classes: {
        paper: styles.popoverPaper,
      },
    };

    if (fullScreenMode) {
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'center',
        },
        BackdropComponent: Backdrop,
        BackdropProps: { className: styles.backdrop },
        ...common,
      };
    }

    return { anchorEl, onClose, ...common };
  }, [fullScreenMode, anchorEl, onClose, open]);

  const relationsData = useRelationData({
    businessPartnerId,
    businessPartnerType,
    relationId,
    isCreateMode,
  });

  const form = useFormPlus<RelationsPopoverFormValue>({
    mode: 'onChange',
    defaultValues: relationsData,
  });

  const { reset, formState, getValues, handleSubmit } = form;

  const doesFieldsFilled = !!(
    getValues('businessPartnerA') &&
    getValues('businessPartnerB') &&
    getValues('relation') &&
    getValues('reverseRelation')
  );

  const canSubmit = isCreateMode
    ? doesFieldsFilled
    : formState.isDirty && doesFieldsFilled;

  useEffect(() => {
    reset(relationsData);
  }, [relationsData, reset]);

  const [mutate] = useDataMutation<
    AddressConnection,
    EMode.upsert,
    InputAddressConnectionRelationAdd
  >({
    entity: Entities.addressConnection,
    mutationType: EMode.upsert,
    responseData: { id: null },
  });

  const onSubmit = useCallback(
    async (data: RelationsPopoverFormValue) => {
      let addressConnection: AddressConnection = {};
      const reverseAddressConnection: AddressConnection = {};

      const dirtyFields = formState.dirtyFields;

      if (isCreateMode) {
        addressConnection = {
          sdObjMemberIdA: data.businessPartnerA.data.id,
          sdObjMemberIdB: data.businessPartnerB.data.id,
          sdObjTypeA: typeNameToSdObjType(
            data.businessPartnerA.data.__typename
          ),
          sdObjTypeB: typeNameToSdObjType(
            data.businessPartnerB.data.__typename
          ),
          connectionId: data.relation.connection.id,
        };

        await mutate(addressConnection, {
          arguments: {
            reverseConnectionId: data.reverseRelation.connection.id,
          },
        });
      } else {
        if (dirtyFields?.businessPartnerB) {
          const id = data.businessPartnerB.data.id;
          const type = typeNameToSdObjType(
            data.businessPartnerB.data.__typename
          );

          addressConnection['sdObjMemberIdB'] = id;
          addressConnection['sdObjTypeB'] = type;

          reverseAddressConnection['sdObjMemberIdA'] = id;
          reverseAddressConnection['sdObjTypeA'] = type;
        }

        if (dirtyFields?.relation) {
          addressConnection['connectionId'] = data.relation.connection.id;
        }

        if (dirtyFields?.reverseRelation) {
          reverseAddressConnection['connectionId'] =
            data.reverseRelation.connection.id;
        }

        if (Object.keys(addressConnection).length > 0) {
          addressConnection['id'] = data.relation.id;
          await mutate(addressConnection);
        }

        if (Object.keys(reverseAddressConnection).length > 0) {
          reverseAddressConnection['id'] = data.reverseRelation.id;
          await mutate(reverseAddressConnection);
        }
      }

      onClose();
    },
    [formState.dirtyFields, isCreateMode, mutate, onClose]
  );

  return (
    <FormProvider {...form}>
      <Popover {...popoverProps}>
        <div className={styles.header}>
          <Typography variant="h4">{t('MASK.RELATION')}</Typography>

          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>

        <div>
          <RelationPickerWithArrow
            arrowDirection="right"
            formKey="relation.connection"
          />
          <ControlWrapper columns={2} paddingTop="md" paddingBottom="md">
            <BusinessPartnerPickerWithPrefix
              prefix="A"
              formKey="businessPartnerA"
              disabled
            />
            <BusinessPartnerPickerWithPrefix
              prefix="B"
              formKey="businessPartnerB"
            />
          </ControlWrapper>
          <RelationPickerWithArrow
            arrowDirection="left"
            formKey="reverseRelation.connection"
          />
        </div>

        <ControlWrapper columns={2} paddingTop="lg">
          <BaseActionButton onClick={onClose}>
            {t('COMMON.ABORT')}
          </BaseActionButton>

          <BaseActionButton
            disabled={!canSubmit}
            onClick={handleSubmit(onSubmit)}
          >
            {t('COMMON.APPLY')}
          </BaseActionButton>
        </ControlWrapper>
      </Popover>
    </FormProvider>
  );
};
