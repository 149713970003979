import { gql, useQuery } from '@apollo/client';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { GroupPicker } from '../GroupPicker';

export interface EntityGroupPickerProps {
  value: Group[] | null;
  onChange: (value: Group[]) => void;
  multiple?: boolean;
  entity: Entities;
}

const LOOK_UP: Partial<Record<Entities, string>> = {
  projectGroup: 'getProjektGruppen',
  articleGroup: 'getArtikelGruppen',
  customerGroup: 'getKundenGruppen',
  supplierGroup: 'getLieferantenGruppen',
};

export function EntityGroupPicker(props: EntityGroupPickerProps) {
  const { value, onChange, entity } = props;

  const response = useGroups(entity);

  const groups = response.data?.groups ?? null;

  return (
    <GroupPicker
      value={value}
      onChange={onChange}
      groups={groups}
      multiple={props.multiple}
    />
  );
}

const GET_GROUPS = (queryName: string) => gql`
  query GetGroups {
    groups: ${queryName} {
      id: code
      name: name
      parentId: parentCode
      index
    }
  }
`;

interface GetGroupsResponse {
  groups: Group[];
}

export function useGroups(entity: Entities) {
  const queryName = LOOK_UP[entity];
  if (!LOOK_UP[entity])
    throw new Error(
      `Entity ${entity} is not supported for <GroupPicker ... />.`
    );
  return useQuery<GetGroupsResponse>(GET_GROUPS(queryName));
}

export const ProjectGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.projectGroup} {...props} />;

export const ArticleGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.articleGroup} {...props} />;

export const CustomerGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.customerGroup} {...props} />;

export const SupplierGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.supplierGroup} {...props} />;
