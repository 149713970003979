import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import React from 'react';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { IdType } from '../edit-table/types';
import { useSelectedPositionState } from '../hooks/use-selected-position';

interface ClonePositionButtonProps {
  onClonePosition: (copyIds: IdType[]) => void;
  disabled: boolean;
  positions: Position[];
}

export const ClonePositionButton = (props: ClonePositionButtonProps) => {
  const { onClonePosition, disabled, positions } = props;

  const { selectedPositionIds } = useSelectedPositionState();
  if (!(selectedPositionIds.length > 0)) return null;

  const disableClone = positions
    .filter((x) => selectedPositionIds.includes(x.localId))
    .some((x) => x.posId || x.positionKind === ErpPositionsKind.STUECKLISTE);

  return (
    <IconButton
      disabled={disabled || disableClone}
      color="primary"
      onClick={() => onClonePosition(selectedPositionIds)}
    >
      <ContentCopyIcon />
    </IconButton>
  );
};
