import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ProjectDirectoryType } from '../Enums/ProjectDirectoryType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputProjectDirectoryDefinition<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: type */
  type?: ProjectDirectoryType;
  /** Alias: localPathRoot */
  localPathRoot?: string;
  /** Alias: remoteUrl */
  remoteUrl?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectDirectoryDefinition> = {
  id: {
    alias: 'id',
  },
  type: {
    alias: 'type',
  },
  localPathRoot: {
    alias: 'localPathRoot',
  },
  remoteUrl: {
    alias: 'remoteUrl',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectDirectoryDefinitionEntityDefinition: EntitiyDefinition<InputProjectDirectoryDefinition> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectDirectoryDefinition',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
