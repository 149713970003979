import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { useContactActions } from '../../../../../../hooks/use-contact-actions';
import { CustomerPicker } from '../../../../../entity-picker/customer-picker/CustomerPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const CustomerPreviewPickerInput = withExtras<Customer>(
  ({ value, onChange, ...rest }) => {
    const { actions: contactActions } = useContactActions(
      {
        ...value,
        id: null,
        businessPartnerId: value?.id,
        businessPartnerType: typeNameToSdObjType(value?.__typename),
        isPrivatePartner: value?.isPrivateCustomer ?? false,
      },
      {
        displayEMail: false,
        noButtonPadding: true,
      }
    );

    return (
      <PreviewPickerInput<Customer>
        {...rest}
        value={value}
        formattedValue={value?.name}
        actions={contactActions ?? []}
        picker={
          <CustomerPicker
            value={value}
            onChange={onChange}
            multiple={false}
            favorites
          />
        }
        onChange={onChange}
        entity={Entities.customer}
      />
    );
  }
);
