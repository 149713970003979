import styles from './use-resizable.module.scss';

import React, { useCallback, useMemo, useRef, useState } from 'react';

export const useResizable = () => {
  const [height, setHeight] = useState(undefined);
  const resizableRef = useRef(null);
  const startY = useRef(0);
  const startHeight = useRef(0);

  const handleMouseMove = useCallback(
    (e) => {
      const newHeight = startHeight.current + (e.clientY - startY.current);
      setHeight(newHeight);
    },
    [setHeight]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (e) => {
      startY.current = e.clientY;
      startHeight.current = height || e.target.parentElement.clientHeight;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp, height]
  );

  const handleElement = useMemo(
    () => <div className={styles.handle} onMouseDown={handleMouseDown} />,
    [handleMouseDown]
  );

  return useMemo(() => {
    const style: React.CSSProperties = {};
    if (height) {
      style.maxHeight = undefined;
      style.height = `${height}px`;
    }
    return {
      resizableRef,
      handleElement,
      style,
      height,
    };
  }, [handleElement, height]);
};
