import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputProjectStepLinkRelation } from './InputProjectStepLinkRelation.entity';

export class InputProjectStepRelation<T extends EMode = EMode.entity> {
  /** Alias: links */
  links?: InputProjectStepLinkRelation<T>;
  /** Alias: addCompletionFeedback */
  addCompletionFeedback?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputProjectStepRelation> = {
  links: {
    alias: 'links',
  },
  addCompletionFeedback: {
    alias: 'addCompletionFeedback',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjectStepRelationEntityDefinition: EntitiyDefinition<InputProjectStepRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjectStepRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
