import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { IconButton } from '@mui/material';

import { TimeCardArchivedPdfFullscreenActionProps } from './types';

export const TimeCardArchivedPdfFullscreenAction = ({
  setFullscreen,
}: TimeCardArchivedPdfFullscreenActionProps) => {
  return (
    <IconButton
      color="primary"
      onClick={() => {
        setFullscreen(true);
      }}
    >
      <FullscreenIcon />
    </IconButton>
  );
};
