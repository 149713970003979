import styles from './StatusesColor.module.scss';

import { useTranslation } from 'react-i18next';

import { ReAccountingStatus } from '@work4all/models/lib/Enums/ReAccountingStatus.enum';

type Props = {
  value: ReAccountingStatus;
};

type color = 'success' | 'alert' | 'error';
const StatusesColor: Record<ReAccountingStatus, color> = {
  [ReAccountingStatus.ACCOUNTED]: 'success',
  [ReAccountingStatus.PARTIAL_ACCOUNTED]: 'alert',
  [ReAccountingStatus.NOT_ACCOUNTED]: 'error',
};

export const ReAccountingStatusCell = ({ value }: Props) => {
  const { t } = useTranslation();
  const color = StatusesColor[value];

  return (
    <div className={styles[color]}>{t(`ACCOUNTING_STATUSES.${value}`)}</div>
  );
};
