import React, { useCallback } from 'react';
import { TableInstance } from 'react-table';

import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';

import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { AttachmentCell } from './AttachmentCell';
import { TravelStatusCell } from './cell-renderer/TravelStatusCell';
import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/travelReceipts-table-schema.json';

const forceRequestFields: TravelReceipts = {
  travelExpenses: {
    closedByuserid: null,
    insertionREDate: null,
  },
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const TravelReceiptsTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function TravelReceiptsTable(props, ref) {
  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();

  const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
    TravelReceipts,
    never
  >({
    schema: schema as never,
    defaultSort,
    forceRequestFields,
    cells: {
      Attachment: AttachmentCell,
      TravelStatusCell,
    },
  });
  const rowModifiers = useCallback(
    (value: TravelReceipts) => {
      const modifiers = prepareRowDisplayModifiers(value);
      const { key: status } = getTravelReceiptsStatus(value.travelExpenses);
      return {
        ...modifiers,
        isFaded: status === 'booked',
        isShade1: status === 'closed' || status === 'booked',
      };
    },
    [prepareRowDisplayModifiers, getTravelReceiptsStatus]
  );

  return (
    <EntityTable
      ref={ref}
      {...dataTable}
      prepareRowDisplayModifiers={rowModifiers}
    />
  );
});
