import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartnerPickerField } from '../../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../../components/entity-picker/ContactPickerField';
import { EInvoiceFormatPickerField } from '../../../../../../../../../../components/entity-picker/EInvoiceFomatPickerField';
import { InvoiceFormPickerField } from '../../../../../../../../../../components/entity-picker/InvoiceFormPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { BPMaskFormValue } from '../../../../../types';

export const Invoice: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContextPlus<BPMaskFormValue>();

  const currentInvoiceForm: InvoiceForm = watch('invoiceForm');
  const currentAdditionalAddress1: BusinessPartnerContactCombined =
    watch('additionalAddress1');

  return (
    <Collapse defaultOpen={true} title={t('COMMON.INVOICE')}>
      <ControlWrapper>
        <ControllerPlus
          name="additionalAddress1"
          control={control}
          render={({ field: add1Field, fieldState: addField1State }) => {
            return (
              <ControllerPlus
                name="additionalAddress1.contact"
                control={control}
                render={({
                  field: contactField,
                  fieldState: contactFieldState,
                }) => {
                  return (
                    <MultiStepControls>
                      <Step active={true} index={0}>
                        <BusinessPartnerPickerField
                          {...add1Field}
                          error={addField1State?.error?.message}
                          label={t('INPUTS.INVOICE_ADDRESS')}
                          value={
                            (add1Field?.value as BusinessPartnerContactCombined)
                              ?.businessPartner?.data
                          }
                          onChange={(value) => {
                            contactField.onChange(
                              value?.data?.mainContact ?? null
                            );
                            const businessPartner = value?.data;

                            if (businessPartner) {
                              const updatedValue: BusinessPartnerContactCombined =
                                {
                                  businessPartner: {
                                    businessPartnerType:
                                      businessPartner.__typename === 'Kunde'
                                        ? SdObjType.KUNDE
                                        : SdObjType.LIEFERANT,
                                    data: businessPartner,
                                    id: businessPartner?.id,
                                  },
                                };
                              add1Field.onChange(updatedValue);
                            } else {
                              add1Field.onChange(null);
                            }
                          }}
                        />
                      </Step>
                      <Step
                        active={
                          add1Field.value !== undefined &&
                          add1Field.value !== null &&
                          !currentAdditionalAddress1?.businessPartner?.data
                            ?.isPrivateCustomer
                        }
                        index={1}
                      >
                        <ContactPickerField
                          {...contactField}
                          businessPartnerId={
                            currentAdditionalAddress1?.businessPartner?.id
                          }
                          businessPartnerType={
                            currentAdditionalAddress1?.businessPartner
                              ?.businessPartnerType
                          }
                          error={contactFieldState?.error?.message}
                          value={contactField.value}
                        />
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            );
          }}
        />

        <MultiStepControls>
          <Step active={true} index={0}>
            <ControllerPlus
              name="invoiceForm"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <InvoiceFormPickerField
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    error={fieldState?.error?.message}
                  />
                );
              }}
            />
          </Step>
          <Step
            active={currentInvoiceForm === InvoiceForm.ELEKTRONISCH}
            index={1}
          >
            <ControllerPlus
              name="eInvoiceFormat"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <EInvoiceFormatPickerField
                    {...field}
                    error={fieldState?.error?.message}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                );
              }}
            />
          </Step>
        </MultiStepControls>
        <div>
          {currentInvoiceForm === InvoiceForm.ELEKTRONISCH && (
            <ControllerPlus
              name="invoiceEMailAddress"
              control={control}
              render={({ field }) => {
                return (
                  <LabeledInput
                    {...field}
                    label={t('INPUTS.EMAIL_FOR_INVOICES')}
                  />
                );
              }}
            />
          )}
        </div>
      </ControlWrapper>
    </Collapse>
  );
};
