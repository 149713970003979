import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputArticleUpsertBomComponent<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: articleCode */
  articleCode?: number;
  /** Alias: index */
  index?: number;
  /** Alias: amount */
  amount?: number;
  /** Alias: name */
  name?: any;
  /** Alias: onlyPurchase */
  onlyPurchase?: boolean;
  /** Alias: doNotCalculate */
  doNotCalculate?: boolean;
  /** Alias: length */
  length?: number;
  /** Alias: width */
  width?: number;
  /** Alias: calculationLogic */
  calculationLogic?: number;
  /** Alias: calculationFactor */
  calculationFactor?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleUpsertBomComponent> = {
  code: {
    alias: 'code',
  },
  articleCode: {
    alias: 'articleCode',
  },
  index: {
    alias: 'index',
  },
  amount: {
    alias: 'amount',
  },
  name: {
    alias: 'name',
  },
  onlyPurchase: {
    alias: 'onlyPurchase',
  },
  doNotCalculate: {
    alias: 'doNotCalculate',
  },
  length: {
    alias: 'length',
  },
  width: {
    alias: 'width',
  },
  calculationLogic: {
    alias: 'calculationLogic',
  },
  calculationFactor: {
    alias: 'calculationFactor',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleUpsertBomComponentEntityDefinition: EntitiyDefinition<InputArticleUpsertBomComponent> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleUpsertBomComponent',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
