import { useEventCallback } from '@mui/material/utils';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';

import { useDataMutation, useDeleteEntity, useUser } from '@work4all/data';

import { UserPresenceTimeInfoItem } from '@work4all/models/lib/Classes/UserPresenceTimeInfoItem.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TimeStampKind } from '@work4all/models/lib/Enums/TimeStampKind.enum';

import { useDebouncedCallback } from '@work4all/utils/lib/hooks/use-debounce-callback';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { useTimeTracker } from '../../../../time-tracker/use-time-tracker';

import { useTimeCardDetails } from './useTimeCardDetails';

const areDayContextEqual = (newDateTime: DateTime, oldDateTime: DateTime) => {
  return (
    newDateTime.hasSame(oldDateTime, 'minutes') &&
    newDateTime.hasSame(oldDateTime, 'hours')
  );
};

const DEFAULT_MINUTES_TO_ADD = 1;

export const useTimeEntryMutation = () => {
  const { date, items, userId, refetch } = useTimeCardDetails();
  const user = useUser();

  const responseData = useMemo(() => {
    const data: UserPresenceTimeInfoItem<EMode.query> = {
      id: null,
    };
    return data;
  }, []);

  const [deleteEntity] = useDeleteEntity(false);

  const [mutate] = useDataMutation<UserPresenceTimeInfoItem, EMode.upsert>({
    entity: Entities.userPresenceTimeInfoItem,
    mutationType: EMode.upsert,
    responseData,
    resetStore: false,
  });

  const forceUpdate = useForceUpdate();

  const lastEntryValid = useRef(true);

  const save = useDebouncedCallback((id: number, timestamp: string) => {
    const dateTime = DateTime.fromISO(timestamp);
    lastEntryValid.current = validate(id, dateTime);
    if (lastEntryValid.current) {
      mutate({
        id,
        timestamp,
      });
    }
  }, 300);

  const { start, pause, resume, stop } = useTimeTracker({
    amplitudeEntryPoint: 'TimeCard',
  });

  const getLast = useCallback(() => {
    const filtered = items
      .flatMap((x) => x.items)
      .filter((x) =>
        DateTime.fromISO(x.timestamp).hasSame(DateTime.fromISO(date), 'day')
      );
    const last = filtered[filtered.length - 1];
    return last ? DateTime.fromISO(last.timestamp) : undefined;
  }, [items, date]);

  const guardValidTime = useCallback(
    (current: DateTime, isEndOfPause = false): DateTime => {
      const numberOfMinutesToAdd = isEndOfPause
        ? user.workTimeTrackingMinBreakTime || DEFAULT_MINUTES_TO_ADD
        : DEFAULT_MINUTES_TO_ADD;

      return current.plus({ minute: numberOfMinutesToAdd });
    },
    [user.workTimeTrackingMinBreakTime]
  );

  const getLastSafe = useCallback(
    (isEndOfPause = false) => {
      const last = getLast();
      return last ? guardValidTime(last, isEndOfPause) : undefined;
    },
    [getLast, guardValidTime]
  );

  const validate = useCallback(
    (id: number, currentTime: DateTime) => {
      const flatmap = items.flatMap((x) => x.items);
      const currentIdx = flatmap.findIndex((x) => x.id === id);

      const prev = flatmap[currentIdx - 1];
      const next = flatmap[currentIdx + 1];

      const isBiggerThenPrev = prev
        ? DateTime.fromISO(prev.timestamp) < currentTime
        : true;
      const isLessThenNext = next
        ? DateTime.fromISO(next.timestamp) > currentTime
        : true;

      return isBiggerThenPrev && isLessThenNext;
    },
    [items]
  );

  const overflowInDateContext = useCallback(
    (newTime: DateTime, extendedContext: DateTime[] = []) => {
      const endOfDay = DateTime.fromISO(date).endOf('day');
      const map = items
        .flatMap((x) => x.items)
        .map((x) => DateTime.fromISO(x.timestamp));
      if (newTime >= endOfDay) {
        const alreadyEnd = [...map, ...extendedContext].some(
          (x) => x.toFormat('HH:mm') === endOfDay.toFormat('HH:mm')
        );
        if (alreadyEnd) return undefined;
        return endOfDay;
      }

      const sorted = [...map, ...extendedContext, newTime].sort((a, b) =>
        a < b ? 1 : -1
      );
      const timestamp = DateTime.fromISO(date).set({
        hour: sorted[0].hour,
        minute: sorted[0].minute,
      });

      return timestamp;
    },
    [date, items]
  );

  return {
    add: useEventCallback(async () => {
      const last = getLastSafe();
      const currentDate = DateTime.fromISO(date);

      let timestamp: DateTime | undefined = undefined;

      if (last) {
        const overflow = overflowInDateContext(last);
        if (!overflow) return;
        timestamp = currentDate.set({
          hour: overflow.hour,
          minute: overflow.minute,
        });
      } else {
        timestamp = currentDate.set({ hour: 9, minute: 0 });
      }

      try {
        await start(timestamp.toJSDate(), userId);
      } finally {
        refetch();
      }
    }),
    pause: useEventCallback(async () => {
      const last = getLastSafe();
      const pauseTime = overflowInDateContext(last);
      if (!pauseTime) return;

      try {
        await pause(pauseTime.toJSDate(), userId);
      } finally {
        refetch();
      }
    }),
    resume: useEventCallback(async () => {
      const last = getLastSafe(true);
      const resumeTime = overflowInDateContext(last);
      if (!resumeTime) return;

      try {
        await resume(resumeTime.toJSDate(), userId);
      } finally {
        refetch();
      }
    }),

    stop: useEventCallback(async () => {
      const allStartsToday = items
        .flatMap((x) => x.items)
        .filter((x) =>
          DateTime.fromISO(x.timestamp).hasSame(DateTime.fromISO(date), 'day')
        )
        .filter((x) => x.kind === TimeStampKind.MANUELL);

      const singleStart = allStartsToday.length === 1;
      const newEnd = singleStart
        ? DateTime.fromISO(allStartsToday[0].timestamp).plus({ hours: 8 })
        : undefined;

      const last = getLastSafe();
      const timestamp = overflowInDateContext(last, [newEnd]);
      if (!timestamp) return;

      try {
        await stop(timestamp.toJSDate(), userId);
      } finally {
        refetch();
      }
    }),
    update: useEventCallback((id: number, value: string) => {
      const item = items.flatMap((x) => x.items).find((x) => x.id === id);

      const newDateTime = DateTime.fromISO(value);
      const oldDateTime = DateTime.fromISO(item.timestamp);

      if (!areDayContextEqual(newDateTime, oldDateTime)) {
        save(id, value);
      }
    }),
    remove: useEventCallback(async (id: number) => {
      try {
        await deleteEntity({
          ids: [id.toString()],
          type: Entities.userPresenceTimeInfoItem,
        });
        const map = items.flatMap((x) => x.items);
        const itemIdx = map.findIndex((x) => x.id === id);
        const item = map[itemIdx];
        if (item.kind === TimeStampKind.PAUSENENDE) {
          const pauseStart = map[itemIdx - 1];
          if (pauseStart.kind === TimeStampKind.PAUSENBEGINN)
            await deleteEntity({
              ids: [pauseStart.id.toString()],
              type: Entities.userPresenceTimeInfoItem,
            });
        }
      } finally {
        refetch();
      }
    }),
    onBlur: useEventCallback(() => {
      if (lastEntryValid.current) refetch();
      else forceUpdate();
    }),
  };
};
