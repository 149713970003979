import { flatten } from 'lodash';

import { IFieldData, IFieldParam } from '@work4all/models/lib/DataProvider';

import { genFieldName } from './genFieldName';

type IRootField = {
  value: IRoot | null;
  alias?: string | null;
  params: IFieldParam[] | null;
  name: string;
};

interface IRoot {
  [x: string]: IRootField;
}

const genField = (root: IRoot, field: IFieldData) => {
  const parts = field.name.split('.');
  const rawAliasParts = field.alias?.split('.');
  const framgentsSet = new Set();
  parts.forEach((p, i) => {
    if (p.startsWith('fragment:')) {
      framgentsSet.add(i);
    }
  });

  let aliasParts: string[] | undefined;
  if (rawAliasParts) {
    /**
     *  the idea is to make "aliases" array length to be eaual to "parts" length.
     *  Add null values at the start of array if parts length is bigger that aliases's
     *  BUT ignore fragments at this step!
     *  Fragments should be replaced in a way that values positions before fragment
     *  in aliases are not affected, but they should be offsetted after fragment by 1
     *  Null saves the day: set null at fragment's position and values after null will be offsetted.
     *
     *  This makes "aliases" to be equal to "parts" and you can safely run code like this: parts[i].alias = aliases[i];
     *  */
    aliasParts = [
      ...new Array(
        parts.length - rawAliasParts.length - framgentsSet.size
      ).fill(null),
      ...rawAliasParts,
    ];
    let idx = 0;
    aliasParts = parts
      .map((_, mapIdx) => {
        if (framgentsSet.has(mapIdx)) {
          return null;
        }
        const i = idx;
        idx += 1;
        return aliasParts[i];
      })
      .filter((a) => a !== undefined);
  }

  let currentPart = root;
  for (let j = 0; j < parts.length; j += 1) {
    const p = parts[j];
    const isFragment = framgentsSet.has(j);
    if (!currentPart[p]) {
      currentPart[p] = {
        name: isFragment ? `... on ${p.split(':')[1]}` : p,
        value: null,
        alias: null,
        // We add the params only for the exact proprety
        // otherwise, we are getting an error
        params: j + 1 === parts.length ? field.params : undefined,
      };
    }

    currentPart[p].alias = aliasParts?.length > 1 ? aliasParts[j] : field.alias;

    if (j !== parts.length - 1) {
      currentPart[p].value = currentPart[p].value || {};
      currentPart = currentPart[p].value as IRoot;
    }
  }

  return root;
};

const getStr = (root: IRoot) => {
  let str = '';
  for (const key in root) {
    const f = root[key];
    str += genFieldName(f as IFieldData);

    if (!f.value) {
      str += '\n';
      continue;
    }

    str += ' {\n';
    str += getStr(f.value);
    str += '}\n';
  }

  return str;
};

export const genFields = (fields: IFieldData[]) => {
  let root: IRoot = {};
  const allParams: IFieldParam[][] = [];
  for (let i = 0; i < fields.length; i += 1) {
    const f = fields[i];
    root = genField(root, f);
    if (f.params) {
      allParams.push(f.params);
    }
  }

  const str = getStr(root);

  return { str, allParams: flatten(allParams) };
};
