import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { NumberCell, useTableStateBag } from '@work4all/components';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ArticleGroup } from '@work4all/models/lib/Classes/ArticleGroup.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  OpenMaskOverride,
  useOpenMask,
} from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { OrganisationArea } from '../../organisation-area';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/article-table-schema.json';

import { AttachmentsCell } from './cells/AttachmentsCell';
import { StockArticleCell } from './cells/StockArticleCell';
import { useTransformedArticleTableSchema } from './use-transformed-article-table-schema';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

const forceRequestFields: Article = {
  groupId: null,
  netPrice: null,
  netPrice2: null,
  netPrice3: null,
  articlePrices: {
    singlePriceList: [
      {
        id: null,
        price: null,
        priceGroupId: null,
      },
    ],
  },
  hasBulkPrices: null,
  bulkPriceList: [
    {
      id: null,
      sdObjMemberCode: null,
      price: null,
      bulkPriceTierItem: {
        id: null,
        name: null,
        edgeValue1: null,
        edgeValue2: null,
      },
      priceGroupId: null,
    },
  ],
  articleTextList: [
    {
      id: null,
      kurztext: null,
      longtext: null,
      sprachCode: null,
    },
  ],
};

export const ArticleTable = React.forwardRef<TableInstance, IEntityTable>(
  function ArticleTable(props, ref) {
    const { t } = useTranslation();

    const transformedSchema = useTransformedArticleTableSchema({ schema });

    const dataTable = useEntityDataTable<Article, never>({
      schema: transformedSchema as never,
      defaultSort,
      forceRequestFields,
      cells: {
        AttachmentsCell,
        NumberCell,
        StockArticleCell,
      },
    });
    const prepareRowDisplayModifiers = dataTable.prepareRowDisplayModifiers;

    const rowModifiers = useCallback(
      (value: Article) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isShutDown,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { tableState } = useTableStateBag();

    const selectedGroup: ArticleGroup = useMemo(() => {
      const groupFilter = tableState?.filters.find((f) => f.id === 'groupId');
      if (groupFilter && groupFilter.value?.value?.length === 1) {
        return groupFilter.value?.value?.[0];
      }
      return null;
    }, [tableState?.filters]);

    const presetFields = useMemo(
      () =>
        selectedGroup
          ? JSON.stringify({
              groupId: selectedGroup.id,
              articleGroup: selectedGroup,
            } as Article)
          : undefined,
      [selectedGroup]
    );

    const onOpenMask = useOpenMask({
      entityType: Entities.article,
      params: { create: { presetFields } },
    });

    return (
      <OpenMaskOverride value={{ onOpenMask }}>
        <EntityTable
          ref={ref}
          areas={{
            left: {
              title: t('COMMON.ORGANISATION'),
              content: <OrganisationArea entity={Entities.article} />,
              resizable: true,
              collapsible: true,
              custom: true,
            },
          }}
          {...dataTable}
          onSelectedRowsChange={props.onSelectedRowsChange}
          prepareRowDisplayModifiers={rowModifiers}
          actions={{
            createWidget: false,
          }}
        />
      </OpenMaskOverride>
    );
  }
);
