import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  CustomerGroupPicker,
  SupplierGroupPicker,
} from '../entity-group-picker/EntityGroupPicker';

interface BusinessPartnerGroupPickerProps {
  value: Group[] | null;
  onChange: (value: Group[]) => void;
  multiple?: boolean;
}

export const BusinessPartnerGroupPicker = (
  props: BusinessPartnerGroupPickerProps
) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(Entities.customer);

  return (
    <>
      <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
        <Tab value={Entities.customer} label={t('COMMON.CUSTOMER')} />
        <Tab value={Entities.supplier} label={t('COMMON.SUPPLIER')} />
      </Tabs>

      {selectedTab === Entities.customer ? (
        <CustomerGroupPicker {...props} />
      ) : (
        <SupplierGroupPicker {...props} />
      )}
    </>
  );
};
