import { Dictionary, groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErpObject } from '../../types';
import { ErpDialogGroupedPositions } from '../ErpDialogArticleGroupedPositions/ErpDialogGroupedPositions';
import { groupByArticle } from '../ErpDialogArticleGroupedPositions/groupByArticle';
import { useGroupByArticleQuery } from '../ErpDialogArticleGroupedPositions/use-group-by-article-query';
import { ErpDialogPositionsProps } from '../ErpDialogPositions';

interface ErpDialogSupplierGroupedPositions
  extends Omit<ErpDialogPositionsProps, 'entry' | 'title'> {
  data: ErpObject[];
}
export const ErpDialogSupplierGroupedPositions = (
  props: ErpDialogSupplierGroupedPositions
) => {
  const { sourceEntity } = props;

  const { t } = useTranslation();
  const {
    erpData: { data },
  } = useGroupByArticleQuery({
    data: props.data,
    sourceEntity,
  });

  const [groupedPositionList, setGroupedPositionList] = useState<
    Dictionary<ReturnType<typeof groupByArticle>>
  >({});
  useEffect(() => {
    if (data.length) {
      const positionList = groupByArticle(data);
      const groups = groupBy(positionList, 'supplierId');
      setGroupedPositionList(groups);
    }
  }, [data]);

  return Object.entries(groupedPositionList).map(
    ([_supplierId, positionList]) => {
      return (
        <ErpDialogGroupedPositions
          title={
            positionList[0].supplier?.name ||
            t('COMMON.ERP.GROUP_WITHOUT_SUPPLIER')
          }
          {...props}
          positionList={positionList}
        />
      );
    }
  );
};
