import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputUserCollection<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputUserCollection> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputUserCollectionEntityDefinition: EntitiyDefinition<InputUserCollection> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputUserCollection',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
