import { gql, useQuery } from '@apollo/client';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Body1 } from '../../../typography/body1/Body1';
import { ListEntityPicker } from '../components/list-entity-picker';

export interface CityPickerProps {
  value: CityWrap | null;
  postalCodeFilter?: string;
  onChange: (value: CityWrap) => void;
  canWriteValue?: boolean;
}

export interface CityWrap {
  id: string;
  city: string;
}

export function CityPicker(props: CityPickerProps) {
  const { value, onChange, postalCodeFilter, canWriteValue } = props;

  const [searchTerm, setSearchTerm] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const response = useCities({
    cityFilter: searchTerm.length > 0 ? `%${searchTerm}%` : undefined,
    queryPage: 0,
    querySize: 100,
    postalCodeFilter,
  });

  const dataset = useMemo(() => {
    const data = (response?.data?.getCities?.data || []) as string[];
    const cities = data.map((x) => ({
      id: x,
      city: x,
    }));

    if (canWriteValue && searchTerm.trim().length > 0) {
      cities.push({ id: searchTerm, city: searchTerm });
    }

    return {
      total: cities.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: cities,
    };
  }, [response, canWriteValue, searchTerm]);

  return (
    <ListEntityPicker
      entity={Entities.customer}
      data={{ id: null, city: null }}
      filterBy={['city']}
      sortBy="city"
      fixedDataSet={dataset}
      onSearchValueChange={(searchValue) => {
        setSearchTerm(searchValue);
      }}
      renderItemContent={(item) => {
        return <Body1>{item.city}</Body1>;
      }}
      multiple={false}
      value={value}
      onChange={(result) => {
        onChange(result);
      }}
    />
  );
}

const GET_CITIES = gql`
  query GetCities(
    $postalCodeFilter: String
    $cityFilter: String
    $querySize: Int
    $queryPage: Int
  ) {
    getCities(
      postalCodeFilter: $postalCodeFilter
      cityFilter: $cityFilter
      querySize: $querySize
      queryPage: $queryPage
    ) {
      total
      data
    }
  }
`;

export function useCities(
  variables: {
    cityFilter?: string;
    postalCodeFilter?: string;
    querySize?: number;
    queryPage?: number;
  },
  skip = false
) {
  return useQuery(GET_CITIES, { variables, skip });
}
