import { isEqual } from 'lodash';

import { createUseFormUpdate } from '../../utils/create-use-form-update';

import { calculatePriceWithSurcharge } from './tabs/prices/calculate-price-with-surcharge';
import { ArticleMaskFormValue } from './types';

export const useArticleFormUpdate = createUseFormUpdate<ArticleMaskFormValue>(
  (form) => {
    function updateEntryPrice() {
      const values = form.getValues();

      const standardAsEntry = values.standardPurchasePriceAsEntryPrice;
      const purchasePriceList = values.articlePrices?.purchasePriceList ?? [];
      const standardSupplierId = values.standardSupplierId ?? 0;

      if (!standardAsEntry) {
        return;
      }

      const standardPrice = purchasePriceList.find(
        (price) => price.supplier.id === standardSupplierId
      );

      if (!standardPrice) {
        return;
      }

      return {
        entryPrice: standardPrice.purchasePrice,
      };
    }

    return {
      unit(unit) {
        return { unitId: unit?.id ?? 0 };
      },
      ressourceClass(resourceClass) {
        return { ressourceClassId: resourceClass?.id ?? 0 };
      },
      manufacturer(manufacturer) {
        return { manufacturerId: manufacturer?.id ?? 0 };
      },
      costCenterNew(costCenter) {
        return { costCenterId: costCenter?.id ?? 0 };
      },
      vatRate(vatRate) {
        return { vatId: vatRate?.id ?? 0 };
      },
      articleGroup(group) {
        return { groupId: group?.id ?? 0 };
      },
      entryPrice(entryPrice) {
        const values = form.getValues();

        const articlePrices = values.articlePrices ?? {};
        const oldPrices = articlePrices.singlePriceList ?? [];

        const newPrices = oldPrices.map((price) => {
          const surcharge = price.surcharge;
          // Do not change prices without surcharge, these are configured manually
          if (!surcharge) {
            return price;
          }

          const newPrice = calculatePriceWithSurcharge(entryPrice, surcharge);

          return { ...price, price: newPrice };
        });

        if (isEqual(oldPrices, newPrices)) {
          return;
        }

        return {
          articlePrices: { ...articlePrices, singlePriceList: newPrices },
        };
      },
      standardPurchasePriceAsEntryPrice: updateEntryPrice,
      standardSupplierId: updateEntryPrice,
      articlePrices: updateEntryPrice,
      name(name, prevName) {
        const longtext = form.getValues('longtext');
        if (prevName === longtext) {
          return { longtext: name };
        }
      },
    };
  }
);
