import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export enum InvoiceStatus {
  Open = 'OPEN',
  Ra = 'RA',
  RaAndPaid = 'RA_AND_PAID',
  Incorrect = 'INCORRECT',
}

export type InvoiceStatusPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: InvoiceStatus; name: string },
  TMultiple
>;

export function InvoiceStatusPicker<TMultiple extends boolean>(
  props: InvoiceStatusPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: InvoiceStatus.Open,
      name: t('COMMON.INVOICE_STATUS_PICKER_' + InvoiceStatus.Open),
    },
    {
      id: InvoiceStatus.Ra,
      name: t('COMMON.INVOICE_STATUS_PICKER_' + InvoiceStatus.Ra),
    },
    {
      id: InvoiceStatus.RaAndPaid,
      name: t('COMMON.INVOICE_STATUS_PICKER_' + InvoiceStatus.RaAndPaid),
    },
  ];

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}

export const mapInvoiceStatus = (ra: boolean, paid: boolean) => {
  if (!ra && !paid) return InvoiceStatus.Open;
  if (ra && !paid) return InvoiceStatus.Ra;
  if (ra && paid) return InvoiceStatus.RaAndPaid;
  return InvoiceStatus.Incorrect;
};
