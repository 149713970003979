import KeyIcon from '@mui/icons-material/Key';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@mui/material';
import { useState } from 'react';

import { MARDialog } from './MARDialog/MARDialog';
import { useSimulatedSafeAreas } from './use-simulated-safe-areas';

export function DevTools() {
  const [isMARDialogOpen, setMARDialogOpen] = useState(false);
  const safeAreas = useSimulatedSafeAreas();

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setMARDialogOpen(true)}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary={'Module access rights'} />
        </ListItemButton>
      </ListItem>

      <ListItem>
        <Switch
          edge="start"
          checked={safeAreas.isEnabled}
          onChange={(e) => safeAreas.onChange(e.target.checked)}
          inputProps={{
            'aria-labelledby': 'switch-list-simulate-safe-areas',
          }}
          sx={{ ml: 0, mr: '0.5rem' }}
        />
        <ListItemText
          id="switch-list-simulate-safe-areas"
          primary={'Simulate device safe areas'}
        />
      </ListItem>

      <MARDialog
        open={isMARDialogOpen}
        onClose={() => setMARDialogOpen(false)}
      />
    </List>
  );
}
