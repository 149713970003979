import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export interface ArticleKindPickerProps
  extends Omit<
    IPickerProps<{ id: string; name: string }, false>,
    'value' | 'onChange' | 'multiple'
  > {
  value: ArticleKind;
  onChange: (value: ArticleKind) => void;
}

const OPTIONS = [
  ArticleKind.NORMAL,
  ArticleKind.TAETIGKEIT_FUER_ZEITERFASSUNG,
  ArticleKind.STUECKLISTE,
];

export function ArticleKindPicker(props: ArticleKindPickerProps) {
  const { value, onChange, ...otherProps } = props;

  const { t } = useTranslation();

  const data = useMemo(() => {
    return OPTIONS.map((key) => {
      return {
        id: key,
        name: t(`ARTICLE_KIND.LONG.${key}`),
      };
    }).sort((a, b) => a.name.localeCompare(b.name));
  }, [t]);

  const valueBoxed = useMemo(
    () => data.find((o) => o.id === value) ?? null,
    [value, data]
  );

  const handleChange = (value) => {
    onChange(value ? value.id : null);
  };

  return (
    <FixedDataPicker
      multiple={false}
      dataSet={data}
      value={valueBoxed}
      onChange={handleChange}
      {...otherProps}
    />
  );
}
