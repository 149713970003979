import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const MAILCONTACT_FIELDS: MailSearchContactsResultItem<EMode.query> = {
  id: null,
  mailAddress: null,
  contact: {
    user: {
      id: null,
      displayName: null,
    },
    customer: {
      id: null,
      name: null,
      mainContactId: null,
      website: null,
      isPrivateCustomer: null,
    },
    supplier: {
      id: null,
      name: null,
      mainContactId: null,
      website: null,
      isPrivateCustomer: null,
    },
    contact: {
      id: null,
      displayName: null,
      eMail: null,
      role: null,
      salutation: {
        id: null,
        isFemale: null,
        isMale: null,
      },
      businessPartner: {
        id: null,
        data: {
          customer: {
            id: null,
            name: null,
            mainContactId: null,
            website: null,
          },
          supplier: {
            id: null,
            name: null,
            mainContactId: null,
            website: null,
          },
        },
      },
    },
  },
};

const prepareRequest = (kind?: ContactKind[]) => {
  return ({ search }: { search: string }): DataRequest => {
    const searchValue = search.length ? `%${search}%` : '%%';
    const result: DataRequest = {
      entity: Entities.mailSearchContactsResultItem,
      data: MAILCONTACT_FIELDS,
      completeDataResponse: true,
      vars: {
        searchValue,
        filterTypes: kind,
      },
    };

    return result;
  };
};

export const prepareRequestInternal = prepareRequest([ContactKind.MITARBEITER]);
export const prepareRequestExternal = prepareRequest([
  ContactKind.KUNDE,
  ContactKind.LIEFERANT,
]);

export const prepareRequestAll = prepareRequest();

export type PrepareRequestType = typeof prepareRequestAll;

export const renderChipContent = (
  resource: MailSearchContactsResultItem
): string => {
  return resource.mailAddress;
};
