import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  PaymentEntity,
  PaymentEntityDefinition,
  UpsertPaymentEntity,
} from './types';

export const NEW_ROW_ID_PREFIX = 'NEW_';
export const MUTATION_ENTITY_BY_VIEW_ENTITY: Record<
  PaymentEntity,
  UpsertPaymentEntity
> = {
  [Entities.reViewModel]: Entities.inboundInvoice,
  [Entities.raViewModel]: Entities.ra,
};

const RE_REQUEST_DATA: ReViewModel = {
  id: null,
  note: null,
  invoiceDate: null,
  dueDate: null,
  supplier: {
    id: null,
    number: null,
    name: null,
    street: null,
    postalCode: null,
    city: null,
  },
  currency: {
    id: null,
    name: null,
  },
  exchangeRate: null,
  discountPerCent: null,
  discountDate: null,
  payments: [
    {
      id: null,
      bookingDate: null,
      datevDate: null,
      amount: null,
      ledgerAccount: {
        id: null,
        name: null,
        number: null,
      },
      text: null,
      user: { id: null, displayName: null },
      date: null,
    },
  ],
  amountGross: null,
  valueNotPayed: null,
  invoiceNumber: null,
};

const RA_REQUEST_DATA: RaViewModel = {
  id: null,
  note: null,
  invoiceDate: null,
  dueDate: null,
  customer: {
    id: null,
    number: null,
    name: null,
    street: null,
    postalCode: null,
    city: null,
  },
  currency: {
    id: null,
    name: null,
  },
  exchangeRate: null,
  discountValue: null,
  dueDateExtended: null,
  grossValue: null,
  valueToPay: null,
  invoice: {
    id: null,
    skonto: null,
    skontoDeadlineDate: null,
  },
  payments: [
    {
      id: null,
      buchungsDatum: null,
      datev: null,
      summe: null,
      sachkontoCode: null,
      ledgerAccountId: null,
      ledgerAccount: {
        id: null,
        name: null,
        number: null,
      },
      note: null,
      createdByUser: { id: null, displayName: null },
      userId: null,
      date: null,
    },
  ],
  invoiceNumber: null,
};

export const REQUEST_DATA_BY_ENTITY: Record<
  PaymentEntity,
  PaymentEntityDefinition
> = {
  [Entities.reViewModel]: RE_REQUEST_DATA,
  [Entities.raViewModel]: RA_REQUEST_DATA,
};
