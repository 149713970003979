import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PreviewInputsProvider,
  PreviewTextEditorInput,
} from '@work4all/components/lib/components/entity-preview/components/preview-input';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useLock } from '@work4all/components/lib/hooks';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectProtectionState } from '@work4all/models/lib/Enums/ObjectProtectionState.enum';

import { formatNumberAsCurrency } from '@work4all/utils';
import { canEditProject } from '@work4all/utils/lib/permissions';

import { ProjectProcessList } from '../../../../../components/data-tables/project-table/components/project-table-bottom-section/components/project-process-list/ProjectProcessList';
import { useProjectCaptions } from '../../../../../hooks';
import { CustomerSupplierCard } from '../file-info-cards/CustomerSupplierCard';
import { EventPeriodCard } from '../file-info-cards/EventPeriodCard';
import { InlineData } from '../file-info-cards/InlineData';
import { ProjectCategoryCard } from '../file-info-cards/ProjectCategoryCard';
import { ProjectPeriodCard } from '../file-info-cards/ProjectPeriodCard';

import { projectDisplayItems } from './consts';
import { FilePreviewPanel } from './FilePreviewPanel';
import { requestedProjectData } from './project-file-info-panel-container/ProjectFileInfoPanelContainer';
import { IFileInfoPanelItems } from './types';

export type IProjectFileInfoPanelProps = {
  project: Project;
  onEdit?: () => void;
  onSettings?: () => void;
  displayItems?: IFileInfoPanelItems[];
  onCloseClick?: () => void;
  onOpenFilePageClicked?: () => void;
  onVisibilityToggle?: (visible: boolean) => void;
};

export function ProjectFileInfoPanel(props: IProjectFileInfoPanelProps) {
  const {
    project,
    onEdit,
    onSettings,
    onCloseClick,
    displayItems = projectDisplayItems,
    onOpenFilePageClicked,
    onVisibilityToggle,
  } = props;

  const { t } = useTranslation();
  const { isEveryInaccessible, isInaccessible } = useInaccessibleFields();
  const { internalProjectManagerCaption, externalProjectManagerCaption } =
    useProjectCaptions();

  const customerSupplierIsAccessible = !isEveryInaccessible(Entities.project, [
    'customer.name',
    'supplier.name',
  ]);

  const timePeriodsIsAccessible = !isEveryInaccessible(Entities.project, [
    'startDateInner',
    'endDateInner',
    'startDateOuter',
    'endDateOuter',
  ]);
  const startDateInnerIsAccessible = !isInaccessible(
    Entities.project,
    'startDateInner'
  );
  const endDateInnerIsAccessible = !isInaccessible(
    Entities.project,
    'endDateInner'
  );
  const startDateOuterIsAccessible = !isInaccessible(
    Entities.project,
    'startDateOuter'
  );
  const endDateOuterIsAccessible = !isInaccessible(
    Entities.project,
    'endDateOuter'
  );

  const groupNameIsAccessible = !isInaccessible(
    Entities.project,
    'project.group.name'
  );
  const projectKindIsAccessible = !isInaccessible(
    Entities.project,
    'project.projectKind.name'
  );
  const internalProjectManagerDisplayNameIsAccessible = !isInaccessible(
    Entities.project,
    'internalProjectManager.displayName'
  );
  const externalProjectManagerIsAccessible = !isInaccessible(
    Entities.project,
    'externalProjectManager'
  );
  const customerNameIsAccessible = !isInaccessible(
    Entities.project,
    'customer.name'
  );
  const supplierNameIsAccessible = !isInaccessible(
    Entities.project,
    'supplier.name'
  );
  const categoryAssignmentListIsAccessible = !isInaccessible(
    Entities.project,
    'categoryAssignmentList'
  );
  const projectStatusNameIsAccessible = !isInaccessible(
    Entities.project,
    'projectStatus.name'
  );
  const parentProjectNameIsAccessible = !isInaccessible(
    Entities.project,
    'parentProject.name'
  );

  const budgetIsAccessible = !isInaccessible(Entities.project, 'budget');
  const documentDirectoryIsAccessible = !isInaccessible(
    Entities.project,
    'documentDirectory'
  );

  const numberIsAccessible = !isInaccessible(Entities.project, 'number');
  const noteIsAccessible = !isInaccessible(Entities.project, 'note');

  const [activePicker, setActivePicker] = useState<string | null>(null);

  const {
    locked,
    loading,
    lock,
    unlock,
    user: lockedByUser,
  } = useLock({
    subEntityIds: [project?.id],
    subEntityType: Entities.project,
  });

  const [mutate] = useDataMutation<Project, EMode.upsert>({
    entity: Entities.project,
    mutationType: EMode.upsert,
    responseData: requestedProjectData as unknown as Project,
  });

  useEffect(() => {
    return () => {
      unlock();
    };
  }, [unlock]);

  const user = useUser();

  const canEdit = canEditProject(user, project);

  const handleLock = useCallback(
    (picker: string) => {
      if (!loading) {
        lock();
        setActivePicker(picker);
      }
    },
    [loading, lock]
  );

  const onPropEdit = async (field) => {
    const update = {
      id: project.id,
      ...field,
    };

    await (mutate(update, undefined) as Promise<Project>)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        unlock();
      });
  };

  const onPopoverClose = useCallback(() => {
    unlock();
    setActivePicker(null);
  }, [unlock, setActivePicker]);

  const renderItem = (item: IFileInfoPanelItems) => {
    switch (item) {
      case IFileInfoPanelItems.Number:
        return (
          numberIsAccessible && (
            <InlineData
              key={item}
              label={t('COMMON.NUMBER')}
              content={project?.number ? project.number.toString() : '-'}
            />
          )
        );

      case IFileInfoPanelItems.Kind:
        return (
          projectKindIsAccessible && (
            <InlineData
              key={item}
              label={t('COMMON.KIND')}
              content={project?.projectKind?.name}
            />
          )
        );

      case IFileInfoPanelItems.ProjectDirectory:
        return (
          documentDirectoryIsAccessible && (
            <InlineData
              key={item}
              label={t('COMMON.PROJECT_DIRECTORY')}
              content={project?.documentDirectory}
              // ToDo: https://work4all.atlassian.net/browse/WW-3814
              // content={project?.documentDirectory}
            />
          )
        );

      case IFileInfoPanelItems.CustomerSupplier:
        return (
          customerSupplierIsAccessible && (
            <CustomerSupplierCard
              key={item}
              customer={customerNameIsAccessible ? project?.customer : null}
              supplier={supplierNameIsAccessible ? project?.supplier : null}
            />
          )
        );

      case IFileInfoPanelItems.InternalProjectLeaders:
        return (
          internalProjectManagerDisplayNameIsAccessible && (
            <InlineData
              key={item}
              label={internalProjectManagerCaption}
              content={project?.internalProjectManager?.displayName}
            />
          )
        );

      case IFileInfoPanelItems.CustomerProjectLeaders:
        return (
          externalProjectManagerIsAccessible && (
            <InlineData
              key={item}
              label={externalProjectManagerCaption}
              content={project?.externalProjectManager}
            />
          )
        );

      case IFileInfoPanelItems.Categories:
        return (
          categoryAssignmentListIsAccessible && (
            <ProjectCategoryCard
              key={item}
              categories={project?.categoryAssignmentList}
              id={project?.id}
            />
          )
        );

      case IFileInfoPanelItems.Group:
        return (
          groupNameIsAccessible && (
            <InlineData
              key={item}
              label={t('COMMON.GROUP')}
              content={project?.group?.name}
            />
          )
        );

      case IFileInfoPanelItems.ProjectPeriod:
        return (
          timePeriodsIsAccessible && (
            <ProjectPeriodCard
              key={item}
              projectStartDate={
                project?.startDateOuter && startDateOuterIsAccessible
                  ? new Date(project?.startDateOuter)
                  : null
              }
              projectEndDate={
                project?.endDateOuter && endDateOuterIsAccessible
                  ? new Date(project?.endDateOuter)
                  : null
              }
            />
          )
        );

      case IFileInfoPanelItems.EventPeriod:
        return (
          timePeriodsIsAccessible && (
            <EventPeriodCard
              key={item}
              eventStartDate={
                project?.startDateInner && startDateInnerIsAccessible
                  ? new Date(project?.startDateInner)
                  : null
              }
              eventEndDate={
                project?.endDateInner && endDateInnerIsAccessible
                  ? new Date(project?.endDateInner)
                  : null
              }
            />
          )
        );

      case IFileInfoPanelItems.Status:
        return (
          projectStatusNameIsAccessible && (
            <InlineData
              key={item}
              label={t('FILE_CONTACTS.STATUS')}
              content={
                project?.projectStatus?.name || t('COMMON.PROJECT_EMPTY_STATUS')
              }
            />
          )
        );

      case IFileInfoPanelItems.Budget:
        return (
          budgetIsAccessible && (
            <InlineData
              key={item}
              label={t('COMMON.BUDGET')}
              content={
                project?.budget
                  ? `${formatNumberAsCurrency(project.budget)} €`
                  : undefined
              }
            />
          )
        );

      case IFileInfoPanelItems.ParentProject:
        return (
          parentProjectNameIsAccessible && (
            <InlineData
              key={item}
              label={t('FILE_CONTACTS.PARENT_PROJECT')}
              content={project?.parentProject?.name}
            />
          )
        );

      case IFileInfoPanelItems.ProjectPlanning:
        return (
          <Box margin="0 " maxHeight={600} overflow="hidden">
            <ProjectProcessList projectId={project?.id} />
          </Box>
        );

      case IFileInfoPanelItems.Note:
        return (
          noteIsAccessible && (
            <Stack key={item}>
              <Divider title={t('COMMON.NOTE')} />
              <Box margin="0 0 0 -1.5rem">
                <PreviewTextEditorInput
                  value={project?.note}
                  onClose={(note) => {
                    if (note !== project?.note) {
                      onPropEdit({ note });
                    }
                    onPopoverClose();
                  }}
                  accessor="note"
                  previewMode="text"
                />
              </Box>
            </Stack>
          )
        );

      default:
        return null;
    }
  };

  if (!displayItems.length) return null;

  return (
    <PreviewInputsProvider
      loading={loading}
      activePicker={activePicker}
      handleLock={handleLock}
      commonFields={[]}
      isMultiselect={false}
      entity={Entities.project}
      locked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={1}
      canEditAllSelected={canEdit}
    >
      <FilePreviewPanel
        entity={Entities.project}
        title={project?.name}
        onEdit={
          project?.objectGroupProtectionState !== ObjectProtectionState.LOCKED
            ? onEdit
            : undefined
        }
        onCloseClick={onCloseClick}
        onSettings={onSettings}
        displayItems={displayItems}
        renderItem={renderItem}
        onOpenFilePageClicked={onOpenFilePageClicked}
        warning={
          project?.projectStatus?.closedStatus === -1
            ? t('FILES_PREVIEW.CLOSED_WARNING.PROJECT')
            : undefined
        }
        onVisibilityToggle={onVisibilityToggle}
      />
    </PreviewInputsProvider>
  );
}
