import { round } from 'lodash';

/**
 * Calculates a price with a given surcharge percentage.
 *
 * @param {number} entryPrice - The price without surcharge.
 * @param {number} surcharge - The surcharge percentage value.
 * @returns {number} The price with the surcharge calculated.
 */
export function calculatePriceWithSurcharge(
  entryPrice: number,
  surcharge: number
): number {
  const factor = 1 + surcharge / 100;
  const finalPrice = round(entryPrice * factor, 2);

  return finalPrice;
}
