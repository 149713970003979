import { DocumentNode, gql, useQuery } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';
import i18next from 'i18next';
import { concat, isEqual, noop, set } from 'lodash';
import { useDebugValue, useEffect, useRef, useState } from 'react';

import { buildQuery } from '@work4all/data/lib/hooks/data-provider/utils/buildQuery';
import { EntityPickerSettingsFilterBy } from '@work4all/data/lib/settings/settings';

import { ObjectTypeByEntity } from '@work4all/models';
import { GroupQueryResult } from '@work4all/models/lib/Classes/GroupQueryResult.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { AggregationType } from '@work4all/models/lib/Enums/AggregationType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant } from '@work4all/utils';
import { indexRangeToPagination } from '@work4all/utils/lib/pagination';

import {
  translateField,
  translateFilter,
} from '../../../../dataDisplay/basic-table/hooks/query-table-data/hooks/translate-utils';
import { GroupByQuery } from '../../../../dataDisplay/basic-table/hooks/query-table-data/types';
import { useTablePrefilterContext } from '../../../table/TablePrefilterProvider';

import { decodeAlias, encodeAlias } from './group-by-alias-utils';
import { getEntityId, prepareSettingsFilters } from './utils';

export interface IFilterPickerConfig {
  parentEntityType: Entities;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: Record<string, any>[];
  field: string;
}

export interface IUseFilterPickerConfigOptions extends IFilterPickerConfig {
  data: unknown;
  sortBy: string;
  sortByDirection?: SortDirection;
  filterBy: string | string[];
  settingsFilters?: EntityPickerSettingsFilterBy[];
  query: string;
}

interface GroupByQueryVariables {
  size?: number | null;
  page?: number | null;
  query: GroupByQuery;
}

const PAGE_SIZE = 100;

type FilterPickerConfig = {
  query: DocumentNode;
  variables: GroupByQueryVariables;
  skip: boolean;
};

function createConfig(
  options: IUseFilterPickerConfigOptions,
  prefilter: unknown[]
): FilterPickerConfig {
  if (!options) {
    return {
      query: NOOP_QUERY,
      variables: {} as GroupByQueryVariables,
      skip: true,
    };
  }

  const {
    parentEntityType,
    filter,
    field,
    data: schema,
    query: searchQuery,
    filterBy,
    settingsFilters,
    sortBy,
    sortByDirection = SortDirection.ASCENDING,
  } = options;

  function flattenSchema(value: unknown): string[] {
    function flattenSchemaRecursive(value: unknown, keys: string[]): string[] {
      if (Array.isArray(value)) {
        invariant(value.length === 1);

        return flattenSchemaRecursive(value[0], keys);
      } else if (typeof value === 'object' && value !== null) {
        return Object.entries(value).flatMap(([key, nested]) => {
          return flattenSchemaRecursive(nested, keys.concat(key));
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-error
        return keys.join('.');
      }
    }

    return flattenSchemaRecursive(value, []);
  }

  const prefix =
    field.lastIndexOf('.') !== -1 ? field.slice(0, field.lastIndexOf('.')) : '';

  if (prefix === '') {
    throw new Error(
      [
        `Field "${field}" is invalid in the context of a picker filter.`,
        ' It must be of pattern "{prefix}.id" so that the prefix can be used in the "groupBy" query.' +
          " Please check this column's definition in the table schema.",
      ].join('')
    );
  }

  const withPrefix = (field: string): string => {
    return `${prefix}.${field}`;
  };

  const prefixedSortBy = withPrefix(sortBy);

  const _fields = flattenSchema(schema)
    .map((f) => withPrefix(f))
    .filter((f) => f !== field && f !== prefixedSortBy);

  // HACK Try to guess which fields are too deeply nested for the "groupBy"
  // query to handle. Remove them and just query what's left and hope that
  // everything works.
  //
  // We guess the fields by only allowing to query 1 level deep after the
  // prefix. This means that, if the prefix is configured correctly in the table
  // schema, we only fetch primitive fields on the entity itself.
  //
  // Example:
  //
  // The entity is contact and the prefix is "nested.contact".
  //
  // The contact picker is trying to request these fields:
  //
  // const CONTACT_FIELDS: Contact = {
  //   id: null,
  //   displayName: null,
  //   salutation: {
  //     isFemale: null,
  //     isMale: null,
  //   },
  // };
  //
  // The fields "id" and "displayName" will be added to the request, and the
  // fields "salutation.isFemale" and "salutation.isMale" won't be.
  //
  // The full field names will then be "nested.contact.id", "nested.contact.displayName".
  //
  // This should, hopefully, avoid all issues with fields depth in these requests.

  const fields = _fields.filter((field) => {
    const prefixLength = prefix.split('.').length;
    const fieldLength = field.split('.').length;

    return fieldLength - prefixLength <= 1;
  });

  const data: GroupQueryResult<EMode.query> = {
    totalCount: null,
    data: [
      {
        data: [
          {
            alias: null,
            value: null,
          },
        ],
        aggregateResult: null,
      },
    ],
  };

  // Remove this picker itself from the filter so that we don't filter to
  // only selected values and show the full list of available options instead.
  const filterExcludingThisPicker = filter?.filter((rule) => !(field in rule));

  const filterArray = Array.isArray(filterBy) ? filterBy : [filterBy];
  const searchFilter =
    searchQuery === ''
      ? null
      : {
          $or: filterArray.map((field) => ({
            [`${prefix}.${field}`]: { $eq: `%${searchQuery}%` },
          })),
        };

  const showHideFilter =
    searchQuery?.length && prepareSettingsFilters(settingsFilters, prefix);

  const finalFilter = concat(
    ...[
      prefilter,
      filterExcludingThisPicker,
      searchFilter,
      showHideFilter,
    ].filter(Boolean)
  );

  const sortingField = {
    field: translateField(prefixedSortBy, parentEntityType),
    alias: encodeAlias(prefixedSortBy),
    sort: [
      {
        sortOrder: sortByDirection,
        sortType: 'BY_FIELD',
      },
    ],
  };

  const defaultFields = [];
  if (field === prefixedSortBy) {
    defaultFields.push({
      ...sortingField,
    });
  } else {
    defaultFields.push(
      {
        field: translateField(field, parentEntityType),
        alias: encodeAlias(field),
      },
      sortingField
    );
  }

  const requestData: DataRequest = {
    operationName: 'GetGroupedItemsForPicker',
    entity: Entities.groupQueryResult,
    data,
    vars: {
      query: {
        entityType: ObjectTypeByEntity[parentEntityType],
        groupKeyFields: [
          ...defaultFields,
          ...fields.map((field) => ({
            field: translateField(field, parentEntityType),
            alias: encodeAlias(field),
          })),
        ],
        filter:
          finalFilter.length > 0
            ? JSON.stringify(translateFilter(finalFilter, parentEntityType))
            : null,
        aggregation: AggregationType.COUNT,
      },
    },
  };

  const { query, variables } = buildQuery(requestData, null);

  return {
    query,
    variables: {
      ...(variables as GroupByQueryVariables),
      page: 0,
      size: PAGE_SIZE,
    },
    skip: false,
  };
}

export function useFilterPickerConfig(options: IUseFilterPickerConfigOptions) {
  const { prefilter } = useTablePrefilterContext();

  const [config, setConfig] = useState(() => createConfig(options, prefilter));

  const currentConfigRef = useRef(options);

  useEffect(() => {
    if (!isEqual(options, currentConfigRef.current)) {
      currentConfigRef.current = options;
      setConfig(createConfig(options, prefilter));
    }
  }, [currentConfigRef, options, prefilter]);

  const { query, variables, skip } = config;

  const result = useQuery<{ groupBy: GroupQueryResult }, GroupByQueryVariables>(
    query,
    {
      variables,
      skip,
    }
  );

  const [state, setState] = useState<IFilterAwarePickerState>(() => {
    return result.data?.groupBy
      ? transformGroupByResult(result.data.groupBy)
      : {
          nodes: [],
          counts: {},
          totalCount: 0,
        };
  });

  useEffect(() => {
    if (!options) {
      setState({
        nodes: [],
        counts: {},
        totalCount: 0,
      });
    }
  }, [options]);

  const handleGroupByResultChange = useEventCallback(
    (result: GroupQueryResult) => {
      setState(transformGroupByResult(result));
    }
  );

  useEffect(() => {
    if (result.data?.groupBy) {
      handleGroupByResultChange(result.data.groupBy);
    }
  }, [handleGroupByResultChange, result.data]);

  useDebugValue(state);

  const isFetchingMoreRef = useRef(false);

  const fetchMore = async (): Promise<void> => {
    // If fetchMore is called before the initial query finished, ignore the
    // call.
    //
    // We can't merge the result of fetchMore with the current data because
    // there is no current data. And it doesn't make sense to try, because the
    // original request will already load this data, so this would just be a
    // duplicate network request.
    //
    // This situation is likely caused by a bug in state management in
    // useFilterPickerConfig that makes it so ListEntityPicker calls fetchMore
    // when the query is not ready yet, which shouldn't happen.
    //
    // But this doesn't break anything and it is safe to just ignore this
    // operation and wait for the initial query to load first. It is likely that
    // we won't need fetchMore after it loads, but, if some rows are still
    // missing, the fetchMore will simply be called again and we will load more
    // rows then.
    if (!result.data) {
      return Promise.resolve();
    }

    if (isFetchingMoreRef.current) return new Promise(noop);

    isFetchingMoreRef.current = true;

    const startIndex = state.nodes.length;
    const stopIndex = startIndex + PAGE_SIZE - 1;

    const { page, size } = indexRangeToPagination({ startIndex, stopIndex });

    await result.fetchMore({
      variables: { page, size },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          groupBy: { data: previousData, totalCount: previousTotalCount },
        } = previousQueryResult;
        const {
          groupBy: { data, totalCount },
        } = fetchMoreResult;

        if (data.length === 0 && totalCount === previousTotalCount) {
          return previousQueryResult;
        }

        const fullData = [...previousData];

        const start = page * size;

        if (fullData.length < start) {
          const originalLength = fullData.length;
          fullData.length = start;
          fullData.fill(null, originalLength, start);
        }

        fullData.splice(start, data.length, ...data);

        const result: { groupBy: GroupQueryResult } = {
          groupBy: {
            ...fetchMoreResult.groupBy,
            data: fullData,
            totalCount,
          },
        };

        isFetchingMoreRef.current = false;

        return result;
      },
    });
  };

  return { ...state, loading: result.loading, fetchMore };
}

const NOOP_QUERY = gql`
  query Noop {
    noop
  }
`;

function transformGroupByResult(
  result: GroupQueryResult
): IFilterAwarePickerState {
  const nodes = [];
  const counts: Record<string, number> = {};

  for (const row of result.data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let node: any = {};
    let isNoneItem = false;

    for (const { alias, value } of row.data) {
      const decodedAlias = decodeAlias(alias);
      const decodedAliasArray = decodedAlias.split('.');
      const property = decodedAliasArray[decodedAliasArray.length - 1];

      isNoneItem = isNoneItem || (property === 'id' && value === '0');
      set(node, decodedAlias, value);
    }

    let nodeKeys = Object.keys(node);

    if (nodeKeys.length !== 1) {
      throw new Error(
        `There are ${nodeKeys.length} root properties in the result instead of expected 1.`
      );
    }
    node = node[nodeKeys[0]];
    nodeKeys = Object.keys(node);
    if (nodeKeys.length === 1) {
      node = node[nodeKeys[0]];
    }

    if (isNoneItem) {
      /*
       * We manipulate some properties value for the node with `id='0'`,
       * we are doing that because is what we call `None Item`.
       *
       * The `None Item` node has empty properties value e.g. name property
       * so we have to give them a value so they don't appear empty
       * e.g. in `Picker Filter`.
       *
       * We are doing that here because node values are used in different
       * places e.g. `Selected Filters List Chip` and it's better to
       * edit the base instead of doing the same everywhere.
       */

      Object.keys(node).forEach((property) => {
        if (['displayName', 'name'].includes(property)) {
          node[property] = i18next.t('COMMON.NONE');
        }
      });

      nodes.unshift(node);
    } else {
      nodes.push(node);
    }

    counts[getEntityId(node)] = row.aggregateResult;
  }

  return {
    counts,
    nodes,
    totalCount: Math.min(result.totalCount, result.data.length + 3),
  };
}

interface IFilterAwarePickerState {
  counts: Record<string, number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: any[];
  totalCount: number;
}
