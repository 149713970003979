export enum RelativeDateFilter {
  today = 'today',
  yesterday = 'yesterday',
  tomorrow = 'tomorrow',
  todayAndPast = 'todayAndPast',
  lastWeek = 'lastWeek',
  currentWeek = 'currentWeek',
  nextWeek = 'nextWeek',
  lastMonth = 'lastMonth',
  currentMonth = 'currentMonth',
  nextMonth = 'nextMonth',
  lastYear = 'lastYear',
  currentYear = 'currentYear',
  nextYear = 'nextYear',
}
