import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputTicketFilter<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: userCode */
  userCode?: number;
  /** Alias: global */
  global?: boolean;
  /** Alias: filter */
  filter?: string;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputTicketFilter> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userCode: {
    alias: 'userCode',
  },
  global: {
    alias: 'global',
  },
  filter: {
    alias: 'filter',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputTicketFilterEntityDefinition: EntitiyDefinition<InputTicketFilter> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputTicketFilter',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
