import { Portal } from '@mui/base';
import { Home, Person } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SadFaceAnimation } from '@work4all/components';

import { useChangeTenant, useUser } from '@work4all/data';
import { logoutUser } from '@work4all/data/lib/actions/user-actions';

const FullScreenWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  zIndex: 501,
  padding: '2rem',
  gap: '3.25rem',
}));

type NoAccessReason = 'tenant' | 'route';

const NoAccessReasonKeys: Record<NoAccessReason, string> = {
  tenant: 'NO_ACCESS_PAGE.MESSAGE.FORBIDDEN_TENANT',
  route: 'NO_ACCESS_PAGE.MESSAGE.FORBIDDEN_ROUTE',
};

export type NoAccessProps = {
  reason?: NoAccessReason;
};

export const NoAccess = ({ reason = 'tenant' }: NoAccessProps) => {
  const { t } = useTranslation();
  const user = useUser();
  const dispatch = useDispatch();
  const changeTenant = useChangeTenant();

  return (
    <Portal>
      <FullScreenWrapper>
        <SadFaceAnimation style={{ width: '5.5rem' }} />
        <Typography variant="body1" color="text.inverse">
          {t(NoAccessReasonKeys[reason])}
        </Typography>
        <Stack direction={{ md: 'row', xs: 'column' }} gap="3rem">
          <Button
            variant="text"
            style={{ color: '#fff' }}
            startIcon={<Home />}
            onClick={() => {
              const newTenantId = user.mandanten[0].code;
              changeTenant(newTenantId);
            }}
          >
            {t('NO_ACCESS_PAGE.BUTTONS.HOME')}
          </Button>
          <Button
            startIcon={<Person />}
            style={{ color: '#fff' }}
            variant="text"
            onClick={() => {
              dispatch(logoutUser());
            }}
          >
            {t('NO_ACCESS_PAGE.BUTTONS.CHANGE_USER')}
          </Button>
        </Stack>
      </FullScreenWrapper>
    </Portal>
  );
};
