import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';

import { LanguageResource } from '@work4all/utils/lib/i18n/LanguageResource.enum';

import { useLanguageManager } from '../../../../providers/LanguageManagerProvider';

export const Firm = () => {
  const { addResource, removeResource, resources } = useLanguageManager();
  const { t } = useTranslation();

  const user = useUser();

  return (
    <Box p="1rem" pr="0">
      <Divider title={t('INPUTS.CONTEXT')} />
      <CheckboxRadioItem
        disabled={!user.isMaster}
        label={t('LANGUAGE_MANAGER.IS_SERVICE_PROVIDER.LABEL')}
        caption={t('LANGUAGE_MANAGER.IS_SERVICE_PROVIDER.CAPTION')}
        checked={resources.includes(LanguageResource.ServiceProvider)}
        onChange={(e) => {
          if (e.target.checked) {
            addResource(LanguageResource.ServiceProvider);
          } else {
            removeResource(LanguageResource.ServiceProvider);
          }
        }}
      />
    </Box>
  );
};
