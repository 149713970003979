import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { nameof } from '../../../../helper/nameof';
import { EntitiesConfigs } from '../types';

export const ENTITIES_CONFIGS: EntitiesConfigs = {
  [Entities.eMail]: {
    [nameof<EMail>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_E-Mail',
    },
  },
  [Entities.document]: {
    [nameof<Document>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_Dokumente',
    },
  },
  [Entities.letter]: {
    [nameof<Letter>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_Korrespondenz',
    },
  },
  [Entities.callMemo]: {
    [nameof<CallMemo>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_Telefonate',
    },
  },
  [Entities.task]: {
    [nameof<Task>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_Aufgaben',
    },
  },
  [Entities.note]: {
    [nameof<Note>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_Notizen',
    },
  },
  [Entities.appointment]: {
    [nameof<Appointment>('project')]: {
      type: 'object',
      settingName: 'PflichtfeldProjekt_Termine',
    },
  },
};
