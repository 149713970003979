import { useCallback } from 'react';

import { UsePreviewInputHandlersProps } from '../../types';
import { usePreviewInputSnackbar } from '../use-preview-input-snackbar/use-preview-input-snackbar';

export const usePreviewInputHandlers = ({
  locked: isLocked,
  loading: isLoading,
  ref,
  handleLock,
  accessor,
  lockedByUser,
  disabled,
  canEditAllSelected,
}: UsePreviewInputHandlersProps) => {
  const { isError, showError } = usePreviewInputSnackbar({
    locked: isLocked,
    canEditAllSelected,
    lockedByUser,
  });

  const onClick = useCallback(() => {
    if (disabled || isLoading) return;

    if (isError) {
      showError();
      return;
    }

    ref.current.open();
    handleLock(accessor);
  }, [disabled, isLoading, isError, ref, handleLock, accessor, showError]);

  return { onClick };
};
