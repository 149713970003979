import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticCustomerDatasheetStatistic } from './StatisticCustomerDatasheetStatistic.entity';

export class StatisticCustomerDatasheetColumn<T extends EMode = EMode.entity> {
  /** Alias: month */
  month?: string;
  /** Alias: statistics */
  statistics?: StatisticCustomerDatasheetStatistic<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCustomerDatasheetColumn> = {
  month: {
    alias: 'month',
  },
  statistics: {
    alias: 'statistics',
    entity: Entities.statisticCustomerDatasheetStatistic,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCustomerDatasheetColumnEntityDefinition: EntitiyDefinition<StatisticCustomerDatasheetColumn> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticCustomerDatasheetColumn',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
