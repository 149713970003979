import { FilterConfig } from '../interfaces/FilterConfig';

export const parseFilterConfig = (config: string): FilterConfig => {
  try {
    return JSON.parse(config);
  } catch {
    return {
      filter: '',
      groupBy: [],
      prefilter: [],
      visibleColumns: [],
    };
  }
};
