import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputReZahlung } from './InputReZahlung.entity';

export class InputRePaymentRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: InputReZahlung<T>[];
  /** Alias: delete */
  delete?: number[];
  /** Alias: modify */
  modify?: InputReZahlung<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputRePaymentRelation> = {
  add: {
    alias: 'add',
  },
  delete: {
    alias: 'delete',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputRePaymentRelationEntityDefinition: EntitiyDefinition<InputRePaymentRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputRePaymentRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
