import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { TaxGroupType } from '../Enums/TaxGroupType.enum';

export class TaxGroup<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: type */
  type?: TaxGroupType;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TaxGroup> = {
  id: {
    alias: 'code',
  },
  type: {
    alias: 'type',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const taxGroupEntityDefinition: EntitiyDefinition<TaxGroup> = {
  local: {},
  remote: {
    queryName: 'getTaxGroups',
    fragmentName: 'TaxGroup',
    withPaginationWrapper: false,
    params: [{ name: 'includeStaticGroups', type: 'Boolean' }],
  },
  fieldDefinitions: fields,
};
