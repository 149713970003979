import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';

export const RE_VIEW_MODEL_DATA: ReViewModel = {
  id: null,
  note: null,
  paymentTermDays: null,
  invoiceKind: null,
  reference: null,
  invoiceNumber: null,
  originalInvoiceNumber: null,
  dateOfReceipt: null,
  dueDate: null,
  invoiceDate: null,
  plannedDeliveryDate: null,
  deliveryDate: null,
  bookingDate: null,
  supplier: {
    id: null,
    name: null,
    number: null,
    standardAccount: null,
  },
  currencyId: null,
  currency: {
    id: null,
    name: null,
  },
  paymentKind: {
    id: null,
    note: null,
  },
  paymentId: null,
  businessPartnerId: null,
  netValue: null,
  valueVat: null,
  deductionDiscount: null,
  discount2: null,
  discountDate: null,
  discount2Date: null,
  discountDays: null,
  discount2Days: null,
  discountPerCent: null,
  approvedByUser: {
    id: null,
    displayName: null,
  },
  receipts: [
    {
      id: null,
      name: null,
      fileInfos: {
        fileSize: null,
        fileEntityFilename: null,
        previewUrl: null,
        downloadUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
      },
    },
  ],
  approvedByUserId: null,
  bankAccountId: null,
  bankAccount: {
    id: null,
    name: null,
    bic: null,
    iban: null,
  },
  releasedByUserId: null,
  releasedByUser: {
    id: null,
    displayName: null,
  },
  orders: [
    {
      id: null,
      date: null,
      number: null,
      note: null,
    },
  ],
  inboundDeliveryNotes: [
    {
      id: null,
      date: null,
      number: null,
      note: null,
    },
  ],
  blockedByUser: {
    id: null,
    displayName: null,
  },
  blockedByUserId: null,
  bookings: [
    {
      id: null,
      konto: { id: null, number: null, name: null },
      costCenter: { id: null, number: null, name: null },
      project: { id: null, number: null, name: null },
      costGroup: { id: null, name: null },
      note: null,
      taxKeyValue: null,
      valueNet: null,
      vat: null,
      vatAmount: null,
      proportionDM: null,
    },
  ],
  valueNotPayed: null,
  isPayed: null,
  amountGross: null,
};
