import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectStepLinkDirection } from '@work4all/components/lib/components/entity-picker/project-step-link-direction-picker copy/ProjectStepLinkDirectionPicker';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';
import { ProjectStepLinkType } from '@work4all/models/lib/Enums/ProjectStepLinkType.enum';

import { ProjectProcessPickerField } from '../../../../../../../../entity-picker/ProjectProcessPickerField';
import { ProjectStepLinkDirectionPickerField } from '../../../../../../../../entity-picker/ProjectStepLinkDirectionPickerField';
import { ProjectStepLinkTypePickerField } from '../../../../../../../../entity-picker/ProjectStepLinkTypePickerField';

interface IProps {
  projectId: number;
  processId: number;
  onChange?: (props: {
    projectProcess: ProjectProcess;
    direction: ProjectStepLinkDirection;
    linkType: ProjectStepLinkType;
  }) => void;
}

export const ProjectProcessConnectionCreator = (props: IProps) => {
  const { projectId, processId, onChange } = props;
  const { t } = useTranslation();

  const [direction, setDirection] = useState({
    id: ProjectStepLinkDirection.Outgoing,
    name: t('PROJECT_STEP_LINK_DIRECTION.' + ProjectStepLinkDirection.Outgoing),
  });

  const [linkType, setLinkType] = useState({
    id: ProjectStepLinkType.FINISH_TO_START,
    name: t('PROJECT_STEP_LINK_TYPE.' + ProjectStepLinkType.FINISH_TO_START),
  });

  return (
    <Grid container spacing="1rem">
      <Grid item xs={3}>
        <ProjectStepLinkDirectionPickerField
          onChange={setDirection}
          value={direction}
        />
      </Grid>
      <Grid item xs={3}>
        <ProjectStepLinkTypePickerField
          onChange={setLinkType}
          value={linkType}
        />
      </Grid>
      <Grid item xs={6}>
        <ProjectProcessPickerField
          onChange={(value) => {
            if (onChange) {
              onChange({
                direction: direction.id,
                linkType: linkType.id,
                projectProcess: value,
              });
            }
          }}
          value={null}
          projectId={projectId}
          prefilter={[
            {
              id: {
                $ne: processId,
              },
            },
            {
              kindId: {
                $in: [
                  ProjectProcessKind.MEILENSTEIN,
                  ProjectProcessKind.VORGANG,
                ],
              },
            },
          ]}
          allowSelection={[
            ProjectProcessKind.MEILENSTEIN,
            ProjectProcessKind.VORGANG,
          ]}
          clearable
          label={t('COMMON.ADD_CONNECTION')}
        />
      </Grid>
    </Grid>
  );
};
