import { DefaultContext } from '@apollo/client';

import { Entities } from '../Enums/Entities.enum';

export interface IFieldParam {
  name: string;
  type: string;
  alias?: string;
}

/**
 * Examples of "name"
 * name: status.name
 * name: kunde.name
 * name: projectId
 *  */
export interface IFieldData {
  name: string;
  alias?: string;
  params?: IFieldParam[];
}

export interface IGenerateParams {
  rootField: IFieldData;
  operationName?: string;
  fields: IFieldData[];
}

export interface IDataProviderFieldParam extends IFieldParam {
  value?: string | number;
}

export interface EntitiyDefinition<T> {
  /**
   * The kind of the GraphQL type definition.
   * Currently supported options for entity definitions are "object" and "union".
   * If omitted, will be treated as an "object" type definition.
   *
   * @default "object"
   */
  kind?: 'object' | 'union';
  local: {
    collection?: string;
  };
  remote: {
    queryName: string;
    fragmentName?: string;
    withPaginationWrapper?: boolean;
    params?: IDataProviderFieldParam[];
    mutations?: {
      upsert?: {
        mutationName: string;
        args?: IFieldParam[];
      };
    };
  };
  fieldDefinitions: FieldDefinitions<T>;
}

export type EntitiyDefinitionMap = {
  [key in Entities]?: EntitiyDefinition<unknown>;
};

export interface Definition {
  alias: string;
  entity?: Entities | Entities[];
  key?: string;
  resolve?: () => void;
}

export type FieldDefinitions<T> = Record<keyof T, Definition>;

interface GroupOption {
  fields: string[];
  values: string[];
  aggregations: GroupAggregation[];
}

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export type SubDataType = {
  [key: string]: string[] | SubDataType | Array<CombinedType>;
};

export type CombinedType = SubDataType | string;

export interface BatchableContext extends DefaultContext {
  /** allow operation to be batched (default undefined), undefined | true = will be batched as conifgured by apollo, false = never batch it with other requests, string = batch it together with other requests with this same batch-key */
  batch?: boolean | string;
  hideError?: boolean;
}

export type KeyArguments = Record<string, boolean>;
export type KeysArguments = Record<string, KeyArguments>;

export interface DataRequest extends Pick<BatchableContext, 'batch'> {
  operationName?: string;
  entity: Entities;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: any[];
  sort?: SortOption[];
  groupBy?: GroupOption;
  vars?: Record<string, unknown>;
  data: Array<CombinedType> | unknown;
  skip?: boolean;
  completeDataResponse?: boolean;
  keysArguments?: KeysArguments;
}

export interface SortOption {
  field: string;
  direction: SortDirection;
}

type GroupAggregation = {
  field: string;
  type: 'sum' | 'avg' | 'min' | 'max';
};

type SubType<Base, Condition> = Pick<
  Base,
  {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
  }[keyof Base]
>;

type PickPrimitiveKeys<Type> = SubType<Type, number | string | null>;

export type UpsertMutationInput<Type extends { id?: number }> = Partial<
  Omit<PickPrimitiveKeys<Type>, 'id'>
> &
  Required<Pick<Type, 'id'>>;
