import { PropsWithChildren } from 'react';

export const OrganisationAreaSectionHeader = ({
  children,
}: PropsWithChildren) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  );
};
