import styles from './DatePicker.module.scss';

import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Datepicker as MobiscrollDatePicker,
  MbscDatepickerOptions,
} from '@mobiscroll/react';
import React from 'react';

import { useMobiscrollLanguage } from '../../utils/use-mobiscroll-language/use-mobiscroll-language';

export const DatePicker: React.FC<MbscDatepickerOptions> = (props) => {
  const language = useMobiscrollLanguage();
  return (
    <MobiscrollDatePicker
      theme="material"
      display="inline"
      touchUi={true}
      {...props}
      locale={language}
      renderCalendarHeader={() => (
        <>
          <CalendarNav />
          <div className={styles.DatePickerContainer}>
            <CalendarPrev />
            <CalendarToday />
            <CalendarNext />
          </div>
        </>
      )}
    />
  );
};
