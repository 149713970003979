import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { BusinessPartnerPickerField } from '../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../components/entity-picker/ContactPickerField';
import { ProjectPickerField } from '../../../../../../../../../components/entity-picker/ProjectPickerField';
import { UserPickerField } from '../../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../form-plus/use-form-context-plus';
import { DateTimeInputPicker } from '../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../components/control-wrapper';
import { DocumentMaskFormValue } from '../../../../types';

export function Assignment() {
  const { t } = useTranslation();
  const { control, watch, register } =
    useFormContextPlus<DocumentMaskFormValue>();

  const businessPartnerType = watch('businessPartnerType');
  const businessPartnerId = watch('businessPartnerId');

  return (
    <Collapse title={t('COMMON.ASSIGNMENT')} defaultOpen>
      <ControlWrapper>
        <ControllerPlus
          control={control}
          name="businessPartner.data"
          render={({ field: businessPartner }) => {
            return (
              <ControllerPlus
                control={control}
                name="contact"
                render={({ field: contact }) => {
                  return (
                    <MultiStepControls isColumnDirection={false}>
                      <Step index={0} active>
                        <BusinessPartnerPickerField
                          {...businessPartner}
                          onChange={(value) => {
                            businessPartner.onChange(value?.data || null);
                          }}
                        />
                      </Step>
                      <Step
                        index={1}
                        active={
                          businessPartner.value != null &&
                          !businessPartner?.value?.isPrivateCustomer
                        }
                      >
                        {businessPartner.value != null && (
                          <ContactPickerField
                            {...contact}
                            businessPartnerType={businessPartnerType}
                            businessPartnerId={businessPartnerId}
                          />
                        )}
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            );
          }}
        />
      </ControlWrapper>

      <ControlWrapper>
        <ControllerPlus
          control={control}
          name={'user'}
          render={({ field }) => {
            return <UserPickerField {...field} />;
          }}
        />

        <DateTimeInputPicker
          {...register('date')}
          dateLabel={t('FILTERS.DATE')}
          withTime={false}
        />
      </ControlWrapper>

      <ControlWrapper>
        <ControllerPlus
          control={control}
          name={'project'}
          render={({ field, fieldState }) => {
            return (
              <ProjectPickerField
                data={{
                  projectSubDirectories: [
                    {
                      id: null,
                      name: null,
                    },
                  ],
                  ...PROJECT_DATA,
                }}
                {...field}
                error={fieldState.error?.message}
              />
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
}
