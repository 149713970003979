import styles from './PartnerNumberAlert.module.scss';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, useDialogs } from '@work4all/components';
import { CopyButton } from '@work4all/components/lib/components/CopyButton';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../../../../locked-inputs';

interface PartnerNumberAlertProps {
  businessPartnerType: Entities.project | Entities.supplier | Entities.customer;
  businessPartnerAssignedNumber: number;
  open: boolean;
  onClose: () => void;
  bussinessParner: BusinessPartner['data'];
}
export const PartnerNumberAlert = (props: PartnerNumberAlertProps) => {
  const {
    businessPartnerAssignedNumber,
    businessPartnerType,
    open,
    bussinessParner,
    onClose,
  } = props;

  const { t } = useTranslation();

  const type =
    businessPartnerType === Entities.customer ? 'CUSTOMER' : 'SUPPLIER';

  return (
    <Dialog
      open={open}
      title={t(`ERP.${type}_NUMBER_GENERATED`)}
      onClose={onClose}
    >
      <div className={styles.container}>
        <Typography>
          {t(`ERP.${type}_NUMBER_GENERATED_DESC`, {
            name: bussinessParner.name,
            city: bussinessParner.city ? `, ${bussinessParner.city}` : '',
          })}
        </Typography>
        <PickerTargetButton
          value={businessPartnerAssignedNumber}
          buttonClassName={styles.button}
          actionsWrapperClassName={styles.actionsWrapper}
          iconRight={
            <CopyButton value={`${businessPartnerAssignedNumber}`}>
              {({ copy, copied }) => {
                return (
                  <Tooltip
                    open={copied}
                    title={t('COMMON.COPIED')}
                    placement="top"
                  >
                    <IconButton color="primary" onClick={copy}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                );
              }}
            </CopyButton>
          }
        />
        <Button
          sx={{
            flex: 1,
          }}
          onClick={onClose}
        >
          {t('ALERTS.CLOSE')}
        </Button>
      </div>
    </Dialog>
  );
};

export const usePartnerNumberAlert = () => {
  const dialogs = useDialogs();

  return useCallback(
    (props: Omit<PartnerNumberAlertProps, 'onClose' | 'open'>) =>
      new Promise<void>((resolve) => {
        const onClose = () => {
          resolve();
          dialogs.close(id);
        };

        const { id } = dialogs.open(PartnerNumberAlert, {
          ...props,
          onClose,
        });
      }),
    [dialogs]
  );
};
