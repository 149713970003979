import styles from '../Grid.module.scss';

import clsx from 'clsx';
import React from 'react';

import { GridCellProps } from '../Grid';

export type CheckBoxGridCellProps = GridCellProps<boolean | null>;

function mapComponent(value: boolean | null) {
  switch (value) {
    case null:
      return Indeterminate;
    case true:
      return Checked;
    case false:
    default:
      return Unchecked;
  }
}

export const CheckBoxGridCell = (props: CheckBoxGridCellProps) => {
  const {
    columnIndex,
    rowIndex,
    onItemClicked,
    cellValue: value,
    disabled,
  } = props;

  const component = mapComponent(value);

  return (
    <div
      className={clsx(styles['cell-checkbox'], {
        [styles['disabled']]: disabled,
      })}
      onClick={
        disabled ? undefined : () => onItemClicked(columnIndex, rowIndex)
      }
    >
      {component}
    </div>
  );
};

export const Checked = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
      fill="#499CE0"
    />
  </svg>
);

export const Unchecked = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
      fill="#8B90A0"
    />
  </svg>
);

const Indeterminate = (
  <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
    <path
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"
      fill="#499CE0"
    ></path>
  </svg>
);
