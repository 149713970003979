import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCategoryMarkRelation } from './InputCategoryMarkRelation.entity';
import { InputObjectGroupRelation } from './InputObjectGroupRelation.entity';
import { InputProjectAccessGroupGroupRelation } from './InputProjectAccessGroupGroupRelation.entity';
import { InputSetProjectDirectory } from './InputSetProjectDirectory.entity';
import { InputStammdatenPostIt } from './InputStammdatenPostIt.entity';

export class InputProjektRelation<T extends EMode = EMode.entity> {
  /** Alias: projectCategories */
  projectCategories?: InputCategoryMarkRelation<T>;
  /** Alias: postIt */
  postIt?: InputStammdatenPostIt<T>;
  /** Alias: projectAccessGroups */
  projectAccessGroups?: InputProjectAccessGroupGroupRelation<T>;
  /** Alias: setProjectDirectory */
  setProjectDirectory?: InputSetProjectDirectory<T>;
  /** Alias: objectGroups */
  objectGroups?: InputObjectGroupRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputProjektRelation> = {
  projectCategories: {
    alias: 'projectCategories',
  },
  postIt: {
    alias: 'postIt',
  },
  projectAccessGroups: {
    alias: 'projectAccessGroups',
  },
  setProjectDirectory: {
    alias: 'setProjectDirectory',
  },
  objectGroups: {
    alias: 'objectGroups',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputProjektRelationEntityDefinition: EntitiyDefinition<InputProjektRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputProjektRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
