import styles from './TextInputCell.module.scss';

import clsx from 'clsx';
import { useEffect, useState } from 'react';

export type TextInputCellProps = {
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  forbiddenPattern?: RegExp;
  pattern?: RegExp;
  rightTextAlign?: boolean;
  autoFocus?: boolean;
};

export const TextInputCell = (props: TextInputCellProps) => {
  const {
    className,
    value = '',
    onChange,
    disabled = false,
    forbiddenPattern,
    rightTextAlign,
    onFocus,
    onBlur,
  } = props;

  const [state, setState] = useState(value);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    setState(value);
  }, [value, isFocused]);

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      onChange(state);
    }
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>): void {
    setFocused(true);
    onFocus?.(e);
    e.target.select();
  }

  function handleBlur(): void {
    onChange(state);
    setFocused(false);
    onBlur?.();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.currentTarget.value;
    const validated = forbiddenPattern
      ? value.replace(forbiddenPattern, '')
      : value;

    setState(validated);
  }

  return (
    <input
      autoFocus={props.autoFocus}
      className={clsx(styles.textInput, className, {
        [styles.rightTextAlign]: rightTextAlign,
      })}
      disabled={disabled}
      value={state}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};
